import { makeStyles } from "@toolkit/ui";

export const useCardButtonStyles = makeStyles()(theme => ({
  button: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    height: "170px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    border: "none",
    outline: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary[50],
    },
    "&:focus": {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: "2px",
    },
    "& svg": {
      "& path": {
        fill: theme.palette.primary[1600],
      },
      width: "45px",
      height: "45px",
    },
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    marginTop: "10px",
    textAlign: "center",
    maxWidth: "130px",
  },
  subtitle: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    display: "block",
  },
}));
