import { FormSlider } from "@/components";
import { LabeledInputContainer } from "@/components/LabeledInputContainer";
import RadioGroupField from "@/components/RadioGroupField";
import { activityOptions, BooleanYesNo, genderOptions } from "@/domains/calculators/fields/constants";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { Gender } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CalculatorNutritionStep } from "../../types";
import { calculatorNutritionFormSchema, ICalculatorNutritionFormValues } from "./CalculatorNutritionFormSchema";
import { useCalculatorNutritionFormStyle } from "./useCalculatorNutritionFormStyle";

export type ICalculatorNutritionFormEvent = {
  type: "COMPLETE_PLAN" | "SHOW_RESULTS" | "SKIP";
  payload: {
    values: ICalculatorNutritionFormValues;
  };
};

type CalculatorNutritionFormProps = {
  step: CalculatorNutritionStep;
  formValues?: ICalculatorNutritionFormValues;
  onChange: (event: ICalculatorNutritionFormEvent) => void;
};

export const CalculatorNutritionForm: FC<CalculatorNutritionFormProps> = props => {
  const { step, formValues, onChange } = props;

  const { t } = useTranslation("digital-twin");

  const { classes } = useCalculatorNutritionFormStyle();

  const profileData = useReactiveVar(userProfileVar);

  const form = useForm<ICalculatorNutritionFormValues>({
    mode: "all",
    resolver: zodResolver(calculatorNutritionFormSchema),
    defaultValues: {
      age: profileData?.age || 0,
      gender: profileData?.gender,
      height: profileData?.height || 0,
      weight: profileData?.weight || 0,
      activityLevel: profileData?.activityLevel,
    },
  });

  const { setValue, watch, handleSubmit } = form;

  const weight = watch("weight");
  const readyToChangeActivityLevel = watch("readyToChangeActivityLevel");

  const onClick = (type: "COMPLETE_PLAN" | "SHOW_RESULTS" | "SKIP") => {
    handleSubmit(values => {
      onChange({
        type: type,
        payload: { values },
      });
    })();
  };

  useEffect(() => {
    if (formValues) {
      setValue("gender", genderOptions?.find(item => item?.value === formValues?.gender)?.value);
      setValue("age", formValues?.age);
      setValue("weight", formValues?.weight);
      setValue("height", formValues?.height);
      setValue("activityLevel", activityOptions?.find(item => item?.value === formValues?.activityLevel)?.value);
      setValue("goalWeight", formValues?.goalWeight);
      setValue("days", formValues?.days);
      setValue("readyToChangeActivityLevel", BooleanYesNo?.find(item => item?.value === formValues?.readyToChangeActivityLevel)?.value);
      setValue(
        "newActivityLevel",
        formValues?.readyToChangeActivityLevel
          ? activityOptions?.find(item => item?.value === formValues?.newActivityLevel)?.value
          : undefined
      );
    }
  }, [formValues, setValue]);

  function getIdealWeight() {
    const height = watch("height");
    const gender = watch("gender");
    if (!height) return 55;

    if (gender === Gender.Female) {
      return 45.5 + 0.91 * (height - 152.4);
    } else {
      return 50 + 0.91 * (height - 152.4);
    }
  }

  return (
    <FormProvider {...form}>
      <Box className={classes.root}>
        <Box className={classes.fieldsWrapper}>
          {step === CalculatorNutritionStep.CalculatorNutritionFirstForm && (
            <>
              <RadioGroupField name={"gender"} label={t("Gender")} options={genderOptions} />

              <LabeledInputContainer label={`${t("Age")} (${t("years")})`}>
                <FormSlider name={"age"} valueLabelDisplay={"on"} min={0} max={120} />
              </LabeledInputContainer>

              <LabeledInputContainer label={`${t("Weight")} (${t("kg")})`}>
                <FormSlider name={"weight"} valueLabelDisplay={"on"} min={0} max={250} />
              </LabeledInputContainer>

              <LabeledInputContainer label={`${t("Height")} (${t("cm")})`}>
                <FormSlider name={"height"} valueLabelDisplay={"on"} min={100} max={250} />
              </LabeledInputContainer>

              <RadioGroupField name={"activityLevel"} label={t("Activity Level")} mode={"vertical"} options={activityOptions} />
            </>
          )}

          {step === CalculatorNutritionStep.CalculatorNutritionSecondForm && (
            <>
              <Typography sx={{ textAlign: "start" }} variant={"h5"} color='primary'>
                {t("Your ideal weight is")} {getIdealWeight()?.toFixed(0)}{" "}
              </Typography>
              <LabeledInputContainer label={`${t("Whats your desired weight goal")} (${t("kg")})`}>
                <>
                  <FormSlider name={"goalWeight"} valueLabelDisplay={"on"} min={0} max={weight || 250} />
                </>
              </LabeledInputContainer>

              <LabeledInputContainer label={`${t("How many days do you want to achieve this goal")} (${t("days")})`}>
                <FormSlider name={"days"} valueLabelDisplay={"on"} min={1} max={300} />
              </LabeledInputContainer>

              <RadioGroupField
                name={"readyToChangeActivityLevel"}
                label={t("Are you ready to change your physical activity level to achieve your goal?")}
                options={BooleanYesNo}
              />

              {readyToChangeActivityLevel && (
                <RadioGroupField name={"newActivityLevel"} label={t("New Activity Level")} mode={"vertical"} options={activityOptions} />
              )}
            </>
          )}
        </Box>

        <Box display={"flex"} gap={2} marginBlock={2}>
          {step === CalculatorNutritionStep.CalculatorNutritionFirstForm && (
            <Button fullWidth onClick={() => onClick("COMPLETE_PLAN")} className={classes.completePlanButton}>
              {t("Continue and get a weight loss plan")}
            </Button>
          )}

          {step === CalculatorNutritionStep.CalculatorNutritionSecondForm && (
            <>
              <Button fullWidth onClick={() => onClick("SHOW_RESULTS")}>
                {t("Show Results")}
              </Button>

              <Button fullWidth onClick={() => onClick("SKIP")}>
                {t("Skip")}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};
