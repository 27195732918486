import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useFitnessDataBoxStyles } from "./useFitnessDataBox.styles";

interface FitnessDataBoxProps {
  color: string;
  borderColor: string;
  value: string;
  label: string;
}

export const FitnessDataBox: React.FC<FitnessDataBoxProps> = ({ color, borderColor, value, label }) => {
  const { classes } = useFitnessDataBoxStyles({ color, borderColor });

  return (
    <Box className={classes.box}>
      <Typography className={classes.value}>
        {value}
        <Box className={classes.supp}>
          <sup>/100</sup>
        </Box>
      </Typography>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};
