import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(theme => ({
  container: {
    position: "absolute",
    backgroundColor: theme.palette.background.default,
    zIndex: 3,
    top: 0,
    left: 0,
    right: 0,
  },
  box: {
    paddingBlock: "5px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    padding: "0px",
    backgroundColor: theme.palette.primary[50],
    "& svg": {
      height: "11px",
      fill: theme.palette.text.primary,
    },
  },
  avatarButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  avatar: {
    height: "35px",
    width: "35px",
  },
  backButton: {
    width: "35ppx",
    height: "35px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary[50],
    "& svg": {
      width: "18px",
      fill: theme.palette.text.primary,
    },
  },
}));

export default useStyles;
