/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useBeeAndTDeeLazyQuery, useGetWeightLossPlanCalculationsLazyQuery } from "@/gql/queries";
import React, { useState } from "react";
import { CalculatorNutritionStep } from "../CalculatorNutrition/types";
import { ICalculatorNutritionFormValues } from "../CalculatorNutrition/forms/CalculatorNutrition/CalculatorNutritionFormSchema";
import { CalculatorNutritionStepMain } from "../CalculatorNutrition/components/CalculatorNutritionStepMain/CalculatorNutritionStepMain";
import {
  CalculatorNutritionStepForm,
  ICalculatorNutritionStepFormEvent,
} from "../CalculatorNutrition/components/CalculatorNutritionStepForm/CalculatorNutritionStepForm";
import {
  CalculatorNutritionStepResult,
  ICalculatorNutritionStepResultEvent,
} from "../CalculatorNutrition/components/CalculatorNutritionStepResult/CalculatorNutritionStepResult";
import { ActivityLevel, BeeAndTdeeResponse } from "@/schema/types";
import { CalculatorPageLayout } from "../CalculatorNutrition/components/CalculatorPageLayout/CalculatorPageLayout";
import { useTranslation } from "react-i18next";

export const BeeAndTdeeCalculatorPage = () => {
  const [step, setStep] = useState<CalculatorNutritionStep>(CalculatorNutritionStep.CalculatorNutritionMain);
  const [formValues, setFormValues] = useState<ICalculatorNutritionFormValues>();
  const [beeAndTdeeCalculation, setBeeAndTdeeCalculation] = useState<BeeAndTdeeResponse>();
  const [maxTdee, setMaxTdee] = useState<number>();

  const { t } = useTranslation("digital-twin");

  const [fetchBeeAndTdeeCalculation, { loading: isBeeAndTdeeCalculationLoading }] = useBeeAndTDeeLazyQuery();

  const [fetchWeightLossCalculation, { data: weightLossCalculationData, loading: isWeightLossCalculationLoading }] =
    useGetWeightLossPlanCalculationsLazyQuery();

  const weightLossCalculation = weightLossCalculationData?.getWeightLossPlanCalculations;

  const onCalculatorNutritionStepMainChange = () => {
    setStep(CalculatorNutritionStep.CalculatorNutritionFirstForm);
  };

  const handleCompletePlan = (values: ICalculatorNutritionFormValues) => {
    setStep(CalculatorNutritionStep.CalculatorNutritionSecondForm);

    fetchBeeAndTdeeCalculation({
      variables: {
        input: {
          age: values?.age,
          gender: values?.gender,
          height: values?.height,
          weight: values?.weight,
          activityLevel: ActivityLevel.VeryHeavyActive,
        },
      },
    }).then(result => setMaxTdee(result?.data?.beeAndtdee?.tdee!));
  };

  const handleSkip = (values: ICalculatorNutritionFormValues) => {
    setStep(CalculatorNutritionStep.CalculatorNutritionSkip);
    setFormValues(values);

    fetchBeeAndTdeeCalculation({
      variables: {
        input: {
          age: values?.age,
          gender: values?.gender,
          height: values?.height,
          weight: values?.weight,
          activityLevel: values?.activityLevel,
        },
        saveData: true,
      },
    }).then(result => setBeeAndTdeeCalculation(result?.data?.beeAndtdee!));
  };

  const handleShowResult = (values: ICalculatorNutritionFormValues) => {
    setStep(CalculatorNutritionStep.CalculatorNutritionResult);
    setFormValues(values);

    fetchBeeAndTdeeCalculation({
      variables: {
        input: {
          age: values?.age,
          gender: values?.gender,
          height: values?.height,
          weight: values?.weight,
          activityLevel: values?.activityLevel,
        },
        saveData: true,
      },
    }).then(result => {
      setBeeAndTdeeCalculation(result?.data?.beeAndtdee!);

      fetchWeightLossCalculation({
        variables: {
          input: {
            bee: result?.data?.beeAndtdee?.bee!,
            weight: values?.weight,
            goalWeight: values?.goalWeight!,
            days: values?.days!,
            newActivityLevel: values?.newActivityLevel || values?.activityLevel,
          },
          saveData: true,
        },
      });
    });
  };

  const onCalculatorNutritionStepFormChange = (event: ICalculatorNutritionStepFormEvent) => {
    const values = event.payload.values;

    if (event.type === "COMPLETE_PLAN") {
      handleCompletePlan(values);
    } else if (event.type === "SKIP") {
      handleSkip(values);
    } else if (event.type === "SHOW_RESULTS") {
      handleShowResult(values);
    }
  };

  const onCalculatorNutritionStepResultChange = (event: ICalculatorNutritionStepResultEvent) => {
    if (event.type === "CARD_CLICK") {
      setStep(CalculatorNutritionStep.CalculatorNutritionFirstForm);
    }
  };

  const onBackIconClick = () => {
    if (step === CalculatorNutritionStep.CalculatorNutritionSecondForm) {
      return setStep(CalculatorNutritionStep.CalculatorNutritionFirstForm);
    }

    setStep(CalculatorNutritionStep.CalculatorNutritionMain);
  };

  return (
    <CalculatorPageLayout
      title={t("Calorie Needs Calculator")}
      includeBackButton={
        step === CalculatorNutritionStep.CalculatorNutritionFirstForm || step === CalculatorNutritionStep.CalculatorNutritionSecondForm
      }
      onBackIconClick={onBackIconClick}
    >
      {step === CalculatorNutritionStep.CalculatorNutritionMain && (
        <CalculatorNutritionStepMain onChange={onCalculatorNutritionStepMainChange} />
      )}

      {(step === CalculatorNutritionStep.CalculatorNutritionFirstForm ||
        step === CalculatorNutritionStep.CalculatorNutritionSecondForm) && (
        <CalculatorNutritionStepForm step={step} formValues={formValues} onChange={onCalculatorNutritionStepFormChange} />
      )}

      {step === CalculatorNutritionStep.CalculatorNutritionSkip && (
        <CalculatorNutritionStepResult
          tdeeValue={beeAndTdeeCalculation?.tdee!}
          beeValue={beeAndTdeeCalculation?.bee!}
          maxTdee={maxTdee}
          isLoading={isBeeAndTdeeCalculationLoading}
          onChange={onCalculatorNutritionStepResultChange}
        />
      )}

      {step === CalculatorNutritionStep.CalculatorNutritionResult && (
        <CalculatorNutritionStepResult
          tdeeValue={beeAndTdeeCalculation?.tdee!}
          beeValue={beeAndTdeeCalculation?.bee!}
          goalWeight={formValues?.goalWeight!}
          daysToAchieveGoalWeight={formValues?.days!}
          reduceCalorie={weightLossCalculation?.caloriesPerDayToAdjust!}
          newTdeeValue={weightLossCalculation?.newTDEE!}
          maxTdee={maxTdee}
          targetCalories={weightLossCalculation?.newCalorieIntake!}
          isLoading={isWeightLossCalculationLoading}
          onChange={onCalculatorNutritionStepResultChange}
        />
      )}
    </CalculatorPageLayout>
  );
};
