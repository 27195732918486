import React, { FC } from "react";
import { Avatar, Box, Card, Grid, ImageIcon, MuiArrowBackIosNewIcon, MuiArrowForwardIosIcon, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { articleRoutes } from "@/pages/Articles";
import { Article } from "@/schema/types";
import { useArticleCardStyle } from "./useArticleCardStyle";

type ArticleCardProps = {
  article: Article;
};

export const ArticleCard: FC<ArticleCardProps> = props => {
  const { article } = props;

  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();

  const { classes } = useArticleCardStyle();

  const onCardClick = () => {
    if (!article) return;

    navigate(articleRoutes.articleDetails.getRouteWithParams({ articleId: article.id }));
  };

  return (
    <Card onClick={onCardClick} className={classes.root}>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={3.5}>
          {article?.headerImage ? (
            <Box
              className={classes.headerImage}
              sx={{
                backgroundImage: `url(${article?.headerImage}), url('icons/article.svg') !important`,
              }}
            />
          ) : (
            <Avatar clssName={classes.avatar}>
              <ImageIcon />
            </Avatar>
          )}
        </Grid>

        <Grid item xs={8.5}>
          <Box className={classes.infoWrapper}>
            <Box className={classes.titleWrapper}>
              <Typography className={classes.title}>{pickLocalizedValue(article?.title, article?.titleAr)}</Typography>

              {article?.readTimeMinutes && (
                <Typography className={classes.readTime}>{t("{{value}} Minutes Reading", { value: article?.readTimeMinutes })}</Typography>
              )}
            </Box>

            {pickLocalizedValue(
              <MuiArrowForwardIosIcon sx={{ fontSize: "10" }} color={"primary"} />,
              <MuiArrowBackIosNewIcon sx={{ fontSize: "10" }} color={"primary"} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
