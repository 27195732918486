/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnxietyScreeningQueryVariables = Types.Exact<{
  input: Types.AnxietyScreeningInput;
}>;


export type AnxietyScreeningQuery = { __typename?: 'Query', anxietyScreening?: { __typename?: 'AnxietyScreeningResponse', score?: number | null, riskLevel?: Types.RiskLevel | null } | null };


export const AnxietyScreeningDocument = gql`
    query AnxietyScreening($input: AnxietyScreeningInput!) {
  anxietyScreening(input: $input) {
    score
    riskLevel
  }
}
    `;

/**
 * __useAnxietyScreeningQuery__
 *
 * To run a query within a React component, call `useAnxietyScreeningQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnxietyScreeningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnxietyScreeningQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnxietyScreeningQuery(baseOptions: Apollo.QueryHookOptions<AnxietyScreeningQuery, AnxietyScreeningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnxietyScreeningQuery, AnxietyScreeningQueryVariables>(AnxietyScreeningDocument, options);
      }
export function useAnxietyScreeningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnxietyScreeningQuery, AnxietyScreeningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnxietyScreeningQuery, AnxietyScreeningQueryVariables>(AnxietyScreeningDocument, options);
        }
export type AnxietyScreeningQueryHookResult = ReturnType<typeof useAnxietyScreeningQuery>;
export type AnxietyScreeningLazyQueryHookResult = ReturnType<typeof useAnxietyScreeningLazyQuery>;
export type AnxietyScreeningQueryResult = Apollo.QueryResult<AnxietyScreeningQuery, AnxietyScreeningQueryVariables>;