import { FC } from "react";
import { Typography, Box } from "@toolkit/ui";
import { CustomSlider } from "../Slider";
import { useLifeSlideStyles } from "./LifeStyles.styles";
import { LifeSliderProps } from "./types";
import { getLabel, getThumbColor } from "./utils";

export const LifeSlider: FC<LifeSliderProps> = ({ values = {}, ...rest }) => {
  const { classes } = useLifeSlideStyles();
  const _values = Object.keys(values)
    .map(key => values[key].value)
    .sort((a, b) => a - b);
  const midValue = _values[1];
  const closerToMin = _values[1] - _values[0] < _values[2] - _values[1];

  if (_values.length >= 2) {
    _values[1] = _values.length === 3 ? ((_values[2] || 120) + (_values[0] || 0)) / 2 + (closerToMin ? -5 : +5) : _values[1];
  }

  const labelGetter = getLabel(values, midValue, _values);
  const thumbColorGetter = getThumbColor(values, midValue, _values);
  console.log({ values });

  return (
    <CustomSlider
      {...rest}
      track={false}
      value={_values}
      disabled
      valueLabelDisplay='on'
      scale={value => {
        const idx = _values.findIndex(val => val === value);
        if (idx === 1) {
          return midValue;
        }
        return value;
      }}
      valueLabelFormat={(value, idx) => (
        <>
          <Box
            className={classes.valueLabelBox}
            sx={{
              "&::before": {
                backgroundColor: thumbColorGetter(value),
              },
              color: thumbColorGetter(value),
            }}
          >
            {value}
          </Box>
          <Typography
            className={classes.valueLabelTypography}
            sx={{
              left: !idx ? 0 : "calc(50% - 42px)",
              color: thumbColorGetter(value),
            }}
          >
            {labelGetter(value)}
          </Typography>
        </>
      )}
      classes={{
        root: classes.slider,
      }}
    />
  );
};
