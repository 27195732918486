/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiabetesRiskScoreQueryVariables = Types.Exact<{
  input: Types.DiabetesRiskInput;
}>;


export type DiabetesRiskScoreQuery = { __typename?: 'Query', diabetesRisk?: { __typename?: 'DiabetesRiskResponse', risk?: Types.RiskLevel | null, score?: number | null } | null };


export const DiabetesRiskScoreDocument = gql`
    query DiabetesRiskScore($input: DiabetesRiskInput!) {
  diabetesRisk(input: $input) {
    risk
    score
  }
}
    `;

/**
 * __useDiabetesRiskScoreQuery__
 *
 * To run a query within a React component, call `useDiabetesRiskScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiabetesRiskScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiabetesRiskScoreQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiabetesRiskScoreQuery(baseOptions: Apollo.QueryHookOptions<DiabetesRiskScoreQuery, DiabetesRiskScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiabetesRiskScoreQuery, DiabetesRiskScoreQueryVariables>(DiabetesRiskScoreDocument, options);
      }
export function useDiabetesRiskScoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiabetesRiskScoreQuery, DiabetesRiskScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiabetesRiskScoreQuery, DiabetesRiskScoreQueryVariables>(DiabetesRiskScoreDocument, options);
        }
export type DiabetesRiskScoreQueryHookResult = ReturnType<typeof useDiabetesRiskScoreQuery>;
export type DiabetesRiskScoreLazyQueryHookResult = ReturnType<typeof useDiabetesRiskScoreLazyQuery>;
export type DiabetesRiskScoreQueryResult = Apollo.QueryResult<DiabetesRiskScoreQuery, DiabetesRiskScoreQueryVariables>;