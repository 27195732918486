import { Box, SxProps, Theme } from "@toolkit/ui";
import { PropsWithChildren } from "react";
import useStyles from "./LabeledInputContainer.styles";

type LabeledInputContainerProps = {
  label: string;
  mode?: "horizontal" | "vertical" | "long-options";
  sx?: SxProps<Theme>;
};

export const LabeledInputContainer: React.FC<PropsWithChildren<LabeledInputContainerProps>> = ({
  label,
  mode = "vertical",
  sx = {},
  children,
}) => {
  const { classes } = useStyles({ mode });

  return (
    <Box className={classes.container} sx={sx}>
      <Box className={classes.label}>{label}</Box>
      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};
