import { DepressionFrequency } from "@/schema/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { FeelingDownField, LittleInterestField } from "../../fields";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";

const IDepressionScreeningCalculatorFormZodSchema = z.object({
  feelingDown: z.nativeEnum(DepressionFrequency),
  littleInterest: z.nativeEnum(DepressionFrequency),
});

export type IDepressionScreeningCalculatorFormZodSchemaType = z.infer<typeof IDepressionScreeningCalculatorFormZodSchema>;
export interface IDepressionScreeningCalculatorFormRef {
  formMethods: ReturnType<typeof useForm<IDepressionScreeningCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const DepressionScreeningCalculatorForm = forwardRef<
  IDepressionScreeningCalculatorFormRef,
  {
    onSubmit: (data: IDepressionScreeningCalculatorFormZodSchemaType) => void;
    onChange?: (data: IDepressionScreeningCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const methods = useForm<IDepressionScreeningCalculatorFormZodSchemaType>({
    resolver: zodResolver(IDepressionScreeningCalculatorFormZodSchema),
    defaultValues: {
      feelingDown: userProfileVar().feelingDown,
      littleInterest: userProfileVar().littleInterest,
    },
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });
  methods.watch(d => props.onChange?.(d as IDepressionScreeningCalculatorFormZodSchemaType));

  useImperativeHandle(ref, () => ({
    formMethods: methods,
    handleSubmit,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <FeelingDownField />
        <LittleInterestField />
      </form>
    </FormProvider>
  );
});
