import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const TimeIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='21.471' height='21.471' viewBox='0 0 21.471 21.471' {...props}>
      <path
        id='Path_4'
        data-name='Path 4'
        d='M15.567,4.639h.068A10.681,10.681,0,1,1,4.954,15.32h0A10.693,10.693,0,0,1,15.567,4.639ZM15.635,24.1a8.786,8.786,0,1,0-6.2-2.573A8.776,8.776,0,0,0,15.635,24.1Z'
        transform='translate(-4.904 -4.589)'
        fill='#a1b2c5'
        stroke='#67768e'
        stroke-width='0.1'
      />
      <path
        id='Path_5'
        data-name='Path 5'
        d='M15.958,16.751h0l-3.775-2.939h-.068V9.5a.952.952,0,1,1,1.9,0v3.788l2.95,1.848a.954.954,0,1,1-1.011,1.619Z'
        transform='translate(-3.065 -1.495)'
        fill='#67768e'
        stroke='#67768e'
        stroke-width='0.1'
      />
    </SvgIcon>
  );
};
