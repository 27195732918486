import { BackButton, CardWithTitle, HeartHealthIcon, ServiceList, ServiceListItemProps } from "@/components";
import { getOrganRisks } from "@/domains/HealthStatus/constant";
import i18n, { useTranslation } from "@/i18n/i18n.config";
import { useHeaderContext } from "@/providers";
import { routesPaths } from "@/routing/routesPaths";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AgeDynamicsAndCalculatorsPage } from "../age-dynamics-and-calculators/AgeDynamicsAndCalculatorsPage";

const DiseaseRiskAndManagement = () => {
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();
  const theme = useTheme();
  const { setHeader, resetHeader, setIcon } = useHeaderContext();

  useEffect(() => {
    setHeader(t("Disease risk & management"));
    setIcon(<BackButton onClick={() => navigate(routesPaths.myDigitalTwin)} />);
    return () => {
      resetHeader();
    };
  }, [t]);
  const highRiskItems = getOrganRisks(theme)
    .filter(r => r.isDiseaseRisk)
    .filter(r => r.isHighRisk)
    .map((item, index) => {
      return {
        id: index,
        icon: item.icon,
        buttonSx: { width: "130px!important" },
        title: item.bodyString,
        body: item.bodyOrganRisk,
        onClick: () => {},
        buttonLabel: i18n.t("Calculate More", { ns: "digital-twin" }),
      } as ServiceListItemProps;
    });
  const hasRisks = highRiskItems.length > 0;
  return (
    <Box sx={{ padding: "10px", borderRadius: "10px", minHeight: "100%", height: "max-content" }}>
      <CardWithTitle title={t("High Risk Diseases")}>
        {hasRisks ? (
          <ServiceList items={highRiskItems} />
        ) : (
          <Typography
            variant='h6'
            sx={{ my: 2, textAlign: "center", display: "flex", alignContent: "baseline", justifyContent: "center", color: "success.main" }}
          >
            <HeartHealthIcon fill={"success.main"} sx={{ mx: 1 }} />

            <Typography>{t("No high risk diseases")}</Typography>
          </Typography>
        )}
      </CardWithTitle>
      <Box sx={{ mx: -1 }}>
        <AgeDynamicsAndCalculatorsPage />
      </Box>
    </Box>
  );
};

export default DiseaseRiskAndManagement;
