import { GeneratingDigitalTwinInProgressCountDownPage } from "@/domains/MyDigitalTwinProfile/containers/GeneratingDigitalTwinInProgressCountDownPage/GeneratingDigitalTwinInProgressCountDownPage";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { AgeDynamicsAndCalculatorsRoutes } from "@/pages/age-dynamics-and-calculators/AgeDynamicsAndCalculatorsRoutes";
import { ArticlesRoutes } from "@/pages/Articles/";
import { baseArticlesRoute } from "@/pages/Articles/routes";
import { GeneratedPage } from "@/pages/my-digital-twin/generated-page/GeneratedPage";
import { InCompleteProfilePage } from "@/pages/my-digital-twin/incomplete-profile/InCompleteProfilePage";
import { MyDigitalTwinUpdateProfilePage } from "@/pages/my-digital-twin/update-profile/MyDigitalTwinUpdateProfilePage";
import { useReactiveVar } from "@apollo/client";
import { Box, Container, FallbackComponent, useTheme } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { BMI, HealthAndWellBeing, MyDigitalTwin } from "../pages";
import DiseaseRiskAndManagement from "../pages/disease-risk-and-management/DiseaseRiskAndManagement";
import { LoginPage } from "../pages/login";
import PageNotFound from "../pages/PageNotFound";
import PageUnderConstruction from "../pages/PageUnderConstruction";
import { HeaderProvider } from "../providers";
import { useAuth } from "../providers/auth";
import { routesPaths } from "./routesPaths";
import { localStorageKeys } from "@/configs/local-storage-keys";
import { isInSehhatiApp } from "@/configs/sehhati-app/sehhati-app-utils";

export const RootRoutes: FC = () => {
  const auth = {
    error: "",
    isLoading: false,
  };
  const { isAuthenticated } = useAuth();

  if (auth.isLoading) {
    return <FallbackComponent />;
  }

  if (auth.error) {
    console.log("auth.error", auth.error);
    return <FallbackComponent />;
  }

  return (
    <Routes>
      <Route path='404' element={<PageNotFound />} />
      <Route path='soon' element={<PageUnderConstruction />} />
      <Route path='current' element={<MyDigitalTwin />} />
      <Route path='login' element={<LoginPage />} />
      <Route path={baseArticlesRoute + "/*"} element={<ArticlesRoutes />} />
      {isAuthenticated && <Route path='/*' element={<LoggedInRoutes />} />}
    </Routes>
  );
};

export const LoggedInRoutes: FC = () => {
  const theme = useTheme();
  const userProfileVarValue = useReactiveVar(userProfileVar);
  useEffect(() => {
    localStorage.setItem(localStorageKeys.digitalTwinUserProfile, JSON.stringify(userProfileVarValue));
  }, [userProfileVarValue]);
  return (
    <HeaderProvider>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          width: "100%",
          "--height-fallback": "100vh",
          "--height-modern": "100dvh",
          height: "var(--height-modern, var(--height-fallback))",
          overflowY: "auto",
        }}
      >
        {/* <PageHeader /> */}
        <Container
          disableGutters
          maxWidth='sm'
          sx={{
            height: isInSehhatiApp() ? "100vh" : "calc(100dvh - 50px)",
            position: "relative",
            overflowY: "auto",
          }}
        >
          <Routes>
            <Route path={routesPaths.myDigitalTwin} Component={MyDigitalTwin} />
            <Route path={routesPaths.myDigitalTwinIncomplete} Component={InCompleteProfilePage} />
            <Route path={routesPaths.generatingYourDigitalTwin} Component={GeneratingDigitalTwinInProgressCountDownPage} />
            <Route path={routesPaths.myDigitalTwinUpdateProfile} element={<MyDigitalTwinUpdateProfilePage />} />
            <Route path={routesPaths.myDigitalTwinHome} element={<GeneratedPage />} />
            <Route path={routesPaths.diseaseAndRiskManagement.replace("/", "")} element={<DiseaseRiskAndManagement />} />
            <Route path={routesPaths.ageDynamicsAndCalculators + "/*"} element={<AgeDynamicsAndCalculatorsRoutes />} />
            <Route path={routesPaths.healthAndWellBeing} element={<HealthAndWellBeing />} />
            <Route path={routesPaths.BMI} element={<BMI />} />

            <Route path='/home' element={<MyDigitalTwin />} />
            <Route path='/' element={<MyDigitalTwin />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Container>
      </Box>
    </HeaderProvider>
  );
};
