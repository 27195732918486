import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { Gender } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import {
  AgeField,
  GenderField,
  HdlCholesterolField,
  IsSmokerField,
  SystolicBpField,
  TotalCholesterolField,
  TreatedForBpField,
} from "../../fields";

const IFraminghamRiskCalculatorFormZodSchema = z.object({
  age: z.number().int().min(0).max(120),
  gender: z.nativeEnum(Gender),
  hdlCholesterol: z.number().min(0).max(100),
  smoker: z.boolean(),
  systolicBp: z.number().min(50).max(300),
  totalCholesterol: z.number().min(100).max(500),
  treatedForBp: z.boolean(),
});

export type IFraminghamRiskCalculatorFormZodSchemaType = z.infer<typeof IFraminghamRiskCalculatorFormZodSchema>;
export interface IFraminghamRiskCalculatorFormRef {
  isValid: boolean;
  formMethods: ReturnType<typeof useForm<IFraminghamRiskCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const FraminghamRiskCalculatorForm = forwardRef<
  IFraminghamRiskCalculatorFormRef,
  {
    onSubmit: (data: IFraminghamRiskCalculatorFormZodSchemaType) => void;
    onChange?: (data: IFraminghamRiskCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const profileData = useReactiveVar(userProfileVar);
  const methods = useForm<IFraminghamRiskCalculatorFormZodSchemaType>({
    resolver: zodResolver(IFraminghamRiskCalculatorFormZodSchema),
    defaultValues: {
      age: profileData.age,
      gender: profileData.gender,
      hdlCholesterol: profileData.hdlCholesterol,
      smoker: profileData.smoker,
      systolicBp: profileData.systolicBp,
      totalCholesterol: profileData.totalCholesterol,
      treatedForBp: profileData.treatedForBp,
    },
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });
  methods.watch(d => props.onChange?.(d as IFraminghamRiskCalculatorFormZodSchemaType));

  useImperativeHandle(
    ref,
    () => ({
      isValid: methods.formState.isValid,
      formMethods: methods,
      handleSubmit,
    }),
    [methods, methods.formState.isValid]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <AgeField />
        <GenderField />
        <IsSmokerField />
        <TreatedForBpField />
        <TotalCholesterolField />
        <HdlCholesterolField />
        <SystolicBpField />
      </form>
    </FormProvider>
  );
});
