import React from "react";
import { Box } from "@toolkit/ui";
import ServiceListItem from "./ServiceListItem";
import { JoinCare } from "../JoinCare/JoinCare";
import { ServiceListItemProps } from "./types";

interface ServiceListProps {
  items: ServiceListItemProps[];
}

export const ServiceList: React.FC<ServiceListProps> = ({ items }) => {
  return (
    <>
      <Box display='flex' flexDirection='column' marginTop='15px'>
        {items.map(item => (
          <ServiceListItem key={item.id} {...item} />
        ))}
      </Box>
      <Box padding='15px'>
        <JoinCare />
      </Box>
    </>
  );
};
