import { BackButton } from "@/components";
import { LifeExpectancyCard } from "@/domains/calculators/life-expectancy-calculator/LifeExpectancyCard/LifeExpectancyCard";
import { LifeExpectancyFormCard } from "@/domains/calculators/life-expectancy-calculator/LifeExpectancyForm/LifeExpectancyForm";
import { lifeExpectancyTabs } from "@/domains/calculators/life-expectancy-calculator/LifeExpectancyForm/lifeExpectancyTabs";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { getLifeExpectancyQueryInput, setDigitalTwinUserProfileToLocalStorage } from "@/domains/MyDigitalTwinProfile/utils";
import { useLifeExpectancyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { useHeaderContext } from "@/providers";
import { routesPaths } from "@/routing/routesPaths";
import { useReactiveVar } from "@apollo/client";
import { Box, Button } from "@toolkit/ui";
import { mapValues } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMyDigitalTwinUpdateProfilePageStyles } from "./MyDigitalTwinUpdateProfilePageStyles";

export const MyDigitalTwinUpdateProfilePage: FC = () => {
  const [selectedTab, setSelected] = useState(0);
  const [nextStatus, setNextStatus] = useState(false);
  const { t } = useTranslation("digital-twin");
  const { classes } = useMyDigitalTwinUpdateProfilePageStyles();

  const { data: lifeExpectancyData, fetchMore } = useLifeExpectancyQuery({});
  const lifeExpectancy = lifeExpectancyData?.lifeExpectancy?.lifeExpectancy;
  const averageAge = lifeExpectancyData?.lifeExpectancy?.averageAge;
  const previousFilled = useReactiveVar(userProfileVar);
  const navigate = useNavigate();
  const form = useForm();
  const { setHeader, resetHeader, setIcon } = useHeaderContext();

  function mapBoolean(value: unknown) {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    } else {
      return value;
    }
  }

  const handleBackClick = useCallback(() => {
    if (selectedTab > 0) {
      setSelected(prevTab => prevTab - 1);
    } else {
      navigate(routesPaths.myDigitalTwin);
    }
  }, [selectedTab]);

  useEffect(() => {
    setHeader(t("Complete my profile"));
    setIcon(<BackButton onClick={handleBackClick} />);
    return () => {
      resetHeader();
    };
  }, [selectedTab, t]);

  const { handleSubmit, setValue } = form;
  useEffect(() => {
    previousFilled &&
      Object.keys(previousFilled).forEach(key => {
        setValue(key, mapBoolean(previousFilled[key]));
      });
  }, []);

  const onNextClick = (data: { [x: string]: unknown }) => {
    const _data = mapValues(data, value => {
      if (value === "Yes") return true;
      if (value === "No") return false;
      return value;
    });
    fetchMore({
      variables: { input: getLifeExpectancyQueryInput(_data) },
      updateQuery(_, { fetchMoreResult }) {
        return fetchMoreResult;
      },
    });
    if (selectedTab < lifeExpectancyTabs.length - 1) {
      setSelected(selectedTab + 1);
    }
    if (selectedTab === lifeExpectancyTabs.length - 1) {
      setDigitalTwinUserProfileToLocalStorage(_data);
      userProfileVar(_data);
      navigate(routesPaths.generatingYourDigitalTwin);
    }
  };

  const activeButton = () => {
    setNextStatus(true);
  };
  const handleGoBack = () => {
    if (selectedTab > 0) {
      setSelected(prevTab => prevTab - 1);
    } else {
      navigate(routesPaths.myDigitalTwin);
    }
  };

  return (
    <Box className={classes.container}>
      <FormProvider {...form}>
        <form className={classes.form}>
          <Box className={classes.formContent}>
            <LifeExpectancyCard lifeExpectancy={lifeExpectancy!} averageAge={averageAge!} />
            <LifeExpectancyFormCard activeButton={activeButton} index={selectedTab} />
          </Box>
        </form>
      </FormProvider>
      <Box className={classes.footer}>
        <Box className={classes.footerContent}>
          <Button className={classes.skipButton} onClick={handleGoBack}>
            {t("Back")}
          </Button>
          <Button variant='outlined' className={classes.nextButton} disabled={!nextStatus} onClick={handleSubmit(onNextClick)}>
            {t("Next")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
