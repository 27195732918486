import { baseRoute as DynamicCalcRoutesBaseRoute } from "@/pages/age-dynamics-and-calculators/routes";

export const routesPaths = {
  home: "/home",
  login: "/login",
  myDigitalTwin: "/my-digital-twin",
  myDigitalTwinHome: "/my-digital-twin/home",
  myDigitalTwinIncomplete: "/my-digital-twin/incomplete",
  myDigitalTwinUpdateProfile: "/my-digital-twin/update-profile",
  generatingYourDigitalTwin: "/generating-your-digital-twin",
  diseaseAndRiskManagement: "/disease-and-risk-management",
  healthAndWellBeing: "/health-and-well-being",
  get ageDynamicsAndCalculators() {
    return DynamicCalcRoutesBaseRoute;
  },
  changeLanguage: "/change-language",
  getHelp: "/get-help",
  accountSettings: "/account-settings",
  BMI: "/bmi",
};
