/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IdealBodyWeightQueryVariables = Types.Exact<{
  input: Types.IdealBodyWeightInput;
  saveData?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type IdealBodyWeightQuery = { __typename?: 'Query', idealBodyWeight?: { __typename?: 'IdealBodyWeightResponse', idealBodyWeight?: number | null } | null };


export const IdealBodyWeightDocument = gql`
    query IdealBodyWeight($input: IdealBodyWeightInput!, $saveData: Boolean) {
  idealBodyWeight(input: $input, saveData: $saveData) {
    idealBodyWeight
  }
}
    `;

/**
 * __useIdealBodyWeightQuery__
 *
 * To run a query within a React component, call `useIdealBodyWeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdealBodyWeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdealBodyWeightQuery({
 *   variables: {
 *      input: // value for 'input'
 *      saveData: // value for 'saveData'
 *   },
 * });
 */
export function useIdealBodyWeightQuery(baseOptions: Apollo.QueryHookOptions<IdealBodyWeightQuery, IdealBodyWeightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdealBodyWeightQuery, IdealBodyWeightQueryVariables>(IdealBodyWeightDocument, options);
      }
export function useIdealBodyWeightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdealBodyWeightQuery, IdealBodyWeightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdealBodyWeightQuery, IdealBodyWeightQueryVariables>(IdealBodyWeightDocument, options);
        }
export type IdealBodyWeightQueryHookResult = ReturnType<typeof useIdealBodyWeightQuery>;
export type IdealBodyWeightLazyQueryHookResult = ReturnType<typeof useIdealBodyWeightLazyQuery>;
export type IdealBodyWeightQueryResult = Apollo.QueryResult<IdealBodyWeightQuery, IdealBodyWeightQueryVariables>;