import CompletePercentage from "@/components/Progress/CompletePercentage";
import { EducationField } from "@/domains/calculators/fields";
import { getColorBaseOnScore } from "@/domains/MyDigitalTwinProfile/utils";
import { Box, useTheme } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useFormStyles } from "./formStyles";

export const LifeEventsForm: React.FC<{ activeButton: () => void }> = ({ activeButton }) => {
  const theme = useTheme();
  const { classes } = useFormStyles();

  const formFields = ["educationLevel"];
  const { control } = useFormContext();
  const values = useWatch({
    control: control,
    name: formFields,
  });

  const filledCount =
    values.reduce((acc: number, value: number) => {
      return value ? acc + 1 : acc;
    }, 0) * 100;

  const count = formFields.length;
  const isFilledCountEqualToCount = filledCount === count;

  useEffect(() => {
    activeButton();
  }, [isFilledCountEqualToCount]);

  return (
    <>
      <CompletePercentage color={getColorBaseOnScore(filledCount / count, theme)} value={filledCount / count} />
      <Box className={classes.box}>
        <EducationField />
      </Box>
    </>
  );
};
