import { Gender } from "@/schema/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { GenderField, HeightField } from "../../fields";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useReactiveVar } from "@apollo/client";

const IIdealBodyWeightCalculatorFormZodSchema = z.object({
  gender: z.nativeEnum(Gender),
  height: z.number().min(100).max(300),
});

export type IIdealBodyWeightCalculatorFormZodSchemaType = z.infer<typeof IIdealBodyWeightCalculatorFormZodSchema>;
export interface IIdealBodyWeightCalculatorFormRef {
  formMethods: ReturnType<typeof useForm<IIdealBodyWeightCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const IdealBodyWeightCalculatorForm = forwardRef<
  IIdealBodyWeightCalculatorFormRef,
  {
    onSubmit: (data: IIdealBodyWeightCalculatorFormZodSchemaType) => void;
    onChange?: (data: IIdealBodyWeightCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const profileData = useReactiveVar(userProfileVar);
  const methods = useForm<IIdealBodyWeightCalculatorFormZodSchemaType>({
    resolver: zodResolver(IIdealBodyWeightCalculatorFormZodSchema),
    defaultValues: { gender: profileData.gender, height: profileData.height },
    mode: "all",
  });

  methods.watch(data => {
    props.onChange?.(data as IIdealBodyWeightCalculatorFormZodSchemaType);
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });

  useImperativeHandle(ref, () => ({
    formMethods: methods,
    handleSubmit,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <HeightField min={100} />
        <GenderField />
      </form>
    </FormProvider>
  );
});
