import { FormRadio, FormSlider, ValueLabelComponent } from "@/components";
import { LabeledInputContainer } from "@/components/LabeledInputContainer";
import RadioGroupField from "@/components/RadioGroupField";
import i18n, { useTranslation } from "@/i18n/i18n.config";
import {
  activityOptions,
  anxietyFrequencyOptions,
  BooleanYesNo,
  communityOptions,
  depressionFrequencyOptions,
  dietQualityOptions,
  educationsOptions,
  genderOptions,
  physicalActivityOptions,
  smokingHabitOptions,
  stressLevelOptions,
  whatIsTheGivenTimeOptions,
} from "./constants";
import { useFormStyles } from "./formStyles";
import { FC } from "react";
import { Box, FormStaticTimeField, useTheme } from "@toolkit/ui";

export const WeightField: React.FC<{ title?: string }> = ({ title }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={title || t("Weight")}>
      <FormSlider
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("kg") })}
        name={"weight"}
        valueLabelDisplay='on'
        defaultValue={0}
        min={0}
        max={200}
      />
    </LabeledInputContainer>
  );
};

export const DaysField: React.FC<{ title?: string }> = ({ title }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={title || t("Days")}>
      <FormSlider
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("days") })}
        name={"days"}
        valueLabelDisplay='on'
        defaultValue={0}
        min={1}
        max={300}
      />
    </LabeledInputContainer>
  );
};

export const BeeField: React.FC = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={t("Bee")}>
      <FormSlider
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("bee") })}
        name={"bee"}
        valueLabelDisplay='on'
        defaultValue={0}
        min={1}
        max={10000}
      />
    </LabeledInputContainer>
  );
};

export const HeightField: React.FC<{ min?: number }> = ({ min = 100 }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={t("Height")}>
      <FormSlider
        name='height'
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("cm") })}
        valueLabelDisplay='on'
        defaultValue={0}
        min={min}
        max={250}
      />
    </LabeledInputContainer>
  );
};

export const AgeField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={t("Age")}>
      <FormSlider
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("years") })}
        name={"age"}
        valueLabelDisplay='on'
        defaultValue={0}
        min={0}
        max={120}
        isRtl
      />
    </LabeledInputContainer>
  );
};
export const TimeField: FC<{ title?: string }> = ({ title }) => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();
  return (
    <LabeledInputContainer label={title || t("Time")}>
      <Box className={classes.timeFieldContainer}>
        <FormStaticTimeField
          name='time'
          timePickerProps={{
            mask: "__:__",
            inputFormat: "HH:mm",
            views: ["hours", "minutes"],
            toolbarTitle: "",
            orientation: "landscape",
          }}
        />
      </Box>
    </LabeledInputContainer>
  );
};

export const GenderField = () => {
  const { t } = useTranslation("digital-twin");

  return <RadioGroupField name='gender' label={t("Gender")} options={genderOptions} />;
};

export const FruitAndVegetableConsumptionField = () => {
  const { t } = useTranslation("digital-twin");
  const theme = useTheme();
  return (
    <LabeledInputContainer label={t("Fruit And Vegetable Consumption")}>
      <FormSlider
        valueLabelFormat={value =>
          ValueLabelComponent({
            value,
            unit: t("servings per day"),
            flexDirection: "column",
            gap: 0,
            justifyContent: "start",
            labelProps: {
              marginBottom: "15px",
              fontSize: theme.mixins.fonts.fontSize.xs,
              fontWeight: theme.mixins.fonts.fontWeight.medium,
            },
          })
        }
        name={"fruitAndVegetableConsumptionPerDay"}
        valueLabelDisplay='on'
        defaultValue={0}
        min={0}
        max={10}
      />
    </LabeledInputContainer>
  );
};

export const DietQualityField = () => {
  const { t } = useTranslation("digital-twin");

  const { classes } = useFormStyles();
  return (
    <LabeledInputContainer mode='vertical' label={t("Diet Quality")}>
      <FormRadio radioGroupProps={{ className: classes.radioGroup }} options={dietQualityOptions} name='dietQuality' />
    </LabeledInputContainer>
  );
};

export const PhysicalActivityField = () => {
  const { t } = useTranslation("digital-twin");

  return <RadioGroupField label={t("Physical Activity")} options={physicalActivityOptions} name='physicalActivity' mode='vertical' />;
};

export const DoYouHaveDiabetesField = () => {
  const { t } = useTranslation("digital-twin");
  return <RadioGroupField label={t("Do you Have Diabetes")} options={BooleanYesNo} labelPlacement='bottom' name='hasDiabetes' />;
};

export const DoYouHaveHeartDiseaseField = () => {
  const { t } = useTranslation("digital-twin");
  return <RadioGroupField label={t("Do you Have Heart Disease")} options={BooleanYesNo} labelPlacement='bottom' name='hasHeartDisease' />;
};
export const DoYouHaveHyperlipidemiaField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <RadioGroupField label={t("Do you Have Hyperlipidemia")} options={BooleanYesNo} labelPlacement='bottom' name='hasHyperlipidemia' />
  );
};

export const DoYouHaveHypertensionField = () => {
  const { t } = useTranslation("digital-twin");
  return <RadioGroupField label={t("Do you Have Hypertension")} options={BooleanYesNo} labelPlacement='bottom' name='hasHypertension' />;
};
export const DoYouHaveKidneyDiseaseField = () => {
  const { t } = useTranslation("digital-twin");

  return <RadioGroupField label={t("Do you Have Kidney Disease")} options={BooleanYesNo} labelPlacement='bottom' name='hasKidneyDisease' />;
};
export const ActivityLevelField: FC<{ title?: string }> = ({ title = i18n.t("Activity Level", { ns: "digital-twin" }) }) => {
  const { classes } = useFormStyles();

  return (
    <RadioGroupField
      label={title}
      options={activityOptions}
      name='activityLevel'
      mode='vertical'
      labelPlacement='end'
      classes={{
        radioGroup: classes.multiLineRadioOptions,
      }}
    />
  );
};
export const EducationField = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();

  return (
    <RadioGroupField
      label={t("What is your education level?")}
      classes={{
        radioGroup: classes.longOption,
      }}
      options={educationsOptions}
      name='educationLevel'
      mode='vertical'
      labelPlacement='end'
    />
  );
};
export const CommunityField = () => {
  const { t } = useTranslation("digital-twin");
  return <RadioGroupField label={t("Community")} options={communityOptions} name='community' />;
};

export const IsDoingPhysicalActivitiesField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <RadioGroupField
      label={t("Are you physically active?")}
      options={BooleanYesNo}
      labelPlacement='bottom'
      name='hasPhysicalActivities'
      mode='vertical'
    />
  );
};
export const IsSmokerField = () => {
  const { t } = useTranslation("digital-twin");

  return <RadioGroupField label={t("Are You a Smoker?")} options={BooleanYesNo} labelPlacement='bottom' name='smoker' />;
};
export const HasBloodPressureField = () => {
  const { t } = useTranslation("digital-twin");

  return (
    <RadioGroupField
      label={t("Have you been diagnosed with high blood pressure (Hypertension)?")}
      options={BooleanYesNo}
      labelPlacement='bottom'
      name='hasBloodPressure'
      mode='vertical'
    />
  );
};
export const HasGestationalDiabetesField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <RadioGroupField
      label={t("Have you ever been diagnosed with gestational diabetes?")}
      options={BooleanYesNo}
      labelPlacement='bottom'
      name='hasGestationalDiabetes'
      mode='vertical'
    />
  );
};
export const HasDiabetesFamilyHistoryField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <RadioGroupField
      label={t("Do you have a parent or sibling with diabetes?")}
      options={BooleanYesNo}
      labelPlacement='bottom'
      name='hasFamilyHistory'
      mode='vertical'
    />
  );
};
export const BasedOnSleepTimeField = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();
  return (
    <RadioGroupField
      label={t("Do you want to calculate based on?")}
      options={whatIsTheGivenTimeOptions}
      name='basedOnSleepTime'
      mode='vertical'
      labelPlacement='end'
      classes={{
        radioGroup: classes.longOption,
      }}
    />
  );
};

export const SmokingHabitField = () => {
  const { t } = useTranslation("digital-twin");
  return <RadioGroupField label={t("Smoking Habit")} options={smokingHabitOptions} name='smokingHabit' />;
};

export const StressLevelField = () => {
  const { t } = useTranslation("digital-twin");
  return <RadioGroupField label={t("Stress Level")} options={stressLevelOptions} name='stressLevel' labelPlacement='end' />;
};
export const FeelingNervousField = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();
  return (
    <RadioGroupField
      label={t("How often have you been feeling nervous or anxious:")}
      options={anxietyFrequencyOptions}
      name='feelingNervous'
      labelPlacement='end'
      mode='vertical'
      classes={{
        radioGroup: classes.longOption,
      }}
    />
  );
};
export const NotBeingAbleToStopOrControlWorryingField = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();

  return (
    <RadioGroupField
      label={t("How often have you felt that you can’t stop or control your worrying")}
      options={anxietyFrequencyOptions}
      name='notBeingAbleToStopOrControlWorrying'
      mode='vertical'
      labelPlacement='end'
      classes={{
        radioGroup: classes.longOption,
      }}
    />
  );
};

export const FeelingDownField = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();

  return (
    <RadioGroupField
      label={t("How often have you felt down, depressed, or hopeless?")}
      options={depressionFrequencyOptions}
      name='feelingDown'
      mode='vertical'
      labelPlacement='end'
      classes={{
        radioGroup: classes.longOption,
      }}
    />
  );
};
export const LittleInterestField = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();
  return (
    <RadioGroupField
      label={t("How often have you felt little interest or pleasure in doing things?")}
      options={depressionFrequencyOptions}
      name='littleInterest'
      mode='vertical'
      labelPlacement='end'
      classes={{
        radioGroup: classes.longOption,
      }}
    />
  );
};

export const HdlCholesterolField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={t("HDL Cholesterol")}>
      <FormSlider
        name={"hdlCholesterol"}
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("mg/dL") })}
        valueLabelDisplay='on'
        defaultValue={0}
        min={0}
        max={100}
        isRtl
      />
    </LabeledInputContainer>
  );
};

export const TotalCholesterolField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={t("total Cholesterol")}>
      <FormSlider
        name={"totalCholesterol"}
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("mg/dL") })}
        valueLabelDisplay='on'
        defaultValue={0}
        min={100}
        max={500}
        isRtl
      />
    </LabeledInputContainer>
  );
};

export const SystolicBpField = () => {
  const { t } = useTranslation("digital-twin");
  return (
    <LabeledInputContainer label={t("systolic Blood Pressure")}>
      <FormSlider
        name={"systolicBp"}
        valueLabelFormat={value => ValueLabelComponent({ value, unit: t("mmHg") })}
        valueLabelDisplay='on'
        defaultValue={0}
        min={80}
        max={300}
        isRtl
      />
    </LabeledInputContainer>
  );
};

export const TreatedForBpField = () => {
  const { t } = useTranslation("digital-twin");

  return (
    <RadioGroupField
      label={t("Are you currently being treated for high blood pressure (hypertension)?")}
      options={BooleanYesNo}
      labelPlacement='bottom'
      name='treatedForBp'
      mode='vertical'
    />
  );
};
