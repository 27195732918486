/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LifeExpectancyQueryVariables = Types.Exact<{
  input: Types.LifeExpectancyInput;
}>;


export type LifeExpectancyQuery = { __typename?: 'Query', lifeExpectancy?: { __typename?: 'LifeExpectancyResponse', averageAge?: number | null, lifeExpectancy?: number | null } | null };


export const LifeExpectancyDocument = gql`
    query LifeExpectancy($input: LifeExpectancyInput!) {
  lifeExpectancy(input: $input) {
    averageAge
    lifeExpectancy
  }
}
    `;

/**
 * __useLifeExpectancyQuery__
 *
 * To run a query within a React component, call `useLifeExpectancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLifeExpectancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLifeExpectancyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLifeExpectancyQuery(baseOptions: Apollo.QueryHookOptions<LifeExpectancyQuery, LifeExpectancyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LifeExpectancyQuery, LifeExpectancyQueryVariables>(LifeExpectancyDocument, options);
      }
export function useLifeExpectancyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LifeExpectancyQuery, LifeExpectancyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LifeExpectancyQuery, LifeExpectancyQueryVariables>(LifeExpectancyDocument, options);
        }
export type LifeExpectancyQueryHookResult = ReturnType<typeof useLifeExpectancyQuery>;
export type LifeExpectancyLazyQueryHookResult = ReturnType<typeof useLifeExpectancyLazyQuery>;
export type LifeExpectancyQueryResult = Apollo.QueryResult<LifeExpectancyQuery, LifeExpectancyQueryVariables>;