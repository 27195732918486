import { Box, CircularProgress, CircularProgressProps, useTheme } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";

export interface CircularProgressWithValueProps extends Omit<CircularProgressProps, "value" | "color"> {
  value: number;
  color?: string;
}

export const CircularProgressPercentage: FC<PropsWithChildren<CircularProgressWithValueProps>> = ({ value, color, children, ...rest }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      <CircularProgress
        variant='determinate'
        value={100}
        size={50}
        thickness={6}
        {...rest}
        sx={{ color: theme.palette.primary[50], ...rest?.sx }}
      />
      <Box top={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress
          size={50}
          thickness={6}
          variant='determinate'
          value={value}
          sx={{
            color: color,
            strokeLinecap: "round",
          }}
          {...rest}
        />
        <Box top={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
