/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDigitalTwinHealthProfileSleepResultQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDigitalTwinHealthProfileSleepResultQuery = { __typename?: 'Query', getDigitalTwinHealthProfile?: { __typename?: 'DigitalTwinHealthProfile', sleepResults?: { __typename?: 'SleepResultConnection', edges: Array<{ __typename?: 'SleepResultEdge', node: { __typename?: 'SleepResult', time?: any | null, sleepCycles?: number | null, queryInput?: { __typename?: 'SleepInputLog', age: number, time: any, basedOnSleepTime: boolean } | null } }> } | null } | null };


export const GetDigitalTwinHealthProfileSleepResultDocument = gql`
    query getDigitalTwinHealthProfileSleepResult {
  getDigitalTwinHealthProfile {
    sleepResults(first: 1, sortBy: {field: CREATED, direction: DESC}) {
      edges {
        node {
          time
          sleepCycles
          queryInput {
            age
            time
            basedOnSleepTime
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDigitalTwinHealthProfileSleepResultQuery__
 *
 * To run a query within a React component, call `useGetDigitalTwinHealthProfileSleepResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalTwinHealthProfileSleepResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalTwinHealthProfileSleepResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDigitalTwinHealthProfileSleepResultQuery(baseOptions?: Apollo.QueryHookOptions<GetDigitalTwinHealthProfileSleepResultQuery, GetDigitalTwinHealthProfileSleepResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDigitalTwinHealthProfileSleepResultQuery, GetDigitalTwinHealthProfileSleepResultQueryVariables>(GetDigitalTwinHealthProfileSleepResultDocument, options);
      }
export function useGetDigitalTwinHealthProfileSleepResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDigitalTwinHealthProfileSleepResultQuery, GetDigitalTwinHealthProfileSleepResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDigitalTwinHealthProfileSleepResultQuery, GetDigitalTwinHealthProfileSleepResultQueryVariables>(GetDigitalTwinHealthProfileSleepResultDocument, options);
        }
export type GetDigitalTwinHealthProfileSleepResultQueryHookResult = ReturnType<typeof useGetDigitalTwinHealthProfileSleepResultQuery>;
export type GetDigitalTwinHealthProfileSleepResultLazyQueryHookResult = ReturnType<typeof useGetDigitalTwinHealthProfileSleepResultLazyQuery>;
export type GetDigitalTwinHealthProfileSleepResultQueryResult = Apollo.QueryResult<GetDigitalTwinHealthProfileSleepResultQuery, GetDigitalTwinHealthProfileSleepResultQueryVariables>;