import { makeStyles } from "@toolkit/ui";

export const useServiceListItemStyles = makeStyles<{ color: "error" | "warning" }>()((theme, { color }) => ({
  listItem: {
    height: "79px",
    display: "flex",
    justifyContent: "start",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary[50],
    alignItems: "center",
    width: "calc(100% - 10px)",
  },
  iconContainer: {
    paddingInlineStart: "15px",
    "& svg": {
      width: "38px",
      height: "38px",
    },
  },
  statusIndicator: {
    marginInlineStart: "12px",
  },
  statusDot: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: theme.palette[color].main,
    border: "2px solid",
    borderColor: theme.palette[color][200],
  },
  titleContainer: {
    "& svg": {
      width: "15px",
      height: "15px",
      margin: "0px",
      fill: theme.palette.primary[600],
    },
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginInlineStart: "10px",
    color: theme.palette[color].main,
    display: "inline",
  },
  buttonContainer: {
    marginInlineStart: "auto",
  },
  button: {
    width: "170px",
    height: "40px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    borderRadius: "20px",
    fontWeight: "400",
  },
}));
