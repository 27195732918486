import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useReactiveVar } from "@apollo/client";
import { Box, Button, Card, Typography } from "@toolkit/ui";
import { TimePickerSheet } from "@/domains/calculators/sleep-time/time-picker/TimePickerSheet";
import { useTranslation } from "react-i18next";
import { useSleepTimeCalculatorFormStyle } from "./useSleepTimeCalculatorFormStyle";
import { FormSlider } from "@/components";
import { LabeledInputContainer } from "@/components/LabeledInputContainer";
import { ICalculatorSleepSelectedTime } from "@/domains/calculators/sleep-time/types";

export type ISleepTimeFormEvent =
  | {
      type: "TIME_CHANGE";
      payload: {
        name: string;
        value: string;
      };
    }
  | {
      type: "SUBMIT";
      payload: {
        values: ISleepTimeFormZodSchemaType;
      };
    };

const ISleepTimeFormZodSchema = z.object({
  age: z.number().int().min(0).max(120),
  basedOnSleepTime: z.boolean(),
});

export type ISleepTimeFormZodSchemaType = z.infer<typeof ISleepTimeFormZodSchema>;

type SleepTimeFormProps = {
  selectedTime: ICalculatorSleepSelectedTime;
  formValues?: ISleepTimeFormZodSchemaType;
  onChange: (event: ISleepTimeFormEvent) => void;
};

export const SleepTimeForm: FC<SleepTimeFormProps> = props => {
  const { formValues, selectedTime, onChange } = props;

  const { t } = useTranslation("digital-twin");
  const { classes } = useSleepTimeCalculatorFormStyle();

  const profileData = useReactiveVar(userProfileVar);

  const form = useForm<ISleepTimeFormZodSchemaType>({
    mode: "all",
    resolver: zodResolver(ISleepTimeFormZodSchema),
    defaultValues: {
      age: profileData.age,
      basedOnSleepTime: false,
    },
  });

  const { watch, setValue, handleSubmit } = form;

  const basedOnSleepTime = watch("basedOnSleepTime");

  const onSubmit = () => {
    handleSubmit(values => {
      onChange({
        type: "SUBMIT",
        payload: { values },
      });
    })();
  };

  const onSleepTimeButtonClick = () => {
    setValue("basedOnSleepTime", true);
  };

  const onWakeupTimeButtonClick = () => {
    setValue("basedOnSleepTime", false);
  };

  const onTimePickerSheetChange = (name: string, value: string) => {
    onChange({
      type: "TIME_CHANGE",
      payload: { name, value },
    });
  };

  useEffect(() => {
    if (formValues) {
      setValue("age", formValues.age);
      setValue("basedOnSleepTime", formValues.basedOnSleepTime);
    }
  }, [formValues, setValue]);

  return (
    <FormProvider {...form}>
      <LabeledInputContainer label={`${t("Age")} (${t("Years")})`}>
        <FormSlider name={"age"} valueLabelDisplay={"on"} min={0} max={100} />
      </LabeledInputContainer>

      <Card style={{ padding: 16 }}>
        <Typography className={classes.calculateBasedOnLabel}>{t("Do you want to calculate based on?")}</Typography>

        <Box className={classes.buttonsWrapper}>
          <Button variant={basedOnSleepTime ? "contained" : "outlined"} className={classes.button} onClick={onSleepTimeButtonClick}>
            {t("Sleep Time")}
          </Button>

          <Button variant={basedOnSleepTime ? "outlined" : "contained"} className={classes.button} onClick={onWakeupTimeButtonClick}>
            {t("Wake Up Time")}
          </Button>
        </Box>

        <Box marginBlock={4}>
          <Typography className={classes.whenSleepWakeUpLabel}>
            {basedOnSleepTime ? t("When do you usually go to sleep?") : t("When do you usually wake up?")}
          </Typography>

          <TimePickerSheet value={selectedTime} onChange={onTimePickerSheetChange} />
        </Box>
      </Card>

      <Box style={{ marginBlock: 20 }}>
        <Button fullWidth onClick={onSubmit}>
          {" "}
          {t("Show Results")}{" "}
        </Button>
      </Box>
    </FormProvider>
  );
};
