import { makeStyles } from "@toolkit/ui";

export const useGeneratedPageStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
    marginInline: 16,
    paddingBlock: 8,
  },
  card: {
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(3),
  },
  healthStatusBox: {
    width: "100%",
    height: "350px",
  },
  underlineText: {
    textDecoration: "underline",
    textAlign: "end",
  },
  disclaimerText: {
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.xxs,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "center",
  },
}));
