import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { articleRoutes } from "./routes";

export const ArticlesRoutes: FC = () => {
  return (
    <Routes>
      <Route path={articleRoutes.articleList.route} Component={articleRoutes.articleList.component} />
      <Route path={articleRoutes.articleDetails.route} Component={articleRoutes.articleDetails.component} />
      <Route path={"/*"} element={<h3> No Such Articles </h3>} />
    </Routes>
  );
};
