import { useBodyFatPercentageLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  BodyFatPercentageCalculatorForm,
  IBodyFatPercentageCalculatorFormRef,
  IBodyFatPercentageCalculatorFormZodSchemaType,
} from "./body-fat-percentage-calculator-form/BodyFatPercentageCalculatorForm";
import { BodyFatPercentageResultCard } from "./body-fat-percentage-results/BodyFatPercentageResultCard";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";

export const BodyFatPercentageCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();
  const ref = useRef<IBodyFatPercentageCalculatorFormRef>(null);
  const doSubmit = debounce(() => ref.current?.handleSubmit(), 500);
  const [fetchBodyFatPercentageResult, { loading, data }] = useBodyFatPercentageLazyQuery({});
  const result = data?.bodyFatPercentage?.bodyFatPercentage;
  const handleSubmit = (data: IBodyFatPercentageCalculatorFormZodSchemaType) => {
    fetchBodyFatPercentageResult({
      variables: {
        input: {
          age: data.age,
          gender: data.gender,
          height: data.height,
          weight: data.weight,
        },
      },
    });
  };

  useEffect(() => {
    ref.current?.formMethods.watch();
    doSubmit();
  }, []);

  return (
    <CalculatorsPageLayout
      title={t("Body Fat Percentage Calculator")}
      footer={
        <DefaultFooter
          isSubmitDisabled={!ref.current?.formMethods.formState.isValid || loading}
          handleSubmit={() => navigate(routesPaths.myDigitalTwin)}
        />
      }
    >
      <BodyFatPercentageResultCard percentage={result ?? 0} isDataReady={result !== undefined} isLoading={loading} />
      <BodyFatPercentageCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={handleSubmit} />
    </CalculatorsPageLayout>
  );
};
