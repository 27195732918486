import { makeStyles } from "@toolkit/ui";

const useStyles = makeStyles()(theme => ({
  card: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    padding: "14px",
    justifyContent: "center",
    alignItems: "center",
    height: "180px",
    width: "100%",
    borderRadius: "10px",
  },
  introText: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  boldText: {
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    fontSize: theme.mixins.fonts.fontSize.sm,
    display: "inline",
  },
  infoText: {
    width: "300px",
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "end",
    justifyContent: "center",
    width: "calc(100% - 28px)",
    height: "100%",
  },
  marginTop: {
    width: "100%",
    marginTop: "10px",
  },
}));

export default useStyles;
