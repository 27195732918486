import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useFitnessProfileStyles } from "./useFitnessProfile.styles";
import { StatsItem } from "../StatsItem/StatsItem.tsx";
import { FitnessDataBox } from "../FitnessDataBox/FitnessDataBox.tsx";
import { useTranslation } from "react-i18next";

export const FitnessProfile: React.FC = () => {
  const { classes } = useFitnessProfileStyles();
  const { t } = useTranslation("digital-twin");

  return (
    <>
      <Box className={classes.statsContainer}>
        <FitnessDataBox color='#238BD8' borderColor='#cbe5f7' value='74' label={t("Move Min")} />
        <FitnessDataBox color='#31A369' borderColor='#d5ebe0' value='23' label={t("Heart Pts")} />
      </Box>

      <Box className={classes.statsItemContainer}>
        <StatsItem value='8255' label={t("Steps")} />
        <StatsItem value='1,876' label={t("Cals")} />
        <StatsItem value='2.2' label={t("Miles")} />
      </Box>

      <Typography className={classes.recommendedAction}>{t("Recommended Action")}</Typography>
      <Typography className={classes.recommendation}>{t("Increase to 1 hour/day")}</Typography>
    </>
  );
};
