import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const CheckedRadioIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={25} height={25} viewBox={"0 0 25 25"} {...props}>
      <g id='Group_15880' data-name='Group 15880' transform='translate(-294 -544)'>
        <g id='Group_15879' data-name='Group 15879'>
          <g
            id='Rectangle_10542'
            data-name='Rectangle 10542'
            transform='translate(294 544)'
            fill='#fff'
            stroke={theme.palette.primary.main}
            strokeWidth='1'
          >
            <rect width='25' height='25' rx='8' stroke='none' />
            <rect x='0.5' y='0.5' width='24' height='24' rx='7.5' fill='none' />
          </g>
          <rect
            id='Rectangle_10543'
            data-name='Rectangle 10543'
            width='13'
            height='13'
            rx='4'
            transform='translate(300 550)'
            fill={theme.palette.primary.main}
          />
        </g>
      </g>
    </SvgIcon>
  );
};
