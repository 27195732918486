import { Box, useTheme } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { AgeField, GenderField, HeightField, WeightField } from "@/domains/calculators/fields";
import CompletePercentage from "@/components/Progress/CompletePercentage";
import { useFormStyles } from "./formStyles";
import { getColorBaseOnScore } from "@/domains/MyDigitalTwinProfile/utils";

export const AgeAndPhysiqueForm: React.FC<{ activeButton: () => void }> = ({ activeButton }) => {
  const formFields = ["age", "weight", "height", "gender"];
  const { control } = useFormContext();
  const values = useWatch({
    control: control,
    name: formFields,
  });
  const filledCount =
    values.reduce((acc: number, value: number) => {
      return value ? acc + 1 : acc;
    }, 0) * 100;
  const count = formFields.length;
  const theme = useTheme();
  const isFilledCountEqualToCount = filledCount === count;
  const { classes } = useFormStyles();

  useEffect(() => {
    activeButton();
  }, [isFilledCountEqualToCount]);

  return (
    <>
      <CompletePercentage color={getColorBaseOnScore(filledCount / count, theme)} value={filledCount / count} />
      <Box className={classes.box}>
        <AgeField />
        <WeightField />
        <HeightField />
        <GenderField />
      </Box>
    </>
  );
};
