import { Box, Button, Divider, Typography } from "@toolkit/ui";
import React from "react";
import { ArrowIcon } from "../../Icons";
import { useAuth } from "@/providers/auth";
import { sideMenuDrawerOpenStateVar } from "../sideMenuDrawerOpenStateVar";
import { useTranslation } from "@/i18n/i18n.config";
import useStyles from "./PostLoginList.styles";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { removeDigitalTwinUserProfileToLocalStorage } from "@/domains/MyDigitalTwinProfile/utils";

export const PostLoginList: React.FC = () => {
  const { removeAppToken } = useAuth();
  const { t } = useTranslation("digital-twin");
  const { classes } = useStyles();

  const handleLogout = () => {
    sideMenuDrawerOpenStateVar(false);
    userProfileVar({});
    removeDigitalTwinUserProfileToLocalStorage();
    removeAppToken();
  };

  return (
    <Box className={classes.container}>
      <Button variant='outlined' className={classes.button} onClick={handleLogout} endIcon={<ArrowIcon />}>
        {t("Log out")}
      </Button>
      <Divider className={classes.divider} />
      <Typography className={classes.versionText}>iO health v.1.20</Typography>
    </Box>
  );
};
