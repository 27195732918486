import { useDepressionScreeningLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { RiskLevel } from "@/schema/types";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  DepressionScreeningCalculatorForm,
  IDepressionScreeningCalculatorFormRef,
  IDepressionScreeningCalculatorFormZodSchemaType,
} from "./depression-screening-calculator-form/DepressionScreeningCalculatorForm";
import { DepressionScreeningResultCard } from "./depression-screening-results/DepressionScreeningResultCard";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";
import { Typography } from "@toolkit/ui";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";

export const DepressionScreeningCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();
  const ref = useRef<IDepressionScreeningCalculatorFormRef>(null);
  const doSubmit = debounce(() => ref.current?.handleSubmit(), 500);

  const [fetchDepressionScreening, { loading, data }] = useDepressionScreeningLazyQuery({});
  const riskResult = data?.depressionScreening;
  const handleSubmit = (data: IDepressionScreeningCalculatorFormZodSchemaType) => {
    userProfileVar({
      ...userProfileVar(),
      feelingDown: data.feelingDown,
      littleInterest: data.littleInterest,
    });
    fetchDepressionScreening({
      variables: {
        input: {
          feelingDown: data.feelingDown,
          littleInterest: data.littleInterest,
        },
      },
      onCompleted: resultData => {
        userProfileVar({
          ...userProfileVar(),
          depressionRiskScore: resultData.depressionScreening?.score,
          depressionRisk: resultData.depressionScreening?.riskLevel,
        });
      },
    });
  };

  useEffect(() => {
    ref.current?.formMethods.watch();
    doSubmit();
  }, []);

  return (
    <CalculatorsPageLayout
      title={t("Depression Calculator")}
      footer={
        <DefaultFooter
          isSubmitDisabled={!ref.current?.formMethods.formState.isValid || loading}
          handleSubmit={() => navigate(routesPaths.myDigitalTwin)}
        />
      }
    >
      <DepressionScreeningResultCard
        score={riskResult?.score ?? 0}
        result={riskResult?.riskLevel ?? RiskLevel.Low}
        isDataReady={riskResult !== undefined}
        isLoading={loading}
      />

      <Typography fontSize={12} color='textPrimary' my={1}>
        {t("Please answer based on how you've felt in the past 2 weeks")}
      </Typography>

      <DepressionScreeningCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={handleSubmit} />
    </CalculatorsPageLayout>
  );
};
