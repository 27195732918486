import { makeStyles } from "@toolkit/ui";

export const useMyDigitalTwinUpdateProfilePageStyles = makeStyles()(theme => ({
  container: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formContent: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    flex: 1,
  },
  footer: {
    width: "100%",
    height: "80px",
    position: "sticky",
    bottom: "0px",
    left: "0px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "15px 15px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footerContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "10px",
  },
  nextButton: {
    width: "130px",
    height: "45px",
    backgroundColor: theme.palette.primary[800],
    borderRadius: "28px",
    color: theme.palette.common.white,
  },
  skipButton: {
    width: "130px",
    height: "45px",
    backgroundColor: theme.palette.primary[800],
    borderRadius: "28px",
    borderColor: theme.palette.common.white,
  },
}));
