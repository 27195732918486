import { CalculatorFooterCard } from "@/domains/calculators/CalculatorNutrition/components/CalculatorFooterCard/CalculatorFooterCard";
import { CalculatorHeaderCard } from "@/domains/calculators/CalculatorNutrition/components/CalculatorHeaderCard/CalculatorHeaderCard";
import { CircularProgressClock } from "@/domains/calculators/sleep-time/sleep-time-clock/SleepTimeClock";
import { useTranslation } from "@/i18n/i18n.config";
import { CircularProgress, Grid } from "@toolkit/ui";
import { FC } from "react";
import { getSleepingArticleForAge } from "../sleep-time-clock/utils";

export type ICalculatorSleepStepResultEvent = {
  type: "CARD_CLICK";
};

type CalculatorSleepStepResultProps = {
  sleepTime?: string;
  selectedTime: string;
  sleepCycles?: number | null;
  basedOnSleepTime?: boolean;
  isLoading?: boolean;
  age: number;
  onChange: (event: ICalculatorSleepStepResultEvent) => void;
};

export const CalculatorSleepStepResult: FC<CalculatorSleepStepResultProps> = props => {
  const { age, sleepTime, selectedTime, sleepCycles, basedOnSleepTime, isLoading, onChange } = props;

  const { t } = useTranslation("digital-twin");

  const onCardClick = () => {
    onChange({
      type: "CARD_CLICK",
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CalculatorHeaderCard
          title={t("Thank you for setting your goal!")}
          description={t("You can come back to modify your goal at any time!")}
          onClick={onCardClick}
        />
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <CircularProgress size={30} />
        ) : (
          <CircularProgressClock
            sleepTime={sleepTime}
            selectedTime={selectedTime}
            sleepCycles={sleepCycles}
            basedOnSleepTime={basedOnSleepTime}
            description={basedOnSleepTime ? t("Sleep Time") : t("Wake Up Time")}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <CalculatorFooterCard mainTitle={t("Sleeping?")} articleCategory={getSleepingArticleForAge(age)} />
      </Grid>
    </Grid>
  );
};
