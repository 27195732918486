import { Box, SvgIcon, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { getCalculateCoordinates } from "@/domains/calculators/sleep-time/sleep-time-clock/utils";

type SleepTimeClockSvgProps = {
  radius: number;
  center: number;
  strokeWidth: number;
  startCalculationTime?: string;
  endCalculationTime?: string;
  sleepTime?: string;
  label?: string;
  sleepCycles?: number;
};

export const SleepTimeClockSvg: FC<SleepTimeClockSvgProps> = props => {
  const { radius, center, strokeWidth, startCalculationTime, endCalculationTime, sleepTime } = props;

  const { t } = useTranslation("digital-twin");

  const theme = useTheme();

  const calculateCoordinates = getCalculateCoordinates(radius, center, startCalculationTime, endCalculationTime);

  const { startValue, endValue, wakeCoords, sleepCoords } = calculateCoordinates ?? {};

  const largeArcFlag = startValue && endValue ? (endValue - startValue > 6 ? 1 : 0) : 0;

  return (
    <Box sx={{ dir: "ltr" }}>
      <svg width={center * 2} height={center * 2}>
        <circle cx={center} cy={center} r={radius} stroke={theme.palette.primary.main} strokeWidth={strokeWidth} fill='none' />

        <path
          d={`M ${wakeCoords?.x} ${wakeCoords?.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${sleepCoords?.x} ${sleepCoords?.y}`}
          fill='none'
          stroke={theme.palette.primary[100]}
          strokeWidth={strokeWidth}
        />

        {wakeCoords?.x && wakeCoords?.y && (
          <foreignObject x={wakeCoords.x - 25} y={wakeCoords.y - 25} width='40' height='40'>
            <SvgIcon
              width='22'
              height='22'
              viewBox='0 0 22 22'
              sx={{
                fontSize: "40px",
                padding: "4px",
                background: "white",
                border: `2px solid`,
                borderRadius: 25,
              }}
            >
              <path
                id='icons8-alarm'
                d='M6.292,2a4.437,4.437,0,0,0-3.2,7.354L9.356,3.089A4.477,4.477,0,0,0,6.292,2Zm13.415.012A4.474,4.474,0,0,0,16.645,3.1L22.91,9.366A4.449,4.449,0,0,0,22.7,3.308,4.4,4.4,0,0,0,19.707,2.013ZM13,4.539A9.3,9.3,0,0,0,6.254,20.251L4.787,21.718a.847.847,0,1,0,1.2,1.2l1.544-1.544a9.261,9.261,0,0,0,10.952,0l1.544,1.544a.847.847,0,1,0,1.2-1.2l-1.467-1.466A9.3,9.3,0,0,0,13,4.539Zm0,2.54h.01a.841.841,0,0,1,.842.842V14.7H8.767a.841.841,0,0,1-.842-.842v-.01A.841.841,0,0,1,8.767,13h3.391V7.921A.841.841,0,0,1,13,7.079Z'
                transform='translate(-1.999 -2)'
                fill='#18203c'
              />
            </SvgIcon>
          </foreignObject>
        )}

        {sleepCoords?.x && sleepCoords?.y && (
          <foreignObject x={sleepCoords.x - 25} y={sleepCoords.y - 25} width='40' height='40'>
            <SvgIcon
              sx={{
                fontSize: "40px",
                padding: "4px",
                background: "white",
                border: `2px solid`,
                borderRadius: 25,
              }}
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20.627'
              viewBox='0 0 20 20.627'
            >
              <path
                id='icons8-moon'
                d='M19.74,18.929A10.558,10.558,0,0,1,10.313,3.588,10.57,10.57,0,1,0,23,18.411,10.55,10.55,0,0,1,19.74,18.929Z'
                transform='translate(-3 -3.588)'
                fill='#18203c'
              />
            </SvgIcon>
          </foreignObject>
        )}

        <line x1={center} y1={center - radius + 25} x2={center} y2={center - radius + 15} stroke='black' strokeWidth='2' />
        <text x={center} y={center - radius + 40} textAnchor='middle' fill='black'>
          {t("12")}
        </text>

        <line x1={center + radius - 15} y1={center} x2={center + radius - 25} y2={center} stroke='black' strokeWidth='2' />
        <text x={center + radius - 35} y={center + 5} textAnchor='middle' fill='black'>
          {t("3")}
        </text>

        <text x={center} y={center + radius - 30} textAnchor='middle' fill='black'>
          {t("6")}
        </text>
        <line x1={center} y1={center + radius - 25} x2={center} y2={center + radius - 15} stroke='black' strokeWidth='2' />

        <line x1={center - radius + 25} y1={center} x2={center - radius + 15} y2={center} stroke='black' strokeWidth='2' />
        <text x={center - radius + 35} y={center + 5} textAnchor='middle' fill='black'>
          {t("9")}
        </text>

        <text x={center} y={center - 10} textAnchor='middle' fontSize='25' fontWeight={theme.mixins.fonts.fontWeight.semiBold} fill='black'>
          {sleepTime || t("00:00")}
        </text>
      </svg>
    </Box>
  );
};
