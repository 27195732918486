import React from "react";
import { Card, CardProps, Typography, TypographyProps } from "@toolkit/ui";
import { useCardWithTitleStyles } from "./CardWithTitle.styles";

interface CardWithTitleProps extends CardProps {
  title: string;
  titleProps?: TypographyProps;
}

export const CardWithTitle: React.FC<React.PropsWithChildren<CardWithTitleProps>> = ({ title, titleProps, children, ...rest }) => {
  const { classes, cx } = useCardWithTitleStyles();

  return (
    <Card className={classes.card} {...rest}>
      <Typography className={cx(classes.title, titleProps?.className)} {...titleProps}>
        {title}
      </Typography>
      {children}
    </Card>
  );
};
