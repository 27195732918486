import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useArticleCardStyle = makeStyles()(theme => ({
  root: {
    padding: 8,
    borderRadius: 10,
    border: `1px solid ${theme.palette.stale[300]}`,
  },
  headerImage: {
    width: "100%",
    height: "80px",
    borderRadius: 5,
    display: "block",
    backgroundColor: "#F3F8FC",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain, auto",
    backgroundPosition: "center, 50% 50%",
  },
  avatar: {
    width: "100%",
    height: 80,
    borderRadius: 5,
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    textAlign: pickLocalizedValue("left", "right"),
  },
  readTime: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    color: theme.palette.stale[900],
  },
}));
