import { styled, TextField } from "@toolkit/ui";

export const AuthTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "28px",
    backgroundColor: theme.palette.primary[200],
    border: "1px solid",
    display: "hidden",
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
      "& svg": {
        fill: theme.palette.error[1300],
      },
    },
  },
  "& .MuiInputBase-input": {
    width: "100%",
    padding: "5px",
    backgroundColor: theme.palette.primary[200],
    borderRadius: "28px",
    "&::placeholder": {
      fontWeight: theme.mixins.fonts.fontWeight.regular,
      fontSize: theme.mixins.fonts.fontSize.sm,
      color: `${theme.palette.primary[1200]}!important`,
    },
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
      "&::placeholder": {
        color: theme.palette.error[1200],
      },
    },
  },
  "& .MuiFilledInput-input:active": {
    backgroundColor: theme.palette.primary[200],
  },
  "& .MuiInput-underline:before": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    display: "none",
  },
  "&:hover .MuiInputBase-root": {
    border: "1px solid",
    borderColor: theme.palette.primary[400],
    backgroundColor: theme.palette.primary[200],
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
    },
  },
  "&:focus-within .MuiInputBase-root": {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary[200],
    "&.Mui-error": {
      borderColor: theme.palette.error[1300],
      backgroundColor: theme.palette.error[50],
    },
  },
  "&:focus-within input": {
    backgroundColor: theme.palette.primary[200],
  },
  "& input:-webkit-autofill": {
    borderRadius: "28px",
    color: theme.palette.primary[1200],
    "-webkit-box-shadow": `0 0 0 30px ${theme.palette.primary[100]} inset!important`,
  },
  "& .Mui-error": {
    color: theme.palette.error[1300],
    "& input": {
      backgroundColor: theme.palette.error[50],
      color: theme.palette.error[1200],
      fontWeight: theme.mixins.fonts.fontWeight.regular,
      fontSize: theme.mixins.fonts.fontSize.sm,
      "&::placeholder": {
        color: `${theme.palette.error[1200]}!important`,
      },
    },
    "& path": {
      fill: theme.palette.error[1300],
    },
  },
  "& .MuiFormLabel-root": {
    display: "none",
  },
  "&:focus-within .MuiFormLabel-root": {
    display: "block",
  },
}));
