import { Box, Button, CircularProgress } from "@toolkit/ui";
import { useGetUserInfoQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import useStyles from "./Profile.styles";

export const Profile: React.FC = () => {
  const { t } = useTranslation("digital-twin");
  const { data, loading } = useGetUserInfoQuery({
    fetchPolicy: "cache-first",
  });
  const { classes } = useStyles();
  const profileButtonTitle =
    data?.me?.firstName || data?.me?.lastName ? data?.me?.firstName ?? "" + " " + data?.me?.lastName ?? "" : t("username");

  return (
    <Box className={classes.container}>
      {loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <>
          <Button className={classes.button} endIcon={<Box className={classes.buttonIcon}></Box>}>
            {profileButtonTitle}
          </Button>
        </>
      )}
    </Box>
  );
};
