import { localStorageKeys } from "@/configs/local-storage-keys";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { AuthContext } from "./context";

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem(localStorageKeys.authToken));
  const setAppToken = (token: string) => {
    localStorage.setItem(localStorageKeys.authToken, token);
    setIsAuthenticated(true);
  };

  const getAppToken = () => {
    return localStorage.getItem(localStorageKeys.authToken);
  };

  const removeAppToken = () => {
    localStorage.removeItem(localStorageKeys.authToken);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = getAppToken();
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAppToken,
        getAppToken,
        removeAppToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};
