import { Box, Collapse, IconButton, Tooltip, Typography } from "@toolkit/ui";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExpandMoreIcon, InfoIcon } from "../Icons";
import { useExpandableListStyles } from "./ExpandableList.styles";
import { ExpandableListProps, ListCategory, ListCategoryWithItems } from "./types";
import {
  getCategoryWithItems as isCategoryWithSubItems,
  getCategoryWithLink,
  getCategoryWithOnClick,
  getItemWithLink,
  getItemWithOnClick,
} from "./utils";

export const ExpandableList = ({ categories, defaultExpanded, classes: _classes }: ExpandableListProps) => {
  const navigate = useNavigate();
  const { classes, cx } = useExpandableListStyles();
  const [expanded, setExpanded] = useState<ListCategory["id"][]>(
    defaultExpanded ? categories.filter((_, i) => defaultExpanded.includes(i)).map(c => c.id) : categories.map(c => c.id)
  );

  const handleExpandClick = (id: ListCategory["id"]) => {
    setExpanded(prevExpanded => (prevExpanded.includes(id) ? prevExpanded.filter(expandedId => expandedId !== id) : [...prevExpanded, id]));
  };

  const handleClick = (link?: string, onClick?: () => void) => {
    if (onClick) {
      onClick();
    } else if (link) {
      navigate(link);
    }
  };

  return (
    <Box className={cx(classes.root, _classes?.root)}>
      {categories.map((category, idx) => {
        const hasSubItems = isCategoryWithSubItems(category);
        return (
          <Fragment key={category.id}>
            <Box
              className={cx(classes.categoryContainer, _classes?.categoryContainer)}
              borderBottom={(hasSubItems && expanded.includes(category.id)) || idx === categories?.length - 1 ? "none!important" : "unset"}
              onClick={() =>
                hasSubItems
                  ? handleExpandClick(category.id)
                  : handleClick(getCategoryWithLink(category)?.link, getCategoryWithOnClick(category)?.onClick)
              }
            >
              {category.icon && <Box className={cx(classes.categoryIcon, _classes?.categoryIcon)}>{category.icon}</Box>}
              <Typography
                className={cx(classes.categoryTitle, _classes?.categoryTitle, { [classes.disabledCategoryTitle]: category.disabled })}
              >
                {category.title}
              </Typography>
              {category.info && (
                <Tooltip title={category.info} placement='top'>
                  <IconButton>
                    <InfoIcon sx={{ zoom: 0.4, width: "34px" }} />
                  </IconButton>
                </Tooltip>
              )}
              {((hasSubItems && !expanded.includes(category.id)) || !hasSubItems) && (
                <IconButton className={cx(classes.expandIconButton, _classes?.expandIconButton)}>
                  <ExpandMoreIcon direction={hasSubItems ? "down" : "right"} />
                </IconButton>
              )}
            </Box>
            {hasSubItems && (
              /// animate the show hide to ease the transition
              <Collapse in={expanded.includes(category.id)}>
                {(category as ListCategoryWithItems)?.items?.map(item => {
                  const itemWithLink = getItemWithLink(item);
                  const itemWithClick = getItemWithOnClick(item);
                  return (
                    <Box
                      key={item.id}
                      className={cx(classes.itemContainer, _classes?.itemContainer)}
                      onClick={item.disabled ? undefined : () => handleClick(itemWithLink?.link, itemWithClick?.onClick)}
                    >
                      <Typography className={cx(classes.itemTitle, _classes?.itemTitle, { [classes.disabledItemTitle]: item.disabled })}>
                        {item.title}
                        {item.info && (
                          <Tooltip title={item.info} placement='top'>
                            <IconButton className={cx(classes.infoIconButton, _classes?.infoIconButton)} disabled={item.disabled}>
                              <InfoIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Typography>
                      {(itemWithLink || itemWithClick) && (
                        <IconButton
                          className={cx(classes.navigateIconButton, _classes?.navigateIconButton)}
                          edge='end'
                          aria-label='navigate'
                          disabled={item.disabled}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};
