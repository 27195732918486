import { makeStyles } from "@toolkit/ui";

export const useHealthStatusComponentStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    gap: 0,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  iconContainer: {
    flex: 0.9,
    width: "100%",
    height: "100%",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  risksContainer: {
    flex: 1.1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    gap: theme.spacing(0.3),
  },
}));
