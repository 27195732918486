import { AnxietyFrequency } from "@/schema/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { FeelingNervousField, NotBeingAbleToStopOrControlWorryingField } from "../../fields";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";

const IAnxietyScreeningCalculatorFormZodSchema = z.object({
  feelingNervous: z.nativeEnum(AnxietyFrequency),
  notBeingAbleToStopOrControlWorrying: z.nativeEnum(AnxietyFrequency),
});

export type IAnxietyScreeningCalculatorFormZodSchemaType = z.infer<typeof IAnxietyScreeningCalculatorFormZodSchema>;
export interface IAnxietyScreeningCalculatorFormRef {
  formMethods: ReturnType<typeof useForm<IAnxietyScreeningCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const AnxietyScreeningCalculatorForm = forwardRef<
  IAnxietyScreeningCalculatorFormRef,
  {
    onSubmit: (data: IAnxietyScreeningCalculatorFormZodSchemaType) => void;
    onChange?: (data: IAnxietyScreeningCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const methods = useForm<IAnxietyScreeningCalculatorFormZodSchemaType>({
    resolver: zodResolver(IAnxietyScreeningCalculatorFormZodSchema),
    defaultValues: {
      feelingNervous: userProfileVar().feelingNervous,
      notBeingAbleToStopOrControlWorrying: userProfileVar().notBeingAbleToStopOrControlWorrying,
    },
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });
  methods.watch(d => props.onChange?.(d as IAnxietyScreeningCalculatorFormZodSchemaType));

  useImperativeHandle(ref, () => ({
    formMethods: methods,
    handleSubmit,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <FeelingNervousField />
        <NotBeingAbleToStopOrControlWorryingField />
      </form>
    </FormProvider>
  );
});
