/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BodyFatPercentageQueryVariables = Types.Exact<{
  input: Types.BodyFatPercentageInput;
}>;


export type BodyFatPercentageQuery = { __typename?: 'Query', bodyFatPercentage?: { __typename?: 'BodyFatPercentageResponse', bodyFatPercentage?: number | null } | null };


export const BodyFatPercentageDocument = gql`
    query BodyFatPercentage($input: BodyFatPercentageInput!) {
  bodyFatPercentage(input: $input) {
    bodyFatPercentage
  }
}
    `;

/**
 * __useBodyFatPercentageQuery__
 *
 * To run a query within a React component, call `useBodyFatPercentageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBodyFatPercentageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBodyFatPercentageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBodyFatPercentageQuery(baseOptions: Apollo.QueryHookOptions<BodyFatPercentageQuery, BodyFatPercentageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BodyFatPercentageQuery, BodyFatPercentageQueryVariables>(BodyFatPercentageDocument, options);
      }
export function useBodyFatPercentageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BodyFatPercentageQuery, BodyFatPercentageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BodyFatPercentageQuery, BodyFatPercentageQueryVariables>(BodyFatPercentageDocument, options);
        }
export type BodyFatPercentageQueryHookResult = ReturnType<typeof useBodyFatPercentageQuery>;
export type BodyFatPercentageLazyQueryHookResult = ReturnType<typeof useBodyFatPercentageLazyQuery>;
export type BodyFatPercentageQueryResult = Apollo.QueryResult<BodyFatPercentageQuery, BodyFatPercentageQueryVariables>;