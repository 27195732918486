import { ApolloProvider } from "@apollo/client";
import { I18nextProvider } from "@toolkit/i18n";
import { ToolkitUiProvider } from "@toolkit/ui";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { client } from "./configs/apollo";
import { i18n } from "@toolkit/i18n";
import { AuthProvider } from "./providers/auth";
import { RootRoutes } from "./routing/RootRoutes";
import SideMenuDrawer from "./components/SideMenuDrawer/SideMenuDrawer";
import { useEffect } from "react";
import { isInSehhatiApp, sehhatiAppEmbedPrefixUrl } from "./configs/sehhati-app/sehhati-app-utils";
import { localStorageKeys } from "./configs/local-storage-keys";

function App() {
  useEffect(() => {
    document.body.dir = i18n.dir(localStorage.getItem(localStorageKeys.locale) || "en");
    console.log(localStorage.getItem(localStorageKeys.locale));
  }, []);
  return (
    <>
      <BrowserRouter basename={isInSehhatiApp() ? sehhatiAppEmbedPrefixUrl : undefined}>
        <AuthProvider>
          <ApolloProvider client={client}>
            <I18nextProvider i18n={i18n}>
              <ToolkitUiProvider theme='digital-twin-sehhati'>
                <>
                  <RootRoutes />
                  <SideMenuDrawer />
                </>
              </ToolkitUiProvider>
            </I18nextProvider>
          </ApolloProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
