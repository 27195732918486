import i18n from "@/i18n/i18n.config";
import {
  ActivityLevel,
  AnxietyFrequency,
  Community,
  DepressionFrequency,
  DietQuality,
  EducationLevel,
  Gender,
  RiskLevel,
  SmokingHabit,
} from "@/schema/types";

export const genderOptions = [
  {
    get name() {
      return i18n.t("Male", { ns: "digital-twin" });
    },
    value: Gender.Male,
  },
  {
    get name() {
      return i18n.t("Female", { ns: "digital-twin" });
    },
    value: Gender.Female,
  },
];

export const physicalActivityOptions = [
  {
    get name() {
      return i18n.t("Sedentary: No Activity", { ns: "digital-twin" });
    },
    value: ActivityLevel.Sedentary,
  },
  {
    get name() {
      return i18n.t("Moderate Activity: 2-4 days/week", { ns: "digital-twin" });
    },
    value: ActivityLevel.ModeratelyActive,
  },
  {
    get name() {
      return i18n.t("Heavy Activity: 4-6 days/week", { ns: "digital-twin" });
    },
    value: ActivityLevel.HeavyActive,
  },
];

export const activityOptions = [
  {
    get name() {
      return i18n.t("Sedentary: No Activity", { ns: "digital-twin" });
    },
    value: ActivityLevel.Sedentary,
  },
  {
    get name() {
      return i18n.t("Light Activity: 1-2 days/week", { ns: "digital-twin" });
    },
    value: ActivityLevel.LightlyActive,
  },
  {
    get name() {
      return i18n.t("Moderate Activity: 2-4 days/week", { ns: "digital-twin" });
    },
    value: ActivityLevel.ModeratelyActive,
  },
  {
    get name() {
      return i18n.t("Heavy Activity: 4-6 days/week", { ns: "digital-twin" });
    },
    value: ActivityLevel.HeavyActive,
  },
  {
    get name() {
      return i18n.t("Very Heavy Activity: 6-7 days/week", { ns: "digital-twin" });
    },
    value: ActivityLevel.VeryHeavyActive,
  },
];
export const dietQualityOptions = [
  {
    get name() {
      return i18n.t("Very Poor", { ns: "digital-twin" });
    },
    value: DietQuality.VeryPoor,
  },
  {
    get name() {
      return i18n.t("Poor", { ns: "digital-twin" });
    },
    value: DietQuality.Poor,
  },
  {
    get name() {
      return i18n.t("Fair", { ns: "digital-twin" });
    },
    value: DietQuality.Fair,
  },
  {
    get name() {
      return i18n.t("Adequate", { ns: "digital-twin" });
    },
    value: DietQuality.Adequate,
  },
];
export const customYesNo = [
  {
    get name() {
      return i18n.t("Yes", { ns: "digital-twin" });
    },
    value: "Yes",
  },
  {
    get name() {
      return i18n.t("No", { ns: "digital-twin" });
    },
    value: "No",
  },
];
export const BooleanYesNo = [
  {
    get name() {
      return i18n.t("Yes", { ns: "digital-twin" });
    },
    value: true,
  },
  {
    get name() {
      return i18n.t("No", { ns: "digital-twin" });
    },
    value: false,
  },
];
export const educationsOptions = [
  {
    get name() {
      return i18n.t("Less Than High School", { ns: "digital-twin" });
    },
    value: EducationLevel.LessThanHighSchool,
  },
  {
    get name() {
      return i18n.t("High School", { ns: "digital-twin" });
    },
    value: EducationLevel.HighSchoolGraduate,
  },
  {
    get name() {
      return i18n.t("University Graduate", { ns: "digital-twin" });
    },
    value: EducationLevel.UniversityGraduate,
  },
];

export const communityOptions = [
  {
    get name() {
      return i18n.t("Rural", { ns: "digital-twin" });
    },
    value: Community.Rural,
  },
  {
    get name() {
      return i18n.t("Urban", { ns: "digital-twin" });
    },
    value: Community.Urban,
  },
];

export const smokingHabitOptions = [
  {
    get name() {
      return i18n.t("Never", { ns: "digital-twin" });
    },
    value: SmokingHabit.Never,
  },
  {
    get name() {
      return i18n.t("Former", { ns: "digital-twin" });
    },
    value: SmokingHabit.FormerSmoker,
  },
  {
    get name() {
      return i18n.t("Light", { ns: "digital-twin" });
    },
    value: SmokingHabit.LightSmoker,
  },
  {
    get name() {
      return i18n.t("Heavy", { ns: "digital-twin" });
    },
    value: SmokingHabit.HeavySmoker,
  },
];

export const stressLevelOptions = [
  {
    get name() {
      return i18n.t("Low", { ns: "digital-twin" });
    },
    value: RiskLevel.Low,
  },
  {
    get name() {
      return i18n.t("High", { ns: "digital-twin" });
    },
    value: RiskLevel.High,
  },
];

export const anxietyFrequencyOptions = [
  {
    get name() {
      return i18n.t("Not At All", { ns: "digital-twin" });
    },
    value: AnxietyFrequency.NotAtAll,
  },
  {
    get name() {
      return i18n.t("Several Days", { ns: "digital-twin" });
    },
    value: AnxietyFrequency.SeveralDays,
  },
  {
    get name() {
      return i18n.t("More Than Half The Days", { ns: "digital-twin" });
    },
    value: AnxietyFrequency.MoreThanHalfTheDays,
  },
  {
    get name() {
      return i18n.t("Nearly Every Day", { ns: "digital-twin" });
    },
    value: AnxietyFrequency.NearlyEveryDay,
  },
];
export const depressionFrequencyOptions = [
  {
    get name() {
      return i18n.t("Not At All", { ns: "digital-twin" });
    },
    value: DepressionFrequency.NotAtAll,
  },
  {
    get name() {
      return i18n.t("Several Days", { ns: "digital-twin" });
    },
    value: DepressionFrequency.SeveralDays,
  },
  {
    get name() {
      return i18n.t("More Than Half The Days", { ns: "digital-twin" });
    },
    value: DepressionFrequency.MoreThanHalfTheDays,
  },
  {
    get name() {
      return i18n.t("Nearly Every Day", { ns: "digital-twin" });
    },
    value: DepressionFrequency.NearlyEveryDay,
  },
];

export const whatIsTheGivenTimeOptions = [
  {
    get name() {
      return i18n.t("Bed Time", { ns: "digital-twin" });
    },
    value: true,
  },
  {
    get name() {
      return i18n.t("Wake Up Time", { ns: "digital-twin" });
    },
    value: false,
  },
];
