import { arEG } from "@mui/material/locale";
import { createTheme, Theme } from "@mui/material/styles";
import { CustomComponentsOverride } from "./components";
import { CustomThemePalette } from "./palette";

let CustomTheme: Theme = createTheme({});

const getFreshTheme = () =>
  createTheme(
    CustomTheme,
    {
      palette: CustomThemePalette(CustomTheme),
      components: CustomComponentsOverride,
      mixins: {
        toolbar: {
          minHeight: 70,
        },
        header: {
          height: 70,
        },
        sidebar: {
          width: 250,
        },
        fonts: {
          fontFamily: "var(--font-family-main)",
          fontSize: {
            xxs: 10,
            xs: 12,
            sm: 14,
            md: 16,
            lg: 18,
            xl: 20,
            xxl: 24,
          },
          fontWeight: {
            black: 900,
            extraBold: 800,
            bold: 700,
            semiBold: 600,
            medium: 500,
            normal: 400,
            regular: 350,
            light: 300,
          },
        },
        shadows: {
          none: "none",
          main: "0px 3px 6px #0000000D",
          xs: "0px 0px 6px #0000001A",
          sm: "0px 3px 10px #0000001A",
          md: "0px 0px 20px #0000001A",
        },
      },
      shadows: [
        "none",
        // Card BoxShadow
        "0px 0px 10px #00000029",
        // Default BoxShadow
        "0px 0px 20px #0000001A",
        ...CustomTheme.shadows,
      ],
    },
    arEG
  );

CustomTheme = getFreshTheme();
export { CustomTheme, getFreshTheme };
