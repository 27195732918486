import { makeStyles } from "@toolkit/ui";

export const useArticleDetailsStyle = makeStyles()(theme => ({
  root: {
    height: "100%",
    position: "relative",
    paddingBottom: 16,
    "& .w-md-editor": {
      boxShadow: "none",
      minHeight: 400,
      height: "unset !important",
      display: "contents",
    },
    "& .w-md-editor-preview ": {
      boxShadow: "none",
      paddingInline: "0",
      display: "contents",
    },
  },
  card: {
    padding: 16,
    margin: 8,
    marginTop: 16,
    height: "calc(100% - 270px)",
    overflowY: "auto",
  },
  symptomsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "flex-start",
  },
  symptoms: {
    fontSize: theme.mixins.fonts.fontSize.lg,
  },
  tagsWrapper: {
    display: "flex",
    gap: 12,
  },
  chip: {
    borderRadius: 8,
    background: theme.palette.primary[300],
    paddingBlock: 20,
    paddingInline: 8,
  },
  chipLabel: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  divider: {
    width: "100%",
    marginBlock: 16,
  },
}));
