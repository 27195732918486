import { useHeaderContext } from "@/providers";
import { Box, makeStyles } from "@toolkit/ui";
import { FC, PropsWithChildren, ReactNode, useEffect } from "react";

export const CalculatorsPageLayout: FC<PropsWithChildren<{ title: string; footer?: ReactNode }>> = ({ children, title, footer }) => {
  const { classes: defaultClasses } = useCalculatorsPageLayoutStyles();
  return (
    <Box display={"flex"} flexDirection={"column"}>
      <PageHeader title={title} />
      <Box className={defaultClasses.pageBody}>{children}</Box>
      {footer && <Box className={defaultClasses.pageFooter}>{footer}</Box>}
    </Box>
  );
};

const PageHeader: FC<{ title: string }> = ({ title }) => {
  const { setHeader } = useHeaderContext();
  useEffect(() => {
    setHeader(title);
    // setIcon(<BackButton onClick={navigate.bind(null, -1)} />);
  }, [title]);
  return <></>;
};

const useCalculatorsPageLayoutStyles = makeStyles()(() => {
  return {
    pageBody: {
      padding: "20px",
      paddingBottom: 40,
    },
    pageFooter: {
      padding: "20px",
    },
  };
});
