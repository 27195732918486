import { makeStyles } from "@toolkit/ui";

export const useCalculatorFooterCardStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    padding: 12,
    boxShadow: "none",
    border: `1px solid ${theme.palette.stale[300]}`,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    color: theme.palette.stale[700],
  },
  mainTitle: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
  },
  buttonsWrapper: {
    display: "flex",
    gap: 16,
    marginTop: 16,
  },
  button: {
    borderRadius: 6,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
}));
