import { ResultsCard, ResultsCardProps } from "@/components";
import { useTranslation } from "@/i18n/i18n.config";
import { RiskLevel } from "@/schema/types";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";

export const DepressionScreeningResultCard: FC<
  {
    score: number;
    result: RiskLevel;
  } & ResultsCardProps
> = ({ result, isLoading, isDataReady }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <ResultsCard
      isDataReady={isDataReady}
      isLoading={isLoading}
      customPlaceholder={t("This tool helps assess if you might be experiencing symptoms of depression.")}
    >
      <Box display={"flex"} flexDirection={"column"} py={2}>
        {isLoading ? (
          <Typography variant='h4' color='textPrimary'>
            <CircularProgress size={20} />
          </Typography>
        ) : (
          <Typography variant='body2' color='textPrimary'>
            <Typography fontWeight={500} color={result === RiskLevel.Low ? "green" : "red"}>
              {result === RiskLevel.Low ? t("Low") : t("High")}
            </Typography>{" "}
            {t("Risk score for developing depression")}
          </Typography>
        )}
      </Box>
    </ResultsCard>
  );
};
