import { CardWithTitle, HumanBodyIcon } from "@/components";
import CompletePercentage from "@/components/Progress/CompletePercentage";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import {
  getColorBaseOnScore,
  getLifeExpectancyQueryVariablesFilledInputsCount,
  lifeExpectancyFieldsTotalCount,
} from "@/domains/MyDigitalTwinProfile/utils";
import { useTranslation } from "@/i18n/i18n.config";
import { routesPaths } from "@/routing/routesPaths";
import { useReactiveVar } from "@apollo/client";
import { Box, Button, Typography, useTheme } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useInCompleteProfilePageStyles } from "./InCompleteProfilePage.styles";

export const InCompleteProfilePage: React.FC = () => {
  const theme = useTheme();
  const profileData = useReactiveVar(userProfileVar);
  const { t } = useTranslation("digital-twin");

  const filledCount = getLifeExpectancyQueryVariablesFilledInputsCount(profileData);
  const value = filledCount / (lifeExpectancyFieldsTotalCount / 100);
  const { classes } = useInCompleteProfilePageStyles();
  const navigate = useNavigate();

  const handleRouteToCompleteFillingProfilePage = () => {
    navigate(routesPaths.myDigitalTwinUpdateProfile);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        mx: { xs: 2, sm: 2, md: 6 },
      }}
    >
      <Box className={classes.root}>
        <CardWithTitle className={classes.card} title={t("Generating My Digital Twin")}>
          <Box className={classes.humanBodyIcon}>
            <HumanBodyIcon width={177} height={213} />
          </Box>
          <Box className={classes.percentageContainer}>
            <CompletePercentage
              value={value}
              color={getColorBaseOnScore(value, theme)}
              size={140}
              thickness={3}
              renderText={() => (
                <Box className={classes.percentageTextContainer} style={{ color: getColorBaseOnScore(value, theme) }}>
                  <Typography component='span' className={classes.percentageValue}>
                    {Number(value.toFixed(0))}
                    <Typography component='span' className={classes.percentageSymbol}>
                      %
                    </Typography>
                  </Typography>
                  <Typography component='span' className={classes.profileText}>
                    {t("Profile")}
                  </Typography>
                  <Typography component='span' className={classes.profileText}>
                    {t("Complete")}
                  </Typography>
                </Box>
              )}
            />
          </Box>
          <Typography className={classes.fillProfileText}>{t("Fill out your profile now!")}</Typography>
          <Typography className={classes.completeText}>{t("to generate your digital twin")}</Typography>
          <Box className={classes.descriptionContainer}>
            <Typography className={classes.descriptionText}>
              {t("Complete the form to help us tailor more accurate predictions and generate your unique digital twin ")}
            </Typography>
          </Box>
          <Button onClick={handleRouteToCompleteFillingProfilePage} className={classes.fillButton}>
            {t("Fill now")}
          </Button>
        </CardWithTitle>
      </Box>
    </Box>
  );
};
