import { ArticleCategoryCode } from "@/domains/Articles/codes";
import moment from "moment/moment";

export const getCalculateCoordinates = (radius: number, center: number, startCalculationTime?: string, endCalculationTime?: string) => {
  if (!startCalculationTime || !endCalculationTime) return;

  const getAngleFromHour = (hour: number) => {
    return (360 * (hour % 12)) / 12;
  };

  const getCoordinates = (angle: number) => {
    const radian = (angle - 90) * (Math.PI / 180);
    const x = center + radius * Math.cos(radian);
    const y = center + radius * Math.sin(radian);
    return { x, y };
  };

  const startValue = startCalculationTime ? moment(startCalculationTime, "hh:mm").hour() : null;
  const endValue = endCalculationTime ? moment(endCalculationTime, "hh:mm").hour() : null;

  const wakeCoords = startValue ? getCoordinates(getAngleFromHour(startValue)) : null;
  const sleepCoords = endValue ? getCoordinates(getAngleFromHour(endValue)) : null;

  return { startValue, endValue, wakeCoords, sleepCoords };
};

export const getSleepingArticleForAge = (age: number) => {
  if (age < 18) {
    return ArticleCategoryCode.sleeping0To18;
  } else if (age > 18) {
    return ArticleCategoryCode.sleeping19to65;
  } else {
    return ArticleCategoryCode.sleepingAfter65;
  }
};
