import { makeStyles } from "@toolkit/ui";

export const useFormStyles = makeStyles()(() => ({
  box: {
    width: "100%",
    marginTop: "19px",
  },
  radioGroup: {
    justifyContent: "space-between",
    gap: "0px",
    width: "100%",
    maxWidth: "370px",
    marginInlineStart: "-10px",
  },
  radioGroupWithGap: {
    gap: "40px",
    marginInlineStart: "-10px",
  },
  radioGroupEducation: {
    justifyContent: "space-between",
    gap: "0px",
    width: "100%",
    minWidth: "360px",
    marginInlineStart: "-10px",
    "& .MuiFormControlLabel-root": {
      marginInlineEnd: "5px",
    },
  },
  radioGroupCommunity: {
    gap: "40px",
    marginInlineStart: "-10px",
  },
}));
