import { useTranslation } from "@/i18n/i18n.config";
import { Box, Card, Typography, useTheme } from "@toolkit/ui";
import React from "react";
import { HealthStatusComponent } from "../../../domains/HealthStatus/HealthStatusComponent/HealthStatusComponent";
import { getOrganRisks } from "../../../domains/HealthStatus/constant";
import AgeExpectationCard from "../../../domains/calculators/life-expectancy-calculator/AgeExpectationCard/AgeExpectationCard";
import { useGeneratedPageStyles } from "./GeneratedPage.styles";
import { DiseaseIcon, HeartHealthIcon } from "@/components";
import DigitalTwinProfileCardButton from "@/components/DigitalTwinProfileCardButton/DigitalTwinProfileCardButton";
import { routesPaths } from "@/routing/routesPaths";
import { useNavigate } from "react-router-dom";

export const GeneratedPage: React.FC = () => {
  const { t } = useTranslation("digital-twin");
  const theme = useTheme();
  const organRisks = getOrganRisks(theme);
  const navigate = useNavigate();
  const { classes } = useGeneratedPageStyles();

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <Box className={classes.healthStatusBox}>
          <HealthStatusComponent organRisks={organRisks} />
        </Box>
        <Typography className={classes.disclaimerText}>
          {t(
            'Disclaimer: "Low Risk" means no action needed but doesn’t ensure full health. Consult a healthcare professional for further assessment'
          )}
        </Typography>
      </Card>
      <AgeExpectationCard />
      <Box className={classes.buttonContainer}>
        <DigitalTwinProfileCardButton
          icon={DiseaseIcon}
          title={t("Disease Risk & Management")}
          onClick={() => navigate(routesPaths.diseaseAndRiskManagement)}
        />
        <DigitalTwinProfileCardButton
          icon={HeartHealthIcon}
          title={t("Health & Wellbeing")}
          onClick={() => navigate(routesPaths.healthAndWellBeing)}
        />
      </Box>
    </Box>
  );
};
