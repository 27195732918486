import { Components, Theme } from "@mui/material/styles";
import { CheckedRadioIcon } from "./icons/CheckedRadioIcon";
import { UnCheckedRadioIcon } from "./icons/UnCheckedRadioIcon";
import React from "react";
export const MuiRadioOverride: Components<Theme>["MuiRadio"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.primary?.main,
      borderRadius: 0,
    }),
  },
  defaultProps: {
    checkedIcon: <CheckedRadioIcon />,
    icon: <UnCheckedRadioIcon />,
  },
};
