import { Box, Button, Card, Typography } from "@toolkit/ui";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCalculatorFooterCardStyle } from "./useCalculatorFooterCardStyle";
import { articleRoutes } from "@/pages/Articles";

type CalculatorFooterCardProps = {
  mainTitle: string;
  secondaryTitle?: string;
  isArticlesDisabled?: boolean;
  articleCategory?: string;
};

export const CalculatorFooterCard: FC<CalculatorFooterCardProps> = props => {
  const { secondaryTitle, mainTitle, isArticlesDisabled = false, articleCategory } = props;

  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();

  const { classes } = useCalculatorFooterCardStyle();

  const onConsultDoctorClick = () => {
    window.parent?.postMessage({ calculator: "book" }, "*");
  };

  const onBrowseArticlesClick = () => {
    if (isArticlesDisabled) return;

    navigate(articleRoutes.articleList.getRouteWithParams({ articleCategory }));
  };

  return (
    <Card className={classes.root}>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.title}>{secondaryTitle || t("Are you having trouble")}</Typography>
        <Typography className={classes.mainTitle}>{mainTitle}</Typography>
      </Box>

      <Box className={classes.buttonsWrapper}>
        <Button fullWidth className={classes.button} onClick={onConsultDoctorClick}>
          {t("Consult Doctor")}
        </Button>

        <Button fullWidth className={classes.button} variant={"outlined"} disabled={isArticlesDisabled} onClick={onBrowseArticlesClick}>
          {t("Browse Articles")}
        </Button>
      </Box>
    </Card>
  );
};
