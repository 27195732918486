import { makeStyles } from "@toolkit/ui";

export const useJoinCareStyles = makeStyles()(theme => ({
  joinCareTitle: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginLeft: "10px",
    color: theme.palette.text.primary,
  },
  joinCareSubtitle: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    marginLeft: "10px",
    color: theme.palette.text.primary,
  },
  joinCareContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "10px",
  },
  joinCareButton: {
    width: "130px",
    height: "40px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.primary[1600],
  },
}));
