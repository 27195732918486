import { BackButton } from "@/components";
import { CalculatorsMenu } from "@/domains/calculators/CalculatorsMenu";
import { getCalculatorsMenu } from "@/domains/calculators/CalculatorsMenu/constants";
import i18n from "@/i18n/i18n.config";
import { useHeaderContext } from "@/providers";
import { routesPaths } from "@/routing/routesPaths";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const AgeDynamicsAndCalculatorsPage: React.FC = () => {
  const [param] = useSearchParams();
  const defaultExpanded = param.has("route") ? [getCalculatorsMenu().findIndex(f => f.id === param.get("route"))] : [];
  const navigate = useNavigate();

  const { setHeader, resetHeader, setIcon } = useHeaderContext();

  useEffect(() => {
    setHeader(i18n.t("Calculators", { ns: "digital-twin" }));
    setIcon(<BackButton onClick={() => navigate(routesPaths.myDigitalTwin)} />);
    return () => {
      resetHeader();
    };
  }, [i18n.language]);

  return <CalculatorsMenu defaultExpanded={defaultExpanded} />;
};
