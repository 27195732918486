import React, { FC } from "react";
import { CircularProgressPercentage, CircularProgressWithValueProps } from "./CirleureProgress/CircularProgressPercentage";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { useTranslation } from "../../i18n/i18n.config";

interface CompletePercentageProps extends Omit<CircularProgressWithValueProps, "value"> {
  value?: number;
  size?: number;
  thickness?: number;
  renderText?: () => React.ReactNode;
}

const CompletePercentage: FC<CompletePercentageProps> = ({ renderText, value = 0, size = 96, thickness = 4, color, ...rest }) => {
  const theme = useTheme();
  const { t } = useTranslation("digital-twin");
  return (
    <CircularProgressPercentage value={value} size={size} thickness={thickness} color={color} {...rest}>
      <Box
        display='flex'
        flexDirection={"column"}
        alignItems='center'
        justifyContent='center'
        marginTop={11}
        fontSize={theme.mixins.fonts.fontSize.xs}
        sx={{
          color: color,
          position: "absolute",
        }}
      >
        {renderText ? (
          renderText()
        ) : (
          <>
            <Typography
              component='span'
              padding={0}
              fontWeight={theme.mixins.fonts.fontWeight.medium}
              fontSize={theme.mixins.fonts.fontSize.xl}
            >
              {Number(value.toFixed(0))}
              <Typography sx={{ verticalAlign: "super", display: "inline" }} fontSize={theme.mixins.fonts.fontSize.xs}>
                %
              </Typography>
            </Typography>

            <Typography
              marginTop={-0.5}
              component='span'
              fontWeight={theme.mixins.fonts.fontWeight.semiBold}
              fontSize={theme.mixins.fonts.fontSize.xxs}
            >
              {t("Complete")}
            </Typography>
          </>
        )}
      </Box>
    </CircularProgressPercentage>
  );
};

export default CompletePercentage;
