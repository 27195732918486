import { FormSlider, ValueLabelComponent } from "@/components";
import { LabeledInputContainer } from "@/components/LabeledInputContainer";
import RadioGroupField from "@/components/RadioGroupField";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useTranslation } from "@/i18n/i18n.config";
import { ActivityLevel } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { ActivityLevelField } from "../../fields";
import { BooleanYesNo } from "../../fields/constants";
const IWeightLossPlanCalculatorFormZodSchema = z.object({
  areYouWillingToChangeYourActivityLevel: z.boolean(),
  days: z.number().int().min(1).max(500),
  weight: z.number().min(0).max(250),
  activityLevel: z.nativeEnum(ActivityLevel),
});

export type IWeightLossPlanCalculatorFormZodSchemaType = z.infer<typeof IWeightLossPlanCalculatorFormZodSchema>;
export interface IWeightLossPlanCalculatorFormRef {
  formMethods: ReturnType<typeof useForm<IWeightLossPlanCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const WeightLossPlanCalculatorForm = forwardRef<
  IWeightLossPlanCalculatorFormRef,
  {
    onSubmit: (data: IWeightLossPlanCalculatorFormZodSchemaType) => void;
    onChange?: (data: IWeightLossPlanCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const { t } = useTranslation("digital-twin");
  const profileData = useReactiveVar(userProfileVar);
  const methods = useForm<IWeightLossPlanCalculatorFormZodSchemaType>({
    resolver: zodResolver(IWeightLossPlanCalculatorFormZodSchema),
    defaultValues: {
      weight: profileData.weight,
      activityLevel: profileData.activityLevel,
    },
    mode: "all",
  });

  methods.watch(data => {
    props.onChange?.(data as IWeightLossPlanCalculatorFormZodSchemaType);
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });

  useImperativeHandle(ref, () => ({
    formMethods: methods,
    handleSubmit,
  }));

  const areYouWillingToChangeYourActivityLevel = methods.watch("areYouWillingToChangeYourActivityLevel");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <LabeledInputContainer label={t("Enter Your Goal Weight")} mode='vertical'>
          <FormSlider
            valueLabelFormat={value => ValueLabelComponent({ value, unit: t("kg") })}
            name={"weight"}
            valueLabelDisplay='on'
            defaultValue={0}
            min={0}
            max={profileData.weight}
          />
        </LabeledInputContainer>
        <LabeledInputContainer label={t("In How Many days do you want to achieve this goal")} mode='vertical'>
          <FormSlider
            valueLabelFormat={value => ValueLabelComponent({ value, unit: t("days") })}
            name={"days"}
            valueLabelDisplay='on'
            defaultValue={0}
            min={1}
            max={300}
          />
        </LabeledInputContainer>
        <RadioGroupField
          label={t("Are you willing to change your physical activity level to achieve your goal")}
          options={BooleanYesNo}
          mode='vertical'
          name='areYouWillingToChangeYourActivityLevel'
          labelPlacement='bottom'
        />
        {areYouWillingToChangeYourActivityLevel && <ActivityLevelField title={t("Enter Your New Activity Level")} />}
      </form>
    </FormProvider>
  );
});
