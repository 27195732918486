import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const UnCheckedRadioIcon: FC<SvgIconProps> = ({ width = 25, height = 25, viewBox = "0 0 25 25", ...props }) => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} {...props}>
      <g xmlns='http://www.w3.org/2000/svg' id='Rectangle_10528' data-name='Rectangle 10528' fill='#fff' stroke='#c7d6ee' strokeWidth='1'>
        <rect width='25' height='25' rx='8' stroke='none' />
        <rect x='0.5' y='0.5' width='24' height='24' rx='7.5' fill='none' />
      </g>
    </SvgIcon>
  );
};
