import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const ObesityIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='48px' height='48px' {...props}>
      <path fill='#26a69a' d='M6,10c0-2.209,1.791-4,4-4h28c2.209,0,4,1.791,4,4v28c0,2.209-1.791,4-4,4H10c-2.209,0-4-1.791-4-4	V10z' />
      <path fill='#eee' d='M32.14,24L39,15.568c-8.668-7.423-21.332-7.425-30-0.003L15.864,24H32.14z' />
      <path
        fill='#00796b'
        d='M23.998,10c5.333,0,10.667,1.856,15.002,5.568L32.14,24H15.864L9,15.565 C13.333,11.855,18.665,10,23.998,10 M23.998,8C18.041,8,12.253,10.147,7.7,14.046l-1.482,1.269l1.231,1.513l6.864,8.435l0.6,0.737 h0.951H32.14h0.951l0.601-0.737l6.86-8.432l1.23-1.513l-1.481-1.269C35.746,10.148,29.956,8,23.998,8L23.998,8z'
      />
      <path
        fill='#90a4ae'
        d='M24.807,10.035c-0.02,0-0.04-0.002-0.06-0.002c-0.616-0.02-1.232-0.013-1.848,0.015 c-0.038,0.002-0.075,0.003-0.112,0.005v3.938h2.02C24.807,13.991,24.807,10.035,24.807,10.035z M13.36,16.92l1.751-0.998 l-1.866-3.224c-0.591,0.313-1.17,0.654-1.738,1.022L13.36,16.92z M17.842,14.816l1.95-0.521l-0.999-3.678 c-0.654,0.152-1.303,0.334-1.945,0.545L17.842,14.816z M28.813,10.532l-1.027,3.823l1.952,0.515l1.03-3.827 C30.121,10.844,29.471,10.674,28.813,10.532z M36.196,13.531c-0.573-0.359-1.157-0.689-1.753-0.994l-1.969,3.422l1.755,0.99 L36.196,13.531z'
      />
      <path fill='#263238' d='M27.054,24.001L29,14.838l-5.502,9.163H27.054z' />
      <path fill='#ffca28' d='M12,37h30c0.552,0,1-0.448,1-1v-4c0-0.552-0.448-1-1-1H12V37z' />
      <rect width='2' height='4' x='15' y='31' fill='#ed8f29' />
      <rect width='2' height='2' x='19' y='31' fill='#ed8f29' />
      <rect width='2' height='4' x='23' y='31' fill='#ed8f29' />
      <rect width='2' height='2' x='27' y='31' fill='#ed8f29' />
      <rect width='2' height='4' x='31' y='31' fill='#ed8f29' />
      <rect width='2' height='4' x='39' y='31' fill='#ed8f29' />
      <rect width='2' height='2' x='35' y='31' fill='#ed8f29' />
    </SvgIcon>
  );
};
