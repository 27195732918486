import { useFraminghamRiskLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { debounce } from "lodash";
import { useRef } from "react";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  FraminghamRiskCalculatorForm,
  IFraminghamRiskCalculatorFormRef,
  IFraminghamRiskCalculatorFormZodSchemaType,
} from "./framingham-risk-calculator-form/FraminghamRiskCalculatorForm";
import { FraminghamRiskResultCard } from "./framingham-risk-results/FraminghamRiskResultCard";
import { useNavigate } from "react-router-dom";
import { routes } from "@/pages/age-dynamics-and-calculators/routes";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";

export const FraminghamRiskCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const ref = useRef<IFraminghamRiskCalculatorFormRef>(null);
  const navigate = useNavigate();
  const doSubmit = debounce(() => {
    ref.current?.handleSubmit();
  }, 500);

  const [fetchFraminghamRisk, { loading, data }] = useFraminghamRiskLazyQuery({});
  const result = data?.framinghamRisk?.riskPercentage;
  const handleSubmit = (data: IFraminghamRiskCalculatorFormZodSchemaType) => {
    userProfileVar({
      ...userProfileVar(),
      hdlCholesterol: data.hdlCholesterol,
      smoker: data.smoker,
      systolicBp: data.systolicBp,
      totalCholesterol: data.totalCholesterol,
      treatedForBp: data.treatedForBp,
    });
    fetchFraminghamRisk({
      variables: {
        input: {
          age: data.age,
          gender: data.gender,
          hdlCholesterol: data.hdlCholesterol,
          smoker: data.smoker,
          systolicBp: data.systolicBp,
          totalCholesterol: data.totalCholesterol,
          treatedForBp: data.treatedForBp ? 1 : 0,
        },
      },
      onCompleted: resultData => {
        userProfileVar({
          ...userProfileVar(),
          heartRiskResult: ((resultData.framinghamRisk?.riskPercentage || 0) * 10) / 3,
        });
      },
    });
  };

  return (
    <CalculatorsPageLayout
      title={t("Heart Risk Percentage Calculator")}
      footer={<DefaultFooter handleSubmit={() => navigate(routes.root.route)} />}
    >
      <FraminghamRiskResultCard percentage={result ?? 0} isDataReady={result !== undefined} isLoading={loading} />
      <FraminghamRiskCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={() => doSubmit()} />
    </CalculatorsPageLayout>
  );
};
