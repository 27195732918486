import { Box } from "@toolkit/ui";
import { FC } from "react";

import { useHealthStatusComponentStyles } from "./HealthStatusComponent.styles";
import { BodyOrganRisk } from "@/components/BodyOrganRisk/BodyOrganRisk";
import { HumanBodyIcon } from "@/components";
import { getOrganRisks } from "../constant";

export const HealthStatusComponent: FC<{ organRisks: ReturnType<typeof getOrganRisks> }> = ({ organRisks }) => {
  const { classes } = useHealthStatusComponentStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>
        <HumanBodyIcon />
      </Box>
      <Box className={classes.risksContainer}>
        {organRisks.map((risk, index) => (
          <BodyOrganRisk key={index} {...risk} />
        ))}
      </Box>
    </Box>
  );
};
