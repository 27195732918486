import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useGetWeightLossPlanCalculationsLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { routesPaths } from "@/routing/routesPaths";
import { useReactiveVar } from "@apollo/client";
import { Typography } from "@toolkit/ui";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  IWeightLossPlanCalculatorFormRef,
  IWeightLossPlanCalculatorFormZodSchemaType,
  WeightLossPlanCalculatorForm,
} from "./weight-loss-plan-calculator-form/WeightLossPlanCalculatorForm";
import { WeightLossPlanResultCard } from "./weight-loss-plan-results/WeightLossPlanResultCard";

export const WeightLossPlanCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const ref = useRef<IWeightLossPlanCalculatorFormRef>(null);
  const navigate = useNavigate();
  const doSubmit = debounce(() => ref.current?.handleSubmit(), 500);
  const profileData = useReactiveVar(userProfileVar);

  const [fetchWeightLossPlanResult, { loading, data }] = useGetWeightLossPlanCalculationsLazyQuery({});
  const result = data?.getWeightLossPlanCalculations;
  const handleSubmit = (data: IWeightLossPlanCalculatorFormZodSchemaType) => {
    fetchWeightLossPlanResult({
      fetchPolicy: "network-only",
      variables: {
        input: {
          weight: profileData.weight,
          bee: Math.round(profileData.bee),
          goalWeight: data.weight,
          days: data.days,
          newActivityLevel: data.areYouWillingToChangeYourActivityLevel ? data.activityLevel : userProfileVar().activityLevel,
        },
      },
    });
  };
  useEffect(() => {
    if (ref.current?.formMethods.formState.isValid) {
      doSubmit();
    }
  }, []);

  return (
    <CalculatorsPageLayout
      title={t("Weight Loss Plan Calculator")}
      footer={
        <DefaultFooter
          isSubmitDisabled={!ref.current?.formMethods.formState.isValid || loading}
          handleSubmit={() => navigate(routesPaths.myDigitalTwin)}
        />
      }
    >
      <WeightLossPlanResultCard
        calorieIntake={result?.newCalorieIntake ?? 0}
        caloriesPerDayToAdjust={result?.caloriesPerDayToAdjust ?? 0}
        newTdee={result?.newTDEE ?? 0}
        isLoading={loading}
        isDataReady={result?.newTDEE !== undefined}
      />
      <Typography fontSize={12} color='textPrimary' my={1}>
        {t("Please fill in the details below to get your weight loss plan")}
      </Typography>
      <WeightLossPlanCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={handleSubmit} />
    </CalculatorsPageLayout>
  );
};
