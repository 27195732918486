import { createContext } from "react";

type AppAuthContext = {
  isAuthenticated: boolean;
  setAppToken: (token: string) => void;
  getAppToken: () => string | null;
  removeAppToken: () => void;
};

export const AuthContext = createContext<AppAuthContext>({
  isAuthenticated: false,
  setAppToken: token => {
    console.log(token);
  },
  getAppToken: () => null,
  removeAppToken: () => {},
});
