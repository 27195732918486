import { Box, Button, MuiDrawer, Typography, useModal } from "@toolkit/ui";
import { TimePicker } from "@/domains/calculators/sleep-time/time-picker/TimePicker";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useTimePickerStyle } from "@/domains/calculators/sleep-time/time-picker/useTimePickerStyle";
import { TimeIcon } from "@/components";
import moment from "moment";
import { ICalculatorSleepSelectedTime } from "@/domains/calculators/sleep-time/types";

type TimePickerSheetProps = {
  value: ICalculatorSleepSelectedTime;
  onChange: (name: string, value: string) => void;
};

export const TimePickerSheet: FC<TimePickerSheetProps> = props => {
  const { value, onChange } = props;

  const { t } = useTranslation("digital-twin");

  const { classes } = useTimePickerStyle();

  const { open, handleToggle } = useModal();

  const timeString = `${value.hour}:${value.minute} ${value.amOrPm}`;

  const time = moment(timeString, "hh:mm A").format("hh:mm");

  return (
    <Box>
      <Button fullWidth variant={"outlined"} onClick={handleToggle} endIcon={<TimeIcon />} classes={{ outlinedPrimary: classes.button }}>
        {time || t("Select time")}
      </Button>

      <MuiDrawer anchor={"bottom"} open={open} onClose={handleToggle} classes={{ paper: classes.paper }}>
        <Typography variant={"h6"}>{t("Select time")}</Typography>

        <TimePicker value={value} onChange={onChange} />

        <Button onClick={handleToggle}>{t("Select")}</Button>
      </MuiDrawer>
    </Box>
  );
};
