import { makeStyles } from "@toolkit/ui";

export const useArticleListStyle = makeStyles()(theme => ({
  root: {
    height: "100%",
    paddingBottom: 100,
  },
  wrapper: {
    height: "100%",
    padding: 16,
  },
  textFieldWrapper: {
    marginBottom: 8,
  },
  textField: {
    "& .MuiFilledInput-input": {
      color: theme.palette.primary.main,
      "&::placeholder": {
        color: theme.palette.stale[700],
      },
      "&:focus": {
        background: "transparent",
      },
      "&:active": {
        background: "transparent",
      },
    },
  },
  inputProps: {
    paddingRight: "8px !important",
    color: theme.palette.primary.main,
    "& .MuiSvgIcon-root": {
      background: theme.palette.primary[300],
      padding: 8,
      borderRadius: 8,
    },
    "& .MuiSvgIcon-root path": {
      fill: theme.palette.primary.main,
    },
  },
}));
