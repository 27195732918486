import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const CalculatorIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='15.894' height='15.894' viewBox='0 0 15.894 15.894' {...props}>
      <path
        id='icons8-date_1_'
        data-name='icons8-date (1)'
        d='M18.449,4h-13A1.445,1.445,0,0,0,4,5.445v13a1.445,1.445,0,0,0,1.445,1.445h13a1.445,1.445,0,0,0,1.445-1.445v-13A1.445,1.445,0,0,0,18.449,4ZM6.167,17.727A.722.722,0,1,1,6.89,17,.723.723,0,0,1,6.167,17.727Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,6.167,14.837Zm2.89,2.89A.722.722,0,1,1,9.78,17,.723.723,0,0,1,9.057,17.727Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,9.057,14.837Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,9.057,11.947Zm2.89,5.78A.722.722,0,1,1,12.669,17,.723.723,0,0,1,11.947,17.727Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,11.947,14.837Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,11.947,11.947Zm2.89,5.78A.722.722,0,1,1,15.559,17,.723.723,0,0,1,14.837,17.727Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,14.837,14.837Zm-.722-3.612a.722.722,0,1,1,.722.722A.723.723,0,0,1,14.114,11.224Zm3.612,3.612a.722.722,0,1,1,.722-.722A.723.723,0,0,1,17.727,14.837Zm0-2.89a.722.722,0,1,1,.722-.722A.723.723,0,0,1,17.727,11.947Zm.722-3.612h-13V5.445h13Z'
        transform='translate(-4 -4)'
        fill={theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
