import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useSleepTimeCalculatorFormStyle = makeStyles()(theme => ({
  calculateBasedOnLabel: {
    textAlign: pickLocalizedValue("left", "right"),
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    marginBottom: 16,
  },
  buttonsWrapper: {
    display: "flex",
    gap: 16,
  },
  button: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  whenSleepWakeUpLabel: {
    textAlign: pickLocalizedValue("left", "right"),
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    marginBottom: 16,
  },
}));
