import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { CalculatorsPlayground } from "./CalculatorsPlayground";
import { routes } from "./routes";
import { AgeDynamicsAndCalculatorsPage } from "./AgeDynamicsAndCalculatorsPage";

export const AgeDynamicsAndCalculatorsRoutes: FC = () => {
  return (
    <Routes>
      {process.env.NODE_ENV === "development" && <Route path={"/playground"} element={<CalculatorsPlayground />} />}
      {/* {Object.values(routes).map(({ route, component }) => (
        <Route key={route} path={route} Component={component} />
      ))} */}
      <Route path={routes.lifeExpectancy.route} Component={routes.lifeExpectancy.component} />
      <Route path={routes.diabetesRiskScore.route} Component={routes.diabetesRiskScore.component} />
      <Route path={routes.beeAndTDee.route} Component={routes.beeAndTDee.component} />
      <Route path={routes.weightLossPlanPage.route} Component={routes.weightLossPlanPage.component} />
      <Route path={routes.bmiCalc.route} Component={routes.bmiCalc.component} />
      <Route path={routes.idealBodyWeight.route} Component={routes.idealBodyWeight.component} />
      <Route path={routes.bodyFatPercentage.route} Component={routes.bodyFatPercentage.component} />
      <Route path={routes.root.route} Component={AgeDynamicsAndCalculatorsPage} />
      <Route path={routes.sleepTime.route} Component={routes.sleepTime.component} />
      <Route path={routes.bmiCalc.route} Component={routes.bmiCalc.component} />
      <Route path={routes.anxietyScreeningPage.route} Component={routes.anxietyScreeningPage.component} />
      <Route path={routes.depressionScreeningPage.route} Component={routes.depressionScreeningPage.component} />
      <Route path={routes.framinghamRiskPercentagePage.route} Component={routes.framinghamRiskPercentagePage.component} />
      <Route
        path={routes.GeneratingDigitalTwinInProgressCountDownPage.route}
        Component={routes.GeneratingDigitalTwinInProgressCountDownPage.component}
      />
      <Route path={"/*"} element={<h3> No Such Calculator</h3>} />
    </Routes>
  );
};
