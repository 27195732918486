import { makeStyles } from "@toolkit/ui";

export const useInCompleteProfilePageStyles = makeStyles()(theme => ({
  root: {
    minHeight: "100%",
    height: "1px",
  },
  card: {
    minHeight: "100%",
    height: "max-content",
    padding: "10px",
  },
  humanBodyIcon: {
    "& svg": {
      width: 177,
      height: 213,
      marginTop: "27px",
    },
  },
  percentageContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  percentageTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    position: "absolute",
  },
  percentageValue: {
    padding: 0,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: 35,
  },
  percentageSymbol: {
    verticalAlign: "super",
    display: "inline",
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  profileText: {
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    fontSize: theme.mixins.fonts.fontSize.sm,
    lineHeight: 1.2,
  },
  completeText: {
    marginBottom: "25px",
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  fillProfileText: {
    lineHeight: 2.4,
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: 600,
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "center",
  },
  descriptionText: {
    width: "340px",
    paddingInline: "10px",
    lineHeight: 2,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  fillButton: {
    marginTop: "10px",
    width: "220px",
    height: "50px",
    borderRadius: "28px",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
}));
