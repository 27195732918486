import { Box, Card, CircularProgress, Divider, Typography } from "@toolkit/ui";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";
import { CalculatorNutritionResultChip } from "../CalculatorNutritionResultChip/CalculatorNutritionResultChip";
import { useCalculatorNutritionResultStyle } from "./useCalculatorNutritionResultStyle";

type CalculatorNutritionResultProps = {
  goalWeight?: number;
  tdeeValue?: number;
  newTdeeValue?: number;
  beeValue?: number;
  targetCalories?: number;
  maxTdee?: number;
  reduceCalorieIntake?: number;
  daysToAchieveGoalWeight?: number;
  size?: number;
  spacing?: number;
  thickness?: number;
};

export const CalculatorNutritionResult: FC<CalculatorNutritionResultProps> = props => {
  const {
    goalWeight = 0,
    tdeeValue = 0,
    beeValue = 0,
    targetCalories = 0,
    maxTdee = 0,
    newTdeeValue = 0,
    daysToAchieveGoalWeight = 0,
    size = 250,
    spacing = 20,
    thickness = 2,
    reduceCalorieIntake = 0,
  } = props;

  const { t } = useTranslation("digital-twin");

  const { classes, theme } = useCalculatorNutritionResultStyle({ size, spacing });

  const maxTdeeRound = Math.round(maxTdee || 0);
  const tdeeValueRound = Math.round(tdeeValue || 0);
  const beeValueRound = Math.round(beeValue || 0);
  const targetCaloriesRound = Math.round(targetCalories || 0);
  const newTdeeRounded = Math.round(newTdeeValue || 0);
  const reduceCalorieIntakeRounded = Math.round(reduceCalorieIntake || 0);

  return (
    <Card className={classes.root}>
      <Box className={classes.wrapper}>
        <CircularProgress
          variant={"determinate"}
          size={size}
          thickness={thickness}
          value={maxTdeeRound > 0 ? (tdeeValueRound / maxTdeeRound) * 100 : 0}
          className={classes.tdeeCircularProgress}
        />

        <CircularProgress
          variant={"determinate"}
          size={size - spacing * 2}
          thickness={thickness}
          value={maxTdeeRound > 0 ? (beeValueRound / maxTdeeRound) * 100 : 0}
          className={classes.beeCircularProgress}
        />

        <CircularProgress
          variant={"determinate"}
          size={size - spacing * 4}
          thickness={thickness}
          value={maxTdeeRound > 0 ? (targetCaloriesRound / maxTdeeRound) * 100 : 0}
          className={classes.targetCaloriesCircularProgress}
        />

        <Box className={classes.circularProgressText}>
          <Typography variant={"h5"} style={{ fontWeight: theme.mixins.fonts.fontWeight.bold }}>
            {t("{{goalWeight}}kg", { goalWeight: goalWeight || "00" })}
          </Typography>

          <Typography variant={"h6"} style={{ fontSize: theme.mixins.fonts.fontSize.md }}>
            {t("Goal Weight")}
          </Typography>
        </Box>
      </Box>
      <Typography variant='h6' color='textPrimary' sx={{ textAlign: "center", width: "100%" }}>
        {" "}
        {t("Calorie needs")}{" "}
      </Typography>

      <Box className={classes.chipsWrapper}>
        {!!tdeeValueRound && (
          <CalculatorNutritionResultChip
            value={tdeeValueRound}
            label={t("Calories your body needs to maintain your current weight based on your physical activity level")}
            chipColor={theme.palette.common.white}
            chipBackground={theme.palette.primary.main}
            labelColor={theme.palette.primary.main}
          />
        )}

        {!!beeValueRound && (
          <CalculatorNutritionResultChip
            value={beeValueRound}
            label={t("Calories your body needs to maintain your current weight without any physical activity")}
            chipColor={theme.palette.common.black}
            chipBackground={theme.palette.primary[500]}
            labelColor={theme.palette.common.black}
          />
        )}

        {!!daysToAchieveGoalWeight && (
          <>
            <Divider sx={{ my: 1, width: "100%" }} color='red' />
            <Typography variant='h6' color='textPrimary' sx={{ textAlign: "center", width: "100%" }}>
              {" "}
              {t("Weight loss adjusted calorie")}
            </Typography>
            <CalculatorNutritionResultChip
              value={newTdeeRounded}
              label={t("Calories your body needs to maintain your current weight based on your new physical activity level")}
              chipColor={theme.palette.common.white}
              chipBackground={theme.palette.grey[800]}
              labelColor={theme.palette.grey[800]}
            />
          </>
        )}
        {!!(tdeeValueRound && beeValueRound && targetCaloriesRound) && (
          <CalculatorNutritionResultChip
            value={targetCaloriesRound}
            label={t("Calories required to reach the target weight ({{goalWeight}}) in {{daysToAchieveGoalWeight}} days", {
              goalWeight,
              daysToAchieveGoalWeight,
            })}
            chipColor={theme.palette.common.white}
            chipBackground={theme.palette.success.main}
            labelColor={theme.palette.success.main}
          />
        )}

        {!!reduceCalorieIntakeRounded && (
          <CalculatorNutritionResultChip
            value={reduceCalorieIntakeRounded}
            label={t("Daily calories you need to reduce")}
            chipColor={theme.palette.common.white}
            chipBackground={theme.palette.error[1200]}
            labelColor={theme.palette.error[1200]}
          />
        )}
      </Box>
    </Card>
  );
};
