/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge, LifeSlider } from "@/components";
import { getLifeSliderProps } from "@/components/Sliders/LifeSlider";
import { useLifeExpectancyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { routesPaths } from "@/routing/routesPaths";
import { useReactiveVar } from "@apollo/client";
import { Trans } from "@toolkit/i18n";
import { Box, Button, Card, CircularProgress, Typography, useTheme } from "@toolkit/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { userProfileVar } from "../../../MyDigitalTwinProfile/userProfileVar";
import { getLifeExpectancyQueryInput } from "../../../MyDigitalTwinProfile/utils";
import { useAgeExpectationCardStyles } from "./AgeExpectationCard.styles";

const AgeExpectationCard: React.FC = () => {
  const { classes } = useAgeExpectationCardStyles();
  const theme = useTheme();
  const { t } = useTranslation("digital-twin");
  const previousFilled = useReactiveVar(userProfileVar) as any;
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(routesPaths.ageDynamicsAndCalculators);
  };
  const { data: lifeExpectancyData, loading } = useLifeExpectancyQuery({
    variables: {
      input: getLifeExpectancyQueryInput(previousFilled),
    },
  });

  const lifeExpectancy = lifeExpectancyData?.lifeExpectancy?.lifeExpectancy;
  const averageAge = lifeExpectancyData?.lifeExpectancy?.averageAge;
  const age = previousFilled?.age;
  const lifeSliderProps = getLifeSliderProps(
    {
      age,
      lifeExpectancy,
      averageAge,
    },
    theme
  );

  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>{t("Age Dynamics & Quality of Life")}</Typography>
      <Typography className={classes.introText}>
        <Trans t={t} i18nKey={"life-slider-intro-text"} components={[<Typography className={classes.boldText} />]} />
      </Typography>
      <Box className={classes.badgeContainer}>
        <Box className={classes.badge}>
          {loading ? (
            <CircularProgress sx={{ width: "100%" }} />
          ) : (
            <Badge number={lifeExpectancy ? Number(lifeExpectancy!).toFixed(1) : undefined} label={t("Years")} />
          )}
        </Box>
      </Box>
      <Box className={classes.sliderContainer}>
        <LifeSlider {...lifeSliderProps} />
      </Box>
      <Button className={classes.button} onClick={handleOnClick}>
        {t("Discover more Calculators")}
      </Button>
    </Card>
  );
};

export default AgeExpectationCard;
