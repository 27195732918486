import { Box, styled, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
export const BadgeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme?.palette.primary[1600],
  borderRadius: "50%",
  width: "100px",
  height: "100px",

  border: "5px solid",
  borderColor: theme?.palette.primary[50],
}));

interface BadgeProps {
  number?: number | string;
  label: string;
}

export const Badge: FC<BadgeProps> = ({ number, label }) => {
  const theme = useTheme();
  return (
    <BadgeContainer>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='h4' component='span' color={theme.palette.common.white}>
          {number}
        </Typography>
        <Typography variant='body2' component='span' color={theme.palette.common.white}>
          {label}
        </Typography>
      </Box>
    </BadgeContainer>
  );
};
