import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useStatsItemStyles } from "./useStatsItem.styles";

interface StatsItemProps {
  value: string | number;
  label: string;
}

export const StatsItem: React.FC<StatsItemProps> = ({ value, label }) => {
  const { classes } = useStatsItemStyles();

  return (
    <Box className={classes.item}>
      <Typography className={classes.value}>{value}</Typography>
      <Typography className={classes.label}>{label}</Typography>
    </Box>
  );
};
