import React, { FC } from "react";
import Picker from "time-picker-scroll";
import { useTimePickerStyle } from "./useTimePickerStyle";
import { ICalculatorSleepSelectedTime } from "@/domains/calculators/sleep-time/types";

type TimePickerProps = {
  value: ICalculatorSleepSelectedTime;
  onChange: (name: string, value: string) => void;
};

export const TimePicker: FC<TimePickerProps> = props => {
  const { value, onChange } = props;

  const { classes } = useTimePickerStyle();

  const optionGroups = {
    hour: Array.from({ length: 12 }, (_, i) => (i + 1).toString()),
    minute: Array.from({ length: 60 }, (_, i) => (i < 10 ? "0" + i : i.toString())),
    amOrPm: ["AM", "PM"],
  };

  return (
    <div className={classes.root}>
      <Picker valueGroups={value} optionGroups={optionGroups} itemHeight={44} height={270} onChange={onChange} />
    </div>
  );
};
