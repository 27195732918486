import { makeStyles } from "@toolkit/ui";

export const useSleepTimeClockStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    paddingInline: 0,
    boxShadow: "none",
    border: `1px solid ${theme.palette.stale[300]}`,
  },
  timesCard: {
    marginTop: 16,
    padding: 8,
  },
  timesWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 1,
    width: "100%",
  },
  timeLabel: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
