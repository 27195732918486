import { Gender } from "@/schema/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { AgeField, GenderField, HeightField, WeightField } from "../../fields";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useReactiveVar } from "@apollo/client";

const IBodyFatPercentageCalculatorFormZodSchema = z.object({
  age: z.number().int().min(0).max(120),
  gender: z.nativeEnum(Gender),
  height: z.number().min(0).max(300),
  weight: z.number().min(0).max(500),
});

export type IBodyFatPercentageCalculatorFormZodSchemaType = z.infer<typeof IBodyFatPercentageCalculatorFormZodSchema>;
export interface IBodyFatPercentageCalculatorFormRef {
  formMethods: ReturnType<typeof useForm<IBodyFatPercentageCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const BodyFatPercentageCalculatorForm = forwardRef<
  IBodyFatPercentageCalculatorFormRef,
  {
    onSubmit: (data: IBodyFatPercentageCalculatorFormZodSchemaType) => void;
    onChange?: (data: IBodyFatPercentageCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const profileData = useReactiveVar(userProfileVar);
  const methods = useForm<IBodyFatPercentageCalculatorFormZodSchemaType>({
    resolver: zodResolver(IBodyFatPercentageCalculatorFormZodSchema),
    defaultValues: {
      age: profileData.age,
      gender: profileData.gender,
      height: profileData.height,
      weight: profileData.weight,
    },
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });
  methods.watch(d => props.onChange?.(d as IBodyFatPercentageCalculatorFormZodSchemaType));

  useImperativeHandle(ref, () => ({
    formMethods: methods,
    handleSubmit,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <WeightField />
        <HeightField />
        <GenderField />
        <AgeField />
      </form>
    </FormProvider>
  );
});
