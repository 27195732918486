import React, { ChangeEvent, useEffect, useState } from "react";
import { Article } from "@/schema/types";
import { Box, CircularProgress, CustomInfiniteScroll, SearchIcon, TextField, Typography } from "@toolkit/ui";
import { ArticleCard } from "../../components/ArticleCard/ArticleCard";
import { useTranslation } from "react-i18next";
import { useArticleListStyle } from "./useArticleListStyle";
import { pickLocalizedValue } from "@toolkit/i18n";
import { ArticleHeader } from "../../components/ArticleHeader/ArticleHeader";
import { useDebounce } from "@toolkit/core";
import { useArticleListQuery } from "@/domains/Articles/gql/queries";
import { useSearchParams } from "react-router-dom";

export const ArticleList = () => {
  const [searchValue, setSearchValue] = useState("");

  const { t } = useTranslation("digital-twin");

  const [params] = useSearchParams();
  const categoryCode = params.get("articleCategory")?.split(",") || [];

  const { classes, theme } = useArticleListStyle();

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const { data, loading, refetch, fetchMore } = useArticleListQuery({
    variables: {
      first: 10,
      filter: {
        categories: categoryCode?.length ? categoryCode! : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const articles = data?.articles?.edges?.map(item => item?.node) as Article[];
  const firstArticle = articles?.find(item => item?.headerImage);

  const pageInfo = data?.articles?.pageInfo;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage && !loading) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
          filter: {
            categories: categoryCode?.length ? categoryCode : undefined,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return Object.assign({}, prev, {
            ...fetchMoreResult,
            articles: {
              ...fetchMoreResult.articles,
              edges: [...(prev?.articles?.edges || []), ...(fetchMoreResult?.articles?.edges || [])],
            },
          });
        },
      });
    }
  };

  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    debouncedSearchValue &&
      refetch({
        first: 10,
        filter: {
          categories: categoryCode ? categoryCode : undefined,
          search: debouncedSearchValue,
        },
      });
  }, [debouncedSearchValue]);

  return (
    <Box className={classes.root}>
      <ArticleHeader
        image={firstArticle?.headerImage || ""}
        title={pickLocalizedValue(firstArticle?.category?.display, firstArticle?.category?.displayAr)!}
      />

      <Box className={classes.wrapper}>
        <Box className={classes.textFieldWrapper}>
          <TextField
            value={searchValue}
            placeholder={t("Search for anything")}
            fullWidth
            className={classes.textField}
            InputProps={{
              classes: { root: classes.inputProps },
              endAdornment: <SearchIcon fontSize={"large"} />,
            }}
            onChange={onTextFieldChange}
          />
        </Box>

        <>
          {articles?.length > 0 && (
            <CustomInfiniteScroll
              dataLength={articles.length}
              onFetchMore={fetchMoreData}
              hasMore={pageInfo?.hasNextPage}
              height={"calc(100vh - 330px)"}
              loader={
                <Box display={"flex"} justifyContent={"center"} color={theme.palette.primary.main} m={1}>
                  <CircularProgress size={50} color={"inherit"} />
                </Box>
              }
            >
              {articles?.map(item => (
                <Box key={item?.id} style={{ marginBottom: 8 }}>
                  <ArticleCard article={item} />
                </Box>
              ))}
            </CustomInfiniteScroll>
          )}

          {!articles?.length && <Typography>{t("There are no articles")}</Typography>}
        </>
      </Box>
    </Box>
  );
};
