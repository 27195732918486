import { makeStyles } from "@toolkit/ui";

export const useCardWithTitleStyles = makeStyles()(theme => ({
  card: {
    width: "100%",
    borderRadius: "10px",
    height: "max-content",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: "24px",
  },
}));
