import { makeStyles } from "@toolkit/ui";

export const useFitnessDataBoxStyles = makeStyles<{ color: string; borderColor: string }>()((theme, { color, borderColor }) => ({
  box: {
    width: "90px",
    height: "90px",
    borderRadius: "50%",
    backgroundColor: color,
    border: `5px solid ${borderColor}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    color: "white",
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    display: "inline",
  },
  supp: {
    display: "inline",
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.xxs,
    color: "white",
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
}));
