import { Box, Typography, useTheme } from "@toolkit/ui";
import { PageContainer } from "./components/PageContainer";
import { SigninForm } from "./components/SigninForm";
import { SignupButton } from "./components/SignupButton";
import { useTranslation } from "../../i18n/i18n.config";

export const LoginPage = () => {
  const theme = useTheme();
  const { t } = useTranslation("digital-twin");

  return (
    <PageContainer>
      <Box
        flex='1'
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography mb={1} fontSize={theme.mixins.fonts.fontSize.lg} fontWeight={"medium"}>
            {t("Welcome to Digital Twin")}
          </Typography>
          <Typography mb={7} fontSize={theme.mixins.fonts.fontSize.xxl} fontWeight={"bold"}>
            {t("Your Health Superapp")}
          </Typography>
        </Box>
        <Box>
          <SigninForm />
        </Box>
      </Box>
      <Box>
        <SignupButton />
      </Box>
      {/* <SocialLogin /> */}
    </PageContainer>
  );
};

export default LoginPage;
