export enum CalculatorSleepStep {
  CalculatorSleepMain = 1,
  CalculatorSleepForm = 2,
  CalculatorSleepResult = 3,
}

export type ICalculatorSleepSelectedTime = {
  hour: string;
  minute: string;
  amOrPm: string;
};
