/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDigitalTwinHealthProfileNutritionResultQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDigitalTwinHealthProfileNutritionResultQuery = { __typename?: 'Query', getDigitalTwinHealthProfile?: { __typename?: 'DigitalTwinHealthProfile', beeAndTdeeResults?: { __typename?: 'BEEAndTDEEResultConnection', edges: Array<{ __typename?: 'BEEAndTDEEResultEdge', node: { __typename?: 'BEEAndTDEEResult', bee?: any | null, tdee?: any | null, queryInput?: { __typename?: 'BEEAndTDEEInputLog', gender: Types.Gender, age: number, height: number, weight: number, activityLevel: Types.ActivityLevel } | null } }> } | null, goalWeightResults?: { __typename?: 'GoalWeightResultConnection', edges: Array<{ __typename?: 'GoalWeightResultEdge', node: { __typename?: 'GoalWeightResult', newCalorieIntake?: any | null, caloriesPerDayToAdjust?: any | null, newTDEE?: any | null, queryInput?: { __typename?: 'GoalWeightInputLog', bee: number, days: number, goalWeight: number, newActivityLevel: Types.ActivityLevel, weight: number } | null } }> } | null } | null };


export const GetDigitalTwinHealthProfileNutritionResultDocument = gql`
    query getDigitalTwinHealthProfileNutritionResult {
  getDigitalTwinHealthProfile {
    beeAndTdeeResults(first: 1, sortBy: {field: CREATED, direction: DESC}) {
      edges {
        node {
          bee
          tdee
          queryInput {
            gender
            age
            height
            weight
            activityLevel
          }
        }
      }
    }
    goalWeightResults(first: 1, sortBy: {field: CREATED, direction: DESC}) {
      edges {
        node {
          newCalorieIntake
          caloriesPerDayToAdjust
          newTDEE
          queryInput {
            bee
            days
            goalWeight
            newActivityLevel
            weight
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDigitalTwinHealthProfileNutritionResultQuery__
 *
 * To run a query within a React component, call `useGetDigitalTwinHealthProfileNutritionResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalTwinHealthProfileNutritionResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalTwinHealthProfileNutritionResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDigitalTwinHealthProfileNutritionResultQuery(baseOptions?: Apollo.QueryHookOptions<GetDigitalTwinHealthProfileNutritionResultQuery, GetDigitalTwinHealthProfileNutritionResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDigitalTwinHealthProfileNutritionResultQuery, GetDigitalTwinHealthProfileNutritionResultQueryVariables>(GetDigitalTwinHealthProfileNutritionResultDocument, options);
      }
export function useGetDigitalTwinHealthProfileNutritionResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDigitalTwinHealthProfileNutritionResultQuery, GetDigitalTwinHealthProfileNutritionResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDigitalTwinHealthProfileNutritionResultQuery, GetDigitalTwinHealthProfileNutritionResultQueryVariables>(GetDigitalTwinHealthProfileNutritionResultDocument, options);
        }
export type GetDigitalTwinHealthProfileNutritionResultQueryHookResult = ReturnType<typeof useGetDigitalTwinHealthProfileNutritionResultQuery>;
export type GetDigitalTwinHealthProfileNutritionResultLazyQueryHookResult = ReturnType<typeof useGetDigitalTwinHealthProfileNutritionResultLazyQuery>;
export type GetDigitalTwinHealthProfileNutritionResultQueryResult = Apollo.QueryResult<GetDigitalTwinHealthProfileNutritionResultQuery, GetDigitalTwinHealthProfileNutritionResultQueryVariables>;