import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const DiseaseIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='43.782' height='45.534' viewBox='0 0 43.782 45.534' {...props}>
      <path
        id='icons8-disease'
        d='M24.015,2a3.5,3.5,0,0,0-1.751,6.53v.578a15.7,15.7,0,0,0-9.471,4.6l-2.839-2.36v0A3.518,3.518,0,1,0,7.71,14.04l2.925,2.429a15.663,15.663,0,0,0-2.381,8.3c0,.165.019.325.024.489A3.5,3.5,0,1,0,9.3,30.373,15.718,15.718,0,0,0,11.709,34.6L9.16,37.145a3.53,3.53,0,1,0,2.473,2.48l2.552-2.552a15.7,15.7,0,0,0,5.5,2.839,3.5,3.5,0,1,0,5.569.554A15.649,15.649,0,0,0,30.8,38.979l2.336,3.5a3.5,3.5,0,1,0,3.14-1.953q-.111,0-.222.01l-2.275-3.41a15.827,15.827,0,0,0,3.626-4.07l2.415.965a3.505,3.505,0,1,0,1.3-3.249l-2.21-.886a15.657,15.657,0,0,0,.8-3.817,3.481,3.481,0,0,0-.8-6.431,15.7,15.7,0,0,0-1.837-3.7l1.8-1.8a3.53,3.53,0,1,0-2.473-2.48L34.78,13.277a15.684,15.684,0,0,0-9.013-4.17V8.533A3.5,3.5,0,0,0,24.015,2ZM18.762,17.762a1.751,1.751,0,1,1-1.751,1.751A1.752,1.752,0,0,1,18.762,17.762Zm12.259,5.254a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,31.021,23.015ZM19.637,28.269A2.627,2.627,0,1,1,17.01,30.9,2.628,2.628,0,0,1,19.637,28.269Z'
        transform='translate(-3 -2)'
        fill={theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
