import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const SleepIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='32' height='36' viewBox='0 0 32 36' {...props}>
      <g id='icons8-sleep_1_' data-name='icons8-sleep (1)' transform='translate(-8 -6)'>
        <path id='Path_21277' data-name='Path 21277' d='M13,30H23v3l-6,6h6v3H13V39l5.938-6H13Z' fill='#b39ddb' />
        <path id='Path_21278' data-name='Path 21278' d='M28,16H40v3l-8.313,9H40v3H28V28l7.938-9H28Z' fill='#7e57c2' />
        <path id='Path_21279' data-name='Path 21279' d='M19,10H8V6H24.062L24,10,13,22H24v4H8V22.062Z' fill='#512da8' />
      </g>
    </SvgIcon>
  );
};
