import React from "react";
import { Box, Button, IconButton, Typography } from "@toolkit/ui";
import { useServiceListItemStyles } from "./useServiceListItemStyles";
import { InfoIcon } from "../Icons";
import { ServiceListItemProps } from "./types";

const ServiceListItem: React.FC<ServiceListItemProps> = ({ id, title, icon: Icon, buttonLabel, onClick, buttonSx, color = "error" }) => {
  const { classes } = useServiceListItemStyles({ color });

  return (
    <Box key={id} className={classes.listItem}>
      <Box className={classes.iconContainer}>{Icon}</Box>
      <Box className={classes.statusIndicator}>
        <Box className={classes.statusDot} />
      </Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <IconButton edge='end' aria-label='info' sx={{ marginRight: "5px" }}>
          <InfoIcon fontSize='small' />
        </IconButton>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button sx={buttonSx} onClick={onClick} className={classes.button}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default ServiceListItem;
