import { Box, IconButton, MuiArrowBackIosNewIcon, MuiArrowForwardIosIcon, Typography } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useArticleHeaderStyle } from "./useArticleHeaderStyle";

type ArticleHeaderProps = {
  image: string;
  title: string;
};

export const ArticleHeader: FC<ArticleHeaderProps> = props => {
  const { image, title } = props;

  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();

  const { classes } = useArticleHeaderStyle();

  const onBackIconClick = () => {
    navigate(-1);
  };

  return (
    <Box className={classes.root}>
      <Box
        className={classes.image}
        sx={{
          background: `url(${image}), url(icons/article.svg)`,
          backgroundPosition: "center, 50% 50%",
          backgroundSize: "cover, auto",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#606062",
        }}
      />

      <Box className={classes.headerWrapper}>
        <IconButton onClick={onBackIconClick}>
          {pickLocalizedValue(
            <MuiArrowBackIosNewIcon className={classes.whiteColor} fontSize={"medium"} />,
            <MuiArrowForwardIosIcon className={classes.whiteColor} fontSize={"medium"} />
          )}
        </IconButton>

        <Typography className={classes.header}>{t("Articles")}</Typography>
      </Box>

      <Box className={classes.titleWrapper}>
        <Typography className={classes.whiteColor}>{title}</Typography>
      </Box>
    </Box>
  );
};
