import { makeStyles } from "@toolkit/ui";

export const useStatsItemStyles = makeStyles()(theme => ({
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.xxl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
