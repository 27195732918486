import { makeStyles } from "@toolkit/ui";

export const useLifeExpectancyFormCardStyles = makeStyles()(theme => ({
  card: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    borderRadius: "10px",
    height: "max-content",
    position: "relative",
  },
  tabContainer: {
    position: "absolute",
    display: "flex",
    left: "0px",
    top: "0px",
    height: "27px",
    width: "100%",
    justifyContent: "space-around",
    padding: "10px",
  },
  tab: {
    position: "relative",
    display: "inline-block",
    paddingBottom: "10px",
    height: "30px",
    padding: "4px",
    "&.active::after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "3px",
      backgroundColor: theme.palette.text.primary,
      borderRadius: "50px",
      position: "absolute",
      bottom: "0px",
      left: "0px",
    },
    "&.inactive::after": {
      content: '""',
      display: "block",
      width: "100%",
      height: "3px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50px",
      position: "absolute",
      bottom: "0px",
      left: "0px",
    },
    "&.inactive": {
      color: theme.palette.primary.main,
    },
    "&.active": {
      color: theme.palette.text.primary,
    },
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  content: {
    marginTop: "60px",
    width: "100%",
  },
}));
