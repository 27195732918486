/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleGetQueryVariables = Types.Exact<{
  articleId: Types.Scalars['ID'];
}>;


export type ArticleGetQuery = { __typename?: 'Query', article?: { __typename?: 'Article', title?: string | null, titleAr?: string | null, content?: string | null, contentAr?: string | null, tags?: Array<string | null> | null, headerImage?: string | null } | null };


export const ArticleGetDocument = gql`
    query ArticleGet($articleId: ID!) {
  article(id: $articleId) {
    title
    titleAr
    content
    contentAr
    tags
    headerImage
  }
}
    `;

/**
 * __useArticleGetQuery__
 *
 * To run a query within a React component, call `useArticleGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGetQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useArticleGetQuery(baseOptions: Apollo.QueryHookOptions<ArticleGetQuery, ArticleGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleGetQuery, ArticleGetQueryVariables>(ArticleGetDocument, options);
      }
export function useArticleGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleGetQuery, ArticleGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleGetQuery, ArticleGetQueryVariables>(ArticleGetDocument, options);
        }
export type ArticleGetQueryHookResult = ReturnType<typeof useArticleGetQuery>;
export type ArticleGetLazyQueryHookResult = ReturnType<typeof useArticleGetLazyQuery>;
export type ArticleGetQueryResult = Apollo.QueryResult<ArticleGetQuery, ArticleGetQueryVariables>;