import { Slider, styled } from "@toolkit/ui";

export const CustomSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 5,
  "& .MuiSlider-thumb": {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      boxShadow: theme.mixins.shadows.sm,
    },
  },
  "& .MuiSlider-rail": {
    backgroundColor: theme.palette.stale[500],
    color: theme.palette.primary[50],
    opacity: 1,
  },
  "& .MuiSlider-valueLabel": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary[50],
    padding: 12,
    height: 30,
  },
}));
