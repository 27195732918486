import { pickLocalizedValue } from "@toolkit/i18n";
import { makeStyles, Theme } from "@toolkit/ui";

export const useSideMenuStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderRadius: "15px",
    padding: "15px",
    margin: "20px 0",
  },
  paper: {
    width: "319px",
    borderRadius: pickLocalizedValue("0px 24px 24px 0px", "24px 0px 0px 24px"),
  },
  menuContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  listContainer: {
    width: "100%",
    height: "calc(100% - 240px)",
    overflowY: "auto",
    position: "absolute",
    top: "120px",
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.primary[50],
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary[50],
    },
    "&.Mui-selected .MuiListItemText-root": {
      color: theme.palette.primary[1600],
    },
    "&.Mui-selected .MuiListItemIcon-root": {
      "& path": {
        fill: theme.palette.primary[1600],
      },
    },
    paddingInlineStart: "36px",
    paddingInlineEnd: "0px",
  },
  listItemIcon: {
    "& path": {
      fill: theme.palette.primary[1600],
    },
    "& svg": {
      width: "16px",
      height: "16px",
    },
    minWidth: "30px",
  },
  listItemText: {
    color: theme.palette.text.primary,
    "& .MuiListItemText-primary": {
      fontSize: theme.mixins.fonts.fontSize.sm,
      fontWeight: theme.mixins.fonts.fontWeight.regular,
      marginLeft: "0px",
    },
  },
  divider: {
    width: "calc(100% - 20px)",
    height: "1px",
    marginY: "5px",
    marginLeft: "10px",
    backgroundColor: theme.palette.primary[50],
  },
}));
