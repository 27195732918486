import {
  ListCategory,
  ListCategoryWithItems,
  ListCategoryWithLink,
  ListCategoryWithOnClick,
  ListItem,
  ListItemWithLink,
  ListItemWithOnClick,
} from "./types";

export function getCategoryWithItems(category: ListCategory): ListCategoryWithItems | undefined {
  return "items" in category ? (category as ListCategoryWithItems) : undefined;
}

export function getCategoryWithLink(category: ListCategory): ListCategoryWithLink | undefined {
  return "link" in category ? (category as ListCategoryWithLink) : undefined;
}

export function getCategoryWithOnClick(category: ListCategory): ListCategoryWithOnClick | undefined {
  return "onClick" in category ? (category as ListCategoryWithOnClick) : undefined;
}

export function getItemWithLink(item: ListItem): ListItemWithLink | undefined {
  return "link" in item ? (item as ListItemWithLink) : undefined;
}

export function getItemWithOnClick(item: ListItem): ListItemWithOnClick | undefined {
  return "onClick" in item ? (item as ListItemWithOnClick) : undefined;
}
