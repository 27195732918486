import { makeStyles } from "@toolkit/ui";

export const useAgeExpectationCardStyles = makeStyles()(theme => ({
  card: {
    height: "fit-content",
    padding: "10px",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: "15px",
  },
  introText: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  boldText: {
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    fontSize: theme.mixins.fonts.fontSize.sm,
    display: "inline",
  },
  badgeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  badge: {
    width: "90px",
    height: "90px",
    margin: "25px",
  },
  sliderContainer: {
    display: "flex",
    flexDirection: "column",
    alignContent: "end",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    padding: "15px",
    marginTop: "10px",
  },
  button: {
    width: "260px",
    height: "45px",
    borderRadius: "28px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    marginTop: "20px",
  },
}));
