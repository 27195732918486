import { ResultsCard, ResultsCardProps } from "@/components";
import { useTranslation } from "@/i18n/i18n.config";
import { RiskLevel } from "@/schema/types";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";

export const DiabetesRiskResultCard: FC<
  {
    score: number;
    result: RiskLevel;
  } & ResultsCardProps
> = ({ result, isLoading, isDataReady }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <ResultsCard
      isLoading={isLoading}
      isDataReady={isDataReady}
      customPlaceholder={t("Fill in the details below to calculate your risk of developing diabetes")}
    >
      <Box display={"flex"} flexDirection={"column"} py={2}>
        <Typography variant='h4' color='textPrimary'>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Typography component={"span"} fontWeight={500} color={result === RiskLevel.High ? "red" : "green"}>
              {result}
            </Typography>
          )}
        </Typography>
        <Typography variant='body2' color='textPrimary'>
          {t("Risk score for developing diabetes")}
        </Typography>
      </Box>
    </ResultsCard>
  );
};
