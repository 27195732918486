import { Option } from "@/types/radio";
import { LabeledInputContainer } from "../LabeledInputContainer";
import { FormRadio } from "../TextFields";
import { SxProps, Theme } from "@toolkit/ui";
import cx from "classnames";
export interface RadioGroupFieldProps {
  name: string;
  label: string;
  options: Option<string | boolean>[];
  mode?: "vertical" | "horizontal";
  classes?: {
    radioGroup?: string;
  };
  sx?: SxProps<Theme>;
  labelPlacement?: "top" | "bottom" | "start" | "end";
}

export const RadioGroupField: React.FC<RadioGroupFieldProps> = ({ name, label, options, labelPlacement, classes, mode, sx = {} }) => {
  const defaultClasses = useFormStyles();
  return (
    <LabeledInputContainer label={label} mode={mode} sx={sx}>
      <FormRadio
        radioGroupProps={{
          className: cx(defaultClasses.radioGroup, classes?.radioGroup),
        }}
        options={options}
        name={name}
        labelPlacement={labelPlacement || (mode === "vertical" ? "end" : "bottom")}
      />
    </LabeledInputContainer>
  );
};

const useFormStyles = () => {
  return {
    radioGroup: {
      gap: "40px",
      marginInlineStart: "-10px",
    },
  };
};

export default RadioGroupField;
