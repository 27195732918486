import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { HeightField, WeightField } from "../../fields";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useReactiveVar } from "@apollo/client";

const IBmiCalculatorFormZodSchema = z.object({
  height: z.number().min(0).max(300),
  weight: z.number().min(0).max(500),
});

export type IBmiCalculatorFormZodSchemaType = z.infer<typeof IBmiCalculatorFormZodSchema>;
export interface IBmiCalculatorFormRef {
  formMethods: ReturnType<typeof useForm<IBmiCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const BmiCalculatorForm = forwardRef<
  IBmiCalculatorFormRef,
  {
    onSubmit: (data: IBmiCalculatorFormZodSchemaType) => void;
    onChange?: (data: IBmiCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const profileData = useReactiveVar(userProfileVar);
  const methods = useForm<IBmiCalculatorFormZodSchemaType>({
    resolver: zodResolver(IBmiCalculatorFormZodSchema),
    defaultValues: {
      height: profileData.height,
      weight: profileData.weight,
    },
    mode: "onChange",
  });

  methods.watch(data => {
    props.onChange?.(data as IBmiCalculatorFormZodSchemaType);
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });

  useImperativeHandle(ref, () => ({
    formMethods: methods,
    handleSubmit,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <WeightField />
        <HeightField />
      </form>
    </FormProvider>
  );
});
