import { useTranslation } from "@/i18n/i18n.config";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import { BmiCalculatorForm, IBmiCalculatorFormRef } from "./bmi-calculator-form/BmiCalculatorForm";
import { BmiResultCard } from "./bmi-results/BmiResultCard";
import { routes } from "@/pages/age-dynamics-and-calculators/routes";

export const BmiCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const ref = useRef<IBmiCalculatorFormRef>(null);
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const handleSubmit = debounce(data => {
    setWeight(data.weight);
    setHeight(data.height);
  }, 100);
  useEffect(() => {
    handleSubmit(ref.current?.formMethods.watch());
  }, []);
  return (
    <CalculatorsPageLayout title={t("BMI Calculator")} footer={<DefaultFooter handleSubmit={() => navigate(routes.root.route)} />}>
      <BmiResultCard height={height ?? 0} weight={weight ?? 0} isLoading={false} isDataReady={true} />
      <BmiCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={handleSubmit} />
    </CalculatorsPageLayout>
  );
};
