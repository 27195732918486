/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DepressionScreeningQueryVariables = Types.Exact<{
  input: Types.DepressionScreeningInput;
}>;


export type DepressionScreeningQuery = { __typename?: 'Query', depressionScreening?: { __typename?: 'DepressionScreeningResponse', score?: number | null, riskLevel?: Types.RiskLevel | null } | null };


export const DepressionScreeningDocument = gql`
    query DepressionScreening($input: DepressionScreeningInput!) {
  depressionScreening(input: $input) {
    score
    riskLevel
  }
}
    `;

/**
 * __useDepressionScreeningQuery__
 *
 * To run a query within a React component, call `useDepressionScreeningQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepressionScreeningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepressionScreeningQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepressionScreeningQuery(baseOptions: Apollo.QueryHookOptions<DepressionScreeningQuery, DepressionScreeningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepressionScreeningQuery, DepressionScreeningQueryVariables>(DepressionScreeningDocument, options);
      }
export function useDepressionScreeningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepressionScreeningQuery, DepressionScreeningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepressionScreeningQuery, DepressionScreeningQueryVariables>(DepressionScreeningDocument, options);
        }
export type DepressionScreeningQueryHookResult = ReturnType<typeof useDepressionScreeningQuery>;
export type DepressionScreeningLazyQueryHookResult = ReturnType<typeof useDepressionScreeningLazyQuery>;
export type DepressionScreeningQueryResult = Apollo.QueryResult<DepressionScreeningQuery, DepressionScreeningQueryVariables>;