import { makeStyles } from "@toolkit/ui";

export const useArticleHeaderStyle = makeStyles()(theme => ({
  root: {
    position: "relative",
    width: "100%",
    height: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  headerWrapper: {
    position: "relative",
    padding: "16px",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  header: {
    flex: 1,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: theme.palette.common.white,
    fontSize: theme.mixins.fonts.fontSize.xxl,
  },
  titleWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px",
  },
}));
