import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CalculatorHeaderCard } from "../CalculatorHeaderCard/CalculatorHeaderCard";
import { Card, CircularProgress } from "@toolkit/ui";
import { CalculatorNutritionResult } from "../CalculatorNutritionResult/CalculatorNutritionResult";
import { CalculatorFooterCard } from "../CalculatorFooterCard/CalculatorFooterCard";
import { useCalculatorNutritionStepResultStyle } from "./useCalculatorNutritionStepResultStyle";
import { ArticleCategoryCode } from "@/domains/Articles/codes";

export type ICalculatorNutritionStepResultEvent = {
  type: "CARD_CLICK";
};

type CalculatorNutritionStepResultProps = {
  tdeeValue?: number;
  newTdeeValue?: number;
  beeValue?: number;
  goalWeight?: number;
  daysToAchieveGoalWeight?: number;
  targetCalories?: number;
  maxTdee?: number;
  reduceCalorie?: number;
  isLoading?: boolean;
  onChange: (event: ICalculatorNutritionStepResultEvent) => void;
};

export const CalculatorNutritionStepResult: FC<CalculatorNutritionStepResultProps> = props => {
  const {
    tdeeValue,
    newTdeeValue,
    beeValue,
    goalWeight,
    daysToAchieveGoalWeight,
    targetCalories,
    maxTdee,
    reduceCalorie,
    isLoading,
    onChange,
  } = props;

  const { t } = useTranslation("digital-twin");

  const { classes } = useCalculatorNutritionStepResultStyle();

  const onCalculatorHeaderCardClick = () => {
    onChange({
      type: "CARD_CLICK",
    });
  };

  return (
    <>
      {isLoading ? (
        <Card className={classes.card}>
          <CircularProgress size={50} />
        </Card>
      ) : (
        <>
          <CalculatorHeaderCard
            title={t("Thank you for setting your goals!")}
            description={t("You can go back to modify your goal at any time!")}
            onClick={onCalculatorHeaderCardClick}
          />

          <CalculatorNutritionResult
            tdeeValue={tdeeValue}
            beeValue={beeValue}
            goalWeight={goalWeight}
            targetCalories={targetCalories}
            maxTdee={maxTdee}
            reduceCalorieIntake={reduceCalorie}
            daysToAchieveGoalWeight={daysToAchieveGoalWeight}
            newTdeeValue={newTdeeValue}
          />

          <CalculatorFooterCard mainTitle={t("Losing Weight?")} articleCategory={ArticleCategoryCode.nutritionArticles} />
        </>
      )}
    </>
  );
};
