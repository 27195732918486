import { makeStyles } from "@toolkit/ui";

export const useBMIStyles = makeStyles()(theme => ({
  container: {
    padding: "10px",
    height: "max-content",
    maxHeight: "100%",
  },
  sliderContainer: {
    marginTop: "35px",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary[50],
    paddingInline: "10px",
  },
  joinCareContainer: {
    marginTop: "30px",
  },
  fitnessCard: {
    marginTop: "15px",
    paddingBottom: "15px",
  },
}));
