import { Box, Card, Typography, useTheme } from "@toolkit/ui";
import { LifeSlider } from "../../../../components";
import { useFormContext } from "react-hook-form";
import { Trans } from "@toolkit/i18n";
import { useTranslation } from "../../../../i18n/i18n.config";
import useStyles from "./LifeExpectancyCard.styles";
import { getLifeSliderProps } from "../../../../components/Sliders/LifeSlider";

type LifeExpectancyCardProps = {
  lifeExpectancy?: number;
  averageAge?: number;
};

export const LifeExpectancyCard: React.FC<LifeExpectancyCardProps> = ({ lifeExpectancy, averageAge }) => {
  const { watch } = useFormContext();
  const age = watch("age");
  const theme = useTheme();
  const { t } = useTranslation("digital-twin");
  const { classes } = useStyles();
  const lifeStyleProps = getLifeSliderProps(
    {
      age,
      lifeExpectancy,
      averageAge,
    },
    theme
  );

  return (
    <Card className={classes.card}>
      <Typography className={classes.introText}>
        <Trans
          t={t}
          ns='digital-twin'
          i18nKey={"life-slider-intro-text"}
          components={[<Typography key='bold' className={classes.boldText} />]}
        />
      </Typography>
      <Typography className={classes.infoText}>
        {t("Your answers below will be reflected directly on your Estimated Life Expectancy")}
      </Typography>
      <Box className={classes.sliderContainer}>
        <LifeSlider {...lifeStyleProps} />
      </Box>
      <Box className={classes.marginTop}></Box>
    </Card>
  );
};
