import React, { FC } from "react";
import { Box, Card, Typography } from "@toolkit/ui";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { SleepTimeClockSvg } from "../SleepTimeClockSvg/SleepTimeClockSvg";
import { useSleepTimeClockStyle } from "./useSleepTimeClockStyle";

type CircularProgressClockProps = {
  sleepTime?: string;
  selectedTime?: string;
  basedOnSleepTime?: boolean;
  description?: string;
  sleepCycles?: number | null;
  radius?: number;
  strokeWidth?: number;
};

export const CircularProgressClock: FC<CircularProgressClockProps> = props => {
  const { basedOnSleepTime, sleepTime, selectedTime, description, sleepCycles, radius = 120, strokeWidth = 20 } = props;

  const { t } = useTranslation("digital-twin");

  const { classes, theme } = useSleepTimeClockStyle();

  const center = radius + 40;

  const sleepTimeMoment = moment(sleepTime, "HH:mm");
  const selectedTimeFormat = selectedTime ? moment(selectedTime, "HH:mm A").format("hh:mm A") : undefined;
  const startCalculationTime = basedOnSleepTime ? sleepTimeMoment.format("hh:mm A") : selectedTimeFormat;
  const endCalculationTime = basedOnSleepTime ? selectedTimeFormat : sleepTimeMoment.format("hh:mm A");

  return (
    <>
      <Card className={classes.root} sx={{ position: "relative" }}>
        <SleepTimeClockSvg
          radius={radius}
          center={center}
          strokeWidth={strokeWidth}
          startCalculationTime={startCalculationTime}
          endCalculationTime={endCalculationTime}
          sleepTime={sleepTime ? sleepTimeMoment.format("h:mm A") : undefined}
          label={description}
          sleepCycles={sleepCycles!}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
            flexDirection: "column",
            paddingTop: "40px",
          }}
        >
          <Typography>{description}</Typography>

          {sleepCycles && (
            <Typography sx={{ color: theme.palette.primary.main }}>{t("{{sleepCycles}} Sleep Cycles", { sleepCycles })}</Typography>
          )}
        </Box>
      </Card>

      {startCalculationTime && endCalculationTime && (
        <Card className={classes.timesCard}>
          <Box className={classes.timesWrapper}>
            <Typography className={classes.timeLabel}>
              {t("Recommended Sleep Time")}: {moment(endCalculationTime, "hh:mm A").format("h:mm A")}
            </Typography>

            <Typography className={classes.timeLabel}>
              {t("Recommended Wakeup Time")}: {moment(startCalculationTime, "hh:mm A").format("h:mm A")}
            </Typography>
          </Box>
        </Card>
      )}
    </>
  );
};
