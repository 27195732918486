import { useTranslation } from "@/i18n/i18n.config";
import { Box, Card, CircularProgress, Typography } from "@toolkit/ui";
import { FC, PropsWithChildren, ReactNode } from "react";

export interface ResultsCardProps {
  isDataReady: boolean;
  customPlaceholder?: ReactNode;
  isLoading: boolean;
}
export const ResultsCard: FC<PropsWithChildren<ResultsCardProps>> = ({ children, isDataReady, isLoading, customPlaceholder }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <Card sx={{ minHeight: 100 }}>
      {isDataReady ? (
        <Box display={"flex"} flexDirection={"column"} py={2}>
          {isLoading && <CircularProgress size={20} />}
          {children}
        </Box>
      ) : (
        <Typography
          sx={{ minHeight: 100, px: 2 }}
          variant='body2'
          color='textPrimary'
          justifySelf={"column"}
          alignSelf={"center"}
          alignContent={"center"}
        >
          {isLoading && <CircularProgress size={20} />} {customPlaceholder || t("Please fill in the form to get the result")}
        </Typography>
      )}
    </Card>
  );
};
