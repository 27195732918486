import { makeStyles } from "@toolkit/ui";

type LabeledInputContainerStylesProps = {
  mode: "horizontal" | "vertical" | "long-options";
};

const useStyles = makeStyles<LabeledInputContainerStylesProps>()((theme, { mode }) => ({
  container: {
    display: "flex",
    padding: mode === "vertical" ? "10px 0" : "16px 0",
    flexDirection: mode === "vertical" ? "column" : "row",
    justifyContent: mode === "vertical" ? "start" : "center",
    gap: mode === "vertical" ? 2 : 5,
    alignItems: mode === "vertical" ? "start" : "center",
    height: "fit-content",
    position: "relative",
    minHeight: "80px",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary[50],
    fontWeight: 500,
  },
  label: {
    marginBottom: mode === "horizontal" ? "0" : "15px",
    width: mode === "horizontal" ? "30%" : "100%",
    textAlign: "start",
    textTransform: "capitalize",
  },
  content: {
    width: mode === "horizontal" ? "70%" : "100%",
  },
}));

export default useStyles;
