/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Box, Card, Chip, CircularProgress, Divider, MDTextEditor, Typography } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useTranslation } from "react-i18next";
import { ArticleHeader } from "@/domains/Articles/components/ArticleHeader/ArticleHeader";
import { useArticleGetQuery } from "@/domains/Articles/gql/queries";
import { useArticleDetailsStyle } from "./useArticleDetailsStyle";
import { useParams } from "react-router-dom";
import { IArticleDetailsParams } from "@/domains/Articles/types";
import React from "react";

export const ArticleDetails = () => {
  const { t } = useTranslation("digital-twin");

  const params = useParams<IArticleDetailsParams>();

  const { classes } = useArticleDetailsStyle();

  const { data, loading } = useArticleGetQuery({
    variables: {
      articleId: params?.articleId!,
    },
    skip: !params?.articleId,
  });

  const article = data?.article;

  return (
    <Box className={classes.root}>
      <ArticleHeader image={article?.headerImage!} title={pickLocalizedValue(article?.title!, article?.titleAr!)} />

      {loading ? (
        <CircularProgress color={"inherit"} size={20} />
      ) : (
        <Card className={classes.card}>
          <Box className={classes.symptomsWrapper}>
            <Typography className={classes.symptoms}> {t("Symptoms")}: </Typography>

            <Box className={classes.tagsWrapper}>
              {article?.tags?.length ? (
                article?.tags?.map(item => (
                  <Chip key={item} label={<Typography className={classes.chipLabel}> {item} </Typography>} className={classes.chip} />
                ))
              ) : (
                <Typography> {t("There are no symptoms available")} </Typography>
              )}
            </Box>
          </Box>

          <Divider className={classes.divider} />

          <MDTextEditor value={pickLocalizedValue(article?.content!, article?.contentAr!)} height={400} readonly />
        </Card>
      )}
    </Box>
  );
};
