import { useTranslation } from "@/i18n/i18n.config";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { ResultsCard, ResultsCardProps } from "@/components/ResultsCard";

export const IdealBodyWeightResultCard: FC<
  {
    weight: number;
  } & ResultsCardProps
> = ({ weight, isLoading, isDataReady }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <ResultsCard isDataReady={isDataReady} isLoading={isLoading}>
      <Box display={"flex"} flexDirection={"column"} py={2}>
        <Typography variant='body2' color='textPrimary'>
          {t("Ideal Body Weight")}
        </Typography>
        <Typography variant='h4' color='textPrimary' fontWeight={500}>
          {isLoading ? <CircularProgress size={20} /> : weight}
        </Typography>
      </Box>
    </ResultsCard>
  );
};
