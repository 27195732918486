import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const FoodIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='32.195' height='34' viewBox='0 0 32.195 34' {...props}>
      <g id='icons8-healthy-food' transform='translate(-4.005 -4)'>
        <path
          id='Path_21260'
          data-name='Path 21260'
          d='M42.2,6.244a1.608,1.608,0,0,0-3.031-.3,1.194,1.194,0,0,0-1.6.465C37.539,6.472,35,10.634,35,10.634l1.61,1.61S40.772,9.7,40.835,9.668a1.194,1.194,0,0,0,.465-1.6A1.61,1.61,0,0,0,42.2,6.244Z'
          transform='translate(-6.048 0)'
          fill='#8bc34a'
        />
        <path
          id='Path_21261'
          data-name='Path 21261'
          d='M34.308,11.4c-.8-.8-2.535-2.4-4.4-2.4a3.6,3.6,0,0,0-2.993,1.6c-.383.571-9,14.794-7.8,15.994s15.421-7.42,15.994-7.8a3.6,3.6,0,0,0,1.6-2.993C36.707,13.934,35.108,12.2,34.308,11.4Z'
          transform='translate(-2.926 -0.78)'
          fill='#ff8f00'
        />
        <path
          id='Path_21262'
          data-name='Path 21262'
          d='M34.219,15.219a.8.8,0,0,1-.569-.236l-1.61-1.61a.8.8,0,0,1,1.138-1.138l1.61,1.61a.8.8,0,0,1-.569,1.374Zm0,4.829a.8.8,0,0,1-.569-.236L31.236,17.4a.8.8,0,0,1,1.138-1.138l2.415,2.415a.8.8,0,0,1-.569,1.374Z'
          transform='translate(-5.267 -1.366)'
          fill='#ef6c00'
        />
        <path
          id='Path_21263'
          data-name='Path 21263'
          d='M17,9.024V6.61A1.609,1.609,0,0,1,18.61,5'
          transform='translate(-2.536)'
          fill='none'
          stroke='#8bc34a'
          stroke-linecap='round'
          stroke-width='2'
        />
        <path
          id='Path_21264'
          data-name='Path 21264'
          d='M23.707,13.447a27.014,27.014,0,0,1-2.415,11.236C20.6,26.059,18.4,26.9,17.9,27.065a1.01,1.01,0,0,1-.1.032H11.9a1.01,1.01,0,0,1-.1-.032c-.491-.169-2.7-1.006-3.389-2.382A27.014,27.014,0,0,1,6,13.447,2.2,2.2,0,0,1,8.213,11h.08a1.3,1.3,0,0,1,.177.008,14.825,14.825,0,0,1,3.429.8h5.908a14.824,14.824,0,0,1,3.429-.8A1.3,1.3,0,0,1,21.413,11h.08A2.2,2.2,0,0,1,23.707,13.447Z'
          transform='translate(-0.389 -1.171)'
          fill='#ff3d00'
        />
        <path
          id='Path_21265'
          data-name='Path 21265'
          d='M12.493,27.1h5.892a7.856,7.856,0,0,0,1.682-2.7,44.4,44.4,0,0,0,1.811-12.6,5.019,5.019,0,0,0-.056-.8,14.825,14.825,0,0,0-3.429.8H12.485a14.824,14.824,0,0,0-3.429-.8,5.019,5.019,0,0,0-.056.8,44.4,44.4,0,0,0,1.811,12.6A7.856,7.856,0,0,0,12.493,27.1Z'
          transform='translate(-0.975 -1.173)'
          fill='#dd2c00'
        />
        <path
          id='Path_21266'
          data-name='Path 21266'
          d='M18.579,9c-1.14,0-1.488.8-2.749.8S14.22,9,13.08,9Q11,9,11,11.415a43.257,43.257,0,0,0,1.725,12.073c.573,1.671,2.22,3.193,3.1,3.22.885-.027,2.531-1.549,3.1-3.22a43.257,43.257,0,0,0,1.725-12.073Q20.659,9,18.579,9Z'
          transform='translate(-1.365 -0.78)'
          fill='#ff3d00'
        />
        <path
          id='Path_21267'
          data-name='Path 21267'
          d='M34.976,22l0,2.646A13.261,13.261,0,0,1,26.83,36.488H14.153A13.264,13.264,0,0,1,6,24.646L6,22.8'
          transform='translate(-0.389 -3.317)'
          fill='#ffb74d'
        />
        <path
          id='Path_21268'
          data-name='Path 21268'
          d='M34.59,23.22a1.61,1.61,0,1,0,0-3.22H5.615a1.61,1.61,0,1,0,0,3.22Z'
          transform='translate(0 -2.927)'
          fill='#ffcc80'
        />
        <path
          id='Path_21269'
          data-name='Path 21269'
          d='M14.22,29.61H11.8a.8.8,0,0,1,0-1.61H14.22a.8.8,0,0,1,0,1.61Zm12.073,0H19.854a.8.8,0,1,1,0-1.61h6.439a.8.8,0,1,1,0,1.61Zm-6.439,4.829h-3.22a.8.8,0,1,1,0-1.61h3.22a.8.8,0,1,1,0,1.61Z'
          transform='translate(-1.365 -4.488)'
          fill='#ff9100'
        />
        <path
          id='Path_21270'
          data-name='Path 21270'
          d='M28,31h5.634'
          transform='translate(-4.682 -5.073)'
          fill='none'
          stroke='#995f1a'
          stroke-linejoin='round'
          stroke-width='2'
        />
        <path
          id='Path_21271'
          data-name='Path 21271'
          d='M26.76,40.927H38.532a1.761,1.761,0,0,0,1.761-1.76V21.761A1.761,1.761,0,0,0,38.532,20H26.76A1.761,1.761,0,0,0,25,21.761V39.167A1.761,1.761,0,0,0,26.76,40.927Z'
          transform='translate(-4.097 -2.927)'
          fill='#616161'
        />
        <path
          id='Path_21272'
          data-name='Path 21272'
          d='M39.76,32.415H38.654A.653.653,0,0,1,38,31.761V30.654A.653.653,0,0,1,38.654,30H39.76a.653.653,0,0,1,.654.654v1.107A.653.653,0,0,1,39.76,32.415Z'
          transform='translate(-6.633 -4.878)'
          fill='#ff5252'
        />
        <path
          id='Path_21273'
          data-name='Path 21273'
          d='M29.76,32.415H28.654A.653.653,0,0,1,28,31.761V30.654A.653.653,0,0,1,28.654,30H29.76a.653.653,0,0,1,.654.654v1.107A.653.653,0,0,1,29.76,32.415Zm4.679-.654V30.654A.653.653,0,0,0,33.785,30H32.678a.653.653,0,0,0-.654.654v1.107a.653.653,0,0,0,.654.654h1.107A.653.653,0,0,0,34.439,31.761Zm-4.024,4.024V34.679a.653.653,0,0,0-.654-.654H28.654a.653.653,0,0,0-.654.654v1.107a.653.653,0,0,0,.654.654H29.76A.653.653,0,0,0,30.415,35.785Zm4.024,0V34.679a.653.653,0,0,0-.654-.654H32.678a.653.653,0,0,0-.654.654v1.107a.653.653,0,0,0,.654.654h1.107A.653.653,0,0,0,34.439,35.785Zm4.024,0V34.679a.653.653,0,0,0-.654-.654H36.7a.653.653,0,0,0-.654.654v1.107a.653.653,0,0,0,.654.654h1.107A.653.653,0,0,0,38.463,35.785ZM30.415,39.81V38.7a.653.653,0,0,0-.654-.654H28.654A.653.653,0,0,0,28,38.7V39.81a.653.653,0,0,0,.654.654H29.76A.653.653,0,0,0,30.415,39.81Zm4.024,0V38.7a.653.653,0,0,0-.654-.654H32.678a.653.653,0,0,0-.654.654V39.81a.653.653,0,0,0,.654.654h1.107A.653.653,0,0,0,34.439,39.81Zm4.024,0V38.7a.653.653,0,0,0-.654-.654H36.7a.653.653,0,0,0-.654.654V39.81a.653.653,0,0,0,.654.654h1.107A.653.653,0,0,0,38.463,39.81Z'
          transform='translate(-4.682 -4.878)'
          fill='#e0e0e0'
        />
        <path
          id='Path_21274'
          data-name='Path 21274'
          d='M40.293,26.439V21.76A1.761,1.761,0,0,0,38.532,20H26.76A1.761,1.761,0,0,0,25,21.761v4.678Z'
          transform='translate(-4.097 -2.927)'
          fill='#424242'
        />
        <path id='Path_21275' data-name='Path 21275' d='M27,22H39.073v3.22H27Z' transform='translate(-4.487 -3.317)' fill='#9ccc65' />
        <path id='Path_21276' data-name='Path 21276' d='M39,23h1.61v1.61H39Z' transform='translate(-6.828 -3.512)' fill='#33691e' />
      </g>
    </SvgIcon>
  );
};
