import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useLifeSlideStyles = makeStyles()(theme => ({
  valueLabelBox: {
    "&::before": {
      content: "''",
      position: "absolute",
      width: "14px",
      height: "14px",
      borderRadius: "50%",
      marginTop: "29px",
      left: "calc(50% - 7px)",
    },
  },
  valueLabelTypography: {
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    display: "block",
    position: "absolute",
    top: "20px",
    width: "90px",
    textAlign: "center",
    marginTop: "20px",
    maxWidth: "84px",
    lineHeight: "1.2",
    whiteSpace: "normal",
    fontSize: theme.mixins.fonts.fontSize.xs,
    wordBreak: "normal",
  },
  slider: {
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-rail": {
      height: "8px",
      opacity: 1,
      background: `linear-gradient(${pickLocalizedValue(
        "90deg",
        "-90deg"
      )}, hsla(32, 100%, 50%, 1) 0%, hsla(203, 82%, 46%, 1) 50%, hsla(127, 100%, 37%, 1) 100%)`,
      filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#FF8900", endColorstr="#158DD8", GradientType=1)',
    },
    "& .MuiSlider-thumb": {
      backgroundColor: "unset",
      "&::before": {
        content: "''",
        position: "absolute",
        height: 20,
        width: 20,
        opacity: 0.5,
        backgroundColor: theme.palette.primary[50],
      },
      "&::after": {
        content: "''",
        position: "absolute",
        width: "14px",
        height: "14px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary[1600],
      },
    },
  },
}));
