/* eslint-disable sonarjs/cognitive-complexity */
/**
 * this type must be updated to have new variables
 * .env convention
 * VAR_NAME --> this is the only thing we add to this type assuming you are folloing the convention
 * REACT_APP_VAR_NAME=${VAR_NAME}
 * NEXT_PUPLIC_VAR_NAME=${VAR_NAME}
 *
 */
import { Base64 } from "js-base64";
const getDefaultVariables = () => {
    return {
        SERVER_URL: process.env.SERVER_URL || process.env.REACT_APP_SERVER_URL || process.env.NEXT_PUBLIC_SERVER_URL,
        BASE_URL: process.env.BASE_URL || process.env.REACT_APP_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL,
        SUBSCRIPTION_URL: process.env.SUBSCRIPTION_URL || process.env.REACT_APP_SUBSCRIPTION_URL || process.env.NEXT_PUBLIC_SUBSCRIPTION_URL,
        FILE_SERVICE_API_URL: process.env.FILE_SERVICE_API_URL || process.env.REACT_APP_FILE_SERVICE_API_URL || process.env.NEXT_PUBLIC_FILE_SERVICE_API_URL,
        BASE_MEDIA_URL: process.env.BASE_MEDIA_URL || process.env.REACT_APP_BASE_MEDIA_URL || process.env.NEXT_PUBLIC_BASE_MEDIA_URL,
        GOOGLE_API_KEY: process.env.GOOGLE_API_KEY || process.env.REACT_APP_GOOGLE_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
        KEYCLOAK_BASE_URL: process.env.KEYCLOAK_BASE_URL || process.env.REACT_APP_KEYCLOAK_BASE_URL || process.env.NEXT_PUBLIC_KEYCLOAK_BASE_URL,
        KEYCLOAK_REALM: process.env.KEYCLOAK_REALM || process.env.REACT_APP_KEYCLOAK_REALM || process.env.NEXT_PUBLIC_KEYCLOAK_REALM,
        KEYCLOAK_REALM_LINK: process.env.KEYCLOAK_REALM_LINK || process.env.REACT_APP_KEYCLOAK_REALM_LINK || process.env.NEXT_PUBLIC_KEYCLOAK_REALM_LINK,
        KEYCLOAK_API_URL: process.env.KEYCLOAK_API_URL || process.env.REACT_APP_KEYCLOAK_API_URL || process.env.NEXT_PUBLIC_KEYCLOAK_API_URL,
        KEYCLOAK_CLIENT_ID_ADMIN: process.env.REACT_APP_KEYCLOAK_CLIENT_ID_ADMIN,
        KEYCLOAK_CLIENT_ID_GUIDED_CARE: process.env.REACT_APP_KEYCLOAK_CLIENT_ID_GUIDED_CARE,
        KEYCLOAK_CLIENT_ID_PROVIDER: process.env.REACT_APP_KEYCLOAK_CLIENT_ID_PROVIDER,
        KEYCLOAK_CLIENT_ID_CONSUMER: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID_CONSUMER,
        MEETORA_BASE_URL: process.env.MEETORA_BASE_URL || process.env.REACT_APP_MEETORA_BASE_URL || process.env.NEXT_PUBLIC_MEETORA_BASE_URL,
        NEXTAUTH_URL: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        SADA_BASE_URL: process.env.NEXT_PUBLIC_SADA_BASE_URL,
        TOLGEE_API_KEY: process.env.TOLGEE_API_KEY || process.env.REACT_APP_TOLGEE_API_KEY || process.env.NEXT_PUBLIC_TOLGEE_API_KEY,
        TOLGEE_API_URL: process.env.TOLGEE_API_URL || process.env.REACT_APP_TOLGEE_API_URL || process.env.NEXT_PUBLIC_TOLGEE_API_URL,
        ENABLE_TOLGEE_WIZARD: process.env.ENABLE_TOLGEE_WIZARD || process.env.REACT_APP_ENABLE_TOLGEE_WIZARD || process.env.NEXT_PUBLIC_ENABLE_TOLGEE_WIZARD,
        DIGITAL_TWIN_LOGIN_URL: process.env.DIGITAL_TWIN_LOGIN_URL || process.env.REACT_APP_DIGITAL_TWIN_LOGIN_URL,
        TOLGEE_PROJECT_ID: process.env.TOLGEE_PROJECT_ID,
        KEYCLOAK_TOKEN_EXCHANGE_API: process.env.KEYCLOAK_TOKEN_EXCHANGE_API || process.env.REACT_APP_KEYCLOAK_TOKEN_EXCHANGE_API,
        KEYCLOAK_TOKEN_EXCHANGE_API_DEV: process.env.KEYCLOAK_TOKEN_EXCHANGE_API_DEV || process.env.REACT_APP_KEYCLOAK_TOKEN_EXCHANGE_API_DEV,
    };
};
export const isInNextApp = () => {
    return !!process.env.NEXT_VERSION;
};
const getWindowRunTimeEnv = () => {
    const CONFIG_FUNCTION_NAME = "getEncodedRuntimeEnv";
    if (typeof window === "undefined") {
        return null;
    }
    if (typeof window[CONFIG_FUNCTION_NAME] === "function") {
        // eslint-disable-next-line prefer-destructuring
        const getEncodedRuntimeEnv = window[CONFIG_FUNCTION_NAME];
        return JSON.parse(Base64.decode(getEncodedRuntimeEnv()).toString());
    }
};
/**
 * this function must be used in the app were ever you need to read form .env, or unexpected behaviour might happen
 * please focus in review that no direct process.env.something exist any where or feature will not work in many envs
 *
 * @param baseKey  the key that you expect to have in .env/.env.js file.
 * @returns
 */
export const createGetEnvVariable = () => {
    const defaultVariables = getDefaultVariables();
    if (typeof window === "undefined") {
        return (baseKey, fallBack) => {
            if (defaultVariables === null || defaultVariables === void 0 ? void 0 : defaultVariables[baseKey])
                return (defaultVariables === null || defaultVariables === void 0 ? void 0 : defaultVariables[baseKey]) || fallBack;
        };
    }
    let runTimeVars = getWindowRunTimeEnv();
    window["getEnvVariable"] = (baseKey, fallBack) => {
        var _a, _b;
        if (!runTimeVars) {
            runTimeVars = getWindowRunTimeEnv();
        }
        if (runTimeVars && ((_a = runTimeVars === null || runTimeVars === void 0 ? void 0 : runTimeVars[baseKey]) === null || _a === void 0 ? void 0 : _a.trim())) {
            return ((_b = runTimeVars === null || runTimeVars === void 0 ? void 0 : runTimeVars[baseKey]) === null || _b === void 0 ? void 0 : _b.trim()) || fallBack;
        }
        if (defaultVariables === null || defaultVariables === void 0 ? void 0 : defaultVariables[baseKey])
            return (defaultVariables === null || defaultVariables === void 0 ? void 0 : defaultVariables[baseKey]) || fallBack;
        else {
            if (fallBack)
                return fallBack;
            console.error({ seriousError: `env variable: ${baseKey} was not found`, env: process.env });
            console.log({ seriousError: `env variable: ${baseKey} was not found` });
            return "";
        }
    };
    return window["getEnvVariable"];
};
export const getEnvVariable = createGetEnvVariable();
