import { Theme } from "@toolkit/ui";
import { isNil } from "lodash";
import { LifeExpectancyData, LifeSliderProps, Values } from "./types";
import i18n from "../../../i18n/i18n.config";

export const getLifeSliderProps = (
  lifeExpectancyData: LifeExpectancyData,
  theme: Theme,
  preferLifeExpectancy: boolean = false
): Partial<LifeSliderProps> => {
  const lifeExpectancy = lifeExpectancyData?.lifeExpectancy ?? -1;
  const averageAge = lifeExpectancyData?.averageAge ?? -1;
  const age = lifeExpectancyData?.age ?? -1;

  const min = Math.min(...[age, averageAge, lifeExpectancy].filter(val => val != -1));
  const max = !isNil(lifeExpectancy) ? Math.max(averageAge, lifeExpectancy, age) + 5 : 120;
  const values = {
    [age]: {
      value: age,
      label: i18n.t("Your Age", { ns: "digital-twin" }),
      color: theme.palette.primary.main || "",
    },
    [lifeExpectancy]: {
      value: Number(lifeExpectancy.toFixed(1)),
      label: i18n.t("Estimated Life Expectancy", { ns: "digital-twin" }),
      color: theme.palette.primary[1600] || "",
    },
    [averageAge]: {
      value: averageAge,
      label: i18n.t("Population Average", { ns: "digital-twin" }),
      color: theme.palette.primary.main || "",
    },
  };
  if (preferLifeExpectancy) {
    values[lifeExpectancy] = {
      value: lifeExpectancy,
      label: i18n.t("Estimated Life Expectancy", { ns: "digital-twin" }),
      color: theme.palette.primary[1600] || "",
    };
  }
  delete values[-1];

  return { min, max, values };
};

export const getLabel = (values: Values, midValue: number, _values: number[]) => (value: number) => {
  const _value = value === _values[1] ? midValue : value;
  return values[_value]?.label;
};

export const getThumbColor = (values: Values, midValue: number, _values: number[]) => (value: number) => {
  const _value = value === _values[1] ? midValue : value;
  return values[_value]?.color;
};
