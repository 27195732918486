import { BrainIcon, FoodIcon, SleepIcon } from "@/components";
import { ColoredLifeCycleIcon } from "@/components/Icons/ColoredLifeCycleIcon";
import { FingerHasBloodDropIcon } from "@/components/Icons/FingerHasBloodDropIcon";
import { HeartCardiographIcon } from "@/components/Icons/HeartCardiographIcon";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { routes } from "@/pages/age-dynamics-and-calculators/routes";
import { routesPaths } from "@/routing/routesPaths";
import { i18n } from "@toolkit/i18n";

export const getCalculatorsMenu = () => [
  {
    id: "life-expectancy",
    title: i18n.t("Life Expectancy", { ns: "digital-twin" }),
    icon: <ColoredLifeCycleIcon />,
    items: [
      {
        title: i18n.t("Life Expectancy Score", { ns: "digital-twin" }),
        link: routesPaths.myDigitalTwinUpdateProfile,
      },
    ],
  },
  {
    id: "cardiovascular",
    title: i18n.t("Cardiovascular", { ns: "digital-twin" }),
    icon: <HeartCardiographIcon />,
    items: [
      { title: i18n.t("Heart Risk", { ns: "digital-twin" }), link: routes.framinghamRiskPercentagePage.absRoute },
      // {
      //   title: i18n.t("Heart Attack Risk Score", { ns: "digital-twin" }),
      //   link: routesPaths.myDigitalTwinUpdateProfile,
      //   disabled: true,
      // },
      // {
      //   title: i18n.t("Heart Disease Risk Score", { ns: "digital-twin" }),
      //   link: routesPaths.myDigitalTwinUpdateProfile,
      //   disabled: true,
      // },
    ],
  },
  {
    id: "sleep",
    title: i18n.t("Sleep", { ns: "digital-twin" }),
    icon: <SleepIcon />,
    items: [{ title: i18n.t("sleep time", { ns: "digital-twin" }), link: routes.sleepTime.absRoute }],
  },
  {
    id: "nutrition",
    title: i18n.t("Nutrition", { ns: "digital-twin" }),
    icon: <FoodIcon />,
    items: [
      {
        title: i18n.t("Body Mass Index (BMI)", { ns: "digital-twin" }),
        link: routes.bmiCalc.absRoute,
      },
      // {
      //   title: i18n.t("ideal body weight", { ns: "digital-twin" }),
      //   link: routes.idealBodyWeight.absRoute,
      // },
      { title: i18n.t("body fat percentage", { ns: "digital-twin" }), link: routes.bodyFatPercentage.absRoute },
      {
        title: i18n.t("Daily Calorie Needs", { ns: "digital-twin" }),
        link: routes.beeAndTDee.absRoute,
        // info: i18n.t("Basal Energy Expenditure (BEE) and Total Daily Energy Expenditure (TDEE)", { ns: "digital-twin" }),),
      },
      {
        title: i18n.t("Weight Loss Plan", { ns: "digital-twin" }),
        link: routes.weightLossPlanPage.absRoute,
        get disabled() {
          return !userProfileVar().bee;
        },
        // info: i18n.t("You have to fill Daily Calorie need first", { ns: "digital-twin" }),),
      },
    ],
  },

  {
    id: "mental-health",
    icon: <BrainIcon />,
    title: i18n.t("Mental Health", { ns: "digital-twin" }),
    items: [
      { title: i18n.t("depression screening", { ns: "digital-twin" }), link: routes.depressionScreeningPage.absRoute },
      { title: i18n.t("anxiety screening", { ns: "digital-twin" }), link: routes.anxietyScreeningPage.absRoute },
    ],
  },
  {
    id: "diabetes",
    title: i18n.t("Diabetes", { ns: "digital-twin" }),
    icon: <FingerHasBloodDropIcon />,
    items: [{ title: i18n.t("diabetes risk", { ns: "digital-twin" }), link: routes.diabetesRiskScore.absRoute }],
  },
  // {
  //   title: i18n.t("Other", { ns: "digital-twin" }),
  //   items: [
  //     // { title: i18n.t("Health Risk Score", { ns: "digital-twin" }), link: routesPaths.diseaseAndRiskManagement },
  //     // { title: i18n.t("General Wellbeing Tool", { ns: "digital-twin" }), link: routesPaths.healthAndWellBeing },
  //   ],
  //   icon: <OthersDotsInCircleIcon />,
  // },
];
