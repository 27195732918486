import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const FingerHasBloodDropIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='29.539' height='25.875' viewBox='0 0 29.539 25.875' {...props}>
      <g id='Group_15789' data-name='Group 15789' transform='translate(-35 -634.791)'>
        <g id='Group_15788' data-name='Group 15788'>
          <g id='Group_15787' data-name='Group 15787'>
            <g id='icons8-diabetes' transform='translate(35 634.791)'>
              <path
                id='Path_23064'
                data-name='Path 23064'
                d='M18.388,19.965a2.476,2.476,0,0,0,1.529,4.708l5.63-1.827a2.476,2.476,0,0,0-1.528-4.709Z'
                transform='translate(-7.928 -9.798)'
                fill='#ffa726'
              />
              <path
                id='Path_23065'
                data-name='Path 23065'
                d='M19.294,26.013a2.477,2.477,0,0,0,1.529,4.709l5.63-1.828a2.476,2.476,0,0,0-1.529-4.708Zm.744,4.964a2.476,2.476,0,0,0,1.529,4.708l5.63-1.827a2.477,2.477,0,0,0-1.529-4.709Z'
                transform='translate(-8.09 -10.883)'
                fill='#ffa726'
              />
              <path
                id='Path_23066'
                data-name='Path 23066'
                d='M22.276,17.145a.824.824,0,0,1,.53-1.04l10.211-3.315A2.567,2.567,0,0,0,34.6,9.674a2.627,2.627,0,0,0-3.111-1.593l-11,3.57L12.65,14.2l-1.454.473a7.563,7.563,0,0,0-5.105,8.388l.282,1.217,1.392,4.288.487,1.151A7.561,7.561,0,0,0,17.311,33.5l1.454-.472,3.917-1.272Z'
                transform='translate(-6 -7.999)'
                fill='#ffa726'
              />
              <path
                id='Path_23067'
                data-name='Path 23067'
                d='M37.186,9.073a1.653,1.653,0,1,1,.985,3.149c-.923.289-1.907-2.86-.985-3.149'
                transform='translate(-11.531 -8.176)'
                fill='#ffcdd2'
              />
              <path
                id='Path_23068'
                data-name='Path 23068'
                d='M36,21.923C36,20.564,38.462,17,38.462,17s2.462,3.564,2.462,4.923a2.462,2.462,0,0,1-4.923,0Z'
                transform='translate(-11.384 -9.614)'
                fill='#f44336'
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
