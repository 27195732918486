import { makeStyles } from "@toolkit/ui";

export const useInfoBoxStyles = makeStyles()(theme => ({
  infoBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "11px",
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
