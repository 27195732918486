import { routesPaths } from "@/routing/routesPaths";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const useDefaultFooterStyles = makeStyles()(() => ({
  footer: {
    width: "100%",
    marginInline: "auto",

    height: "80px",
    position: "fixed",
    bottom: "0px",
    left: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (min-width: 768px)": {
      maxWidth: "600px",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  footerContent: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "10px",
    columnGap: 20,
  },
  submitButton: {
    flex: 1,
  },
  cancelButton: {
    flex: 1,
  },
}));

export const DefaultFooter: FC<{
  handleSubmit: () => void;
  handleCancel?: () => void;
  isSubmitDisabled?: boolean;
}> = ({ isSubmitDisabled, handleSubmit, handleCancel: upperHandleCancel }) => {
  const { classes } = useDefaultFooterStyles();
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();
  const handleCancel = () => {
    if (upperHandleCancel) {
      upperHandleCancel();
    } else {
      navigate(routesPaths.ageDynamicsAndCalculators);
    }
  };
  return (
    <Box className={classes.footer}>
      <Box className={classes.footerContent}>
        <Button className={classes.cancelButton} onClick={handleCancel}>
          {t("Back")}
        </Button>
        <Button className={classes.submitButton} variant='outlined' disabled={isSubmitDisabled} onClick={handleSubmit}>
          {t("Submit")}
        </Button>
      </Box>
    </Box>
  );
};
