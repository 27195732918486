import React, { FC } from "react";
import { Box, Card, MuiArrowBackIosNewIcon, MuiArrowForwardIosIcon, Typography } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useCalculatorHeaderCardStyle } from "./useCalculatorHeaderCardStyle";

type CalculatorHeaderCardProps = {
  title: string;
  description: string;
  onClick?: () => void;
};

export const CalculatorHeaderCard: FC<CalculatorHeaderCardProps> = props => {
  const { title, description, onClick: handleClick } = props;

  const isClickable = !!handleClick;

  const { classes } = useCalculatorHeaderCardStyle();

  const onCardClick = () => {
    if (!isClickable) return;

    handleClick?.();
  };

  return (
    <Card className={classes.root} onClick={onCardClick}>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}> {description} </Typography>
      </Box>

      {isClickable &&
        pickLocalizedValue(
          <MuiArrowForwardIosIcon sx={{ fontSize: "10" }} color={"primary"} />,
          <MuiArrowBackIosNewIcon sx={{ fontSize: "10" }} color={"primary"} />
        )}
    </Card>
  );
};
