import { FC } from "react";
import { Box, Chip, Typography } from "@toolkit/ui";
import { useCalculatorNutritionResultChipStyle } from "./useCalculatorNutritionResultChipStyle.ts";

type CalculatorNutritionResultChipProps = {
  value: number;
  label: string;
  chipColor: string;
  chipBackground: string;
  labelColor: string;
};

export const CalculatorNutritionResultChip: FC<CalculatorNutritionResultChipProps> = props => {
  const { value, label, chipColor, chipBackground, labelColor } = props;

  const { classes } = useCalculatorNutritionResultChipStyle();

  return (
    <Box className={classes.root}>
      <Chip
        label={
          <Typography className={classes.chipLabel} sx={{ color: chipColor }}>
            {value}
          </Typography>
        }
        sx={{ background: chipBackground }}
        className={classes.chip}
      />

      <Typography className={classes.label} sx={{ color: labelColor }}>
        {label}
      </Typography>
    </Box>
  );
};
