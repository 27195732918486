import React from "react";
import { CustomSlider } from "../Slider";
import { Box } from "@toolkit/ui";
import { TriangleIcon } from "../../Icons";
import { useTranslation } from "../../../i18n/i18n.config";
import { useBMISliderStyles } from "./BMISlider.styles";

const marks = [
  {
    value: 18.5,
    label: "18.5",
    scaledValue: 1,
  },
  {
    value: 29.9,
    label: "30",
    scaledValue: 1,
  },
  {
    value: 24.9,
    label: "25",
    scaledValue: 1,
  },
];

export const BMISlider: React.FC<{ value: number }> = ({ value = 0 }) => {
  const { t } = useTranslation("digital-twin");
  const { classes, theme } = useBMISliderStyles();

  return (
    <Box className={classes.sliderContainer}>
      <Box className={classes.labelContainer}>
        <Box className={classes.labelUnderweight}>{t("Underweight")}</Box>
        <Box className={classes.labelHealthWeight}>{t("Healthy Weight")}</Box>
        <Box className={classes.labelOverweight}>{t("Overweight")}</Box>
        <Box className={classes.labelObese}>{t("Obese")}</Box>
      </Box>
      <CustomSlider
        marks={marks}
        min={15}
        max={34}
        value={value}
        track={false}
        disabled
        valueLabelDisplay='on'
        valueLabelFormat={() => (
          <Box className={classes.valueLabel}>
            <TriangleIcon fill={theme.palette.primary.dark} />
          </Box>
        )}
        classes={{
          mark: classes.sliderMark,
          markLabel: classes.sliderMarkLabel,
          rail: classes.sliderRail,
        }}
      />
    </Box>
  );
};
