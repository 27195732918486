import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { Gender } from "@/schema/types";
import { useReactiveVar } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { forwardRef, useImperativeHandle } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import {
  AgeField,
  GenderField,
  HasBloodPressureField,
  HasDiabetesFamilyHistoryField,
  HasGestationalDiabetesField,
  HeightField,
  IsDoingPhysicalActivitiesField,
  WeightField,
} from "../../fields";

const IDiabetesRiskCalculatorFormZodSchema = z.object({
  age: z.number().int().min(0).max(120),
  gender: z.nativeEnum(Gender),
  height: z.number().min(0).max(300),
  weight: z.number().min(0).max(500),
  hasPhysicalActivities: z.boolean(),
  hasBloodPressure: z.boolean(),
  hasFamilyHistory: z.boolean(),
  hasGestationalDiabetes: z.boolean().optional().nullable(),
});

export type IDiabetesRiskCalculatorFormZodSchemaType = z.infer<typeof IDiabetesRiskCalculatorFormZodSchema>;
export interface IDiabetesRiskCalculatorFormRef {
  isValid: boolean;
  formMethods: ReturnType<typeof useForm<IDiabetesRiskCalculatorFormZodSchemaType>>;
  handleSubmit: () => void;
}
export const DiabetesRiskCalculatorForm = forwardRef<
  IDiabetesRiskCalculatorFormRef,
  {
    onSubmit: (data: IDiabetesRiskCalculatorFormZodSchemaType) => void;
    onChange: (data: IDiabetesRiskCalculatorFormZodSchemaType) => void;
  }
>((props, ref) => {
  const profileData = useReactiveVar(userProfileVar);
  const methods = useForm<IDiabetesRiskCalculatorFormZodSchemaType>({
    resolver: zodResolver(IDiabetesRiskCalculatorFormZodSchema),
    defaultValues: {
      age: profileData.age,
      gender: profileData.gender,
      weight: profileData.weight,
      height: profileData.height,
      hasBloodPressure: profileData.hasBloodPressure,
      hasFamilyHistory: profileData.hasFamilyHistory,
      hasPhysicalActivities: profileData.hasPhysicalActivities,
      hasGestationalDiabetes: !!profileData.hasGestationalDiabetes,
    },
  });

  const handleSubmit = methods.handleSubmit(data => {
    props.onSubmit(data);
  });
  const gender = methods.watch("gender");

  methods.watch(data => {
    if (data.gender === Gender.Male && data.hasGestationalDiabetes) {
      methods.setValue("hasGestationalDiabetes", null);
    }
    props.onChange?.(data as IDiabetesRiskCalculatorFormZodSchemaType);
  });

  useImperativeHandle(
    ref,
    () => ({
      get isValid() {
        return methods.formState.isValid;
      },
      formMethods: methods,
      handleSubmit,
    }),
    [methods.getValues()]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <WeightField />
        <HeightField />
        <AgeField />
        <GenderField />
        <IsDoingPhysicalActivitiesField />
        <HasBloodPressureField />
        {gender === Gender.Female && <HasGestationalDiabetesField />}
        <HasDiabetesFamilyHistoryField />
      </form>
    </FormProvider>
  );
});
