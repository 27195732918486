import { getEnvVariable } from "@health/env";
import { Box, Button, Typography, UserIcon, useTheme } from "@toolkit/ui";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthTextField, PasswordField } from "../../../components";
import { useAuth } from "../../../providers/auth";
import { useTranslation } from "../../../i18n/i18n.config";

export const SigninForm = () => {
  const theme = useTheme();
  const { setAppToken } = useAuth();
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();

  const methods = useForm();
  const {
    register,
    formState: { errors },
  } = methods;
  const handleLogin: SubmitHandler<FieldValues> = data => {
    const { username, password } = data;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      appType: "customer",
      username,
      password,
    });

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(getEnvVariable("DIGITAL_TWIN_LOGIN_URL"), requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        if (result.access_token) {
          setAppToken(result.access_token);
          navigate("/");
        }
      })
      .catch(error => console.log("Fetch error:", error));
  };
  return (
    <FormProvider {...methods}>
      <Typography mb={2} fontSize={theme.mixins.fonts.fontSize.xl} fontWeight={"medium"}>
        {t("Sign in")}
      </Typography>
      <Box display={"flex"} flexDirection={"column"} component='form' onSubmit={methods.handleSubmit(handleLogin)}>
        <AuthTextField
          label={t("Mobile / Email")}
          placeholder={t("Mobile / Email")}
          {...register("username", { required: true })}
          sx={{
            "& .MuiInputBase-root": {
              "& svg": {
                height: "14px",
                width: "21px",
              },
            },
            pb: "19px",
          }}
          InputProps={{
            startAdornment: <UserIcon />,
          }}
          error={!!errors.username}
          helperText={errors.username ? t("Required") : ""}
        />
        <PasswordField name='password' errorMessage={errors?.password ? t("Required") : ""} isRequired={true} />
        <Typography mt={"5px"} fontSize={theme.mixins.fonts.fontSize.sm} textAlign={"left"}>
          {t("Forgot Password?")}
        </Typography>
        <Button
          sx={{
            borderRadius: "28px",
            fontWeight: theme.mixins.fonts.fontWeight.regular,
            height: "48px",
            mt: 4,
          }}
          variant='contained'
          color='primary'
          type='submit'
        >
          {t("Sign in")}
        </Button>
      </Box>
    </FormProvider>
  );
};
