import { useGetDigitalTwinHealthProfileSleepResultQuery } from "@/gql/queries";
import { Card, CircularProgress, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CalculatorFooterCard } from "../../CalculatorNutrition/components/CalculatorFooterCard/CalculatorFooterCard";
import { CalculatorHeaderCard } from "../../CalculatorNutrition/components/CalculatorHeaderCard/CalculatorHeaderCard";
import { CircularProgressClock } from "../sleep-time-clock/SleepTimeClock";
import { getSleepingArticleForAge } from "../sleep-time-clock/utils";

export type ICalculatorSleepStepMainEvent = {
  type: "CARD_CLICK";
};

type CalculatorSleepStepMainProps = {
  onChange: (event: ICalculatorSleepStepMainEvent) => void;
};

export const CalculatorSleepStepMain: FC<CalculatorSleepStepMainProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("digital-twin");
  const params = new URLSearchParams(window.location.search);

  const { data, loading } = useGetDigitalTwinHealthProfileSleepResultQuery();

  const sleepResult = data?.getDigitalTwinHealthProfile?.sleepResults?.edges?.[0]?.node;
  const sleepResultQueryInput = data?.getDigitalTwinHealthProfile?.sleepResults?.edges?.[0]?.node?.queryInput;

  const onCalculatorHeaderCardClick = () => {
    onChange({
      type: "CARD_CLICK",
    });
  };

  return (
    <>
      {loading ? (
        <Card sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 400 }}>
          <CircularProgress size={50} />
        </Card>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CalculatorHeaderCard
              title={t("Set your sleep goal!")}
              description={t("Fill in the details to calculate your sleep or wake up time.")}
              onClick={onCalculatorHeaderCardClick}
            />
          </Grid>

          <Grid item xs={12}>
            {sleepResult ? (
              <CircularProgressClock
                sleepTime={sleepResult?.time}
                selectedTime={sleepResultQueryInput?.time}
                basedOnSleepTime={!!sleepResultQueryInput?.basedOnSleepTime}
                description={sleepResultQueryInput?.basedOnSleepTime ? t("Sleep Time") : t("Wake Up Time")}
                sleepCycles={sleepResult?.sleepCycles}
              />
            ) : (
              <CircularProgressClock description={t("Define your goal first")} />
            )}
          </Grid>

          <Grid item xs={12}>
            <CalculatorFooterCard
              mainTitle={t("Sleeping?")}
              isArticlesDisabled={!sleepResult}
              articleCategory={getSleepingArticleForAge(parseInt(params.get("age") || "0"))}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
