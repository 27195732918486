/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FraminghamRiskQueryVariables = Types.Exact<{
  input: Types.FraminghamRiskInput;
}>;


export type FraminghamRiskQuery = { __typename?: 'Query', framinghamRisk?: { __typename?: 'FraminghamRiskResponse', riskPercentage?: number | null } | null };


export const FraminghamRiskDocument = gql`
    query FraminghamRisk($input: FraminghamRiskInput!) {
  framinghamRisk(input: $input) {
    riskPercentage
  }
}
    `;

/**
 * __useFraminghamRiskQuery__
 *
 * To run a query within a React component, call `useFraminghamRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFraminghamRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFraminghamRiskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFraminghamRiskQuery(baseOptions: Apollo.QueryHookOptions<FraminghamRiskQuery, FraminghamRiskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FraminghamRiskQuery, FraminghamRiskQueryVariables>(FraminghamRiskDocument, options);
      }
export function useFraminghamRiskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FraminghamRiskQuery, FraminghamRiskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FraminghamRiskQuery, FraminghamRiskQueryVariables>(FraminghamRiskDocument, options);
        }
export type FraminghamRiskQueryHookResult = ReturnType<typeof useFraminghamRiskQuery>;
export type FraminghamRiskLazyQueryHookResult = ReturnType<typeof useFraminghamRiskLazyQuery>;
export type FraminghamRiskQueryResult = Apollo.QueryResult<FraminghamRiskQuery, FraminghamRiskQueryVariables>;