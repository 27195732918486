import { useAnxietyScreeningLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { routesPaths } from "@/routing/routesPaths";
import { RiskLevel } from "@/schema/types";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  AnxietyScreeningCalculatorForm,
  IAnxietyScreeningCalculatorFormRef,
  IAnxietyScreeningCalculatorFormZodSchemaType,
} from "./anxiety-screening-calculator-form/AnxietyScreeningCalculatorForm";
import { AnxietyScreeningResultCard } from "./anxiety-screening-results/AnxietyScreeningResultCard";
import { Typography } from "@toolkit/ui";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";

export const AnxietyScreeningCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();
  const ref = useRef<IAnxietyScreeningCalculatorFormRef>(null);
  const [fetchDiabetesRiskScore, { loading, data }] = useAnxietyScreeningLazyQuery({});
  const riskResult = data?.anxietyScreening;
  const doSubmit = debounce(() => ref.current?.handleSubmit(), 500);
  const handleSubmit = (data: IAnxietyScreeningCalculatorFormZodSchemaType) => {
    userProfileVar({
      ...userProfileVar(),
      feelingNervous: data.feelingNervous,
      notBeingAbleToStopOrControlWorrying: data.notBeingAbleToStopOrControlWorrying,
    });
    fetchDiabetesRiskScore({
      variables: {
        input: {
          feelingNervous: data.feelingNervous,
          notBeingAbleToStopOrControlWorrying: data.notBeingAbleToStopOrControlWorrying,
        },
      },
      onCompleted: resultData => {
        userProfileVar({
          ...userProfileVar(),
          anxietyRiskScore: resultData.anxietyScreening?.score,
          anxietyRisk: resultData.anxietyScreening?.riskLevel,
        });
      },
    });
  };
  useEffect(() => {
    ref.current?.formMethods.watch(() => {
      doSubmit();
    });
  }, []);

  return (
    <CalculatorsPageLayout
      title={t("Anxiety Calculator")}
      footer={
        <DefaultFooter
          isSubmitDisabled={!ref.current?.formMethods.formState.isValid || loading}
          handleSubmit={() => navigate(routesPaths.myDigitalTwin)}
        />
      }
    >
      <AnxietyScreeningResultCard
        isDataReady={riskResult !== undefined}
        score={riskResult?.score ?? 0}
        result={riskResult?.riskLevel ?? RiskLevel.Low}
        isLoading={loading}
      />

      <Typography fontSize={12} color='textPrimary' my={1}>
        {t("Please answer based on how you've felt in the past 2 weeks")}
      </Typography>

      <AnxietyScreeningCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={handleSubmit} />
    </CalculatorsPageLayout>
  );
};
