import { getEnvVariable } from "@health/env";
import { localStorageKeys } from "../local-storage-keys";

export const fetchTokenExchange = (token: string, apiUrl?: string) => {
  const myHeaders = new Headers();
  myHeaders.append("x-authorization", `Bearer ${token}`);
  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };
  return fetch(apiUrl || getEnvVariable("KEYCLOAK_TOKEN_EXCHANGE_API_DEV"), requestOptions)
    .then(response => response.json())
    .then(result => {
      localStorage.setItem(localStorageKeys.authToken, result.access_token);
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchGetAccessTokenFromParams = () => {
  const params = new URLSearchParams(location.search);
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("access-key", params.get("access-key") || "XzsFmUZjlOphLNmYAf3cUPUSSZFPB1D");
  const raw = JSON.stringify({
    id: params.get("id") || "1",
    lang: params.get("lang") || "ar",
    age: parseInt(params.get("age") || "30"),
    gender: params.get("gender")?.[0] || "m",
    weight: parseInt(params.get("weight") || "90"),
    height: parseInt(params.get("height") || "180"),
  });

  const requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(getEnvVariable("DIGITAL_TWIN_LOGIN_URL", process.env.REACT_APP_DIGITAL_TWIN_LOGIN_URL), requestOptions)
    .then(response => response.json())
    .then(result => {
      localStorage.setItem(localStorageKeys.authToken, result.access_token);
    })
    .catch(error => console.error(error));
};
