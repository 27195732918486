import { BmiCalculatorPage } from "@/domains/calculators/bmi/BmiCalculatorPage";
import { DiabetesRiskCalculatorPage } from "@/domains/calculators/diabetes-risk-calculators/DiabetesRiskCalculatorPage";
import { GeneratingDigitalTwinInProgressCountDownPage } from "@/domains/MyDigitalTwinProfile/containers/GeneratingDigitalTwinInProgressCountDownPage/GeneratingDigitalTwinInProgressCountDownPage";
import AnxietyScreeningPage from "./AnxietyScreeningPage";
import NutritionCalculatorsPage from "./NutritionCalculatorsPage";
import BodyFatPercentagePage from "./BodyFatPercentagePage";
import DepressionScreeningPage from "./DepressionScreeningPage";
import FraminghamRiskPercentagePage from "./FraminghamRiskPercentagePage";
import IdealBodyWeightPage from "./IdealBodyWeightPage";
import LifeExpectancyCalculatorPage from "./LifeExpectancyCalculatorPage";
import SleepTimePage from "./SleepTimePage";
import WeightLossPlanPage from "./WeightLossPlanPage";
export const baseRoute = "/age-dynamics-and-calculators";

export const routes = {
  root: {
    route: "/",
    getRouteWithParams(expand: string) {
      return `${baseRoute}${this.route}?route=${expand}`;
    },
  },
  lifeExpectancy: {
    route: "/life-expectancy",
    component: LifeExpectancyCalculatorPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  diabetesRiskScore: {
    route: "/diabetes-risk-score",
    component: DiabetesRiskCalculatorPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  beeAndTDee: {
    route: "/nutrition-calculators",
    component: NutritionCalculatorsPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  weightLossPlanPage: {
    route: "/weight-loss-plan",
    component: WeightLossPlanPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  idealBodyWeight: {
    route: "/ideal-body-weight",
    component: IdealBodyWeightPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  sleepTime: {
    route: "/sleep-time",
    component: SleepTimePage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  bodyFatPercentage: {
    route: "/body-fat-percentage",
    component: BodyFatPercentagePage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  anxietyScreeningPage: {
    route: "/anxiety-screening",
    component: AnxietyScreeningPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  depressionScreeningPage: {
    route: "/depression-screening",
    component: DepressionScreeningPage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  framinghamRiskPercentagePage: {
    route: "/framingham-risk-percentage",
    component: FraminghamRiskPercentagePage,
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  GeneratingDigitalTwinInProgressCountDownPage: {
    route: "/generating-digital-twin-in-progress-count-down",
    get component() {
      return GeneratingDigitalTwinInProgressCountDownPage;
    },
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
  },
  bmiCalc: {
    route: "/bmi-calc",
    component: BmiCalculatorPage,
    queryParams: ["weight", "height"],
    get absRoute() {
      return `${baseRoute}${this.route}`;
    },
    getRouteWithParams({ weight, height }: { weight: number; height: number }) {
      return `${this.absRoute}?weight=${weight}&height=${height}`;
    },
  },
};
