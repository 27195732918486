import { makeStyles } from "@toolkit/ui";

export const useBodyOrganRiskStyles = makeStyles()(theme => ({
  container: {
    width: "100%",
    height: "80px",
    display: "flex",
  },
  iconContainer: {
    flex: 4,
    width: "100px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: "50px",
      height: "50px",
    },
  },
  textContainer: {
    flex: 9,
    width: "100px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  bodyString: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    textAlign: "start",
  },
  bodyOrganRisk: {
    textAlign: "start",
    color: theme.palette.common.black,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  progress: {
    width: "100%",
    height: "7px",
    borderRadius: "18px",
    marginTop: "5px",
  },
}));
