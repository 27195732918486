import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useDiabetesRiskScoreLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { routesPaths } from "@/routing/routesPaths";
import { RiskLevel } from "@/schema/types";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  DiabetesRiskCalculatorForm,
  IDiabetesRiskCalculatorFormRef,
  IDiabetesRiskCalculatorFormZodSchemaType,
} from "./diabetes-risk-calculator-form/DiabetesRiskCalculatorForm";
import { DiabetesRiskResultCard } from "./diabetes-risk-results/DiabetesRiskResultCard";

export const DiabetesRiskCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const navigate = useNavigate();
  const ref = useRef<IDiabetesRiskCalculatorFormRef>(null);
  const [fetchDiabetesRiskScore, { loading, data }] = useDiabetesRiskScoreLazyQuery({});
  const riskResult = data?.diabetesRisk;
  const doSubmit = debounce(() => ref.current?.handleSubmit(), 500);

  const handleSubmit = (data: IDiabetesRiskCalculatorFormZodSchemaType) => {
    userProfileVar({
      ...userProfileVar(),
      hasBloodPressure: data.hasBloodPressure,
      hasFamilyHistory: data.hasFamilyHistory,
      physicalActive: data.hasPhysicalActivities,
      hasGestationalDiabetes: !!data.hasGestationalDiabetes,
    });
    fetchDiabetesRiskScore({
      variables: {
        input: {
          age: data.age,
          gender: data.gender,
          height: data.height,
          weight: data.weight,
          hasBloodPressure: data.hasBloodPressure,
          hasFamilyHistory: data.hasFamilyHistory,
          physicalActive: data.hasPhysicalActivities,
          hasGestationalDiabetes: !!data.hasGestationalDiabetes,
        },
      },
      onCompleted: resultData => {
        userProfileVar({
          ...userProfileVar(),
          diabetesRiskScore: resultData.diabetesRisk?.score,
          diabetesRisk: resultData.diabetesRisk?.risk,
        });
      },
    });
  };

  useEffect(() => {
    ref.current?.formMethods.watch(() => {
      if (ref.current?.formMethods.formState.isValid) doSubmit();
    });
  }, []);

  return (
    <CalculatorsPageLayout
      title={t("Diabetes Risk Calculator")}
      footer={<DefaultFooter isSubmitDisabled={loading} handleSubmit={() => navigate(routesPaths.myDigitalTwin)} />}
    >
      <DiabetesRiskResultCard
        score={riskResult?.score ?? 0}
        result={riskResult?.risk ?? RiskLevel.Low}
        isDataReady={riskResult !== undefined}
        isLoading={loading}
      />
      <DiabetesRiskCalculatorForm
        ref={ref}
        onSubmit={handleSubmit}
        onChange={() => {
          ref.current?.handleSubmit();
        }}
      />
    </CalculatorsPageLayout>
  );
};
