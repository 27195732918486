import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const HeartHealthIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='17.339' height='15.894' viewBox='0 0 17.339 15.894' {...props}>
      <path
        id='icons8-heart-health'
        d='M12.18,13.9a.722.722,0,0,1-1.022,0L7.546,10.29A.722.722,0,1,1,8.568,9.269l3.1,3.1L18.8,5.243A4.7,4.7,0,0,0,15.61,4a5.151,5.151,0,0,0-3.941,2.167A5.151,5.151,0,0,0,7.728,4,4.728,4.728,0,0,0,3,8.728c0,4.581,5.361,8.963,7.8,10.874l0,0a1.42,1.42,0,0,0,1.734,0l0,0c2.438-1.912,7.8-6.293,7.8-10.874a4.7,4.7,0,0,0-.631-2.353Z'
        transform='translate(-3 -4)'
        fill={props?.fill || theme.palette.primary[1600]}
      />
    </SvgIcon>
  );
};
