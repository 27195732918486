import { Box, Typography, useTheme } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { useFormStyles } from "./formStyles";
import { useTranslation } from "@/i18n/i18n.config";
import CompletePercentage from "@/components/Progress/CompletePercentage";
import { FormRadio } from "@/components";
import { smokingHabitOptions, stressLevelOptions } from "@/domains/calculators/fields/constants";
import { LabeledInputContainer } from "@/components/LabeledInputContainer";
import { getColorBaseOnScore } from "@/domains/MyDigitalTwinProfile/utils";

export const LifestyleForm: React.FC<{ activeButton: () => void }> = ({ activeButton }) => {
  const { t } = useTranslation("digital-twin");
  const theme = useTheme();
  const { classes } = useFormStyles();

  const formFields = ["smokingHabit", "stressLevel"];
  const { control } = useFormContext();
  const values = useWatch({
    control: control,
    name: formFields,
  });

  const filledCount =
    values.reduce((acc: number, value: number) => {
      return value ? acc + 1 : acc;
    }, 0) * 100;

  const count = formFields.length;
  const isFilledCountEqualToCount = filledCount === count;

  useEffect(() => {
    activeButton();
  }, [isFilledCountEqualToCount]);

  return (
    <>
      <CompletePercentage color={getColorBaseOnScore(filledCount / count, theme)} value={filledCount / count} />
      <Box className={classes.box}>
        <LabeledInputContainer mode='vertical' label={t("Smoking Habit")}>
          <FormRadio name='smokingHabit' options={smokingHabitOptions} />
          <Typography fontSize={theme.mixins.fonts.fontSize.xs} textAlign={"start"} p={1.5}>
            <Typography>{t("Light : less than 15 cigarettes per day")}</Typography>
            <Typography>{t("Heavy : more than 15 cigarettes per day")}</Typography>
          </Typography>
        </LabeledInputContainer>
        <LabeledInputContainer mode='vertical' label={t("Stress Level")}>
          <FormRadio name='stressLevel' options={stressLevelOptions} />
        </LabeledInputContainer>
      </Box>
    </>
  );
};
