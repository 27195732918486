import { FormRadio } from "@/components";
import { LabeledInputContainer } from "@/components/LabeledInputContainer";
import CompletePercentage from "@/components/Progress/CompletePercentage";
import { PhysicalActivityField } from "@/domains/calculators/fields";
import { dietQualityOptions } from "@/domains/calculators/fields/constants";
import { getColorBaseOnScore } from "@/domains/MyDigitalTwinProfile/utils";
import { useTranslation } from "@/i18n/i18n.config";
import { Box, useTheme } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useFormStyles } from "./formStyles";

export const FitnessForm: React.FC<{ activeButton: () => void }> = ({ activeButton }) => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useFormStyles();

  const formFields = ["physicalActivity", "dietQuality"];

  const { control } = useFormContext();
  const values = useWatch({
    control: control,
    name: formFields,
  });

  const filledCount =
    values.reduce((acc: number, value: number) => {
      return value !== undefined ? acc + 1 : acc;
    }, 0) * 100;

  const count = formFields.length;
  const theme = useTheme();
  const isFilledCountEqualToCount = filledCount === count;

  useEffect(() => {
    activeButton();
  }, [isFilledCountEqualToCount]);

  return (
    <>
      <CompletePercentage color={getColorBaseOnScore(filledCount / count, theme)} value={filledCount / count} />
      <Box className={classes.box}>
        <LabeledInputContainer mode='vertical' label={t("Diet Quality")}>
          <FormRadio radioGroupProps={{ className: classes.radioGroup }} options={dietQualityOptions} name='dietQuality' />
        </LabeledInputContainer>
        <PhysicalActivityField />
      </Box>
    </>
  );
};
