import i18n from "@/i18n/i18n.config";
import { AgeAndPhysiqueForm } from "./components/AgeAndPhysiqueForm";
import { FitnessForm } from "./components/FitnessFrom";
import { LifeEventsForm } from "./components/LifeEventsForm";
import { LifestyleForm } from "./components/LifestyleForm";

type Tab = {
  label: string;
  render: (activeButton: () => void) => JSX.Element;
  cardHeight: string;
};

export const lifeExpectancyTabs: Tab[] = [
  {
    get label() {
      return i18n.t("Age & Physique", { ns: "digital-twin" });
    },
    render: (activeButton: () => void) => <AgeAndPhysiqueForm activeButton={activeButton} />,
    cardHeight: "494px",
  },
  {
    get label() {
      return i18n.t("Life Events", { ns: "digital-twin" });
    },
    render: (activeButton: () => void) => <LifeEventsForm activeButton={activeButton} />,
    cardHeight: "415px",
  },
  {
    get label() {
      return i18n.t("Fitness", { ns: "digital-twin" });
    },
    render: (activeButton: () => void) => <FitnessForm activeButton={activeButton} />,
    cardHeight: "1010px",
  },
  {
    get label() {
      return i18n.t("Lifestyle", { ns: "digital-twin" });
    },
    render: (activeButton: () => void) => <LifestyleForm activeButton={activeButton} />,
    cardHeight: "415px",
  },
];
