import { makeStyles } from "@toolkit/ui";

export const useGeneratingDigitalTwinInProgressCountDownPageStylesStyles = makeStyles()(theme => ({
  card: {
    minHeight: "calc( 100% - 16px )",
    padding: "10px",
    marginInline: 16,
    blockSize: "unset",
  },
  title: {
    marginTop: "10px",
    fontSize: theme.mixins.fonts.fontSize.xxl,
  },
  button: {
    marginTop: "10px",
    width: "213px",
    height: "45px",
    borderRadius: "28px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
  },
  videoBox: {
    width: "100%",
    height: "350px",
    marginTop: "10px",
  },
  cardMedia: {
    width: "100%",
    maxHeight: "350px",
    objectFit: "contain",
  },
}));
