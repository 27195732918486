import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

interface HeaderContextProps {
  icon: React.ReactNode;
  header: string;
  hasBackIcon?: boolean;
  resetHeader: () => void;
  handleBackClick: () => void;
  setHeader: (header: string) => void;
  setIcon: (icon: React.ReactNode) => void;
  setBackDestination: (dest: string | number) => void;
}

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw new Error("useHeaderContext must be used within a HeaderProvider");
  }
  return context;
};

interface HeaderProviderProps {
  children: ReactNode;
}

const initialIcon: React.ReactNode = undefined;
const initialHeader: string = "";

export const HeaderProvider: FC<HeaderProviderProps> = ({ children }) => {
  const [icon, setIcon] = useState<React.ReactNode>(initialIcon);
  const [backDestination, setBackDestination] = useState<string | number>("");
  const [header, setHeader] = useState<string>(initialHeader);

  const navigate = useNavigate();
  const handleBackClick = useCallback(() => {
    navigate(backDestination as string);
  }, []);

  const resetHeader = () => {
    setIcon(initialIcon);
    setHeader(initialHeader);
    setBackDestination("");
  };

  return (
    <HeaderContext.Provider
      value={{ icon, header, hasBackIcon: !!backDestination, setIcon, setBackDestination, setHeader, resetHeader, handleBackClick }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
