import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useCalculatorHeaderCardStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 10,
    padding: 12,
    boxShadow: "none",
    border: `1px solid ${theme.palette.stale[300]}`,
    transition: "box-shadow 0.3s ease",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    gap: 4,
    textAlign: pickLocalizedValue("left", "right"),
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
  },
  description: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.primary.main,
  },
}));
