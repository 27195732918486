import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CalculatorHeaderCard } from "../CalculatorHeaderCard/CalculatorHeaderCard";
import { CalculatorNutritionForm, ICalculatorNutritionFormEvent } from "../../forms/CalculatorNutrition/CalculatorNutritionForm";
import { CalculatorNutritionStep } from "../../types";
import { ICalculatorNutritionFormValues } from "../../forms/CalculatorNutrition/CalculatorNutritionFormSchema";
import { Box } from "@toolkit/ui";

export type ICalculatorNutritionStepFormEvent = ICalculatorNutritionFormEvent;

type CalculatorNutritionStepFormProps = {
  step: CalculatorNutritionStep;
  formValues?: ICalculatorNutritionFormValues;
  onChange: (event: ICalculatorNutritionStepFormEvent) => void;
};

export const CalculatorNutritionStepForm: FC<CalculatorNutritionStepFormProps> = props => {
  const { step, formValues, onChange } = props;

  const { t } = useTranslation("digital-twin");

  return (
    <Box>
      <CalculatorHeaderCard
        title={t("Set your goal for calorie counting")}
        description={t("Fill in the details so we can calculate your calories and weight loss plan")}
      />

      <CalculatorNutritionForm step={step} formValues={formValues} onChange={onChange} />
    </Box>
  );
};
