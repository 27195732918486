import { ResultsCard, ResultsCardProps } from "@/components";
import { useTranslation } from "@/i18n/i18n.config";
import { Box, CircularProgress, Divider, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";

export const WeightLossPlanResultCard: FC<
  {
    calorieIntake: number;
    newTdee: number;
    caloriesPerDayToAdjust: number;
  } & ResultsCardProps
> = ({ calorieIntake, caloriesPerDayToAdjust, isLoading, isDataReady }) => {
  const { t } = useTranslation("digital-twin");
  const theme = useTheme();
  return (
    <ResultsCard
      isLoading={isLoading}
      isDataReady={isDataReady}
      customPlaceholder={t("Please fill in the details below to get your weight loss plan")}
    >
      <Box display={"flex"} flexDirection={"column"} py={2}>
        <Typography variant='h4' color='textPrimary'>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box sx={{ flex: 1, px: 1, justifyContent: "space-around" }}>
                  <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                    {t("You need to reduce your daily calorie intake by")}
                  </Typography>
                  <Typography variant='h5' mt={1} fontWeight={theme.mixins.fonts.fontWeight.bold} mb={0}>
                    {caloriesPerDayToAdjust?.toFixed(0)} {t("Calories")}
                  </Typography>
                </Box>
                <Box sx={{ flex: 0, width: 5 }}>
                  <Divider orientation='vertical' />
                </Box>
                <Box sx={{ flex: 1, px: 1 }}>
                  <Typography fontSize={theme.mixins.fonts.fontSize.sm} fontWeight={theme.mixins.fonts.fontWeight.medium}>
                    {t("Your estimated new daily calorie intake should be")}
                  </Typography>
                  <Typography variant='h5' mt={1} fontWeight={theme.mixins.fonts.fontWeight.bold}>
                    {calorieIntake?.toFixed(0)} {t("Calories")}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Typography>
      </Box>
    </ResultsCard>
  );
};
