import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import React from "react";

export const GlucometerIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='48px' height='48px' {...props}>
      <path fill='#90CAF9' d='M22 38H26V45H22z' />
      <path fill='#1565C0' d='M37,5c0,0-6-2-13-2S11,5,11,5S8,6.001,8,8v2v19v4c0,0,0,7,7,7s11,0,18,0s7-7,7-7v-4V10V8C40,6.001,37,5,37,5z' />
      <path fill='#0D47A1' d='M25,37h-2c-1.657,0-3,1.344-3,3h8C28,38.344,26.657,37,25,37z' />
      <path fill='#FFF' d='M12 9H36V26H12z' />
      <path fill='#F44336' d='M20,19.5c0-2.209,4-8,4-8s4,5.791,4,8s-1.79,4-4,4S20,21.709,20,19.5z' />
      <path
        fill='#90CAF9'
        d='M23 34c0 .553-.447 1-1 1h-9c-.553 0-1-.447-1-1v-4c0-.553.447-1 1-1h9c.553 0 1 .447 1 1V34zM36 34c0 .553-.447 1-1 1h-9c-.553 0-1-.447-1-1v-4c0-.553.447-1 1-1h9c.553 0 1 .447 1 1V34z'
      />
    </SvgIcon>
  );
};
