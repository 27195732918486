import React, { useState } from "react";
import { useIdealSleepCalculationLazyQuery } from "./gql";
import { ISleepTimeFormZodSchemaType } from "./sleep-time-calculator-form/SleepTimeCalculatorForm";
import moment from "moment";
import {
  ICalculatorSleepStepMainEvent,
  CalculatorSleepStepMain,
} from "@/domains/calculators/sleep-time/CalculatorSleepStepMain/CalculatorSleepStepMain";
import { CalculatorSleepStep, ICalculatorSleepSelectedTime } from "@/domains/calculators/sleep-time/types";
import {
  CalculatorSleepStepForm,
  ICalculatorSleepStepFormEvent,
} from "@/domains/calculators/sleep-time/CalculatorSleepStepForm/CalculatorSleepStepForm";
import {
  CalculatorSleepStepResult,
  ICalculatorSleepStepResultEvent,
} from "@/domains/calculators/sleep-time/CalculatorSleepStepResult/CalculatorSleepStepResult";
import { CalculatorPageLayout } from "@/domains/calculators/CalculatorNutrition/components/CalculatorPageLayout/CalculatorPageLayout";
import { useTranslation } from "react-i18next";

export const SleepTimeCalculatorPage = () => {
  const [step, setStep] = useState<CalculatorSleepStep>(CalculatorSleepStep.CalculatorSleepMain);
  const [formValues, setFormValues] = useState<ISleepTimeFormZodSchemaType>();
  const [selectedTime, setSelectedTime] = useState<ICalculatorSleepSelectedTime>({
    hour: "01",
    minute: "00",
    amOrPm: "AM",
  });

  const { t } = useTranslation("digital-twin");

  const selectedTimeString = `${selectedTime.hour}:${selectedTime.minute} ${selectedTime.amOrPm}`;
  const formattedTime = moment(selectedTimeString, "hh:mm A").format("HH:mm");
  const params = new URLSearchParams(window.location.search);

  const [fetchSleepTimeResult, { data, loading }] = useIdealSleepCalculationLazyQuery();

  const onCalculatorSleepStepFormChange = (event: ICalculatorSleepStepFormEvent) => {
    if (event.type === "TIME_CHANGE") {
      const { name, value } = event.payload;

      setSelectedTime({
        ...selectedTime,
        [name]: value,
      });
    } else if (event.type === "SUBMIT") {
      const values = event.payload.values;

      setFormValues(values);

      fetchSleepTimeResult({
        variables: {
          input: {
            age: values?.age,
            time: formattedTime,
            basedOnSleepTime: values?.basedOnSleepTime,
          },
          saveData: true,
        },
      }).then(() => setStep(CalculatorSleepStep.CalculatorSleepResult));
    }
  };

  const onSleepCalculatorMainChange = (event: ICalculatorSleepStepMainEvent) => {
    if (event.type === "CARD_CLICK") {
      setStep(CalculatorSleepStep.CalculatorSleepForm);
    }
  };

  const onCalculatorSleepStepResultChange = (event: ICalculatorSleepStepResultEvent) => {
    if (event.type === "CARD_CLICK") {
      setStep(CalculatorSleepStep.CalculatorSleepForm);
    }
  };

  const onBackIconClick = () => {
    setStep(CalculatorSleepStep.CalculatorSleepMain);
  };

  return (
    <CalculatorPageLayout
      title={t("Sleep Calculator")}
      includeBackButton={step === CalculatorSleepStep.CalculatorSleepForm}
      onBackIconClick={onBackIconClick}
    >
      {step === CalculatorSleepStep.CalculatorSleepMain && <CalculatorSleepStepMain onChange={onSleepCalculatorMainChange} />}

      {step === CalculatorSleepStep.CalculatorSleepForm && (
        <CalculatorSleepStepForm formValues={formValues} selectedTime={selectedTime} onChange={onCalculatorSleepStepFormChange} />
      )}

      {step === CalculatorSleepStep.CalculatorSleepResult && (
        <CalculatorSleepStepResult
          sleepTime={data?.sleep?.time}
          sleepCycles={data?.sleep?.sleepCycles}
          selectedTime={selectedTimeString}
          basedOnSleepTime={formValues?.basedOnSleepTime}
          isLoading={loading}
          age={formValues?.age || parseInt(params.get("age") || "0") || 0}
          onChange={onCalculatorSleepStepResultChange}
        />
      )}
    </CalculatorPageLayout>
  );
};
