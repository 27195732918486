import { makeStyles } from "@toolkit/ui";

export const useFormStyles = makeStyles()(() => ({
  box: {
    width: "100%",
    marginTop: "19px",
  },
  radioGroup: {
    justifyContent: "space-between",
    gap: "0px",
    width: "100%",
    maxWidth: "370px",
    marginInlineStart: "-10px",
  },
  radioGroupWithGaps: {
    gap: "40px",
    marginInlineStart: "-10px",
  },
  longOption: {
    gap: 2,
    marginInlineStart: "0px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    ["@media (min-width: 700px)"]: {
      flexDirection: "row",
    },
  },
  multiLineRadioOptions: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  radioGroupEducation: {
    justifyContent: "space-between",
    gap: "0px",
    width: "100%",
    minWidth: "360px",
    marginInlineStart: "-10px",
    "& .MuiFormControlLabel-root": {
      marginInlineEnd: "5px",
    },
  },
  radioGroupCommunity: {
    gap: "40px",
    marginInlineStart: "-10px",
  },
  timeFieldContainer: {
    // "& .MuiPickerStaticWrapper-root": {
    //   width: "100%",
    //   backgroundColor: theme.palette.background.default,
    //   "& .PrivatePickersToolbar-root": {
    //     padding: "0px",
    //     width: "100px",
    //     "& .MuiTypography-root": {
    //       fontSize: theme.mixins.fonts.fontSize.xxl,
    //     },
    //     "& .PrivateTimePickerToolbar-ampmLabel": {
    //       fontSize: theme.mixins.fonts.fontSize.xl,
    //     },
    //     "& .PrivateTimePickerToolbar-separator": {
    //       paddingBottom: "5px",
    //       fontSize: "30px",
    //     },
    //     "& .PrivateTimePickerToolbar-ampmSelection": {
    //       flexDirection: "row",
    //       alignItems: "start",
    //       justifyContent: "start",
    //       padding: "3px",
    //       gap: "6px",
    //     },
    //   },
    //   "& .MuiClockPicker-root": {
    //     width: "min-content",
    //     scale: "0.9",
    //   },
    // },
  },
}));
