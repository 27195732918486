import { SvgIconProps, SvgIcon } from "@toolkit/ui";
import { FC } from "react";

export const HeartCardiographIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='33.095' height='27.078' viewBox='0 0 33.095 27.078' {...props}>
      <g id='Group_15783' data-name='Group 15783' transform='translate(-31.722 -232.381)'>
        <g id='Group_15782' data-name='Group 15782'>
          <g id='Group_15781' data-name='Group 15781'>
            <g id='Group_15780' data-name='Group 15780'>
              <g id='icons8-life' transform='translate(31.722 232.381)'>
                <path
                  id='Path_23076'
                  data-name='Path 23076'
                  d='M26.069,6a9.017,9.017,0,0,0-7.522,4.04A9.025,9.025,0,0,0,2,15.026c0,8.983,16.548,18.052,16.548,18.052S35.1,24.086,35.1,15.026A9.026,9.026,0,0,0,26.069,6'
                  transform='translate(-2 -6)'
                  fill='#f44336'
                />
                <path
                  id='Path_23077'
                  data-name='Path 23077'
                  d='M22.608,21.428h2.6a.752.752,0,1,0,0-1.5H21.6l-.89,2.184L18.234,16.33l-2.661,7.722L12.206,10.588,9.29,21.048,7.184,16.135,5.559,19.924H3.19a14.126,14.126,0,0,0,.8,1.5H6.551l.633-1.476L9.591,25.57l2.536-9.1,3.215,12.862,3.043-8.826,2.354,5.5Z'
                  transform='translate(-2.295 -7.137)'
                  fill='#ffebee'
                />
                <path
                  id='Path_23078'
                  data-name='Path 23078'
                  d='M33.5,22a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,33.5,22Z'
                  transform='translate(-9.435 -9.965)'
                  fill='#ffebee'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
