import { makeStyles } from "@toolkit/ui";

export const useTimePickerStyle = makeStyles()(theme => ({
  root: {
    paddingInline: 20,
    direction: "ltr",
    "& .picker-column .picker-item.picker-item-selected": {
      fontFamily: theme.mixins.fonts.fontFamily.medium,
    },
    "& .picker-column .picker-item": {
      fontFamily: theme.mixins.fonts.fontFamily.medium,
    },
    "& .picker-column .picker-item:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main, // Blue on hover
    },
    ".picker-container .picker-inner": {
      padding: 0,
    },
    "& .picker-container .picker-highlight": {
      borderRadius: 10,
      backgroundColor: "rgba(25, 118, 210, 0.1)", // Light blue background for the selected item
    },
    "& .picker-container .picker-highlight:after, .picker-container .picker-highlight:before": {
      display: "none",
    },
  },
  button: {
    fontWeight: "bold",
    justifyContent: "space-between",
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  paper: {
    padding: 20,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
}));
