/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { CalculatorHeaderCard } from "../CalculatorHeaderCard/CalculatorHeaderCard";
import { CalculatorNutritionResult } from "../CalculatorNutritionResult/CalculatorNutritionResult";
import { CalculatorFooterCard } from "../CalculatorFooterCard/CalculatorFooterCard";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ArticleCategoryCode } from "@/domains/Articles/codes";
import { ActivityLevel } from "@/schema/types";
import { useBeeAndTDeeQuery, useGetDigitalTwinHealthProfileNutritionResultQuery } from "@/gql/queries";
import { Card, CircularProgress } from "@toolkit/ui";

type CalculatorNutritionStepMainProps = {
  onChange: () => void;
};

export const CalculatorNutritionStepMain: FC<CalculatorNutritionStepMainProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("digital-twin");

  const { data, loading } = useGetDigitalTwinHealthProfileNutritionResultQuery();

  const healthProfile = data?.getDigitalTwinHealthProfile;
  const beeAndTdeeResult = healthProfile?.beeAndTdeeResults?.edges?.[0]?.node;
  const beeAndTdeeResultQueryInput = healthProfile?.beeAndTdeeResults?.edges?.[0]?.node?.queryInput;
  const goalWeightResult = healthProfile?.goalWeightResults?.edges?.[0]?.node;
  const goalWeightResultQueryInput = healthProfile?.goalWeightResults?.edges?.[0]?.node?.queryInput;

  const { data: beeAndTDeeData, loading: isBeeAndTDeeLoading } = useBeeAndTDeeQuery({
    variables: {
      input: {
        age: beeAndTdeeResultQueryInput?.age!,
        gender: beeAndTdeeResultQueryInput?.gender!,
        height: beeAndTdeeResultQueryInput?.height!,
        weight: beeAndTdeeResultQueryInput?.weight!,
        activityLevel: ActivityLevel.VeryHeavyActive!,
      },
    },
  });

  const maxTdee = beeAndTDeeData?.beeAndtdee?.tdee!;

  const isLoading = loading || isBeeAndTDeeLoading;

  return (
    <>
      {isLoading ? (
        <Card sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: 400 }}>
          <CircularProgress size={50} />
        </Card>
      ) : (
        <>
          <CalculatorHeaderCard
            title={t("Set your goal for calorie counting")}
            description={t("Fill in the details so we can calculate your calories and weight loss plan")}
            onClick={onChange}
          />

          <CalculatorNutritionResult
            tdeeValue={beeAndTdeeResult?.tdee}
            beeValue={beeAndTdeeResult?.bee}
            maxTdee={maxTdee}
            targetCalories={goalWeightResult?.newCalorieIntake}
            goalWeight={goalWeightResultQueryInput?.goalWeight!}
            daysToAchieveGoalWeight={goalWeightResultQueryInput?.days!}
            newTdeeValue={goalWeightResult?.newTDEE!}
            reduceCalorieIntake={goalWeightResult?.caloriesPerDayToAdjust!}
          />

          <CalculatorFooterCard
            mainTitle={t("Losing Weight?")}
            isArticlesDisabled={!beeAndTdeeResult}
            articleCategory={ArticleCategoryCode.nutritionArticles}
          />
        </>
      )}
    </>
  );
};
