// BMI.tsx
import React, { useEffect } from "react";
import { Box } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../components/PageHeader/BackButton";
import { routesPaths } from "../../routing/routesPaths";
import { useHeaderContext } from "../../providers";
import { useTranslation } from "react-i18next";
import { useBMIStyles } from "./components/useBMI.styles";
import { CardWithTitle } from "../../components";
import { BMISlider } from "../../components/Sliders/BMI/BMISlider";
import { InfoBox } from "./components/InfoBox/InfoBox";
import { FitnessProfile } from "./components/FitnessProfile/FitnessProfile";
import { JoinCare } from "../../components/JoinCare/JoinCare";

export const BMI: React.FC = () => {
  const { classes } = useBMIStyles();
  const { setHeader, resetHeader, setIcon } = useHeaderContext();
  const navigate = useNavigate();
  const { t } = useTranslation("digital-twin");

  useEffect(() => {
    setHeader(t("Health & Wellbeing"));
    setIcon(<BackButton onClick={() => navigate(routesPaths.healthAndWellBeing)} />);
    return () => {
      resetHeader();
    };
  }, [t]);

  return (
    <Box className={classes.container}>
      <CardWithTitle title={t("Current BMI status")}>
        <Box className={classes.sliderContainer}>
          <BMISlider value={22} />
        </Box>
        <InfoBox label={t("Height")} value='180' />
        <InfoBox label={t("Weight")} value='110' />
      </CardWithTitle>
      <CardWithTitle className={classes.fitnessCard} title={t("Fitness Profile")}>
        <FitnessProfile />
      </CardWithTitle>
      <Box className={classes.joinCareContainer}>
        <JoinCare />
      </Box>
    </Box>
  );
};
