import { SvgIcon, SvgIconProps, useTheme } from "@toolkit/ui";
import React from "react";

export const TriangleIcon: React.FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' {...props}>
      <path id='Polygon_14' data-name='Polygon 14' d='M8,0l8,14H0Z' fill={props.fill || theme.palette.common.white} />
    </SvgIcon>
  );
};
