import { Box, FormHelperText, SliderProps, TextField, Typography, useTheme } from "@toolkit/ui";
import { get, isNumber } from "lodash";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomSlider } from "./Slider";
import { pickLocalizedValue } from "@toolkit/i18n";

interface FormSliderProps extends SliderProps {
  name: string;
}

export const FormSlider: FC<FormSliderProps> = ({ name, ...rest }) => {
  const {
    control,
    formState: { errors, disabled: isFormDisabled },
  } = useFormContext();

  const theme = useTheme();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Box display={"flex"} alignContent={"center"} alignItems={"center"} mt={1}>
              <Typography style={{ marginRight: pickLocalizedValue(12, 0), marginLeft: pickLocalizedValue(0, 12) }}>{rest.min}</Typography>

              <CustomSlider
                {...rest}
                value={field.value || 0}
                onChange={(_, value) => field.onChange(value)}
                disabled={isFormDisabled}
                sx={{ flex: 5 }}
              />

              <Typography style={{ marginRight: pickLocalizedValue(0, 12), marginLeft: pickLocalizedValue(12, 0) }}>{rest.max}</Typography>

              <TextField
                error={!!error}
                sx={{
                  mx: 1,
                  px: 0.5,
                  textAlign: "center",
                  border: `1px solid ${theme.palette.stale[400]}`,
                  borderRadius: 2,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                variant='standard'
                type='number'
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  max: rest.max,
                  min: rest.min,
                  style: {
                    textAlign: "center",
                    color: theme.palette.primary.main,
                  },
                }}
                placeholder='0'
                name={field.name}
                value={field.value || 0}
                onChange={e => (isNumber(e.target.value) ? field.onChange(20) : field.onChange(parseInt(e.target.value)))}
              />
            </Box>
          );
        }}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
