import { makeStyles } from "@toolkit/ui";

export const useStyles = makeStyles()(() => ({
  root: {
    padding: "0px",
    paddingInline: "10px important",
  },
  categoryContainer: {
    marginTop: "18px",
    paddingInline: "10px",
    height: "50px",
  },
  categoryIcon: {
    paddingInlineStart: "10px",
    width: "40px",
    marginInlineEnd: "20px",
  },
  categoryTitle: {
    marginInlineStart: "0px",
  },
  itemTitle: {
    marginInlineStart: "50px",
  },
  expandIconButton: {
    marginInlineStart: "auto",
    "& svg": {
      width: "7px",
      height: "13px",
    },
  },
  infoIconButton: {
    "& svg": {
      width: "15px",
      height: "15px",
    },
  },
}));
