import { makeStyles } from "@toolkit/ui";

export const useCalculatorPageLayoutStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 16,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  titleWrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
  },
  body: {
    flex: 1,
  },
  footerButton: {
    paddingBlock: 24,
    borderRadius: 6,
    fontSize: theme.mixins.fonts.fontSize.md,
    "&:disabled": {
      backgroundColor: `${theme.palette.grey[800]} !important`,
    },
  },
}));
