import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useCalculatorNutritionResultChipStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  chip: {
    borderRadius: 18,
    minWidth: 60,
  },
  chipLabel: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    textAlign: pickLocalizedValue("left", "right"),
  },
}));
