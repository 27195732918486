import { Box, EyeIconVisitHistory, EyeOffIcon, IconButton, PasswordIcon2, TextFieldProps } from "@toolkit/ui";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { AuthTextField } from "../AuthTextField";
import { useTranslation } from "../../../i18n/i18n.config";

export const PasswordField: FC<TextFieldProps & { isRequired?: boolean; errorMessage?: string; name: string }> = props => {
  const { isRequired, errorMessage, name } = props;
  const [showPass, setShowPass] = useState<boolean>(false);
  const { t } = useTranslation("digital-twin");
  const handleClick = () => {
    setShowPass(show => !show);
  };
  const { register } = useFormContext();
  return (
    <AuthTextField
      label={t("Password")}
      placeholder={t("Password")}
      type={showPass ? "text" : "password"}
      {...props}
      error={!!errorMessage}
      helperText={errorMessage}
      {...register(name, { required: isRequired })}
      InputProps={{
        startAdornment: (
          <Box
            sx={{
              width: "17px",
              height: "16px",
              "& svg": {
                height: "15px",
                width: "16px",
              },
              marginRight: "4px",
              paddingInlineStart: "4px",
            }}
          >
            <PasswordIcon2 />
          </Box>
        ),
        endAdornment: (
          <IconButton
            sx={{
              width: "21px",
              height: "14px",
              "& svg": {
                height: "14px",
                width: "21px",
              },
            }}
            onClick={handleClick}
          >
            {!showPass ? <EyeIconVisitHistory /> : <EyeOffIcon />}
          </IconButton>
        ),
      }}
    />
  );
};
