import { Box, FormControlLabel, FormHelperText, RadioGroup, RadioGroupProps, RadioProps } from "@toolkit/ui";
import { get } from "lodash";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CustomRadio } from "../CustomRadio";
import { Option } from "@/types/radio";

type FormRadioProps = RadioProps & {
  name: string;
  options: Option<string | boolean>[];
  radioGroupProps?: RadioGroupProps;
  labelPlacement?: "top" | "bottom" | "start" | "end";
};

export const FormRadio: FC<FormRadioProps> = props => {
  const { name, options, labelPlacement = "bottom", radioGroupProps, ...rest } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name + ".message", "") as string;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <RadioGroup
            value={field.value ?? ""}
            onChange={event =>
              field.onChange(["true", "false"].includes(event.target.value) ? event.target.value === "true" : event.target.value)
            }
            row
            sx={{ rowGap: "8px" }}
            {...radioGroupProps}
          >
            {options.map((option: Option<string | boolean>) => (
              <FormControlLabel
                key={option.value?.toString() + option.name}
                value={option.value?.toString()}
                control={<CustomRadio value={option.value} {...rest} />}
                label={option.name}
                labelPlacement={labelPlacement}
                className='form-radio'
                sx={{
                  fontSize: "small",
                  // maxWidth: { xs: "60px" },
                  textWrap: "wrap",
                  "&.MuiFormControlLabel-root": {
                    marginInlineStart: "10px",
                    gap: "12px",
                  },
                }}
              />
            ))}
          </RadioGroup>
        )}
      />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </Box>
  );
};
