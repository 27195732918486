import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useCardButtonStyles } from "./DigitalTwinProfileCardButton.styles";

interface DigitalTwinProfileCardButtonProps {
  icon: React.ComponentType;
  title: string;
  subtitle?: string;
  onClick: () => void;
}

const DigitalTwinProfileCardButton: React.FC<DigitalTwinProfileCardButtonProps> = ({ icon: Icon, title, subtitle, onClick }) => {
  const { classes } = useCardButtonStyles();

  return (
    <Box component='button' onClick={onClick} className={classes.button} aria-label={`${title} ${subtitle ? subtitle : ""}`}>
      <Icon />
      <Typography className={classes.title}>
        {title}
        {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
      </Typography>
    </Box>
  );
};

export default DigitalTwinProfileCardButton;
