import { ButtonProps, IconButton } from "@toolkit/ui";
import { FC } from "react";
import { LongArrowIcon } from "../Icons";
import useStyles from "./PageHeader.styles";

export const BackButton: FC<ButtonProps> = props => {
  const { classes } = useStyles();
  return (
    <IconButton className={classes?.backButton} {...props}>
      <LongArrowIcon direction='left' />
    </IconButton>
  );
};
