import { LinearProgress, styled } from "@toolkit/ui";

export const BorderLinearProgress = styled(LinearProgress)<{ customColor: string }>(({ theme, customColor }) => {
  return {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.primary[50],
    "& .MuiLinearProgress-bar": {
      backgroundColor: customColor,
      borderRadius: 5,
    },
  };
});
