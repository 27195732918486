import { ResultsCard, ResultsCardProps } from "@/components";
import { useTranslation } from "@/i18n/i18n.config";
import { Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC } from "react";

export const FraminghamRiskResultCard: FC<
  {
    percentage: number;
  } & ResultsCardProps
> = ({ percentage, isLoading, isDataReady }) => {
  const { t } = useTranslation("digital-twin");
  return (
    <ResultsCard
      isLoading={isLoading}
      isDataReady={isDataReady}
      customPlaceholder={t("Please fill in the details below to get your heart risk percentage")}
    >
      <Box display={"flex"} flexDirection={"column"} py={2}>
        <Typography variant='body2' color='textPrimary'>
          {t("Heart Risk Percentage")}
        </Typography>
        <Typography variant='h4' color='textPrimary'>
          {isLoading ? <CircularProgress size={20} /> : <>{percentage < 1 ? t("less than 1") : percentage}%</>}
        </Typography>
      </Box>
    </ResultsCard>
  );
};
