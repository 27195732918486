/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  DateTime: any;
  Decimal: any;
  DictType: any;
  Double: any;
  H_Long: any;
  Instant: any;
  InstantNoTime: any;
  InstantNotFuture: any;
  JSONString: any;
  LocalDateTime: any;
  /** 24-hour clock time value string in the format `hh:mm:ss` or `hh:mm:ss.sss`. */
  LocalTime: any;
  P_Date: any;
  Time: any;
  UUID: any;
  /** A custom scalar that represents files */
  Upload: any;
};

export type AccountAddressCreate = {
  __typename?: "AccountAddressCreate";
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAddressDelete = {
  __typename?: "AccountAddressDelete";
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAddressUpdate = {
  __typename?: "AccountAddressUpdate";
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAvatarDelete = {
  __typename?: "AccountAvatarDelete";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAvatarUpdate = {
  __typename?: "AccountAvatarUpdate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountError = {
  __typename?: "AccountError";
  code: AccountErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum AccountErrorCode {
  ActivateOwnAccount = "ACTIVATE_OWN_ACCOUNT",
  ActivateSuperuserAccount = "ACTIVATE_SUPERUSER_ACCOUNT",
  DeactivateOwnAccount = "DEACTIVATE_OWN_ACCOUNT",
  DeactivateSuperuserAccount = "DEACTIVATE_SUPERUSER_ACCOUNT",
  DeleteConsumerAccount = "DELETE_CONSUMER_ACCOUNT",
  DeleteNonStaffUser = "DELETE_NON_STAFF_USER",
  DeleteOwnAccount = "DELETE_OWN_ACCOUNT",
  DeleteStaffAccount = "DELETE_STAFF_ACCOUNT",
  DeleteSuperuserAccount = "DELETE_SUPERUSER_ACCOUNT",
  DeleteSupervendorAccount = "DELETE_SUPERVENDOR_ACCOUNT",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  IntegrationTokenError = "INTEGRATION_TOKEN_ERROR",
  Invalid = "INVALID",
  InvalidCredentials = "INVALID_CREDENTIALS",
  InvalidPassword = "INVALID_PASSWORD",
  LeftNotManageablePermission = "LEFT_NOT_MANAGEABLE_PERMISSION",
  NotFound = "NOT_FOUND",
  OutOfScopeGroup = "OUT_OF_SCOPE_GROUP",
  OutOfScopePermission = "OUT_OF_SCOPE_PERMISSION",
  OutOfScopeServiceAccount = "OUT_OF_SCOPE_SERVICE_ACCOUNT",
  OutOfScopeUser = "OUT_OF_SCOPE_USER",
  PasswordEntirelyNumeric = "PASSWORD_ENTIRELY_NUMERIC",
  PasswordTooCommon = "PASSWORD_TOO_COMMON",
  PasswordTooShort = "PASSWORD_TOO_SHORT",
  PasswordTooSimilar = "PASSWORD_TOO_SIMILAR",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type AccountSetDefaultAddress = {
  __typename?: "AccountSetDefaultAddress";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type Action = {
  __typename?: "Action";
  actionFields?: Maybe<Array<Maybe<ActionField>>>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
};

export type ActionDefinitionField = {
  __typename?: "ActionDefinitionField";
  allowedValues?: Maybe<Array<Maybe<Scalars["String"]>>>;
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<ActionFieldType>;
};

export type ActionField = {
  __typename?: "ActionField";
  code?: Maybe<Scalars["String"]>;
  extra?: Maybe<Scalars["String"]>;
  type: ActionFieldType;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueDate?: Maybe<Scalars["Date"]>;
  valueList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  valueNumber?: Maybe<Scalars["Float"]>;
  valueString?: Maybe<Scalars["String"]>;
};

export type ActionFieldInput = {
  code?: InputMaybe<Scalars["String"]>;
  extra?: InputMaybe<Scalars["String"]>;
  type: ActionFieldType;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueDate?: InputMaybe<Scalars["Date"]>;
  valueList?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  valueNumber?: InputMaybe<Scalars["Float"]>;
  valueString?: InputMaybe<Scalars["String"]>;
};

export enum ActionFieldType {
  Boolean = "BOOLEAN",
  Date = "DATE",
  List = "LIST",
  Number = "NUMBER",
  String = "STRING",
}

export type ActivateInterventionInput = {
  guidedCareJourneyId: Scalars["ID"];
  interventionActivationType: InterventionActivationType;
  startDate?: InputMaybe<Scalars["Instant"]>;
  templateInterventionActivityItemsIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  templateInterventionId: Scalars["ID"];
};

export type ActivityGuidedCareProgramTeamMemberInput = {
  uniqueId?: InputMaybe<Scalars["String"]>;
};

export enum ActivityLevel {
  HeavyActive = "HEAVY_ACTIVE",
  LightlyActive = "LIGHTLY_ACTIVE",
  ModeratelyActive = "MODERATELY_ACTIVE",
  Sedentary = "SEDENTARY",
  VeryHeavyActive = "VERY_HEAVY_ACTIVE",
}

export type ActivityTemplateTeamMemberInput = {
  uniqueId: Scalars["String"];
};

export enum Actor {
  Doctor = "DOCTOR",
  Patient = "PATIENT",
}

export type AddDiagnosisToVisitInput = {
  code: Scalars["String"];
  visitDiagnosisType: VisitDiagnosisType;
  visitId: Scalars["ID"];
};

export type AddMemberToProgramInput = {
  guidedCareProgramId: Scalars["ID"];
  memberId?: InputMaybe<Scalars["ID"]>;
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type AddVendorToFavorite = {
  __typename?: "AddVendorToFavorite";
  customerFavoriteVendor?: Maybe<CustomerFavoriteVendor>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export type Address = Node & {
  __typename?: "Address";
  area: Scalars["String"];
  areaAr: Scalars["String"];
  buildingName?: Maybe<Scalars["String"]>;
  buildingNameAr?: Maybe<Scalars["String"]>;
  buildingNumber?: Maybe<Scalars["String"]>;
  city: City;
  coordinates?: Maybe<LocationType>;
  district: Scalars["String"];
  districtAr: Scalars["String"];
  id: Scalars["ID"];
  isDefaultBillingAddress?: Maybe<Scalars["Boolean"]>;
  isDefaultShippingAddress?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  postalCode: Scalars["String"];
  region: Scalars["String"];
  regionAr: Scalars["String"];
  streetAddress1: Scalars["String"];
  streetAddress1Ar: Scalars["String"];
  streetAddress2: Scalars["String"];
  streetAddress2Ar: Scalars["String"];
  unitNumber?: Maybe<Scalars["String"]>;
};

export type AddressCountableConnection = {
  __typename?: "AddressCountableConnection";
  edges: Array<AddressCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AddressCountableEdge = {
  __typename?: "AddressCountableEdge";
  cursor: Scalars["String"];
  node: Address;
};

export type AddressInput = {
  area?: InputMaybe<Scalars["String"]>;
  areaAr?: InputMaybe<Scalars["String"]>;
  buildingName?: InputMaybe<Scalars["String"]>;
  buildingNameAr?: InputMaybe<Scalars["String"]>;
  buildingNumber?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["ID"]>;
  coordinates: LocationInput;
  district?: InputMaybe<Scalars["String"]>;
  districtAr?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  regionAr?: InputMaybe<Scalars["String"]>;
  streetAddress1?: InputMaybe<Scalars["String"]>;
  streetAddress1Ar?: InputMaybe<Scalars["String"]>;
  streetAddress2?: InputMaybe<Scalars["String"]>;
  streetAddress2Ar?: InputMaybe<Scalars["String"]>;
  unitNumber?: InputMaybe<Scalars["String"]>;
};

export type AddressTranslationInput = {
  area?: InputMaybe<Scalars["String"]>;
  areaAr?: InputMaybe<Scalars["String"]>;
  buildingName?: InputMaybe<Scalars["String"]>;
  buildingNameAr?: InputMaybe<Scalars["String"]>;
  district?: InputMaybe<Scalars["String"]>;
  districtAr?: InputMaybe<Scalars["String"]>;
  languageCode: LanguageCodeEnum;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  regionAr?: InputMaybe<Scalars["String"]>;
  streetAddress1?: InputMaybe<Scalars["String"]>;
  streetAddress1Ar?: InputMaybe<Scalars["String"]>;
  streetAddress2?: InputMaybe<Scalars["String"]>;
  streetAddress2Ar?: InputMaybe<Scalars["String"]>;
};

export enum AddressTypeEnum {
  Billing = "BILLING",
  Shipping = "SHIPPING",
}

export type AdminMedicalFormInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category: MedicalFormCategory;
  code: Scalars["String"];
  departmentId?: InputMaybe<Scalars["ID"]>;
  description: Scalars["String"];
  medicalFormClassifications?: InputMaybe<Array<MedicalFormClassificationInput>>;
  name: Scalars["String"];
  nameAr: Scalars["String"];
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  type: MedicalFormType;
  welcomeCard?: InputMaybe<Scalars["String"]>;
  welcomeCardAr?: InputMaybe<Scalars["String"]>;
};

export type AdminPromotion = {
  __typename?: "AdminPromotion";
  content?: Maybe<Scalars["String"]>;
  contentAr?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  isPublished: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Instant"]>;
};

export type AdminPromotionConnection = {
  __typename?: "AdminPromotionConnection";
  edges: Array<AdminPromotionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminPromotionEdge = {
  __typename?: "AdminPromotionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: AdminPromotion;
};

export type AdminPromotionInput = {
  content: Scalars["String"];
  contentAr: Scalars["String"];
  description: Scalars["String"];
  descriptionAr: Scalars["String"];
  endDate: Scalars["Instant"];
  name: Scalars["String"];
  nameAr: Scalars["String"];
  photo: Scalars["String"];
  startDate: Scalars["Instant"];
};

export enum AdminPromotionSortingField {
  Created = "CREATED",
  EndDate = "END_DATE",
  Name = "NAME",
  NameAr = "NAME_AR",
  Published = "PUBLISHED",
  StartDate = "START_DATE",
}

export type AdminPromotionSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<AdminPromotionSortingField>;
};

export type AdminUserCreateInput = {
  addGroups?: InputMaybe<Array<Scalars["ID"]>>;
  address?: InputMaybe<AddressInput>;
  appRole: AppRoleTypes;
  appType: AppTypes;
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  defaultBranch?: InputMaybe<Scalars["ID"]>;
  dentalHygienistInfo?: InputMaybe<DentalHygienistInput>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  diabetesEducatorInfo?: InputMaybe<DiabetesEducatorInput>;
  doctorInfo?: InputMaybe<DoctorInput>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  fitnessCoachInfo?: InputMaybe<FitnessCoachInput>;
  gender?: InputMaybe<PersonGenderEnum>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  nurseInfo?: InputMaybe<NurseInput>;
  nutritionistInfo?: InputMaybe<NutritionistInput>;
  optometristInfo?: InputMaybe<OptometristInput>;
  parentUser?: InputMaybe<Scalars["ID"]>;
  password: Scalars["String"];
  payer?: InputMaybe<Scalars["ID"]>;
  pharmacistInfo?: InputMaybe<PharmacistInput>;
  photo?: InputMaybe<Scalars["String"]>;
  podiatricMedicalAssistantInfo?: InputMaybe<PodiatricMedicalAssistantInput>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  psychologistInfo?: InputMaybe<PsychologistInput>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars["String"]>;
  socialWorkerInfo?: InputMaybe<SocialWorkerInput>;
  thirdName?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<Scalars["ID"]>;
  vendorUserType?: InputMaybe<VendorUserTypes>;
};

export enum AffectedType {
  Appointment = "APPOINTMENT",
  Visit = "VISIT",
  VisitCall = "VISIT_CALL",
}

export type Aggregation = {
  __typename?: "Aggregation";
  key: Scalars["String"];
  value: Scalars["Int"];
};

export enum AmountSourceType {
  Appointment = "APPOINTMENT",
  Doctor = "DOCTOR",
  HealthProgram = "HEALTH_PROGRAM",
  MarketplaceOrder = "MARKETPLACE_ORDER",
  Order = "ORDER",
  OrderCheckoutToken = "ORDER_CHECKOUT_TOKEN",
  OutPatientJourney = "OUT_PATIENT_JOURNEY",
  PrescriptionOrder = "PRESCRIPTION_ORDER",
  Visit = "VISIT",
}

export type AnonymousChatGroup = H_Node & {
  __typename?: "AnonymousChatGroup";
  anonymousChatLink?: Maybe<Scalars["String"]>;
  anonymousChatLinkForChatView?: Maybe<Scalars["String"]>;
  anonymousChatLinkForJoinCall?: Maybe<Scalars["String"]>;
  chatId?: Maybe<Scalars["H_Long"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  error?: Maybe<Scalars["String"]>;
  failedToCreate?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  participants?: Maybe<Array<Maybe<AnonymousChatGroupParticipant>>>;
  suspended?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
  visit?: Maybe<Visit>;
};

export type AnonymousChatGroupCountableConnection = {
  __typename?: "AnonymousChatGroupCountableConnection";
  edges: Array<AnonymousChatGroupCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AnonymousChatGroupCountableEdge = {
  __typename?: "AnonymousChatGroupCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: AnonymousChatGroup;
};

export type AnonymousChatGroupParticipant = H_Node & {
  __typename?: "AnonymousChatGroupParticipant";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  meetingPlatformId?: Maybe<Scalars["H_Long"]>;
  name?: Maybe<Scalars["String"]>;
  selectedAsOwnerUser?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<User>;
};

export enum AnxietyFrequency {
  MoreThanHalfTheDays = "MORE_THAN_HALF_THE_DAYS",
  NearlyEveryDay = "NEARLY_EVERY_DAY",
  NotAtAll = "NOT_AT_ALL",
  SeveralDays = "SEVERAL_DAYS",
}

export type AnxietyScreeningInput = {
  feelingNervous: AnxietyFrequency;
  notBeingAbleToStopOrControlWorrying: AnxietyFrequency;
};

export type AnxietyScreeningInputLog = {
  __typename?: "AnxietyScreeningInputLog";
  feelingNervous: AnxietyFrequency;
  notBeingAbleToStopOrControlWorrying: AnxietyFrequency;
};

export type AnxietyScreeningResponse = {
  __typename?: "AnxietyScreeningResponse";
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars["Int"]>;
};

export type AnxietyScreeningResult = {
  __typename?: "AnxietyScreeningResult";
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<AnxietyScreeningInputLog>;
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars["Int"]>;
};

export type AnxietyScreeningResultConnection = {
  __typename?: "AnxietyScreeningResultConnection";
  edges: Array<AnxietyScreeningResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AnxietyScreeningResultEdge = {
  __typename?: "AnxietyScreeningResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: AnxietyScreeningResult;
};

export enum AppRoleTypes {
  Admin = "ADMIN",
  User = "USER",
}

export enum AppTypes {
  Admin = "ADMIN",
  Aggregator = "AGGREGATOR",
  Customer = "CUSTOMER",
  Payer = "PAYER",
  Vendor = "VENDOR",
}

export type Appointment = H_Node & {
  __typename?: "Appointment";
  appointmentCategory?: Maybe<AppointmentCategory>;
  appointmentCreationSource?: Maybe<AppointmentCreationSource>;
  appointmentServiceDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  branch?: Maybe<Branch>;
  callId?: Maybe<Scalars["H_Long"]>;
  cancellationReason?: Maybe<Scalars["String"]>;
  careInitialType?: Maybe<CareType>;
  chatId?: Maybe<Scalars["H_Long"]>;
  confirmedEndTime?: Maybe<Scalars["Instant"]>;
  confirmedStartTime?: Maybe<Scalars["Instant"]>;
  consumer?: Maybe<Patient>;
  consumerUser?: Maybe<User>;
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  department?: Maybe<Department>;
  doctor?: Maybe<Doctor>;
  doctorNote?: Maybe<Scalars["String"]>;
  doctorUser?: Maybe<User>;
  healthProgram?: Maybe<HealthProgram>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  /** The ID of the object. */
  id: Scalars["ID"];
  justification?: Maybe<Scalars["String"]>;
  lastModifiedByUser?: Maybe<User>;
  messageToPatient?: Maybe<Scalars["String"]>;
  modified?: Maybe<Scalars["Instant"]>;
  paymentInfo?: Maybe<AppointmentPaymentRequest>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusFromPaymentLogs?: Maybe<PaymentStatus>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  rejectionReason?: Maybe<Scalars["String"]>;
  requestedBy?: Maybe<RequestedBy>;
  requestedTimeSlots?: Maybe<Array<Maybe<Scalars["String"]>>>;
  specialty?: Maybe<T_Specialization>;
  status?: Maybe<AppointmentStatus>;
  subscription?: Maybe<HealthProgramUserSubscription>;
  suggestedTimeSlots?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<AppointmentType>;
  vendor?: Maybe<Vendor>;
  visit?: Maybe<Visit>;
};

export type AppointmentPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AppointmentPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Appointment Approval Action */
export type AppointmentApprovalAction = {
  __typename?: "AppointmentApprovalAction";
  appointment?: Maybe<Appointment>;
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export enum AppointmentCategory {
  Consultation = "CONSULTATION",
  Lab = "LAB",
  Rad = "RAD",
}

export type AppointmentChatLinkInfo = {
  __typename?: "AppointmentChatLinkInfo";
  appointmentId: Scalars["ID"];
  chatLinkForChatView?: Maybe<Scalars["String"]>;
  chatLinkForJoinCall?: Maybe<Scalars["String"]>;
};

export type AppointmentCountableConnection = {
  __typename?: "AppointmentCountableConnection";
  edges: Array<AppointmentCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AppointmentCountableEdge = {
  __typename?: "AppointmentCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Appointment;
};

export enum AppointmentCreationSource {
  Automatic = "AUTOMATIC",
  Other = "OTHER",
  Personalized = "PERSONALIZED",
}

export type AppointmentFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  confirmedEndTime?: InputMaybe<H_DateTimeRangeInput>;
  confirmedStartTime?: InputMaybe<H_DateTimeRangeInput>;
  consumerUserId?: InputMaybe<Scalars["Int"]>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  doctors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  hasOutPatientJourney?: InputMaybe<Scalars["Boolean"]>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  providerGuidedCareHealthProgramTeams?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  requestedBy?: InputMaybe<RequestedBy>;
  requestedEndTime?: InputMaybe<H_DateTimeRangeInput>;
  requestedStartTime?: InputMaybe<H_DateTimeRangeInput>;
  selfOnly?: InputMaybe<Scalars["Boolean"]>;
  selfProviderGuidedCareHealthProgramTeamsOnly?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Array<InputMaybe<AppointmentStatus>>>;
  type?: InputMaybe<Array<InputMaybe<AppointmentType>>>;
  upcoming?: InputMaybe<Scalars["Boolean"]>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export enum AppointmentFrequencyType {
  Day = "Day",
  Month = "Month",
  Week = "Week",
  Year = "Year",
}

export type AppointmentNotificationSetting = H_Node & {
  __typename?: "AppointmentNotificationSetting";
  arabicText?: Maybe<Scalars["String"]>;
  channels?: Maybe<Array<Maybe<NotificationChannel>>>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  englishText?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  sendTimeInMinutes?: Maybe<Scalars["Int"]>;
  types?: Maybe<Array<Maybe<AppointmentType>>>;
  vendor?: Maybe<Vendor>;
};

export type AppointmentNotificationSettingCountableConnection = {
  __typename?: "AppointmentNotificationSettingCountableConnection";
  edges: Array<AppointmentNotificationSettingCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AppointmentNotificationSettingCountableEdge = {
  __typename?: "AppointmentNotificationSettingCountableEdge";
  cursor: Scalars["String"];
  node: AppointmentNotificationSetting;
};

export type AppointmentNotificationSettingFilterInput = {
  channels?: InputMaybe<Array<InputMaybe<NotificationChannel>>>;
  deleted?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  sendTimeInMinutes?: InputMaybe<H_IntRangeInput>;
  types?: InputMaybe<Array<InputMaybe<AppointmentType>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type AppointmentNotificationSettingInput = {
  arabicText: Scalars["String"];
  channels: Array<NotificationChannel>;
  englishText: Scalars["String"];
  sendTimeInMinutes: Scalars["Int"];
  types: Array<AppointmentType>;
};

export enum AppointmentNotificationType {
  Confirmation = "CONFIRMATION",
  Reminder = "REMINDER",
}

export type AppointmentNotifications = {
  __typename?: "AppointmentNotifications";
  appNotification?: Maybe<SubscriptionEntityChangedForAppointmentNotification>;
  emailNotifications?: Maybe<Array<Maybe<InternalEmailNotificationConverted>>>;
  smsNotifications?: Maybe<Array<Maybe<InternalSmsNotificationConverted>>>;
};

export type AppointmentPaymentRequest = H_Node & {
  __typename?: "AppointmentPaymentRequest";
  appointment?: Maybe<Appointment>;
  currency?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  priceAmountAfterVat?: Maybe<Scalars["Float"]>;
  totalAmount?: Maybe<Scalars["Float"]>;
  type?: Maybe<AppointmentPaymentType>;
  vatPercentage?: Maybe<Scalars["Float"]>;
};

export type AppointmentPaymentRequestForAcceptInput = {
  integrationPatientShare?: InputMaybe<Scalars["Float"]>;
};

export type AppointmentPaymentRequestForRescheduleInput = {
  totalAmount?: InputMaybe<Scalars["Float"]>;
};

export enum AppointmentPaymentType {
  CashOnly = "CASH_ONLY",
  InsuranceCoverage = "INSURANCE_COVERAGE",
  PrePaid = "PRE_PAID",
}

export enum AppointmentSortingField {
  /** Sort appointment by confirmed start time. */
  ConfirmedStartDate = "CONFIRMED_START_DATE",
  /** Sort appointments by creation date. */
  Created = "CREATED",
  /** Sort appointments by last modification date. */
  LastModifiedDate = "LAST_MODIFIED_DATE",
}

export type AppointmentSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort appointments by the selected field. */
  field?: InputMaybe<AppointmentSortingField>;
};

export enum AppointmentStatus {
  Approved = "APPROVED",
  CallInProgress = "CALL_IN_PROGRESS",
  CanceledByConsumer = "CANCELED_BY_CONSUMER",
  CanceledByDoctor = "CANCELED_BY_DOCTOR",
  Cancelled = "CANCELLED",
  ChatInProgress = "CHAT_IN_PROGRESS",
  ComingSoon = "COMING_SOON",
  Confirmed = "CONFIRMED",
  FinishedSuccessfully = "FINISHED_SUCCESSFULLY",
  OutPatientJourneyAssessmentDone = "OUT_PATIENT_JOURNEY_ASSESSMENT_DONE",
  OutPatientJourneyAssessmentPending = "OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING",
  OutPatientJourneyCheckedIn = "OUT_PATIENT_JOURNEY_CHECKED_IN",
  OutPatientJourneyConsultationDone = "OUT_PATIENT_JOURNEY_CONSULTATION_DONE",
  OutPatientJourneyConsultationPending = "OUT_PATIENT_JOURNEY_CONSULTATION_PENDING",
  OutPatientJourneyPending = "OUT_PATIENT_JOURNEY_PENDING",
  OutPatientJourneyTreatmentDone = "OUT_PATIENT_JOURNEY_TREATMENT_DONE",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentPending = "PAYMENT_PENDING",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  PendingApproval = "PENDING_APPROVAL",
  Rejected = "REJECTED",
  Request = "REQUEST",
  RescheduledByConsumer = "RESCHEDULED_BY_CONSUMER",
  RescheduledByDoctor = "RESCHEDULED_BY_DOCTOR",
  Tentative = "TENTATIVE",
}

export type AppointmentStatusHistory = H_Node & {
  __typename?: "AppointmentStatusHistory";
  callId?: Maybe<Scalars["H_Long"]>;
  cancellationReason?: Maybe<Scalars["String"]>;
  chatId?: Maybe<Scalars["H_Long"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  doctorId?: Maybe<Scalars["Int"]>;
  doctorUserId?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  meetingPlatformLink?: Maybe<Scalars["String"]>;
  meetingPlatformLinkForChatView?: Maybe<Scalars["String"]>;
  meetingPlatformLinkForJoinCall?: Maybe<Scalars["String"]>;
  patientId?: Maybe<Scalars["Int"]>;
  patientUserId?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["H_Long"]>;
  status?: Maybe<AppointmentStatus>;
  vendorId?: Maybe<Scalars["Int"]>;
};

export type AppointmentStatusHistoryCountableConnection = {
  __typename?: "AppointmentStatusHistoryCountableConnection";
  edges: Array<AppointmentStatusHistoryCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AppointmentStatusHistoryCountableEdge = {
  __typename?: "AppointmentStatusHistoryCountableEdge";
  cursor: Scalars["String"];
  node: AppointmentStatusHistory;
};

export type AppointmentStatusHistoryFilterInput = {
  doctors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  refIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  statuses?: InputMaybe<Array<InputMaybe<AppointmentStatus>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

/** Appointment status update */
export type AppointmentStatusUpdate = {
  __typename?: "AppointmentStatusUpdate";
  appointment?: Maybe<Appointment>;
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type AppointmentStatusUpdateInput = {
  callId?: InputMaybe<Scalars["H_Long"]>;
  cancellationReason?: InputMaybe<Scalars["String"]>;
  chatId?: InputMaybe<Scalars["H_Long"]>;
  meetingPlatformLink?: InputMaybe<Scalars["String"]>;
  status: AppointmentStatus;
};

export type AppointmentTimeSlotInput = {
  endTime: Scalars["Instant"];
  startTime: Scalars["Instant"];
};

export enum AppointmentType {
  AtHome = "AT_HOME",
  Diagnostic = "DIAGNOSTIC",
  Online = "ONLINE",
  Onsite = "ONSITE",
}

export enum AppointmentTypeEnum {
  AtHome = "AT_HOME",
  Diagnostic = "DIAGNOSTIC",
  Online = "ONLINE",
  Onsite = "ONSITE",
}

export type ApproveTermsAndConditions = {
  __typename?: "ApproveTermsAndConditions";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type ApproveVendor = {
  __typename?: "ApproveVendor";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type Article = {
  __typename?: "Article";
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<ArticleCategory>;
  content?: Maybe<Scalars["String"]>;
  contentAr?: Maybe<Scalars["String"]>;
  contentImages?: Maybe<Array<Maybe<ArticleContentImage>>>;
  createdDate: Scalars["Instant"];
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  headerImage?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isRead?: Maybe<Scalars["Boolean"]>;
  priority?: Maybe<Scalars["Int"]>;
  readCounter?: Maybe<Scalars["Int"]>;
  readTimeMinutes?: Maybe<Scalars["Int"]>;
  readers?: Maybe<Array<Maybe<DigitalTwinHealthProfile>>>;
  references?: Maybe<Array<Maybe<Scalars["String"]>>>;
  relatedArticles?: Maybe<Array<Maybe<Article>>>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  title?: Maybe<Scalars["String"]>;
  titleAr?: Maybe<Scalars["String"]>;
};

export type ArticleCategory = {
  __typename?: "ArticleCategory";
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  displayAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type ArticleCategoryCountableConnection = {
  __typename?: "ArticleCategoryCountableConnection";
  edges: Array<ArticleCategoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
};

export type ArticleCategoryCountableEdge = {
  __typename?: "ArticleCategoryCountableEdge";
  node: ArticleCategory;
};

export type ArticleCategoryFilterInput = {
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type ArticleCategoryInput = {
  code: Scalars["String"];
  display: Scalars["String"];
  displayAr: Scalars["String"];
};

export enum ArticleCategorySortingField {
  Code = "CODE",
  Created = "CREATED",
  Id = "ID",
  Modified = "MODIFIED",
}

export type ArticleCategorySortingInput = {
  direction: OrderDirection;
  field?: InputMaybe<ArticleCategorySortingField>;
};

export type ArticleContentImage = {
  __typename?: "ArticleContentImage";
  alias: Scalars["String"];
  id: Scalars["ID"];
  url: Scalars["String"];
};

export type ArticleContentImageInput = {
  alias: Scalars["String"];
  url: Scalars["String"];
};

export type ArticleCountableConnection = {
  __typename?: "ArticleCountableConnection";
  edges: Array<ArticleCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type ArticleCountableEdge = {
  __typename?: "ArticleCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: Article;
};

export type ArticleFilterInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  search?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ArticleInput = {
  active: Scalars["Boolean"];
  categoryId: Scalars["ID"];
  content: Scalars["String"];
  contentAr: Scalars["String"];
  contentImageInputs?: InputMaybe<Array<InputMaybe<ArticleContentImageInput>>>;
  description: Scalars["String"];
  descriptionAr: Scalars["String"];
  headerImage?: InputMaybe<Scalars["String"]>;
  priority?: InputMaybe<Scalars["Int"]>;
  readTimeMinutes?: InputMaybe<Scalars["Int"]>;
  references?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  relatedArticles?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title: Scalars["String"];
  titleAr: Scalars["String"];
};

export enum ArticleSortingField {
  Created = "CREATED",
  Id = "ID",
  Modified = "MODIFIED",
  Priority = "PRIORITY",
  Title = "TITLE",
}

export type ArticleSortingInput = {
  direction: OrderDirection;
  field?: InputMaybe<ArticleSortingField>;
};

export type AssignDefaultBranch = {
  __typename?: "AssignDefaultBranch";
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type AssignUserToBranch = {
  __typename?: "AssignUserToBranch";
  branchErrors: Array<BranchError>;
  branchUser?: Maybe<BranchUser>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type AssigneeTeamMemberInput = {
  id: Scalars["ID"];
};

export enum AssigneeType {
  Patient = "PATIENT",
  TeamMember = "TEAM_MEMBER",
}

export type Attachment = Node & {
  __typename?: "Attachment";
  alt: Scalars["String"];
  contentType: Scalars["String"];
  created: Scalars["DateTime"];
  file?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  messages: MessageCountableConnection;
  modified: Scalars["DateTime"];
  sortOrder?: Maybe<Scalars["Int"]>;
};

export type AttachmentMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type AttachmentInput = {
  alt?: InputMaybe<Scalars["String"]>;
  contentType: Scalars["String"];
  file: Scalars["String"];
};

export type AuditLog = H_Node & {
  __typename?: "AuditLog";
  auditLogDirtyFields?: Maybe<Array<Maybe<AuditLogDirtyField>>>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  entityClassSimpleName?: Maybe<Scalars["String"]>;
  entityId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  operationType?: Maybe<AuditLogOperationType>;
};

export type AuditLogCountableConnection = {
  __typename?: "AuditLogCountableConnection";
  edges: Array<AuditLogCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type AuditLogCountableEdge = {
  __typename?: "AuditLogCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: AuditLog;
};

export type AuditLogDirtyField = H_Node & {
  __typename?: "AuditLogDirtyField";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  currentValue?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  previousValue?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type AuditLogFilterInput = {
  entityIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  entityNames?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  operationTypes?: InputMaybe<Array<InputMaybe<AuditLogOperationType>>>;
};

export enum AuditLogOperationType {
  Create = "CREATE",
  Delete = "DELETE",
  Update = "UPDATE",
}

export type AvailabilitiesAverage = {
  __typename?: "AvailabilitiesAverage";
  average?: Maybe<Scalars["Float"]>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export type BeeAndTdeeInput = {
  activityLevel: ActivityLevel;
  age: Scalars["Int"];
  gender: Gender;
  height: Scalars["Float"];
  weight: Scalars["Float"];
};

export type BeeAndTdeeInputLog = {
  __typename?: "BEEAndTDEEInputLog";
  activityLevel: ActivityLevel;
  age: Scalars["Int"];
  gender: Gender;
  height: Scalars["Float"];
  weight: Scalars["Float"];
};

export type BeeAndTdeeResponse = {
  __typename?: "BEEAndTDEEResponse";
  bee?: Maybe<Scalars["Float"]>;
  tdee?: Maybe<Scalars["Float"]>;
};

export type BeeAndTdeeResult = {
  __typename?: "BEEAndTDEEResult";
  bee?: Maybe<Scalars["Double"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<BeeAndTdeeInputLog>;
  tdee?: Maybe<Scalars["Double"]>;
};

export type BeeAndTdeeResultConnection = {
  __typename?: "BEEAndTDEEResultConnection";
  edges: Array<BeeAndTdeeResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BeeAndTdeeResultEdge = {
  __typename?: "BEEAndTDEEResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: BeeAndTdeeResult;
};

export type BenefitFilterInput = {
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type BenefitGroup = H_Node & {
  __typename?: "BenefitGroup";
  created?: Maybe<Scalars["Instant"]>;
  description?: Maybe<Scalars["String"]>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
};

export type BenefitGroupHealthProgramsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** CRUD a new Benefit Group */
export type BenefitGroupCrud = {
  __typename?: "BenefitGroupCRUD";
  benefitGroup?: Maybe<BenefitGroup>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type BenefitGroupComplex = H_Node & {
  __typename?: "BenefitGroupComplex";
  benefitGroup?: Maybe<BenefitGroup>;
  created?: Maybe<Scalars["Instant"]>;
  extraBenefits?: Maybe<Array<Maybe<ExtraBenefitComplex>>>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  optionsValues?: Maybe<Array<Maybe<BenefitOptionComplex>>>;
};

export type BenefitGroupInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type BenefitOption = H_Node & {
  __typename?: "BenefitOption";
  careType?: Maybe<CareType>;
  code?: Maybe<HealthProgramBenefitOptionCode>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<HealthProgramOptionType>;
};

export type BenefitOptionComplex = H_Node & {
  __typename?: "BenefitOptionComplex";
  created?: Maybe<Scalars["Instant"]>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  option?: Maybe<BenefitOption>;
  value?: Maybe<Scalars["Float"]>;
};

export type BenefitOptionComplexHealthProgramsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type Block = Node & {
  __typename?: "Block";
  city: City;
  coordinates?: Maybe<Array<Maybe<LocationType>>>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  nameAr?: Maybe<Scalars["String"]>;
};

export type BlockCountableConnection = {
  __typename?: "BlockCountableConnection";
  edges: Array<BlockCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BlockCountableEdge = {
  __typename?: "BlockCountableEdge";
  cursor: Scalars["String"];
  node: Block;
};

export type BlockCreate = {
  __typename?: "BlockCreate";
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BlockDelete = {
  __typename?: "BlockDelete";
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BlockError = {
  __typename?: "BlockError";
  code: BlockErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum BlockErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type BlockFilterInput = {
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
};

export type BlockInput = {
  city?: InputMaybe<Scalars["ID"]>;
  coordinates?: InputMaybe<Array<LocationInput>>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
};

export type BlockOrder = {
  direction: OrderDirection;
  field?: InputMaybe<BlockOrderField>;
};

export enum BlockOrderField {
  Created = "CREATED",
  Name = "NAME",
}

export type BlockUpdate = {
  __typename?: "BlockUpdate";
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BodyComposition = {
  __typename?: "BodyComposition";
  alcohol?: Maybe<Scalars["Boolean"]>;
  alcoholCupsPerDay?: Maybe<Scalars["Int"]>;
  dietType?: Maybe<DietType>;
  exercise?: Maybe<Scalars["Boolean"]>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  heightInCM?: Maybe<Scalars["Float"]>;
  patientId?: Maybe<Scalars["H_Long"]>;
  sleep?: Maybe<Scalars["Int"]>;
  smoking?: Maybe<Scalars["Int"]>;
  weightInKG?: Maybe<Scalars["Float"]>;
};

export type BodyFatPercentageInput = {
  age: Scalars["Int"];
  gender: Gender;
  height: Scalars["Float"];
  weight: Scalars["Float"];
};

export type BodyFatPercentageInputLog = {
  __typename?: "BodyFatPercentageInputLog";
  age: Scalars["Int"];
  gender: Gender;
  height: Scalars["Float"];
  weight: Scalars["Float"];
};

export type BodyFatPercentageResponse = {
  __typename?: "BodyFatPercentageResponse";
  bodyFatPercentage?: Maybe<Scalars["Float"]>;
};

export type BodyFatPercentageResult = {
  __typename?: "BodyFatPercentageResult";
  bodyFatPercentage?: Maybe<Scalars["Double"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<BodyFatPercentageInputLog>;
};

export type BodyFatPercentageResultConnection = {
  __typename?: "BodyFatPercentageResultConnection";
  edges: Array<BodyFatPercentageResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BodyFatPercentageResultEdge = {
  __typename?: "BodyFatPercentageResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: BodyFatPercentageResult;
};

export type Branch = Node & {
  __typename?: "Branch";
  acceptsDelivery: Scalars["Boolean"];
  acceptsPickup: Scalars["Boolean"];
  address?: Maybe<Address>;
  branchuser: BranchUserCountableConnection;
  consumers: UserCountableConnection;
  contactEmail?: Maybe<Scalars["String"]>;
  contactNumber?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  departments: DepartmentConnection;
  description: Scalars["String"];
  healthLicense?: Maybe<Scalars["String"]>;
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  isOpen?: Maybe<Scalars["Boolean"]>;
  isTrained: Scalars["Boolean"];
  messages?: Maybe<MessageCountableConnection>;
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  nameAr?: Maybe<Scalars["String"]>;
  preferredPharmacies?: Maybe<Array<Maybe<Branch>>>;
  seoDescription?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  type?: Maybe<BranchType>;
  users?: Maybe<Array<Maybe<User>>>;
  vendor: Vendor;
  workingHours?: Maybe<Array<Maybe<WorkingHour>>>;
  workingHoursOverride?: Maybe<Array<Maybe<WorkingHourOverride>>>;
};

export type BranchBranchuserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type BranchConsumersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type BranchDepartmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type BranchMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type BranchCountableConnection = {
  __typename?: "BranchCountableConnection";
  edges: Array<BranchCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BranchCountableEdge = {
  __typename?: "BranchCountableEdge";
  cursor: Scalars["String"];
  node: Branch;
};

export type BranchCreate = {
  __typename?: "BranchCreate";
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchDelete = {
  __typename?: "BranchDelete";
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchError = {
  __typename?: "BranchError";
  code: VendorErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type BranchFilterInput = {
  acceptsDelivery?: InputMaybe<Scalars["Boolean"]>;
  acceptsPickup?: InputMaybe<Scalars["Boolean"]>;
  branchGlobalSearch?: InputMaybe<Scalars["String"]>;
  contactNumber?: InputMaybe<Scalars["String"]>;
  contactNumber_Icontains?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  description_Icontains?: InputMaybe<Scalars["String"]>;
  enableVisitDetails?: InputMaybe<Scalars["Boolean"]>;
  hasChatWith?: InputMaybe<Scalars["Boolean"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isActiveBranch?: InputMaybe<Scalars["Boolean"]>;
  isIntegrated?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<DistanceFilterInput>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Array<InputMaybe<VendorBranchTypeEnum>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type BranchInput = {
  acceptsDelivery?: InputMaybe<Scalars["Boolean"]>;
  acceptsPickup?: InputMaybe<Scalars["Boolean"]>;
  addressInput?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactNumber?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  healthLicense?: InputMaybe<Scalars["String"]>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isTrained?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  preferredPharmacies?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type?: InputMaybe<VendorBranchTypeEnum>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  workingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
};

export type BranchOrder = {
  direction: OrderDirection;
  field?: InputMaybe<BranchOrderField>;
};

export enum BranchOrderField {
  Created = "CREATED",
  LastMessageSent = "LAST_MESSAGE_SENT",
  Name = "NAME",
  Nearest = "NEAREST",
}

export enum BranchType {
  Clinic = "CLINIC",
  DiagnosticCenter = "DIAGNOSTIC_CENTER",
  Hospital = "HOSPITAL",
  Pharmacy = "PHARMACY",
}

export type BranchUpdate = {
  __typename?: "BranchUpdate";
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchUser = {
  __typename?: "BranchUser";
  branch: Branch;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user: User;
};

export type BranchUserCountableConnection = {
  __typename?: "BranchUserCountableConnection";
  edges: Array<BranchUserCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BranchUserCountableEdge = {
  __typename?: "BranchUserCountableEdge";
  cursor: Scalars["String"];
  node: BranchUser;
};

export enum BulkErrorCodes {
  BulkCancelBulkFile = "BULK_CANCEL_BULK_FILE",
  BulkDuplicateRowData = "BULK_DUPLICATE_ROW_DATA",
  BulkFieldRequired = "BULK_FIELD_REQUIRED",
  BulkFileNotFound = "BULK_FILE_NOT_FOUND",
  BulkFileServiceNotReachable = "BULK_FILE_SERVICE_NOT_REACHABLE",
  BulkInvalidDateFormat = "BULK_INVALID_DATE_FORMAT",
  BulkInvalidFileExtension = "BULK_INVALID_FILE_EXTENSION",
  BulkNoneNegativePageSize = "BULK_NONE_NEGATIVE_PAGE_SIZE",
  BulkPermissionDenied = "BULK_PERMISSION_DENIED",
  BulkTemplateNotEnabled = "BULK_TEMPLATE_NOT_ENABLED",
  BulkUnknown = "BULK_UNKNOWN",
  BulkUnknownType = "BULK_UNKNOWN_TYPE",
  FileAlreadyExist = "FILE_ALREADY_EXIST",
}

export type BulkFile = {
  __typename?: "BulkFile";
  code?: Maybe<BulkTemplateCode>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  errors?: Maybe<Array<Maybe<BulkGraphqlError>>>;
  failedRecordCount?: Maybe<Scalars["Int"]>;
  fileName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  processedRecordCount?: Maybe<Scalars["Int"]>;
  processingStatus?: Maybe<BulkProcessingStatus>;
  recordsCount?: Maybe<Scalars["Int"]>;
  updatedDate?: Maybe<Scalars["Date"]>;
};

export type BulkFileConnection = {
  __typename?: "BulkFileConnection";
  edges?: Maybe<Array<Maybe<BulkFileEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BulkFileEdge = {
  __typename?: "BulkFileEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<BulkFile>;
};

export enum BulkFileSortField {
  Code = "CODE",
  CreatedDate = "CREATED_DATE",
  FailedRecordCount = "FAILED_RECORD_COUNT",
  FileName = "FILE_NAME",
  ProcessedRecordCount = "PROCESSED_RECORD_COUNT",
  RecordsCount = "RECORDS_COUNT",
  UpdatedDate = "UPDATED_DATE",
}

export type BulkFilesFilterInput = {
  code?: InputMaybe<BulkTemplateCode>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  createdDate?: InputMaybe<Scalars["Date"]>;
  entityID?: InputMaybe<Scalars["ID"]>;
  fileName?: InputMaybe<Scalars["String"]>;
  processingStatus?: InputMaybe<BulkProcessingStatus>;
  updateDate?: InputMaybe<Scalars["Date"]>;
};

export enum BulkFilesOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type BulkFilesSortingInput = {
  direction: BulkFilesOrderDirection;
  field: BulkFileSortField;
};

export type BulkGraphqlError = {
  __typename?: "BulkGraphqlError";
  code?: Maybe<BulkErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum BulkProcessingStatus {
  Cancelled = "CANCELLED",
  Pending = "PENDING",
  Processed = "PROCESSED",
  UnderProcess = "UNDER_PROCESS",
}

export type BulkTemplate = {
  __typename?: "BulkTemplate";
  code?: Maybe<BulkTemplateCode>;
  description?: Maybe<Scalars["String"]>;
};

export enum BulkTemplateCode {
  Doctor = "DOCTOR",
  HealthParameter = "HEALTH_PARAMETER",
  HealthProgramMember = "HEALTH_PROGRAM_MEMBER",
  HealthProgramNetwork = "HEALTH_PROGRAM_NETWORK",
  HealthProgramNetworkProvider = "HEALTH_PROGRAM_NETWORK_PROVIDER",
  MedicalForm = "MEDICAL_FORM",
}

export enum CalculatedPaymentStatusForSubscription {
  PaymentChargedBack = "PAYMENT_CHARGED_BACK",
  PaymentPending = "PAYMENT_PENDING",
  PaymentRejected = "PAYMENT_REJECTED",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  RefundedToWallet = "REFUNDED_TO_WALLET",
  RefundChargedBack = "REFUND_CHARGED_BACK",
  RefundPending = "REFUND_PENDING",
  RefundRejected = "REFUND_REJECTED",
  RefundSucceeded = "REFUND_SUCCEEDED",
}

export type CallAuditRecord = H_Node & {
  __typename?: "CallAuditRecord";
  accessHash?: Maybe<Scalars["String"]>;
  anonChatId?: Maybe<Scalars["String"]>;
  callAuditRecordCreatedAt?: Maybe<Scalars["Instant"]>;
  callAuditRecordCreatedBy?: Maybe<Scalars["String"]>;
  callAuditRecordId?: Maybe<Scalars["String"]>;
  callId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  fileId?: Maybe<Scalars["String"]>;
  fileUrl?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  ofUser?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["String"]>;
  type?: Maybe<CallSummaryAuditRecordType>;
};

export type CallDoctorNowInput = {
  branchId?: InputMaybe<Scalars["ID"]>;
  departmentId?: InputMaybe<Scalars["ID"]>;
  doctorId: Scalars["ID"];
  patientNote?: InputMaybe<Scalars["String"]>;
};

export enum CallRecordStatus {
  Available = "AVAILABLE",
  Ready = "READY",
  TranscriptedFailed = "TRANSCRIPTED_FAILED",
  TranscriptedSucceeded = "TRANSCRIPTED_SUCCEEDED",
  Unavailable = "UNAVAILABLE",
}

export enum CallSummaryAuditRecordType {
  Snapshot = "SNAPSHOT",
}

export type CallbackRequest = H_Node & {
  __typename?: "CallbackRequest";
  comments?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contactNo?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  patient?: Maybe<Patient>;
  patientNotes?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
  status?: Maybe<CallbackRequestStatus>;
  user?: Maybe<User>;
};

export type CallbackRequestAdminInput = {
  comments?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<CallbackRequestStatus>;
};

export type CallbackRequestCrud = {
  __typename?: "CallbackRequestCRUD";
  callbackRequest?: Maybe<CallbackRequest>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type CallbackRequestCountableConnection = {
  __typename?: "CallbackRequestCountableConnection";
  edges: Array<CallbackRequestCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CallbackRequestCountableEdge = {
  __typename?: "CallbackRequestCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: CallbackRequest;
};

export type CallbackRequestFilterInput = {
  contactNo?: InputMaybe<Scalars["String"]>;
  direction?: InputMaybe<H_OrderDirection>;
  patientId?: InputMaybe<Scalars["ID"]>;
  patientName?: InputMaybe<Scalars["String"]>;
  requestDateFrom?: InputMaybe<Scalars["Instant"]>;
  requestDateTo?: InputMaybe<Scalars["Instant"]>;
  serviceName?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<CallbackRequestStatus>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type CallbackRequestInput = {
  contactNo?: InputMaybe<Scalars["String"]>;
  patientNotes?: InputMaybe<Scalars["String"]>;
  serviceName: Scalars["String"];
};

export enum CallbackRequestStatus {
  InProgress = "IN_PROGRESS",
  New = "NEW",
  Resolved = "RESOLVED",
}

export type CallbackRequestUpdateHistory = H_Node & {
  __typename?: "CallbackRequestUpdateHistory";
  callbackRequestId?: Maybe<Scalars["ID"]>;
  comments?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  status?: Maybe<CallbackRequestStatus>;
  user?: Maybe<User>;
};

export type CallbackRequestUpdateHistoryCountableConnection = {
  __typename?: "CallbackRequestUpdateHistoryCountableConnection";
  edges: Array<CallbackRequestUpdateHistoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CallbackRequestUpdateHistoryCountableEdge = {
  __typename?: "CallbackRequestUpdateHistoryCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: CallbackRequestUpdateHistory;
};

export type CallsForVendorFilter = {
  fromDate: Scalars["String"];
  healthProgramId: Scalars["Int"];
  toDate: Scalars["String"];
  vendorId: Scalars["String"];
};

export type CallsPerVendorFilter = {
  fromDate: Scalars["String"];
  healthProgramId: Scalars["Int"];
  isUrgentDemandCall: Scalars["Boolean"];
  toDate: Scalars["String"];
};

export type CancelInput = {
  cancellationReason: Scalars["String"];
};

/** CRUD a new cancel reason. */
export type CancelReasonCrud = {
  __typename?: "CancelReasonCRUD";
  cancelReason?: Maybe<H_CancelReason>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type CancelReasonInput = {
  actor: Actor;
  affectedType: AffectedType;
  /** Cancel Reason */
  text: Scalars["String"];
  text_ar: Scalars["String"];
};

export type CardInput = {
  cardCVV: Scalars["String"];
  cardExpiryMonth: Scalars["Int"];
  cardExpiryYear: Scalars["Int"];
  cardHolder: Scalars["String"];
  cardNumber: Scalars["String"];
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  paymentBrand: PaymentBrand;
};

export type CardIssuer = {
  __typename?: "CardIssuer";
  bank?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type CareJourneyStatistics = {
  __typename?: "CareJourneyStatistics";
  adherence?: Maybe<Scalars["Float"]>;
  allActivities?: Maybe<Scalars["Int"]>;
  allCompletedActivities?: Maybe<Scalars["Int"]>;
  gaps?: Maybe<Scalars["Int"]>;
};

export type CareJourneyStatisticsFilterInput = {
  guidedCareHealthProgramId?: InputMaybe<Scalars["ID"]>;
  guidedCareJourneyId?: InputMaybe<Scalars["ID"]>;
  patientId?: InputMaybe<Scalars["ID"]>;
};

export enum CareType {
  ErxCoverage = "ERX_COVERAGE",
  ImmediateCare = "IMMEDIATE_CARE",
  Proactive = "PROACTIVE",
  Reactive = "REACTIVE",
}

export enum ChartType {
  Bar = "BAR",
  HorizontalBar = "HORIZONTAL_BAR",
  Line = "LINE",
  Table = "TABLE",
}

export type ChatError = {
  __typename?: "ChatError";
  code: ChatErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum ChatErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
}

export type ChatFlow = {
  __typename?: "ChatFlow";
  code: Scalars["String"];
  conditionsAndRules?: Maybe<Scalars["String"]>;
  contributors?: Maybe<Array<User>>;
  createdAt: Scalars["Instant"];
  description?: Maybe<Scalars["String"]>;
  documents?: Maybe<Array<ChatFlowDocument>>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Instant"];
};

export type ChatFlowConnection = {
  __typename?: "ChatFlowConnection";
  edges: Array<ChatFlowEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChatFlowCreateInput = {
  conditionsAndRules?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type ChatFlowDocument = {
  __typename?: "ChatFlowDocument";
  createdAt: Scalars["Instant"];
  fileName: Scalars["String"];
  fileSize: Scalars["Int"];
  fileType: FileType;
  id: Scalars["ID"];
};

export type ChatFlowDocumentInput = {
  convertToText?: InputMaybe<Scalars["Boolean"]>;
  fileName: Scalars["String"];
  fileType: FileType;
};

export type ChatFlowEdge = {
  __typename?: "ChatFlowEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: ChatFlow;
};

export type ChatFlowFilterInput = {
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ChatFlowSortingInput = {
  direction?: InputMaybe<FlowrseIntegrationSortDirection>;
  field?: InputMaybe<ChatSortingField>;
};

export type ChatFlowUpdateInput = {
  conditionsAndRules: Scalars["String"];
  description: Scalars["String"];
  name: Scalars["String"];
};

export enum ChatSortingField {
  Code = "CODE",
  Created = "CREATED",
  Description = "DESCRIPTION",
  Name = "NAME",
  Updated = "UPDATED",
}

export type CheckCustomerAccountExist = {
  __typename?: "CheckCustomerAccountExist";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  isContactNumberExists?: Maybe<Scalars["Boolean"]>;
  isEmailExists?: Maybe<Scalars["Boolean"]>;
  patient?: Maybe<Patient>;
};

export type ChooseOrderPaymentMethod = {
  __typename?: "ChooseOrderPaymentMethod";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type City = Node & {
  __typename?: "City";
  addresses: AddressCountableConnection;
  area?: Maybe<Scalars["String"]>;
  blocks: BlockCountableConnection;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  maxNumberOfRounds?: Maybe<Scalars["Int"]>;
  maxNumberOfTries?: Maybe<Scalars["Int"]>;
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  nameAr?: Maybe<Scalars["String"]>;
  rounds?: Maybe<Array<Maybe<CityRound>>>;
  timeOutPeriod?: Maybe<Scalars["Int"]>;
};

export type CityAddressesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CityBlocksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type CityCountableConnection = {
  __typename?: "CityCountableConnection";
  edges: Array<CityCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CityCountableEdge = {
  __typename?: "CityCountableEdge";
  cursor: Scalars["String"];
  node: City;
};

export type CityCreate = {
  __typename?: "CityCreate";
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CityDelete = {
  __typename?: "CityDelete";
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CityFilterInput = {
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
};

export type CityInput = {
  area?: InputMaybe<Scalars["String"]>;
  maxNumberOfRounds?: InputMaybe<Scalars["Int"]>;
  maxNumberOfTries?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  rounds?: InputMaybe<Array<InputMaybe<RoundInput>>>;
  timeOutPeriod?: InputMaybe<Scalars["Int"]>;
};

export type CityOrder = {
  direction: OrderDirection;
  field?: InputMaybe<CityOrderField>;
};

export enum CityOrderField {
  Created = "CREATED",
  Name = "NAME",
}

export type CityRound = Node & {
  __typename?: "CityRound";
  city: City;
  id: Scalars["ID"];
  maxNumberOfPharmacies: Scalars["Int"];
  pharmaciesTypes?: Maybe<Array<Maybe<VendorBranchTypeEnum>>>;
  radius: Scalars["Float"];
};

export type CityUpdate = {
  __typename?: "CityUpdate";
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type Clinician = {
  __typename?: "Clinician";
  email?: Maybe<Scalars["String"]>;
  englishName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  license?: Maybe<Scalars["String"]>;
  licenseEnd?: Maybe<Scalars["Date"]>;
  licenseStart?: Maybe<Scalars["Date"]>;
  nationalId?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  tempPassword?: Maybe<Scalars["String"]>;
};

export type ClinicianCredentials = {
  __typename?: "ClinicianCredentials";
  password: Scalars["String"];
  user: User;
  userName: Scalars["String"];
};

export type ClinicianCredentialsInput = {
  password: Scalars["String"];
  user: Scalars["ID"];
  userName: Scalars["String"];
};

export type CodeSystem = {
  __typename?: "CodeSystem";
  codeSystemConcepts?: Maybe<Array<Maybe<CodeSystemConcept>>>;
  codeSystemDefinition?: Maybe<CodeSystemDefinition>;
  codeSystemHistory?: Maybe<Array<Maybe<CodeSystemHistory>>>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  processingStatus?: Maybe<ProcessingStatus>;
  version?: Maybe<Scalars["Int"]>;
};

export enum CodeSystemCode {
  Allergy = "ALLERGY",
  CancellationReason = "CANCELLATION_REASON",
  ChronicDisease = "CHRONIC_DISEASE",
  ChronicMedication = "CHRONIC_MEDICATION",
  City = "CITY",
  Clinician = "CLINICIAN",
  Cpt = "CPT",
  DenialCode = "DENIAL_CODE",
  Diagnosis = "DIAGNOSIS",
  DiagnosisRelatedGroup = "DIAGNOSIS_RELATED_GROUP",
  Disability = "DISABILITY",
  Division = "DIVISION",
  Family = "FAMILY",
  GenericDrug = "GENERIC_DRUG",
  GenericDrugExtraData = "GENERIC_DRUG_EXTRA_DATA",
  HealthPackageCategory = "HEALTH_PACKAGE_CATEGORY",
  HealthProfessionalSpeciality = "HEALTH_PROFESSIONAL_SPECIALITY",
  HealthProvider = "HEALTH_PROVIDER",
  LabTest = "LAB_TEST",
  Loinc = "LOINC",
  MedicalService = "MEDICAL_SERVICE",
  MobileTranslation = "MOBILE_TRANSLATION",
  Nationality = "NATIONALITY",
  NonChronicDisease = "NON_CHRONIC_DISEASE",
  RejectionReason = "REJECTION_REASON",
  RouteOfAdmin = "ROUTE_OF_ADMIN",
  Service = "SERVICE",
  Speciality = "SPECIALITY",
  Supplement = "SUPPLEMENT",
  Surgery = "SURGERY",
  Temperature = "TEMPERATURE",
  TradeDrug = "TRADE_DRUG",
  TradeDrugExtraData = "TRADE_DRUG_EXTRA_DATA",
  UnitId = "UNIT_ID",
}

export type CodeSystemConcept = {
  __typename?: "CodeSystemConcept";
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  properties?: Maybe<Array<Maybe<CodeSystemConceptProperty>>>;
};

export type CodeSystemConceptChange = {
  __typename?: "CodeSystemConceptChange";
  newCodeSystemConcept?: Maybe<CodeSystemConcept>;
  oldCodeSystemConcept?: Maybe<CodeSystemConcept>;
};

export type CodeSystemConceptConnection = {
  __typename?: "CodeSystemConceptConnection";
  edges?: Maybe<Array<Maybe<CodeSystemConceptEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CodeSystemConceptEdge = {
  __typename?: "CodeSystemConceptEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<CodeSystemConcept>;
};

export type CodeSystemConceptFieldDefinition = {
  __typename?: "CodeSystemConceptFieldDefinition";
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  fieldType?: Maybe<Type>;
  id?: Maybe<Scalars["ID"]>;
  isEditable: Scalars["Boolean"];
  isMandatory?: Maybe<Scalars["Boolean"]>;
  isVisible?: Maybe<Scalars["Boolean"]>;
  toolTip?: Maybe<Scalars["String"]>;
};

export type CodeSystemConceptFieldDefinitionInput = {
  code: Scalars["String"];
  codeSystemDefinition: CodeSystemDefinitionInput;
  display: Scalars["String"];
  fieldType: Type;
  id?: InputMaybe<Scalars["ID"]>;
  isMandatory: Scalars["Boolean"];
  isVisible: Scalars["Boolean"];
  toolTip?: InputMaybe<Scalars["String"]>;
};

export type CodeSystemConceptInput = {
  code?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  properties?: InputMaybe<Array<InputMaybe<CodeSystemConceptPropertyInput>>>;
};

export type CodeSystemConceptProperty = {
  __typename?: "CodeSystemConceptProperty";
  code?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  fieldType?: Maybe<Type>;
  id?: Maybe<Scalars["ID"]>;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueDate?: Maybe<Scalars["Date"]>;
  valueFloat?: Maybe<Scalars["Float"]>;
  valueInteger?: Maybe<Scalars["Int"]>;
  valueString?: Maybe<Scalars["String"]>;
};

export type CodeSystemConceptPropertyInput = {
  code?: InputMaybe<Scalars["String"]>;
  fieldType?: InputMaybe<Type>;
  id?: InputMaybe<Scalars["ID"]>;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueDate?: InputMaybe<Scalars["Date"]>;
  valueFloat?: InputMaybe<Scalars["Float"]>;
  valueInteger?: InputMaybe<Scalars["Int"]>;
  valueString?: InputMaybe<Scalars["String"]>;
};

export type CodeSystemConnection = {
  __typename?: "CodeSystemConnection";
  edges?: Maybe<Array<Maybe<CodeSystemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CodeSystemDefinition = {
  __typename?: "CodeSystemDefinition";
  code?: Maybe<CodeSystemCode>;
  codeSystemConceptFields?: Maybe<Array<Maybe<CodeSystemConceptFieldDefinition>>>;
  display?: Maybe<Scalars["String"]>;
  editorUserId?: Maybe<User>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  isEditable?: Maybe<Scalars["Boolean"]>;
  reviewerUserId?: Maybe<User>;
};

export type CodeSystemDefinitionInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type CodeSystemEdge = {
  __typename?: "CodeSystemEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<CodeSystem>;
};

export type CodeSystemEvent = {
  __typename?: "CodeSystemEvent";
  codeSystemCode?: Maybe<CodeSystemCode>;
  id?: Maybe<Scalars["ID"]>;
  processingStatus?: Maybe<ProcessingStatus>;
};

export type CodeSystemHistory = {
  __typename?: "CodeSystemHistory";
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  processingStatus?: Maybe<ProcessingStatus>;
  updateDate?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
};

export type CodeSystemSummery = {
  __typename?: "CodeSystemSummery";
  activeCodeSystem?: Maybe<CodeSystem>;
  codeSystemDefinition?: Maybe<CodeSystemDefinition>;
  iconUrl?: Maybe<Scalars["String"]>;
  lastCodeSystem?: Maybe<CodeSystem>;
};

export type Coding = {
  __typename?: "Coding";
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
};

export enum Community {
  Rural = "RURAL",
  Urban = "URBAN",
}

export type Condition = {
  __typename?: "Condition";
  conditionOperation?: Maybe<ConditionOperation>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  entityType?: Maybe<EntityType>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars["ID"]>;
  isGroup?: Maybe<Scalars["Boolean"]>;
  operator?: Maybe<Operator>;
  value?: Maybe<ConditionValue>;
};

export type ConditionInput = {
  conditionOperation?: InputMaybe<ConditionOperation>;
  conditions?: InputMaybe<Array<InputMaybe<ConditionInput>>>;
  entityType?: InputMaybe<EntityType>;
  field?: InputMaybe<FieldInput>;
  isGroup: Scalars["Boolean"];
  operator?: InputMaybe<Operator>;
  value?: InputMaybe<ConditionValueInput>;
};

export enum ConditionOperation {
  And = "AND",
  Or = "OR",
}

export type ConditionValue = {
  __typename?: "ConditionValue";
  extra?: Maybe<Scalars["String"]>;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueDate?: Maybe<Scalars["Date"]>;
  valueID?: Maybe<Scalars["String"]>;
  valueList?: Maybe<Scalars["ID"]>;
  valueNumber?: Maybe<Scalars["Float"]>;
  valueString?: Maybe<Scalars["String"]>;
};

export type ConditionValueInput = {
  extra?: InputMaybe<Scalars["String"]>;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueDate?: InputMaybe<Scalars["Date"]>;
  valueID?: InputMaybe<Scalars["String"]>;
  valueList?: InputMaybe<Scalars["ID"]>;
  valueNumber?: InputMaybe<Scalars["Float"]>;
  valueString?: InputMaybe<Scalars["String"]>;
};

export type ConfigurationItem = {
  __typename?: "ConfigurationItem";
  helpText?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  type?: Maybe<ConfigurationTypeFieldEnum>;
  value?: Maybe<Scalars["String"]>;
};

export type ConfigurationItemInput = {
  name: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export enum ConfigurationTypeFieldEnum {
  Boolean = "BOOLEAN",
  Integer = "INTEGER",
  Password = "PASSWORD",
  Secret = "SECRET",
  String = "STRING",
}

export type ConfirmAccountEmail = {
  __typename?: "ConfirmAccountEmail";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ConfirmAccountMobile = {
  __typename?: "ConfirmAccountMobile";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ConfirmPasswordRestOtp = {
  __typename?: "ConfirmPasswordRestOTP";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ConfirmVerification = {
  __typename?: "ConfirmVerification";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars["Boolean"]>;
};

export enum ConsentLevel {
  All = "ALL",
  Basic = "BASIC",
  Medical = "MEDICAL",
}

export enum ConsentStatus {
  Approved = "APPROVED",
  Closed = "CLOSED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum ConsentType {
  All = "ALL",
  View = "VIEW",
}

export type ConsumerAppointmentConfirmInput = {
  confirmedEndTime: Scalars["Instant"];
  confirmedStartTime: Scalars["Instant"];
  doctor?: InputMaybe<Scalars["ID"]>;
};

/** Creates an Appointment. */
export type ConsumerAppointmentCreate = {
  __typename?: "ConsumerAppointmentCreate";
  appointment?: Maybe<Appointment>;
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type ConsumerAppointmentInput = {
  branchId?: InputMaybe<Scalars["ID"]>;
  departmentId?: InputMaybe<Scalars["ID"]>;
  doctor: Scalars["ID"];
  healthProgram?: InputMaybe<Scalars["ID"]>;
  requestedTimeSlots: Array<AppointmentTimeSlotInput>;
  type?: InputMaybe<AppointmentType>;
};

export type ConsumerAppointmentInputForAnonymous = {
  branchId?: InputMaybe<Scalars["ID"]>;
  departmentId?: InputMaybe<Scalars["ID"]>;
  doctor: Scalars["ID"];
  insuranceCategoryCode?: InputMaybe<Scalars["String"]>;
  insuranceCompanyName?: InputMaybe<Scalars["String"]>;
  insuranceId?: InputMaybe<Scalars["String"]>;
  insurancePolicyNumber?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  nationalId?: InputMaybe<Scalars["String"]>;
  nationality: Scalars["String"];
  phone: Scalars["String"];
  requestedTimeSlots: Array<AppointmentTimeSlotInput>;
  type?: InputMaybe<AppointmentType>;
  verificationCode?: InputMaybe<Scalars["Int"]>;
};

export type ConsumerAppointmentRescheduleInput = {
  requestedTimeSlots: Array<AppointmentTimeSlotInput>;
};

/** follow health channel. */
export type ConsumerHealthChannelFollow = {
  __typename?: "ConsumerHealthChannelFollow";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
  healthChannelFollower?: Maybe<HealthChannelFollower>;
};

export type ConsumerHealthChannelFollowInput = {
  channel: Scalars["ID"];
};

/** UnFollow health channel. */
export type ConsumerHealthChannelUnFollow = {
  __typename?: "ConsumerHealthChannelUnFollow";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type ConsumerPrescriptionDispense = {
  __typename?: "ConsumerPrescriptionDispense";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type ConsumerPrescriptionDispenseInput = {
  deliveryDate?: InputMaybe<Scalars["Date"]>;
  deliveryTimeSlot?: InputMaybe<Scalars["ID"]>;
  isDeliveryRequested: Scalars["Boolean"];
  memberId?: InputMaybe<Scalars["String"]>;
  referenceNumber: Scalars["String"];
  shippingAddress: AddressInput;
};

/** Rate a Health Channel */
export type ConsumerRateHealthChannel = {
  __typename?: "ConsumerRateHealthChannel";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
  healthChannelRating?: Maybe<HealthChannelRating>;
};

export type ConsumerViewPreference = Node & {
  __typename?: "ConsumerViewPreference";
  data?: Maybe<Scalars["JSONString"]>;
  id: Scalars["ID"];
  user: User;
  view: Scalars["String"];
};

export type ConsumerViewPreferenceError = {
  __typename?: "ConsumerViewPreferenceError";
  code: ConsumerViewPreferenceErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum ConsumerViewPreferenceErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type ConsumerViewPreferenceInput = {
  data?: InputMaybe<Scalars["JSONString"]>;
  view?: InputMaybe<Scalars["String"]>;
};

export type ConsumerViewPreferenceSave = {
  __typename?: "ConsumerViewPreferenceSave";
  consumerViewPreference?: Maybe<ConsumerViewPreference>;
  consumerViewPreferenceErrors: Array<ConsumerViewPreferenceError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type ConvertVirtualOrderToRealOne = {
  __typename?: "ConvertVirtualOrderToRealOne";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  orders?: Maybe<Array<Maybe<Order>>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CountryDisplay = {
  __typename?: "CountryDisplay";
  code: Scalars["String"];
  country: Scalars["String"];
};

export enum Coverage {
  FullyCovered = "FULLY_COVERED",
  NotCovered = "NOT_COVERED",
  PartiallyCovered = "PARTIALLY_COVERED",
}

export enum CoverageByType {
  Cash = "CASH",
  Insurance = "INSURANCE",
  Provider = "PROVIDER",
  Tpa = "TPA",
}

export type CreatePersonalizedAppointmentInput = {
  appointmentServiceDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  doctorNote?: InputMaybe<Scalars["String"]>;
  justification?: InputMaybe<Scalars["String"]>;
  medicalFormId?: InputMaybe<Scalars["ID"]>;
  medicalMessageId?: InputMaybe<Scalars["ID"]>;
  memberId: Scalars["String"];
  messageToPatient?: InputMaybe<Scalars["String"]>;
  serviceDescription?: InputMaybe<ServiceDescription>;
  startTime: Scalars["Instant"];
  type: GuidedCareActivityType;
};

export type CreatePersonalizedAppointmentsInput = {
  DurationBetweenAppointments: Scalars["Int"];
  appointmentServiceDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  doctorNote?: InputMaybe<Scalars["String"]>;
  frequencyType: AppointmentFrequencyType;
  justification?: InputMaybe<Scalars["String"]>;
  medicalFormId?: InputMaybe<Scalars["ID"]>;
  medicalMessageId?: InputMaybe<Scalars["ID"]>;
  memberId: Scalars["ID"];
  messageToPatient?: InputMaybe<Scalars["String"]>;
  numberOfAppointments: Scalars["Int"];
  serviceDescription?: InputMaybe<ServiceDescription>;
  startTime: Scalars["Instant"];
  type: GuidedCareActivityType;
};

export type CreateVisitPrescriptionActivityInput = {
  code: Scalars["String"];
  duration: Scalars["Int"];
  frequencyType: VisitPrescriptionActivityFrequencyType;
  frequencyUnitType: Scalars["String"];
  frequencyValue: Scalars["Int"];
  instructions: Scalars["String"];
  observation?: InputMaybe<Scalars["String"]>;
  quantity: Scalars["Float"];
  refills: Scalars["Int"];
  type: VisitPrescriptionActivityType;
};

export type CreateVisitPrescriptionDiagnosisInput = {
  code: Scalars["String"];
  type: VisitDiagnosisType;
};

export type CreateVisitPrescriptionInput = {
  activities: Array<InputMaybe<CreateVisitPrescriptionActivityInput>>;
  diagnoses: Array<InputMaybe<CreateVisitPrescriptionDiagnosisInput>>;
  memberWeight?: InputMaybe<Scalars["Int"]>;
  /** this is only used by vendor integration when notifying creating a prescription */
  reference?: InputMaybe<Scalars["String"]>;
  visitId: Scalars["ID"];
};

export enum Currency {
  Aed = "AED",
  Eur = "EUR",
  Sar = "SAR",
  Usd = "USD",
}

export type CustomerCreate = {
  __typename?: "CustomerCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type CustomerCreateInput = {
  contactNumber?: InputMaybe<Scalars["String"]>;
  dateOfBirth: Scalars["Date"];
  email?: InputMaybe<Scalars["String"]>;
  emailVerificationToken?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender: PersonGenderEnum;
  isDependent?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  mobileVerificationToken?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationTypes>;
};

export type CustomerDashboardFilterInput = {
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
};

export type CustomerDelete = {
  __typename?: "CustomerDelete";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type CustomerDeleteInput = {
  deleteReason: Scalars["String"];
  email?: InputMaybe<Scalars["String"]>;
  emailVerificationToken?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  mobileVerificationToken?: InputMaybe<Scalars["String"]>;
};

export type CustomerEvent = Node & {
  __typename?: "CustomerEvent";
  count?: Maybe<Scalars["Int"]>;
  date?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  message?: Maybe<Scalars["String"]>;
  type?: Maybe<CustomerEventsEnum>;
  user?: Maybe<User>;
};

export enum CustomerEventsEnum {
  AccountCreated = "ACCOUNT_CREATED",
  AccountCreatedFromSso = "ACCOUNT_CREATED_FROM_SSO",
  CustomerDeleted = "CUSTOMER_DELETED",
  DigitalLinkDownloaded = "DIGITAL_LINK_DOWNLOADED",
  EmailAssigned = "EMAIL_ASSIGNED",
  EmailChanged = "EMAIL_CHANGED",
  EmailChangedRequest = "EMAIL_CHANGED_REQUEST",
  NameAssigned = "NAME_ASSIGNED",
  NoteAdded = "NOTE_ADDED",
  NoteAddedToOrder = "NOTE_ADDED_TO_ORDER",
  PasswordChanged = "PASSWORD_CHANGED",
  PasswordReset = "PASSWORD_RESET",
  PasswordResetLinkSent = "PASSWORD_RESET_LINK_SENT",
  PlacedOrder = "PLACED_ORDER",
}

export type CustomerFavoriteVendor = Node & {
  __typename?: "CustomerFavoriteVendor";
  customer: User;
  id: Scalars["ID"];
  vendor: Vendor;
};

export type CustomerFavoriteVendorCountableConnection = {
  __typename?: "CustomerFavoriteVendorCountableConnection";
  edges: Array<CustomerFavoriteVendorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CustomerFavoriteVendorCountableEdge = {
  __typename?: "CustomerFavoriteVendorCountableEdge";
  cursor: Scalars["String"];
  node: CustomerFavoriteVendor;
};

export type CustomerFilterInput = {
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
  nationalId?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type CustomerMarkMessagesAsSeen = {
  __typename?: "CustomerMarkMessagesAsSeen";
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars["Int"]>;
};

export type CustomerProfileUpdate = {
  __typename?: "CustomerProfileUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type CustomerProfileUpdateInput = {
  bloodGroup?: InputMaybe<Scalars["String"]>;
  contactNumber?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  lastName?: InputMaybe<Scalars["String"]>;
  maritalStatus?: InputMaybe<MaritalStatusesEnum>;
  photo?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationTypes>;
  residencyType?: InputMaybe<ResidencyTypesEnum>;
};

export type CustomerSendMessage = {
  __typename?: "CustomerSendMessage";
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  message?: Maybe<Message>;
};

export type CustomerSupportErxOrderStatusUpdate = {
  __typename?: "CustomerSupportERXOrderStatusUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export enum CustomerSupportOrderStatusEnum {
  Delivered = "DELIVERED",
  Failed = "FAILED",
  Returned = "RETURNED",
}

export type DateRangeInput = {
  gte?: InputMaybe<Scalars["Date"]>;
  lte?: InputMaybe<Scalars["Date"]>;
};

export type DateTimeFilterInput = {
  date: Scalars["Date"];
  time: TimeRangeFilterInput;
};

export enum DayOfWeekEnum {
  Fri = "FRI",
  Mon = "MON",
  Sat = "SAT",
  Sun = "SUN",
  Thu = "THU",
  Tue = "TUE",
  Wed = "WED",
}

export type DecimalRangeInput = {
  gte?: InputMaybe<Scalars["Decimal"]>;
  lte?: InputMaybe<Scalars["Decimal"]>;
};

export type Decision = {
  __typename?: "Decision";
  actionFields?: Maybe<Array<Maybe<ActionField>>>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  decisionPlan?: Maybe<DecisionPlan>;
  drl?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  healthProgramTemplate?: Maybe<HealthProgramTemplate>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isScript?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  priority?: Maybe<Priority>;
};

export type DecisionConnection = {
  __typename?: "DecisionConnection";
  edges?: Maybe<Array<Maybe<DecisionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DecisionEdge = {
  __typename?: "DecisionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Decision>;
};

export type DecisionFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<DecisionPlanCategory>>>;
  decisionName?: InputMaybe<Scalars["String"]>;
  guidedCareTemplateCode?: InputMaybe<Scalars["String"]>;
  healthParameterId?: InputMaybe<Scalars["ID"]>;
  healthProgramTemplateId?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isScript?: InputMaybe<Scalars["Boolean"]>;
  plan?: InputMaybe<Scalars["ID"]>;
};

export type DecisionInput = {
  actionFields?: InputMaybe<Array<InputMaybe<ActionFieldInput>>>;
  conditions: Array<InputMaybe<ConditionInput>>;
  decisionPlan: DecisionPlanInput;
  drl?: InputMaybe<Scalars["String"]>;
  guidedCareTemplateCode?: InputMaybe<Scalars["String"]>;
  healthParameterId?: InputMaybe<Scalars["ID"]>;
  healthProgramTemplateId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  isActive: Scalars["Boolean"];
  isScript: Scalars["Boolean"];
  name: Scalars["String"];
  priority: Priority;
};

export type DecisionInputData = {
  code: Scalars["String"];
  type: EntityType;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueDate?: InputMaybe<Scalars["Date"]>;
  valueID?: InputMaybe<Scalars["String"]>;
  valueList?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  valueNumber?: InputMaybe<Scalars["Float"]>;
  valueString?: InputMaybe<Scalars["String"]>;
};

export enum DecisionMakerErrorCodes {
  DecisionFileNotFound = "DECISION_FILE_NOT_FOUND",
  DecisionFileServiceNotReachable = "DECISION_FILE_SERVICE_NOT_REACHABLE",
  DecisionInvalidFileExtension = "DECISION_INVALID_FILE_EXTENSION",
  DecisionInvalidId = "DECISION_INVALID_ID",
  DecisionMakerDuplicateListItem = "DECISION_MAKER_DUPLICATE_LIST_ITEM",
  DecisionMakerDuplicateListName = "DECISION_MAKER_DUPLICATE_LIST_NAME",
  DecisionMakerDuplicateRuleName = "DECISION_MAKER_DUPLICATE_RULE_NAME",
  DecisionMakerEntityNotFound = "DECISION_MAKER_ENTITY_NOT_FOUND",
  DecisionMakerFieldMandatory = "DECISION_MAKER_FIELD_MANDATORY",
  DecisionMakerFieldSize = "DECISION_MAKER_FIELD_SIZE",
  DecisionMakerFieldSizeException = "DECISION_MAKER_FIELD_SIZE_EXCEPTION",
  DecisionMakerInvalidAction = "DECISION_MAKER_INVALID_ACTION",
  DecisionMakerInvalidOperator = "DECISION_MAKER_INVALID_OPERATOR",
  DecisionMakerInvalidRule = "DECISION_MAKER_INVALID_RULE",
  DecisionMakerInvalidValue = "DECISION_MAKER_INVALID_VALUE",
  DecisionMakerNoneNegativePageSize = "DECISION_MAKER_NONE_NEGATIVE_PAGE_SIZE",
  DecisionMakerSubListNotFound = "DECISION_MAKER_SUB_LIST_NOT_FOUND",
  DecisionMakerUnknown = "DECISION_MAKER_UNKNOWN",
  DecisionMakerUnknownType = "DECISION_MAKER_UNKNOWN_TYPE",
  DecisionMakerValidationError = "DECISION_MAKER_VALIDATION_ERROR",
  DecisionPermissionDenied = "DECISION_PERMISSION_DENIED",
  InvalidOrExpiredToken = "INVALID_OR_EXPIRED_TOKEN",
}

export type DecisionMakerGraphqlError = {
  __typename?: "DecisionMakerGraphqlError";
  code?: Maybe<DecisionMakerErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum DecisionMakerOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type DecisionPlan = {
  __typename?: "DecisionPlan";
  actionDefinitionFields?: Maybe<Array<Maybe<ActionDefinitionField>>>;
  category?: Maybe<DecisionPlanCategory>;
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  field?: Maybe<Array<Maybe<Field>>>;
  id?: Maybe<Scalars["ID"]>;
};

export enum DecisionPlanCategory {
  HealthCondition = "HEALTH_CONDITION",
  Intervention = "INTERVENTION",
  PatientEligibility = "PATIENT_ELIGIBILITY",
  RiskStratification = "RISK_STRATIFICATION",
  TaskFlow = "TASK_FLOW",
  UserRequiredAction = "USER_REQUIRED_ACTION",
}

export type DecisionPlanConnection = {
  __typename?: "DecisionPlanConnection";
  edges?: Maybe<Array<Maybe<DecisionPlanEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DecisionPlanEdge = {
  __typename?: "DecisionPlanEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<DecisionPlan>;
};

export type DecisionPlanFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<DecisionPlanCategory>>>;
  planCode?: InputMaybe<Scalars["String"]>;
};

export type DecisionPlanInput = {
  id: Scalars["ID"];
};

export enum DecisionSortField {
  DecisionPlan = "DECISION_PLAN",
  IsActive = "IS_ACTIVE",
  Name = "NAME",
  Type = "TYPE",
}

export type DecisionSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: DecisionSortField;
};

export enum DefaultSortingField {
  Created = "CREATED",
}

export type DefaultSortingInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<DefaultSortingField>;
};

export type DeleteCurrentCustomerUser = {
  __typename?: "DeleteCurrentCustomerUser";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type DeliverySortingInput = {
  direction: OrderDirection;
  field: DeliveryTimeSlotSortField;
};

export type DeliveryTimeSlot = Node & {
  __typename?: "DeliveryTimeSlot";
  deliveryType: DeliveryTimeSlotDeliveryType;
  endTime: Scalars["Time"];
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  orders: OrderCountableConnection;
  startTime: Scalars["Time"];
};

export type DeliveryTimeSlotOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type DeliveryTimeSlotCountableConnection = {
  __typename?: "DeliveryTimeSlotCountableConnection";
  edges: Array<DeliveryTimeSlotCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DeliveryTimeSlotCountableEdge = {
  __typename?: "DeliveryTimeSlotCountableEdge";
  cursor: Scalars["String"];
  node: DeliveryTimeSlot;
};

export type DeliveryTimeSlotCreate = {
  __typename?: "DeliveryTimeSlotCreate";
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  deliveryTimeSlotsErrors: Array<DeliveryTimeSlotError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DeliveryTimeSlotCreateInput = {
  endTime: Scalars["Time"];
  isActive: Scalars["Boolean"];
  startTime: Scalars["Time"];
};

export enum DeliveryTimeSlotDeliveryType {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export type DeliveryTimeSlotError = {
  __typename?: "DeliveryTimeSlotError";
  code: DeliveryTimeSlotErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum DeliveryTimeSlotErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type DeliveryTimeSlotFilterInput = {
  deliveryType?: InputMaybe<DeliveryTypesEnum>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
};

export enum DeliveryTimeSlotSortField {
  EndTime = "END_TIME",
  IsActive = "IS_ACTIVE",
  StartTime = "START_TIME",
}

export type DeliveryTimeSlotUpdateStatus = {
  __typename?: "DeliveryTimeSlotUpdateStatus";
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  deliveryTimeSlotsErrors: Array<DeliveryTimeSlotError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export enum DeliveryType {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export enum DeliveryTypes {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export enum DeliveryTypesEnum {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export type DentalHygienist = Node & {
  __typename?: "DentalHygienist";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type DentalHygienistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export type Department = Node & {
  __typename?: "Department";
  branch: Branch;
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  directions?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  medicalServices?: Maybe<DepartmentMedicalServiceCountableConnection>;
  name: Scalars["String"];
  users?: Maybe<UserCountableConnection>;
};

export type DepartmentMedicalServicesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type DepartmentUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type DepartmentConnection = {
  __typename?: "DepartmentConnection";
  edges: Array<Maybe<DepartmentEdge>>;
  pageInfo: PageInfo;
};

export type DepartmentEdge = {
  __typename?: "DepartmentEdge";
  cursor: Scalars["String"];
  node?: Maybe<Department>;
};

export type DepartmentMedicalService = Node & {
  __typename?: "DepartmentMedicalService";
  code: Scalars["String"];
  department: Department;
  id: Scalars["ID"];
};

export type DepartmentMedicalServiceCountableConnection = {
  __typename?: "DepartmentMedicalServiceCountableConnection";
  edges: Array<DepartmentMedicalServiceCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DepartmentMedicalServiceCountableEdge = {
  __typename?: "DepartmentMedicalServiceCountableEdge";
  cursor: Scalars["String"];
  node: DepartmentMedicalService;
};

export enum DepressionFrequency {
  MoreThanHalfTheDays = "MORE_THAN_HALF_THE_DAYS",
  NearlyEveryDay = "NEARLY_EVERY_DAY",
  NotAtAll = "NOT_AT_ALL",
  SeveralDays = "SEVERAL_DAYS",
}

export type DepressionScreeningInput = {
  feelingDown: DepressionFrequency;
  littleInterest: DepressionFrequency;
};

export type DepressionScreeningInputLog = {
  __typename?: "DepressionScreeningInputLog";
  feelingDown: DepressionFrequency;
  littleInterest: DepressionFrequency;
};

export type DepressionScreeningResponse = {
  __typename?: "DepressionScreeningResponse";
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars["Int"]>;
};

export type DepressionScreeningResult = {
  __typename?: "DepressionScreeningResult";
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<DepressionScreeningInputLog>;
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars["Int"]>;
};

export type DepressionScreeningResultConnection = {
  __typename?: "DepressionScreeningResultConnection";
  edges: Array<DepressionScreeningResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DepressionScreeningResultEdge = {
  __typename?: "DepressionScreeningResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DepressionScreeningResult;
};

export enum DestinationType {
  Eclaim = "ECLAIM",
  Io = "IO",
}

export type DeviceInformation = {
  __typename?: "DeviceInformation";
  id: Scalars["ID"];
};

export type DeviceInformationInput = {
  AppVersion?: InputMaybe<Scalars["String"]>;
  OsVersion?: InputMaybe<Scalars["String"]>;
  buildNumber?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  fireBaseToken?: InputMaybe<Scalars["String"]>;
  heightPixels?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<Scalars["String"]>;
  localDisplay?: InputMaybe<Scalars["String"]>;
  manufacturer?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  uniqueId: Scalars["String"];
  widthPixels?: InputMaybe<Scalars["Int"]>;
};

export type DiabetesEducator = Node & {
  __typename?: "DiabetesEducator";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type DiabetesEducatorInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export enum DiabetesRisk {
  High = "HIGH",
  Low = "LOW",
}

export type DiabetesRiskInput = {
  age: Scalars["Int"];
  gender: Gender;
  hasBloodPressure: Scalars["Boolean"];
  hasFamilyHistory: Scalars["Boolean"];
  hasGestationalDiabetes: Scalars["Boolean"];
  height: Scalars["Float"];
  physicalActive: Scalars["Boolean"];
  weight: Scalars["Float"];
};

export type DiabetesRiskInputLog = {
  __typename?: "DiabetesRiskInputLog";
  age: Scalars["Int"];
  gender: Gender;
  hasBloodPressure: Scalars["Boolean"];
  hasFamilyHistory: Scalars["Boolean"];
  hasGestationalDiabetes: Scalars["Boolean"];
  height: Scalars["Float"];
  physicalActive: Scalars["Boolean"];
  weight: Scalars["Float"];
};

export type DiabetesRiskResponse = {
  __typename?: "DiabetesRiskResponse";
  risk?: Maybe<RiskLevel>;
  score?: Maybe<Scalars["Int"]>;
};

export type DiabetesRiskResult = {
  __typename?: "DiabetesRiskResult";
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<DiabetesRiskInputLog>;
  risk?: Maybe<RiskLevel>;
  score?: Maybe<Scalars["Int"]>;
};

export type DiabetesRiskResultConnection = {
  __typename?: "DiabetesRiskResultConnection";
  edges: Array<DiabetesRiskResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DiabetesRiskResultEdge = {
  __typename?: "DiabetesRiskResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DiabetesRiskResult;
};

export type Diagnose = Node & {
  __typename?: "Diagnose";
  code: Scalars["String"];
  created: Scalars["DateTime"];
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  prescription: Prescription;
  type: Scalars["String"];
};

export type DiagnoseFilterInput = {
  code?: InputMaybe<Scalars["String"]>;
};

export type DiagnosisInput = {
  code: Scalars["String"];
  type: DiagnosisType;
};

export type DiagnosisPredictionError = {
  __typename?: "DiagnosisPredictionError";
  code: DiagnosisPrescriptionErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type DiagnosisPredictionInput = {
  doctorNote?: InputMaybe<Scalars["String"]>;
};

export type DiagnosisPredictionMutation = {
  __typename?: "DiagnosisPredictionMutation";
  diagnosis?: Maybe<Array<Maybe<Scalars["String"]>>>;
  diagnosisPredictionsErrors: Array<DiagnosisPredictionError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export enum DiagnosisPrescriptionErrorCode {
  ControlledDrug = "CONTROLLED_DRUG",
  GraphqlError = "GRAPHQL_ERROR",
  Ingested = "INGESTED",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export enum DiagnosisType {
  Principal = "Principal",
  Secondary = "Secondary",
}

export type DiagnosisValidProcedure = {
  __typename?: "DiagnosisValidProcedure";
  diagnosisCode: Scalars["String"];
  id: Scalars["ID"];
  procedureCode: Scalars["String"];
};

export type DiagnosisValidProcedureConnection = {
  __typename?: "DiagnosisValidProcedureConnection";
  edges: Array<DiagnosisValidProcedureEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DiagnosisValidProcedureEdge = {
  __typename?: "DiagnosisValidProcedureEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: DiagnosisValidProcedure;
};

export type DiagnosisValidProcedureFilter = {
  diagnosisCode?: InputMaybe<Scalars["String"]>;
  procedureCode?: InputMaybe<Scalars["String"]>;
};

export type DiagnosisValidProcedureInput = {
  diagnosisCode: Scalars["String"];
  procedureCode: Scalars["String"];
};

export type DiagnosisValidProcedureSorting = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<DiagnosisValidProcedureSortingField>;
};

export enum DiagnosisValidProcedureSortingField {
  DiagnosisCode = "DIAGNOSIS_CODE",
  ProcedureCode = "PROCEDURE_CODE",
}

export enum DietQuality {
  Adequate = "ADEQUATE",
  Fair = "FAIR",
  Poor = "POOR",
  VeryPoor = "VERY_POOR",
}

export enum DietType {
  Balanced = "BALANCED",
  HighProtein = "HIGH_PROTEIN",
  Keto = "KETO",
  LowCarb = "LOW_CARB",
  LowFat = "LOW_FAT",
  MealReplacement = "MEAL_REPLACEMENT",
  VeryLowCalorie = "VERY_LOW_CALORIE",
}

export type DigitalTwinHealthProfile = {
  __typename?: "DigitalTwinHealthProfile";
  activityLevel?: Maybe<Scalars["String"]>;
  anxietyScreeningResults?: Maybe<AnxietyScreeningResultConnection>;
  beeAndTdeeResults?: Maybe<BeeAndTdeeResultConnection>;
  bodyFatPercentageResults?: Maybe<BodyFatPercentageResultConnection>;
  dateOfBirth?: Maybe<Scalars["Instant"]>;
  dependents?: Maybe<Array<Maybe<DigitalTwinHealthProfile>>>;
  depressionScreeningResults?: Maybe<DepressionScreeningResultConnection>;
  diabetesRiskResults?: Maybe<DiabetesRiskResultConnection>;
  dietQuality?: Maybe<Scalars["String"]>;
  educationLevel?: Maybe<Scalars["String"]>;
  feelingNervous?: Maybe<Scalars["String"]>;
  framinghamRiskResults?: Maybe<FraminghamRiskResultConnection>;
  gender?: Maybe<Scalars["String"]>;
  goalWeightResults?: Maybe<GoalWeightResultConnection>;
  hasBloodPressure?: Maybe<Scalars["Boolean"]>;
  hasFamilyHistory?: Maybe<Scalars["Boolean"]>;
  hasGestationalDiabetes?: Maybe<Scalars["Boolean"]>;
  hdlCholesterol?: Maybe<Scalars["Float"]>;
  healthHistories?: Maybe<Array<Maybe<HealthHistory>>>;
  healthId?: Maybe<Scalars["String"]>;
  height?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["ID"]>;
  idealBodyWeightResults?: Maybe<IdealBodyWeightResultConnection>;
  lifeExpectancyResults?: Maybe<LifeExpectancyResultConnection>;
  nationalId?: Maybe<Scalars["String"]>;
  notBeingAbleToStopOrControlWorrying?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  sleepHours?: Maybe<Scalars["Int"]>;
  sleepResults?: Maybe<SleepResultConnection>;
  smokingHabit?: Maybe<Scalars["String"]>;
  stressLevel?: Maybe<Scalars["String"]>;
  systolicBp?: Maybe<Scalars["Float"]>;
  totalCholesterol?: Maybe<Scalars["Float"]>;
  treatedForBp?: Maybe<Scalars["Int"]>;
  weight?: Maybe<Scalars["Float"]>;
};

export type DigitalTwinHealthProfileAnxietyScreeningResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileBeeAndTdeeResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileBodyFatPercentageResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileDepressionScreeningResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileDiabetesRiskResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileFraminghamRiskResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileGoalWeightResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileIdealBodyWeightResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileLifeExpectancyResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DigitalTwinHealthProfileSleepResultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type DistanceFilterInput = {
  coordinates: LocationInput;
  distance?: InputMaybe<Scalars["Decimal"]>;
};

export type Division = Node & {
  __typename?: "Division";
  created: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  modifiedBy?: Maybe<User>;
  name: Scalars["String"];
  vendorSet: VendorCountableConnection;
};

export type DivisionVendorSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type DivisionConnection = {
  __typename?: "DivisionConnection";
  edges: Array<Maybe<DivisionEdge>>;
  pageInfo: PageInfo;
};

export type DivisionEdge = {
  __typename?: "DivisionEdge";
  cursor: Scalars["String"];
  node?: Maybe<Division>;
};

export type Doctor = Node & {
  __typename?: "Doctor";
  appointmentSlotTimePeriod: Scalars["Int"];
  appointmentTypes?: Maybe<Array<Maybe<AppointmentTypeEnum>>>;
  appointments?: Maybe<AppointmentCountableConnection>;
  /**
   * returns the minimal info of accepted but not confirmed appointments,
   * check the property suggestedTimeSlotsOverlapped for the value of the booked timeslots
   */
  appointmentsAcceptedOnlyBooked?: Maybe<OverlappedAppointmentCountableConnection>;
  /**
   * returns the minimal info of confirmed appointments,
   * check the properties (confirmedStartTime, confirmedEndTime) for the value of the booked timeslots
   */
  appointmentsConfirmedBooked?: Maybe<OverlappedAppointmentCountableConnection>;
  atHomeVisitPrice?: Maybe<Scalars["Float"]>;
  availabilities: DoctorAvailabilityCountableConnection;
  availabilityStatus: DoctorAvailabilityStatusEnum;
  /** needed to send either the values of appointmentId or the values each of (start, end) */
  availableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  averageRating: Scalars["Float"];
  bio?: Maybe<Scalars["String"]>;
  canAcceptCall: Scalars["Boolean"];
  created: Scalars["DateTime"];
  dashboard?: Maybe<DoctorDashboard>;
  experiences?: Maybe<Array<Maybe<DoctorExperience>>>;
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  id: Scalars["ID"];
  isAddressPublic: Scalars["Boolean"];
  isDateOfBirthPublic: Scalars["Boolean"];
  isLanguagesPublic: Scalars["Boolean"];
  isLicenseNumberPublic: Scalars["Boolean"];
  isMobileNumberPublic: Scalars["Boolean"];
  isNationalIdPublic: Scalars["Boolean"];
  isSecondMobileNumberPublic: Scalars["Boolean"];
  isSocialLinksPublic: Scalars["Boolean"];
  isYearsOfExperiencePublic: Scalars["Boolean"];
  languages?: Maybe<Array<Maybe<Language>>>;
  lastAvailability?: Maybe<DoctorAvailability>;
  modified: Scalars["DateTime"];
  onlineVisitPrice?: Maybe<Scalars["Float"]>;
  onsiteVisitPrice?: Maybe<Scalars["Float"]>;
  qualifications?: Maybe<Array<Maybe<Qualification>>>;
  ratingsSum: Scalars["Float"];
  secondMobileNumber?: Maybe<Scalars["String"]>;
  seniority?: Maybe<DoctorSeniorityEnum>;
  socialLinks?: Maybe<Scalars["JSONString"]>;
  specializations?: Maybe<Array<Maybe<DoctorSpecialization>>>;
  totalRatings: Scalars["Int"];
  user?: Maybe<User>;
  vendor: Vendor;
  workingHours?: Maybe<Array<Maybe<WorkingHour>>>;
  yearsOfExperience?: Maybe<Scalars["Int"]>;
};

export type DoctorAppointmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AppointmentFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<AppointmentSortingInput>;
};

export type DoctorAppointmentsAcceptedOnlyBookedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  end?: InputMaybe<Scalars["Instant"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["Instant"]>;
};

export type DoctorAppointmentsConfirmedBookedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  end?: InputMaybe<Scalars["Instant"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  start?: InputMaybe<Scalars["Instant"]>;
};

export type DoctorAvailabilitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type DoctorAvailableTimeSlotsArgs = {
  end?: InputMaybe<Scalars["Instant"]>;
  start?: InputMaybe<Scalars["Instant"]>;
};

export type DoctorHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type DoctorAcceptAppointmentInput = {
  branchId?: InputMaybe<Scalars["ID"]>;
  departmentId?: InputMaybe<Scalars["ID"]>;
  paymentInfo?: InputMaybe<AppointmentPaymentRequestForAcceptInput>;
  suggestedTimeSlots: Array<InputMaybe<AppointmentTimeSlotInput>>;
};

export type DoctorAppointment = H_Node & {
  __typename?: "DoctorAppointment";
  appointmentCategory?: Maybe<AppointmentCategory>;
  branch?: Maybe<Branch>;
  cancellationReason?: Maybe<Scalars["String"]>;
  confirmedEndTime?: Maybe<Scalars["Instant"]>;
  confirmedStartTime?: Maybe<Scalars["Instant"]>;
  consumer?: Maybe<Patient>;
  created?: Maybe<Scalars["Instant"]>;
  doctor?: Maybe<Doctor>;
  doctorNote?: Maybe<Scalars["String"]>;
  /** The ID of the object. */
  id: Scalars["ID"];
  justification?: Maybe<Scalars["String"]>;
  messageToPatient?: Maybe<Scalars["String"]>;
  modified?: Maybe<Scalars["Instant"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  requestedTimeSlots?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status?: Maybe<AppointmentStatus>;
  suggestedTimeSlots?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<AppointmentType>;
  vendor?: Maybe<Vendor>;
  visitId?: Maybe<Scalars["ID"]>;
};

export type DoctorAppointmentRejectInput = {
  rejectionReason: Scalars["String"];
};

export type DoctorAppointmentRescheduleInput = {
  branchId?: InputMaybe<Scalars["ID"]>;
  departmentId?: InputMaybe<Scalars["ID"]>;
  paymentInfo?: InputMaybe<AppointmentPaymentRequestForRescheduleInput>;
  suggestedTimeSlots: Array<InputMaybe<AppointmentTimeSlotInput>>;
};

export type DoctorAvailabilitiesSum = {
  __typename?: "DoctorAvailabilitiesSum";
  doctor?: Maybe<Scalars["ID"]>;
  sumOfAvailabilities?: Maybe<Scalars["Float"]>;
};

export type DoctorAvailability = Node & {
  __typename?: "DoctorAvailability";
  doctor: Doctor;
  endTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  period?: Maybe<Scalars["Float"]>;
  startTime: Scalars["DateTime"];
  status: DoctorAvailabilityStatus;
  vendor: Vendor;
};

export type DoctorAvailabilityCountableConnection = {
  __typename?: "DoctorAvailabilityCountableConnection";
  edges: Array<DoctorAvailabilityCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DoctorAvailabilityCountableEdge = {
  __typename?: "DoctorAvailabilityCountableEdge";
  cursor: Scalars["String"];
  node: DoctorAvailability;
};

export enum DoctorAvailabilityStatus {
  AvailableLevel_1 = "AVAILABLE_LEVEL_1",
  AvailableLevel_2 = "AVAILABLE_LEVEL_2",
  Unavailable = "UNAVAILABLE",
}

export type DoctorAvailabilityStatusChange = {
  __typename?: "DoctorAvailabilityStatusChange";
  doctor?: Maybe<Doctor>;
};

export enum DoctorAvailabilityStatusEnum {
  AvailableLevel_1 = "AVAILABLE_LEVEL_1",
  AvailableLevel_2 = "AVAILABLE_LEVEL_2",
  Unavailable = "UNAVAILABLE",
}

export type DoctorAvailabilityStatusUpdate = {
  __typename?: "DoctorAvailabilityStatusUpdate";
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  result?: Maybe<Scalars["String"]>;
};

export type DoctorCallsRatingsConnection = {
  __typename?: "DoctorCallsRatingsConnection";
  edges: Array<DoctorCallsRatingsEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DoctorCallsRatingsEdge = {
  __typename?: "DoctorCallsRatingsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: DoctorCallsRatingsResponse;
};

export type DoctorCallsRatingsFilter = {
  department?: InputMaybe<Scalars["String"]>;
  doctorName?: InputMaybe<Scalars["String"]>;
  minRatingAverage?: InputMaybe<Scalars["Int"]>;
  specialization?: InputMaybe<Scalars["String"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type DoctorCallsRatingsResponse = {
  __typename?: "DoctorCallsRatingsResponse";
  doctor?: Maybe<Doctor>;
  ratings?: Maybe<Array<Maybe<RatingAndNumberOfCallsPair>>>;
};

export type DoctorCanAcceptCallUpdate = {
  __typename?: "DoctorCanAcceptCallUpdate";
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  result?: Maybe<Scalars["String"]>;
};

export type DoctorCountableConnection = {
  __typename?: "DoctorCountableConnection";
  edges: Array<DoctorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DoctorCountableEdge = {
  __typename?: "DoctorCountableEdge";
  cursor: Scalars["String"];
  node: Doctor;
};

export type DoctorDashboard = {
  __typename?: "DoctorDashboard";
  numberOfNewRequests?: Maybe<Scalars["Int"]>;
  numberOfScheduledCalls?: Maybe<Scalars["Int"]>;
  numberOfVisitsPerStatus?: Maybe<Scalars["DictType"]>;
  totalEVisits?: Maybe<Scalars["Int"]>;
};

export type DoctorDashboardNumberOfVisitsPerStatusArgs = {
  date?: InputMaybe<H_ReportingPeriod>;
};

export type DoctorError = {
  __typename?: "DoctorError";
  code: DoctorErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum DoctorErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type DoctorExperience = Node & {
  __typename?: "DoctorExperience";
  description?: Maybe<Scalars["String"]>;
  doctor: Doctor;
  endDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  jobTitle: Scalars["String"];
  provider: Scalars["String"];
  startDate: Scalars["Date"];
};

export type DoctorExperienceCreate = {
  __typename?: "DoctorExperienceCreate";
  doctorErrors: Array<DoctorError>;
  doctorExperience?: Maybe<DoctorExperience>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorExperienceCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  jobTitle: Scalars["String"];
  provider: Scalars["String"];
  startDate: Scalars["Date"];
};

export type DoctorExperienceDelete = {
  __typename?: "DoctorExperienceDelete";
  doctorErrors: Array<DoctorError>;
  doctorExperience?: Maybe<DoctorExperience>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorExperienceUpdate = {
  __typename?: "DoctorExperienceUpdate";
  doctorErrors: Array<DoctorError>;
  doctorExperience?: Maybe<DoctorExperience>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorExperienceUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  jobTitle?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
};

export type DoctorFilterInput = {
  appointmentAvailableTime?: InputMaybe<DateTimeFilterInput>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeEnum>>>;
  availabilityStatus?: InputMaybe<Array<InputMaybe<DoctorAvailabilityStatusEnum>>>;
  branch?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  canAcceptCall?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["ID"]>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  experience?: InputMaybe<Scalars["String"]>;
  fromAtHomeVisitPrice?: InputMaybe<Scalars["Float"]>;
  fromOnlineVisitPrice?: InputMaybe<Scalars["Float"]>;
  fromOnsiteVisitPrice?: InputMaybe<Scalars["Float"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  healthConditions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  languages?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  licenseNumber?: InputMaybe<Scalars["String"]>;
  licenseNumber_Icontains?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<DistanceFilterInput>;
  meetingPlatformId?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  networkId?: InputMaybe<Scalars["Float"]>;
  networkName?: InputMaybe<Scalars["String"]>;
  programId?: InputMaybe<Scalars["Float"]>;
  programName?: InputMaybe<Scalars["String"]>;
  rating?: InputMaybe<DecimalRangeInput>;
  search?: InputMaybe<Scalars["String"]>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  toAtHomeVisitPrice?: InputMaybe<Scalars["Float"]>;
  toOnlineVisitPrice?: InputMaybe<Scalars["Float"]>;
  toOnsiteVisitPrice?: InputMaybe<Scalars["Float"]>;
  vendor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  yearsOfExperience?: InputMaybe<IntRangeInput>;
};

export type DoctorHealthProgramPointsFilterInput = {
  doctorId: Scalars["String"];
  fromDate: Scalars["String"];
  healthProgramId: Scalars["Int"];
  toDate: Scalars["String"];
};

export type DoctorHealthProgramTotalPointsTrackingConnection = {
  __typename?: "DoctorHealthProgramTotalPointsTrackingConnection";
  edges: Array<DoctorHealthProgramTotalPointsTrackingCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DoctorHealthProgramTotalPointsTrackingCountableEdge = {
  __typename?: "DoctorHealthProgramTotalPointsTrackingCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: DoctorHealthProgramTotalPointsTrackingResponse;
};

export type DoctorHealthProgramTotalPointsTrackingResponse = {
  __typename?: "DoctorHealthProgramTotalPointsTrackingResponse";
  availabilityRatingFactor?: Maybe<Scalars["Float"]>;
  callQualityRatingFactor?: Maybe<Scalars["Float"]>;
  doctor?: Maybe<Doctor>;
  mainSpecializationsRateFactor?: Maybe<Scalars["Float"]>;
  pointsDuringNormalDemand?: Maybe<Scalars["Float"]>;
  pointsDuringUrgentDemand?: Maybe<Scalars["Float"]>;
  totalPoints?: Maybe<Scalars["Int"]>;
};

export type DoctorInput = {
  appointmentSlotTimePeriod?: InputMaybe<Scalars["Int"]>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeEnum>>>;
  atHomeVisitPrice?: InputMaybe<Scalars["Float"]>;
  bio?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isAddressPublic?: InputMaybe<Scalars["Boolean"]>;
  isDateOfBirthPublic?: InputMaybe<Scalars["Boolean"]>;
  isLanguagesPublic?: InputMaybe<Scalars["Boolean"]>;
  isLicenseNumberPublic?: InputMaybe<Scalars["Boolean"]>;
  isMobileNumberPublic?: InputMaybe<Scalars["Boolean"]>;
  isNationalIdPublic?: InputMaybe<Scalars["Boolean"]>;
  isSecondMobileNumberPublic?: InputMaybe<Scalars["Boolean"]>;
  isSocialLinksPublic?: InputMaybe<Scalars["Boolean"]>;
  isYearsOfExperiencePublic?: InputMaybe<Scalars["Boolean"]>;
  languages?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlineVisitPrice?: InputMaybe<Scalars["Float"]>;
  onsiteVisitPrice?: InputMaybe<Scalars["Float"]>;
  photo?: InputMaybe<Scalars["String"]>;
  qualifications?: InputMaybe<Array<InputMaybe<QualificationInput>>>;
  secondMobileNumber?: InputMaybe<Scalars["String"]>;
  seniority?: InputMaybe<DoctorSeniorityEnum>;
  socialLinks?: InputMaybe<Array<Scalars["String"]>>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  yearsOfExperience?: InputMaybe<Scalars["Int"]>;
};

export type DoctorNurseAssignment = H_Node & {
  __typename?: "DoctorNurseAssignment";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  doctor?: Maybe<Doctor>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  nurseUser?: Maybe<User>;
  vendor?: Maybe<Vendor>;
};

export type DoctorNurseAssignmentCountableConnection = {
  __typename?: "DoctorNurseAssignmentCountableConnection";
  edges: Array<DoctorNurseAssignmentCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DoctorNurseAssignmentCountableEdge = {
  __typename?: "DoctorNurseAssignmentCountableEdge";
  cursor: Scalars["String"];
  node: DoctorNurseAssignment;
};

export type DoctorNurseAssignmentFilterInput = {
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  doctors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  nurseUsers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type DoctorNurseAssignmentInput = {
  departmentId: Scalars["ID"];
  doctorId: Scalars["ID"];
};

export type DoctorOrder = {
  direction: OrderDirection;
  field?: InputMaybe<DoctorOrderField>;
};

export enum DoctorOrderField {
  Created = "CREATED",
  Distance = "DISTANCE",
  Rating = "RATING",
}

export type DoctorProfileInput = {
  appointmentSlotTimePeriod?: InputMaybe<Scalars["Int"]>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeEnum>>>;
  bio?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  isAddressPublic?: InputMaybe<Scalars["Boolean"]>;
  isDateOfBirthPublic?: InputMaybe<Scalars["Boolean"]>;
  isLanguagesPublic?: InputMaybe<Scalars["Boolean"]>;
  isMobileNumberPublic?: InputMaybe<Scalars["Boolean"]>;
  isSocialLinksPublic?: InputMaybe<Scalars["Boolean"]>;
  isYearsOfExperiencePublic?: InputMaybe<Scalars["Boolean"]>;
  languages?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  photo?: InputMaybe<Scalars["String"]>;
  qualifications?: InputMaybe<Array<InputMaybe<QualificationInput>>>;
  seniority?: InputMaybe<DoctorSeniorityEnum>;
  socialLinks?: InputMaybe<Array<Scalars["String"]>>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  yearsOfExperience?: InputMaybe<Scalars["Int"]>;
};

export type DoctorProfileUpdate = {
  __typename?: "DoctorProfileUpdate";
  doctor?: Maybe<Doctor>;
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorRating = {
  __typename?: "DoctorRating";
  doctor?: Maybe<Doctor>;
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorRatingInput = {
  action: RatingActionEnum;
  doctor: Scalars["ID"];
  oldRating?: InputMaybe<Scalars["Decimal"]>;
  rating: Scalars["Decimal"];
};

export enum DoctorSeniorityEnum {
  Cp = "CP",
  Gp = "GP",
  Sp = "SP",
}

export type DoctorSpecialization = Node & {
  __typename?: "DoctorSpecialization";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code: Scalars["String"];
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** doctor reject assigned call */
export type DoctorVisitReject = {
  __typename?: "DoctorVisitReject";
  doctorVisitRejectionAudit?: Maybe<DoctorVisitRejectionAudit>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type DoctorVisitRejectInput = {
  reason: Scalars["String"];
  visit: Scalars["ID"];
};

export type DoctorVisitRejectionAudit = H_Node & {
  __typename?: "DoctorVisitRejectionAudit";
  created?: Maybe<Scalars["Instant"]>;
  doctorId?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  reason?: Maybe<Scalars["String"]>;
  visit?: Maybe<Visit>;
};

export type DoctorVisitRejectionAuditCountableConnection = {
  __typename?: "DoctorVisitRejectionAuditCountableConnection";
  edges: Array<DoctorVisitRejectionAuditCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type DoctorVisitRejectionAuditCountableEdge = {
  __typename?: "DoctorVisitRejectionAuditCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DoctorVisitRejectionAudit;
};

export type DoctorVisitRejectionAuditFilterInput = {
  visits?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type DocumentStore = {
  __typename?: "DocumentStore";
  code: Scalars["String"];
  createdAt: Scalars["Instant"];
  description?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Instant"];
};

export type DocumentStoreConnection = {
  __typename?: "DocumentStoreConnection";
  edges: Array<DocumentStoreEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentStoreEdge = {
  __typename?: "DocumentStoreEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: DocumentStore;
};

/** Create a new EarliestVisit */
export type EarliestVisitCreate = {
  __typename?: "EarliestVisitCreate";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type EarliestVisitCreateInput = {
  healthProgram: Scalars["ID"];
  patientNote?: InputMaybe<Scalars["String"]>;
  paymentReceiptIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type EarlyRefillReason = Node & {
  __typename?: "EarlyRefillReason";
  created: Scalars["DateTime"];
  earlyRefillRequests: EarlyRefillRequestCountableConnection;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  reason: Scalars["String"];
  type: EarlyRefillReasonType;
};

export type EarlyRefillReasonEarlyRefillRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type EarlyRefillReasonCreate = {
  __typename?: "EarlyRefillReasonCreate";
  earlyRefillReason?: Maybe<EarlyRefillReason>;
  earlyRefillReasonErrors: Array<EarlyRefillReasonError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillReasonDelete = {
  __typename?: "EarlyRefillReasonDelete";
  earlyRefillReason?: Maybe<EarlyRefillReason>;
  earlyRefillReasonErrors: Array<EarlyRefillReasonError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillReasonError = {
  __typename?: "EarlyRefillReasonError";
  code: EarlyRefillReasonErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum EarlyRefillReasonErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type EarlyRefillReasonInput = {
  reason: Scalars["String"];
};

export enum EarlyRefillReasonType {
  Custom = "CUSTOM",
  System = "SYSTEM",
}

export type EarlyRefillReasonUpdate = {
  __typename?: "EarlyRefillReasonUpdate";
  earlyRefillReason?: Maybe<EarlyRefillReason>;
  earlyRefillReasonErrors: Array<EarlyRefillReasonError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillRequest = Node & {
  __typename?: "EarlyRefillRequest";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  prescription: Prescription;
  reason: EarlyRefillReason;
  status: EarlyRefillRequestStatus;
};

export type EarlyRefillRequestCountableConnection = {
  __typename?: "EarlyRefillRequestCountableConnection";
  edges: Array<EarlyRefillRequestCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type EarlyRefillRequestCountableEdge = {
  __typename?: "EarlyRefillRequestCountableEdge";
  cursor: Scalars["String"];
  node: EarlyRefillRequest;
};

export type EarlyRefillRequestCreate = {
  __typename?: "EarlyRefillRequestCreate";
  earlyRefillRequest?: Maybe<EarlyRefillRequest>;
  earlyRefillRequestErrors: Array<RefillError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type EarlyRefillRequestFilterInput = {
  status?: InputMaybe<Scalars["String"]>;
};

export type EarlyRefillRequestInput = {
  customEarlyRefillReason?: InputMaybe<Scalars["String"]>;
  earlyRefillReason?: InputMaybe<Scalars["ID"]>;
  prescription: Scalars["ID"];
};

export enum EarlyRefillRequestStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum EducationLevel {
  HighSchoolGraduate = "HIGH_SCHOOL_GRADUATE",
  LessThanHighSchool = "LESS_THAN_HIGH_SCHOOL",
  UniversityGraduate = "UNIVERSITY_GRADUATE",
}

export enum EntityType {
  Boolean = "BOOLEAN",
  Date = "DATE",
  Id = "ID",
  List = "LIST",
  Number = "NUMBER",
  String = "STRING",
}

export type Error = {
  __typename?: "Error";
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = "DEADLINE_EXCEEDED",
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = "ENHANCE_YOUR_CALM",
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = "FIELD_NOT_FOUND",
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = "INVALID_ARGUMENT",
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = "INVALID_CURSOR",
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = "MISSING_RESOURCE",
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = "SERVICE_ERROR",
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = "TCP_FAILURE",
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = "THROTTLED_CONCURRENCY",
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = "THROTTLED_CPU",
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = "UNIMPLEMENTED",
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = "UNKNOWN",
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = "BAD_REQUEST",
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = "FAILED_PRECONDITION",
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = "INTERNAL",
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = "NOT_FOUND",
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = "PERMISSION_DENIED",
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = "UNAUTHENTICATED",
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = "UNAVAILABLE",
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = "UNKNOWN",
}

export type EvaluateProgramRiskFactorTemplateInput = {
  memberId?: InputMaybe<Scalars["ID"]>;
  programId: Scalars["ID"];
  result: Scalars["String"];
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type EvaluatedLabTemplate = {
  __typename?: "EvaluatedLabTemplate";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  fields?: Maybe<Array<Maybe<EvaluatedTemplateLabField>>>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
};

export type EvaluatedTemplateLabField = {
  __typename?: "EvaluatedTemplateLabField";
  allowedValues?: Maybe<Array<Maybe<Scalars["String"]>>>;
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  defaultValue?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  maxNormalRangeValue?: Maybe<Scalars["Float"]>;
  minNormalRangeValue?: Maybe<Scalars["Float"]>;
  referenceRange?: Maybe<Scalars["String"]>;
  standard?: Maybe<FieldStandard>;
  type?: Maybe<TemplateFieldType>;
  unit?: Maybe<FieldUnit>;
};

export type ExclusionOption = H_Node & {
  __typename?: "ExclusionOption";
  created?: Maybe<Scalars["Instant"]>;
  description?: Maybe<Scalars["String"]>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ExclusionOptionHealthProgramsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** CRUD a new Exclusion Option */
export type ExclusionOptionCrud = {
  __typename?: "ExclusionOptionCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  exclusionOption?: Maybe<ExclusionOption>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type ExclusionOptionInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Exercise = {
  __typename?: "Exercise";
  exerciseDuration: Scalars["Int"];
  exerciseDurationUnit: ExerciseDurationUnit;
  exerciseFrequencyDayWeek: Scalars["Int"];
  exerciseType: ExerciseType;
  id: Scalars["ID"];
};

export enum ExerciseDurationUnit {
  Hours = "HOURS",
  Minutes = "MINUTES",
}

export enum ExerciseType {
  BodyweightExercises = "BODYWEIGHT_EXERCISES",
  Cycling = "CYCLING",
  Dancing = "DANCING",
  Pilates = "PILATES",
  ResistanceBandExercises = "RESISTANCE_BAND_EXERCISES",
  StretchingRoutines = "STRETCHING_ROUTINES",
  Swimming = "SWIMMING",
  Walking = "WALKING",
  WaterAerobics = "WATER_AEROBICS",
  Weightlifting = "WEIGHTLIFTING",
  Yoga = "YOGA",
}

export type ExtraBenefit = H_Node & {
  __typename?: "ExtraBenefit";
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
};

/** CRUD a new Extra Benefit */
export type ExtraBenefitCrud = {
  __typename?: "ExtraBenefitCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  extraBenefit?: Maybe<ExtraBenefit>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type ExtraBenefitComplex = H_Node & {
  __typename?: "ExtraBenefitComplex";
  created?: Maybe<Scalars["Instant"]>;
  extraBenefit?: Maybe<ExtraBenefit>;
  healthChannels?: Maybe<Array<Maybe<HealthChannel>>>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
};

export type ExtraBenefitInput = {
  name?: InputMaybe<Scalars["String"]>;
};

export type FeatureFlag = {
  __typename?: "FeatureFlag";
  addMemberCardFeatureEnabled: Scalars["Boolean"];
  addNationalIdFeatureEnabled: Scalars["Boolean"];
  communityFeatureEnabled: Scalars["Boolean"];
  customerRegistrationFeatureEnabled: Scalars["Boolean"];
  deleteCustomerAccountFeatureEnabled: Scalars["Boolean"];
  dependentCreationFeatureEnabled: Scalars["Boolean"];
  enayaIntegrationFeatureEnabled: Scalars["Boolean"];
  familySyncFeatureEnabled: Scalars["Boolean"];
  healthPackageFeatureEnabled: Scalars["Boolean"];
  id: Scalars["ID"];
  inPersonCheckinFeatureEnabled: Scalars["Boolean"];
  interventionFeatureEnabled: Scalars["Boolean"];
  iotAddDeviceFeatureEnabled: Scalars["Boolean"];
  knowledgeHubFeatureEnabled: Scalars["Boolean"];
  marketplaceFeatureEnabled: Scalars["Boolean"];
  ssoFeatureEnabled: Scalars["Boolean"];
  transcriptionFeatureEnabled: Scalars["Boolean"];
  uaePassFeatureEnabled: Scalars["Boolean"];
};

export type Field = {
  __typename?: "Field";
  allowedValues?: Maybe<Array<Maybe<Scalars["String"]>>>;
  category?: Maybe<FieldCategory>;
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  fieldType?: Maybe<FieldType>;
  id?: Maybe<Scalars["ID"]>;
  operators?: Maybe<Array<Maybe<Operator>>>;
  standard?: Maybe<FieldStandard>;
  unit?: Maybe<FieldUnit>;
};

export type FieldCategory = {
  __typename?: "FieldCategory";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

export type FieldCategoryConnection = {
  __typename?: "FieldCategoryConnection";
  edges?: Maybe<Array<Maybe<FieldCategoryEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type FieldCategoryEdge = {
  __typename?: "FieldCategoryEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<FieldCategory>;
};

export type FieldCategoryInput = {
  id: Scalars["ID"];
};

export type FieldInput = {
  id: Scalars["ID"];
};

export enum FieldStandard {
  Cpt = "CPT",
  Loinc = "LOINC",
}

export type FieldType = {
  __typename?: "FieldType";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<EntityType>;
};

export type FieldTypeInput = {
  type: EntityType;
};

export type FieldUnit = {
  __typename?: "FieldUnit";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
};

export type FieldUnitInput = {
  id: Scalars["ID"];
};

export enum FileType {
  Csv = "CSV",
  Pdf = "PDF",
  Txt = "TXT",
}

export type FitnessCoach = Node & {
  __typename?: "FitnessCoach";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type FitnessCoachInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export enum FlowrseIntegrationSortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type FraminghamRiskInput = {
  age: Scalars["Int"];
  gender: Gender;
  hdlCholesterol: Scalars["Float"];
  smoker: Scalars["Boolean"];
  systolicBp: Scalars["Float"];
  totalCholesterol: Scalars["Float"];
  treatedForBp: Scalars["Int"];
};

export type FraminghamRiskInputLog = {
  __typename?: "FraminghamRiskInputLog";
  age: Scalars["Int"];
  gender: Gender;
  hdlCholesterol: Scalars["Float"];
  smoker: Scalars["Boolean"];
  systolicBp: Scalars["Float"];
  totalCholesterol: Scalars["Float"];
  treatedForBp: Scalars["Int"];
};

export type FraminghamRiskResponse = {
  __typename?: "FraminghamRiskResponse";
  riskPercentage?: Maybe<Scalars["Float"]>;
};

export type FraminghamRiskResult = {
  __typename?: "FraminghamRiskResult";
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<FraminghamRiskInputLog>;
  riskPercentage?: Maybe<Scalars["Double"]>;
};

export type FraminghamRiskResultConnection = {
  __typename?: "FraminghamRiskResultConnection";
  edges: Array<FraminghamRiskResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type FraminghamRiskResultEdge = {
  __typename?: "FraminghamRiskResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: FraminghamRiskResult;
};

export type FreeDeliveryEligibilityCheckInput = {
  patientIdentifier: Scalars["String"];
  shippingAddress: AddressInput;
};

export type FromGlobalId = {
  __typename?: "FromGlobalId";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  pk?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export type GeneralResponse = {
  __typename?: "GeneralResponse";
  message?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type GenericDrug = {
  __typename?: "GenericDrug";
  code: Scalars["String"];
  display: Scalars["String"];
  routeOfAdmin?: Maybe<Scalars["String"]>;
};

export type GenericDrugDeliveryEligibility = {
  __typename?: "GenericDrugDeliveryEligibility";
  deliveryTypes?: Maybe<Array<Maybe<DeliveryType>>>;
  genericDrug?: Maybe<Scalars["String"]>;
};

export type GenericDrugProperties = {
  __typename?: "GenericDrugProperties";
  isHazardous?: Maybe<Scalars["Boolean"]>;
  isHighAlert?: Maybe<Scalars["Boolean"]>;
  isLASA?: Maybe<Scalars["Boolean"]>;
};

export type GoalWeightInput = {
  bee: Scalars["Float"];
  days: Scalars["Int"];
  goalWeight: Scalars["Float"];
  newActivityLevel: ActivityLevel;
  weight: Scalars["Float"];
};

export type GoalWeightInputLog = {
  __typename?: "GoalWeightInputLog";
  bee: Scalars["Float"];
  days: Scalars["Int"];
  goalWeight: Scalars["Float"];
  newActivityLevel: ActivityLevel;
  weight: Scalars["Float"];
};

export type GoalWeightResponse = {
  __typename?: "GoalWeightResponse";
  caloriesPerDayToAdjust?: Maybe<Scalars["Float"]>;
  isWeightLoss?: Maybe<Scalars["Boolean"]>;
  newCalorieIntake?: Maybe<Scalars["Float"]>;
  newTDEE?: Maybe<Scalars["Float"]>;
};

export type GoalWeightResult = {
  __typename?: "GoalWeightResult";
  caloriesPerDayToAdjust?: Maybe<Scalars["Double"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  isWeightLoss?: Maybe<Scalars["Boolean"]>;
  newCalorieIntake?: Maybe<Scalars["Double"]>;
  newTDEE?: Maybe<Scalars["Double"]>;
  queryInput?: Maybe<GoalWeightInputLog>;
};

export type GoalWeightResultConnection = {
  __typename?: "GoalWeightResultConnection";
  edges: Array<GoalWeightResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type GoalWeightResultEdge = {
  __typename?: "GoalWeightResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: GoalWeightResult;
};

export type Group = Node & {
  __typename?: "Group";
  groupConfiguration?: Maybe<GroupConfiguration>;
  id: Scalars["ID"];
  name: Scalars["String"];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  userCanManage: Scalars["Boolean"];
  users?: Maybe<Array<Maybe<User>>>;
};

export type GroupConfiguration = {
  __typename?: "GroupConfiguration";
  created?: Maybe<Scalars["DateTime"]>;
  groupType?: Maybe<AppTypes>;
  isEditable?: Maybe<Scalars["Boolean"]>;
  isGlobal?: Maybe<Scalars["Boolean"]>;
  keycloakGroupId?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Vendor>;
};

export type GroupConfigurationInput = {
  groupType: AppTypes;
  isEditable?: InputMaybe<Scalars["Boolean"]>;
  isGlobal?: InputMaybe<Scalars["Boolean"]>;
  vendor?: InputMaybe<Scalars["ID"]>;
};

export type GroupConfigurationUpdateInput = {
  isEditable?: InputMaybe<Scalars["Boolean"]>;
};

export type GroupCountableConnection = {
  __typename?: "GroupCountableConnection";
  edges: Array<GroupCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type GroupCountableEdge = {
  __typename?: "GroupCountableEdge";
  cursor: Scalars["String"];
  node: Group;
};

export enum GuidedCareActivityType {
  FacilityVisit = "FACILITY_VISIT",
  HomeVisit = "HOME_VISIT",
  Lab = "LAB",
  MedicalForm = "MEDICAL_FORM",
  MedicalMessageGeneral = "MEDICAL_MESSAGE_GENERAL",
  MedicalMessageInstruction = "MEDICAL_MESSAGE_INSTRUCTION",
  MedicalMessageMedical = "MEDICAL_MESSAGE_MEDICAL",
  MedicalMessageMonitoring = "MEDICAL_MESSAGE_MONITORING",
  MedicalMessageReminder = "MEDICAL_MESSAGE_REMINDER",
  OnlineVisit = "ONLINE_VISIT",
  Rad = "RAD",
}

export enum GuidedCareCareJourneyItemStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Missed = "MISSED",
  OnHold = "ON_HOLD",
  Pending = "PENDING",
  Received = "RECEIVED",
  Rejected = "REJECTED",
  Rescheduled = "RESCHEDULED",
}

export type GuidedCareHealthProgram = H_Node & {
  __typename?: "GuidedCareHealthProgram";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  description?: Maybe<Scalars["String"]>;
  guidedCareHealthProgramMemberLists?: Maybe<Array<Maybe<HealthProgramMemberList>>>;
  guidedCareHealthProgramTemplate?: Maybe<HealthProgramTemplate>;
  guidedCareType?: Maybe<GuidedCareType>;
  id: Scalars["ID"];
  insuranceNetworks?: Maybe<Array<Maybe<HealthProgramNetwork>>>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isLatestVersion?: Maybe<Scalars["Boolean"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  payer?: Maybe<Payer>;
  price?: Maybe<Scalars["Int"]>;
  programActivities?: Maybe<Array<Maybe<GuidedCareHealthProgramActivity>>>;
  teamMembers?: Maybe<Array<Maybe<GuidedCareProgramTeamMember>>>;
};

export type GuidedCareHealthProgramActivity = {
  __typename?: "GuidedCareHealthProgramActivity";
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  frequencyType: AppointmentFrequencyType;
  guidedCareProgramActivityItems?: Maybe<Array<Maybe<GuidedCareProgramActivityItem>>>;
  guidedCareProgramTeamMember?: Maybe<GuidedCareProgramTeamMember>;
  id?: Maybe<Scalars["ID"]>;
  numberOfOccurrences: Scalars["Int"];
  serviceOfGuidedCareHealthProgramDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateActivity?: Maybe<TemplateActivity>;
  templateActivityType?: Maybe<GuidedCareActivityType>;
};

export type GuidedCareHealthProgramActivityCrud = {
  __typename?: "GuidedCareHealthProgramActivityCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  guidedCareHealthProgramActivity?: Maybe<GuidedCareHealthProgramActivity>;
  guidedCareHealthProgramActivityErrors?: Maybe<Array<H_EntityError>>;
};

export type GuidedCareHealthProgramActivityInput = {
  afterCallMessageId?: InputMaybe<Scalars["ID"]>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  beforeCallMessageId?: InputMaybe<Scalars["ID"]>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  frequencyType: AppointmentFrequencyType;
  guidedCareProgramTeamMember?: InputMaybe<ActivityGuidedCareProgramTeamMemberInput>;
  id?: InputMaybe<Scalars["ID"]>;
  isModified?: InputMaybe<Scalars["Boolean"]>;
  numberOfOccurrences: Scalars["Int"];
  serviceOfGuidedCareHealthProgramDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  templateActivity?: InputMaybe<TemplateActivityInput>;
  templateActivityType?: InputMaybe<GuidedCareActivityType>;
};

export type GuidedCareHealthProgramActivityItemInput = {
  afterCallMessageId?: InputMaybe<Scalars["ID"]>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  beforeCallMessageId?: InputMaybe<Scalars["ID"]>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  offsetInDays?: InputMaybe<Scalars["Int"]>;
};

export type GuidedCareHealthProgramCrud = {
  __typename?: "GuidedCareHealthProgramCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  guidedCareHealthProgram?: Maybe<GuidedCareHealthProgram>;
  guidedCareHealthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type GuidedCareHealthProgramCountableConnection = {
  __typename?: "GuidedCareHealthProgramCountableConnection";
  edges: Array<GuidedCareHealthProgramCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type GuidedCareHealthProgramCountableEdge = {
  __typename?: "GuidedCareHealthProgramCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: GuidedCareHealthProgram;
};

export type GuidedCareHealthProgramFilter = {
  careTeams?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  description_Icontains?: InputMaybe<Scalars["String"]>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthConditionId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  memberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  payerId?: InputMaybe<Scalars["ID"]>;
  price?: InputMaybe<H_PriceRangeInput>;
  providerId?: InputMaybe<Scalars["ID"]>;
  templateId?: InputMaybe<Scalars["ID"]>;
  templateName?: InputMaybe<Scalars["String"]>;
};

export type GuidedCareHealthProgramInput = {
  description?: InputMaybe<Scalars["String"]>;
  guidedCareHealthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name: Scalars["String"];
  payerId?: InputMaybe<Scalars["ID"]>;
  price?: InputMaybe<Scalars["Int"]>;
  programActivities?: InputMaybe<Array<InputMaybe<GuidedCareHealthProgramActivityInput>>>;
  teamMembers?: InputMaybe<Array<InputMaybe<GuidedCareProgramTeamMemberInput>>>;
  templateName: Scalars["String"];
};

export type GuidedCareHealthProgramRiskFactorTemplateEvaluation = {
  __typename?: "GuidedCareHealthProgramRiskFactorTemplateEvaluation";
  enrolledGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  level?: Maybe<Scalars["String"]>;
  notification?: Maybe<Scalars["String"]>;
  suggestedGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  visitId?: Maybe<Scalars["ID"]>;
};

export enum GuidedCareHealthProgramSortingField {
  Created = "CREATED",
  Id = "ID",
  IsActive = "IS_ACTIVE",
  Modified = "MODIFIED",
  Price = "PRICE",
  ProgramName = "PROGRAM_NAME",
  TemplateName = "TEMPLATE_NAME",
}

export type GuidedCareHealthProgramSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: GuidedCareHealthProgramSortingField;
};

export type GuidedCareJourney = H_Node & {
  __typename?: "GuidedCareJourney";
  activeInterventions?: Maybe<Array<Maybe<GuidedCareJourneyIntervention>>>;
  activities?: Maybe<Scalars["String"]>;
  adherence?: Maybe<Scalars["Int"]>;
  allActivitiesCount?: Maybe<Scalars["Int"]>;
  allActivitiesCountBeforeCurrentDate?: Maybe<Scalars["Int"]>;
  completedActivitiesCount?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdByUserId?: Maybe<Scalars["ID"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  disenrollmentDate?: Maybe<Scalars["Instant"]>;
  eligibleInterventions?: Maybe<Array<Maybe<GuidedCareJourneyIntervention>>>;
  endDate?: Maybe<Scalars["Instant"]>;
  gaps?: Maybe<Scalars["Int"]>;
  guidedCareHealthProgram?: Maybe<GuidedCareHealthProgram>;
  guidedCareJourneyItems?: Maybe<Array<Maybe<GuidedCareJourneyItem>>>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  patientMedicalProfile?: Maybe<PatientMedicalProfile>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  startDate?: Maybe<Scalars["Instant"]>;
  status?: Maybe<GuidedCareJourneyStatus>;
  statusReason?: Maybe<Scalars["String"]>;
};

export type GuidedCareJourneyCountableConnection = {
  __typename?: "GuidedCareJourneyCountableConnection";
  edges: Array<GuidedCareJourneyCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type GuidedCareJourneyCountableEdge = {
  __typename?: "GuidedCareJourneyCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: GuidedCareJourney;
};

export type GuidedCareJourneyFilterInput = {
  healthProgramId?: InputMaybe<Scalars["ID"]>;
  ioHealthId?: InputMaybe<Scalars["String"]>;
  patientName?: InputMaybe<Scalars["String"]>;
  patientsIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  statuses?: InputMaybe<Array<InputMaybe<GuidedCareJourneyStatus>>>;
  teamLeaderName?: InputMaybe<Scalars["String"]>;
};

export type GuidedCareJourneyIntervention = {
  __typename?: "GuidedCareJourneyIntervention";
  healthParameter?: Maybe<HealthParameter>;
  templateIntervention?: Maybe<TemplateIntervention>;
};

export type GuidedCareJourneyItem = H_Node & {
  __typename?: "GuidedCareJourneyItem";
  appointment?: Maybe<Appointment>;
  classification?: Maybe<JourneyItemClassification>;
  createdBy?: Maybe<Scalars["String"]>;
  createdByUserId?: Maybe<Scalars["ID"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  delete?: Maybe<Scalars["Boolean"]>;
  dueDate?: Maybe<Scalars["Instant"]>;
  executionEnd?: Maybe<Scalars["Instant"]>;
  executionStart?: Maybe<Scalars["Instant"]>;
  guidedCareJourney?: Maybe<GuidedCareJourney>;
  guidedCareJourneyItemServiceDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  guidedCareJourneyItemSource?: Maybe<GuidedCareJourneyItemSource>;
  guidedCareJourneyItemStatus?: Maybe<GuidedCareJourneyItemStatus>;
  guidedCareJourneyItemType?: Maybe<GuidedCareActivityType>;
  id: Scalars["ID"];
  isDisenrolled?: Maybe<Scalars["Boolean"]>;
  isVisibleInCareJourneyTimeLine?: Maybe<Scalars["Boolean"]>;
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  medicalForm?: Maybe<MedicalForm>;
  medicalFormAnswers?: Maybe<MedicalFormAnswerConnection>;
  medicalMessage?: Maybe<MedicalMessage>;
  note?: Maybe<Scalars["String"]>;
  priority?: Maybe<GuidedCareJourneyItemPriority>;
  questionAnswers?: Maybe<QuestionAnswerConnection>;
  statusReason?: Maybe<Scalars["String"]>;
  teamMember?: Maybe<ProviderGuidedCareProgramTeamMember>;
  templateIntervention?: Maybe<TemplateIntervention>;
};

export type GuidedCareJourneyItemMedicalFormAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MedicalFormAnswerFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type GuidedCareJourneyItemQuestionAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<QuestionAnswerFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type GuidedCareJourneyItemCrud = {
  __typename?: "GuidedCareJourneyItemCRUD";
  guidedCareJourneyItem?: Maybe<GuidedCareJourneyItem>;
  guidedCareJourneyItemErrors?: Maybe<Array<H_EntityError>>;
};

export type GuidedCareJourneyItemCountableConnection = {
  __typename?: "GuidedCareJourneyItemCountableConnection";
  edges: Array<GuidedCareJourneyItemCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type GuidedCareJourneyItemCountableEdge = {
  __typename?: "GuidedCareJourneyItemCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: GuidedCareJourneyItem;
};

export type GuidedCareJourneyItemFilter = {
  doctorId?: InputMaybe<Scalars["ID"]>;
  doctorUserId?: InputMaybe<Scalars["ID"]>;
  dueDate?: InputMaybe<H_DateTimeRangeInput>;
  guidedCareHealthProgramId?: InputMaybe<Scalars["ID"]>;
  guidedCareJourneyId?: InputMaybe<Scalars["ID"]>;
  patientId?: InputMaybe<Scalars["ID"]>;
  source?: InputMaybe<GuidedCareJourneyItemSource>;
  statuses?: InputMaybe<Array<InputMaybe<GuidedCareJourneyItemStatus>>>;
  teamMemberPosition?: InputMaybe<TeamMemberPosition>;
  types?: InputMaybe<Array<InputMaybe<GuidedCareActivityType>>>;
};

export type GuidedCareJourneyItemInput = {
  guidedCareJourneyItemStatus?: InputMaybe<GuidedCareCareJourneyItemStatus>;
  note?: InputMaybe<Scalars["String"]>;
  statusReason?: InputMaybe<Scalars["String"]>;
};

export enum GuidedCareJourneyItemPriority {
  /** The request should be actioned as soon as possible - higher priority than urgent */
  Asap = "ASAP",
  /** The request has normal priority */
  Routine = "ROUTINE",
  /** The request should be actioned immediately - highest possible priority. E.g. an emergency */
  Stat = "STAT",
  /** The request should be actioned promptly - higher priority than routine */
  Urgent = "URGENT",
}

export enum GuidedCareJourneyItemSource {
  Personalized = "PERSONALIZED",
  Program = "PROGRAM",
}

export enum GuidedCareJourneyItemStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Missed = "MISSED",
  OnHold = "ON_HOLD",
  Pending = "PENDING",
  Received = "RECEIVED",
  Rejected = "REJECTED",
  Rescheduled = "RESCHEDULED",
}

export enum GuidedCareJourneySortField {
  /** Sort by adherence. */
  Adherence = "ADHERENCE",
  /** Sort by creation date. */
  CreationDate = "CREATION_DATE",
  /** Sort by gaps. */
  Gaps = "GAPS",
}

export type GuidedCareJourneySortingInput = {
  /** Specifies the direction in which to sort Medical Messages. */
  direction: H_OrderDirection;
  /** Sort medical messages by the selected field. */
  field: GuidedCareJourneySortField;
};

export enum GuidedCareJourneyStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  InProgress = "IN_PROGRESS",
}

export type GuidedCarePatientTeamEnrollmentInput = {
  patientId?: InputMaybe<Scalars["ID"]>;
  patientUserId?: InputMaybe<Scalars["ID"]>;
  teamId: Scalars["ID"];
};

export type GuidedCarePatientTeamSwitchInput = {
  newTeamId: Scalars["ID"];
  oldTeamId: Scalars["ID"];
};

export type GuidedCareProgramActivityItem = H_Node & {
  __typename?: "GuidedCareProgramActivityItem";
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  offsetInDays?: Maybe<Scalars["Int"]>;
};

export type GuidedCareProgramTeamMember = H_Node & {
  __typename?: "GuidedCareProgramTeamMember";
  id: Scalars["ID"];
  isBackupPersonMandatory?: Maybe<Scalars["Boolean"]>;
  isKeyTeamMember?: Maybe<Scalars["Boolean"]>;
  isLicencedHealthProfessional?: Maybe<Scalars["Boolean"]>;
  position?: Maybe<TeamMemberPosition>;
  specialization?: Maybe<Scalars["String"]>;
  specializationArabicDisplay?: Maybe<Scalars["String"]>;
  specializationDisplay?: Maybe<Scalars["String"]>;
  templateTeamMember?: Maybe<TemplateTeamMember>;
};

export type GuidedCareProgramTeamMemberIdInput = {
  id: Scalars["ID"];
};

export type GuidedCareProgramTeamMemberInput = {
  id?: InputMaybe<Scalars["ID"]>;
  isBackupPersonMandatory: Scalars["Boolean"];
  isKeyTeamMember: Scalars["Boolean"];
  isLicencedHealthProfessional: Scalars["Boolean"];
  position: TeamMemberPosition;
  specialization?: InputMaybe<Scalars["String"]>;
  templateTeamMember?: InputMaybe<TemplateTeamMemberIdInput>;
  uniqueId?: InputMaybe<Scalars["String"]>;
};

export type GuidedCareTask = H_Node & {
  __typename?: "GuidedCareTask";
  assignee?: Maybe<Array<Maybe<GuidedCareTaskAssignee>>>;
  description?: Maybe<Scalars["String"]>;
  displayAr?: Maybe<Scalars["String"]>;
  displayEn?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["Instant"]>;
  executionStartDate?: Maybe<Scalars["Instant"]>;
  guidedCareJourneyItemId?: Maybe<Scalars["ID"]>;
  guidedCareProgramName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  nextReminderDate?: Maybe<Scalars["Instant"]>;
  notes?: Maybe<Scalars["String"]>;
  notificationMessageAr?: Maybe<Scalars["String"]>;
  notificationMessageEn?: Maybe<Scalars["String"]>;
  notificationTitleAr?: Maybe<Scalars["String"]>;
  notificationTitleEn?: Maybe<Scalars["String"]>;
  patient?: Maybe<Patient>;
  previousTask?: Maybe<GuidedCareTask>;
  status?: Maybe<TaskDefinitionStatus>;
  statusReason?: Maybe<Scalars["String"]>;
  taskActionEntityId?: Maybe<Scalars["ID"]>;
  taskDefinition?: Maybe<TaskDefinition>;
};

export type GuidedCareTaskAssignee = {
  __typename?: "GuidedCareTaskAssignee";
  assigneeType?: Maybe<AssigneeType>;
  assigneeUserId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  teamMember?: Maybe<ProviderGuidedCareProgramTeamMember>;
};

export type GuidedCareTaskAssigneeInput = {
  assigneeType: AssigneeType;
  assigneeUserId: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
  teamMember?: InputMaybe<AssigneeTeamMemberInput>;
};

/** CRUD a new Guided Care Task */
export type GuidedCareTaskCrud = {
  __typename?: "GuidedCareTaskCRUD";
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  guidedCareTask?: Maybe<GuidedCareTask>;
};

export type GuidedCareTaskCountableConnection = {
  __typename?: "GuidedCareTaskCountableConnection";
  edges: Array<GuidedCareTaskCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type GuidedCareTaskCountableEdge = {
  __typename?: "GuidedCareTaskCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: GuidedCareTask;
};

export type GuidedCareTaskFilterInput = {
  assigneeUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  dueDate?: InputMaybe<H_DateTimeRangeInput>;
  guidedCareHealthProgramId?: InputMaybe<Scalars["ID"]>;
  nextReminderDate?: InputMaybe<H_DateTimeRangeInput>;
  patientUserId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  statuses?: InputMaybe<Array<InputMaybe<TaskDefinitionStatus>>>;
  teamMemberUserId?: InputMaybe<Scalars["ID"]>;
};

export type GuidedCareTaskInnerInput = {
  id: Scalars["ID"];
};

export type GuidedCareTaskInput = {
  description: Scalars["String"];
  displayAr: Scalars["String"];
  displayEn: Scalars["String"];
  dueDate: Scalars["Instant"];
  nextReminderDate: Scalars["Instant"];
  notes: Scalars["String"];
  notificationMessageAr: Scalars["String"];
  notificationMessageEn: Scalars["String"];
  notificationTitleAr: Scalars["String"];
  notificationTitleEn: Scalars["String"];
  previousTask?: InputMaybe<GuidedCareTaskInnerInput>;
  status: TaskDefinitionStatus;
  statusReason: Scalars["String"];
  taskDefinition: TaskDefinitionInnerInput;
};

export enum GuidedCareTaskSortingField {
  DueDate = "DUE_DATE",
  NextReminderDate = "NEXT_REMINDER_DATE",
}

export type GuidedCareTaskSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<GuidedCareTaskSortingField>;
};

export type GuidedCareTaskUpdateInput = {
  assignee: Array<InputMaybe<GuidedCareTaskAssigneeInput>>;
  description?: InputMaybe<Scalars["String"]>;
  displayAr?: InputMaybe<Scalars["String"]>;
  displayEn?: InputMaybe<Scalars["String"]>;
  dueDate?: InputMaybe<Scalars["Instant"]>;
  nextReminderDate?: InputMaybe<Scalars["Instant"]>;
  notes?: InputMaybe<Scalars["String"]>;
  notificationMessageAr?: InputMaybe<Scalars["String"]>;
  notificationMessageEn?: InputMaybe<Scalars["String"]>;
  notificationTitleAr?: InputMaybe<Scalars["String"]>;
  notificationTitleEn?: InputMaybe<Scalars["String"]>;
  previousTask?: InputMaybe<GuidedCareTaskInnerInput>;
  status?: InputMaybe<TaskDefinitionStatus>;
  statusReason?: InputMaybe<Scalars["String"]>;
  taskDefinition?: InputMaybe<TaskDefinitionInnerInput>;
};

export enum GuidedCareType {
  Acute = "ACUTE",
  Chronic = "CHRONIC",
  PostOperative = "POST_OPERATIVE",
  PreOperative = "PRE_OPERATIVE",
  Primary = "PRIMARY",
  SubAcute = "SUB_ACUTE",
  Supportive = "SUPPORTIVE",
}

export type HisAppointmentFilterInput = {
  branchId?: InputMaybe<Scalars["String"]>;
  departmentId?: InputMaybe<Scalars["String"]>;
  doctorId?: InputMaybe<Scalars["String"]>;
  fromDate: Scalars["String"];
  page: Scalars["Int"];
  size: Scalars["Int"];
  toDate: Scalars["String"];
  vendorId?: InputMaybe<Scalars["String"]>;
};

export type H_CancelReason = H_Node & {
  __typename?: "H_CancelReason";
  actor?: Maybe<Actor>;
  affectedType?: Maybe<AffectedType>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  text?: Maybe<Scalars["String"]>;
  text_ar?: Maybe<Scalars["String"]>;
};

export type H_DateTimeRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars["Instant"]>;
  /** End date. */
  lte?: InputMaybe<Scalars["Instant"]>;
};

export enum H_DayOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type H_EntityError = {
  __typename?: "H_EntityError";
  /** The error code. */
  code: H_EntityErrorCode;
  /** Name of a field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field?: Maybe<Scalars["String"]>;
  /** The error message. */
  message?: Maybe<Scalars["String"]>;
};

export enum H_EntityErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  AppointmentOverlap = "APPOINTMENT_OVERLAP",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  Mismatch = "MISMATCH",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

/** Represents an error in the input of a mutation. */
export type H_Error = {
  __typename?: "H_Error";
  /** Name of a field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field?: Maybe<Scalars["String"]>;
  /** The error message. */
  message?: Maybe<Scalars["String"]>;
};

export enum H_Gender {
  Female = "FEMALE",
  Male = "MALE",
}

export enum H_IdTypes {
  NationalCard = "NATIONAL_CARD",
  Passport = "PASSPORT",
  ResidentCard = "RESIDENT_CARD",
}

export type H_IntRangeInput = {
  gte?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
};

export type H_LocationInput = {
  /** Latitude Value. */
  lat: Scalars["Float"];
  /** Longitude Value . */
  lng: Scalars["Float"];
};

export type H_LocationType = {
  __typename?: "H_LocationType";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** Represents amount of money in specific currency. */
export type H_Money = {
  __typename?: "H_Money";
  /** Amount of money. */
  amount: Scalars["Float"];
  /** Currency code. */
  currency: Scalars["String"];
};

/** An object with an ID */
export type H_Node = {
  /** The ID of the object. */
  id: Scalars["ID"];
};

export enum H_OrderDirection {
  /** Specifies an ascending sort order. */
  Asc = "ASC",
  /** Specifies a descending sort order. */
  Desc = "DESC",
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type H_PageInfo = {
  __typename?: "H_PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type H_PriceRangeInput = {
  /** Price greater than or equal to. */
  gte?: InputMaybe<Scalars["Float"]>;
  /** Price less than or equal to. */
  lte?: InputMaybe<Scalars["Float"]>;
};

export type H_RejectionReason = H_Node & {
  __typename?: "H_RejectionReason";
  actor?: Maybe<Actor>;
  affectedType?: Maybe<AffectedType>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  text?: Maybe<Scalars["String"]>;
  text_ar?: Maybe<Scalars["String"]>;
};

export enum H_RelationTypes {
  Aunt = "AUNT",
  Brother = "BROTHER",
  Cousin = "COUSIN",
  Daughter = "DAUGHTER",
  Father = "FATHER",
  Friend = "FRIEND",
  Granddaughter = "GRANDDAUGHTER",
  Grandfather = "GRANDFATHER",
  Grandmother = "GRANDMOTHER",
  Grandson = "GRANDSON",
  Husband = "HUSBAND",
  Mother = "MOTHER",
  Nephew = "NEPHEW",
  Niece = "NIECE",
  Other = "OTHER",
  Sister = "SISTER",
  Son = "SON",
  Uncle = "UNCLE",
  Wife = "WIFE",
}

export enum H_ReportingPeriod {
  Last_7Days = "LAST_7_DAYS",
  ThisMonth = "THIS_MONTH",
  Today = "TODAY",
}

export type H_SiteSettingsInput = {
  /**
   * allowed time in seconds to make a new call after the essential latest call was made
   * , essential call means the first call that was requested by patient and perhaps the call was timedout/rejoined/cancelled
   * and then the final status of it can be
   * (INCOMPLETED, COMPLETED, CANCELED, CANCELED_BY_CONSUMER, ENDED_BY_ONE_OF_THE_PARTICIPANTS)
   */
  allowedTimeInSecondsToMakeNewCall?: InputMaybe<Scalars["Int"]>;
  appointmentReminderTimeSeconds?: InputMaybe<Scalars["Int"]>;
  callJoinTimeoutSeconds?: InputMaybe<Scalars["Int"]>;
  callRejoinTimeoutInSeconds?: InputMaybe<Scalars["Int"]>;
  doctorVisitAcceptanceTimeoutSeconds?: InputMaybe<Scalars["Int"]>;
  guidedCareAppointmentBookingFirstReminderSeconds?: InputMaybe<Scalars["Int"]>;
  guidedCareAppointmentBookingReminderCareTeamAdminMessage?: InputMaybe<Scalars["String"]>;
  guidedCareAppointmentBookingReminderCareTeamAdminMessageAr?: InputMaybe<Scalars["String"]>;
  guidedCareAppointmentBookingReminderDoctorMessage?: InputMaybe<Scalars["String"]>;
  guidedCareAppointmentBookingReminderDoctorMessageAr?: InputMaybe<Scalars["String"]>;
  guidedCareAppointmentBookingReminderPatientMessage?: InputMaybe<Scalars["String"]>;
  guidedCareAppointmentBookingReminderPatientMessageAr?: InputMaybe<Scalars["String"]>;
  guidedCareAppointmentBookingSecondReminderSeconds?: InputMaybe<Scalars["Int"]>;
  guidedCareAppointmentBookingThirdReminderSeconds?: InputMaybe<Scalars["Int"]>;
  healthProgramVisitWaitTimeBeforeCallSeconds?: InputMaybe<Scalars["Int"]>;
  messengerCallMinimumDurationSeconds?: InputMaybe<Scalars["Int"]>;
  runningLateTimeoutMinutes?: InputMaybe<Scalars["Int"]>;
  vatPercentageForMedicalServices?: InputMaybe<Scalars["Float"]>;
};

export type HandleOrderVendorAcceptTimeout = {
  __typename?: "HandleOrderVendorAcceptTimeout";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type HealthChannel = H_Node & {
  __typename?: "HealthChannel";
  averageRating?: Maybe<Scalars["Float"]>;
  category?: Maybe<HealthChannelCategory>;
  coverImage?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  description?: Maybe<Scalars["String"]>;
  followers?: Maybe<HealthChannelFollowerCountableConnection>;
  followersCount?: Maybe<Scalars["Int"]>;
  healthChannelFollowers?: Maybe<Array<Maybe<HealthChannelFollower>>>;
  healthChannelRatings?: Maybe<Array<Maybe<HealthChannelRating>>>;
  id: Scalars["ID"];
  isFollowing?: Maybe<Scalars["Boolean"]>;
  labels?: Maybe<Array<Maybe<Scalars["String"]>>>;
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  published?: Maybe<Scalars["Boolean"]>;
  ratingsSum?: Maybe<Scalars["Float"]>;
  subscriptionType?: Maybe<HealthChannelSubscriptionType>;
  totalRatings?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type HealthChannelFollowersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** CRUD a new health channel. */
export type HealthChannelCrud = {
  __typename?: "HealthChannelCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannel?: Maybe<HealthChannel>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthChannelCategory = H_Node & {
  __typename?: "HealthChannelCategory";
  created?: Maybe<Scalars["Instant"]>;
  healthChannels?: Maybe<HealthChannelCountableConnection>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
};

export type HealthChannelCategoryHealthChannelsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** CRUD a new health channel category. */
export type HealthChannelCategoryCrud = {
  __typename?: "HealthChannelCategoryCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelCategory?: Maybe<HealthChannelCategory>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthChannelCategoryCountableConnection = {
  __typename?: "HealthChannelCategoryCountableConnection";
  edges: Array<HealthChannelCategoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthChannelCategoryCountableEdge = {
  __typename?: "HealthChannelCategoryCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthChannelCategory;
};

export type HealthChannelCategoryInput = {
  /** Health Channel Category Name */
  name: Scalars["String"];
};

export type HealthChannelCountableConnection = {
  __typename?: "HealthChannelCountableConnection";
  edges: Array<HealthChannelCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthChannelCountableEdge = {
  __typename?: "HealthChannelCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthChannel;
};

export type HealthChannelFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  consumers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  published?: InputMaybe<Scalars["Boolean"]>;
  subscriptionType?: InputMaybe<Array<InputMaybe<HealthChannelSubscriptionType>>>;
};

export type HealthChannelFollower = H_Node & {
  __typename?: "HealthChannelFollower";
  channel?: Maybe<HealthChannel>;
  consumerId?: Maybe<Scalars["Int"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
};

export type HealthChannelFollowerCountableConnection = {
  __typename?: "HealthChannelFollowerCountableConnection";
  edges: Array<HealthChannelFollowerCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthChannelFollowerCountableEdge = {
  __typename?: "HealthChannelFollowerCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthChannelFollower;
};

export type HealthChannelFollowerFilterInput = {
  channels?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type HealthChannelInput = {
  category?: InputMaybe<Scalars["ID"]>;
  coverImage?: InputMaybe<Scalars["Upload"]>;
  description?: InputMaybe<Scalars["String"]>;
  labels?: InputMaybe<Array<Scalars["String"]>>;
  name?: InputMaybe<Scalars["String"]>;
  published?: InputMaybe<Scalars["Boolean"]>;
  subscriptionType?: InputMaybe<HealthChannelSubscriptionType>;
  url?: InputMaybe<Scalars["String"]>;
};

export type HealthChannelRating = H_Node & {
  __typename?: "HealthChannelRating";
  comment?: Maybe<Scalars["String"]>;
  consumerId?: Maybe<Scalars["Int"]>;
  healthChannel?: Maybe<HealthChannel>;
  id: Scalars["ID"];
  rating?: Maybe<Scalars["Float"]>;
};

export type HealthChannelRatingInput = {
  /** ID of Health Channel to be rated */
  channel: Scalars["ID"];
  /** Feedback on the user's rating for the health channel */
  comment?: InputMaybe<Scalars["String"]>;
  /** The rating provided by the user (from 1 to 5) */
  rating: Scalars["Float"];
};

export enum HealthChannelSubscriptionType {
  Free = "FREE",
  Paid = "PAID",
}

export type HealthCondition = {
  __typename?: "HealthCondition";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  disease?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  fields?: Maybe<Array<Maybe<HealthParameter>>>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<HealthConditionType>;
  umlsCode?: Maybe<Scalars["String"]>;
};

export type HealthConditionDiagnosesFilterInput = {
  diagnosisCode?: InputMaybe<Scalars["String"]>;
  healthConditionId?: InputMaybe<Scalars["ID"]>;
};

export type HealthConditionDiagnosesSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: HealthConditionDiagnosisSortingField;
};

export type HealthConditionDiagnosis = {
  __typename?: "HealthConditionDiagnosis";
  diagnosisCode?: Maybe<Scalars["String"]>;
  diagnosisDisplay?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
};

export type HealthConditionDiagnosisConnection = {
  __typename?: "HealthConditionDiagnosisConnection";
  edges?: Maybe<Array<Maybe<HealthConditionDiagnosisEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthConditionDiagnosisEdge = {
  __typename?: "HealthConditionDiagnosisEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<HealthConditionDiagnosis>;
};

export enum HealthConditionDiagnosisSortingField {
  Code = "CODE",
  CreatedDate = "CREATED_DATE",
}

export type HealthConditionEdge = {
  __typename?: "HealthConditionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<HealthCondition>;
};

export type HealthConditionFilterInput = {
  arabicDisplay?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  diagnosisCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  disease?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<HealthConditionType>;
};

export type HealthConditionInput = {
  arabicDisplay: Scalars["String"];
  code: Scalars["String"];
  disease: Scalars["String"];
  display: Scalars["String"];
  isActive: Scalars["Boolean"];
  type: HealthConditionType;
  umlsCode: Scalars["String"];
};

export enum HealthConditionSortingField {
  ArabicDisplay = "ARABIC_DISPLAY",
  Code = "CODE",
  CreatedDate = "CREATED_DATE",
  Disease = "DISEASE",
  Display = "DISPLAY",
  IsActive = "IS_ACTIVE",
  Type = "TYPE",
}

export type HealthConditionSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: HealthConditionSortingField;
};

export enum HealthConditionType {
  Acute = "ACUTE",
  Chronic = "CHRONIC",
  SubAcute = "SUB_ACUTE",
}

export type HealthConditionUpdateInput = {
  arabicDisplay: Scalars["String"];
  disease: Scalars["String"];
  display: Scalars["String"];
  isActive: Scalars["Boolean"];
  type: HealthConditionType;
  umlsCode: Scalars["String"];
};

export type HealthConditioneConnection = {
  __typename?: "HealthConditioneConnection";
  edges?: Maybe<Array<Maybe<HealthConditionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthHistory = {
  __typename?: "HealthHistory";
  code?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<HealthParameterType>;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueNumber?: Maybe<Scalars["Double"]>;
  valueNumericList?: Maybe<Array<Maybe<Scalars["Double"]>>>;
  valueString?: Maybe<Scalars["String"]>;
};

export type HealthHistoryInput = {
  code: Scalars["String"];
  date: Scalars["Instant"];
  type: HealthParameterType;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueNumber?: InputMaybe<Scalars["Float"]>;
  valueNumericList?: InputMaybe<Array<InputMaybe<Scalars["Double"]>>>;
  valueString?: InputMaybe<Scalars["String"]>;
};

export enum HealthHistoryType {
  Allergy = "ALLERGY",
  Diagnose = "DIAGNOSE",
  Family = "FAMILY",
  Medical = "MEDICAL",
  Surgery = "SURGERY",
}

export type HealthParameter = {
  __typename?: "HealthParameter";
  allowedValues?: Maybe<Array<Maybe<Scalars["String"]>>>;
  arabicDisplay?: Maybe<Scalars["String"]>;
  category?: Maybe<FieldCategory>;
  chartType?: Maybe<ChartType>;
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  defaultValue?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isMandatory?: Maybe<Scalars["Boolean"]>;
  maxNormalRangeValue?: Maybe<Scalars["Float"]>;
  minNormalRangeValue?: Maybe<Scalars["Float"]>;
  referenceRange?: Maybe<Scalars["String"]>;
  standard?: Maybe<FieldStandard>;
  standardCode?: Maybe<Scalars["String"]>;
  standardCodeDisplay?: Maybe<Scalars["String"]>;
  type?: Maybe<TemplateFieldType>;
  unit?: Maybe<FieldUnit>;
  visibleForPatientOverview?: Maybe<Scalars["Boolean"]>;
};

export type HealthParameterCategoryFilterInput = {
  arabicDisplay?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
};

export type HealthParameterConnection = {
  __typename?: "HealthParameterConnection";
  edges?: Maybe<Array<Maybe<HealthParameterEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthParameterEdge = {
  __typename?: "HealthParameterEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<HealthParameter>;
};

export type HealthParameterFilterInput = {
  arabicDisplay?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["ID"]>;
  categoryCode?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  display?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  standardCode?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type HealthParameterInput = {
  allowedValues?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  arabicDisplay: Scalars["String"];
  category: FieldCategoryInput;
  chartType: ChartType;
  code: Scalars["String"];
  defaultValue?: InputMaybe<Scalars["String"]>;
  display: Scalars["String"];
  isActive?: Scalars["Boolean"];
  isMandatory: Scalars["Boolean"];
  maxNormalRangeValue?: InputMaybe<Scalars["Float"]>;
  minNormalRangeValue?: InputMaybe<Scalars["Float"]>;
  referenceRange?: InputMaybe<Scalars["String"]>;
  standard: FieldStandard;
  standardCode: Scalars["String"];
  type: TemplateFieldType;
  unit?: InputMaybe<FieldUnitInput>;
  visibleForPatientOverview: Scalars["Boolean"];
};

export enum HealthParameterSortingField {
  ArabicDisplay = "ARABIC_DISPLAY",
  Code = "CODE",
  CreatedDate = "CREATED_DATE",
  Display = "DISPLAY",
  StandardCode = "STANDARD_CODE",
}

export type HealthParameterSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: HealthParameterSortingField;
};

export enum HealthParameterType {
  Vaccination = "VACCINATION",
}

export type HealthParameterUpdateInput = {
  arabicDisplay: Scalars["String"];
  category: FieldCategoryInput;
  chartType: ChartType;
  defaultValue?: InputMaybe<Scalars["String"]>;
  display: Scalars["String"];
  isActive?: Scalars["Boolean"];
  isMandatory: Scalars["Boolean"];
  maxNormalRangeValue?: InputMaybe<Scalars["Float"]>;
  minNormalRangeValue?: InputMaybe<Scalars["Float"]>;
  referenceRange?: InputMaybe<Scalars["String"]>;
  standard: FieldStandard;
  standardCode: Scalars["String"];
  unit?: InputMaybe<FieldUnitInput>;
  visibleForPatientOverview: Scalars["Boolean"];
};

export type HealthProfileInput = {
  activityLevel?: InputMaybe<ActivityLevel>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  dependentInputs?: InputMaybe<Array<HealthProfileInput>>;
  dietQuality?: InputMaybe<DietQuality>;
  educationLevel?: InputMaybe<EducationLevel>;
  feelingNervous?: InputMaybe<AnxietyFrequency>;
  gender?: InputMaybe<Gender>;
  hasBloodPressure?: InputMaybe<Scalars["Boolean"]>;
  hasFamilyHistory?: InputMaybe<Scalars["Boolean"]>;
  hasGestationalDiabetes?: InputMaybe<Scalars["Boolean"]>;
  hdlCholesterol?: InputMaybe<Scalars["Float"]>;
  healthHistoryInputs?: InputMaybe<Array<HealthHistoryInput>>;
  healthId: Scalars["String"];
  height?: InputMaybe<Scalars["Float"]>;
  nationalId: Scalars["String"];
  notBeingAbleToStopOrControlWorrying?: InputMaybe<AnxietyFrequency>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  sleepHours?: InputMaybe<Scalars["Int"]>;
  smokingHabit?: InputMaybe<SmokingHabit>;
  stressLevel?: InputMaybe<RiskLevel>;
  systolicBp?: InputMaybe<Scalars["Float"]>;
  totalCholesterol?: InputMaybe<Scalars["Float"]>;
  treatedForBp?: InputMaybe<Scalars["Int"]>;
  userName: Scalars["String"];
  weight?: InputMaybe<Scalars["Float"]>;
};

export type HealthProgram = H_Node & {
  __typename?: "HealthProgram";
  backGroundImage?: Maybe<Scalars["String"]>;
  baselineAvailabilityHours?: Maybe<Scalars["Int"]>;
  benefitGroups?: Maybe<Array<Maybe<BenefitGroupComplex>>>;
  callQualityRatingFactors?: Maybe<Scalars["String"]>;
  careFor?: Maybe<Array<Maybe<Scalars["String"]>>>;
  code?: Maybe<Scalars["String"]>;
  copay?: Maybe<Scalars["Boolean"]>;
  copayAmount?: Maybe<Scalars["Float"]>;
  copayPercentage?: Maybe<Scalars["Float"]>;
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  currency?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exclusionOptions?: Maybe<Array<Maybe<ExclusionOption>>>;
  healthProgramMemberLists?: Maybe<HealthProgramMemberListCountableConnection>;
  healthProgramNetworks?: Maybe<HealthProgramNetworkCountableConnection>;
  icon?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  insuranceNetworks?: Maybe<InsuranceNetworkCountableConnection>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isSubscribed?: Maybe<Scalars["Boolean"]>;
  mainSpecializationsRateFactors?: Maybe<Scalars["String"]>;
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  operatorProfitSharePercentage?: Maybe<Scalars["Float"]>;
  payPerCallOnly?: Maybe<Scalars["Boolean"]>;
  payer?: Maybe<Payer>;
  paymentReceipts: PaymentReceiptCountableConnection;
  platformProfitSharePercentage?: Maybe<Scalars["Float"]>;
  pointsDuringNormalDemand?: Maybe<Scalars["Float"]>;
  pointsDuringUrgentDemand?: Maybe<Scalars["Float"]>;
  prescriptionEnabled?: Maybe<Scalars["Boolean"]>;
  price?: Maybe<H_Money>;
  priceAmount?: Maybe<Scalars["Float"]>;
  referralsToSpecialistsEnabled?: Maybe<Scalars["Boolean"]>;
  requiresIdValidation?: Maybe<Scalars["Boolean"]>;
  service?: Maybe<Scalars["String"]>;
  specializations?: Maybe<Array<Maybe<HealthProgramSpecialization>>>;
  subscriptionPeriod?: Maybe<HealthProgramSubscriptionPeriod>;
  subscriptions?: Maybe<HealthProgramUserSubscriptionCountableConnection>;
  targetGroup?: Maybe<Scalars["String"]>;
  totalMembers?: Maybe<Scalars["Int"]>;
  type?: Maybe<HealthProgramType>;
  unattendedCallPenaltyFactor?: Maybe<Scalars["Float"]>;
  vendorProfitSharePercentage?: Maybe<Scalars["Float"]>;
};

export type HealthProgramHealthProgramMemberListsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramHealthProgramNetworksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramInsuranceNetworksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramBenefitGroupExtraBenefitInput = {
  extraBenefit: Scalars["ID"];
  healthChannels: Array<InputMaybe<Scalars["ID"]>>;
};

export type HealthProgramBenefitGroupInput = {
  benefitGroup: Scalars["ID"];
  extraBenefits?: InputMaybe<Array<InputMaybe<HealthProgramBenefitGroupExtraBenefitInput>>>;
  optionsValues: Array<InputMaybe<HealthProgramBenefitGroupOptionValueInput>>;
};

export type HealthProgramBenefitGroupOptionValueInput = {
  name?: InputMaybe<Scalars["String"]>;
  option: Scalars["ID"];
  value: Scalars["Float"];
};

/** CRUD a new Health Program Benefit Option */
export type HealthProgramBenefitOptionCrud = {
  __typename?: "HealthProgramBenefitOptionCRUD";
  benefitOption?: Maybe<BenefitOption>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export enum HealthProgramBenefitOptionCode {
  MaxOfFreeAudioVideoCalls = "MAX_OF_FREE_AUDIO_VIDEO_CALLS",
  NumberOfProactiveAudioVideoCalls = "NUMBER_OF_PROACTIVE_AUDIO_VIDEO_CALLS",
  NumberOfReactiveAudioVideoCalls = "NUMBER_OF_REACTIVE_AUDIO_VIDEO_CALLS",
  PatientSharePercentage = "PATIENT_SHARE_PERCENTAGE",
  ProactiveAtHomePlannedHealthServices = "PROACTIVE_AT_HOME_PLANNED_HEALTH_SERVICES",
  ProactiveInPersonPlannedHealthServices = "PROACTIVE_IN_PERSON_PLANNED_HEALTH_SERVICES",
  ReactiveAtHomeHealthServices = "REACTIVE_AT_HOME_HEALTH_SERVICES",
  ReactiveInPersonHealthServices = "REACTIVE_IN_PERSON_HEALTH_SERVICES",
}

export type HealthProgramBenefitOptionInput = {
  careType?: InputMaybe<CareType>;
  code?: InputMaybe<HealthProgramBenefitOptionCode>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<HealthProgramOptionType>;
};

/** CRUD a new Health Program */
export type HealthProgramCrud = {
  __typename?: "HealthProgramCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgram?: Maybe<HealthProgram>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthProgramCallsForVendorResponse = {
  __typename?: "HealthProgramCallsForVendorResponse";
  isUrgentDemandCall?: Maybe<Scalars["Boolean"]>;
  numberOfCalls?: Maybe<Scalars["Int"]>;
};

export type HealthProgramCallsPerVendorResponse = {
  __typename?: "HealthProgramCallsPerVendorResponse";
  numberOfCalls?: Maybe<Scalars["Int"]>;
  vendor?: Maybe<Vendor>;
};

export type HealthProgramCallsPerVendorResponseConnection = {
  __typename?: "HealthProgramCallsPerVendorResponseConnection";
  edges: Array<HealthProgramCallsPerVendorResponseCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramCallsPerVendorResponseCountableEdge = {
  __typename?: "HealthProgramCallsPerVendorResponseCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: HealthProgramCallsPerVendorResponse;
};

export type HealthProgramCountableConnection = {
  __typename?: "HealthProgramCountableConnection";
  edges: Array<HealthProgramCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramCountableEdge = {
  __typename?: "HealthProgramCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgram;
};

export type HealthProgramExclusionOptionInput = {
  exclusionOption: Scalars["ID"];
};

export type HealthProgramFilterInput = {
  benefitGroups?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  benefitOptions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  description_Icontains?: InputMaybe<Scalars["String"]>;
  exclusionOptions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isSubscribed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  payPerCallOnly?: InputMaybe<Scalars["Boolean"]>;
  prescriptionEnabled?: InputMaybe<Scalars["Boolean"]>;
  price?: InputMaybe<H_PriceRangeInput>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  targetGroup?: InputMaybe<Scalars["String"]>;
  targetGroup_Icontains?: InputMaybe<Scalars["String"]>;
  types?: InputMaybe<Array<InputMaybe<HealthProgramType>>>;
};

export type HealthProgramInput = {
  /** health program back ground image */
  backGroundImage?: InputMaybe<Scalars["Upload"]>;
  baselineAvailabilityHours?: InputMaybe<Scalars["Int"]>;
  benefitGroups?: InputMaybe<Array<InputMaybe<HealthProgramBenefitGroupInput>>>;
  callQualityRatingFactors?: InputMaybe<Scalars["String"]>;
  careFor?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code: Scalars["String"];
  copay?: InputMaybe<Scalars["Boolean"]>;
  copayAmount?: InputMaybe<Scalars["Float"]>;
  copayPercentage?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  exclusionOptions?: InputMaybe<Array<InputMaybe<HealthProgramExclusionOptionInput>>>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** health program icon */
  icon?: InputMaybe<Scalars["Upload"]>;
  images?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  mainSpecializationsRateFactors?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  operatorProfitSharePercentage?: InputMaybe<Scalars["Float"]>;
  payPerCallOnly?: InputMaybe<Scalars["Boolean"]>;
  payerId?: InputMaybe<Scalars["ID"]>;
  platformProfitSharePercentage?: InputMaybe<Scalars["Float"]>;
  pointsDuringNormalDemand?: InputMaybe<Scalars["Float"]>;
  pointsDuringUrgentDemand?: InputMaybe<Scalars["Float"]>;
  prescriptionEnabled?: InputMaybe<Scalars["Boolean"]>;
  priceAmount?: InputMaybe<Scalars["Float"]>;
  referralsToSpecialistsEnabled?: InputMaybe<Scalars["Boolean"]>;
  requiresIdValidation?: InputMaybe<Scalars["Boolean"]>;
  service?: InputMaybe<Scalars["String"]>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subscriptionPeriod?: InputMaybe<HealthProgramSubscriptionPeriod>;
  targetGroup?: InputMaybe<Scalars["String"]>;
  type: HealthProgramType;
  unattendedCallPenaltyFactor?: InputMaybe<Scalars["Float"]>;
  vendorProfitSharePercentage?: InputMaybe<Scalars["Float"]>;
};

export type HealthProgramMember = H_Node & {
  __typename?: "HealthProgramMember";
  active?: Maybe<Scalars["Boolean"]>;
  age?: Maybe<Scalars["Int"]>;
  backInsuranceCardPhoto?: Maybe<Scalars["String"]>;
  categoryCode?: Maybe<Scalars["String"]>;
  chronicDiseases?: Maybe<Array<Scalars["String"]>>;
  company?: Maybe<Scalars["String"]>;
  coverageBy?: Maybe<CoverageByType>;
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  dateOfBirth?: Maybe<Scalars["InstantNoTime"]>;
  disabilities?: Maybe<Array<Scalars["String"]>>;
  documentExpiryDate?: Maybe<Scalars["Instant"]>;
  email?: Maybe<Scalars["String"]>;
  employeeId?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  frontInsuranceCardPhoto?: Maybe<Scalars["String"]>;
  gender?: Maybe<H_Gender>;
  healthProgramMemberLists?: Maybe<HealthProgramMemberListCountableConnection>;
  healthProgramsByActiveMembers?: Maybe<Array<Maybe<HealthProgram>>>;
  id: Scalars["ID"];
  idType?: Maybe<H_IdTypes>;
  insuranceCategory?: Maybe<Scalars["String"]>;
  insuranceClass?: Maybe<Scalars["String"]>;
  insuranceCompanyId?: Maybe<Scalars["String"]>;
  insuranceCompanyLicenseNumber?: Maybe<Scalars["String"]>;
  insuranceId?: Maybe<Scalars["String"]>;
  insurancePolicyNumber?: Maybe<Scalars["String"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  memberType?: Maybe<Scalars["String"]>;
  membershipEnd?: Maybe<Scalars["Instant"]>;
  membershipStart?: Maybe<Scalars["Instant"]>;
  modified?: Maybe<Scalars["Instant"]>;
  nationalId?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  nationality3166Code?: Maybe<Scalars["String"]>;
  nationalityArabicDisplay?: Maybe<Scalars["String"]>;
  nationalityDisplay?: Maybe<Scalars["String"]>;
  parentNationalId?: Maybe<Scalars["String"]>;
  patient?: Maybe<Patient>;
  payer?: Maybe<Payer>;
  phone?: Maybe<Scalars["String"]>;
  relationType?: Maybe<H_RelationTypes>;
  remarks?: Maybe<Scalars["String"]>;
  sehacityId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
};

export type HealthProgramMemberHealthProgramMemberListsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramMemberHealthProgramsByActiveMembersArgs = {
  filter?: InputMaybe<HealthProgramFilterInput>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};

export type HealthProgramMemberBodyComposition = H_Node & {
  __typename?: "HealthProgramMemberBodyComposition";
  alcohol?: Maybe<Scalars["Boolean"]>;
  alcoholCupsPerDay?: Maybe<Scalars["Int"]>;
  bmi?: Maybe<Scalars["Float"]>;
  created?: Maybe<Scalars["Instant"]>;
  dietType?: Maybe<DietType>;
  exercise?: Maybe<Scalars["Boolean"]>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  heightInCM?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  member?: Maybe<HealthProgramMember>;
  modified?: Maybe<Scalars["Instant"]>;
  patient?: Maybe<Patient>;
  sleep?: Maybe<Scalars["Int"]>;
  smoking?: Maybe<Scalars["Int"]>;
  user?: Maybe<User>;
  weightInKG?: Maybe<Scalars["Float"]>;
};

export type HealthProgramMemberBodyCompositionBasic = {
  __typename?: "HealthProgramMemberBodyCompositionBasic";
  alcohol?: Maybe<Scalars["Boolean"]>;
  alcoholCupsPerDay?: Maybe<Scalars["Int"]>;
  bmi?: Maybe<Scalars["Float"]>;
  dietType?: Maybe<DietType>;
  exercise?: Maybe<Scalars["Boolean"]>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  heightInCM?: Maybe<Scalars["Float"]>;
  sleep?: Maybe<Scalars["Int"]>;
  smoking?: Maybe<Scalars["Int"]>;
  weightInKG?: Maybe<Scalars["Float"]>;
};

export type HealthProgramMemberBodyCompositionCrud = {
  __typename?: "HealthProgramMemberBodyCompositionCRUD";
  entity?: Maybe<HealthProgramMemberBodyComposition>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramMemberBodyCompositionCountableConnection = {
  __typename?: "HealthProgramMemberBodyCompositionCountableConnection";
  edges: Array<HealthProgramMemberBodyCompositionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramMemberBodyCompositionCountableEdge = {
  __typename?: "HealthProgramMemberBodyCompositionCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramMemberBodyComposition;
};

export type HealthProgramMemberBodyCompositionExerciseInput = {
  exerciseDuration: Scalars["Int"];
  exerciseDurationUnit: ExerciseDurationUnit;
  exerciseFrequencyDayWeek: Scalars["Int"];
  exerciseType: ExerciseType;
};

export type HealthProgramMemberBodyCompositionFilterInput = {
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type HealthProgramMemberBodyCompositionInput = {
  alcohol?: InputMaybe<Scalars["Boolean"]>;
  alcoholCupsPerDay?: InputMaybe<Scalars["Int"]>;
  dietType?: InputMaybe<DietType>;
  exercise?: InputMaybe<Scalars["Boolean"]>;
  exercises?: InputMaybe<Array<InputMaybe<HealthProgramMemberBodyCompositionExerciseInput>>>;
  heightInCM?: InputMaybe<Scalars["Float"]>;
  patientId?: InputMaybe<Scalars["ID"]>;
  sleep?: InputMaybe<Scalars["Int"]>;
  smoking?: InputMaybe<Scalars["Int"]>;
  userId?: InputMaybe<Scalars["ID"]>;
  weightInKG?: InputMaybe<Scalars["Float"]>;
};

export type HealthProgramMemberCrud = {
  __typename?: "HealthProgramMemberCRUD";
  details?: Maybe<Scalars["String"]>;
  entity?: Maybe<HealthProgramMember>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramMemberCountableConnection = {
  __typename?: "HealthProgramMemberCountableConnection";
  edges: Array<HealthProgramMemberCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramMemberCountableEdge = {
  __typename?: "HealthProgramMemberCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramMember;
};

export type HealthProgramMemberFilterInput = {
  chronicDiseases?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  coverageBys?: InputMaybe<Array<InputMaybe<CoverageByType>>>;
  dateOfBirth?: InputMaybe<Scalars["InstantNotFuture"]>;
  disabilities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  email?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<H_Gender>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insuranceCompanyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insuranceCompanyLicenseNumbers?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  insuranceId?: InputMaybe<Scalars["String"]>;
  insurancePolicyNumber?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  membershipEnd?: InputMaybe<H_DateTimeRangeInput>;
  membershipStart?: InputMaybe<H_DateTimeRangeInput>;
  name?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  phone?: InputMaybe<Scalars["String"]>;
  relationTypes?: InputMaybe<Array<InputMaybe<H_RelationTypes>>>;
  teamId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type HealthProgramMemberInput = {
  backInsuranceCardPhoto?: InputMaybe<Scalars["String"]>;
  categoryCode?: InputMaybe<Scalars["String"]>;
  chronicDiseases?: InputMaybe<Array<Scalars["String"]>>;
  company?: InputMaybe<Scalars["String"]>;
  coverageBy: CoverageByType;
  dateOfBirth?: InputMaybe<Scalars["InstantNotFuture"]>;
  disabilities?: InputMaybe<Array<Scalars["String"]>>;
  documentExpiryDate: Scalars["Instant"];
  email?: InputMaybe<Scalars["String"]>;
  employeeId?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  frontInsuranceCardPhoto?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<H_Gender>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  idType: H_IdTypes;
  insuranceCategory?: InputMaybe<Scalars["String"]>;
  insuranceClass?: InputMaybe<Scalars["String"]>;
  insuranceCompanyId?: InputMaybe<Scalars["ID"]>;
  insuranceCompanyLicenseNumber?: InputMaybe<Scalars["String"]>;
  insuranceId: Scalars["String"];
  insurancePolicyNumber?: InputMaybe<Scalars["String"]>;
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  memberType?: InputMaybe<Scalars["String"]>;
  membershipEnd: Scalars["Instant"];
  membershipStart: Scalars["Instant"];
  nationalId: Scalars["String"];
  nationality: Scalars["String"];
  parentNationalId?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<H_RelationTypes>;
  remarks?: InputMaybe<Scalars["String"]>;
  sehacityId?: InputMaybe<Scalars["String"]>;
};

export type HealthProgramMemberList = H_Node & {
  __typename?: "HealthProgramMemberList";
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  healthProgramMembers?: Maybe<HealthProgramMemberCountableConnection>;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  payer?: Maybe<Payer>;
};

export type HealthProgramMemberListHealthProgramMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramMemberListCrud = {
  __typename?: "HealthProgramMemberListCRUD";
  entity?: Maybe<HealthProgramMemberList>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramMemberListCountableConnection = {
  __typename?: "HealthProgramMemberListCountableConnection";
  edges: Array<HealthProgramMemberListCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramMemberListCountableEdge = {
  __typename?: "HealthProgramMemberListCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramMemberList;
};

export type HealthProgramMemberListFilterInput = {
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  members?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  payers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type HealthProgramMemberListInput = {
  description?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  payerId?: InputMaybe<Scalars["ID"]>;
};

export enum HealthProgramMemberListSortingField {
  Created = "CREATED",
  IsActive = "IS_ACTIVE",
  Name = "NAME",
  Updated = "UPDATED",
}

export type HealthProgramMemberListSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramMemberListSortingField>;
};

export type HealthProgramMemberListUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  payerId?: InputMaybe<Scalars["ID"]>;
};

export enum HealthProgramMemberSortingField {
  Created = "CREATED",
  InsuranceId = "INSURANCE_ID",
  Name = "NAME",
  NationalId = "NATIONAL_ID",
  Updated = "UPDATED",
}

export type HealthProgramMemberSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramMemberSortingField>;
};

export type HealthProgramMembershipRequest = H_Node & {
  __typename?: "HealthProgramMembershipRequest";
  backInsuranceCardPhoto?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  dateOfBirth?: Maybe<Scalars["InstantNoTime"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  frontInsuranceCardPhoto?: Maybe<Scalars["String"]>;
  gender?: Maybe<H_Gender>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  id: Scalars["ID"];
  insuranceCategory?: Maybe<Scalars["String"]>;
  insuranceClass?: Maybe<Scalars["String"]>;
  insuranceCompanyId?: Maybe<Scalars["H_Long"]>;
  insuranceCompanyLicenseNumber?: Maybe<Scalars["String"]>;
  insuranceId?: Maybe<Scalars["String"]>;
  insurancePolicyNumber?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  modified?: Maybe<Scalars["Instant"]>;
  nationalId?: Maybe<Scalars["String"]>;
  patient?: Maybe<Patient>;
  payer?: Maybe<Payer>;
  phone?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  status?: Maybe<RequestStatus>;
  user?: Maybe<User>;
};

export type HealthProgramMembershipRequestApproveInput = {
  coverageBy: CoverageByType;
  insuranceCategory?: InputMaybe<Scalars["String"]>;
  insuranceCategoryCode?: InputMaybe<Scalars["String"]>;
  insuranceClass?: InputMaybe<Scalars["String"]>;
  insurancePolicyNumber?: InputMaybe<Scalars["String"]>;
  membershipEnd: Scalars["Instant"];
  membershipStart: Scalars["Instant"];
  sehacityId?: InputMaybe<Scalars["String"]>;
};

export type HealthProgramMembershipRequestCrud = {
  __typename?: "HealthProgramMembershipRequestCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramMembershipRequest?: Maybe<HealthProgramMembershipRequest>;
  healthProgramMembershipRequestErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthProgramMembershipRequestConnection = {
  __typename?: "HealthProgramMembershipRequestConnection";
  edges: Array<HealthProgramMembershipRequestCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramMembershipRequestCountableEdge = {
  __typename?: "HealthProgramMembershipRequestCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: HealthProgramMembershipRequest;
};

export type HealthProgramMembershipRequestFilterInput = {
  coverageBys?: InputMaybe<Array<InputMaybe<CoverageByType>>>;
  insuranceCompanyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insuranceCompanyLicenseNumbers?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  insuranceId?: InputMaybe<Scalars["String"]>;
  membershipEnd?: InputMaybe<H_DateTimeRangeInput>;
  membershipStart?: InputMaybe<H_DateTimeRangeInput>;
  patientId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<RequestStatus>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type HealthProgramMembershipRequestInput = {
  backInsuranceCardPhoto: Scalars["String"];
  frontInsuranceCardPhoto: Scalars["String"];
  insuranceCompanyId: Scalars["ID"];
  insuranceId: Scalars["String"];
};

export enum HealthProgramMembershipRequestSortingField {
  Created = "CREATED",
  InsuranceId = "INSURANCE_ID",
  Name = "NAME",
  NationalId = "NATIONAL_ID",
  Updated = "UPDATED",
}

export type HealthProgramMembershipRequestSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramMembershipRequestSortingField>;
};

export type HealthProgramNetwork = H_Node & {
  __typename?: "HealthProgramNetwork";
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  healthProgramNetworkProviders?: Maybe<HealthProgramNetworkProviderCountableConnection>;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  payer?: Maybe<Payer>;
  providerCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramNetworkHealthProgramNetworkProvidersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramNetworkCrud = {
  __typename?: "HealthProgramNetworkCRUD";
  entity?: Maybe<HealthProgramNetwork>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramNetworkCountableConnection = {
  __typename?: "HealthProgramNetworkCountableConnection";
  edges: Array<HealthProgramNetworkCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramNetworkCountableEdge = {
  __typename?: "HealthProgramNetworkCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramNetwork;
};

export type HealthProgramNetworkFilterInput = {
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  payers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  providers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type HealthProgramNetworkInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  payerId?: InputMaybe<Scalars["ID"]>;
};

export type HealthProgramNetworkProvider = H_Node & {
  __typename?: "HealthProgramNetworkProvider";
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  healthProgramNetwork?: Maybe<HealthProgramNetwork>;
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  modified?: Maybe<Scalars["Instant"]>;
  provider?: Maybe<Vendor>;
  services?: Maybe<Array<Maybe<Scalars["String"]>>>;
  virtualCallEnabled?: Maybe<Scalars["Boolean"]>;
};

export type HealthProgramNetworkProviderHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramNetworkProviderCrud = {
  __typename?: "HealthProgramNetworkProviderCRUD";
  entity?: Maybe<HealthProgramNetworkProvider>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramNetworkProviderCountableConnection = {
  __typename?: "HealthProgramNetworkProviderCountableConnection";
  edges: Array<HealthProgramNetworkProviderCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramNetworkProviderCountableEdge = {
  __typename?: "HealthProgramNetworkProviderCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramNetworkProvider;
};

export type HealthProgramNetworkProviderFilterInput = {
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  professionals?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  providers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  services?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  virtualCallEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type HealthProgramNetworkProviderInput = {
  healthProgramNetwork?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  providerId?: InputMaybe<Scalars["ID"]>;
  services?: InputMaybe<Array<Scalars["String"]>>;
  virtualCallEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type HealthProgramNetworkProviderProfessional = H_Node & {
  __typename?: "HealthProgramNetworkProviderProfessional";
  created?: Maybe<Scalars["Instant"]>;
  createdByUser?: Maybe<User>;
  healthProgramNetworkProvider?: Maybe<HealthProgramNetworkProvider>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  overridingServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  overridingVirtualCallEnabled?: Maybe<Scalars["Boolean"]>;
  professional?: Maybe<Doctor>;
};

export type HealthProgramNetworkProviderProfessionalCrud = {
  __typename?: "HealthProgramNetworkProviderProfessionalCRUD";
  entity?: Maybe<HealthProgramNetworkProviderProfessional>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramNetworkProviderProfessionalCountableConnection = {
  __typename?: "HealthProgramNetworkProviderProfessionalCountableConnection";
  edges: Array<HealthProgramNetworkProviderProfessionalCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramNetworkProviderProfessionalCountableEdge = {
  __typename?: "HealthProgramNetworkProviderProfessionalCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramNetworkProviderProfessional;
};

export type HealthProgramNetworkProviderProfessionalFilterInput = {
  healthProgramNetworkProviders?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  overridingServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  overridingVirtualCallEnabled?: InputMaybe<Scalars["Boolean"]>;
  professionals?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  providers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type HealthProgramNetworkProviderProfessionalInput = {
  healthProgramNetworkProvider?: InputMaybe<Scalars["ID"]>;
  overridingServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  overridingVirtualCallEnabled?: InputMaybe<Scalars["Boolean"]>;
  professionalId?: InputMaybe<Scalars["ID"]>;
};

export enum HealthProgramNetworkSortingField {
  Code = "CODE",
  Created = "CREATED",
  IsActive = "IS_ACTIVE",
  Name = "NAME",
  Updated = "UPDATED",
}

export type HealthProgramNetworkSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramNetworkSortingField>;
};

export type HealthProgramNetworkUpdateInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  payerId?: InputMaybe<Scalars["ID"]>;
};

export enum HealthProgramOptionType {
  Include = "INCLUDE",
  Limitation = "LIMITATION",
}

export type HealthProgramPointsFilterInput = {
  doctorId?: InputMaybe<Scalars["String"]>;
  healthProgramId?: InputMaybe<Scalars["Int"]>;
  month: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export enum HealthProgramSortingField {
  Code = "CODE",
  Created = "CREATED",
  IsActive = "IS_ACTIVE",
  Name = "NAME",
  Type = "TYPE",
  Updated = "UPDATED",
}

export type HealthProgramSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramSortingField>;
};

export type HealthProgramSpecialization = H_Node & {
  __typename?: "HealthProgramSpecialization";
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  program?: Maybe<HealthProgram>;
  specialization?: Maybe<T_Specialization>;
};

/** Subscribe user to Health Program */
export type HealthProgramSubscribe = {
  __typename?: "HealthProgramSubscribe";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
  healthProgramUserSubscription?: Maybe<HealthProgramUserSubscription>;
};

export type HealthProgramSubscribeInput = {
  insuranceMemberId?: InputMaybe<Scalars["String"]>;
  period: HealthProgramSubscriptionPeriod;
  /** program id to subscribe to */
  program?: InputMaybe<Scalars["ID"]>;
};

export enum HealthProgramSubscriptionPeriod {
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
}

export type HealthProgramTemplate = H_Node & {
  __typename?: "HealthProgramTemplate";
  active?: Maybe<Scalars["Boolean"]>;
  code?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  guidedCareType?: Maybe<GuidedCareType>;
  healthCondition?: Maybe<HealthCondition>;
  id: Scalars["ID"];
  isDraft?: Maybe<Scalars["Boolean"]>;
  isLatestVersion?: Maybe<Scalars["Boolean"]>;
  isRenewable?: Maybe<Scalars["Boolean"]>;
  isVerified?: Maybe<Scalars["Boolean"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  riskFactorTemplate?: Maybe<RiskFactorTemplate>;
  standardCode?: Maybe<Scalars["String"]>;
  standardName?: Maybe<Scalars["String"]>;
  standardURL?: Maybe<Scalars["String"]>;
  templateActivities?: Maybe<Array<Maybe<TemplateActivity>>>;
  templateDuration?: Maybe<Scalars["Int"]>;
  templateGoals?: Maybe<Array<Maybe<TemplateGoal>>>;
  templateInterventions?: Maybe<Array<Maybe<TemplateIntervention>>>;
  templateTeamMembers?: Maybe<Array<Maybe<TemplateTeamMember>>>;
  version?: Maybe<Scalars["Int"]>;
};

/** CRUD a new Health Program Template */
export type HealthProgramTemplateCrud = {
  __typename?: "HealthProgramTemplateCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramTemplate?: Maybe<HealthProgramTemplate>;
  healthProgramTemplateErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthProgramTemplateCountableConnection = {
  __typename?: "HealthProgramTemplateCountableConnection";
  edges: Array<HealthProgramTemplateCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramTemplateCountableEdge = {
  __typename?: "HealthProgramTemplateCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramTemplate;
};

export type HealthProgramTemplateFilterInput = {
  creationDate?: InputMaybe<H_DateTimeRangeInput>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthConditionId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isDraft?: InputMaybe<Scalars["Boolean"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
};

export type HealthProgramTemplateInput = {
  code?: InputMaybe<Scalars["String"]>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthConditionId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  isRenewable?: InputMaybe<Scalars["Boolean"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  riskFactorTemplateId?: InputMaybe<Scalars["ID"]>;
  standardCode?: InputMaybe<Scalars["String"]>;
  standardName?: InputMaybe<Scalars["String"]>;
  standardURL?: InputMaybe<Scalars["String"]>;
  templateActivities?: InputMaybe<Array<InputMaybe<TemplateActivityInput>>>;
  templateDuration?: InputMaybe<Scalars["Int"]>;
  templateGoals?: InputMaybe<Array<InputMaybe<TemplateGoalInput>>>;
  templateInterventions?: InputMaybe<Array<InputMaybe<TemplateInterventionInput>>>;
  templateTeamMembers?: InputMaybe<Array<InputMaybe<TemplateTeamMemberInput>>>;
  version?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramTemplateInterventionCountableConnection = {
  __typename?: "HealthProgramTemplateInterventionCountableConnection";
  edges: Array<HealthProgramTemplateInterventionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramTemplateInterventionCountableEdge = {
  __typename?: "HealthProgramTemplateInterventionCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: TemplateIntervention;
};

export type HealthProgramTemplateInterventionFilterInput = {
  excludedIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  goalId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  recommendationId?: InputMaybe<Scalars["ID"]>;
  templateId?: InputMaybe<Scalars["ID"]>;
};

export enum HealthProgramTemplateSortingField {
  Created = "CREATED",
  GuidedCareType = "GUIDED_CARE_TYPE",
  Id = "ID",
  IsDraft = "IS_DRAFT",
  Modified = "MODIFIED",
  Name = "NAME",
}

export type HealthProgramTemplateSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramTemplateSortingField>;
};

export type HealthProgramTotalPointsTrackingConnection = {
  __typename?: "HealthProgramTotalPointsTrackingConnection";
  doctorId?: Maybe<Scalars["Int"]>;
  edges: Array<HealthProgramTotalPointsTrackingCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
  totalPoints?: Maybe<Scalars["Int"]>;
};

export type HealthProgramTotalPointsTrackingCountableEdge = {
  __typename?: "HealthProgramTotalPointsTrackingCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: HealthProgramTotalPointsTrackingResponse;
};

export type HealthProgramTotalPointsTrackingResponse = {
  __typename?: "HealthProgramTotalPointsTrackingResponse";
  doctor?: Maybe<Doctor>;
  healthProgramId?: Maybe<Scalars["String"]>;
  healthProgramName?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["Int"]>;
  totalPoints?: Maybe<Scalars["Int"]>;
  vendor?: Maybe<Vendor>;
};

export enum HealthProgramType {
  ImmediateCare = "IMMEDIATE_CARE",
  ScheduledCare = "SCHEDULED_CARE",
}

export type HealthProgramUnSubscribe = {
  __typename?: "HealthProgramUnSubscribe";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type HealthProgramUpdateInput = {
  /** health program back ground image */
  backGroundImage?: InputMaybe<Scalars["Upload"]>;
  baselineAvailabilityHours?: InputMaybe<Scalars["Int"]>;
  benefitGroups?: InputMaybe<Array<InputMaybe<HealthProgramBenefitGroupInput>>>;
  callQualityRatingFactors?: InputMaybe<Scalars["String"]>;
  careFor?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code: Scalars["String"];
  copay?: InputMaybe<Scalars["Boolean"]>;
  copayAmount?: InputMaybe<Scalars["Float"]>;
  copayPercentage?: InputMaybe<Scalars["Float"]>;
  description?: InputMaybe<Scalars["String"]>;
  exclusionOptions?: InputMaybe<Array<InputMaybe<HealthProgramExclusionOptionInput>>>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** health program icon */
  icon?: InputMaybe<Scalars["Upload"]>;
  images?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  mainSpecializationsRateFactors?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  operatorProfitSharePercentage?: InputMaybe<Scalars["Float"]>;
  payerId?: InputMaybe<Scalars["ID"]>;
  platformProfitSharePercentage?: InputMaybe<Scalars["Float"]>;
  pointsDuringNormalDemand?: InputMaybe<Scalars["Float"]>;
  pointsDuringUrgentDemand?: InputMaybe<Scalars["Float"]>;
  prescriptionEnabled?: InputMaybe<Scalars["Boolean"]>;
  priceAmount?: InputMaybe<Scalars["Float"]>;
  referralsToSpecialistsEnabled?: InputMaybe<Scalars["Boolean"]>;
  requiresIdValidation?: InputMaybe<Scalars["Boolean"]>;
  service?: InputMaybe<Scalars["String"]>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subscriptionPeriod?: InputMaybe<HealthProgramSubscriptionPeriod>;
  targetGroup?: InputMaybe<Scalars["String"]>;
  unattendedCallPenaltyFactor?: InputMaybe<Scalars["Float"]>;
  vendorProfitSharePercentage?: InputMaybe<Scalars["Float"]>;
};

export type HealthProgramUserSubscription = H_Node & {
  __typename?: "HealthProgramUserSubscription";
  consumerId?: Maybe<Scalars["Int"]>;
  created?: Maybe<Scalars["Instant"]>;
  currency?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insuranceMemberId?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  modified?: Maybe<Scalars["Instant"]>;
  period?: Maybe<Scalars["Int"]>;
  program?: Maybe<HealthProgram>;
  total?: Maybe<H_Money>;
  totalAmount?: Maybe<Scalars["Float"]>;
  user?: Maybe<User>;
  validTill?: Maybe<Scalars["Instant"]>;
  visits?: Maybe<VisitCountableConnection>;
};

export type HealthProgramUserSubscriptionVisitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type HealthProgramUserSubscriptionCountableConnection = {
  __typename?: "HealthProgramUserSubscriptionCountableConnection";
  edges: Array<HealthProgramUserSubscriptionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramUserSubscriptionCountableEdge = {
  __typename?: "HealthProgramUserSubscriptionCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: HealthProgramUserSubscription;
};

export type HealthProgramUserSubscriptionFilterInput = {
  consumers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgram?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  users?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type HealthProgramVendorPointsFilterInput = {
  healthProgramId?: InputMaybe<Scalars["Int"]>;
  month: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type HealthProgramVendorTotalPointsTrackingConnection = {
  __typename?: "HealthProgramVendorTotalPointsTrackingConnection";
  edges: Array<HealthProgramVendorTotalPointsTrackingCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthProgramVendorTotalPointsTrackingCountableEdge = {
  __typename?: "HealthProgramVendorTotalPointsTrackingCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: HealthProgramVendorTotalPointsTrackingResponse;
};

export type HealthProgramVendorTotalPointsTrackingResponse = {
  __typename?: "HealthProgramVendorTotalPointsTrackingResponse";
  healthProgramId?: Maybe<Scalars["String"]>;
  healthProgramName?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["Int"]>;
  profitShare?: Maybe<Scalars["Float"]>;
  totalPoints?: Maybe<Scalars["Int"]>;
  vendor?: Maybe<Vendor>;
};

export type HealthSymptom = Node & {
  __typename?: "HealthSymptom";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  specializations?: Maybe<Array<Maybe<HealthSymptomSpecialization>>>;
};

export type HealthSymptomCountableConnection = {
  __typename?: "HealthSymptomCountableConnection";
  edges: Array<HealthSymptomCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type HealthSymptomCountableEdge = {
  __typename?: "HealthSymptomCountableEdge";
  cursor: Scalars["String"];
  node: HealthSymptom;
};

export type HealthSymptomCreate = {
  __typename?: "HealthSymptomCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptomErrors: Array<HealthSymptomError>;
};

export type HealthSymptomDelete = {
  __typename?: "HealthSymptomDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptomErrors: Array<HealthSymptomError>;
};

export type HealthSymptomError = {
  __typename?: "HealthSymptomError";
  code: HealthSymptomErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum HealthSymptomErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type HealthSymptomFilterInput = {
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type HealthSymptomInput = {
  name: Scalars["String"];
  specializations?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type HealthSymptomOrder = {
  direction: OrderDirection;
  field?: InputMaybe<HealthSymptomOrderField>;
};

export enum HealthSymptomOrderField {
  Created = "CREATED",
  Name = "NAME",
}

export type HealthSymptomSpecialization = Node & {
  __typename?: "HealthSymptomSpecialization";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  healthSymptom?: Maybe<HealthSymptom>;
  id: Scalars["ID"];
};

export type HealthSymptomSpecializationDelete = {
  __typename?: "HealthSymptomSpecializationDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptomSpecialization?: Maybe<HealthSymptomSpecialization>;
  specializationErrors: Array<SpecializationError>;
};

export type HealthSymptomSpecializationsCreate = {
  __typename?: "HealthSymptomSpecializationsCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  specializationErrors: Array<SpecializationError>;
};

export type HealthSymptomUpdate = {
  __typename?: "HealthSymptomUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptomErrors: Array<HealthSymptomError>;
};

export type IdealBodyWeightInput = {
  gender: Gender;
  height: Scalars["Float"];
};

export type IdealBodyWeightInputLog = {
  __typename?: "IdealBodyWeightInputLog";
  gender: Gender;
  height: Scalars["Float"];
};

export type IdealBodyWeightResponse = {
  __typename?: "IdealBodyWeightResponse";
  idealBodyWeight?: Maybe<Scalars["Float"]>;
};

export type IdealBodyWeightResult = {
  __typename?: "IdealBodyWeightResult";
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  idealBodyWeight?: Maybe<Scalars["Double"]>;
  queryInput?: Maybe<IdealBodyWeightInputLog>;
};

export type IdealBodyWeightResultConnection = {
  __typename?: "IdealBodyWeightResultConnection";
  edges: Array<IdealBodyWeightResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type IdealBodyWeightResultEdge = {
  __typename?: "IdealBodyWeightResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IdealBodyWeightResult;
};

export type Image = {
  __typename?: "Image";
  alt?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type InsuranceNetwork = H_Node & {
  __typename?: "InsuranceNetwork";
  created?: Maybe<Scalars["Instant"]>;
  facilities?: Maybe<InsuranceNetworkFacilityCountableConnection>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  modified?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  payer?: Maybe<Payer>;
};

export type InsuranceNetworkFacilitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type InsuranceNetworkHealthProgramsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** CRUD a new InsuranceNetwork */
export type InsuranceNetworkCrud = {
  __typename?: "InsuranceNetworkCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  insuranceNetworkErrors?: Maybe<Array<H_EntityError>>;
};

export type InsuranceNetworkCountableConnection = {
  __typename?: "InsuranceNetworkCountableConnection";
  edges: Array<InsuranceNetworkCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type InsuranceNetworkCountableEdge = {
  __typename?: "InsuranceNetworkCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: InsuranceNetwork;
};

export type InsuranceNetworkFacility = H_Node & {
  __typename?: "InsuranceNetworkFacility";
  created?: Maybe<Scalars["Instant"]>;
  facility?: Maybe<Vendor>;
  facilityId?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  modified?: Maybe<Scalars["Instant"]>;
};

export type InsuranceNetworkFacilityCountableConnection = {
  __typename?: "InsuranceNetworkFacilityCountableConnection";
  edges: Array<InsuranceNetworkFacilityCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type InsuranceNetworkFacilityCountableEdge = {
  __typename?: "InsuranceNetworkFacilityCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: InsuranceNetworkFacility;
};

export type InsuranceNetworkFacilityFilterInput = {
  facilities?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  insurances?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type InsuranceNetworkFilterInput = {
  facilities?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  payer?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type InsuranceNetworkInput = {
  facilities?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** is InsuranceNetwork Active */
  isActive?: InputMaybe<Scalars["Boolean"]>;
  /** InsuranceNetwork Name */
  name?: InputMaybe<Scalars["String"]>;
  /** Payer ID. */
  payerId?: InputMaybe<Scalars["ID"]>;
};

export enum InsuranceSortField {
  /** Sort by creation date. */
  CreationDate = "CREATION_DATE",
  /** Sort by name. */
  Name = "NAME",
}

export type InsuranceSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort insurance by the selected field. */
  field: InsuranceSortField;
};

export type IntRangeInput = {
  gte?: InputMaybe<Scalars["Int"]>;
  lte?: InputMaybe<Scalars["Int"]>;
};

export type InternalEmailNotificationBody = {
  __typename?: "InternalEmailNotificationBody";
  body?: Maybe<Scalars["String"]>;
  recipientEmail?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type InternalEmailNotificationConverted = {
  __typename?: "InternalEmailNotificationConverted";
  notification?: Maybe<InternalEmailNotificationBody>;
  recipientUserId?: Maybe<Scalars["ID"]>;
};

export type InternalSmsNotificationBody = {
  __typename?: "InternalSMSNotificationBody";
  body?: Maybe<Scalars["String"]>;
  recipientMobileNumber?: Maybe<Scalars["String"]>;
};

export type InternalSmsNotificationConverted = {
  __typename?: "InternalSMSNotificationConverted";
  notification?: Maybe<InternalSmsNotificationBody>;
  recipientUserId?: Maybe<Scalars["ID"]>;
};

export enum InterventionActivationType {
  Merge = "MERGE",
  Replace = "REPLACE",
}

export type IsPrescriptionEligibleForFreeDelivery = {
  __typename?: "IsPrescriptionEligibleForFreeDelivery";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  isFreeDeliveryEligible?: Maybe<Scalars["Boolean"]>;
  prescriptionErrors: Array<PrescriptionError>;
};

export enum JourneyItemClassification {
  Essential = "ESSENTIAL",
  Supportive = "SUPPORTIVE",
}

export type KeyCloakPermission = Node & {
  __typename?: "KeyCloakPermission";
  id: Scalars["ID"];
  isAggregator: Scalars["Boolean"];
  isClient?: Maybe<Scalars["Boolean"]>;
  isStaff?: Maybe<Scalars["Boolean"]>;
  isVendor?: Maybe<Scalars["Boolean"]>;
  keycloakRoleId?: Maybe<Scalars["String"]>;
};

export type KeycloakPermissionConfigurationInput = {
  isAggregator?: InputMaybe<Scalars["Boolean"]>;
  isClient?: InputMaybe<Scalars["Boolean"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]>;
  isVendor?: InputMaybe<Scalars["Boolean"]>;
  keycloakRoleId?: InputMaybe<Scalars["String"]>;
};

export type KeycloakPermissionConfigurationUpdate = {
  __typename?: "KeycloakPermissionConfigurationUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  keyCloakPermission?: Maybe<KeyCloakPermission>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export enum KeywordSearch {
  All = "ALL",
  Code = "CODE",
  Display = "DISPLAY",
}

export type Lab = H_Node & {
  __typename?: "Lab";
  code?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  display?: Maybe<Scalars["String"]>;
  evaluatedTemplate?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  result?: Maybe<Scalars["String"]>;
  resultReceived?: Maybe<Scalars["Boolean"]>;
  visit?: Maybe<Visit>;
};

export type LabCrud = {
  __typename?: "LabCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  evaluatedLabTemplate?: Maybe<EvaluatedLabTemplate>;
  lab?: Maybe<Lab>;
  labErrors?: Maybe<Array<H_EntityError>>;
};

export type LabInput = {
  code?: InputMaybe<Scalars["String"]>;
  visitId: Scalars["ID"];
};

export type LabResultInput = {
  code: Scalars["String"];
  result: Scalars["String"];
  visitId: Scalars["ID"];
};

export type LabTemplate = {
  __typename?: "LabTemplate";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  fields?: Maybe<Array<Maybe<HealthParameter>>>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  labCodeDisplay?: Maybe<Scalars["String"]>;
};

export type LabTemplateConnection = {
  __typename?: "LabTemplateConnection";
  edges?: Maybe<Array<Maybe<LabTemplateEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type LabTemplateEdge = {
  __typename?: "LabTemplateEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<LabTemplate>;
};

export type LabTemplateFilterInput = {
  arabicDisplay?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
};

export type LabTemplateInput = {
  arabicDisplay: Scalars["String"];
  code: Scalars["String"];
  display: Scalars["String"];
  isActive: Scalars["Boolean"];
};

export enum LabTemplateSortingField {
  ArabicDisplay = "ARABIC_DISPLAY",
  Code = "CODE",
  CreatedDate = "CREATED_DATE",
  Display = "DISPLAY",
  IsActive = "IS_ACTIVE",
}

export type LabTemplateSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: LabTemplateSortingField;
};

export type LabTemplateUpdateInput = {
  arabicDisplay: Scalars["String"];
  display: Scalars["String"];
  isActive: Scalars["Boolean"];
};

export type Language = Node & {
  __typename?: "Language";
  code: Scalars["String"];
  display: Scalars["String"];
  displayAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export enum LanguageCodeEnum {
  Ar = "AR",
  Az = "AZ",
  Bg = "BG",
  Bn = "BN",
  Ca = "CA",
  Cs = "CS",
  Da = "DA",
  De = "DE",
  El = "EL",
  En = "EN",
  Es = "ES",
  EsCo = "ES_CO",
  Et = "ET",
  Fa = "FA",
  Fi = "FI",
  Fr = "FR",
  Hi = "HI",
  Hu = "HU",
  Hy = "HY",
  Id = "ID",
  Is = "IS",
  It = "IT",
  Ja = "JA",
  Ko = "KO",
  Lt = "LT",
  Mn = "MN",
  Nb = "NB",
  Nl = "NL",
  Pl = "PL",
  Pt = "PT",
  PtBr = "PT_BR",
  Ro = "RO",
  Ru = "RU",
  Sk = "SK",
  Sl = "SL",
  Sq = "SQ",
  Sr = "SR",
  Sv = "SV",
  Sw = "SW",
  Ta = "TA",
  Th = "TH",
  Tr = "TR",
  Uk = "UK",
  Vi = "VI",
  ZhHans = "ZH_HANS",
  ZhHant = "ZH_HANT",
}

export type LanguageCountableConnection = {
  __typename?: "LanguageCountableConnection";
  edges: Array<LanguageCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type LanguageCountableEdge = {
  __typename?: "LanguageCountableEdge";
  cursor: Scalars["String"];
  node: Language;
};

export type LanguageCreate = {
  __typename?: "LanguageCreate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  language?: Maybe<Language>;
};

export type LanguageCreateInput = {
  code: Scalars["String"];
  display: Scalars["String"];
  displayAr?: InputMaybe<Scalars["String"]>;
};

export type LanguageDisplay = {
  __typename?: "LanguageDisplay";
  code: LanguageCodeEnum;
  language: Scalars["String"];
};

export type LanguageFilterInput = {
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
};

export type LanguageUpdate = {
  __typename?: "LanguageUpdate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  language?: Maybe<Language>;
};

export type LanguageUpdateInput = {
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
  displayAr?: InputMaybe<Scalars["String"]>;
};

export type LifeExpectancyInput = {
  age: Scalars["Int"];
  dietQuality?: InputMaybe<DietQuality>;
  educationLevel?: InputMaybe<EducationLevel>;
  gender: Gender;
  physicalActivity?: InputMaybe<ActivityLevel>;
  smokingHabit?: InputMaybe<SmokingHabit>;
  stressLevel?: InputMaybe<RiskLevel>;
};

export type LifeExpectancyInputLog = {
  __typename?: "LifeExpectancyInputLog";
  age: Scalars["Int"];
  dietQuality?: Maybe<DietQuality>;
  educationLevel?: Maybe<EducationLevel>;
  gender: Gender;
  physicalActivity?: Maybe<ActivityLevel>;
  smokingHabit?: Maybe<SmokingHabit>;
  stressLevel?: Maybe<RiskLevel>;
};

export type LifeExpectancyResponse = {
  __typename?: "LifeExpectancyResponse";
  averageAge?: Maybe<Scalars["Float"]>;
  lifeExpectancy?: Maybe<Scalars["Float"]>;
};

export type LifeExpectancyResult = {
  __typename?: "LifeExpectancyResult";
  averageAge?: Maybe<Scalars["Int"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  lifeExpectancy?: Maybe<Scalars["Double"]>;
  queryInput?: Maybe<LifeExpectancyInputLog>;
};

export type LifeExpectancyResultConnection = {
  __typename?: "LifeExpectancyResultConnection";
  edges: Array<LifeExpectancyResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type LifeExpectancyResultEdge = {
  __typename?: "LifeExpectancyResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: LifeExpectancyResult;
};

export type LocationInput = {
  lat: Scalars["Float"];
  lng: Scalars["Float"];
};

export type LocationType = {
  __typename?: "LocationType";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

export type Manager = Node & {
  __typename?: "Manager";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export enum MaritalStatusesEnum {
  Divorced = "DIVORCED",
  Married = "MARRIED",
  Single = "SINGLE",
  Widowed = "WIDOWED",
}

export type MarkAllNotificationsAsSeenResponse = {
  __typename?: "MarkAllNotificationsAsSeenResponse";
  errors?: Maybe<Array<Maybe<SubscriptionGraphqlError>>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type MarketplaceAddress = {
  __typename?: "MarketplaceAddress";
  area?: Maybe<Scalars["String"]>;
  areaAr?: Maybe<Scalars["String"]>;
  buildingName?: Maybe<Scalars["String"]>;
  buildingNameAr?: Maybe<Scalars["String"]>;
  buildingNumber?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  cityAr?: Maybe<Scalars["String"]>;
  coordinates?: Maybe<Scalars["String"]>;
  district?: Maybe<Scalars["String"]>;
  districtAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  regionAr?: Maybe<Scalars["String"]>;
  streetAddress1?: Maybe<Scalars["String"]>;
  streetAddress1Ar?: Maybe<Scalars["String"]>;
  streetAddress2?: Maybe<Scalars["String"]>;
  streetAddress2Ar?: Maybe<Scalars["String"]>;
  unitNumber?: Maybe<Scalars["String"]>;
};

export enum MarketplaceApprovalStatus {
  Approved = "APPROVED",
  Draft = "DRAFT",
  PendingApproval = "PENDING_APPROVAL",
  Rejected = "REJECTED",
}

export type MarketplaceBrand = {
  __typename?: "MarketplaceBrand";
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
};

export type MarketplaceBrandConnection = {
  __typename?: "MarketplaceBrandConnection";
  edges: Array<MarketplaceBrandEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceBrandEdge = {
  __typename?: "MarketplaceBrandEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceBrand;
};

export type MarketplaceBrandFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
};

export type MarketplaceBrandInput = {
  image: Scalars["String"];
  name: Scalars["String"];
  nameAr: Scalars["String"];
};

export type MarketplaceCancellationReasonFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type MarketplaceCart = {
  __typename?: "MarketplaceCart";
  id: Scalars["ID"];
  items: Array<MarketplaceCartItem>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusFromPaymentLogs?: Maybe<PaymentStatus>;
  totalDeliveryPrice: Scalars["Double"];
  totalDiscount: Scalars["Double"];
  totalPrice: Scalars["Double"];
  totalPriceWithVAT: Scalars["Double"];
  totalVat: Scalars["Double"];
  user: User;
};

export type MarketplaceCartPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceCartPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceCartInput = {
  healthPackageId?: InputMaybe<Scalars["ID"]>;
  productId?: InputMaybe<Scalars["ID"]>;
  quantity: Scalars["Int"];
};

export type MarketplaceCartItem = {
  __typename?: "MarketplaceCartItem";
  healthPackage?: Maybe<MarketplaceHealthPackage>;
  id: Scalars["ID"];
  product?: Maybe<MarketplaceProduct>;
  quantity: Scalars["Int"];
};

export type MarketplaceCategoryFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  parent?: InputMaybe<Scalars["Boolean"]>;
  parentId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplaceCategoryInput = {
  description: Scalars["String"];
  descriptionAr: Scalars["String"];
  image: Scalars["String"];
  name: Scalars["String"];
  nameAr: Scalars["String"];
  parentId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplaceCheckoutCompleteInput = {
  addressId?: InputMaybe<Scalars["ID"]>;
  cartId: Scalars["ID"];
  paymentMethod: MarketplacePaymentMethod;
};

export enum MarketplaceContentType {
  Html = "HTML",
  Md = "MD",
  Text = "TEXT",
}

export enum MarketplaceDefaultSortingField {
  Created = "CREATED",
  Name = "NAME",
  NameAr = "NAME_AR",
}

export type MarketplaceDefaultSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceDefaultSortingField>;
};

export type MarketplaceDiscount = {
  __typename?: "MarketplaceDiscount";
  amount?: Maybe<Scalars["Double"]>;
  endDate: Scalars["Instant"];
  healthPackages?: Maybe<Array<Maybe<MarketplaceHealthPackage>>>;
  id: Scalars["ID"];
  isPublished: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["Int"]>;
  products?: Maybe<Array<Maybe<MarketplaceProduct>>>;
  startDate: Scalars["Instant"];
  type: MarketplaceDiscountType;
  vendor: Vendor;
};

export type MarketplaceDiscountConnection = {
  __typename?: "MarketplaceDiscountConnection";
  edges: Array<MarketplaceDiscountEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceDiscountCreateInput = {
  amount?: InputMaybe<Scalars["Double"]>;
  endDate: Scalars["Instant"];
  healthPackageIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  percentage?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  startDate: Scalars["Instant"];
  type: MarketplaceDiscountType;
};

export type MarketplaceDiscountEdge = {
  __typename?: "MarketplaceDiscountEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceDiscount;
};

export type MarketplaceDiscountFilterInput = {
  endDate?: InputMaybe<Scalars["Instant"]>;
  healthPackageId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  productId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  startDate?: InputMaybe<Scalars["Instant"]>;
};

export enum MarketplaceDiscountSortingField {
  Amount = "AMOUNT",
  Created = "CREATED",
  EndDate = "END_DATE",
  Name = "NAME",
  Percentage = "PERCENTAGE",
  Published = "PUBLISHED",
  StartDate = "START_DATE",
  Type = "TYPE",
}

export type MarketplaceDiscountSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceDiscountSortingField>;
};

export enum MarketplaceDiscountType {
  Fixed = "FIXED",
  Percentage = "PERCENTAGE",
}

export type MarketplaceDiscountUpdateInput = {
  amount?: InputMaybe<Scalars["Double"]>;
  endDate?: InputMaybe<Scalars["Instant"]>;
  name?: InputMaybe<Scalars["String"]>;
  percentage?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Instant"]>;
  type?: InputMaybe<MarketplaceDiscountType>;
};

export enum MarketplaceGender {
  All = "ALL",
  Female = "FEMALE",
  Male = "MALE",
}

export type MarketplaceHealthPackage = {
  __typename?: "MarketplaceHealthPackage";
  activeDiscount?: Maybe<MarketplaceDiscount>;
  approvalStatus?: Maybe<MarketplaceApprovalStatus>;
  category?: Maybe<MarketplaceHealthPackageCategory>;
  contentType?: Maybe<MarketplaceContentType>;
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isPublished: Scalars["Boolean"];
  mainImageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Double"]>;
  priceWithVAT?: Maybe<Scalars["Double"]>;
  priceWithVatAfterDiscount?: Maybe<Scalars["Double"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  reviewSummary?: Maybe<MarketplaceReviewSummary>;
  reviews?: Maybe<MarketplaceReviewConnection>;
  shortDescription?: Maybe<Scalars["String"]>;
  shortDescriptionAr?: Maybe<Scalars["String"]>;
  targetFromAge?: Maybe<Scalars["Int"]>;
  targetGender?: Maybe<MarketplaceGender>;
  targetToAge?: Maybe<Scalars["Int"]>;
  updatable?: Maybe<Scalars["Boolean"]>;
  vendor: Vendor;
};

export type MarketplaceHealthPackageReviewsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceHealthPackageCategory = {
  __typename?: "MarketplaceHealthPackageCategory";
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  parent?: Maybe<MarketplaceHealthPackageCategory>;
};

export type MarketplaceHealthPackageCategoryConnection = {
  __typename?: "MarketplaceHealthPackageCategoryConnection";
  edges: Array<MarketplaceHealthPackageCategoryEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceHealthPackageCategoryEdge = {
  __typename?: "MarketplaceHealthPackageCategoryEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceHealthPackageCategory;
};

export type MarketplaceHealthPackageConnection = {
  __typename?: "MarketplaceHealthPackageConnection";
  edges: Array<MarketplaceHealthPackageEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceHealthPackageEdge = {
  __typename?: "MarketplaceHealthPackageEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceHealthPackage;
};

export type MarketplaceHealthPackageFilterInput = {
  approvalStatuses?: InputMaybe<Array<InputMaybe<MarketplaceApprovalStatus>>>;
  categoryId?: InputMaybe<Scalars["ID"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  priceRange?: InputMaybe<MarketplacePriceRangeInput>;
  search?: InputMaybe<Scalars["String"]>;
  targetAge?: InputMaybe<Scalars["Int"]>;
  targetGender?: InputMaybe<MarketplaceGender>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplaceHealthPackageInput = {
  categoryId: Scalars["ID"];
  description: Scalars["String"];
  descriptionAr: Scalars["String"];
  mainImageUrl: Scalars["String"];
  name: Scalars["String"];
  nameAr: Scalars["String"];
  price: Scalars["Double"];
  shortDescription: Scalars["String"];
  shortDescriptionAr: Scalars["String"];
  targetFromAge: Scalars["Int"];
  targetGender?: InputMaybe<MarketplaceGender>;
  targetToAge: Scalars["Int"];
};

export enum MarketplaceHealthPackageSortingField {
  Created = "CREATED",
  Name = "NAME",
  NameAr = "NAME_AR",
  Price = "PRICE",
}

export type MarketplaceHealthPackageSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceHealthPackageSortingField>;
};

export type MarketplaceOrder = {
  __typename?: "MarketplaceOrder";
  address?: Maybe<MarketplaceAddress>;
  cancellationReason?: Maybe<Scalars["String"]>;
  cartTokenId: Scalars["String"];
  createdDate: Scalars["Instant"];
  id: Scalars["ID"];
  orderLines: Array<MarketplaceOrderLine>;
  paymentMethod: MarketplacePaymentMethod;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusFromPaymentLogs?: Maybe<PaymentStatus>;
  rejectionReason?: Maybe<Scalars["String"]>;
  status: MarketplaceOrderStatus;
  totalDeliveryPrice: Scalars["Double"];
  totalDiscount?: Maybe<Scalars["Double"]>;
  totalNetAmount: Scalars["Double"];
  totalNetAmountWithVAT: Scalars["Double"];
  type: MarketplaceOrderType;
  user: User;
  vendor: Vendor;
};

export type MarketplaceOrderPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceOrderPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceOrderConnection = {
  __typename?: "MarketplaceOrderConnection";
  edges: Array<MarketplaceOrderEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceOrderEdge = {
  __typename?: "MarketplaceOrderEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceOrder;
};

export type MarketplaceOrderFilterInput = {
  cartTokenId?: InputMaybe<Scalars["ID"]>;
  fromDate?: InputMaybe<Scalars["Instant"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  statuses?: InputMaybe<Array<InputMaybe<MarketplaceOrderStatus>>>;
  toDate?: InputMaybe<Scalars["Instant"]>;
  type?: InputMaybe<MarketplaceOrderType>;
  userId?: InputMaybe<Scalars["ID"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplaceOrderLine = {
  __typename?: "MarketplaceOrderLine";
  healthPackage?: Maybe<MarketplaceHealthPackage>;
  id: Scalars["ID"];
  product?: Maybe<MarketplaceProduct>;
  quantity: Scalars["Int"];
  unitPriceDiscount?: Maybe<Scalars["Double"]>;
  unitPriceNetAmount: Scalars["Double"];
  unitPriceWithVAT: Scalars["Double"];
};

export enum MarketplaceOrderSortingField {
  Created = "CREATED",
  PaymentMethod = "PAYMENT_METHOD",
  Status = "STATUS",
  Type = "TYPE",
}

export type MarketplaceOrderSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceOrderSortingField>;
};

export enum MarketplaceOrderStatus {
  Accepted = "ACCEPTED",
  Cancelled = "CANCELLED",
  Consumed = "CONSUMED",
  Delivered = "DELIVERED",
  New = "NEW",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Rejected = "REJECTED",
}

export enum MarketplaceOrderType {
  HealthPackage = "HEALTH_PACKAGE",
  Product = "PRODUCT",
}

export enum MarketplacePaymentMethod {
  CreditWhenArrive = "CREDIT_WHEN_ARRIVE",
  PayNow = "PAY_NOW",
}

export type MarketplacePriceRangeInput = {
  max?: InputMaybe<Scalars["Double"]>;
  min?: InputMaybe<Scalars["Double"]>;
};

export type MarketplaceProduct = {
  __typename?: "MarketplaceProduct";
  activeDiscount?: Maybe<MarketplaceDiscount>;
  approvalStatus?: Maybe<MarketplaceApprovalStatus>;
  brand?: Maybe<MarketplaceBrand>;
  category?: Maybe<MarketplaceProductCategory>;
  contentType?: Maybe<MarketplaceContentType>;
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  howToUse?: Maybe<Scalars["String"]>;
  howToUseAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isPublished: Scalars["Boolean"];
  mainImageUrl?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Double"]>;
  priceWithVAT?: Maybe<Scalars["Double"]>;
  priceWithVatAfterDiscount?: Maybe<Scalars["Double"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  relatedProducts?: Maybe<MarketplaceProductConnection>;
  returnPolicy?: Maybe<Scalars["String"]>;
  returnPolicyAr?: Maybe<Scalars["String"]>;
  returnPolicyDays?: Maybe<Scalars["Int"]>;
  returnable?: Maybe<Scalars["Boolean"]>;
  reviewSummary?: Maybe<MarketplaceReviewSummary>;
  reviews?: Maybe<MarketplaceReviewConnection>;
  shortDescription?: Maybe<Scalars["String"]>;
  shortDescriptionAr?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  stockQuantity: Scalars["Int"];
  subImages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  updatable?: Maybe<Scalars["Boolean"]>;
  vendor: Vendor;
};

export type MarketplaceProductRelatedProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceProductReviewsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceProductCategory = {
  __typename?: "MarketplaceProductCategory";
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  parent?: Maybe<MarketplaceProductCategory>;
};

export type MarketplaceProductCategoryConnection = {
  __typename?: "MarketplaceProductCategoryConnection";
  edges: Array<MarketplaceProductCategoryEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceProductCategoryEdge = {
  __typename?: "MarketplaceProductCategoryEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceProductCategory;
};

export type MarketplaceProductConnection = {
  __typename?: "MarketplaceProductConnection";
  edges: Array<MarketplaceProductEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceProductEdge = {
  __typename?: "MarketplaceProductEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceProduct;
};

export type MarketplaceProductFilterInput = {
  approvalStatuses?: InputMaybe<Array<InputMaybe<MarketplaceApprovalStatus>>>;
  brandIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  priceRange?: InputMaybe<MarketplacePriceRangeInput>;
  search?: InputMaybe<Scalars["String"]>;
  /** showOutOfStock is true by default, set it to false to hide out of stock products */
  showOutOfStock?: InputMaybe<Scalars["Boolean"]>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplaceProductInput = {
  brandId: Scalars["ID"];
  categoryId: Scalars["ID"];
  description: Scalars["String"];
  descriptionAr: Scalars["String"];
  howToUse?: InputMaybe<Scalars["String"]>;
  howToUseAr?: InputMaybe<Scalars["String"]>;
  mainImageUrl: Scalars["String"];
  name: Scalars["String"];
  nameAr: Scalars["String"];
  price: Scalars["Double"];
  returnPolicy?: InputMaybe<Scalars["String"]>;
  returnPolicyAr?: InputMaybe<Scalars["String"]>;
  returnPolicyDays?: InputMaybe<Scalars["Int"]>;
  returnable: Scalars["Boolean"];
  shortDescription: Scalars["String"];
  shortDescriptionAr: Scalars["String"];
  sku: Scalars["String"];
  stockQuantity: Scalars["Int"];
  subImages?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum MarketplaceProductSortingField {
  Created = "CREATED",
  Name = "NAME",
  NameAr = "NAME_AR",
  Price = "PRICE",
  Published = "PUBLISHED",
}

export type MarketplaceProductSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceProductSortingField>;
};

export type MarketplacePromotion = {
  __typename?: "MarketplacePromotion";
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["Instant"]>;
  healthPackageCategories?: Maybe<Array<Maybe<MarketplaceHealthPackageCategory>>>;
  healthPackages?: Maybe<Array<Maybe<MarketplaceHealthPackage>>>;
  id: Scalars["ID"];
  isPublished: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  photo?: Maybe<Scalars["String"]>;
  productCategories?: Maybe<Array<Maybe<MarketplaceProductCategory>>>;
  products?: Maybe<Array<Maybe<MarketplaceProduct>>>;
  startDate?: Maybe<Scalars["Instant"]>;
  vendor: Vendor;
};

export type MarketplacePromotionConnection = {
  __typename?: "MarketplacePromotionConnection";
  edges: Array<MarketplacePromotionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplacePromotionEdge = {
  __typename?: "MarketplacePromotionEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplacePromotion;
};

export type MarketplacePromotionFilterInput = {
  endDate?: InputMaybe<Scalars["Instant"]>;
  healthPackageCategoryIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthPackageIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  startDate?: InputMaybe<Scalars["Instant"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplacePromotionInput = {
  description: Scalars["String"];
  endDate: Scalars["Instant"];
  healthPackageCategoryIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthPackageIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name: Scalars["String"];
  photo: Scalars["String"];
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  startDate: Scalars["Instant"];
};

export enum MarketplacePromotionSortingField {
  Created = "CREATED",
  EndDate = "END_DATE",
  Name = "NAME",
  Published = "PUBLISHED",
  StartDate = "START_DATE",
}

export type MarketplacePromotionSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplacePromotionSortingField>;
};

export type MarketplaceRatingCount = {
  __typename?: "MarketplaceRatingCount";
  count: Scalars["Int"];
  rating: Scalars["Int"];
};

export type MarketplaceRejectionReasonFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type MarketplaceReview = {
  __typename?: "MarketplaceReview";
  comment?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  rating: Scalars["Int"];
  user: User;
};

export type MarketplaceReviewConnection = {
  __typename?: "MarketplaceReviewConnection";
  edges: Array<MarketplaceReviewEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceReviewEdge = {
  __typename?: "MarketplaceReviewEdge";
  cursor?: Maybe<Scalars["String"]>;
  node: MarketplaceReview;
};

export type MarketplaceReviewFilterInput = {
  hasComment?: InputMaybe<Scalars["Boolean"]>;
  healthPackageId?: InputMaybe<Scalars["ID"]>;
  productId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MarketplaceReviewInput = {
  healthPackageId?: InputMaybe<Scalars["ID"]>;
  productId?: InputMaybe<Scalars["ID"]>;
  rating: Scalars["Int"];
  review?: InputMaybe<Scalars["String"]>;
};

export type MarketplaceReviewRatingCount = {
  __typename?: "MarketplaceReviewRatingCount";
  count: Scalars["Int"];
  rating: Scalars["Int"];
};

export type MarketplaceReviewSummary = {
  __typename?: "MarketplaceReviewSummary";
  avgRating: Scalars["Double"];
  ratingCounts: Array<MarketplaceReviewRatingCount>;
  total: Scalars["Int"];
};

export type MarketplaceReviewsAvg = {
  __typename?: "MarketplaceReviewsAvg";
  avgRating: Scalars["Double"];
  reviewRatingCountDTOS: Array<MarketplaceRatingCount>;
};

export enum MarketplaceSortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type MarketplaceWishlist = {
  __typename?: "MarketplaceWishlist";
  id: Scalars["ID"];
  items: Array<MarketplaceWishlistItems>;
  user: User;
};

export type MarketplaceWishlistInput = {
  healthPackageId?: InputMaybe<Scalars["ID"]>;
  productId?: InputMaybe<Scalars["ID"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type MarketplaceWishlistItems = {
  __typename?: "MarketplaceWishlistItems";
  healthPackage?: Maybe<MarketplaceHealthPackage>;
  id: Scalars["ID"];
  product?: Maybe<MarketplaceProduct>;
  quantity: Scalars["Int"];
};

export type MedicalForm = Node & {
  __typename?: "MedicalForm";
  MedicalFormAnswers?: Maybe<Array<Maybe<MedicalFormAnswer>>>;
  active?: Maybe<Scalars["Boolean"]>;
  category?: Maybe<MedicalFormCategory>;
  code?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  department?: Maybe<Department>;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<MedicalFormGraphqlError>>>;
  id: Scalars["ID"];
  medicalFormClassifications?: Maybe<Array<Maybe<MedicalFormClassification>>>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  questions?: Maybe<Array<Question>>;
  type?: Maybe<MedicalFormType>;
  updateDate?: Maybe<Scalars["Date"]>;
  vendor?: Maybe<Vendor>;
  welcomeCard?: Maybe<Scalars["String"]>;
  welcomeCardAr?: Maybe<Scalars["String"]>;
};

export type MedicalFormAnswer = Node & {
  __typename?: "MedicalFormAnswer";
  classificationName?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  medicalForm?: Maybe<MedicalForm>;
  medicalFormClassification?: Maybe<MedicalFormClassification>;
  patient?: Maybe<Patient>;
  questionAnswers?: Maybe<Array<Maybe<QuestionAnswerExtended>>>;
  score?: Maybe<Scalars["Int"]>;
};

export type MedicalFormAnswerConnection = {
  __typename?: "MedicalFormAnswerConnection";
  edges?: Maybe<Array<Maybe<MedicalFormAnswerEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MedicalFormAnswerEdge = {
  __typename?: "MedicalFormAnswerEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<MedicalFormAnswer>;
};

export type MedicalFormAnswerFilter = {
  guidedCareHealthPrograms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  guidedCareJourneyItems?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  medicalForms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export enum MedicalFormCategory {
  Condition = "CONDITION",
  Disease = "DISEASE",
  General = "GENERAL",
  Procedure = "PROCEDURE",
}

export type MedicalFormClassification = Node & {
  __typename?: "MedicalFormClassification";
  createdBy?: Maybe<User>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  rangeFrom?: Maybe<Scalars["Int"]>;
  rangeTo?: Maybe<Scalars["Int"]>;
};

export type MedicalFormClassificationInput = {
  name: Scalars["String"];
  rangeFrom: Scalars["Int"];
  rangeTo: Scalars["Int"];
};

export type MedicalFormClassificationToInterventionLink = H_Node & {
  __typename?: "MedicalFormClassificationToInterventionLink";
  classification?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  intervention?: Maybe<TemplateIntervention>;
};

export type MedicalFormClassificationToInterventionLinkList = {
  classification: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
  intervention?: InputMaybe<TemplateInterventionInputLight>;
};

export type MedicalFormConnection = {
  __typename?: "MedicalFormConnection";
  edges?: Maybe<Array<Maybe<MedicalFormEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MedicalFormEdge = {
  __typename?: "MedicalFormEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<MedicalForm>;
};

export type MedicalFormFilter = {
  category?: InputMaybe<MedicalFormCategory>;
  codes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdminForm?: InputMaybe<Scalars["Boolean"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  names?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  namesAr?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  questions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  type?: InputMaybe<MedicalFormType>;
};

export type MedicalFormGraphqlError = {
  __typename?: "MedicalFormGraphqlError";
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type MedicalFormInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category: MedicalFormCategory;
  code: Scalars["String"];
  departmentId: Scalars["ID"];
  description: Scalars["String"];
  medicalFormClassifications?: InputMaybe<Array<MedicalFormClassificationInput>>;
  name: Scalars["String"];
  nameAr: Scalars["String"];
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  type: MedicalFormType;
  welcomeCard?: InputMaybe<Scalars["String"]>;
  welcomeCardAr?: InputMaybe<Scalars["String"]>;
};

export enum MedicalFormSortingField {
  Active = "ACTIVE",
  Category = "CATEGORY",
  CreatedDate = "CREATED_DATE",
  Name = "NAME",
  NameAr = "NAME_AR",
  Type = "TYPE",
  UpdatedDate = "UPDATED_DATE",
}

export type MedicalFormSortingInput = {
  direction: SortDirection;
  field: MedicalFormSortingField;
};

export enum MedicalFormType {
  Administrative = "ADMINISTRATIVE",
  Assessment = "ASSESSMENT",
  History = "HISTORY",
  Prem = "PREM",
  Prom = "PROM",
  Quizzes = "QUIZZES",
}

export type MedicalMessage = H_Node & {
  __typename?: "MedicalMessage";
  code?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  headerImage?: Maybe<Scalars["String"]>;
  healthCondition?: Maybe<HealthCondition>;
  id: Scalars["ID"];
  isPublished?: Maybe<Scalars["Boolean"]>;
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  medicalMessageCategory?: Maybe<MedicalMessageCategory>;
  medicalMessageType?: Maybe<MedicalMessageType>;
  messages?: Maybe<Array<Maybe<MedicalMessageVariant>>>;
  name?: Maybe<Scalars["String"]>;
  readCounter?: Maybe<Scalars["Int"]>;
  sentCounter?: Maybe<Scalars["Int"]>;
};

/** CRUD a new Medical message. */
export type MedicalMessageCrud = {
  __typename?: "MedicalMessageCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  medicalMessage?: Maybe<MedicalMessage>;
  medicalMessageErrors?: Maybe<Array<H_EntityError>>;
};

export enum MedicalMessageCategory {
  General = "GENERAL",
  Instruction = "INSTRUCTION",
  Medical = "MEDICAL",
  Monitoring = "MONITORING",
  Reminder = "REMINDER",
}

export type MedicalMessageCountableConnection = {
  __typename?: "MedicalMessageCountableConnection";
  edges: Array<MedicalMessageCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type MedicalMessageCountableEdge = {
  __typename?: "MedicalMessageCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: MedicalMessage;
};

export type MedicalMessageFilterInput = {
  category?: InputMaybe<MedicalMessageCategory>;
  code?: InputMaybe<Scalars["String"]>;
  healthConditionId?: InputMaybe<Scalars["ID"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameContains?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MedicalMessageType>;
};

export type MedicalMessageInput = {
  code?: InputMaybe<Scalars["String"]>;
  headerImage?: InputMaybe<Scalars["String"]>;
  healthConditionId?: InputMaybe<Scalars["ID"]>;
  medicalMessageCategory?: InputMaybe<MedicalMessageCategory>;
  medicalMessageType?: InputMaybe<MedicalMessageType>;
  name?: InputMaybe<Scalars["String"]>;
};

export enum MedicalMessageSortField {
  /** Sort by creation date. */
  CreatedDate = "CREATED_DATE",
  /** Sort by update date. */
  LastModifiedDate = "LAST_MODIFIED_DATE",
  /** Sort by name. */
  Name = "NAME",
}

export type MedicalMessageSortingInput = {
  /** Specifies the direction in which to sort Medical Messages. */
  direction: H_OrderDirection;
  /** Sort medical messages by the selected field. */
  field: MedicalMessageSortField;
};

export enum MedicalMessageType {
  Article = "ARTICLE",
  Text = "TEXT",
  Video = "VIDEO",
}

export type MedicalMessageVariant = H_Node & {
  __typename?: "MedicalMessageVariant";
  article?: Maybe<Article>;
  bodyArabic?: Maybe<Scalars["String"]>;
  bodyEnglish?: Maybe<Scalars["String"]>;
  buttonTextArabic?: Maybe<Scalars["String"]>;
  buttonTextEnglish?: Maybe<Scalars["String"]>;
  contentURLArabic?: Maybe<Scalars["String"]>;
  contentURLEnglish?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  extraData?: Maybe<Scalars["String"]>;
  headerImage?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  presentedTitleArabic?: Maybe<Scalars["String"]>;
  presentedTitleEnglish?: Maybe<Scalars["String"]>;
  readCounter?: Maybe<Scalars["Int"]>;
  sentCounter?: Maybe<Scalars["Int"]>;
};

/** CRUD a new Medical message variant. */
export type MedicalMessageVariantCrud = {
  __typename?: "MedicalMessageVariantCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  medicalMessageVariant?: Maybe<MedicalMessageVariant>;
  medicalMessageVariantErrors?: Maybe<Array<H_EntityError>>;
};

export type MedicalMessageVariantCountableConnection = {
  __typename?: "MedicalMessageVariantCountableConnection";
  edges: Array<MedicalMessageVariantCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type MedicalMessageVariantCountableEdge = {
  __typename?: "MedicalMessageVariantCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: MedicalMessageVariant;
};

export type MedicalMessageVariantFilterInput = {
  buttonText?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  medicalMessageId?: InputMaybe<Scalars["ID"]>;
  title?: InputMaybe<Scalars["String"]>;
  titleContains?: InputMaybe<Scalars["String"]>;
};

export type MedicalMessageVariantInput = {
  articleId?: InputMaybe<Scalars["ID"]>;
  bodyArabic?: InputMaybe<Scalars["String"]>;
  bodyEnglish?: InputMaybe<Scalars["String"]>;
  buttonTextArabic?: InputMaybe<Scalars["String"]>;
  buttonTextEnglish?: InputMaybe<Scalars["String"]>;
  contentURLArabic?: InputMaybe<Scalars["String"]>;
  contentURLEnglish?: InputMaybe<Scalars["String"]>;
  extraData?: InputMaybe<Scalars["String"]>;
  headerImage?: InputMaybe<Scalars["String"]>;
  /** ID of Medical Message to add variant to */
  medicalMessageID?: InputMaybe<Scalars["H_Long"]>;
  presentedTitleArabic?: InputMaybe<Scalars["String"]>;
  /** Body of Variant */
  presentedTitleEnglish?: InputMaybe<Scalars["String"]>;
};

export enum MedicalMessageVariantSortField {
  /** Sort by creation date. */
  CreatedDate = "CREATED_DATE",
  Id = "ID",
  IsActive = "IS_ACTIVE",
  /** Sort by update date. */
  LastModifiedDate = "LAST_MODIFIED_DATE",
}

export type MedicalMessageVariantSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: MedicalMessageVariantSortField;
};

export type Medication = Node & {
  __typename?: "Medication";
  arabicInstructions: Scalars["String"];
  code: Scalars["String"];
  created: Scalars["DateTime"];
  dispenseCount: Scalars["Int"];
  display?: Maybe<Scalars["String"]>;
  downloadAction: MedicationDownloadAction;
  downloadActionDate: Scalars["DateTime"];
  duration: Scalars["String"];
  genericDrugProperties?: Maybe<GenericDrugProperties>;
  id: Scalars["ID"];
  instructions: Scalars["String"];
  lastDispenseDate?: Maybe<Scalars["DateTime"]>;
  modified: Scalars["DateTime"];
  orderLines: OrderLineCountableConnection;
  prescription: Prescription;
  quantity: Scalars["Float"];
  refills: Scalars["Int"];
  remainingDaysToNextRefill?: Maybe<Scalars["Int"]>;
  roaArName?: Maybe<Scalars["String"]>;
  roaEnName?: Maybe<Scalars["String"]>;
  routOfAdmin: Scalars["String"];
  startDate: Scalars["String"];
  tradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
  type: Scalars["String"];
  unitId?: Maybe<Scalars["Int"]>;
};

export type MedicationOrderLinesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type MedicationAuthorizationStatusInput = {
  grossAmount?: InputMaybe<Scalars["Float"]>;
  netAmount?: InputMaybe<Scalars["Float"]>;
  rejectionReason?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
  tradeCode: Scalars["String"];
};

export enum MedicationDownloadAction {
  Added = "ADDED",
  Original = "ORIGINAL",
  Removed = "REMOVED",
}

export type MedicationFilterInput = {
  canRefill?: InputMaybe<Scalars["Boolean"]>;
};

export type MedicationOrderLineInput = {
  id: Scalars["ID"];
  quantity: Scalars["Int"];
  tradeCode: Scalars["String"];
};

export type MedicationTradeDrugs = {
  __typename?: "MedicationTradeDrugs";
  code: Scalars["String"];
  tradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
};

export type MeetingPlatformIdUpdate = {
  __typename?: "MeetingPlatformIdUpdate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  result?: Maybe<Scalars["String"]>;
};

export type MeetoraAnonymousChatInput = {
  anonymousChatLink?: InputMaybe<Scalars["String"]>;
  chatId?: InputMaybe<Scalars["H_Long"]>;
  error?: InputMaybe<Scalars["String"]>;
  exception?: InputMaybe<Scalars["String"]>;
  exceptionMessage?: InputMaybe<Scalars["String"]>;
  suspended?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  visitId?: InputMaybe<Scalars["String"]>;
};

export type Message = Node & {
  __typename?: "Message";
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  branch: Branch;
  content?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["Int"]>;
  recipient?: Maybe<User>;
  seenDate?: Maybe<Scalars["DateTime"]>;
  sender: User;
};

export type MessageAttachmentInput = {
  attachment: Scalars["ID"];
  sortOrder: Scalars["Int"];
};

export type MessageCountableConnection = {
  __typename?: "MessageCountableConnection";
  edges: Array<MessageCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type MessageCountableEdge = {
  __typename?: "MessageCountableEdge";
  cursor: Scalars["String"];
  node: Message;
};

export type MessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<MessageAttachmentInput>>>;
  branch?: InputMaybe<Scalars["ID"]>;
  content?: InputMaybe<Scalars["String"]>;
};

export type MessageSent = {
  __typename?: "MessageSent";
  message?: Maybe<Message>;
};

export type Money = {
  __typename?: "Money";
  amount: Scalars["Float"];
  currency: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  ProviderGuidedCareHealthProgramTeamPayerResponse?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  accountAddressCreate?: Maybe<AccountAddressCreate>;
  accountAddressDelete?: Maybe<AccountAddressDelete>;
  accountAddressUpdate?: Maybe<AccountAddressUpdate>;
  accountSetDefaultAddress?: Maybe<AccountSetDefaultAddress>;
  activateMedicalForm?: Maybe<MedicalForm>;
  addCard?: Maybe<UserCard>;
  addDecision?: Maybe<Decision>;
  addDiagnosisToHealthCondition?: Maybe<Array<Maybe<HealthConditionDiagnosis>>>;
  addDiagnosisToHealthConditionByDisease?: Maybe<HealthCondition>;
  addDiagnosisToVisit?: Maybe<VisitDiagnosisCrud>;
  addFieldToHealthCondition?: Maybe<HealthCondition>;
  addFieldToLabTemplate?: Maybe<LabTemplate>;
  addFieldToRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  addHealthCondition?: Maybe<HealthCondition>;
  addHealthParameter?: Maybe<HealthParameter>;
  addLabTemplate?: Maybe<LabTemplate>;
  addMemberToChronicGuidedCareProgram?: Maybe<VisitDiagnosisRiskTemplateCrud>;
  addMemberToProgram?: Maybe<GuidedCareHealthProgram>;
  addPatientChronicDisease?: Maybe<PatientMedicalProfile>;
  addPatientChronicMedication?: Maybe<PatientMedicalProfile>;
  addPatientDisability?: Maybe<PatientMedicalProfile>;
  addPatientNonChronicDisease?: Maybe<PatientMedicalProfile>;
  addPersonalizedPatientAppointment?: Maybe<MutationPersonalizedConsumerAppointmentCreate>;
  addPersonalizedPatientAppointments?: Maybe<MutationPersonalizedConsumerAppointmentCreate>;
  addRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  addServiceToAppointment?: Maybe<ServiceDetails>;
  addSubList?: Maybe<SubList>;
  addSubListItem?: Maybe<SubListItem>;
  addVendorToFavorite?: Maybe<AddVendorToFavorite>;
  adminCreateMedicalForm?: Maybe<MedicalForm>;
  adminPromotionCreate?: Maybe<AdminPromotion>;
  adminPromotionPublish?: Maybe<AdminPromotion>;
  adminPromotionUnpublish?: Maybe<AdminPromotion>;
  adminPromotionUpdate?: Maybe<AdminPromotion>;
  adminUpdateMedicalForm?: Maybe<MedicalForm>;
  appointmentAssignBranch?: Maybe<AppointmentApprovalAction>;
  appointmentAssignDepartment?: Maybe<AppointmentApprovalAction>;
  /** Appointment status update */
  appointmentStatusUpdate?: Maybe<AppointmentStatusUpdate>;
  approveTermsAndConditions?: Maybe<ApproveTermsAndConditions>;
  approveVendor?: Maybe<ApproveVendor>;
  articleAddContentImages?: Maybe<Article>;
  articleCategoryCreate?: Maybe<ArticleCategory>;
  articleCategoryUpdate?: Maybe<ArticleCategory>;
  articleCreate?: Maybe<Article>;
  articleRemoveContentImages?: Maybe<Article>;
  articleUpdate?: Maybe<Article>;
  assignCodeSystemEditorAndReviewer?: Maybe<CodeSystemDefinition>;
  assignDefaultBranch?: Maybe<AssignDefaultBranch>;
  assignUserToBranch?: Maybe<AssignUserToBranch>;
  availabilitiesAverage?: Maybe<AvailabilitiesAverage>;
  /** create a new Benefit Group */
  benefitGroupCreate?: Maybe<BenefitGroupCrud>;
  /** delete a Benefit Group */
  benefitGroupDelete?: Maybe<BenefitGroupCrud>;
  /** update Benefit Group */
  benefitGroupUpdate?: Maybe<BenefitGroupCrud>;
  blockCreate?: Maybe<BlockCreate>;
  blockDelete?: Maybe<BlockDelete>;
  blockUpdate?: Maybe<BlockUpdate>;
  branchCreate?: Maybe<BranchCreate>;
  branchDelete?: Maybe<BranchDelete>;
  branchUpdate?: Maybe<BranchUpdate>;
  callDoctorNowCreate?: Maybe<VisitAction>;
  callbackRequestCreate?: Maybe<CallbackRequestCrud>;
  callbackRequestUpdate?: Maybe<CallbackRequestCrud>;
  cancelBulkFile?: Maybe<BulkFile>;
  cancelSurvey?: Maybe<Survey>;
  /** Create a new cancel reason. */
  cancellationReasonCreate?: Maybe<CancelReasonCrud>;
  /** Delete an existing cancel reason. */
  cancellationReasonDelete?: Maybe<CancelReasonCrud>;
  /** Update an existing cancel reason. */
  cancellationReasonUpdate?: Maybe<CancelReasonCrud>;
  cardUpdateIsDefault?: Maybe<UserCardCrud>;
  changeGuidedCareHealthProgramActiveStatus?: Maybe<GuidedCareHealthProgramCrud>;
  changeHealthProgramTemplateActiveStatus?: Maybe<HealthProgramTemplateCrud>;
  changeProviderGuidedCareHealthProgramTeamActiveStatus?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  chatFlowAddContributors?: Maybe<ChatFlow>;
  chatFlowDelete?: Maybe<ChatFlow>;
  chatFlowDocumentCreate?: Maybe<ChatFlowDocument>;
  chatFlowDocumentDelete?: Maybe<ChatFlowDocument>;
  chatFlowRemoveContributors?: Maybe<ChatFlow>;
  chatFlowUpdate?: Maybe<ChatFlow>;
  checkCustomerAccountExist?: Maybe<CheckCustomerAccountExist>;
  chooseOrderPaymentMethod?: Maybe<ChooseOrderPaymentMethod>;
  cityCreate?: Maybe<CityCreate>;
  cityDelete?: Maybe<CityDelete>;
  cityUpdate?: Maybe<CityUpdate>;
  cloneCodeSystem?: Maybe<CodeSystem>;
  cloneSurvey?: Maybe<Survey>;
  confirmAccountEmail?: Maybe<ConfirmAccountEmail>;
  confirmAccountMobile?: Maybe<ConfirmAccountMobile>;
  confirmPasswordRestOtp?: Maybe<ConfirmPasswordRestOtp>;
  confirmVerification?: Maybe<ConfirmVerification>;
  /** Test create an Auto Appointment. */
  consumerAppointmentAutoCreate?: Maybe<ConsumerAppointmentCreate>;
  /** Doctor Accept Appointment Cancel */
  consumerAppointmentCancel?: Maybe<AppointmentApprovalAction>;
  /** Consumer Confirm Appointment */
  consumerAppointmentConfirm?: Maybe<AppointmentApprovalAction>;
  /** Creates an Appointment. */
  consumerAppointmentCreate?: Maybe<ConsumerAppointmentCreate>;
  /** Creates an Appointment For Anonymous. */
  consumerAppointmentCreateForAnonymous?: Maybe<ConsumerAppointmentCreate>;
  consumerAppointmentReschedule?: Maybe<AppointmentApprovalAction>;
  /** follow health channel. */
  consumerHealthChannelFollow?: Maybe<ConsumerHealthChannelFollow>;
  /** UnFollow health channel. */
  consumerHealthChannelUnFollow?: Maybe<ConsumerHealthChannelUnFollow>;
  consumerPrescriptionDispense?: Maybe<ConsumerPrescriptionDispense>;
  /** Rate a Health Channel */
  consumerRateHealthChannel?: Maybe<ConsumerRateHealthChannel>;
  consumerViewPreferenceSave?: Maybe<ConsumerViewPreferenceSave>;
  convertVirtualOrderToRealOne?: Maybe<ConvertVirtualOrderToRealOne>;
  createConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  createDiagnosisValidProcedure?: Maybe<DiagnosisValidProcedure>;
  createDummyVirtualVisit?: Maybe<Visit>;
  createMedicalForm?: Maybe<MedicalForm>;
  createOrUpdateDigitalTwinHealthProfile: DigitalTwinHealthProfile;
  createQuestion?: Maybe<Question>;
  createSurvey?: Maybe<Survey>;
  createVisitPrescription?: Maybe<VisitPrescriptionTransaction>;
  createVisitPrescriptionWithoutTPO?: Maybe<VisitPrescriptionTransaction>;
  customerCreate?: Maybe<CustomerCreate>;
  customerDelete?: Maybe<CustomerDelete>;
  customerMarkMessagesAsSeen?: Maybe<CustomerMarkMessagesAsSeen>;
  customerProfileUpdate?: Maybe<CustomerProfileUpdate>;
  customerSendMessage?: Maybe<CustomerSendMessage>;
  customerSupportErxOrderStatusUpdate?: Maybe<CustomerSupportErxOrderStatusUpdate>;
  deactivateMedicalForm?: Maybe<MedicalForm>;
  deleteAppointmentNotificationSetting?: Maybe<Scalars["Boolean"]>;
  deleteAppointmentServiceDetails?: Maybe<ServiceDetails>;
  deleteCard?: Maybe<UserCardCrud>;
  deleteCodeSystemConcept?: Maybe<CodeSystemConcept>;
  deleteConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  deleteCurrentCustomerUser?: Maybe<DeleteCurrentCustomerUser>;
  deleteDecision?: Maybe<Decision>;
  deleteDiagnosisValidProcedure?: Maybe<Scalars["Boolean"]>;
  /** must be called by vendor nurse user */
  deleteDoctorNurseAssignment?: Maybe<Scalars["Boolean"]>;
  deleteFieldFromHealthCondition?: Maybe<HealthCondition>;
  deleteFieldFromLabTemplate?: Maybe<LabTemplate>;
  deleteFieldFromRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  deleteHealthConditionDiagnosis?: Maybe<HealthConditionDiagnosis>;
  deletePatientChronicDisease?: Maybe<PatientMedicalProfile>;
  deletePatientChronicMedication?: Maybe<PatientMedicalProfile>;
  deletePatientDisability?: Maybe<PatientMedicalProfile>;
  deletePatientNonChronicDisease?: Maybe<PatientMedicalProfile>;
  deleteProviderSettings?: Maybe<Scalars["Boolean"]>;
  deleteQuestion?: Maybe<Question>;
  deleteSubList?: Maybe<SubList>;
  deleteSubListItem?: Maybe<SubListItem>;
  deleteVisitDiagnosis?: Maybe<VisitDiagnosisCrud>;
  deliveryTimeSlotCreate?: Maybe<DeliveryTimeSlotCreate>;
  deliveryTimeSlotUpdateStatus?: Maybe<DeliveryTimeSlotUpdateStatus>;
  diagnosisPrediction?: Maybe<DiagnosisPredictionMutation>;
  disEnrollPatientToTeam?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  /** Doctor Accept Appointment */
  doctorAppointmentAccept?: Maybe<AppointmentApprovalAction>;
  /** Doctor Accept Appointment Cancel */
  doctorAppointmentCancel?: Maybe<AppointmentApprovalAction>;
  /** Doctor Accept Appointment Reject */
  doctorAppointmentReject?: Maybe<AppointmentApprovalAction>;
  doctorAppointmentReschedule?: Maybe<AppointmentApprovalAction>;
  doctorAvailabilityStatusUpdate?: Maybe<DoctorAvailabilityStatusUpdate>;
  doctorCanAcceptCallUpdate?: Maybe<DoctorCanAcceptCallUpdate>;
  doctorExperienceCreate?: Maybe<DoctorExperienceCreate>;
  doctorExperienceDelete?: Maybe<DoctorExperienceDelete>;
  doctorExperienceUpdate?: Maybe<DoctorExperienceUpdate>;
  doctorProfileUpdate?: Maybe<DoctorProfileUpdate>;
  doctorRating?: Maybe<DoctorRating>;
  /** doctor accepts assigned call */
  doctorVisitAccept?: Maybe<VisitApprovalAction>;
  doctorVisitComplete?: Maybe<VisitApprovalAction>;
  /** doctor reject assigned call */
  doctorVisitReject?: Maybe<DoctorVisitReject>;
  duplicateHealthCondition?: Maybe<HealthCondition>;
  duplicateLabTemplate?: Maybe<LabTemplate>;
  duplicateRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  /** Create a new EarliestVisit */
  earliestVisitCreate?: Maybe<EarliestVisitCreate>;
  earlyRefillReasonCreate?: Maybe<EarlyRefillReasonCreate>;
  earlyRefillReasonDelete?: Maybe<EarlyRefillReasonDelete>;
  earlyRefillReasonUpdate?: Maybe<EarlyRefillReasonUpdate>;
  earlyRefillRequestCreate?: Maybe<EarlyRefillRequestCreate>;
  enrollPatientToTeam?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  evaluateGuidedCareProgramRiskFactorTemplate?: Maybe<GuidedCareHealthProgramRiskFactorTemplateEvaluation>;
  evaluateLabTemplate?: Maybe<LabTemplate>;
  evaluatePatientHealthConditions?: Maybe<Array<Maybe<PatientEvaluatedHealthCondition>>>;
  evaluateUserRequiredAction?: Maybe<Array<Maybe<UserAction>>>;
  /** create a new Exclusion Option */
  exclusionOptionCreate?: Maybe<ExclusionOptionCrud>;
  /** delete a Exclusion Option */
  exclusionOptionDelete?: Maybe<ExclusionOptionCrud>;
  /** update Exclusion Option */
  exclusionOptionUpdate?: Maybe<ExclusionOptionCrud>;
  /**
   * can be called by vendor user only,
   * filter.createdDate can't cover more than 3 months,
   * can't return more than 100K records in the file so the filter.createdDate should be adjusted according to that,
   * returns an absolute file url to download,
   *
   * CSV file header column
   * Invoice Id,Marketplace Order Id,Payment Source,Currency,Discount,Price Before VAT,VAT,Price After VAT,Consumer Id,Consumer User Id,Creation Date
   */
  exportPaymentInvoices?: Maybe<Scalars["String"]>;
  /** create a new Extra Benefit */
  extraBenefitCreate?: Maybe<ExtraBenefitCrud>;
  /** delete a Extra Benefit */
  extraBenefitDelete?: Maybe<ExtraBenefitCrud>;
  /** update Extra Benefit */
  extraBenefitUpdate?: Maybe<ExtraBenefitCrud>;
  fetchTerminologyListData?: Maybe<SubList>;
  firePlanDecisions?: Maybe<Action>;
  firebaseLogout?: Maybe<DeviceInformation>;
  fromGlobalId?: Maybe<FromGlobalId>;
  generatePatientProfileConsentCode?: Maybe<PatientProfileConsentCode>;
  generatePatientReferral?: Maybe<PatientReferral>;
  generatePatientReferralByClinician?: Maybe<PatientReferral>;
  getComplexPDFTextFile?: Maybe<Scalars["String"]>;
  guidedCareHealthProgramCreate?: Maybe<GuidedCareHealthProgramCrud>;
  guidedCareHealthProgramUpdate?: Maybe<GuidedCareHealthProgramCrud>;
  guidedCareJourneyInterventionActivate?: Maybe<GuidedCareJourney>;
  guidedCareJourneyInterventionDeactivate?: Maybe<GuidedCareJourney>;
  guidedCareJourneyItemUpdate?: Maybe<GuidedCareJourneyItemCrud>;
  guidedCareJourneyItemUpdateStatus?: Maybe<GuidedCareJourneyItem>;
  guidedCareTaskCreate?: Maybe<GuidedCareTaskCrud>;
  /** delete an Existing Guided Care Task */
  guidedCareTaskDelete?: Maybe<GuidedCareTaskCrud>;
  /** Update an Existing Guided Care Task */
  guidedCareTaskUpdate?: Maybe<GuidedCareTaskCrud>;
  handleCheckoutPaymentRedirectionByPaymentCheckoutId?: Maybe<PaymentResponse>;
  handleHyperPayWebhookNotification?: Maybe<PaymentResponse>;
  handleOrderVendorAcceptTimeout?: Maybe<HandleOrderVendorAcceptTimeout>;
  handlePaymentRedirectionByPaymentId?: Maybe<PaymentResponse>;
  /** Create a new health channel category. */
  healthChannelCategoryCreate?: Maybe<HealthChannelCategoryCrud>;
  /** Delete an existing health channel category. */
  healthChannelCategoryDelete?: Maybe<HealthChannelCategoryCrud>;
  /** Update an existing health channel category. */
  healthChannelCategoryUpdate?: Maybe<HealthChannelCategoryCrud>;
  /** Create a new health channel. */
  healthChannelCreate?: Maybe<HealthChannelCrud>;
  /** Delete an existing health channel. */
  healthChannelDelete?: Maybe<HealthChannelCrud>;
  /** Update an existing health channel. */
  healthChannelUpdate?: Maybe<HealthChannelCrud>;
  /** Create a new Health Program Benefit Option */
  healthProgramBenefitOptionCreate?: Maybe<HealthProgramBenefitOptionCrud>;
  /** delete a Health Program Benefit Option */
  healthProgramBenefitOptionDelete?: Maybe<HealthProgramBenefitOptionCrud>;
  /** update Health Program Benefit Option */
  healthProgramBenefitOptionUpdate?: Maybe<HealthProgramBenefitOptionCrud>;
  /** Create a new Health Program */
  healthProgramCreate?: Maybe<HealthProgramCrud>;
  healthProgramMemberBodyCompositionUpdate?: Maybe<HealthProgramMemberBodyCompositionCrud>;
  /**
   * """delete an Existing Health Program"""
   * healthProgramDelete(
   *     """ID of a health program to delete."""
   *     id: ID!
   * ): HealthProgramCRUD
   */
  healthProgramMemberCreate?: Maybe<HealthProgramMemberCrud>;
  /**
   * healthProgramMemberDelete(
   *     id: ID!
   * ): HealthProgramMemberCRUD
   */
  healthProgramMemberListCreate?: Maybe<HealthProgramMemberListCrud>;
  healthProgramMemberListUpdate?: Maybe<HealthProgramMemberListCrud>;
  healthProgramMemberUpdate?: Maybe<HealthProgramMemberCrud>;
  healthProgramMemberUpdateIsDefault?: Maybe<HealthProgramMemberCrud>;
  healthProgramMembershipRequestApprove?: Maybe<HealthProgramMembershipRequestCrud>;
  healthProgramMembershipRequestCreate?: Maybe<HealthProgramMembershipRequestCrud>;
  healthProgramMembershipRequestReject?: Maybe<HealthProgramMembershipRequestCrud>;
  /**
   * healthProgramMemberListDelete(
   *     id: ID!
   * ): HealthProgramMemberListCRUD
   */
  healthProgramNetworkCreate?: Maybe<HealthProgramNetworkCrud>;
  /**
   * healthProgramNetworkDelete(
   *     id: ID!
   * ): HealthProgramNetworkCRUD
   */
  healthProgramNetworkProviderCreate?: Maybe<HealthProgramNetworkProviderCrud>;
  /**
   * healthProgramNetworkProviderDelete(
   *     id: ID!
   * ): HealthProgramNetworkProviderCRUD
   */
  healthProgramNetworkProviderProfessionalCreate?: Maybe<HealthProgramNetworkProviderProfessionalCrud>;
  healthProgramNetworkProviderProfessionalUpdate?: Maybe<HealthProgramNetworkProviderProfessionalCrud>;
  healthProgramNetworkProviderUpdate?: Maybe<HealthProgramNetworkProviderCrud>;
  healthProgramNetworkUpdate?: Maybe<HealthProgramNetworkCrud>;
  /** Subscribe user to Health Program */
  healthProgramSubscribe?: Maybe<HealthProgramSubscribe>;
  healthProgramTemplateCreate?: Maybe<HealthProgramTemplateCrud>;
  healthProgramTemplateUpdate?: Maybe<HealthProgramTemplateCrud>;
  healthProgramUnSubscribe?: Maybe<HealthProgramUnSubscribe>;
  /** Update an Existing Health Program */
  healthProgramUpdate?: Maybe<HealthProgramCrud>;
  healthProgramsSiteSettingsUpdate?: Maybe<SiteSettings>;
  healthSymptomCreate?: Maybe<HealthSymptomCreate>;
  healthSymptomDelete?: Maybe<HealthSymptomDelete>;
  healthSymptomSpecializationDelete?: Maybe<HealthSymptomSpecializationDelete>;
  healthSymptomSpecializationsCreate?: Maybe<HealthSymptomSpecializationsCreate>;
  healthSymptomUpdate?: Maybe<HealthSymptomUpdate>;
  insertDeviceInformation?: Maybe<DeviceInformation>;
  insertPatientHealthParameters?: Maybe<Array<Maybe<PatientHealthParameterCrud>>>;
  insertUnattendedCall?: Maybe<TypeMutationInsertUnattendedCall>;
  /** Create a new InsuranceNetwork */
  insuranceNetworkCreate?: Maybe<InsuranceNetworkCrud>;
  /** deletes an existing InsuranceNetwork */
  insuranceNetworkDelete?: Maybe<InsuranceNetworkCrud>;
  /** Update an existing InsuranceNetwork */
  insuranceNetworkUpdate?: Maybe<InsuranceNetworkCrud>;
  invalidateAnonymousChatGroup?: Maybe<AnonymousChatGroup>;
  isPrescriptionEligibleForFreeDelivery?: Maybe<IsPrescriptionEligibleForFreeDelivery>;
  journeySwitchTeam?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  keycloakPermissionConfigurationUpdate?: Maybe<KeycloakPermissionConfigurationUpdate>;
  labRequestCreate?: Maybe<LabCrud>;
  labRequestDelete?: Maybe<LabCrud>;
  languageCreate?: Maybe<LanguageCreate>;
  languageUpdate?: Maybe<LanguageUpdate>;
  markAllNotificationsAsSeen?: Maybe<MarkAllNotificationsAsSeenResponse>;
  markNotificationAsSeen?: Maybe<UserNotification>;
  marketplaceBrandCreate?: Maybe<MarketplaceBrand>;
  marketplaceBrandUpdate?: Maybe<MarketplaceBrand>;
  marketplaceCartAddItem?: Maybe<MarketplaceCart>;
  marketplaceCartClear?: Maybe<MarketplaceCart>;
  marketplaceCartRemoveItems?: Maybe<MarketplaceCart>;
  marketplaceCartUpdateItemQuantity?: Maybe<MarketplaceCart>;
  marketplaceDiscountCreate?: Maybe<MarketplaceDiscount>;
  marketplaceDiscountPublish?: Maybe<MarketplaceDiscount>;
  marketplaceDiscountUnpublish?: Maybe<MarketplaceDiscount>;
  marketplaceDiscountUpdate?: Maybe<MarketplaceDiscount>;
  marketplaceHealthPackageApprove?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageCategoryCreate?: Maybe<MarketplaceHealthPackageCategory>;
  marketplaceHealthPackageCategoryUpdate?: Maybe<MarketplaceHealthPackageCategory>;
  marketplaceHealthPackageClone?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageCreate?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackagePublish?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageReject?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageSubmitForApproval?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageUnpublish?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageUpdate?: Maybe<MarketplaceHealthPackage>;
  marketplaceOrderAccept?: Maybe<MarketplaceOrder>;
  marketplaceOrderCancel?: Maybe<MarketplaceOrder>;
  marketplaceOrderCheckoutComplete?: Maybe<Array<Maybe<MarketplaceOrder>>>;
  marketplaceOrderConsumedUpdate?: Maybe<MarketplaceOrder>;
  marketplaceOrderDeliveredUpdate?: Maybe<MarketplaceOrder>;
  marketplaceOrderOutForDeliveryUpdate?: Maybe<MarketplaceOrder>;
  marketplaceOrderReject?: Maybe<MarketplaceOrder>;
  marketplaceOrderStatusUpdate?: Maybe<MarketplaceOrder>;
  marketplaceProductApprove?: Maybe<MarketplaceProduct>;
  marketplaceProductCategoryCreate?: Maybe<MarketplaceProductCategory>;
  marketplaceProductCategoryUpdate?: Maybe<MarketplaceProductCategory>;
  marketplaceProductClone?: Maybe<MarketplaceProduct>;
  marketplaceProductCreate?: Maybe<MarketplaceProduct>;
  marketplaceProductPublish?: Maybe<MarketplaceProduct>;
  marketplaceProductReject?: Maybe<MarketplaceProduct>;
  marketplaceProductSubmitForApproval?: Maybe<MarketplaceProduct>;
  marketplaceProductUnpublish?: Maybe<MarketplaceProduct>;
  marketplaceProductUpdate?: Maybe<MarketplaceProduct>;
  marketplaceProductUpdateStockQuantity?: Maybe<MarketplaceProduct>;
  marketplacePromotionCreate?: Maybe<MarketplacePromotion>;
  marketplacePromotionPublish?: Maybe<MarketplacePromotion>;
  marketplacePromotionUnpublish?: Maybe<MarketplacePromotion>;
  marketplacePromotionUpdate?: Maybe<MarketplacePromotion>;
  marketplaceReviewSubmit?: Maybe<MarketplaceReview>;
  marketplaceSyncVATPercentage?: Maybe<Scalars["Double"]>;
  marketplaceWishlistAddItem?: Maybe<MarketplaceWishlist>;
  marketplaceWishlistClear?: Maybe<MarketplaceWishlist>;
  marketplaceWishlistRemoveItems?: Maybe<MarketplaceWishlist>;
  marketplaceWishlistUpdateItemQuantity?: Maybe<MarketplaceWishlist>;
  /** Create a new Medical Message */
  medicalMessageCreate?: Maybe<MedicalMessageCrud>;
  /** Update an Existing Medical Message */
  medicalMessageUpdate?: Maybe<MedicalMessageCrud>;
  /** Create a new Medical Message Variant */
  medicalMessageVariantCreate?: Maybe<MedicalMessageVariantCrud>;
  /** Update an Existing Medical Message Variant */
  medicalMessageVariantUpdate?: Maybe<MedicalMessageVariantCrud>;
  meetingPlatformUpdate?: Maybe<MeetingPlatformIdUpdate>;
  myReviewForVendor?: Maybe<MyReviewForVendor>;
  offlineAppointmentComplete?: Maybe<AppointmentStatusUpdate>;
  orderAddNote?: Maybe<OrderAddNote>;
  orderCancel?: Maybe<OrderCancel>;
  orderDeliveryStatusUpdate?: Maybe<OrderDeliveryStatusUpdate>;
  orderGetErxHubApproval?: Maybe<OrderGetErxHubApproval>;
  orderRejectionReasonCreate?: Maybe<OrderRejectionReasonCreate>;
  orderRejectionReasonDelete?: Maybe<OrderRejectionReasonDelete>;
  orderRejectionReasonUpdate?: Maybe<OrderRejectionReasonUpdate>;
  orderStatusUpdate?: Maybe<OrderStatusUpdate>;
  orderVendorFinishProcessing?: Maybe<OrderVendorFinishProcessing>;
  otpjAssessmentCreate?: Maybe<OtpjAssessment>;
  otpjCheckInCreate?: Maybe<OtpjCheckIn>;
  otpjConsultationCreate?: Maybe<OtpjConsultation>;
  otpjExtPaymentCreate?: Maybe<OtpjExtPayment>;
  outPatientJourneySetPriceAmount?: Maybe<OutPatientJourney>;
  outPatientJourneyStatusUpdate?: Maybe<OutPatientJourney>;
  passwordChange?: Maybe<PasswordChange>;
  patientCreate?: Maybe<PatientCreate>;
  patientHealthHistoryCreate?: Maybe<PatientMedicalProfileCrud>;
  patientHealthHistoryDelete?: Maybe<PatientMedicalProfileCrud>;
  patientMedicalProfileMembershipAssign?: Maybe<PatientMedicalProfileCrud>;
  patientProfileConsentFormApprove?: Maybe<PatientProfileConsentFormCrud>;
  patientProfileConsentFormClose?: Maybe<PatientProfileConsentFormCrud>;
  patientProfileConsentFormCreate?: Maybe<PatientProfileConsentFormCrud>;
  patientProfileConsentFormReject?: Maybe<PatientProfileConsentFormCrud>;
  patientSendOrderOtp?: Maybe<PatientSendOrderOtp>;
  patientUpdate?: Maybe<PatientUpdate>;
  /** Upload an attachment to visit */
  patientUploadVisitAttachment?: Maybe<PatientUploadVisitAttachment>;
  /** Patient Visit Cancel */
  patientVisitCancel?: Maybe<VisitApprovalAction>;
  payerAddUpdateMemberBankDetails?: Maybe<GeneralResponse>;
  /** Payer Mutations */
  payerClaimSubmission?: Maybe<GeneralResponse>;
  payerCreate?: Maybe<PayerCreate>;
  payerUpdate?: Maybe<PayerUpdate>;
  permissionGroupCreate?: Maybe<PermissionGroupCreate>;
  permissionGroupDelete?: Maybe<PermissionGroupDelete>;
  permissionGroupUpdate?: Maybe<PermissionGroupUpdate>;
  planCreate?: Maybe<PlanCreate>;
  planUpdate?: Maybe<PlanUpdate>;
  pluginUpdate?: Maybe<PluginUpdate>;
  prescriptionOrderAssignVendors?: Maybe<PrescriptionOrderAssignVendors>;
  processAppointmentNotificationSetting?: Maybe<AppointmentNotifications>;
  processPaymentCheckout?: Maybe<PaymentCheckoutResponse>;
  processPaymentClearance?: Maybe<Array<Maybe<PaymentReceipt>>>;
  processPaymentUsingWallet?: Maybe<Wallet>;
  processPaymentUsingWalletForTest?: Maybe<Wallet>;
  providerGuidedCareHealthProgramTeamCreate?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  providerGuidedCareHealthProgramTeamUpdate?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  publishCodeSystem?: Maybe<CodeSystem>;
  publishDraftHealthProgramTemplate?: Maybe<HealthProgramTemplateCrud>;
  publishSurvey?: Maybe<Survey>;
  refundPaymentFromWallet?: Maybe<PaymentResponse>;
  rejectVendor?: Maybe<RejectVendor>;
  /** Create a new rejection reason. */
  rejectionReasonCreate?: Maybe<RejectionReasonCrud>;
  /** Delete an existing rejection reason. */
  rejectionReasonDelete?: Maybe<RejectionReasonCrud>;
  /** Update an existing rejection reason. */
  rejectionReasonUpdate?: Maybe<RejectionReasonCrud>;
  removeVendorFromFavorite?: Maybe<RemoveVendorFromFavorite>;
  requestOtpForDeleteAccount?: Maybe<RequestOtpForDeleteAccount>;
  requestPasswordReset?: Maybe<RequestPasswordReset>;
  resubmitOrderGetErxHubApproval?: Maybe<ResubmitOrderGetErxHubApproval>;
  reviewCodeSystem?: Maybe<CodeSystem>;
  reviewVendor?: Maybe<ReviewVendor>;
  /** Review an Visit */
  reviewVisit?: Maybe<ReviewVisit>;
  saveAppointmentNotificationSetting?: Maybe<Array<Maybe<AppointmentNotificationSetting>>>;
  /** must be called by vendor nurse user, remove all existing ones for nurse and save the provided */
  saveDoctorNurseAssignment?: Maybe<Array<Maybe<DoctorNurseAssignment>>>;
  saveOrFetchExistedAnonymousChatGroup?: Maybe<AnonymousChatGroup>;
  saveProviderSettings?: Maybe<ProviderSettings>;
  scheduleFixedDelayTask?: Maybe<Scalars["Boolean"]>;
  sendCodeSystemToReview?: Maybe<CodeSystem>;
  sendGuidedCareAppointmentReminderToTopic?: Maybe<Scalars["Boolean"]>;
  sendMedicalFormRequestToTopic?: Maybe<Scalars["Boolean"]>;
  sendMedicalMessageRequestToTopic?: Maybe<Scalars["Boolean"]>;
  sendNotificationForGuidedCareTask?: Maybe<Scalars["Boolean"]>;
  sendOrderSubscriptionEvent?: Maybe<SendOrderSubscriptionEvent>;
  /** send OTP Message to the provided input details for the purpose of Appointment For Anonymous */
  sendOtpMessageForAnonymousAppointment?: Maybe<Scalars["Boolean"]>;
  /** Creates a new visit message. */
  sendVisitMessage?: Maybe<SendVisitMessage>;
  setClinicianCredentials?: Maybe<ClinicianCredentials>;
  setLabResult?: Maybe<LabCrud>;
  setOrderToDelivered?: Maybe<SetOrderToDelivered>;
  setOrderToOutForDelivery?: Maybe<SetOrderToOutForDelivery>;
  setPassword?: Maybe<SetPassword>;
  setPharmacyCredentials?: Maybe<PharmacyCredentials>;
  setlRiskTemplateResult?: Maybe<VisitDiagnosisRiskTemplateCrud>;
  siteSettingsUpdate?: Maybe<SiteSettingsUpdate>;
  submitQuestionAnswersForCareJourneyMedicalForm?: Maybe<MedicalFormAnswer>;
  submitQuestionAnswersForMedicalForm?: Maybe<Array<Maybe<QuestionAnswerExtended>>>;
  submitSurveyAnswers?: Maybe<SurveyUserAnswer>;
  syncChatFlow?: Maybe<Scalars["Boolean"]>;
  syncFamilyMembers?: Maybe<HealthProgramMember>;
  syncLFProducts?: Maybe<Scalars["String"]>;
  taskDefinitionCreate?: Maybe<TaskDefinitionCrud>;
  /** delete an Existing Task Definition */
  taskDefinitionDelete?: Maybe<TaskDefinitionCrud>;
  /** Update an Existing Task Definition */
  taskDefinitionUpdate?: Maybe<TaskDefinitionCrud>;
  /** Nurse Confirm Appointment */
  tentativeAppointmentConfirm?: Maybe<AppointmentApprovalAction>;
  terminateVendorSubscription?: Maybe<TerminateVendorSubscription>;
  textSummary?: Maybe<TextSummaryMutation>;
  /** time out doctor reject assigned call */
  timeoutDoctorVisitReject?: Maybe<DoctorVisitReject>;
  toGlobalId?: Maybe<ToGlobalId>;
  unAssignUserFromBranch?: Maybe<UnAssignUserFromBranch>;
  unPublishSurvey?: Maybe<Survey>;
  unbindDependent?: Maybe<UnbindDependent>;
  unbindParent?: Maybe<UnbindParent>;
  updateAppointmentNotificationSetting?: Maybe<AppointmentNotificationSetting>;
  updateArticleActiveStatus?: Maybe<Article>;
  updateCodeSystemConcept?: Maybe<CodeSystemConcept>;
  updateConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  updateDecision?: Maybe<Decision>;
  updateDecisionStatus?: Maybe<Decision>;
  updateDiagnosisValidProcedure?: Maybe<DiagnosisValidProcedure>;
  updateGuidedCareHealthProgramActivityItems?: Maybe<GuidedCareHealthProgramActivityCrud>;
  updateGuidedCareTaskToDone?: Maybe<GuidedCareTaskCrud>;
  updateHealthCondition?: Maybe<HealthCondition>;
  updateHealthConditionActiveStatus?: Maybe<HealthCondition>;
  updateHealthParameter?: Maybe<HealthParameter>;
  updateHealthParameterActiveStatus?: Maybe<HealthParameter>;
  updateLabTemplate?: Maybe<LabTemplate>;
  updateLabTemplateActiveStatus?: Maybe<LabTemplate>;
  updateMedicalForm?: Maybe<MedicalForm>;
  /** Update an Existing Medical Message */
  updateMedicalMessagePublishStatus?: Maybe<MedicalMessageCrud>;
  updateMedicalMessageVariantActiveStatus?: Maybe<MedicalMessageVariantCrud>;
  updateMissedAppointmentJourneyItems?: Maybe<Array<Maybe<GuidedCareJourneyItem>>>;
  updatePayment?: Maybe<PaymentResponse>;
  updateQuestion?: Maybe<Question>;
  updateRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  updateRiskFactorTemplateActiveStatus?: Maybe<RiskFactorTemplate>;
  updateSubList?: Maybe<SubList>;
  updateSubListItem?: Maybe<SubListItem>;
  updateSurvey?: Maybe<Survey>;
  updateTemplateActivityItem?: Maybe<TemplateActivityCrud>;
  updateTemplateActivityItems?: Maybe<TemplateActivityCrud>;
  updateTemplateInterventionActivityItems?: Maybe<TemplateInterventionActivityCrud>;
  updateUserEmailMobile?: Maybe<UpdateUserEmailMobile>;
  updateVisitPrescriptionAuthStatus?: Maybe<VisitPrescriptionTransaction>;
  uploadAttachment?: Maybe<UploadAttachment>;
  uploadBulkFile?: Maybe<BulkFile>;
  uploadCodeSystemFile?: Maybe<CodeSystem>;
  uploadNationalCard?: Maybe<UploadNationalCard>;
  uploadSubList?: Maybe<SubList>;
  userAvatarDelete?: Maybe<AccountAvatarDelete>;
  userAvatarUpdate?: Maybe<AccountAvatarUpdate>;
  userCreate?: Maybe<UserCreate>;
  userDelete?: Maybe<UserDelete>;
  userManagementSiteSettingsUpdate?: Maybe<UserManagementSiteSettingsUpdate>;
  userProfileUpdate?: Maybe<UserProfileUpdate>;
  userUpdate?: Maybe<UserUpdate>;
  validatePrescription?: Maybe<ValidatePrescription>;
  validateProcedures?: Maybe<Array<Maybe<ValidateProcedureResponse>>>;
  vendorCreate?: Maybe<VendorCreate>;
  vendorDelete?: Maybe<VendorDelete>;
  vendorDepartmentCreate?: Maybe<VendorDepartmentCreate>;
  vendorDepartmentDelete?: Maybe<VendorDepartmentDelete>;
  vendorDepartmentUpdate?: Maybe<VendorDepartmentUpdate>;
  vendorImageCreate?: Maybe<VendorImageCreate>;
  vendorImageDelete?: Maybe<VendorImageDelete>;
  vendorImageReorder?: Maybe<VendorImageReorder>;
  vendorImageUpdate?: Maybe<VendorImageUpdate>;
  vendorMarkMessagesAsSeen?: Maybe<VendorMarkMessagesAsSeen>;
  vendorOrderAccept?: Maybe<VendorOrderAccept>;
  vendorOrderCancel?: Maybe<VendorOrderCancel>;
  vendorOrderDeliveryStatusUpdate?: Maybe<VendorOrderDeliveryStatusUpdate>;
  vendorOrderReject?: Maybe<VendorOrderReject>;
  vendorRegister?: Maybe<VendorRegister>;
  vendorReviewDelete?: Maybe<VendorReviewDelete>;
  vendorReviewPublish?: Maybe<VendorReviewPublish>;
  vendorSendMessage?: Maybe<VendorSendMessage>;
  vendorUpdate?: Maybe<VendorUpdate>;
  vendorUpdateEnableVisitDetails?: Maybe<VendorUpdateEnableVisitDetails>;
  vendorUpdateIsIntegrated?: Maybe<VendorUpdateIsIntegrated>;
  vendorUserCreate?: Maybe<VendorUserCreate>;
  vendorUserDelete?: Maybe<VendorUserDelete>;
  vendorUserUpdate?: Maybe<VendorUserUpdate>;
  verifyCredentials?: Maybe<VerifyCredentials>;
  verifyNationalId?: Maybe<VerifyNationalId>;
  visitAssignBranch?: Maybe<VisitAction>;
  /** Update Visit Assistant Agent Status. */
  visitAssistantAgentStatusUpdate?: Maybe<VisitAssistantAgentStatusUpdate>;
  /** end visit call. */
  visitCallEnd?: Maybe<VisitCallEnd>;
  /** make new visit active call if no active call exists. */
  visitCallRejoin?: Maybe<VisitAction>;
  /** creates a new Visit Complain */
  visitComplainCreate?: Maybe<VisitComplainCreate>;
  /** assign call request to doctor */
  visitDoctorAssign?: Maybe<VisitDoctorAssign>;
  visitDoctorNoteUpdate?: Maybe<VisitAction>;
  visitEvaluationRateSet?: Maybe<VisitAction>;
  /**
   * make new visit call if no active call exists. and if it still allowed
   * to make a new call after the essential latest call was made,
   * essential call means the first call that was requested by patient
   * and perhaps the call was timedout/rejoined/cancelled
   * and then the final status of it can be
   * (INCOMPLETED, COMPLETED, CANCELED, CANCELED_BY_CONSUMER, ENDED_BY_ONE_OF_THE_PARTICIPANTS)
   */
  visitNewCallStart?: Maybe<VisitAction>;
  /** Visit status update */
  visitStatusUpdate?: Maybe<VisitStatusUpdate>;
  /** Creates a new Visit Summary. */
  visitSummaryCreate?: Maybe<VisitSummaryCrud>;
  /** deletes an existing visit summary. */
  visitSummaryDelete?: Maybe<VisitSummaryCrud>;
  /** Update an existing lab */
  visitSummaryUpdate?: Maybe<VisitSummaryCrud>;
  visitTranscription?: Maybe<VisitTranscriptionMutation>;
};

export type MutationProviderGuidedCareHealthProgramTeamPayerResponseArgs = {
  input: ProviderGuidedCareHealthProgramTeamPayerResponseInput;
};

export type MutationAccountAddressCreateArgs = {
  input: AddressInput;
  type?: InputMaybe<AddressTypeEnum>;
};

export type MutationAccountAddressDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationAccountAddressUpdateArgs = {
  id: Scalars["ID"];
  input: AddressInput;
};

export type MutationAccountSetDefaultAddressArgs = {
  id: Scalars["ID"];
  type: AddressTypeEnum;
};

export type MutationActivateMedicalFormArgs = {
  id: Scalars["ID"];
};

export type MutationAddCardArgs = {
  cardInput: CardInput;
};

export type MutationAddDecisionArgs = {
  decision: DecisionInput;
};

export type MutationAddDiagnosisToHealthConditionArgs = {
  diagnosisCode?: InputMaybe<Array<Scalars["String"]>>;
  healthConditionId: Scalars["ID"];
};

export type MutationAddDiagnosisToHealthConditionByDiseaseArgs = {
  disease: Scalars["String"];
  healthConditionId: Scalars["ID"];
};

export type MutationAddDiagnosisToVisitArgs = {
  input?: InputMaybe<AddDiagnosisToVisitInput>;
};

export type MutationAddFieldToHealthConditionArgs = {
  healthConditionId: Scalars["ID"];
  healthParameterId: Scalars["ID"];
};

export type MutationAddFieldToLabTemplateArgs = {
  healthParameterId: Scalars["ID"];
  labTemplateId: Scalars["ID"];
};

export type MutationAddFieldToRiskFactorTemplateArgs = {
  healthParameterId: Scalars["ID"];
  riskFactorTemplateId: Scalars["ID"];
};

export type MutationAddHealthConditionArgs = {
  input: HealthConditionInput;
};

export type MutationAddHealthParameterArgs = {
  input: HealthParameterInput;
};

export type MutationAddLabTemplateArgs = {
  input: LabTemplateInput;
};

export type MutationAddMemberToChronicGuidedCareProgramArgs = {
  input: VisitChronicGuidedCareMemberAddInput;
};

export type MutationAddMemberToProgramArgs = {
  input?: InputMaybe<AddMemberToProgramInput>;
};

export type MutationAddPatientChronicDiseaseArgs = {
  input: PatientChronicDiseaseInput;
};

export type MutationAddPatientChronicMedicationArgs = {
  input: PatientChronicMedicationInput;
};

export type MutationAddPatientDisabilityArgs = {
  input: PatientDisabilityInput;
};

export type MutationAddPatientNonChronicDiseaseArgs = {
  input: PatientNonChronicDiseaseInput;
};

export type MutationAddPersonalizedPatientAppointmentArgs = {
  input: CreatePersonalizedAppointmentInput;
};

export type MutationAddPersonalizedPatientAppointmentsArgs = {
  input: CreatePersonalizedAppointmentsInput;
};

export type MutationAddRiskFactorTemplateArgs = {
  input: RiskFactorTemplateInput;
};

export type MutationAddServiceToAppointmentArgs = {
  input: ServiceDetailsInput;
};

export type MutationAddSubListArgs = {
  subList?: InputMaybe<SubListInput>;
};

export type MutationAddSubListItemArgs = {
  subListId: Scalars["ID"];
  subListItem: SubListItemInput;
};

export type MutationAddVendorToFavoriteArgs = {
  vendor?: Scalars["ID"];
};

export type MutationAdminCreateMedicalFormArgs = {
  input: AdminMedicalFormInput;
};

export type MutationAdminPromotionCreateArgs = {
  input: AdminPromotionInput;
};

export type MutationAdminPromotionPublishArgs = {
  id: Scalars["ID"];
};

export type MutationAdminPromotionUnpublishArgs = {
  id: Scalars["ID"];
};

export type MutationAdminPromotionUpdateArgs = {
  id: Scalars["ID"];
  input: AdminPromotionInput;
};

export type MutationAdminUpdateMedicalFormArgs = {
  id: Scalars["ID"];
  input: AdminMedicalFormInput;
};

export type MutationAppointmentAssignBranchArgs = {
  branchId: Scalars["ID"];
  id: Scalars["ID"];
};

export type MutationAppointmentAssignDepartmentArgs = {
  departmentId: Scalars["ID"];
  id: Scalars["ID"];
};

export type MutationAppointmentStatusUpdateArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  input?: InputMaybe<AppointmentStatusUpdateInput>;
};

export type MutationApproveVendorArgs = {
  id: Scalars["ID"];
};

export type MutationArticleAddContentImagesArgs = {
  articleId: Scalars["ID"];
  input: Array<ArticleContentImageInput>;
};

export type MutationArticleCategoryCreateArgs = {
  input: ArticleCategoryInput;
};

export type MutationArticleCategoryUpdateArgs = {
  id: Scalars["ID"];
  input: ArticleCategoryInput;
};

export type MutationArticleCreateArgs = {
  input: ArticleInput;
};

export type MutationArticleRemoveContentImagesArgs = {
  articleId: Scalars["ID"];
  contentImageIds: Array<Scalars["ID"]>;
};

export type MutationArticleUpdateArgs = {
  id: Scalars["ID"];
  input: ArticleInput;
};

export type MutationAssignCodeSystemEditorAndReviewerArgs = {
  code: CodeSystemCode;
  editorUser: Scalars["ID"];
  reviewerUser: Scalars["ID"];
};

export type MutationAssignDefaultBranchArgs = {
  branchId: Scalars["ID"];
};

export type MutationAssignUserToBranchArgs = {
  input?: InputMaybe<UserBranchInput>;
};

export type MutationAvailabilitiesAverageArgs = {
  fromDate: Scalars["Date"];
  toDate: Scalars["Date"];
  vendorId: Scalars["ID"];
};

export type MutationBenefitGroupCreateArgs = {
  input: BenefitGroupInput;
};

export type MutationBenefitGroupDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationBenefitGroupUpdateArgs = {
  id: Scalars["ID"];
  input: BenefitGroupInput;
};

export type MutationBlockCreateArgs = {
  input: BlockInput;
};

export type MutationBlockDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationBlockUpdateArgs = {
  id: Scalars["ID"];
  input: BlockInput;
};

export type MutationBranchCreateArgs = {
  input: BranchInput;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type MutationBranchDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationBranchUpdateArgs = {
  id: Scalars["ID"];
  input: BranchInput;
};

export type MutationCallDoctorNowCreateArgs = {
  input: CallDoctorNowInput;
};

export type MutationCallbackRequestCreateArgs = {
  input: CallbackRequestInput;
};

export type MutationCallbackRequestUpdateArgs = {
  id: Scalars["ID"];
  input: CallbackRequestAdminInput;
};

export type MutationCancelBulkFileArgs = {
  fileId: Scalars["ID"];
};

export type MutationCancelSurveyArgs = {
  id: Scalars["ID"];
};

export type MutationCancellationReasonCreateArgs = {
  input: CancelReasonInput;
};

export type MutationCancellationReasonDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationCancellationReasonUpdateArgs = {
  id: Scalars["ID"];
  input: CancelReasonInput;
};

export type MutationCardUpdateIsDefaultArgs = {
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
};

export type MutationChangeGuidedCareHealthProgramActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationChangeHealthProgramTemplateActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationChangeProviderGuidedCareHealthProgramTeamActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationChatFlowAddContributorsArgs = {
  chatFlowId: Scalars["ID"];
  contributors: Array<Scalars["ID"]>;
};

export type MutationChatFlowDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationChatFlowDocumentCreateArgs = {
  chatFlowId: Scalars["ID"];
  documentStoreId: Scalars["ID"];
  input?: InputMaybe<ChatFlowDocumentInput>;
};

export type MutationChatFlowDocumentDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationChatFlowRemoveContributorsArgs = {
  chatFlowId: Scalars["ID"];
  contributors: Array<Scalars["ID"]>;
};

export type MutationChatFlowUpdateArgs = {
  id: Scalars["ID"];
  input?: InputMaybe<ChatFlowUpdateInput>;
};

export type MutationCheckCustomerAccountExistArgs = {
  contactNumber?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  recaptchaToken?: InputMaybe<Scalars["String"]>;
};

export type MutationChooseOrderPaymentMethodArgs = {
  id: Scalars["ID"];
  paymentMethod: PaymentMethodEnum;
};

export type MutationCityCreateArgs = {
  input: CityInput;
};

export type MutationCityDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationCityUpdateArgs = {
  id: Scalars["ID"];
  input: CityInput;
};

export type MutationCloneCodeSystemArgs = {
  codeSystemCode: CodeSystemCode;
};

export type MutationCloneSurveyArgs = {
  id: Scalars["ID"];
  label: Scalars["String"];
};

export type MutationConfirmAccountEmailArgs = {
  sessionToken: Scalars["String"];
  token: Scalars["String"];
  username: Scalars["String"];
};

export type MutationConfirmAccountMobileArgs = {
  mobile: Scalars["String"];
  sessionToken: Scalars["String"];
  token: Scalars["String"];
  username: Scalars["String"];
};

export type MutationConfirmPasswordRestOtpArgs = {
  appType: AppTypes;
  passwordResetMethod: PasswordResetMethodEnum;
  sessionToken: Scalars["String"];
  username: Scalars["String"];
  vendor?: InputMaybe<Scalars["ID"]>;
  verificationCode: Scalars["String"];
};

export type MutationConfirmVerificationArgs = {
  email?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  sessionToken: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MutationConsumerAppointmentAutoCreateArgs = {
  input: ConsumerAppointmentInput;
  memberId?: InputMaybe<Scalars["ID"]>;
  patientId?: InputMaybe<Scalars["ID"]>;
  teamId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationConsumerAppointmentCancelArgs = {
  id: Scalars["ID"];
  input: CancelInput;
};

export type MutationConsumerAppointmentConfirmArgs = {
  id: Scalars["ID"];
  input: ConsumerAppointmentConfirmInput;
};

export type MutationConsumerAppointmentCreateArgs = {
  input: ConsumerAppointmentInput;
};

export type MutationConsumerAppointmentCreateForAnonymousArgs = {
  input: ConsumerAppointmentInputForAnonymous;
};

export type MutationConsumerAppointmentRescheduleArgs = {
  id: Scalars["ID"];
  input: ConsumerAppointmentRescheduleInput;
};

export type MutationConsumerHealthChannelFollowArgs = {
  input: ConsumerHealthChannelFollowInput;
};

export type MutationConsumerHealthChannelUnFollowArgs = {
  channelId: Scalars["ID"];
};

export type MutationConsumerPrescriptionDispenseArgs = {
  input: ConsumerPrescriptionDispenseInput;
};

export type MutationConsumerRateHealthChannelArgs = {
  input: HealthChannelRatingInput;
};

export type MutationConsumerViewPreferenceSaveArgs = {
  input: ConsumerViewPreferenceInput;
};

export type MutationConvertVirtualOrderToRealOneArgs = {
  branches: Array<InputMaybe<Scalars["ID"]>>;
  order?: InputMaybe<Scalars["ID"]>;
};

export type MutationCreateConceptDefinitionArgs = {
  codeSystemConceptFieldDefinition: CodeSystemConceptFieldDefinitionInput;
};

export type MutationCreateDiagnosisValidProcedureArgs = {
  input: DiagnosisValidProcedureInput;
};

export type MutationCreateDummyVirtualVisitArgs = {
  doctorId: Scalars["ID"];
  patientId: Scalars["ID"];
};

export type MutationCreateMedicalFormArgs = {
  input: MedicalFormInput;
};

export type MutationCreateOrUpdateDigitalTwinHealthProfileArgs = {
  input: HealthProfileInput;
};

export type MutationCreateQuestionArgs = {
  input: QuestionInput;
  surveyId: Scalars["ID"];
};

export type MutationCreateSurveyArgs = {
  input: SurveyInput;
};

export type MutationCreateVisitPrescriptionArgs = {
  createPrescriptionInput?: InputMaybe<CreateVisitPrescriptionInput>;
};

export type MutationCreateVisitPrescriptionWithoutTpoArgs = {
  createPrescriptionInput?: InputMaybe<CreateVisitPrescriptionInput>;
};

export type MutationCustomerCreateArgs = {
  input: CustomerCreateInput;
};

export type MutationCustomerDeleteArgs = {
  input: CustomerDeleteInput;
};

export type MutationCustomerMarkMessagesAsSeenArgs = {
  branchId: Scalars["String"];
};

export type MutationCustomerProfileUpdateArgs = {
  input: CustomerProfileUpdateInput;
};

export type MutationCustomerSendMessageArgs = {
  input: MessageInput;
};

export type MutationCustomerSupportErxOrderStatusUpdateArgs = {
  id: Scalars["ID"];
  status: CustomerSupportOrderStatusEnum;
};

export type MutationDeactivateMedicalFormArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAppointmentNotificationSettingArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAppointmentServiceDetailsArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteCardArgs = {
  cardId: Scalars["ID"];
};

export type MutationDeleteCodeSystemConceptArgs = {
  codeSystemConceptId?: InputMaybe<Scalars["ID"]>;
};

export type MutationDeleteConceptDefinitionArgs = {
  codeSystemConceptFieldDefinitionId: Scalars["ID"];
};

export type MutationDeleteCurrentCustomerUserArgs = {
  deleteReason: Scalars["String"];
};

export type MutationDeleteDecisionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteDiagnosisValidProcedureArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteDoctorNurseAssignmentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteFieldFromHealthConditionArgs = {
  healthConditionId: Scalars["ID"];
  healthParameterId: Scalars["ID"];
};

export type MutationDeleteFieldFromLabTemplateArgs = {
  healthParameterId: Scalars["ID"];
  labTemplateId: Scalars["ID"];
};

export type MutationDeleteFieldFromRiskFactorTemplateArgs = {
  healthParameterId: Scalars["ID"];
  riskFactorTemplateId: Scalars["ID"];
};

export type MutationDeleteHealthConditionDiagnosisArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePatientChronicDiseaseArgs = {
  id: Scalars["ID"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationDeletePatientChronicMedicationArgs = {
  id: Scalars["ID"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationDeletePatientDisabilityArgs = {
  id: Scalars["ID"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationDeletePatientNonChronicDiseaseArgs = {
  id: Scalars["ID"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationDeleteProviderSettingsArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteQuestionArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteSubListArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteSubListItemArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteVisitDiagnosisArgs = {
  id: Scalars["ID"];
};

export type MutationDeliveryTimeSlotCreateArgs = {
  input: DeliveryTimeSlotCreateInput;
};

export type MutationDeliveryTimeSlotUpdateStatusArgs = {
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
};

export type MutationDiagnosisPredictionArgs = {
  input: DiagnosisPredictionInput;
};

export type MutationDisEnrollPatientToTeamArgs = {
  input: GuidedCarePatientTeamEnrollmentInput;
};

export type MutationDoctorAppointmentAcceptArgs = {
  id: Scalars["ID"];
  input: DoctorAcceptAppointmentInput;
};

export type MutationDoctorAppointmentCancelArgs = {
  id: Scalars["ID"];
  input: CancelInput;
};

export type MutationDoctorAppointmentRejectArgs = {
  id: Scalars["ID"];
  input: DoctorAppointmentRejectInput;
};

export type MutationDoctorAppointmentRescheduleArgs = {
  id: Scalars["ID"];
  input: DoctorAppointmentRescheduleInput;
};

export type MutationDoctorAvailabilityStatusUpdateArgs = {
  availabilityStatus: DoctorAvailabilityStatusEnum;
  doctorId?: InputMaybe<Scalars["ID"]>;
  period: Scalars["Int"];
};

export type MutationDoctorCanAcceptCallUpdateArgs = {
  canAcceptCall: Scalars["Boolean"];
  doctorId?: InputMaybe<Scalars["ID"]>;
};

export type MutationDoctorExperienceCreateArgs = {
  input: DoctorExperienceCreateInput;
};

export type MutationDoctorExperienceDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationDoctorExperienceUpdateArgs = {
  id: Scalars["ID"];
  input: DoctorExperienceUpdateInput;
};

export type MutationDoctorProfileUpdateArgs = {
  input: DoctorProfileInput;
};

export type MutationDoctorRatingArgs = {
  input: DoctorRatingInput;
};

export type MutationDoctorVisitAcceptArgs = {
  branchId?: InputMaybe<Scalars["ID"]>;
  id: Scalars["ID"];
};

export type MutationDoctorVisitCompleteArgs = {
  id: Scalars["ID"];
};

export type MutationDoctorVisitRejectArgs = {
  input: DoctorVisitRejectInput;
};

export type MutationDuplicateHealthConditionArgs = {
  id: Scalars["ID"];
  input: HealthConditionInput;
};

export type MutationDuplicateLabTemplateArgs = {
  id: Scalars["ID"];
  input: LabTemplateInput;
};

export type MutationDuplicateRiskFactorTemplateArgs = {
  id: Scalars["ID"];
  input: RiskFactorTemplateInput;
};

export type MutationEarliestVisitCreateArgs = {
  input: EarliestVisitCreateInput;
};

export type MutationEarlyRefillReasonCreateArgs = {
  input: EarlyRefillReasonInput;
};

export type MutationEarlyRefillReasonDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationEarlyRefillReasonUpdateArgs = {
  id: Scalars["ID"];
  input: EarlyRefillReasonInput;
};

export type MutationEarlyRefillRequestCreateArgs = {
  input: EarlyRefillRequestInput;
};

export type MutationEnrollPatientToTeamArgs = {
  input: GuidedCarePatientTeamEnrollmentInput;
};

export type MutationEvaluateGuidedCareProgramRiskFactorTemplateArgs = {
  input?: InputMaybe<EvaluateProgramRiskFactorTemplateInput>;
};

export type MutationEvaluateLabTemplateArgs = {
  code: Scalars["String"];
  inputs: Array<InputMaybe<DecisionInputData>>;
};

export type MutationEvaluatePatientHealthConditionsArgs = {
  patientId: Scalars["ID"];
};

export type MutationExclusionOptionCreateArgs = {
  input: ExclusionOptionInput;
};

export type MutationExclusionOptionDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationExclusionOptionUpdateArgs = {
  id: Scalars["ID"];
  input: ExclusionOptionInput;
};

export type MutationExportPaymentInvoicesArgs = {
  filter: PaymentInvoiceFilterInput;
};

export type MutationExtraBenefitCreateArgs = {
  input: ExtraBenefitInput;
};

export type MutationExtraBenefitDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationExtraBenefitUpdateArgs = {
  id: Scalars["ID"];
  input: ExtraBenefitInput;
};

export type MutationFetchTerminologyListDataArgs = {
  codeSystemId: Scalars["ID"];
  searchParameters: Array<InputMaybe<SearchParameter>>;
  subListId: Scalars["ID"];
};

export type MutationFirePlanDecisionsArgs = {
  inputs: Array<InputMaybe<DecisionInputData>>;
  planCode: Scalars["String"];
};

export type MutationFirebaseLogoutArgs = {
  firebaseToken: Scalars["String"];
  uniqueId: Scalars["String"];
};

export type MutationFromGlobalIdArgs = {
  id: Scalars["ID"];
};

export type MutationGeneratePatientReferralArgs = {
  memberId: Scalars["String"];
};

export type MutationGeneratePatientReferralByClinicianArgs = {
  newReferral?: InputMaybe<ReferralRequestInput>;
};

export type MutationGetComplexPdfTextFileArgs = {
  fileName: Scalars["String"];
};

export type MutationGuidedCareHealthProgramCreateArgs = {
  input: GuidedCareHealthProgramInput;
};

export type MutationGuidedCareHealthProgramUpdateArgs = {
  input: GuidedCareHealthProgramInput;
};

export type MutationGuidedCareJourneyInterventionActivateArgs = {
  input: ActivateInterventionInput;
};

export type MutationGuidedCareJourneyInterventionDeactivateArgs = {
  guidedCareJourneyId: Scalars["ID"];
  templateInterventionId: Scalars["ID"];
};

export type MutationGuidedCareJourneyItemUpdateArgs = {
  id: Scalars["ID"];
  input: GuidedCareJourneyItemInput;
};

export type MutationGuidedCareJourneyItemUpdateStatusArgs = {
  id: Scalars["ID"];
  status: GuidedCareCareJourneyItemStatus;
};

export type MutationGuidedCareTaskCreateArgs = {
  input: GuidedCareTaskInput;
};

export type MutationGuidedCareTaskDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationGuidedCareTaskUpdateArgs = {
  id: Scalars["ID"];
  input: GuidedCareTaskUpdateInput;
};

export type MutationHandleCheckoutPaymentRedirectionByPaymentCheckoutIdArgs = {
  paymentCheckoutId: Scalars["String"];
};

export type MutationHandleHyperPayWebhookNotificationArgs = {
  body: Scalars["String"];
};

export type MutationHandleOrderVendorAcceptTimeoutArgs = {
  orders: Array<Scalars["ID"]>;
};

export type MutationHandlePaymentRedirectionByPaymentIdArgs = {
  paymentId: Scalars["String"];
};

export type MutationHealthChannelCategoryCreateArgs = {
  input: HealthChannelCategoryInput;
};

export type MutationHealthChannelCategoryDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationHealthChannelCategoryUpdateArgs = {
  id: Scalars["ID"];
  input: HealthChannelCategoryInput;
};

export type MutationHealthChannelCreateArgs = {
  input: HealthChannelInput;
};

export type MutationHealthChannelDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationHealthChannelUpdateArgs = {
  id: Scalars["ID"];
  input: HealthChannelInput;
};

export type MutationHealthProgramBenefitOptionCreateArgs = {
  input: HealthProgramBenefitOptionInput;
};

export type MutationHealthProgramBenefitOptionDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationHealthProgramBenefitOptionUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramBenefitOptionInput;
};

export type MutationHealthProgramCreateArgs = {
  input: HealthProgramInput;
};

export type MutationHealthProgramMemberBodyCompositionUpdateArgs = {
  input: HealthProgramMemberBodyCompositionInput;
};

export type MutationHealthProgramMemberCreateArgs = {
  input: HealthProgramMemberInput;
};

export type MutationHealthProgramMemberListCreateArgs = {
  input: HealthProgramMemberListInput;
};

export type MutationHealthProgramMemberListUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramMemberListUpdateInput;
};

export type MutationHealthProgramMemberUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramMemberInput;
};

export type MutationHealthProgramMemberUpdateIsDefaultArgs = {
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
};

export type MutationHealthProgramMembershipRequestApproveArgs = {
  id: Scalars["ID"];
  input: HealthProgramMembershipRequestApproveInput;
};

export type MutationHealthProgramMembershipRequestCreateArgs = {
  input: HealthProgramMembershipRequestInput;
};

export type MutationHealthProgramMembershipRequestRejectArgs = {
  id: Scalars["ID"];
  rejectionReason: Scalars["String"];
};

export type MutationHealthProgramNetworkCreateArgs = {
  input: HealthProgramNetworkInput;
};

export type MutationHealthProgramNetworkProviderCreateArgs = {
  input: HealthProgramNetworkProviderInput;
};

export type MutationHealthProgramNetworkProviderProfessionalCreateArgs = {
  input: HealthProgramNetworkProviderProfessionalInput;
};

export type MutationHealthProgramNetworkProviderProfessionalUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramNetworkProviderProfessionalInput;
};

export type MutationHealthProgramNetworkProviderUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramNetworkProviderInput;
};

export type MutationHealthProgramNetworkUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramNetworkUpdateInput;
};

export type MutationHealthProgramSubscribeArgs = {
  input: HealthProgramSubscribeInput;
};

export type MutationHealthProgramTemplateCreateArgs = {
  input: HealthProgramTemplateInput;
};

export type MutationHealthProgramTemplateUpdateArgs = {
  input: HealthProgramTemplateInput;
};

export type MutationHealthProgramUnSubscribeArgs = {
  programId: Scalars["ID"];
};

export type MutationHealthProgramUpdateArgs = {
  id: Scalars["ID"];
  input: HealthProgramUpdateInput;
};

export type MutationHealthProgramsSiteSettingsUpdateArgs = {
  input: H_SiteSettingsInput;
};

export type MutationHealthSymptomCreateArgs = {
  input: HealthSymptomInput;
};

export type MutationHealthSymptomDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationHealthSymptomSpecializationDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationHealthSymptomSpecializationsCreateArgs = {
  id: Scalars["ID"];
  specializations: Array<InputMaybe<Scalars["String"]>>;
};

export type MutationHealthSymptomUpdateArgs = {
  id: Scalars["ID"];
  input: HealthSymptomInput;
};

export type MutationInsertDeviceInformationArgs = {
  input?: InputMaybe<DeviceInformationInput>;
};

export type MutationInsertPatientHealthParametersArgs = {
  input: Array<PatientHealthParameterInput>;
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type MutationInsertUnattendedCallArgs = {
  input?: InputMaybe<UnattendedCallInput>;
};

export type MutationInsuranceNetworkCreateArgs = {
  input: InsuranceNetworkInput;
};

export type MutationInsuranceNetworkDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationInsuranceNetworkUpdateArgs = {
  id: Scalars["ID"];
  input: InsuranceNetworkInput;
};

export type MutationInvalidateAnonymousChatGroupArgs = {
  id: Scalars["ID"];
};

export type MutationIsPrescriptionEligibleForFreeDeliveryArgs = {
  id: Scalars["ID"];
  input: FreeDeliveryEligibilityCheckInput;
};

export type MutationJourneySwitchTeamArgs = {
  input: GuidedCarePatientTeamSwitchInput;
};

export type MutationKeycloakPermissionConfigurationUpdateArgs = {
  id: Scalars["ID"];
  input: KeycloakPermissionConfigurationInput;
};

export type MutationLabRequestCreateArgs = {
  input: LabInput;
};

export type MutationLabRequestDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationLanguageCreateArgs = {
  input: LanguageCreateInput;
};

export type MutationLanguageUpdateArgs = {
  id: Scalars["ID"];
  input: LanguageUpdateInput;
};

export type MutationMarkNotificationAsSeenArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceBrandCreateArgs = {
  input: MarketplaceBrandInput;
};

export type MutationMarketplaceBrandUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplaceBrandInput;
};

export type MutationMarketplaceCartAddItemArgs = {
  input: MarketplaceCartInput;
};

export type MutationMarketplaceCartRemoveItemsArgs = {
  cartItemIds: Array<Scalars["ID"]>;
};

export type MutationMarketplaceCartUpdateItemQuantityArgs = {
  cartItemId: Scalars["ID"];
  quantity: Scalars["Int"];
};

export type MutationMarketplaceDiscountCreateArgs = {
  input: MarketplaceDiscountCreateInput;
};

export type MutationMarketplaceDiscountPublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceDiscountUnpublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceDiscountUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplaceDiscountUpdateInput;
};

export type MutationMarketplaceHealthPackageApproveArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceHealthPackageCategoryCreateArgs = {
  input: MarketplaceCategoryInput;
};

export type MutationMarketplaceHealthPackageCategoryUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplaceCategoryInput;
};

export type MutationMarketplaceHealthPackageCloneArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceHealthPackageCreateArgs = {
  input: MarketplaceHealthPackageInput;
};

export type MutationMarketplaceHealthPackagePublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceHealthPackageRejectArgs = {
  id: Scalars["ID"];
  rejectionReason: Scalars["String"];
};

export type MutationMarketplaceHealthPackageSubmitForApprovalArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceHealthPackageUnpublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceHealthPackageUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplaceHealthPackageInput;
};

export type MutationMarketplaceOrderAcceptArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceOrderCancelArgs = {
  cancellationReason: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationMarketplaceOrderCheckoutCompleteArgs = {
  input: MarketplaceCheckoutCompleteInput;
};

export type MutationMarketplaceOrderConsumedUpdateArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceOrderDeliveredUpdateArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceOrderOutForDeliveryUpdateArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceOrderRejectArgs = {
  id: Scalars["ID"];
  rejectionReason: Scalars["String"];
};

export type MutationMarketplaceOrderStatusUpdateArgs = {
  id: Scalars["ID"];
  status: MarketplaceOrderStatus;
};

export type MutationMarketplaceProductApproveArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceProductCategoryCreateArgs = {
  input: MarketplaceCategoryInput;
};

export type MutationMarketplaceProductCategoryUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplaceCategoryInput;
};

export type MutationMarketplaceProductCloneArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceProductCreateArgs = {
  input: MarketplaceProductInput;
};

export type MutationMarketplaceProductPublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceProductRejectArgs = {
  id: Scalars["ID"];
  rejectionReason: Scalars["String"];
};

export type MutationMarketplaceProductSubmitForApprovalArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceProductUnpublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplaceProductUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplaceProductInput;
};

export type MutationMarketplaceProductUpdateStockQuantityArgs = {
  id: Scalars["ID"];
  quantity: Scalars["Int"];
};

export type MutationMarketplacePromotionCreateArgs = {
  input: MarketplacePromotionInput;
};

export type MutationMarketplacePromotionPublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplacePromotionUnpublishArgs = {
  id: Scalars["ID"];
};

export type MutationMarketplacePromotionUpdateArgs = {
  id: Scalars["ID"];
  input: MarketplacePromotionInput;
};

export type MutationMarketplaceReviewSubmitArgs = {
  input: MarketplaceReviewInput;
};

export type MutationMarketplaceWishlistAddItemArgs = {
  input: MarketplaceWishlistInput;
};

export type MutationMarketplaceWishlistRemoveItemsArgs = {
  wishlistItemIds: Array<Scalars["ID"]>;
};

export type MutationMarketplaceWishlistUpdateItemQuantityArgs = {
  quantity: Scalars["Int"];
  wishlistItemId: Scalars["ID"];
};

export type MutationMedicalMessageCreateArgs = {
  input: MedicalMessageInput;
};

export type MutationMedicalMessageUpdateArgs = {
  id: Scalars["ID"];
  input: MedicalMessageInput;
};

export type MutationMedicalMessageVariantCreateArgs = {
  input: MedicalMessageVariantInput;
};

export type MutationMedicalMessageVariantUpdateArgs = {
  id: Scalars["ID"];
  input: MedicalMessageVariantInput;
};

export type MutationMeetingPlatformUpdateArgs = {
  meetingPlatformId: Scalars["String"];
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationMyReviewForVendorArgs = {
  vendor: Scalars["ID"];
};

export type MutationOfflineAppointmentCompleteArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type MutationOrderAddNoteArgs = {
  input: OrderAddNoteInput;
  order: Scalars["ID"];
};

export type MutationOrderCancelArgs = {
  cancellationReason?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  nationalId?: InputMaybe<Scalars["String"]>;
};

export type MutationOrderDeliveryStatusUpdateArgs = {
  id: Scalars["ID"];
  input: OrderDeliveryStatusUpdateInput;
};

export type MutationOrderGetErxHubApprovalArgs = {
  id: Scalars["ID"];
};

export type MutationOrderRejectionReasonCreateArgs = {
  input: OrderRejectionReasonInput;
};

export type MutationOrderRejectionReasonDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationOrderRejectionReasonUpdateArgs = {
  id: Scalars["ID"];
  input: OrderRejectionReasonInput;
};

export type MutationOrderStatusUpdateArgs = {
  id: Scalars["ID"];
  input: OrderStatusUpdateInput;
};

export type MutationOrderVendorFinishProcessingArgs = {
  id: Scalars["ID"];
};

export type MutationOtpjAssessmentCreateArgs = {
  input?: InputMaybe<OtpjAssessmentInput>;
};

export type MutationOtpjCheckInCreateArgs = {
  input?: InputMaybe<OtpjCheckInInput>;
};

export type MutationOtpjConsultationCreateArgs = {
  input?: InputMaybe<OtpjConsultationInput>;
};

export type MutationOtpjExtPaymentCreateArgs = {
  input?: InputMaybe<OtpjExtPaymentInput>;
};

export type MutationOutPatientJourneySetPriceAmountArgs = {
  id: Scalars["ID"];
  priceAmount: Scalars["Float"];
};

export type MutationOutPatientJourneyStatusUpdateArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  input?: InputMaybe<OutPatientJourneyStatusUpdateInput>;
};

export type MutationPasswordChangeArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationPatientCreateArgs = {
  input: PatientInput;
};

export type MutationPatientHealthHistoryCreateArgs = {
  input: PatientHealthHistoryInput;
};

export type MutationPatientHealthHistoryDeleteArgs = {
  id: Scalars["ID"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationPatientMedicalProfileMembershipAssignArgs = {
  membershipId: Scalars["Int"];
};

export type MutationPatientProfileConsentFormApproveArgs = {
  id: Scalars["ID"];
};

export type MutationPatientProfileConsentFormCloseArgs = {
  id: Scalars["ID"];
};

export type MutationPatientProfileConsentFormCreateArgs = {
  input: PatientProfileConsentFormInput;
};

export type MutationPatientProfileConsentFormRejectArgs = {
  id: Scalars["ID"];
};

export type MutationPatientSendOrderOtpArgs = {
  input: PatientSendOrderOtpInput;
};

export type MutationPatientUpdateArgs = {
  id: Scalars["ID"];
  input: PatientUpdateInput;
};

export type MutationPatientUploadVisitAttachmentArgs = {
  input: VisitAttachmentUploadInput;
};

export type MutationPatientVisitCancelArgs = {
  id: Scalars["ID"];
  input: CancelInput;
};

export type MutationPayerAddUpdateMemberBankDetailsArgs = {
  input: PayerAddUpdateMemberBankDetailsRequestInput;
};

export type MutationPayerClaimSubmissionArgs = {
  input: PayerClaimSubmissionRequestInput;
};

export type MutationPayerCreateArgs = {
  input: PayerInput;
};

export type MutationPayerUpdateArgs = {
  id: Scalars["ID"];
  input: PayerInput;
};

export type MutationPermissionGroupCreateArgs = {
  input: PermissionGroupCreateInput;
};

export type MutationPermissionGroupDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationPermissionGroupUpdateArgs = {
  id: Scalars["ID"];
  input: PermissionGroupUpdateInput;
};

export type MutationPlanCreateArgs = {
  input: PlanInput;
};

export type MutationPlanUpdateArgs = {
  id: Scalars["ID"];
  input: PlanInput;
};

export type MutationPluginUpdateArgs = {
  id: Scalars["ID"];
  input: PluginUpdateInput;
};

export type MutationPrescriptionOrderAssignVendorsArgs = {
  input: PrescriptionOrderAssignVendorsInput;
};

export type MutationProcessAppointmentNotificationSettingArgs = {
  appointmentId: Scalars["ID"];
  id: Scalars["ID"];
  index: Scalars["Int"];
};

export type MutationProcessPaymentCheckoutArgs = {
  paymentCheckoutInput: PaymentCheckoutInput;
};

export type MutationProcessPaymentClearanceArgs = {
  input?: InputMaybe<PaymentClearanceInput>;
};

export type MutationProcessPaymentUsingWalletArgs = {
  input: PaymentClearanceFromMultipleSourcesInput;
};

export type MutationProcessPaymentUsingWalletForTestArgs = {
  input: PaymentClearanceFromMultipleSourcesInput;
  patientId: Scalars["H_Long"];
  userId: Scalars["H_Long"];
};

export type MutationProviderGuidedCareHealthProgramTeamCreateArgs = {
  input: ProviderGuidedCareHealthProgramTeamInput;
};

export type MutationProviderGuidedCareHealthProgramTeamUpdateArgs = {
  input: ProviderGuidedCareHealthProgramTeamInput;
};

export type MutationPublishCodeSystemArgs = {
  codeSystemId: Scalars["ID"];
};

export type MutationPublishDraftHealthProgramTemplateArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type MutationPublishSurveyArgs = {
  id: Scalars["ID"];
  input: PublishCriteriaInput;
};

export type MutationRefundPaymentFromWalletArgs = {
  input: RefundPaymentFromWalletInput;
};

export type MutationRejectVendorArgs = {
  input: VendorRejectionReasonInput;
};

export type MutationRejectionReasonCreateArgs = {
  input: RejectionReasonInput;
};

export type MutationRejectionReasonDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationRejectionReasonUpdateArgs = {
  id: Scalars["ID"];
  input: RejectionReasonInput;
};

export type MutationRemoveVendorFromFavoriteArgs = {
  vendor?: Scalars["ID"];
};

export type MutationRequestOtpForDeleteAccountArgs = {
  email?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  recaptchaToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRequestPasswordResetArgs = {
  appType: AppTypes;
  passwordResetMethod: PasswordResetMethodEnum;
  recaptchaToken?: InputMaybe<Scalars["String"]>;
  username: Scalars["String"];
  vendor?: InputMaybe<Scalars["ID"]>;
};

export type MutationResubmitOrderGetErxHubApprovalArgs = {
  id: Scalars["ID"];
  input: VendorOrderAcceptInput;
};

export type MutationReviewCodeSystemArgs = {
  codeSystemId: Scalars["ID"];
  processingStatus: ProcessingStatus;
};

export type MutationReviewVendorArgs = {
  input: VendorReviewCreateInput;
};

export type MutationReviewVisitArgs = {
  input: VisitReviewInput;
};

export type MutationSaveAppointmentNotificationSettingArgs = {
  input: Array<AppointmentNotificationSettingInput>;
};

export type MutationSaveDoctorNurseAssignmentArgs = {
  input: Array<DoctorNurseAssignmentInput>;
};

export type MutationSaveOrFetchExistedAnonymousChatGroupArgs = {
  fetchOnlyWithoutSave?: InputMaybe<Scalars["Boolean"]>;
  meetoraAnonymousChatInput?: InputMaybe<MeetoraAnonymousChatInput>;
  ownerUserId?: InputMaybe<Scalars["ID"]>;
  shouldNotCreateAnonymousChat?: InputMaybe<Scalars["Boolean"]>;
  userIds: Array<Scalars["ID"]>;
};

export type MutationSaveProviderSettingsArgs = {
  input: ProviderSettingsInput;
};

export type MutationSendCodeSystemToReviewArgs = {
  codeSystemId: Scalars["ID"];
};

export type MutationSendGuidedCareAppointmentReminderToTopicArgs = {
  appointmentId: Scalars["ID"];
  reminderNumber: Scalars["Int"];
};

export type MutationSendMedicalFormRequestToTopicArgs = {
  guidedCareJourneyItemId: Scalars["ID"];
  medicalFormId: Scalars["ID"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationSendMedicalMessageRequestToTopicArgs = {
  guidedCareJourneyItemId: Scalars["ID"];
  medicalMessageId: Scalars["ID"];
  medicalMessageType: MedicalMessageType;
  medicalMessageVariantId?: InputMaybe<Scalars["ID"]>;
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationSendNotificationForGuidedCareTaskArgs = {
  taskId: Scalars["ID"];
};

export type MutationSendOrderSubscriptionEventArgs = {
  orderId: Scalars["ID"];
};

export type MutationSendOtpMessageForAnonymousAppointmentArgs = {
  consumerMobileNumber: Scalars["String"];
  consumerName: Scalars["String"];
};

export type MutationSendVisitMessageArgs = {
  input: VisitMessageInput;
};

export type MutationSetClinicianCredentialsArgs = {
  clinicianCredentials: ClinicianCredentialsInput;
  destinationType: DestinationType;
};

export type MutationSetLabResultArgs = {
  input: LabResultInput;
};

export type MutationSetOrderToDeliveredArgs = {
  id: Scalars["ID"];
};

export type MutationSetOrderToOutForDeliveryArgs = {
  id: Scalars["ID"];
};

export type MutationSetPasswordArgs = {
  appType: AppTypes;
  password: Scalars["String"];
  passwordResetMethod: PasswordResetMethodEnum;
  token: Scalars["String"];
  username: Scalars["String"];
  vendor?: InputMaybe<Scalars["ID"]>;
};

export type MutationSetPharmacyCredentialsArgs = {
  destinationType: DestinationType;
  pharmacyCredentials: PharmacyCredentialsInput;
};

export type MutationSetlRiskTemplateResultArgs = {
  input: RiskTemplateResultInput;
};

export type MutationSiteSettingsUpdateArgs = {
  input: SiteSettingsInput;
};

export type MutationSubmitQuestionAnswersForCareJourneyMedicalFormArgs = {
  guidedCareJourneyItemId: Scalars["ID"];
  input: Array<QuestionAnswerCareJourneyExtendedInput>;
};

export type MutationSubmitQuestionAnswersForMedicalFormArgs = {
  input: Array<QuestionAnswerExtendedInput>;
};

export type MutationSubmitSurveyAnswersArgs = {
  input: SurveyAnswerInput;
};

export type MutationTaskDefinitionCreateArgs = {
  input: TaskDefinitionInput;
};

export type MutationTaskDefinitionDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationTaskDefinitionUpdateArgs = {
  id: Scalars["ID"];
  input: TaskDefinitionUpdateInput;
};

export type MutationTentativeAppointmentConfirmArgs = {
  id: Scalars["ID"];
  input: ConsumerAppointmentConfirmInput;
};

export type MutationTerminateVendorSubscriptionArgs = {
  vendor: Scalars["ID"];
};

export type MutationTextSummaryArgs = {
  input: TextSummaryInput;
};

export type MutationTimeoutDoctorVisitRejectArgs = {
  input: DoctorVisitRejectInput;
};

export type MutationToGlobalIdArgs = {
  pk: Scalars["String"];
  type?: InputMaybe<Scalars["String"]>;
};

export type MutationUnAssignUserFromBranchArgs = {
  input?: InputMaybe<UserBranchInput>;
};

export type MutationUnPublishSurveyArgs = {
  id: Scalars["ID"];
};

export type MutationUnbindDependentArgs = {
  input: UnbindDependentInput;
};

export type MutationUpdateAppointmentNotificationSettingArgs = {
  id: Scalars["ID"];
  input: AppointmentNotificationSettingInput;
};

export type MutationUpdateArticleActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateCodeSystemConceptArgs = {
  codeSystemConcept?: InputMaybe<CodeSystemConceptInput>;
};

export type MutationUpdateConceptDefinitionArgs = {
  codeSystemConceptFieldDefinition: CodeSystemConceptFieldDefinitionInput;
};

export type MutationUpdateDecisionArgs = {
  decision: DecisionInput;
};

export type MutationUpdateDecisionStatusArgs = {
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
};

export type MutationUpdateDiagnosisValidProcedureArgs = {
  id: Scalars["ID"];
  input: DiagnosisValidProcedureInput;
};

export type MutationUpdateGuidedCareHealthProgramActivityItemsArgs = {
  activityId: Scalars["ID"];
  activityItems?: InputMaybe<Array<InputMaybe<GuidedCareHealthProgramActivityItemInput>>>;
};

export type MutationUpdateGuidedCareTaskToDoneArgs = {
  actionEntityId: Scalars["ID"];
  assigneeUserId: Scalars["ID"];
  classificationName?: InputMaybe<Scalars["String"]>;
  guidedCareJourneyItemId: Scalars["ID"];
  taskWorkflowType: TaskWorkflowType;
};

export type MutationUpdateHealthConditionArgs = {
  id: Scalars["ID"];
  input: HealthConditionUpdateInput;
};

export type MutationUpdateHealthConditionActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateHealthParameterArgs = {
  id: Scalars["ID"];
  input?: InputMaybe<HealthParameterUpdateInput>;
};

export type MutationUpdateHealthParameterActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateLabTemplateArgs = {
  id: Scalars["ID"];
  input: LabTemplateUpdateInput;
};

export type MutationUpdateLabTemplateActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateMedicalFormArgs = {
  id: Scalars["ID"];
  input: MedicalFormInput;
};

export type MutationUpdateMedicalMessagePublishStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateMedicalMessageVariantActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdatePaymentArgs = {
  paymentUpdateInput: PaymentUpdateInput;
};

export type MutationUpdateQuestionArgs = {
  id: Scalars["ID"];
  input: QuestionInput;
};

export type MutationUpdateRiskFactorTemplateArgs = {
  id: Scalars["ID"];
  input: RiskFactorTemplateUpdateInput;
};

export type MutationUpdateRiskFactorTemplateActiveStatusArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateSubListArgs = {
  subList?: InputMaybe<SubListInput>;
};

export type MutationUpdateSubListItemArgs = {
  subListItem: SubListItemInput;
};

export type MutationUpdateSurveyArgs = {
  id: Scalars["ID"];
  input: SurveyInput;
};

export type MutationUpdateTemplateActivityItemArgs = {
  activityItems?: InputMaybe<Array<InputMaybe<TemplateActivityEventItemInput>>>;
};

export type MutationUpdateTemplateActivityItemsArgs = {
  activityId: Scalars["ID"];
  activityItems?: InputMaybe<Array<InputMaybe<TemplateActivityEventItemInput>>>;
};

export type MutationUpdateTemplateInterventionActivityItemsArgs = {
  activityId: Scalars["ID"];
  activityItems?: InputMaybe<Array<InputMaybe<TemplateInterventionActivityEventItemInput>>>;
};

export type MutationUpdateUserEmailMobileArgs = {
  input: UpdateUserEmailMobileInput;
};

export type MutationUpdateVisitPrescriptionAuthStatusArgs = {
  input: PriorAuthorizationInput;
  prescriptionReference: Scalars["String"];
};

export type MutationUploadAttachmentArgs = {
  input: AttachmentInput;
};

export type MutationUploadBulkFileArgs = {
  code: BulkTemplateCode;
  entityID?: InputMaybe<Scalars["ID"]>;
  fileName: Scalars["String"];
};

export type MutationUploadCodeSystemFileArgs = {
  codeSystemCode: CodeSystemCode;
  fileName: Scalars["String"];
};

export type MutationUploadNationalCardArgs = {
  input: UploadNationalCardInput;
};

export type MutationUploadSubListArgs = {
  fileName: Scalars["String"];
  subListId: Scalars["ID"];
};

export type MutationUserAvatarUpdateArgs = {
  image: Scalars["String"];
};

export type MutationUserCreateArgs = {
  input: AdminUserCreateInput;
};

export type MutationUserDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationUserManagementSiteSettingsUpdateArgs = {
  input: UserManagementSiteSettingsInput;
};

export type MutationUserProfileUpdateArgs = {
  input: UserProfileUpdateInput;
};

export type MutationUserUpdateArgs = {
  id: Scalars["ID"];
  input: UserUpdateInput;
};

export type MutationValidatePrescriptionArgs = {
  input: ValidatePrescriptionInput;
};

export type MutationValidateProceduresArgs = {
  input: ValidateProceduresInput;
};

export type MutationVendorCreateArgs = {
  input: VendorAdminInput;
};

export type MutationVendorDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationVendorDepartmentCreateArgs = {
  input: VendorDepartmentCreateInput;
};

export type MutationVendorDepartmentDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationVendorDepartmentUpdateArgs = {
  id: Scalars["ID"];
  input: VendorDepartmentUpdateInput;
};

export type MutationVendorImageCreateArgs = {
  input: VendorImageCreateInput;
};

export type MutationVendorImageDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationVendorImageReorderArgs = {
  imagesIds: Array<InputMaybe<Scalars["ID"]>>;
  vendorId: Scalars["ID"];
};

export type MutationVendorImageUpdateArgs = {
  id: Scalars["ID"];
  input: VendorImageUpdateInput;
};

export type MutationVendorMarkMessagesAsSeenArgs = {
  branchId: Scalars["String"];
  customerId: Scalars["String"];
};

export type MutationVendorOrderAcceptArgs = {
  id: Scalars["ID"];
  input?: InputMaybe<VendorOrderAcceptInput>;
};

export type MutationVendorOrderCancelArgs = {
  cancellationReason: Scalars["String"];
  id: Scalars["ID"];
};

export type MutationVendorOrderDeliveryStatusUpdateArgs = {
  id: Scalars["ID"];
  input: OrderDeliveryStatusUpdateInput;
};

export type MutationVendorOrderRejectArgs = {
  id: Scalars["ID"];
  rejectionReason: Scalars["String"];
};

export type MutationVendorRegisterArgs = {
  input: VendorInput;
};

export type MutationVendorReviewDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationVendorReviewPublishArgs = {
  id: Scalars["ID"];
  input: VendorReviewPublishInput;
};

export type MutationVendorSendMessageArgs = {
  input: VendorMessageInput;
};

export type MutationVendorUpdateArgs = {
  id: Scalars["ID"];
  input: VendorUpdateAdminInput;
};

export type MutationVendorUpdateEnableVisitDetailsArgs = {
  id: Scalars["ID"];
  input: VendorUpdateEnableVisitDetailsInput;
};

export type MutationVendorUpdateIsIntegratedArgs = {
  id: Scalars["ID"];
  input: VendorUpdateIsIntegratedInput;
};

export type MutationVendorUserCreateArgs = {
  input: VendorUserCreateInput;
};

export type MutationVendorUserDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationVendorUserUpdateArgs = {
  id: Scalars["ID"];
  input: VendorUserUpdateInput;
};

export type MutationVerifyCredentialsArgs = {
  email?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  recaptchaToken?: InputMaybe<Scalars["String"]>;
};

export type MutationVerifyNationalIdArgs = {
  id: Scalars["ID"];
  input: VerifyNationalIdInput;
};

export type MutationVisitAssignBranchArgs = {
  branchId: Scalars["ID"];
  id: Scalars["ID"];
};

export type MutationVisitAssistantAgentStatusUpdateArgs = {
  connected: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationVisitCallEndArgs = {
  visitId: Scalars["ID"];
};

export type MutationVisitCallRejoinArgs = {
  visitId: Scalars["ID"];
};

export type MutationVisitComplainCreateArgs = {
  input: VisitComplainCreateInput;
};

export type MutationVisitDoctorAssignArgs = {
  doctorAvailabilityStatus?: InputMaybe<Scalars["String"]>;
  doctorId: Scalars["ID"];
  doctorUserId: Scalars["ID"];
  visitId: Scalars["ID"];
};

export type MutationVisitDoctorNoteUpdateArgs = {
  input: VisitDoctorNoteInput;
};

export type MutationVisitEvaluationRateSetArgs = {
  callQualityRate?: InputMaybe<Scalars["Float"]>;
  consultationRate?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
};

export type MutationVisitNewCallStartArgs = {
  visitId: Scalars["ID"];
};

export type MutationVisitStatusUpdateArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  input?: InputMaybe<VisitStatusUpdateInput>;
};

export type MutationVisitSummaryCreateArgs = {
  input: VisitSummaryInput;
};

export type MutationVisitSummaryDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationVisitSummaryUpdateArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  input: VisitSummaryInput;
};

export type MutationVisitTranscriptionArgs = {
  input: TranscribeInput;
};

export type MutationConsumerAppointmentCreate = {
  __typename?: "MutationConsumerAppointmentCreate";
  appointment?: Maybe<Appointment>;
  providerGuidedCareHealthProgramTeamErrors?: Maybe<Array<H_EntityError>>;
};

export type MutationPersonalizedConsumerAppointmentCreate = {
  __typename?: "MutationPersonalizedConsumerAppointmentCreate";
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  requestStatus?: Maybe<RequestStatus>;
};

export type MyReviewForVendor = {
  __typename?: "MyReviewForVendor";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  review?: Maybe<VendorReview>;
  vendorErrors: Array<VendorError>;
};

export type NeedActionCodeSystems = {
  __typename?: "NeedActionCodeSystems";
  codeSystemEvents?: Maybe<Array<Maybe<CodeSystemEvent>>>;
  count?: Maybe<Scalars["Int"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars["ID"];
};

export enum NotificationChannel {
  App = "APP",
  Email = "EMAIL",
  Sms = "SMS",
}

export enum NotificationType {
  BulkFile = "BULK_FILE",
  Chat = "CHAT",
  ChronicProgramEnrollment = "CHRONIC_PROGRAM_ENROLLMENT",
  DoctorAvailabilityStatusChanged = "DOCTOR_AVAILABILITY_STATUS_CHANGED",
  ErxCreate = "ERX_CREATE",
  ErxDeliveryStatusUpdate = "ERX_DELIVERY_STATUS_UPDATE",
  ErxStatusUpdate = "ERX_STATUS_UPDATE",
  GuidedCareAppointmentBookingReminder = "GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER",
  GuidedCareInterventionNotification = "GUIDED_CARE_INTERVENTION_NOTIFICATION",
  GuidedCareTaskNotification = "GUIDED_CARE_TASK_NOTIFICATION",
  HealthParameters = "HEALTH_PARAMETERS",
  HealthProgramAppointmentReminder = "HEALTH_PROGRAM_APPOINTMENT_REMINDER",
  HealthProgramAppointmentStatusChanged = "HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED",
  HealthProgramNotification = "HEALTH_PROGRAM_NOTIFICATION",
  HealthProgramVisitStatusChanged = "HEALTH_PROGRAM_VISIT_STATUS_CHANGED",
  MarketplaceCheckoutCompleteFailure = "MARKETPLACE_CHECKOUT_COMPLETE_FAILURE",
  MarketplaceOrderStatusUpdate = "MARKETPLACE_ORDER_STATUS_UPDATE",
  MarkAllAsRead = "MARK_ALL_AS_READ",
  MarkAsRead = "MARK_AS_READ",
  MedicalForm = "MEDICAL_FORM",
  MedicalMessage = "MEDICAL_MESSAGE",
  NeedActionCodeSystem = "NEED_ACTION_CODE_SYSTEM",
  NewNotification = "NEW_NOTIFICATION",
  OrderDeleted = "ORDER_DELETED",
  OrderStatusUpdate = "ORDER_STATUS_UPDATE",
  OrderTimeoutAlert = "ORDER_TIMEOUT_ALERT",
  PatientProfileAccessClose = "PATIENT_PROFILE_ACCESS_CLOSE",
  PatientProfileAccessRequest = "PATIENT_PROFILE_ACCESS_REQUEST",
  PatientProfileAccessResponse = "PATIENT_PROFILE_ACCESS_RESPONSE",
  PaymentNotification = "PAYMENT_NOTIFICATION",
  PingPong = "PING_PONG",
  PrescriptionAuthStatusChanged = "PRESCRIPTION_AUTH_STATUS_CHANGED",
  SurveyNotification = "SURVEY_NOTIFICATION",
  VisitVitalSigns = "VISIT_VITAL_SIGNS",
}

export type Nurse = Node & {
  __typename?: "Nurse";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type NurseInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export type Nutritionist = Node & {
  __typename?: "Nutritionist";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type NutritionistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export enum OperationStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Successful = "SUCCESSFUL",
}

export enum OperationType {
  AddToWallet = "ADD_TO_WALLET",
  DeductFromWallet = "DEDUCT_FROM_WALLET",
  Refund = "REFUND",
  RefundFromWallet = "REFUND_FROM_WALLET",
}

export enum Operator {
  Contains = "CONTAINS",
  Count = "COUNT",
  EndWith = "END_WITH",
  Equal = "EQUAL",
  GreaterThan = "GREATER_THAN",
  GreaterThanOrEqual = "GREATER_THAN_OR_EQUAL",
  In = "IN",
  IsEmpty = "IS_EMPTY",
  IsNotEmpty = "IS_NOT_EMPTY",
  LessThan = "LESS_THAN",
  LessThanOrEqual = "LESS_THAN_OR_EQUAL",
  No = "NO",
  NotContains = "NOT_CONTAINS",
  NotEqual = "NOT_EQUAL",
  NotIn = "NOT_IN",
  StartWith = "START_WITH",
  Yes = "YES",
}

export type Optometrist = Node & {
  __typename?: "Optometrist";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type OptometristInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export type Order = Node & {
  __typename?: "Order";
  branch?: Maybe<Branch>;
  carrierName?: Maybe<Scalars["String"]>;
  children?: Maybe<Array<Maybe<Order>>>;
  codPaymentAmount: Money;
  created: Scalars["DateTime"];
  customerNote: Scalars["String"];
  deliveryDate?: Maybe<Scalars["Date"]>;
  deliveryStatus?: Maybe<Scalars["String"]>;
  deliveryTimeSlot?: Maybe<DeliveryTimeSlot>;
  discount?: Maybe<Money>;
  discountName?: Maybe<Scalars["String"]>;
  displayGrossPrices: Scalars["Boolean"];
  distanceGroup?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<OrderEvent>>>;
  failReason?: Maybe<Scalars["String"]>;
  helpDiskTicketId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isCodPayment?: Maybe<Scalars["Boolean"]>;
  isDeliveryRequested: Scalars["Boolean"];
  isFreeDelivery: Scalars["Boolean"];
  isPaid?: Maybe<Scalars["Boolean"]>;
  languageCode: Scalars["String"];
  lines: Array<Maybe<OrderLine>>;
  modified: Scalars["DateTime"];
  nationalId?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  parent?: Maybe<Order>;
  paymentMethod?: Maybe<OrderPaymentMethod>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  pickUpOtp?: Maybe<Scalars["String"]>;
  prescription?: Maybe<Prescription>;
  rejectionReason?: Maybe<OrderRejectionReason>;
  serviceCommissions?: Maybe<Scalars["Float"]>;
  shippingAddress?: Maybe<OrderAddress>;
  shippingPrice?: Maybe<TaxedMoney>;
  source: OrderSource;
  status: OrderStatus;
  subtotal?: Maybe<TaxedMoney>;
  total?: Maybe<TaxedMoney>;
  totalBalance: Money;
  totalOrderDistance?: Maybe<Scalars["Float"]>;
  trackingClientId: Scalars["String"];
  translatedDiscountName?: Maybe<Scalars["String"]>;
  type?: Maybe<OrderTypesEnum>;
  user?: Maybe<User>;
  userEmail?: Maybe<Scalars["String"]>;
  weight?: Maybe<Weight>;
};

export type OrderPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrderPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrderAddNote = {
  __typename?: "OrderAddNote";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  event?: Maybe<OrderEvent>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderAddNoteInput = {
  message: Scalars["String"];
};

export type OrderAddress = Node & {
  __typename?: "OrderAddress";
  area: Scalars["String"];
  buildingName?: Maybe<Scalars["String"]>;
  buildingNameAr?: Maybe<Scalars["String"]>;
  buildingNumber?: Maybe<Scalars["String"]>;
  city?: Maybe<City>;
  coordinates?: Maybe<LocationType>;
  district: Scalars["String"];
  districtAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  phone: Scalars["String"];
  postalCode: Scalars["String"];
  region: Scalars["String"];
  regionAr?: Maybe<Scalars["String"]>;
  streetAddress1: Scalars["String"];
  streetAddress1Ar?: Maybe<Scalars["String"]>;
  streetAddress2: Scalars["String"];
  streetAddress2Ar?: Maybe<Scalars["String"]>;
  unitNumber?: Maybe<Scalars["String"]>;
};

export type OrderCancel = {
  __typename?: "OrderCancel";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderCountableConnection = {
  __typename?: "OrderCountableConnection";
  edges: Array<OrderCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrderCountableEdge = {
  __typename?: "OrderCountableEdge";
  cursor: Scalars["String"];
  node: Order;
};

export type OrderDashboardFilterInput = {
  created?: InputMaybe<DateRangeInput>;
};

export enum OrderDeliveryStatusEnum {
  CarrierAllocated = "CARRIER_ALLOCATED",
  DeliveryTimedOut = "DELIVERY_TIMED_OUT",
  DriverAcceptedOrder = "DRIVER_ACCEPTED_ORDER",
  DriverIsAboutToArrive = "DRIVER_IS_ABOUT_TO_ARRIVE",
  DriverPickedUpOrder = "DRIVER_PICKED_UP_ORDER",
  Failed = "FAILED",
  OrderIsCancelled = "ORDER_IS_CANCELLED",
  OrderIsDelivered = "ORDER_IS_DELIVERED",
  OrderIsResumed = "ORDER_IS_RESUMED",
  OrderIsSuspended = "ORDER_IS_SUSPENDED",
  Pending = "PENDING",
  PickedByConsumer = "PICKED_BY_CONSUMER",
  Returned = "RETURNED",
}

export type OrderDeliveryStatusUpdate = {
  __typename?: "OrderDeliveryStatusUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderDeliveryStatusUpdateInput = {
  carrierName?: InputMaybe<Scalars["String"]>;
  customOrderRejectionReason?: InputMaybe<Scalars["String"]>;
  deliveryPrice?: InputMaybe<Scalars["Float"]>;
  deliveryStatus: OrderDeliveryStatusEnum;
  distanceGroup?: InputMaybe<Scalars["String"]>;
  orderRejectionReason?: InputMaybe<Scalars["ID"]>;
  pickUpOtp?: InputMaybe<Scalars["String"]>;
  totalOrderDistance?: InputMaybe<Scalars["Float"]>;
};

export enum OrderDirection {
  /** Specifies an ascending sort order. */
  Asc = "ASC",
  /** Specifies a descending sort order. */
  Desc = "DESC",
}

export type OrderDraftFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  created?: InputMaybe<DateRangeInput>;
};

export type OrderError = {
  __typename?: "OrderError";
  branch?: Maybe<Scalars["ID"]>;
  code: OrderErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  orderLine?: Maybe<Scalars["ID"]>;
};

export enum OrderErrorCode {
  BillingAddressNotSet = "BILLING_ADDRESS_NOT_SET",
  CannotAcceptOrder = "CANNOT_ACCEPT_ORDER",
  CannotCancelFulfillment = "CANNOT_CANCEL_FULFILLMENT",
  CannotCancelOrder = "CANNOT_CANCEL_ORDER",
  CannotDelete = "CANNOT_DELETE",
  CannotRefund = "CANNOT_REFUND",
  CannotRejectOrder = "CANNOT_REJECT_ORDER",
  CaptureInactivePayment = "CAPTURE_INACTIVE_PAYMENT",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  FulfillOrderLine = "FULFILL_ORDER_LINE",
  GraphqlError = "GRAPHQL_ERROR",
  InsufficientStock = "INSUFFICIENT_STOCK",
  Invalid = "INVALID",
  NotEditable = "NOT_EDITABLE",
  NotFound = "NOT_FOUND",
  OrderNoShippingAddress = "ORDER_NO_SHIPPING_ADDRESS",
  PaymentError = "PAYMENT_ERROR",
  PaymentMissing = "PAYMENT_MISSING",
  ProductNotPublished = "PRODUCT_NOT_PUBLISHED",
  Required = "REQUIRED",
  ShippingMethodNotApplicable = "SHIPPING_METHOD_NOT_APPLICABLE",
  ShippingMethodRequired = "SHIPPING_METHOD_REQUIRED",
  Unique = "UNIQUE",
  VoidInactivePayment = "VOID_INACTIVE_PAYMENT",
  ZeroQuantity = "ZERO_QUANTITY",
}

export type OrderEvent = Node & {
  __typename?: "OrderEvent";
  amount?: Maybe<Scalars["Float"]>;
  composedId?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  emailType?: Maybe<OrderEventsEmailsEnum>;
  id: Scalars["ID"];
  lines?: Maybe<Array<Maybe<OrderEventOrderLineObject>>>;
  message?: Maybe<Scalars["String"]>;
  orderNumber?: Maybe<Scalars["String"]>;
  oversoldItems?: Maybe<Array<Maybe<Scalars["String"]>>>;
  paymentGateway?: Maybe<Scalars["String"]>;
  paymentId?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  type?: Maybe<OrderEventsEnum>;
  user?: Maybe<User>;
};

export type OrderEventOrderLineObject = {
  __typename?: "OrderEventOrderLineObject";
  itemName?: Maybe<Scalars["String"]>;
  orderLine?: Maybe<OrderLine>;
  quantity?: Maybe<Scalars["Int"]>;
};

export enum OrderEventsEmailsEnum {
  FulfillmentConfirmation = "FULFILLMENT_CONFIRMATION",
  OrderConfirmation = "ORDER_CONFIRMATION",
  PaymentConfirmation = "PAYMENT_CONFIRMATION",
  ShippingConfirmation = "SHIPPING_CONFIRMATION",
  TrackingUpdated = "TRACKING_UPDATED",
}

export enum OrderEventsEnum {
  Allocated = "ALLOCATED",
  Canceled = "CANCELED",
  CardPaid = "CARD_PAID",
  Consumed = "CONSUMED",
  ConsumerCanceled = "CONSUMER_CANCELED",
  ConsumerPicked = "CONSUMER_PICKED",
  Delivered = "DELIVERED",
  DeliveredTimedOut = "DELIVERED_TIMED_OUT",
  Dispensed = "DISPENSED",
  DraftAddedProducts = "DRAFT_ADDED_PRODUCTS",
  DraftCreated = "DRAFT_CREATED",
  DraftCustomerAccepted = "DRAFT_CUSTOMER_ACCEPTED",
  DraftRemovedProducts = "DRAFT_REMOVED_PRODUCTS",
  DriverAcceptedOrder = "DRIVER_ACCEPTED_ORDER",
  DriverDelivered = "DRIVER_DELIVERED",
  DriverIsAboutToArrive = "DRIVER_IS_ABOUT_TO_ARRIVE",
  DriverPicked = "DRIVER_PICKED",
  DriverReturned = "DRIVER_RETURNED",
  EmailSent = "EMAIL_SENT",
  ErxHubAuthRequestFailed = "ERX_HUB_AUTH_REQUEST_FAILED",
  ErxHubAuthSubmitTimedOut = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT",
  ErxHubClaimApproved = "ERX_HUB_CLAIM_APPROVED",
  ErxHubClaimFailed = "ERX_HUB_CLAIM_FAILED",
  ErxHubClaimRejected = "ERX_HUB_CLAIM_REJECTED",
  ErxHubClaimSubmitTimedOut = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT",
  ErxHubFullyApproved = "ERX_HUB_FULLY_APPROVED",
  ErxHubPartiallyApproved = "ERX_HUB_PARTIALLY_APPROVED",
  ErxHubRejected = "ERX_HUB_REJECTED",
  ErxHubTimedOut = "ERX_HUB_TIMED_OUT",
  Failed = "FAILED",
  FulfillmentCanceled = "FULFILLMENT_CANCELED",
  FulfillmentFulfilledItems = "FULFILLMENT_FULFILLED_ITEMS",
  FulfillmentRestockedItems = "FULFILLMENT_RESTOCKED_ITEMS",
  NoteAdded = "NOTE_ADDED",
  OrderCarrierAllocated = "ORDER_CARRIER_ALLOCATED",
  OrderCreated = "ORDER_CREATED",
  OrderDeliveryFailed = "ORDER_DELIVERY_FAILED",
  OrderDeliveryIsCancelled = "ORDER_DELIVERY_IS_CANCELLED",
  OrderDeliveryIsResumed = "ORDER_DELIVERY_IS_RESUMED",
  OrderDeliveryIsSuspended = "ORDER_DELIVERY_IS_SUSPENDED",
  OrderDeliveryTimedOut = "ORDER_DELIVERY_TIMED_OUT",
  OrderFullyPaid = "ORDER_FULLY_PAID",
  OrderMarkedAsPaid = "ORDER_MARKED_AS_PAID",
  OrderPickedByCustomer = "ORDER_PICKED_BY_CUSTOMER",
  Other = "OTHER",
  OutForDelivery = "OUT_FOR_DELIVERY",
  OutForDeliveryTimedOut = "OUT_FOR_DELIVERY_TIMED_OUT",
  OversoldItems = "OVERSOLD_ITEMS",
  PaymentCaptured = "PAYMENT_CAPTURED",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentOnArrive = "PAYMENT_ON_ARRIVE",
  PaymentPending = "PAYMENT_PENDING",
  PaymentRefunded = "PAYMENT_REFUNDED",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  PaymentTimedOut = "PAYMENT_TIMED_OUT",
  PaymentVoided = "PAYMENT_VOIDED",
  PendingDelivery = "PENDING_DELIVERY",
  Placed = "PLACED",
  PlacedFromDraft = "PLACED_FROM_DRAFT",
  PrescriptionOrderCreated = "PRESCRIPTION_ORDER_CREATED",
  PrescriptionOrderVendorAssigned = "PRESCRIPTION_ORDER_VENDOR_ASSIGNED",
  PrescriptionRefillOrderCreated = "PRESCRIPTION_REFILL_ORDER_CREATED",
  ReadyForCustomerPickup = "READY_FOR_CUSTOMER_PICKUP",
  Returned = "RETURNED",
  ReturnedTimedOut = "RETURNED_TIMED_OUT",
  ReturnInProgress = "RETURN_IN_PROGRESS",
  TrackingUpdated = "TRACKING_UPDATED",
  UpdatedAddress = "UPDATED_ADDRESS",
  VendorAccepted = "VENDOR_ACCEPTED",
  VendorCanceled = "VENDOR_CANCELED",
  VendorDelivered = "VENDOR_DELIVERED",
  VendorFinishedProcessing = "VENDOR_FINISHED_PROCESSING",
  VendorRejected = "VENDOR_REJECTED",
  VendorResponseTimedOut = "VENDOR_RESPONSE_TIMED_OUT",
  VendorReturned = "VENDOR_RETURNED",
  WaitingErxHubAuthApproval = "WAITING_ERX_HUB_AUTH_APPROVAL",
  WaitingErxHubClaimApproval = "WAITING_ERX_HUB_CLAIM_APPROVAL",
}

export type OrderFilterInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  childrenAndFailedOrders?: InputMaybe<Scalars["Boolean"]>;
  clinician?: InputMaybe<Scalars["String"]>;
  created?: InputMaybe<DateRangeInput>;
  deliveryStatus?: InputMaybe<Array<InputMaybe<OrderDeliveryStatusEnum>>>;
  hasHelpDiskTicket?: InputMaybe<Scalars["Boolean"]>;
  helpDiskTicketId?: InputMaybe<Scalars["String"]>;
  helpDiskTicketId_Icontains?: InputMaybe<Scalars["String"]>;
  isDelivery?: InputMaybe<Scalars["Boolean"]>;
  modified?: InputMaybe<DateRangeInput>;
  nationalId?: InputMaybe<Scalars["String"]>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  orderGlobalSearch?: InputMaybe<Scalars["String"]>;
  parentOrders?: InputMaybe<Scalars["Boolean"]>;
  prescription?: InputMaybe<Scalars["ID"]>;
  price?: InputMaybe<IntRangeInput>;
  referenceNumber?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Array<InputMaybe<OrderSourcesEnum>>>;
  status?: InputMaybe<Array<InputMaybe<OrderStatusEnum>>>;
};

export type OrderGetErxHubApproval = {
  __typename?: "OrderGetErxHubApproval";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderLine = Node & {
  __typename?: "OrderLine";
  authStatus: OrderLineAuthStatus;
  children?: Maybe<Array<Maybe<OrderLine>>>;
  id: Scalars["ID"];
  medication?: Maybe<Medication>;
  productSku: Scalars["String"];
  quantity: Scalars["Int"];
  quantityFulfilled: Scalars["Int"];
  rejectionReason?: Maybe<Scalars["String"]>;
  taxRate: Scalars["Float"];
  thumbnail?: Maybe<Image>;
  tradeDrug?: Maybe<TradeDrug>;
  unitPrice?: Maybe<TaxedMoney>;
  variantCode: Scalars["String"];
  variantName: Scalars["String"];
};

export enum OrderLineAuthStatus {
  Approved = "APPROVED",
  New = "NEW",
  PartialApproved = "PARTIAL_APPROVED",
  Rejected = "REJECTED",
}

export type OrderLineCountableConnection = {
  __typename?: "OrderLineCountableConnection";
  edges: Array<OrderLineCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrderLineCountableEdge = {
  __typename?: "OrderLineCountableEdge";
  cursor: Scalars["String"];
  node: OrderLine;
};

export enum OrderPaymentMethod {
  CreditWhenArrive = "CREDIT_WHEN_ARRIVE",
  PayNow = "PAY_NOW",
}

export type OrderRejectionReason = Node & {
  __typename?: "OrderRejectionReason";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  orders: OrderCountableConnection;
  reason: Scalars["String"];
  type: OrderRejectionReasonType;
};

export type OrderRejectionReasonOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrderRejectionReasonCountableConnection = {
  __typename?: "OrderRejectionReasonCountableConnection";
  edges: Array<OrderRejectionReasonCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrderRejectionReasonCountableEdge = {
  __typename?: "OrderRejectionReasonCountableEdge";
  cursor: Scalars["String"];
  node: OrderRejectionReason;
};

export type OrderRejectionReasonCreate = {
  __typename?: "OrderRejectionReasonCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderRejectionReason?: Maybe<OrderRejectionReason>;
  orderRejectionReasonErrors: Array<OrderRejectionReasonError>;
};

export type OrderRejectionReasonDelete = {
  __typename?: "OrderRejectionReasonDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderRejectionReason?: Maybe<OrderRejectionReason>;
  orderRejectionReasonErrors: Array<OrderRejectionReasonError>;
};

export type OrderRejectionReasonError = {
  __typename?: "OrderRejectionReasonError";
  code: OrderRejectionReasonErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum OrderRejectionReasonErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type OrderRejectionReasonInput = {
  reason: Scalars["String"];
};

export enum OrderRejectionReasonType {
  Custom = "CUSTOM",
  System = "SYSTEM",
}

export type OrderRejectionReasonUpdate = {
  __typename?: "OrderRejectionReasonUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderRejectionReason?: Maybe<OrderRejectionReason>;
  orderRejectionReasonErrors: Array<OrderRejectionReasonError>;
};

export enum OrderSortField {
  CreationDate = "CREATION_DATE",
  Customer = "CUSTOMER",
  FulfillmentStatus = "FULFILLMENT_STATUS",
  ModifiedDate = "MODIFIED_DATE",
  Number = "NUMBER",
  StatusAndCreated = "STATUS_AND_CREATED",
  Total = "TOTAL",
}

export type OrderSortingInput = {
  direction: OrderDirection;
  field: OrderSortField;
};

export enum OrderSource {
  PrescriptionDispense = "PRESCRIPTION_DISPENSE",
  PrescriptionRefill = "PRESCRIPTION_REFILL",
}

export enum OrderSourcesEnum {
  PrescriptionDispense = "PRESCRIPTION_DISPENSE",
  PrescriptionRefill = "PRESCRIPTION_REFILL",
}

export enum OrderStatus {
  AcceptedByProvider = "ACCEPTED_BY_PROVIDER",
  Allocated = "ALLOCATED",
  CanceledByConsumer = "CANCELED_BY_CONSUMER",
  CanceledByProvider = "CANCELED_BY_PROVIDER",
  Consumed = "CONSUMED",
  ConsumedTimedOut = "CONSUMED_TIMED_OUT",
  Delivered = "DELIVERED",
  DeliveredTimedOut = "DELIVERED_TIMED_OUT",
  Dispensed = "DISPENSED",
  Draft = "DRAFT",
  ErxHubAuthRequestFailed = "ERX_HUB_AUTH_REQUEST_FAILED",
  ErxHubAuthSubmitTimedOut = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT",
  ErxHubClaimApproved = "ERX_HUB_CLAIM_APPROVED",
  ErxHubClaimFailed = "ERX_HUB_CLAIM_FAILED",
  ErxHubClaimRejected = "ERX_HUB_CLAIM_REJECTED",
  ErxHubClaimSubmitTimedOut = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT",
  ErxHubFullyApproved = "ERX_HUB_FULLY_APPROVED",
  ErxHubPartiallyApproved = "ERX_HUB_PARTIALLY_APPROVED",
  ErxHubRejected = "ERX_HUB_REJECTED",
  ErxHubTimedOut = "ERX_HUB_TIMED_OUT",
  Failed = "FAILED",
  Fulfilled = "FULFILLED",
  InProgress = "IN_PROGRESS",
  NewRequest = "NEW_REQUEST",
  OutForDelivery = "OUT_FOR_DELIVERY",
  OutForDeliveryTimedOut = "OUT_FOR_DELIVERY_TIMED_OUT",
  PartiallyFulfilled = "PARTIALLY_FULFILLED",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentOnArrive = "PAYMENT_ON_ARRIVE",
  PaymentPending = "PAYMENT_PENDING",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  PaymentTimedOut = "PAYMENT_TIMED_OUT",
  ReadyForCustomerPickup = "READY_FOR_CUSTOMER_PICKUP",
  RejectedByProvider = "REJECTED_BY_PROVIDER",
  Returned = "RETURNED",
  ReturnedTimedOut = "RETURNED_TIMED_OUT",
  ReturnInProgress = "RETURN_IN_PROGRESS",
  Unfulfilled = "UNFULFILLED",
  VendorResponseTimedOut = "VENDOR_RESPONSE_TIMED_OUT",
  WaitingErxHubApproval = "WAITING_ERX_HUB_APPROVAL",
  WaitingErxHubClaimApproval = "WAITING_ERX_HUB_CLAIM_APPROVAL",
  WaitingProviderSelection = "WAITING_PROVIDER_SELECTION",
}

export enum OrderStatusEnum {
  AcceptedByProvider = "ACCEPTED_BY_PROVIDER",
  Allocated = "ALLOCATED",
  CanceledByConsumer = "CANCELED_BY_CONSUMER",
  CanceledByProvider = "CANCELED_BY_PROVIDER",
  Consumed = "CONSUMED",
  ConsumedTimedOut = "CONSUMED_TIMED_OUT",
  Delivered = "DELIVERED",
  DeliveredTimedOut = "DELIVERED_TIMED_OUT",
  Dispensed = "DISPENSED",
  Draft = "DRAFT",
  ErxHubAuthRequestFailed = "ERX_HUB_AUTH_REQUEST_FAILED",
  ErxHubAuthSubmitTimedOut = "ERX_HUB_AUTH_SUBMIT_TIMED_OUT",
  ErxHubClaimApproved = "ERX_HUB_CLAIM_APPROVED",
  ErxHubClaimFailed = "ERX_HUB_CLAIM_FAILED",
  ErxHubClaimRejected = "ERX_HUB_CLAIM_REJECTED",
  ErxHubClaimSubmitTimedOut = "ERX_HUB_CLAIM_SUBMIT_TIMED_OUT",
  ErxHubFullyApproved = "ERX_HUB_FULLY_APPROVED",
  ErxHubPartiallyApproved = "ERX_HUB_PARTIALLY_APPROVED",
  ErxHubRejected = "ERX_HUB_REJECTED",
  ErxHubTimedOut = "ERX_HUB_TIMED_OUT",
  Failed = "FAILED",
  Fulfilled = "FULFILLED",
  InProgress = "IN_PROGRESS",
  NewRequest = "NEW_REQUEST",
  OutForDelivery = "OUT_FOR_DELIVERY",
  OutForDeliveryTimedOut = "OUT_FOR_DELIVERY_TIMED_OUT",
  PartiallyFulfilled = "PARTIALLY_FULFILLED",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentOnArrive = "PAYMENT_ON_ARRIVE",
  PaymentPending = "PAYMENT_PENDING",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  PaymentTimedOut = "PAYMENT_TIMED_OUT",
  ReadyForCustomerPickup = "READY_FOR_CUSTOMER_PICKUP",
  RejectedByProvider = "REJECTED_BY_PROVIDER",
  Returned = "RETURNED",
  ReturnedTimedOut = "RETURNED_TIMED_OUT",
  ReturnInProgress = "RETURN_IN_PROGRESS",
  Unfulfilled = "UNFULFILLED",
  VendorResponseTimedOut = "VENDOR_RESPONSE_TIMED_OUT",
  WaitingErxHubApproval = "WAITING_ERX_HUB_APPROVAL",
  WaitingErxHubClaimApproval = "WAITING_ERX_HUB_CLAIM_APPROVAL",
  WaitingProviderSelection = "WAITING_PROVIDER_SELECTION",
}

export type OrderStatusUpdate = {
  __typename?: "OrderStatusUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OrderStatusUpdateInput = {
  failReason?: InputMaybe<Scalars["String"]>;
  helpDiskTicketId?: InputMaybe<Scalars["String"]>;
  medicationsStatuses?: InputMaybe<Array<InputMaybe<MedicationAuthorizationStatusInput>>>;
  status: OrderStatusEnum;
};

export enum OrderTypesEnum {
  HomeDelivery = "HOME_DELIVERY",
  PickupFromPharmacy = "PICKUP_FROM_PHARMACY",
}

export type OrderVendorFinishProcessing = {
  __typename?: "OrderVendorFinishProcessing";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type OtpjAssessment = H_Node & {
  __typename?: "OtpjAssessment";
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  patient?: Maybe<Patient>;
  skipped?: Maybe<Scalars["Boolean"]>;
  vendor?: Maybe<Vendor>;
};

export type OtpjAssessmentInput = {
  appointmentId?: InputMaybe<Scalars["ID"]>;
  departmentId: Scalars["ID"];
  details?: InputMaybe<Scalars["String"]>;
  outPatientJourneyId?: InputMaybe<Scalars["ID"]>;
  patientId: Scalars["ID"];
  skipped?: InputMaybe<Scalars["Boolean"]>;
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type OtpjCheckIn = H_Node & {
  __typename?: "OtpjCheckIn";
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  patient?: Maybe<Patient>;
  patientCopayment?: Maybe<Scalars["String"]>;
  patientInsurance?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Vendor>;
};

export type OtpjCheckInInput = {
  appointmentId?: InputMaybe<Scalars["ID"]>;
  departmentId: Scalars["ID"];
  details?: InputMaybe<Scalars["String"]>;
  outPatientJourneyId?: InputMaybe<Scalars["ID"]>;
  patientCopayment?: InputMaybe<Scalars["String"]>;
  patientInsurance?: InputMaybe<Scalars["String"]>;
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type OtpjConsultation = H_Node & {
  __typename?: "OtpjConsultation";
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars["String"]>;
  doctor?: Maybe<Doctor>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  patient?: Maybe<Patient>;
  patientCopayment?: Maybe<Scalars["String"]>;
  patientInsurance?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Vendor>;
  vendorUser?: Maybe<User>;
};

export type OtpjConsultationInput = {
  appointmentId?: InputMaybe<Scalars["ID"]>;
  departmentId: Scalars["ID"];
  details?: InputMaybe<Scalars["String"]>;
  doctorId?: InputMaybe<Scalars["ID"]>;
  outPatientJourneyId?: InputMaybe<Scalars["ID"]>;
  patientId: Scalars["ID"];
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type OtpjExtPayment = H_Node & {
  __typename?: "OtpjExtPayment";
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  invoiceReferenceNumber?: Maybe<Scalars["String"]>;
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  patient?: Maybe<Patient>;
  vendor?: Maybe<Vendor>;
  vendorUser?: Maybe<User>;
};

export type OtpjExtPaymentInput = {
  appointmentId?: InputMaybe<Scalars["ID"]>;
  departmentId: Scalars["ID"];
  details?: InputMaybe<Scalars["String"]>;
  invoiceReferenceNumber?: InputMaybe<Scalars["String"]>;
  outPatientJourneyId?: InputMaybe<Scalars["ID"]>;
  patientId: Scalars["ID"];
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type OutPatientJourney = H_Node & {
  __typename?: "OutPatientJourney";
  branch?: Maybe<Branch>;
  cancellationReason?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  otpjAssessment?: Maybe<OtpjAssessment>;
  otpjCheckIn?: Maybe<OtpjCheckIn>;
  otpjConsultation?: Maybe<OtpjConsultation>;
  otpjExtPayment?: Maybe<OtpjExtPayment>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  priceAmount?: Maybe<Scalars["Float"]>;
  priceAmountAfterVat?: Maybe<Scalars["Float"]>;
  status?: Maybe<OutPatientJourneyStatus>;
  vatPercentage?: Maybe<Scalars["Float"]>;
  vendor?: Maybe<Vendor>;
  visit?: Maybe<Visit>;
};

export type OutPatientJourneyPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OutPatientJourneyPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OutPatientJourneyCountableConnection = {
  __typename?: "OutPatientJourneyCountableConnection";
  edges: Array<OutPatientJourneyCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OutPatientJourneyCountableEdge = {
  __typename?: "OutPatientJourneyCountableEdge";
  cursor: Scalars["String"];
  node: OutPatientJourney;
};

export enum OutPatientJourneyStatus {
  AssessmentDone = "ASSESSMENT_DONE",
  AssessmentPending = "ASSESSMENT_PENDING",
  Canceled = "CANCELED",
  CanceledByConsumer = "CANCELED_BY_CONSUMER",
  CheckedIn = "CHECKED_IN",
  Completed = "COMPLETED",
  ConsultationDone = "CONSULTATION_DONE",
  ConsultationPending = "CONSULTATION_PENDING",
  Incompleted = "INCOMPLETED",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentPending = "PAYMENT_PENDING",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  Pending = "PENDING",
  TreatmentDone = "TREATMENT_DONE",
}

export type OutPatientJourneyStatusHistory = H_Node & {
  __typename?: "OutPatientJourneyStatusHistory";
  branchId?: Maybe<Scalars["ID"]>;
  cancellationReason?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  departmentId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  refId?: Maybe<Scalars["ID"]>;
  status?: Maybe<OutPatientJourneyStatus>;
  vendorId?: Maybe<Scalars["ID"]>;
};

export type OutPatientJourneyStatusHistoryCountableConnection = {
  __typename?: "OutPatientJourneyStatusHistoryCountableConnection";
  edges: Array<OutPatientJourneyStatusHistoryCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OutPatientJourneyStatusHistoryCountableEdge = {
  __typename?: "OutPatientJourneyStatusHistoryCountableEdge";
  cursor: Scalars["String"];
  node: OutPatientJourneyStatusHistory;
};

export type OutPatientJourneyStatusHistoryFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  refIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  selfOnly?: InputMaybe<Scalars["Boolean"]>;
  statuses?: InputMaybe<Array<InputMaybe<OutPatientJourneyStatus>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type OutPatientJourneyStatusUpdateInput = {
  cancellationReason?: InputMaybe<Scalars["String"]>;
  status: OutPatientJourneyStatus;
};

export type OverlappedAppointment = H_Node & {
  __typename?: "OverlappedAppointment";
  careInitialType?: Maybe<CareType>;
  confirmedEndTime?: Maybe<Scalars["Instant"]>;
  confirmedStartTime?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  requestedBy?: Maybe<RequestedBy>;
  status?: Maybe<AppointmentStatus>;
  suggestedTimeSlotsOverlapped?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<AppointmentType>;
};

export type OverlappedAppointmentCountableConnection = {
  __typename?: "OverlappedAppointmentCountableConnection";
  edges: Array<OverlappedAppointmentCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OverlappedAppointmentCountableEdge = {
  __typename?: "OverlappedAppointmentCountableEdge";
  cursor: Scalars["String"];
  node: OverlappedAppointment;
};

export type P_SubscriptionEntityChanged = H_Node & {
  __typename?: "P_SubscriptionEntityChanged";
  changes?: Maybe<Array<Maybe<P_SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars["Instant"]>;
  graphqlType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  users?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type P_SubscriptionEntityFieldChanges = {
  __typename?: "P_SubscriptionEntityFieldChanges";
  field?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type P_SubscriptionKafkaTopic = {
  __typename?: "P_SubscriptionKafkaTopic";
  graphqlType?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  startCursor?: Maybe<Scalars["String"]>;
};

export type PasswordChange = {
  __typename?: "PasswordChange";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export enum PasswordResetMethodEnum {
  Email = "EMAIL",
  Mobile = "MOBILE",
}

export type Patient = Node & {
  __typename?: "Patient";
  activeAppointmentsInPerson?: Maybe<Array<Maybe<Appointment>>>;
  activeHealthProgramMembers?: Maybe<Array<Maybe<HealthProgramMember>>>;
  activeVisitsInPerson?: Maybe<Array<Maybe<Visit>>>;
  age?: Maybe<Scalars["Int"]>;
  /** needed to send the values of (doctorId, start, end) or the value of appointmentId */
  availableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  bloodGroup?: Maybe<Scalars["String"]>;
  bodyComposition?: Maybe<HealthProgramMemberBodyCompositionBasic>;
  bodyCompositions?: Maybe<HealthProgramMemberBodyCompositionCountableConnection>;
  chronicDiseases?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contactNumber?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["Date"]>;
  disabilities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  documentExpiryDate?: Maybe<Scalars["Date"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  guidedCareTeamChatGroups?: Maybe<Array<AnonymousChatGroup>>;
  healthProgramMembershipRequests: HealthProgramMembershipRequestConnection;
  healthProgramsByActiveMembers?: Maybe<Array<Maybe<HealthProgram>>>;
  healthProgramsByActiveUserSubscriptions?: Maybe<Array<Maybe<HealthProgram>>>;
  healthProgramsOfPayPerCallOnly?: Maybe<HealthProgramCountableConnection>;
  id: Scalars["ID"];
  idType?: Maybe<PatientIdType>;
  inActiveHealthProgramMembers?: Maybe<HealthProgramMemberCountableConnection>;
  lastName?: Maybe<Scalars["String"]>;
  maritalStatus?: Maybe<PatientMaritalStatus>;
  medicalFormAnswers?: Maybe<MedicalFormAnswerConnection>;
  modified: Scalars["DateTime"];
  nationalIdNumber?: Maybe<Scalars["String"]>;
  nationality?: Maybe<Scalars["String"]>;
  nationality3166Code?: Maybe<Scalars["String"]>;
  nationalityArabicDisplay?: Maybe<Scalars["String"]>;
  nationalityDisplay?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  patientLabs?: Maybe<PatientLabsCountableConnection>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  providerGuidedCareHealthProgramTeamChatGroups?: Maybe<AnonymousChatGroupCountableConnection>;
  questionAnswers?: Maybe<QuestionAnswerConnection>;
  secondName?: Maybe<Scalars["String"]>;
  thirdName?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  validationSkipped: Scalars["Boolean"];
  wallet?: Maybe<Wallet>;
};

export type PatientActiveAppointmentsInPersonArgs = {
  departmentId?: InputMaybe<Scalars["ID"]>;
  outPatientJourneySupportedForProvidedDepartmentId?: InputMaybe<Scalars["Boolean"]>;
};

export type PatientActiveVisitsInPersonArgs = {
  departmentId?: InputMaybe<Scalars["ID"]>;
};

export type PatientAvailableTimeSlotsArgs = {
  doctorId?: InputMaybe<Scalars["ID"]>;
  end?: InputMaybe<Scalars["Instant"]>;
  start?: InputMaybe<Scalars["Instant"]>;
};

export type PatientBodyCompositionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramMemberBodyCompositionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientHealthProgramMembershipRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramMembershipRequestFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramMembershipRequestSortingInput>;
};

export type PatientHealthProgramsByActiveMembersArgs = {
  filter?: InputMaybe<HealthProgramFilterInput>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};

export type PatientHealthProgramsByActiveUserSubscriptionsArgs = {
  filter?: InputMaybe<HealthProgramFilterInput>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};

export type PatientHealthProgramsOfPayPerCallOnlyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};

export type PatientInActiveHealthProgramMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientMedicalFormAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MedicalFormAnswerFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientPatientLabsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PaymentOperationLogSortingInput>;
};

export type PatientPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientProviderGuidedCareHealthProgramTeamChatGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ProviderGuidedCareHealthProgramTeamFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientQuestionAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<QuestionAnswerFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientChronicDisease = H_Node & {
  __typename?: "PatientChronicDisease";
  code: Scalars["String"];
  createdDate?: Maybe<Scalars["Instant"]>;
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type PatientChronicDiseaseInput = {
  code: Scalars["String"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type PatientChronicMedication = H_Node & {
  __typename?: "PatientChronicMedication";
  code: Scalars["String"];
  createdDate?: Maybe<Scalars["Instant"]>;
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type PatientChronicMedicationInput = {
  code: Scalars["String"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type PatientCountableConnection = {
  __typename?: "PatientCountableConnection";
  edges: Array<PatientCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientCountableEdge = {
  __typename?: "PatientCountableEdge";
  cursor: Scalars["String"];
  node: Patient;
};

export type PatientCreate = {
  __typename?: "PatientCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type PatientCurrentMedicalStatus = H_Node & {
  __typename?: "PatientCurrentMedicalStatus";
  id: Scalars["ID"];
  patientChronicDiseases?: Maybe<Array<Maybe<PatientChronicDisease>>>;
  patientChronicMedications?: Maybe<Array<Maybe<PatientChronicMedication>>>;
  patientDisabilities?: Maybe<Array<Maybe<PatientDisability>>>;
  patientNonChronicDiseases?: Maybe<Array<Maybe<PatientNonChronicDisease>>>;
};

export type PatientDisability = H_Node & {
  __typename?: "PatientDisability";
  code: Scalars["String"];
  createdDate?: Maybe<Scalars["Instant"]>;
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type PatientDisabilityInput = {
  code: Scalars["String"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type PatientError = {
  __typename?: "PatientError";
  code: PatientErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum PatientErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type PatientEvaluatedHealthCondition = {
  __typename?: "PatientEvaluatedHealthCondition";
  chronicDiseaseCode?: Maybe<Scalars["String"]>;
  evaluatedHealthCondition?: Maybe<HealthCondition>;
};

export type PatientFilterInput = {
  bloodGroup?: InputMaybe<Scalars["String"]>;
  bloodGroup_Icontains?: InputMaybe<Scalars["String"]>;
  contactNumber?: InputMaybe<Scalars["String"]>;
  contactNumber_Icontains?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  email?: InputMaybe<Scalars["String"]>;
  email_Icontains?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  firstName_Icontains?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  id?: InputMaybe<Scalars["ID"]>;
  idType?: InputMaybe<Array<InputMaybe<PatientIdTypeEnum>>>;
  id_Icontains?: InputMaybe<Scalars["ID"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  lastName_Icontains?: InputMaybe<Scalars["String"]>;
  maritalStatus?: InputMaybe<Array<InputMaybe<MaritalStatusesEnum>>>;
  nationalIdNumber?: InputMaybe<Scalars["String"]>;
  nationalIdNumber_Icontains?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  nationality_Icontains?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["ID"]>;
  residencyType?: InputMaybe<Array<InputMaybe<ResidencyTypesEnum>>>;
  secondName?: InputMaybe<Scalars["String"]>;
  secondName_Icontains?: InputMaybe<Scalars["String"]>;
  thirdName?: InputMaybe<Scalars["String"]>;
  thirdName_Icontains?: InputMaybe<Scalars["String"]>;
};

export type PatientHpVitalSignsChangePercentage = {
  __typename?: "PatientHPVitalSignsChangePercentage";
  changePercentage?: Maybe<Scalars["Double"]>;
  hpCode?: Maybe<Scalars["String"]>;
  hpId?: Maybe<Scalars["ID"]>;
};

export type PatientHealthHistory = H_Node & {
  __typename?: "PatientHealthHistory";
  affectedDate?: Maybe<Scalars["Instant"]>;
  code: Scalars["String"];
  createdBy?: Maybe<Scalars["String"]>;
  createdByUserType?: Maybe<ProfileType>;
  createdDate?: Maybe<Scalars["Instant"]>;
  display?: Maybe<Scalars["String"]>;
  healthHistoryType: HealthHistoryType;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  visitDiagnosis?: Maybe<VisitDiagnosis>;
};

export type PatientHealthHistoryCrud = {
  __typename?: "PatientHealthHistoryCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientHealthHistory?: Maybe<PatientHealthHistory>;
  patientHealthHistoryErrors?: Maybe<Array<H_EntityError>>;
};

export type PatientHealthHistoryConnection = {
  __typename?: "PatientHealthHistoryConnection";
  edges: Array<PatientHealthHistoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientHealthHistoryCountableEdge = {
  __typename?: "PatientHealthHistoryCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: PatientHealthHistory;
};

export type PatientHealthHistoryInput = {
  affectedDate: Scalars["Instant"];
  code: Scalars["String"];
  display: Scalars["String"];
  healthHistoryType: HealthHistoryType;
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type PatientHealthParameter = {
  __typename?: "PatientHealthParameter";
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  deviceName?: Maybe<Scalars["String"]>;
  deviceSerial?: Maybe<Scalars["String"]>;
  healthParameter?: Maybe<HealthParameter>;
  hpCode?: Maybe<Scalars["String"]>;
  hpId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  isLab?: Maybe<Scalars["Boolean"]>;
  isRiskStratification?: Maybe<Scalars["Boolean"]>;
  isVitalSigns?: Maybe<Scalars["Boolean"]>;
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  patientId?: Maybe<Scalars["ID"]>;
  practitionerUserId?: Maybe<Scalars["ID"]>;
  source?: Maybe<Source>;
  valueAudioFile?: Maybe<Scalars["String"]>;
  valueBoolean?: Maybe<Scalars["Boolean"]>;
  valueDate?: Maybe<Scalars["Instant"]>;
  valueNumber?: Maybe<Scalars["Double"]>;
  valueNumericList?: Maybe<Array<Maybe<Scalars["Double"]>>>;
  valueString?: Maybe<Scalars["String"]>;
  valueVideoFile?: Maybe<Scalars["String"]>;
};

/** CRUD a new Patient Health Parameter. */
export type PatientHealthParameterCrud = {
  __typename?: "PatientHealthParameterCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientHealthParameter?: Maybe<PatientHealthParameter>;
  patientHealthParameterErrors?: Maybe<Array<H_EntityError>>;
};

export type PatientHealthParameterCountableConnection = {
  __typename?: "PatientHealthParameterCountableConnection";
  edges: Array<PatientHealthParameterCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientHealthParameterCountableEdge = {
  __typename?: "PatientHealthParameterCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: PatientHealthParameter;
};

export type PatientHealthParameterFilterInput = {
  codes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isVitalSigns?: InputMaybe<Scalars["Boolean"]>;
  patientId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  requestedDateRange?: InputMaybe<H_DateTimeRangeInput>;
  visitId?: InputMaybe<Scalars["ID"]>;
};

export type PatientHealthParameterInput = {
  deviceName?: InputMaybe<Scalars["String"]>;
  deviceSerial?: InputMaybe<Scalars["String"]>;
  hpCode: Scalars["String"];
  valueAudioFile?: InputMaybe<Scalars["String"]>;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueNumber?: InputMaybe<Scalars["Double"]>;
  valueNumericList?: InputMaybe<Array<InputMaybe<Scalars["Double"]>>>;
  valueString?: InputMaybe<Scalars["String"]>;
  valueVideoFile?: InputMaybe<Scalars["String"]>;
};

export enum PatientHealthParameterSortField {
  /** Sort by health parameter code. */
  Code = "CODE",
  /** Sort by creation date. */
  CreatedDate = "CREATED_DATE",
  /** Sort by source. */
  Source = "SOURCE",
}

export type PatientHealthParameterSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: PatientHealthParameterSortField;
};

export enum PatientIdType {
  NationalCard = "NATIONAL_CARD",
  Passport = "PASSPORT",
  ResidentCard = "RESIDENT_CARD",
}

export enum PatientIdTypeEnum {
  NationalCard = "NATIONAL_CARD",
  Passport = "PASSPORT",
  ResidentCard = "RESIDENT_CARD",
}

export enum PatientIdentifierType {
  MemberId = "MEMBER_ID",
  NationalId = "NATIONAL_ID",
}

export type PatientInput = {
  bloodGroup?: InputMaybe<Scalars["String"]>;
  contactNumber?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  documentExpiryDate?: InputMaybe<Scalars["Date"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  generateMeetingPlatformId?: InputMaybe<Scalars["Boolean"]>;
  idType?: InputMaybe<PatientIdTypeEnum>;
  lastName?: InputMaybe<Scalars["String"]>;
  maritalStatus?: InputMaybe<MaritalStatusesEnum>;
  mobile?: InputMaybe<Scalars["String"]>;
  nationalIdNumber?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  parentNationalId?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars["String"]>;
  sendWelcomeMessage?: InputMaybe<Scalars["Boolean"]>;
  skipValidation?: InputMaybe<Scalars["Boolean"]>;
  thirdName?: InputMaybe<Scalars["String"]>;
};

export type PatientLabsCountableConnection = {
  __typename?: "PatientLabsCountableConnection";
  edges: Array<PatientLabsCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientLabsCountableEdge = {
  __typename?: "PatientLabsCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Lab;
};

export enum PatientMaritalStatus {
  Divorced = "DIVORCED",
  Married = "MARRIED",
  Single = "SINGLE",
  Widowed = "WIDOWED",
}

export type PatientMedicalProfile = H_Node & {
  __typename?: "PatientMedicalProfile";
  healthProgramMember?: Maybe<HealthProgramMember>;
  id: Scalars["ID"];
  patient?: Maybe<Patient>;
  patientCurrentMedicalStatus?: Maybe<PatientCurrentMedicalStatus>;
  patientHealthHistory?: Maybe<Array<Maybe<PatientHealthHistory>>>;
};

export type PatientMedicalProfileCrud = {
  __typename?: "PatientMedicalProfileCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientMedicalProfile?: Maybe<PatientMedicalProfile>;
  patientMedicalProfileErrors?: Maybe<Array<H_EntityError>>;
};

export type PatientMinimal = H_Node & {
  __typename?: "PatientMinimal";
  id: Scalars["ID"];
  patientUserId?: Maybe<Scalars["ID"]>;
};

export type PatientMinimalCountableConnection = {
  __typename?: "PatientMinimalCountableConnection";
  edges: Array<PatientMinimalCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientMinimalCountableEdge = {
  __typename?: "PatientMinimalCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PatientMinimal;
};

export type PatientNonChronicDisease = H_Node & {
  __typename?: "PatientNonChronicDisease";
  code: Scalars["String"];
  createdDate?: Maybe<Scalars["Instant"]>;
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type PatientNonChronicDiseaseInput = {
  code: Scalars["String"];
  patientId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type PatientProfileConsentCode = {
  __typename?: "PatientProfileConsentCode";
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  patientId?: Maybe<Scalars["ID"]>;
  userId?: Maybe<Scalars["ID"]>;
  validUntil?: Maybe<Scalars["Instant"]>;
};

export type PatientProfileConsentForm = H_Node & {
  __typename?: "PatientProfileConsentForm";
  consentCode?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  level?: Maybe<ConsentLevel>;
  parentUser?: Maybe<User>;
  patient?: Maybe<Patient>;
  patientUser?: Maybe<User>;
  requesterUser?: Maybe<User>;
  status?: Maybe<ConsentStatus>;
  type?: Maybe<ConsentType>;
};

export type PatientProfileConsentFormCrud = {
  __typename?: "PatientProfileConsentFormCRUD";
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientProfileConsentForm?: Maybe<PatientProfileConsentForm>;
};

export type PatientProfileConsentFormConnection = {
  __typename?: "PatientProfileConsentFormConnection";
  edges: Array<PatientProfileConsentFormCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientProfileConsentFormCountableEdge = {
  __typename?: "PatientProfileConsentFormCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: PatientProfileConsentForm;
};

export type PatientProfileConsentFormInput = {
  consentCode?: InputMaybe<Scalars["String"]>;
  patientId?: InputMaybe<Scalars["ID"]>;
};

export type PatientReferral = {
  __typename?: "PatientReferral";
  code?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["Date"]>;
  errors?: Maybe<Array<Maybe<ReferralGraphqlError>>>;
  startDate?: Maybe<Scalars["Date"]>;
};

export type PatientReferralConnection = {
  __typename?: "PatientReferralConnection";
  edges?: Maybe<Array<Maybe<ReferralEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PatientSendOrderOtp = {
  __typename?: "PatientSendOrderOTP";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  ok?: Maybe<Scalars["Boolean"]>;
  patientErrors: Array<PatientError>;
};

export type PatientSendOrderOtpInput = {
  branchId: Scalars["ID"];
  orderId: Scalars["ID"];
  orderOtp: Scalars["String"];
  patientId: Scalars["ID"];
  url: Scalars["String"];
};

export type PatientShare = {
  __typename?: "PatientShare";
  coverage?: Maybe<Coverage>;
  patientShare?: Maybe<Scalars["Float"]>;
};

export type PatientUpdate = {
  __typename?: "PatientUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type PatientUpdateInput = {
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  documentExpiryDate?: InputMaybe<Scalars["Date"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  idType?: InputMaybe<PatientIdTypeEnum>;
  lastName?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  secondName?: InputMaybe<Scalars["String"]>;
  thirdName?: InputMaybe<Scalars["String"]>;
};

/** Upload an attachment to visit */
export type PatientUploadVisitAttachment = {
  __typename?: "PatientUploadVisitAttachment";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitAttachment?: Maybe<VisitAttachment>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type Payer = Node & {
  __typename?: "Payer";
  backgroundImage?: Maybe<Scalars["String"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactMobileNumber?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  contactPhoneNumber?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  facilities?: Maybe<InsuranceNetworkFacilityCountableConnection>;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  licenseNumber: Scalars["String"];
  logo?: Maybe<Scalars["String"]>;
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  nameAr?: Maybe<Scalars["String"]>;
  networks?: Maybe<InsuranceNetworkCountableConnection>;
  parent?: Maybe<Payer>;
  payerSet: PayerCountableConnection;
  tpo: PayerTpo;
  type: PayerType;
  users: UserCountableConnection;
};

export type PayerFacilitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PayerNetworksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PayerPayerSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PayerUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PayerAddUpdateMemberBankDetailsRequestInput = {
  accountNo: Scalars["String"];
  bankId: Scalars["Int"];
  bankName: Scalars["String"];
  branchName: Scalars["String"];
  healthProgramMemberId: Scalars["ID"];
  ibanNumber: Scalars["String"];
  memberBankBeneficiaryName: Scalars["String"];
  memberBankDetailId?: InputMaybe<Scalars["String"]>;
  swiftCode: Scalars["String"];
};

export type PayerBankListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
};

export type PayerBankListResponse = {
  __typename?: "PayerBankListResponse";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PayerBenefitListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
};

export type PayerBenefitListResponse = {
  __typename?: "PayerBenefitListResponse";
  benefitType?: Maybe<Scalars["String"]>;
  coInValue?: Maybe<Scalars["Double"]>;
  covered?: Maybe<Scalars["Boolean"]>;
  deductibleValue?: Maybe<Scalars["Double"]>;
  remarks?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
};

export type PayerCityListRequestInput = {
  countryId?: InputMaybe<Scalars["Int"]>;
  healthProgramMemberId: Scalars["ID"];
  searchText?: InputMaybe<Scalars["String"]>;
};

export type PayerCityListResponse = {
  __typename?: "PayerCityListResponse";
  cityId?: Maybe<Scalars["Int"]>;
  cityName?: Maybe<Scalars["String"]>;
  countryId?: Maybe<Scalars["Int"]>;
  countryName?: Maybe<Scalars["String"]>;
};

export type PayerClaimDetailsRequestInput = {
  claimId: Scalars["String"];
  healthProgramMemberId: Scalars["ID"];
};

export type PayerClaimDetailsResponse = {
  __typename?: "PayerClaimDetailsResponse";
  approvedClaimAmount?: Maybe<Scalars["Int"]>;
  benefitType?: Maybe<Scalars["String"]>;
  claimId?: Maybe<Scalars["String"]>;
  claimNo?: Maybe<Scalars["String"]>;
  claimType?: Maybe<Scalars["String"]>;
  dateOfDischargeDate?: Maybe<Scalars["String"]>;
  dateOfServiceDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  memberCardId?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  submissionDate?: Maybe<Scalars["String"]>;
  submissionMode?: Maybe<Scalars["String"]>;
  treatingDoctorName?: Maybe<Scalars["String"]>;
};

export type PayerClaimListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  pageNo: Scalars["Int"];
};

export type PayerClaimListResponse = {
  __typename?: "PayerClaimListResponse";
  claimAmount?: Maybe<Scalars["Int"]>;
  claimId?: Maybe<Scalars["String"]>;
  claimNo?: Maybe<Scalars["String"]>;
  providerName?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  submittedDate?: Maybe<Scalars["String"]>;
};

export type PayerClaimSubmissionRequestInput = {
  claimFormDocument: Scalars["String"];
  claimedAmount: Scalars["Double"];
  countryId: Scalars["Int"];
  currencyId: Scalars["Int"];
  diagnosis: Scalars["String"];
  healthProgramMemberId: Scalars["ID"];
  investigationResultDocument: Scalars["String"];
  invoiceDocument: Scalars["String"];
  memberBankDetailId: Scalars["String"];
  physicianAddress?: InputMaybe<Scalars["String"]>;
  physicianContact?: InputMaybe<Scalars["String"]>;
  physicianName?: InputMaybe<Scalars["String"]>;
  providerName: Scalars["String"];
  treatmentDate: Scalars["String"];
};

export type PayerClinicianDetails = {
  __typename?: "PayerClinicianDetails";
  clinicianId?: Maybe<Scalars["Int"]>;
  clinicianPhoto?: Maybe<Scalars["String"]>;
  clinicianPhotoThumb?: Maybe<Scalars["String"]>;
  consultationFee?: Maybe<Scalars["Double"]>;
  fullName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  generalContact?: Maybe<PayerGeneralContact>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isCovered?: Maybe<Scalars["Boolean"]>;
  specialty?: Maybe<Array<Maybe<PayerClinicianSpecialty>>>;
};

export type PayerClinicianDetailsRequestInput = {
  doctorId: Scalars["Int"];
  healthProgramMemberId: Scalars["ID"];
};

export type PayerClinicianDetailsResponse = {
  __typename?: "PayerClinicianDetailsResponse";
  clinicianDetails?: Maybe<PayerClinicianDetails>;
  languages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  majorSpecialities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  nationalities?: Maybe<Array<Maybe<PayerNationality>>>;
  providers?: Maybe<Array<Maybe<PayerProvider>>>;
};

export type PayerClinicianListRequestInput = {
  cityIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  countryIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  doctorIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  healthProgramMemberId: Scalars["ID"];
  languageIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pageNo: Scalars["Int"];
  specialityIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type PayerClinicianListResponse = {
  __typename?: "PayerClinicianListResponse";
  doctorId?: Maybe<Scalars["Int"]>;
  doctorName?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  specialty?: Maybe<Array<Maybe<PayerClinicianSpecialty>>>;
};

export type PayerClinicianSearchRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  isCovered?: InputMaybe<Scalars["Boolean"]>;
  searchText?: InputMaybe<Scalars["String"]>;
};

export type PayerClinicianSearchResponse = {
  __typename?: "PayerClinicianSearchResponse";
  clinicianId?: Maybe<Scalars["Int"]>;
  fullName?: Maybe<Scalars["String"]>;
};

export type PayerClinicianSpecialty = {
  __typename?: "PayerClinicianSpecialty";
  specialtyId?: Maybe<Scalars["Int"]>;
  specialtyName?: Maybe<Scalars["String"]>;
};

export type PayerCountableConnection = {
  __typename?: "PayerCountableConnection";
  edges: Array<PayerCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PayerCountableEdge = {
  __typename?: "PayerCountableEdge";
  cursor: Scalars["String"];
  node: Payer;
};

export type PayerCountryRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  searchText?: InputMaybe<Scalars["String"]>;
};

export type PayerCountryResponse = {
  __typename?: "PayerCountryResponse";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PayerCreate = {
  __typename?: "PayerCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  payer?: Maybe<Payer>;
  payerErrors: Array<PayerError>;
};

export type PayerCurrencyRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  searchText?: InputMaybe<Scalars["String"]>;
};

export type PayerCurrencyResponse = {
  __typename?: "PayerCurrencyResponse";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PayerError = {
  __typename?: "PayerError";
  code: PayerErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum PayerErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  NotPayersImage = "NOT_PAYERS_IMAGE",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type PayerFilterInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  licenseNumber?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
};

export type PayerGeneralContact = {
  __typename?: "PayerGeneralContact";
  addressLine?: Maybe<Scalars["String"]>;
  alternativeName?: Maybe<Scalars["String"]>;
  cityName?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  countryName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type PayerGeoLocation = {
  __typename?: "PayerGeoLocation";
  area?: Maybe<Scalars["String"]>;
  geoLocationId?: Maybe<Scalars["Int"]>;
  latitude?: Maybe<Scalars["Int"]>;
  link?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Int"]>;
};

export type PayerInput = {
  backgroundImage?: InputMaybe<Scalars["String"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactMobileNumber?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  contactPhoneNumber?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  licenseNumber?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  parentId?: InputMaybe<Scalars["ID"]>;
  tpo?: InputMaybe<Tpo>;
  type?: InputMaybe<PayerTypeEnum>;
};

export type PayerLanguageRequestInput = {
  healthProgramMemberId: Scalars["ID"];
};

export type PayerLanguageResponse = {
  __typename?: "PayerLanguageResponse";
  appLanguageId?: Maybe<Scalars["String"]>;
  languageName?: Maybe<Scalars["String"]>;
};

export type PayerMemberBankDetailsRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  pageNo: Scalars["Int"];
};

export type PayerMemberBankDetailsResponse = {
  __typename?: "PayerMemberBankDetailsResponse";
  accountNo?: Maybe<Scalars["String"]>;
  bankAccountHolderName?: Maybe<Scalars["String"]>;
  bankId?: Maybe<Scalars["Int"]>;
  bankName?: Maybe<Scalars["String"]>;
  branchName?: Maybe<Scalars["String"]>;
  ibanNumber?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  memberBankId?: Maybe<Scalars["String"]>;
  swiftCode?: Maybe<Scalars["String"]>;
};

export type PayerNationality = {
  __typename?: "PayerNationality";
  nationalityId?: Maybe<Scalars["Int"]>;
  nationalityName?: Maybe<Scalars["String"]>;
};

export type PayerOrder = {
  direction: OrderDirection;
  field?: InputMaybe<PayerOrderField>;
};

export enum PayerOrderField {
  Created = "CREATED",
  Name = "NAME",
  NameAr = "NAME_AR",
  Tpo = "TPO",
}

export type PayerPreApprovalListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  pageNo: Scalars["Int"];
};

export type PayerPreApprovalListResponse = {
  __typename?: "PayerPreApprovalListResponse";
  IPOP?: Maybe<Scalars["String"]>;
  approvedAmount?: Maybe<Scalars["Double"]>;
  claimType?: Maybe<Scalars["String"]>;
  dateOfDischarge?: Maybe<Scalars["String"]>;
  dateOfService?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  preApprovalReference?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  submissionDate?: Maybe<Scalars["String"]>;
  submissionMode?: Maybe<Scalars["String"]>;
  treatingDoctorName?: Maybe<Scalars["String"]>;
};

export type PayerProvider = {
  __typename?: "PayerProvider";
  providerId?: Maybe<Scalars["Int"]>;
  providerLogo?: Maybe<Scalars["String"]>;
  providerName?: Maybe<Scalars["String"]>;
};

export type PayerProviderDetailsRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  providerId: Scalars["Int"];
};

export type PayerProviderDetailsResponse = {
  __typename?: "PayerProviderDetailsResponse";
  aboutProvider?: Maybe<Scalars["String"]>;
  generalContact?: Maybe<PayerGeneralContact>;
  geoLocation?: Maybe<PayerGeoLocation>;
  officeHours?: Maybe<Scalars["String"]>;
  providerBranches?: Maybe<Array<Maybe<Scalars["String"]>>>;
  providerClinicians?: Maybe<Array<Maybe<Scalars["String"]>>>;
  providerId?: Maybe<Scalars["Int"]>;
  providerLicenseSource?: Maybe<Scalars["String"]>;
  providerLogoUrl?: Maybe<Scalars["String"]>;
  providerName?: Maybe<Scalars["String"]>;
  providerType?: Maybe<Scalars["String"]>;
};

export type PayerProviderFilterListRequestInput = {
  cityIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  countryIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  healthProgramMemberId: Scalars["ID"];
  pageNo: Scalars["Int"];
  providerIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  providerTypeIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  specialityIds?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type PayerProviderListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  pageNo: Scalars["Int"];
  searchText?: InputMaybe<Scalars["String"]>;
};

export type PayerProviderListResponse = {
  __typename?: "PayerProviderListResponse";
  isFavorite?: Maybe<Scalars["Boolean"]>;
  providerId?: Maybe<Scalars["Int"]>;
  providerLogo?: Maybe<Scalars["String"]>;
  providerName?: Maybe<Scalars["String"]>;
  providerType?: Maybe<Scalars["String"]>;
};

export type PayerProviderNearByListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  latitude: Scalars["Double"];
  longitude: Scalars["Double"];
  pageNo: Scalars["Int"];
};

export type PayerProviderNearByListResponse = {
  __typename?: "PayerProviderNearByListResponse";
  addressLine?: Maybe<Scalars["String"]>;
  distance?: Maybe<Scalars["Double"]>;
  isCovered?: Maybe<Scalars["Boolean"]>;
  isFavourite?: Maybe<Scalars["Boolean"]>;
  latitude?: Maybe<Scalars["Double"]>;
  longitude?: Maybe<Scalars["Double"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  providerId?: Maybe<Scalars["Int"]>;
  providerLogo?: Maybe<Scalars["String"]>;
  providerName?: Maybe<Scalars["String"]>;
  providerType?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type PayerProviderTypeListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
};

export type PayerProviderTypeListResponse = {
  __typename?: "PayerProviderTypeListResponse";
  providerType?: Maybe<Scalars["String"]>;
  providerTypeId?: Maybe<Scalars["Int"]>;
};

export type PayerSpecialtyListRequestInput = {
  healthProgramMemberId: Scalars["ID"];
  searchText?: InputMaybe<Scalars["String"]>;
};

export type PayerSpecialtyListResponse = {
  __typename?: "PayerSpecialtyListResponse";
  specialtyId?: Maybe<Scalars["Int"]>;
  specialtyName?: Maybe<Scalars["String"]>;
};

export enum PayerTpo {
  Eclaim = "ECLAIM",
  Io = "IO",
}

export enum PayerType {
  Insurance = "INSURANCE",
  Tpa = "TPA",
}

export enum PayerTypeEnum {
  Insurance = "INSURANCE",
  Tpa = "TPA",
}

export type PayerUpdate = {
  __typename?: "PayerUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  payer?: Maybe<Payer>;
  payerErrors: Array<PayerError>;
};

export enum PaymentBrand {
  Mada = "MADA",
  Master = "MASTER",
  Visa = "VISA",
}

export type PaymentCheckoutInput = {
  addressId?: InputMaybe<Scalars["String"]>;
  amountSourceId: Scalars["String"];
  amountSourceType: AmountSourceType;
};

export type PaymentCheckoutResponse = {
  __typename?: "PaymentCheckoutResponse";
  appointmentId?: Maybe<Scalars["H_Long"]>;
  buildNumber?: Maybe<Scalars["String"]>;
  checkoutPaymentFormUrl?: Maybe<Scalars["String"]>;
  healthProgramId?: Maybe<Scalars["H_Long"]>;
  id?: Maybe<Scalars["String"]>;
  marketplaceOrderId?: Maybe<Scalars["H_Long"]>;
  merchantTransactionId?: Maybe<Scalars["String"]>;
  ndc?: Maybe<Scalars["String"]>;
  orderCheckoutToken?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["H_Long"]>;
  outPatientJourneyId?: Maybe<Scalars["H_Long"]>;
  patientId?: Maybe<Scalars["H_Long"]>;
  paymentStatus?: Maybe<OperationStatus>;
  paymentType?: Maybe<Scalars["String"]>;
  prescriptionOrderId?: Maybe<Scalars["H_Long"]>;
  result?: Maybe<PaymentResponseResult>;
  shopperResultUrl?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["H_Long"]>;
  vendorId?: Maybe<Scalars["H_Long"]>;
  visitId?: Maybe<Scalars["H_Long"]>;
};

export type PaymentClearanceFromMultipleSourcesInput = {
  addressId?: InputMaybe<Scalars["String"]>;
  amountSourceId: Scalars["String"];
  amountSourceType: AmountSourceType;
};

export type PaymentClearanceInput = {
  amountSentToWallet?: InputMaybe<Scalars["String"]>;
  amountSourceId: Scalars["String"];
  amountSourceType: AmountSourceType;
  deductedPaymentReceiptIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patientId?: InputMaybe<Scalars["ID"]>;
  userId?: InputMaybe<Scalars["ID"]>;
};

export enum PaymentErrorCodes {
  InvalidOrExpiredToken = "INVALID_OR_EXPIRED_TOKEN",
  PaymentAddCardError = "PAYMENT_ADD_CARD_ERROR",
  PaymentCardNotFound = "PAYMENT_CARD_NOT_FOUND",
  PaymentDeleteCardError = "PAYMENT_DELETE_CARD_ERROR",
  PaymentInvalidSecurityCodeError = "PAYMENT_INVALID_SECURITY_CODE_ERROR",
  PaymentOwnershipError = "PAYMENT_OWNERSHIP_ERROR",
  PaymentPermissionDenied = "PAYMENT_PERMISSION_DENIED",
  PaymentProcessError = "PAYMENT_PROCESS_ERROR",
  PaymentUnknown = "PAYMENT_UNKNOWN",
  PaymentUnknownType = "PAYMENT_UNKNOWN_TYPE",
}

export type PaymentGraphqlError = {
  __typename?: "PaymentGraphqlError";
  code?: Maybe<PaymentErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type PaymentInvoice = {
  __typename?: "PaymentInvoice";
  appointmentId?: Maybe<Scalars["ID"]>;
  checkoutId?: Maybe<Scalars["String"]>;
  consumerId?: Maybe<Scalars["ID"]>;
  consumerUserId?: Maybe<Scalars["ID"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  currency?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["Float"]>;
  healthProgramId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  marketplaceOrderId?: Maybe<Scalars["ID"]>;
  merchantTransactionId?: Maybe<Scalars["String"]>;
  orderCheckoutToken?: Maybe<Scalars["String"]>;
  outPatientJourneyId?: Maybe<Scalars["ID"]>;
  paymentSource?: Maybe<PaymentSource>;
  paymentType?: Maybe<Scalars["String"]>;
  prescriptionOrderId?: Maybe<Scalars["ID"]>;
  priceAfterVat?: Maybe<Scalars["Float"]>;
  priceBeforeVat?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
  vendorId?: Maybe<Scalars["ID"]>;
  visitId?: Maybe<Scalars["ID"]>;
};

export type PaymentInvoiceCountableConnection = {
  __typename?: "PaymentInvoiceCountableConnection";
  edges: Array<PaymentInvoiceCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PaymentInvoiceCountableEdge = {
  __typename?: "PaymentInvoiceCountableEdge";
  cursor: Scalars["String"];
  node: PaymentInvoice;
};

export type PaymentInvoiceFilterInput = {
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  checkoutIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  consumerIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  consumerUserIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  createdDate?: InputMaybe<H_DateTimeRangeInput>;
  healthProgramIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  marketplaceOrderIdNotNull?: InputMaybe<Scalars["Boolean"]>;
  marketplaceOrderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  orderCheckoutTokens?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  outPatientJourneyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  paymentSources?: InputMaybe<Array<InputMaybe<PaymentSource>>>;
  paymentTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  prescriptionOrderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  viewed?: InputMaybe<Scalars["Boolean"]>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export enum PaymentMethod {
  Cash = "CASH",
  Cc = "CC",
  PaymentCard = "PAYMENT_CARD",
}

export enum PaymentMethodEnum {
  CreditWhenArrive = "CREDIT_WHEN_ARRIVE",
  PayNow = "PAY_NOW",
}

export enum PaymentOperation {
  Confirm = "CONFIRM",
  Refund = "REFUND",
  Reverse = "REVERSE",
}

export type PaymentOperationLog = {
  __typename?: "PaymentOperationLog";
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars["ID"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  details?: Maybe<Scalars["String"]>;
  healthProgramId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  marketplaceOrder?: Maybe<MarketplaceOrder>;
  marketplaceOrderId?: Maybe<Scalars["H_Long"]>;
  operationStatus?: Maybe<OperationStatus>;
  operationType?: Maybe<OperationType>;
  order?: Maybe<Order>;
  orderCheckoutToken?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["ID"]>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  outPatientJourneyId?: Maybe<Scalars["ID"]>;
  patientId?: Maybe<Scalars["ID"]>;
  paymentAmount?: Maybe<Scalars["Float"]>;
  paymentAmountCanBeRefundedFromWallet?: Maybe<Scalars["Float"]>;
  paymentAmountRefundedFromWallet?: Maybe<Scalars["Float"]>;
  paymentId?: Maybe<Scalars["String"]>;
  prescriptionOrderId?: Maybe<Scalars["H_Long"]>;
  refundId?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["ID"]>;
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars["ID"]>;
  walletId?: Maybe<Scalars["ID"]>;
};

export type PaymentOperationLogCountableConnection = {
  __typename?: "PaymentOperationLogCountableConnection";
  edges: Array<PaymentOperationLogCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PaymentOperationLogCountableEdge = {
  __typename?: "PaymentOperationLogCountableEdge";
  cursor: Scalars["String"];
  node: PaymentOperationLog;
};

export type PaymentOperationLogFilterInput = {
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  marketplaceOrderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  operationStatuses?: InputMaybe<Array<InputMaybe<OperationStatus>>>;
  operationTypes?: InputMaybe<Array<InputMaybe<OperationType>>>;
  orderCheckoutTokens?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  outPatientJourneyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  prescriptionOrderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  refundIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  walletIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export enum PaymentOperationLogSortField {
  /** Sort visit by created. */
  Created = "CREATED",
}

export type PaymentOperationLogSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort visits by the selected field. */
  field: PaymentOperationLogSortField;
};

export type PaymentParameter = {
  __typename?: "PaymentParameter";
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type PaymentReceipt = {
  __typename?: "PaymentReceipt";
  amountRefundedByHyperpay?: Maybe<Scalars["Float"]>;
  amountRefundedByHyperpayFromWallet?: Maybe<Scalars["Float"]>;
  amountSentToWallet?: Maybe<Scalars["Float"]>;
  appointment?: Maybe<Appointment>;
  balanceInWallet?: Maybe<Scalars["Float"]>;
  benefitedFrom?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  healthProgram?: Maybe<HealthProgram>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  lastPaymentAmount?: Maybe<Scalars["Float"]>;
  lastPaymentId?: Maybe<Scalars["String"]>;
  lastPaymentType?: Maybe<Scalars["String"]>;
  marketplaceOrder?: Maybe<MarketplaceOrder>;
  marketplaceOrderId?: Maybe<Scalars["H_Long"]>;
  order?: Maybe<Order>;
  orderCheckoutToken?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["H_Long"]>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  patient?: Maybe<Patient>;
  paymentBrand?: Maybe<PaymentBrand>;
  paymentId?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentType?: Maybe<Scalars["String"]>;
  prescriptionOrderId?: Maybe<Scalars["H_Long"]>;
  totalAmount?: Maybe<Scalars["Float"]>;
  user?: Maybe<User>;
  vendor?: Maybe<Vendor>;
  visit?: Maybe<Visit>;
};

export type PaymentReceiptCountableConnection = {
  __typename?: "PaymentReceiptCountableConnection";
  edges: Array<PaymentReceiptCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PaymentReceiptCountableEdge = {
  __typename?: "PaymentReceiptCountableEdge";
  cursor: Scalars["String"];
  node: PaymentReceipt;
};

export type PaymentReceiptFilterInput = {
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  benefitedFrom?: InputMaybe<Scalars["Boolean"]>;
  healthProgramIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  marketplaceOrderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  orderCheckoutTokens?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  outPatientJourneyIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  paymentBrands?: InputMaybe<Array<InputMaybe<PaymentBrand>>>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
  prescriptionOrderIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type PaymentReceiptInput = {
  healthProgramId: Scalars["ID"];
  paymentMethod: PaymentMethod;
};

export type PaymentResponse = {
  __typename?: "PaymentResponse";
  amount?: Maybe<Scalars["Float"]>;
  appointmentId?: Maybe<Scalars["H_Long"]>;
  buildNumber?: Maybe<Scalars["String"]>;
  card?: Maybe<PaymentResponseCard>;
  currency?: Maybe<Scalars["String"]>;
  customParameters?: Maybe<Scalars["DictType"]>;
  descriptor?: Maybe<Scalars["String"]>;
  healthProgramId?: Maybe<Scalars["H_Long"]>;
  id?: Maybe<Scalars["String"]>;
  marketplaceOrderId?: Maybe<Scalars["H_Long"]>;
  merchantTransactionId?: Maybe<Scalars["String"]>;
  ndc?: Maybe<Scalars["String"]>;
  orderCheckoutToken?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["H_Long"]>;
  outPatientJourneyId?: Maybe<Scalars["H_Long"]>;
  patientId?: Maybe<Scalars["H_Long"]>;
  paymentBrand?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReceiptId?: Maybe<Scalars["H_Long"]>;
  paymentType?: Maybe<Scalars["String"]>;
  prescriptionOrderId?: Maybe<Scalars["H_Long"]>;
  recurringType?: Maybe<Scalars["String"]>;
  redirect?: Maybe<PaymentResponseRedirect>;
  referencedId?: Maybe<Scalars["String"]>;
  refundReason?: Maybe<Scalars["String"]>;
  refundedAfterPayment?: Maybe<Scalars["Boolean"]>;
  registrationId?: Maybe<Scalars["String"]>;
  result?: Maybe<PaymentResponseResult>;
  resultDetails?: Maybe<PaymentResponseResultDetails>;
  risk?: Maybe<PaymentResponseRisk>;
  shopperResultUrl?: Maybe<Scalars["String"]>;
  standingInstruction?: Maybe<Scalars["DictType"]>;
  timestamp?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["H_Long"]>;
  vendorId?: Maybe<Scalars["H_Long"]>;
  visitId?: Maybe<Scalars["H_Long"]>;
};

export type PaymentResponseCard = {
  __typename?: "PaymentResponseCard";
  bin?: Maybe<Scalars["String"]>;
  binCountry?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  expiryMonth?: Maybe<Scalars["String"]>;
  expiryYear?: Maybe<Scalars["String"]>;
  holder?: Maybe<Scalars["String"]>;
  issuer?: Maybe<CardIssuer>;
  last4Digits?: Maybe<Scalars["String"]>;
  maxPanLength?: Maybe<Scalars["String"]>;
  regulatedFlag?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type PaymentResponseRedirect = {
  __typename?: "PaymentResponseRedirect";
  parameters?: Maybe<Array<Maybe<PaymentParameter>>>;
  url?: Maybe<Scalars["String"]>;
};

export type PaymentResponseResult = {
  __typename?: "PaymentResponseResult";
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type PaymentResponseResultDetails = {
  __typename?: "PaymentResponseResultDetails";
  clearingInstituteName?: Maybe<Scalars["String"]>;
};

export type PaymentResponseRisk = {
  __typename?: "PaymentResponseRisk";
  score?: Maybe<Scalars["String"]>;
};

export enum PaymentSource {
  Checkout = "CHECKOUT",
  Wallet = "WALLET",
}

export enum PaymentStatus {
  FullyCovered = "FULLY_COVERED",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  PaymentUnavailable = "PAYMENT_UNAVAILABLE",
  PaymentUnderProcessing = "PAYMENT_UNDER_PROCESSING",
  Refunded = "REFUNDED",
  RefundedFailed = "REFUNDED_FAILED",
  RefundedToWallet = "REFUNDED_TO_WALLET",
  RefundedUnderProcessing = "REFUNDED_UNDER_PROCESSING",
  UnPaid = "UN_PAID",
}

export enum PaymentType {
  Cd = "CD",
  Cp = "CP",
  Db = "DB",
  Pa = "PA",
  Rf = "RF",
  Rv = "RV",
}

export type PaymentUpdateInput = {
  /**
   * the amount value is only effective for the case of partial (not full) refund,
   * and the amount value should be within the available value for refund
   */
  amount?: InputMaybe<Scalars["Float"]>;
  amountSourceId: Scalars["String"];
  amountSourceType: AmountSourceType;
  paymentOperation: PaymentOperation;
};

export type Permission = Node & {
  __typename?: "Permission";
  code?: Maybe<PermissionEnum>;
  id: Scalars["ID"];
  keyCloakPermission?: Maybe<KeyCloakPermission>;
  name: Scalars["String"];
};

export enum PermissionEnum {
  ApproveHealthPackages = "APPROVE_HEALTH_PACKAGES",
  ApproveProducts = "APPROVE_PRODUCTS",
  AssignPrescriptionOrderToVendors = "ASSIGN_PRESCRIPTION_ORDER_TO_VENDORS",
  ConvertVirtualOrderToRealOne = "CONVERT_VIRTUAL_ORDER_TO_REAL_ONE",
  CustomerSupportManageOrders = "CUSTOMER_SUPPORT_MANAGE_ORDERS",
  ManageAdminPromotions = "MANAGE_ADMIN_PROMOTIONS",
  ManageAppointments = "MANAGE_APPOINTMENTS",
  ManageArticles = "MANAGE_ARTICLES",
  ManageBlocks = "MANAGE_BLOCKS",
  ManageBranches = "MANAGE_BRANCHES",
  ManageBrands = "MANAGE_BRANDS",
  ManageCallbacksRequests = "MANAGE_CALLBACKS_REQUESTS",
  ManageChat = "MANAGE_CHAT",
  ManageChatFlow = "MANAGE_CHAT_FLOW",
  ManageChatFlowContributor = "MANAGE_CHAT_FLOW_CONTRIBUTOR",
  ManageCheckouts = "MANAGE_CHECKOUTS",
  ManageCities = "MANAGE_CITIES",
  ManageCodeSystemEditorReviewers = "MANAGE_CODE_SYSTEM_EDITOR_REVIEWERS",
  ManageCodeSystemLists = "MANAGE_CODE_SYSTEM_LISTS",
  ManageCodeSystemReviewers = "MANAGE_CODE_SYSTEM_REVIEWERS",
  ManageDeliveryTimeSlots = "MANAGE_DELIVERY_TIME_SLOTS",
  ManageDepartments = "MANAGE_DEPARTMENTS",
  ManageDiagnosis = "MANAGE_DIAGNOSIS",
  ManageDiscounts = "MANAGE_DISCOUNTS",
  ManageDoctors = "MANAGE_DOCTORS",
  ManageEarlyRefillReasons = "MANAGE_EARLY_REFILL_REASONS",
  ManageHealthChannels = "MANAGE_HEALTH_CHANNELS",
  ManageHealthChannelsCategories = "MANAGE_HEALTH_CHANNELS_CATEGORIES",
  ManageHealthConditions = "MANAGE_HEALTH_CONDITIONS",
  ManageHealthMessages = "MANAGE_HEALTH_MESSAGES",
  ManageHealthPackages = "MANAGE_HEALTH_PACKAGES",
  ManageHealthPackageCategories = "MANAGE_HEALTH_PACKAGE_CATEGORIES",
  ManageHealthPackageOrders = "MANAGE_HEALTH_PACKAGE_ORDERS",
  ManageHealthPrograms = "MANAGE_HEALTH_PROGRAMS",
  ManageHealthProgramsCareForFields = "MANAGE_HEALTH_PROGRAMS_CARE_FOR_FIELDS",
  ManageHealthSymptoms = "MANAGE_HEALTH_SYMPTOMS",
  ManageInsurance = "MANAGE_INSURANCE",
  ManageInsuranceNetworks = "MANAGE_INSURANCE_NETWORKS",
  ManageInvoices = "MANAGE_INVOICES",
  ManageLabs = "MANAGE_LABS",
  ManageLanguages = "MANAGE_LANGUAGES",
  ManageMarketplaceOrders = "MANAGE_MARKETPLACE_ORDERS",
  ManageMedicalDeliveryRequests = "MANAGE_MEDICAL_DELIVERY_REQUESTS",
  ManageMedicalEdits = "MANAGE_MEDICAL_EDITS",
  ManageMedicalForms = "MANAGE_MEDICAL_FORMS",
  ManageMedications = "MANAGE_MEDICATIONS",
  ManageMedicationScientificDetails = "MANAGE_MEDICATION_SCIENTIFIC_DETAILS",
  ManageOrders = "MANAGE_ORDERS",
  ManageParameters = "MANAGE_PARAMETERS",
  ManagePatients = "MANAGE_PATIENTS",
  ManagePatientMedicalHistory = "MANAGE_PATIENT_MEDICAL_HISTORY",
  ManagePayments = "MANAGE_PAYMENTS",
  ManagePermissionGroup = "MANAGE_PERMISSION_GROUP",
  ManagePharmacyCredentials = "MANAGE_PHARMACY_CREDENTIALS",
  ManagePlugins = "MANAGE_PLUGINS",
  ManagePrescriptions = "MANAGE_PRESCRIPTIONS",
  ManagePricingRules = "MANAGE_PRICING_RULES",
  ManageProducts = "MANAGE_PRODUCTS",
  ManageProductCategories = "MANAGE_PRODUCT_CATEGORIES",
  ManagePrograms = "MANAGE_PROGRAMS",
  ManageProgramTeams = "MANAGE_PROGRAM_TEAMS",
  ManageProgramTemplates = "MANAGE_PROGRAM_TEMPLATES",
  ManagePromotions = "MANAGE_PROMOTIONS",
  ManageQualifications = "MANAGE_QUALIFICATIONS",
  ManageRejectionReasons = "MANAGE_REJECTION_REASONS",
  ManageRiskStratification = "MANAGE_RISK_STRATIFICATION",
  ManageRuleEngineRules = "MANAGE_RULE_ENGINE_RULES",
  ManageSettings = "MANAGE_SETTINGS",
  ManageShipping = "MANAGE_SHIPPING",
  ManageSocialAndStreaming = "MANAGE_SOCIAL_AND_STREAMING",
  ManageSpecializations = "MANAGE_SPECIALIZATIONS",
  ManageStaff = "MANAGE_STAFF",
  ManageSubscriptions = "MANAGE_SUBSCRIPTIONS",
  ManageSurveys = "MANAGE_SURVEYS",
  ManageTranslations = "MANAGE_TRANSLATIONS",
  ManageUsers = "MANAGE_USERS",
  ManageVendors = "MANAGE_VENDORS",
  ManageVisits = "MANAGE_VISITS",
  ManageVisitCancelReasons = "MANAGE_VISIT_CANCEL_REASONS",
  ManageVisitRejectionReasons = "MANAGE_VISIT_REJECTION_REASONS",
  ManageVisitSummary = "MANAGE_VISIT_SUMMARY",
  ManageWallets = "MANAGE_WALLETS",
  MedicationScientificDetails = "MEDICATION_SCIENTIFIC_DETAILS",
  PublishCodeSystem = "PUBLISH_CODE_SYSTEM",
  UploadCodeSystemFile = "UPLOAD_CODE_SYSTEM_FILE",
  ValidateProcedures = "VALIDATE_PROCEDURES",
  VerifyMemberIds = "VERIFY_MEMBER_IDS",
  VerifyNationalIds = "VERIFY_NATIONAL_IDS",
  ViewAppointments = "VIEW_APPOINTMENTS",
  ViewCodeSystemLists = "VIEW_CODE_SYSTEM_LISTS",
  ViewCustomers = "VIEW_CUSTOMERS",
  ViewHealthPrograms = "VIEW_HEALTH_PROGRAMS",
  ViewOrders = "VIEW_ORDERS",
  ViewPatients = "VIEW_PATIENTS",
  ViewPrograms = "VIEW_PROGRAMS",
  ViewProgramTeams = "VIEW_PROGRAM_TEAMS",
  ViewProgramTemplates = "VIEW_PROGRAM_TEMPLATES",
  ViewStaff = "VIEW_STAFF",
  ViewSubscriptions = "VIEW_SUBSCRIPTIONS",
  ViewSurveys = "VIEW_SURVEYS",
  ViewUsers = "VIEW_USERS",
  ViewVisits = "VIEW_VISITS",
  ViewWallets = "VIEW_WALLETS",
  WorkflowManageOrders = "WORKFLOW_MANAGE_ORDERS",
  ManageOutPatientJourneys = "manage_out_patient_journeys",
}

export type PermissionGroupCreate = {
  __typename?: "PermissionGroupCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupCreateInput = {
  addPermissions?: InputMaybe<Array<PermissionEnum>>;
  addUsers?: InputMaybe<Array<Scalars["ID"]>>;
  groupConfiguration: GroupConfigurationInput;
  name: Scalars["String"];
};

export type PermissionGroupDelete = {
  __typename?: "PermissionGroupDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupError = {
  __typename?: "PermissionGroupError";
  code: PermissionGroupErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Array<PermissionEnum>>;
  users?: Maybe<Array<Scalars["ID"]>>;
};

export enum PermissionGroupErrorCode {
  AssignNonStaffMember = "ASSIGN_NON_STAFF_MEMBER",
  CannotRemoveFromLastGroup = "CANNOT_REMOVE_FROM_LAST_GROUP",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  Invalid = "INVALID",
  LeftNotManageablePermission = "LEFT_NOT_MANAGEABLE_PERMISSION",
  NotEditable = "NOT_EDITABLE",
  NotFound = "NOT_FOUND",
  OutOfScopePermission = "OUT_OF_SCOPE_PERMISSION",
  OutOfScopeUser = "OUT_OF_SCOPE_USER",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type PermissionGroupFilterInput = {
  isEditable?: InputMaybe<Scalars["Boolean"]>;
  isGlobal?: InputMaybe<Scalars["Boolean"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export enum PermissionGroupSortField {
  CreatedDate = "CREATED_DATE",
  Name = "NAME",
}

export type PermissionGroupSortingInput = {
  direction: OrderDirection;
  field: PermissionGroupSortField;
};

export type PermissionGroupUpdate = {
  __typename?: "PermissionGroupUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupUpdateInput = {
  addPermissions?: InputMaybe<Array<PermissionEnum>>;
  addUsers?: InputMaybe<Array<Scalars["ID"]>>;
  groupConfiguration: GroupConfigurationUpdateInput;
  removePermissions?: InputMaybe<Array<PermissionEnum>>;
  removeUsers?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum PersonGenderEnum {
  Female = "FEMALE",
  Male = "MALE",
}

export type Pharmacist = Node & {
  __typename?: "Pharmacist";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type PharmacistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export type PharmacyCredentials = {
  __typename?: "PharmacyCredentials";
  branch: Branch;
  password: Scalars["String"];
  token?: Maybe<Scalars["String"]>;
  userName: Scalars["String"];
};

export type PharmacyCredentialsInput = {
  branch: Scalars["ID"];
  password: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
  userName: Scalars["String"];
};

export type Plan = Node & {
  __typename?: "Plan";
  created: Scalars["DateTime"];
  currency: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  fixedCost?: Maybe<Money>;
  fixedCostAmount: Scalars["Float"];
  fixedOrderCost?: Maybe<Money>;
  fixedOrderCostAmount: Scalars["Float"];
  fixedOrderPercentage: Scalars["Float"];
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  isEditable?: Maybe<Scalars["Boolean"]>;
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  period: PlanPeriod;
  subscriptions: VendorSubscriptionCountableConnection;
  validFrom: Scalars["DateTime"];
  validTill?: Maybe<Scalars["DateTime"]>;
};

export type PlanSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PlanCountableConnection = {
  __typename?: "PlanCountableConnection";
  edges: Array<PlanCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PlanCountableEdge = {
  __typename?: "PlanCountableEdge";
  cursor: Scalars["String"];
  node: Plan;
};

export type PlanCreate = {
  __typename?: "PlanCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plan?: Maybe<Plan>;
  subscriptionErrors: Array<SubscriptionError>;
};

export type PlanFilterInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
};

export type PlanInput = {
  description?: InputMaybe<Scalars["String"]>;
  fixedCostAmount?: InputMaybe<Scalars["Decimal"]>;
  fixedOrderCostAmount?: InputMaybe<Scalars["Decimal"]>;
  fixedOrderPercentage?: InputMaybe<Scalars["Decimal"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  period?: InputMaybe<Period>;
  validFrom?: InputMaybe<Scalars["DateTime"]>;
  validTill?: InputMaybe<Scalars["DateTime"]>;
};

export type PlanOrder = {
  direction: OrderDirection;
  field?: InputMaybe<PlanOrderField>;
};

export enum PlanOrderField {
  Name = "NAME",
}

export enum PlanPeriod {
  HalfYear = "HALF_YEAR",
  Monthly = "MONTHLY",
  ThreeMonths = "THREE_MONTHS",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export type PlanUpdate = {
  __typename?: "PlanUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plan?: Maybe<Plan>;
  subscriptionErrors: Array<SubscriptionError>;
};

export type Plugin = Node & {
  __typename?: "Plugin";
  active: Scalars["Boolean"];
  configuration?: Maybe<Array<Maybe<ConfigurationItem>>>;
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type PluginCountableConnection = {
  __typename?: "PluginCountableConnection";
  edges: Array<PluginCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PluginCountableEdge = {
  __typename?: "PluginCountableEdge";
  cursor: Scalars["String"];
  node: Plugin;
};

export type PluginError = {
  __typename?: "PluginError";
  code: PluginErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum PluginErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  PluginMisconfigured = "PLUGIN_MISCONFIGURED",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type PluginFilterInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export enum PluginSortField {
  IsActive = "IS_ACTIVE",
  Name = "NAME",
}

export type PluginSortingInput = {
  direction: OrderDirection;
  field: PluginSortField;
};

export type PluginUpdate = {
  __typename?: "PluginUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plugin?: Maybe<Plugin>;
  pluginsErrors: Array<PluginError>;
};

export type PluginUpdateInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  configuration?: InputMaybe<Array<InputMaybe<ConfigurationItemInput>>>;
};

export type PodiatricMedicalAssistant = Node & {
  __typename?: "PodiatricMedicalAssistant";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type PodiatricMedicalAssistantInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export type Prescription = Node & {
  __typename?: "Prescription";
  activeOrders?: Maybe<Array<Maybe<Order>>>;
  clinician?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  diagnosesHash?: Maybe<Scalars["String"]>;
  diagnosis?: Maybe<Array<Maybe<Diagnose>>>;
  dispenseCount: Scalars["Int"];
  dispenseMethod?: Maybe<PrescriptionDispenseMethodEnum>;
  dispenseStatus: PrescriptionDispenseStatus;
  earlyRefillRequests?: Maybe<EarlyRefillRequestCountableConnection>;
  id: Scalars["ID"];
  isDispensed: Scalars["Boolean"];
  lastDispenseDate?: Maybe<Scalars["DateTime"]>;
  medicalAlerts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  medications?: Maybe<Array<Maybe<Medication>>>;
  medicationsHash?: Maybe<Scalars["String"]>;
  modified: Scalars["DateTime"];
  orders?: Maybe<OrderCountableConnection>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars["Int"]>;
  patientIdentifier?: Maybe<Scalars["String"]>;
  patientIdentifierType: PrescriptionPatientIdentifierType;
  prescriptionId: Scalars["String"];
  providerLicense?: Maybe<Scalars["String"]>;
  referenceNumber: Scalars["String"];
  remainingDaysToNextRefill?: Maybe<Scalars["Int"]>;
  tpo?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["Int"]>;
  visit?: Maybe<Visit>;
};

export type PrescriptionDiagnosisArgs = {
  filter?: InputMaybe<DiagnoseFilterInput>;
};

export type PrescriptionEarlyRefillRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<EarlyRefillRequestFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PrescriptionMedicationsArgs = {
  filter?: InputMaybe<MedicationFilterInput>;
};

export type PrescriptionOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<OrderSortingInput>;
};

export type PrescriptionCountableConnection = {
  __typename?: "PrescriptionCountableConnection";
  edges: Array<PrescriptionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type PrescriptionCountableEdge = {
  __typename?: "PrescriptionCountableEdge";
  cursor: Scalars["String"];
  node: Prescription;
};

export enum PrescriptionDispenseMethodEnum {
  Dispense = "DISPENSE",
  Ingested = "INGESTED",
  Refill = "REFILL",
}

export enum PrescriptionDispenseStatus {
  Delivered = "DELIVERED",
  Dispensed = "DISPENSED",
  ErxHubAuthRequestFailed = "ERX_HUB_AUTH_REQUEST_FAILED",
  ErxHubClaimApproved = "ERX_HUB_CLAIM_APPROVED",
  ErxHubClaimFailed = "ERX_HUB_CLAIM_FAILED",
  ErxHubClaimRejected = "ERX_HUB_CLAIM_REJECTED",
  ErxHubFullyApproved = "ERX_HUB_FULLY_APPROVED",
  ErxHubPartiallyApproved = "ERX_HUB_PARTIALLY_APPROVED",
  ErxHubRejected = "ERX_HUB_REJECTED",
  ErxHubTimedOut = "ERX_HUB_TIMED_OUT",
  InProgress = "IN_PROGRESS",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Pending = "PENDING",
  ReadyForCustomerPickup = "READY_FOR_CUSTOMER_PICKUP",
  WaitingErxHubApproval = "WAITING_ERX_HUB_APPROVAL",
  WaitingErxHubClaimApproval = "WAITING_ERX_HUB_CLAIM_APPROVAL",
  WaitingProviderSelection = "WAITING_PROVIDER_SELECTION",
}

export enum PrescriptionDispenseStatusEnum {
  Delivered = "DELIVERED",
  Dispensed = "DISPENSED",
  ErxHubAuthRequestFailed = "ERX_HUB_AUTH_REQUEST_FAILED",
  ErxHubClaimApproved = "ERX_HUB_CLAIM_APPROVED",
  ErxHubClaimFailed = "ERX_HUB_CLAIM_FAILED",
  ErxHubClaimRejected = "ERX_HUB_CLAIM_REJECTED",
  ErxHubFullyApproved = "ERX_HUB_FULLY_APPROVED",
  ErxHubPartiallyApproved = "ERX_HUB_PARTIALLY_APPROVED",
  ErxHubRejected = "ERX_HUB_REJECTED",
  ErxHubTimedOut = "ERX_HUB_TIMED_OUT",
  InProgress = "IN_PROGRESS",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Pending = "PENDING",
  ReadyForCustomerPickup = "READY_FOR_CUSTOMER_PICKUP",
  WaitingErxHubApproval = "WAITING_ERX_HUB_APPROVAL",
  WaitingErxHubClaimApproval = "WAITING_ERX_HUB_CLAIM_APPROVAL",
  WaitingProviderSelection = "WAITING_PROVIDER_SELECTION",
}

export type PrescriptionError = {
  __typename?: "PrescriptionError";
  code: PrescriptionErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum PrescriptionErrorCode {
  ControlledDrug = "CONTROLLED_DRUG",
  GraphqlError = "GRAPHQL_ERROR",
  Ingested = "INGESTED",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type PrescriptionFilterInput = {
  canRefill?: InputMaybe<Scalars["Boolean"]>;
  created?: InputMaybe<DateRangeInput>;
  medication?: InputMaybe<Scalars["String"]>;
  orderType?: InputMaybe<OrderTypesEnum>;
  orders?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ordersNumbers?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  patientId?: InputMaybe<Scalars["String"]>;
  referenceNumber?: InputMaybe<Scalars["String"]>;
  referenceNumber_Icontains?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Array<InputMaybe<PrescriptionDispenseStatusEnum>>>;
};

export type PrescriptionOrderAssignVendors = {
  __typename?: "PrescriptionOrderAssignVendors";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  orders?: Maybe<Array<Maybe<Order>>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type PrescriptionOrderAssignVendorsInput = {
  branches: Array<InputMaybe<Scalars["ID"]>>;
  order: Scalars["ID"];
};

export enum PrescriptionPatientIdentifierType {
  MemberId = "MEMBER_ID",
  NationalId = "NATIONAL_ID",
}

export enum PrescriptionSortField {
  Clinician = "CLINICIAN",
  Created = "CREATED",
  PatientIdentifier = "PATIENT_IDENTIFIER",
  ProviderLicense = "PROVIDER_LICENSE",
  ReferenceNumber = "REFERENCE_NUMBER",
}

export type PrescriptionSortingInput = {
  direction: OrderDirection;
  field: PrescriptionSortField;
};

export enum PriceRangeEnum {
  Average = "AVERAGE",
  Cheap = "CHEAP",
  Expensive = "EXPENSIVE",
  VeryCheap = "VERY_CHEAP",
  VeryExpensive = "VERY_EXPENSIVE",
}

export type PriorAuthorizationActivityInput = {
  code?: InputMaybe<Scalars["String"]>;
  denialCode?: InputMaybe<Scalars["String"]>;
  denialDescription?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["Int"]>;
};

export type PriorAuthorizationInput = {
  activities?: InputMaybe<Array<InputMaybe<PriorAuthorizationActivityInput>>>;
  comments?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
};

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM",
}

export enum ProcessingStatus {
  Approved = "APPROVED",
  Draft = "DRAFT",
  Expired = "EXPIRED",
  Published = "PUBLISHED",
  Rejected = "REJECTED",
  UnderReview = "UNDER_REVIEW",
}

export enum ProfileType {
  Doctor = "DOCTOR",
  Patient = "PATIENT",
  Vendor = "VENDOR",
}

export type ProviderGuidedCareHealthProgramTeam = H_Node & {
  __typename?: "ProviderGuidedCareHealthProgramTeam";
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  branch: Branch;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  description?: Maybe<Scalars["String"]>;
  guidedCareHealthProgram: GuidedCareHealthProgram;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  isPatientAlreadyEnrolled?: Maybe<Scalars["Boolean"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  maxNumberOfPatients?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  numberOfEnrolledPatients?: Maybe<Scalars["Int"]>;
  provider: Vendor;
  teamEnrolledPatients?: Maybe<Array<Maybe<HealthProgramMember>>>;
  teamMembers?: Maybe<Array<Maybe<ProviderGuidedCareProgramTeamMember>>>;
  teamStatus?: Maybe<ProviderGuidedCareTeamStatus>;
  visits?: Maybe<Array<Maybe<Visit>>>;
};

export type ProviderGuidedCareHealthProgramTeamCrud = {
  __typename?: "ProviderGuidedCareHealthProgramTeamCRUD";
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  providerGuidedCareHealthProgramTeamErrors?: Maybe<Array<H_EntityError>>;
};

export type ProviderGuidedCareHealthProgramTeamCountableConnection = {
  __typename?: "ProviderGuidedCareHealthProgramTeamCountableConnection";
  edges: Array<ProviderGuidedCareHealthProgramTeamCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type ProviderGuidedCareHealthProgramTeamCountableEdge = {
  __typename?: "ProviderGuidedCareHealthProgramTeamCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ProviderGuidedCareHealthProgramTeam;
};

export type ProviderGuidedCareHealthProgramTeamFilterInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  doctorIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  guidedCareHealthProgramId?: InputMaybe<Scalars["ID"]>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isProgramLatestVersion?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  patientUsers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  patientsNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  payerId?: InputMaybe<Scalars["ID"]>;
  providerId?: InputMaybe<Scalars["ID"]>;
  teamLeaderDoctorUserId?: InputMaybe<Scalars["ID"]>;
};

export type ProviderGuidedCareHealthProgramTeamInput = {
  branchId: Scalars["ID"];
  description: Scalars["String"];
  guidedCareHealthProgram: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
  isActive: Scalars["Boolean"];
  maxNumberOfPatients: Scalars["Int"];
  name: Scalars["String"];
  providerId: Scalars["ID"];
  teamMembers?: InputMaybe<Array<InputMaybe<ProviderGuidedCareProgramTeamMemberInput>>>;
};

export type ProviderGuidedCareHealthProgramTeamPayerResponseInput = {
  isResponseApprove?: InputMaybe<Scalars["Boolean"]>;
  teamId?: InputMaybe<Scalars["ID"]>;
};

export enum ProviderGuidedCareHealthProgramTeamSortingField {
  Created = "CREATED",
  Modified = "MODIFIED",
  Name = "NAME",
  Status = "STATUS",
}

export type ProviderGuidedCareHealthProgramTeamSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: ProviderGuidedCareHealthProgramTeamSortingField;
};

export type ProviderGuidedCareProgramTeamMember = H_Node & {
  __typename?: "ProviderGuidedCareProgramTeamMember";
  doctor?: Maybe<Doctor>;
  guidedCareProgramTeamMember?: Maybe<GuidedCareProgramTeamMember>;
  id: Scalars["ID"];
  isBackup?: Maybe<Scalars["Boolean"]>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  user?: Maybe<User>;
};

export type ProviderGuidedCareProgramTeamMemberInput = {
  guidedCareProgramTeamMember: GuidedCareProgramTeamMemberIdInput;
  id?: InputMaybe<Scalars["ID"]>;
  isBackup?: InputMaybe<Scalars["Boolean"]>;
  userId: Scalars["ID"];
};

export enum ProviderGuidedCareTeamStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type ProviderSettings = H_Node & {
  __typename?: "ProviderSettings";
  appointmentConfirmationTimeoutSeconds?: Maybe<Scalars["Int"]>;
  appointmentReminderTimeoutSeconds?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  outPatientJourneySupported?: Maybe<Scalars["Boolean"]>;
  paymentAfterConsultation?: Maybe<Scalars["Boolean"]>;
  vendor?: Maybe<Vendor>;
};

export type ProviderSettingsCountableConnection = {
  __typename?: "ProviderSettingsCountableConnection";
  edges: Array<ProviderSettingsCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type ProviderSettingsCountableEdge = {
  __typename?: "ProviderSettingsCountableEdge";
  cursor: Scalars["String"];
  node: ProviderSettings;
};

export type ProviderSettingsFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type ProviderSettingsInput = {
  appointmentConfirmationTimeoutSeconds?: InputMaybe<Scalars["Int"]>;
  appointmentReminderTimeoutSeconds?: InputMaybe<Scalars["Int"]>;
  outPatientJourneySupported?: InputMaybe<Scalars["Boolean"]>;
  paymentAfterConsultation?: InputMaybe<Scalars["Boolean"]>;
};

export type Psychologist = Node & {
  __typename?: "Psychologist";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type PsychologistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export type PublishCriteria = {
  __typename?: "PublishCriteria";
  answeredParticipantsCount?: Maybe<Scalars["Int"]>;
  chronicDiseaseCode?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  expirationDate?: Maybe<Scalars["Date"]>;
  fromAge?: Maybe<Scalars["Int"]>;
  gender?: Maybe<SurveyGender>;
  id?: Maybe<Scalars["ID"]>;
  maxParticipants?: Maybe<Scalars["Int"]>;
  participantsCount?: Maybe<Scalars["Int"]>;
  toAge?: Maybe<Scalars["Int"]>;
};

export type PublishCriteriaInput = {
  chronicDiseaseCode?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  expirationDate: Scalars["Date"];
  fromAge?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<SurveyGender>;
  maxParticipants: Scalars["Int"];
  toAge?: InputMaybe<Scalars["Int"]>;
};

export type Qualification = Node & {
  __typename?: "Qualification";
  code: Scalars["String"];
  created: Scalars["DateTime"];
  doctor?: Maybe<Doctor>;
  fromDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  issuer: Scalars["String"];
  modified: Scalars["DateTime"];
  toDate?: Maybe<Scalars["Date"]>;
};

export type QualificationInput = {
  code?: InputMaybe<Scalars["String"]>;
  fromDate?: InputMaybe<Scalars["Date"]>;
  issuer?: InputMaybe<Scalars["String"]>;
  toDate?: InputMaybe<Scalars["Date"]>;
};

export type Query = {
  __typename?: "Query";
  TemplateActivityEventItems?: Maybe<TemplateActivityEventItemCountableConnection>;
  activeAdminPromotions?: Maybe<AdminPromotionConnection>;
  address?: Maybe<Address>;
  adminPromotion?: Maybe<AdminPromotion>;
  adminPromotions?: Maybe<AdminPromotionConnection>;
  anonymousChatGroups?: Maybe<AnonymousChatGroupCountableConnection>;
  anxietyScreening?: Maybe<AnxietyScreeningResponse>;
  /** Look up a Appointment by ID. */
  appointment?: Maybe<Appointment>;
  appointmentNotificationSettings?: Maybe<AppointmentNotificationSettingCountableConnection>;
  appointmentStatusHistories?: Maybe<AppointmentStatusHistoryCountableConnection>;
  /** List of appointments */
  appointments?: Maybe<AppointmentCountableConnection>;
  article?: Maybe<Article>;
  articleCategories?: Maybe<ArticleCategoryCountableConnection>;
  articleCategory?: Maybe<ArticleCategory>;
  articles?: Maybe<ArticleCountableConnection>;
  auditLogsForHealthProgramModule?: Maybe<AuditLogCountableConnection>;
  auditLogsForPaymentModule?: Maybe<AuditLogCountableConnection>;
  /** needed to send the values of (doctorId, start, end) or the value of appointmentId */
  availableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  beeAndtdee?: Maybe<BeeAndTdeeResponse>;
  block?: Maybe<Block>;
  blocks?: Maybe<BlockCountableConnection>;
  bodyFatPercentage?: Maybe<BodyFatPercentageResponse>;
  branch?: Maybe<Branch>;
  branches?: Maybe<BranchCountableConnection>;
  bulkFiles?: Maybe<BulkFileConnection>;
  bulkTemplates?: Maybe<Array<Maybe<BulkTemplate>>>;
  callbackRequest?: Maybe<CallbackRequest>;
  callbackRequestUpdateHistory: CallbackRequestUpdateHistoryCountableConnection;
  callbackRequests: CallbackRequestCountableConnection;
  cancellationReasons?: Maybe<Array<Maybe<H_CancelReason>>>;
  careJourneyStatistics?: Maybe<CareJourneyStatistics>;
  chatFlow?: Maybe<ChatFlow>;
  chatFlows?: Maybe<ChatFlowConnection>;
  cities?: Maybe<CityCountableConnection>;
  city?: Maybe<City>;
  codeSystemConceptAutoCompleteSearch?: Maybe<Array<Maybe<Scalars["String"]>>>;
  codeSystemConceptSearch?: Maybe<CodeSystemConceptConnection>;
  consumerViewPreference?: Maybe<ConsumerViewPreference>;
  containsControlledDrugs?: Maybe<Scalars["Boolean"]>;
  countries?: Maybe<Array<Maybe<CountryDisplay>>>;
  customer?: Maybe<User>;
  customers?: Maybe<UserCountableConnection>;
  customersDashboard?: Maybe<Array<Maybe<Aggregation>>>;
  deliveryTimeSlots?: Maybe<DeliveryTimeSlotCountableConnection>;
  depressionScreening?: Maybe<DepressionScreeningResponse>;
  diabetesRisk?: Maybe<DiabetesRiskResponse>;
  division?: Maybe<Division>;
  divisions?: Maybe<DivisionConnection>;
  doctor?: Maybe<Doctor>;
  doctorCallsRatings: DoctorCallsRatingsConnection;
  /** must be called by vendor nurse user or vendor doctor */
  doctorNurseAssignments?: Maybe<DoctorNurseAssignmentCountableConnection>;
  doctorTeamEnrolledMembers?: Maybe<HealthProgramMemberCountableConnection>;
  doctorVisitHealthProgramTracking: DoctorHealthProgramTotalPointsTrackingConnection;
  doctorVisitRejectionAudits: DoctorVisitRejectionAuditCountableConnection;
  doctors?: Maybe<DoctorCountableConnection>;
  doctorsAvailabilitiesSum?: Maybe<Array<Maybe<DoctorAvailabilitiesSum>>>;
  documentStore?: Maybe<DocumentStore>;
  documentStores?: Maybe<DocumentStoreConnection>;
  downloadBulkFile?: Maybe<Scalars["String"]>;
  downloadCodeSystemFile?: Maybe<Scalars["String"]>;
  downloadSubList?: Maybe<Scalars["String"]>;
  draftOrders?: Maybe<OrderCountableConnection>;
  earlyRefillReasons?: Maybe<Array<Maybe<EarlyRefillReason>>>;
  earlyRefillRequests?: Maybe<EarlyRefillRequestCountableConnection>;
  featureFlag?: Maybe<FeatureFlag>;
  framinghamRisk?: Maybe<FraminghamRiskResponse>;
  generateAppointmentChatLinks?: Maybe<AppointmentChatLinkInfo>;
  getAllDiagnosisValidProcedure?: Maybe<DiagnosisValidProcedureConnection>;
  getAllListFields?: Maybe<Array<Maybe<Field>>>;
  getAllowedDeliveryTypes?: Maybe<Array<Maybe<GenericDrugDeliveryEligibility>>>;
  getClinician?: Maybe<Clinician>;
  getClinicianCredentials?: Maybe<ClinicianCredentials>;
  getCodeSystem?: Maybe<CodeSystem>;
  getCodeSystemConcept?: Maybe<CodeSystemConcept>;
  getCodeSystemConcepts?: Maybe<CodeSystemConceptConnection>;
  getCodeSystemConceptsByCode?: Maybe<CodeSystemConceptConnection>;
  getCodeSystemDefinition?: Maybe<CodeSystemDefinition>;
  getCodeSystemDefinitionById?: Maybe<CodeSystemDefinition>;
  getCodeSystemDefinitionsSummery?: Maybe<Array<Maybe<CodeSystemSummery>>>;
  getCodeSystems?: Maybe<CodeSystemConnection>;
  getDecision?: Maybe<Decision>;
  getDecisions?: Maybe<DecisionConnection>;
  getDiagnosisValidProcedure?: Maybe<DiagnosisValidProcedure>;
  getDigitalTwinHealthProfile?: Maybe<DigitalTwinHealthProfile>;
  getGenericDrugs?: Maybe<Array<Maybe<GenericDrug>>>;
  getHISDoctorAppointments?: Maybe<Array<Maybe<DoctorAppointment>>>;
  getHISDoctorAvailableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  getHealthCondition?: Maybe<HealthCondition>;
  getHealthConditionDiagnoses?: Maybe<HealthConditionDiagnosisConnection>;
  getHealthConditions?: Maybe<HealthConditioneConnection>;
  getHealthParameter?: Maybe<HealthParameter>;
  getHealthParameterCategories?: Maybe<FieldCategoryConnection>;
  getHealthParameterUnits?: Maybe<Array<Maybe<FieldUnit>>>;
  getHealthParameters?: Maybe<HealthParameterConnection>;
  getLabTemplate?: Maybe<LabTemplate>;
  getLabTemplates?: Maybe<LabTemplateConnection>;
  getLatestBodyComposition: BodyComposition;
  getPayment?: Maybe<PaymentResponse>;
  getPaymentCard?: Maybe<UserCard>;
  getPaymentCards?: Maybe<UserCardConnection>;
  getPharmacyCredentials?: Maybe<PharmacyCredentials>;
  getPlan?: Maybe<DecisionPlan>;
  getPlans?: Maybe<DecisionPlanConnection>;
  getReferralById?: Maybe<PatientReferral>;
  getRejectionReasons?: Maybe<Array<Maybe<RejectionReason>>>;
  getRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  getRiskFactorTemplates?: Maybe<RiskFactorTemplateConnection>;
  getSpecialties?: Maybe<SpecialityConnection>;
  getSubList?: Maybe<SubList>;
  getSubListItems?: Maybe<SubListItemConnection>;
  getSubLists?: Maybe<SubListConnection>;
  getTradeDrugQuantity?: Maybe<Array<Maybe<MedicationTradeDrugs>>>;
  getTradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
  getTransaction?: Maybe<TransactionReport>;
  getVisitReferrals?: Maybe<PatientReferralConnection>;
  goalWeight?: Maybe<GoalWeightResponse>;
  groupCandidateUsers?: Maybe<UserCountableConnection>;
  guidedCareHealthProgram?: Maybe<GuidedCareHealthProgram>;
  guidedCareHealthProgramActivity?: Maybe<GuidedCareHealthProgramActivity>;
  guidedCareHealthPrograms?: Maybe<GuidedCareHealthProgramCountableConnection>;
  /** Look up a Guided Care Journey by ID. */
  guidedCareJourney?: Maybe<GuidedCareJourney>;
  guidedCareJourneyItem?: Maybe<GuidedCareJourneyItem>;
  guidedCareJourneyItems?: Maybe<GuidedCareJourneyItemCountableConnection>;
  /** List of Guided Care Journeys */
  guidedCareJourneys?: Maybe<GuidedCareJourneyCountableConnection>;
  /** Look up GuidedCareTask by ID. */
  guidedCareTask?: Maybe<GuidedCareTask>;
  /** List of GuidedCareTasks */
  guidedCareTasks?: Maybe<GuidedCareTaskCountableConnection>;
  guidedCareTeamChatGroups?: Maybe<Array<AnonymousChatGroup>>;
  healthChannel?: Maybe<HealthChannel>;
  healthChannelCategories?: Maybe<HealthChannelCategoryCountableConnection>;
  healthChannelCategory?: Maybe<HealthChannelCategory>;
  healthChannelFollowers: HealthChannelFollowerCountableConnection;
  healthChannels?: Maybe<HealthChannelCountableConnection>;
  healthCheckForHealthProgram?: Maybe<Scalars["String"]>;
  healthCheckForPayment?: Maybe<Scalars["String"]>;
  /** Look up HealthProgram by ID. */
  healthProgram?: Maybe<HealthProgram>;
  /** Look up BenefitOption by ID. */
  healthProgramBenefitGroupOption?: Maybe<BenefitOption>;
  healthProgramBenefitGroupOptions?: Maybe<Array<Maybe<BenefitOption>>>;
  healthProgramBenefitGroups?: Maybe<Array<Maybe<BenefitGroup>>>;
  healthProgramCallsForVendor: Array<Maybe<HealthProgramCallsForVendorResponse>>;
  healthProgramCallsPerVendor: HealthProgramCallsPerVendorResponseConnection;
  /** Look up ExclusionOption by ID. */
  healthProgramExclusionOption?: Maybe<ExclusionOption>;
  healthProgramExclusionOptions?: Maybe<Array<Maybe<ExclusionOption>>>;
  healthProgramExtraBenefits?: Maybe<Array<Maybe<ExtraBenefit>>>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  healthProgramMemberLists?: Maybe<HealthProgramMemberListCountableConnection>;
  healthProgramMembers?: Maybe<HealthProgramMemberCountableConnection>;
  healthProgramMembershipRequest?: Maybe<HealthProgramMembershipRequest>;
  healthProgramMembershipRequests: HealthProgramMembershipRequestConnection;
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  healthProgramNetworkProviders?: Maybe<HealthProgramNetworkProviderCountableConnection>;
  healthProgramNetworks?: Maybe<HealthProgramNetworkCountableConnection>;
  healthProgramTemplate?: Maybe<HealthProgramTemplate>;
  healthProgramTemplates?: Maybe<HealthProgramTemplateCountableConnection>;
  healthProgramTotalPointsReport: HealthProgramTotalPointsTrackingConnection;
  healthProgramUserSubscriptions?: Maybe<HealthProgramUserSubscriptionCountableConnection>;
  healthProgramVendorTotalPointsReport: HealthProgramVendorTotalPointsTrackingConnection;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptoms?: Maybe<HealthSymptomCountableConnection>;
  idealBodyWeight?: Maybe<IdealBodyWeightResponse>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  insuranceNetworkFacilities?: Maybe<InsuranceNetworkFacilityCountableConnection>;
  insuranceNetworks?: Maybe<InsuranceNetworkCountableConnection>;
  keyCloakPermissions?: Maybe<Array<Maybe<Permission>>>;
  lab?: Maybe<Lab>;
  language?: Maybe<Language>;
  languages?: Maybe<LanguageCountableConnection>;
  latestCodeSystemVersion?: Maybe<Scalars["Int"]>;
  lifeExpectancy?: Maybe<LifeExpectancyResponse>;
  marketplaceBrand?: Maybe<MarketplaceBrand>;
  marketplaceBrands?: Maybe<MarketplaceBrandConnection>;
  marketplaceCart?: Maybe<MarketplaceCart>;
  marketplaceDiscount?: Maybe<MarketplaceDiscount>;
  marketplaceDiscounts?: Maybe<MarketplaceDiscountConnection>;
  marketplaceHealthPackage?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageBestSellers?: Maybe<MarketplaceHealthPackageConnection>;
  marketplaceHealthPackageCategories?: Maybe<MarketplaceHealthPackageCategoryConnection>;
  marketplaceHealthPackageCategory?: Maybe<MarketplaceHealthPackageCategory>;
  marketplaceHealthPackages?: Maybe<MarketplaceHealthPackageConnection>;
  marketplaceHealthPackagesForYou?: Maybe<MarketplaceHealthPackageConnection>;
  marketplaceOrder?: Maybe<MarketplaceOrder>;
  marketplaceOrders?: Maybe<MarketplaceOrderConnection>;
  marketplaceProduct?: Maybe<MarketplaceProduct>;
  marketplaceProductBestSellers?: Maybe<MarketplaceProductConnection>;
  marketplaceProductCategories?: Maybe<MarketplaceProductCategoryConnection>;
  marketplaceProductCategory?: Maybe<MarketplaceProductCategory>;
  marketplaceProducts?: Maybe<MarketplaceProductConnection>;
  marketplaceProductsForYou?: Maybe<MarketplaceProductConnection>;
  marketplacePromotion?: Maybe<MarketplacePromotion>;
  marketplacePromotions?: Maybe<MarketplacePromotionConnection>;
  marketplaceReviews?: Maybe<MarketplaceReviewConnection>;
  marketplaceVATPercentage?: Maybe<Scalars["Double"]>;
  marketplaceWishlist?: Maybe<MarketplaceWishlist>;
  me?: Maybe<User>;
  medicalForm?: Maybe<MedicalForm>;
  medicalFormAnswers?: Maybe<MedicalFormAnswerConnection>;
  medicalForms?: Maybe<MedicalFormConnection>;
  /** Look up a Medical Message by ID. */
  medicalMessage?: Maybe<MedicalMessage>;
  /** Look up a Medical Message by Unique Message Code. */
  medicalMessageByCode?: Maybe<MedicalMessage>;
  /** Look up a Medical Message Variant by ID. */
  medicalMessageVariant?: Maybe<MedicalMessageVariant>;
  /** List of Medical Message Variants */
  medicalMessageVariants?: Maybe<MedicalMessageVariantCountableConnection>;
  /** List of Medical Messages */
  medicalMessages?: Maybe<MedicalMessageCountableConnection>;
  medlistUsers?: Maybe<UserCountableConnection>;
  order?: Maybe<Order>;
  orderByToken?: Maybe<Order>;
  orderRejectionReasons?: Maybe<OrderRejectionReasonCountableConnection>;
  orders?: Maybe<OrderCountableConnection>;
  ordersDashboard?: Maybe<Array<Maybe<Aggregation>>>;
  ordersSocialDashboard?: Maybe<Array<Maybe<Scalars["DictType"]>>>;
  ordersTotal?: Maybe<TaxedMoney>;
  outPatientJourneyStatusHistories?: Maybe<OutPatientJourneyStatusHistoryCountableConnection>;
  outPatientJourneys?: Maybe<OutPatientJourneyCountableConnection>;
  patient?: Maybe<Patient>;
  patientEligibleTeams?: Maybe<ProviderGuidedCareHealthProgramTeamCountableConnection>;
  /** Look up a Patient Health Parameter by ID. */
  patientHealthParameter?: Maybe<PatientHealthParameter>;
  patientHealthParameterVitalSignsChangePercentage?: Maybe<Array<Maybe<PatientHpVitalSignsChangePercentage>>>;
  /** List of Patient Health Parameters */
  patientHealthParameters?: Maybe<PatientHealthParameterCountableConnection>;
  patientIdByConsentCode?: Maybe<Patient>;
  patientMedicalProfile?: Maybe<PatientMedicalProfile>;
  patientProfileActiveConsentForms: PatientProfileConsentFormConnection;
  patientProfileConsentForm?: Maybe<PatientProfileConsentForm>;
  patientProfileConsentFormCheck: Scalars["Boolean"];
  patientProfilePendingConsentForms: PatientProfileConsentFormConnection;
  patientShare?: Maybe<PatientShare>;
  patientShareForAppointment?: Maybe<PatientShare>;
  patients?: Maybe<PatientCountableConnection>;
  payer?: Maybe<Payer>;
  payerBankList?: Maybe<Array<Maybe<PayerBankListResponse>>>;
  payerBenefitList?: Maybe<Array<Maybe<PayerBenefitListResponse>>>;
  payerCityList?: Maybe<Array<Maybe<PayerCityListResponse>>>;
  payerClaim?: Maybe<PayerClaimDetailsResponse>;
  /** Payer Queries */
  payerClaims?: Maybe<Array<Maybe<PayerClaimListResponse>>>;
  payerClinicianDetails?: Maybe<PayerClinicianDetailsResponse>;
  payerClinicianList?: Maybe<Array<Maybe<PayerClinicianListResponse>>>;
  payerClinicianSearch?: Maybe<Array<Maybe<PayerClinicianSearchResponse>>>;
  payerCountries?: Maybe<Array<Maybe<PayerCountryResponse>>>;
  payerCurrencies?: Maybe<Array<Maybe<PayerCurrencyResponse>>>;
  payerLanguages?: Maybe<Array<Maybe<PayerLanguageResponse>>>;
  payerMemberBankDetails?: Maybe<Array<Maybe<PayerMemberBankDetailsResponse>>>;
  payerPreApprovals?: Maybe<Array<Maybe<PayerPreApprovalListResponse>>>;
  payerProviderDetails?: Maybe<PayerProviderDetailsResponse>;
  payerProviderFilterList?: Maybe<Array<Maybe<PayerProviderListResponse>>>;
  payerProviderList?: Maybe<Array<Maybe<PayerProviderListResponse>>>;
  payerProviderNearByList?: Maybe<Array<Maybe<PayerProviderNearByListResponse>>>;
  payerProviderTypeList?: Maybe<Array<Maybe<PayerProviderTypeListResponse>>>;
  payerSpecialtyList?: Maybe<Array<Maybe<PayerSpecialtyListResponse>>>;
  payers?: Maybe<PayerCountableConnection>;
  /** can be called by any of (patient, vendor) user only */
  paymentInvoices?: Maybe<PaymentInvoiceCountableConnection>;
  paymentReceipts: PaymentReceiptCountableConnection;
  permissionGroup?: Maybe<Group>;
  permissionGroups?: Maybe<GroupCountableConnection>;
  permissions: Array<Maybe<Permission>>;
  plan?: Maybe<Plan>;
  planPeriods?: Maybe<Array<Maybe<Scalars["String"]>>>;
  plans?: Maybe<PlanCountableConnection>;
  plugin?: Maybe<Plugin>;
  plugins?: Maybe<PluginCountableConnection>;
  prescription?: Maybe<Prescription>;
  prescriptions?: Maybe<PrescriptionCountableConnection>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  providerGuidedCareHealthProgramTeams?: Maybe<ProviderGuidedCareHealthProgramTeamCountableConnection>;
  providerSettings?: Maybe<ProviderSettingsCountableConnection>;
  question?: Maybe<Question>;
  questionAnswers?: Maybe<QuestionAnswerConnection>;
  readArticlesCount?: Maybe<Scalars["Int"]>;
  rejectionReasons?: Maybe<Array<Maybe<H_RejectionReason>>>;
  reviewQuestions?: Maybe<Array<Maybe<ReviewQuestion>>>;
  siteSettings: SiteSettings;
  sleep?: Maybe<SleepResponse>;
  survey?: Maybe<Survey>;
  surveyStatistics?: Maybe<SurveyStatistics>;
  surveys?: Maybe<SurveyConnection>;
  /** Look up TaskDefinition by ID. */
  taskDefinition?: Maybe<TaskDefinition>;
  taskDefinitions?: Maybe<TaskDefinitionCountableConnection>;
  taxTypes?: Maybe<Array<Maybe<TaxType>>>;
  teamEnrolledMembers?: Maybe<HealthProgramMemberCountableConnection>;
  teamEnrolledPatients?: Maybe<PatientMinimalCountableConnection>;
  teamLeaderDoctors?: Maybe<TypeProviderGuidedCareProgramTeamMemberCountableConnection>;
  templateActivity?: Maybe<TemplateActivity>;
  templateInterventionActivity?: Maybe<TemplateInterventionActivity>;
  templateInterventions?: Maybe<HealthProgramTemplateInterventionCountableConnection>;
  templateRecommendations?: Maybe<TemplateRecommendationCountableConnection>;
  unverifiedNationals?: Maybe<UnverifiedNationalsCountableConnection>;
  user?: Maybe<User>;
  userManagementSiteSettings?: Maybe<UserManagementSiteSettings>;
  users?: Maybe<UserCountableConnection>;
  vendor?: Maybe<Vendor>;
  vendorDepartment?: Maybe<Department>;
  vendorDepartments?: Maybe<DepartmentConnection>;
  vendorSubscription?: Maybe<VendorSubscription>;
  vendorSubscriptions?: Maybe<VendorSubscriptionCountableConnection>;
  vendors?: Maybe<VendorCountableConnection>;
  /** Look up HealthProgram by ID. */
  visit?: Maybe<Visit>;
  visitComplains: VisitComplainCountableConnection;
  visitDiagnosisRiskTemplate?: Maybe<VisitDiagnosisRiskTemplate>;
  visitDiagnosisRiskTemplates?: Maybe<VisitDiagnosisRiskTemplateCountableConnection>;
  visitMessages?: Maybe<VisitMessageCountableConnection>;
  visitPrescriptions?: Maybe<VisitPrescriptionTransactionCountableConnection>;
  visitReviewQuestions?: Maybe<VisitReviewQuestionCountableConnection>;
  visitStatusHistories?: Maybe<VisitStatusHistoryCountableConnection>;
  visitSummaries: VisitSummaryCountableConnection;
  visitSummary?: Maybe<VisitSummary>;
  /** all accessible visits for the requester */
  visits?: Maybe<VisitCountableConnection>;
};

export type QueryTemplateActivityEventItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter: TemplateActivityEventItemFilterInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<TemplateActivityEventItemSortingInput>;
};

export type QueryActiveAdminPromotionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<AdminPromotionSortingInput>;
};

export type QueryAddressArgs = {
  id: Scalars["ID"];
};

export type QueryAdminPromotionArgs = {
  id: Scalars["ID"];
};

export type QueryAdminPromotionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplacePromotionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<AdminPromotionSortingInput>;
};

export type QueryAnonymousChatGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryAnxietyScreeningArgs = {
  input: AnxietyScreeningInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryAppointmentArgs = {
  id: Scalars["ID"];
};

export type QueryAppointmentNotificationSettingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AppointmentNotificationSettingFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryAppointmentStatusHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AppointmentStatusHistoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryAppointmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AppointmentFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<AppointmentSortingInput>;
};

export type QueryArticleArgs = {
  id: Scalars["ID"];
};

export type QueryArticleCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ArticleCategoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<ArticleCategorySortingInput>;
};

export type QueryArticleCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ArticleFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<ArticleSortingInput>;
};

export type QueryAuditLogsForHealthProgramModuleArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AuditLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryAuditLogsForPaymentModuleArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AuditLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryAvailableTimeSlotsArgs = {
  doctorId?: InputMaybe<Scalars["ID"]>;
  end?: InputMaybe<Scalars["Instant"]>;
  start?: InputMaybe<Scalars["Instant"]>;
};

export type QueryBeeAndtdeeArgs = {
  input: BeeAndTdeeInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryBlockArgs = {
  id: Scalars["ID"];
};

export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<BlockFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<BlockOrder>;
};

export type QueryBodyFatPercentageArgs = {
  input: BodyFatPercentageInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryBranchArgs = {
  id: Scalars["ID"];
};

export type QueryBranchesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<BranchFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<BranchOrder>;
  userLocation?: InputMaybe<LocationInput>;
};

export type QueryBulkFilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<BulkFilesFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<BulkFilesSortingInput>;
};

export type QueryCallbackRequestArgs = {
  id: Scalars["ID"];
};

export type QueryCallbackRequestUpdateHistoryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  callbackRequestId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryCallbackRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<CallbackRequestFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryCancellationReasonsArgs = {
  filter?: InputMaybe<ReasonFilterInput>;
};

export type QueryCareJourneyStatisticsArgs = {
  filter?: InputMaybe<CareJourneyStatisticsFilterInput>;
};

export type QueryChatFlowArgs = {
  id: Scalars["ID"];
};

export type QueryChatFlowsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ChatFlowFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<ChatFlowSortingInput>;
};

export type QueryCitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<CityFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<CityOrder>;
};

export type QueryCityArgs = {
  id: Scalars["ID"];
};

export type QueryCodeSystemConceptAutoCompleteSearchArgs = {
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  codeSystemId?: InputMaybe<Scalars["ID"]>;
  keyword?: InputMaybe<Scalars["String"]>;
  propertyCode: Scalars["String"];
};

export type QueryCodeSystemConceptSearchArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  codeSystemId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  keyword?: InputMaybe<Scalars["String"]>;
  keywordSearch?: InputMaybe<KeywordSearch>;
  last?: InputMaybe<Scalars["Int"]>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
};

export type QueryConsumerViewPreferenceArgs = {
  view: Scalars["String"];
};

export type QueryContainsControlledDrugsArgs = {
  genericDrugs?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryCustomerArgs = {
  nationalId: Scalars["String"];
};

export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  branch?: InputMaybe<Scalars["ID"]>;
  filter?: InputMaybe<CustomerFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  hasChatWith?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<UserSortingInput>;
};

export type QueryCustomersDashboardArgs = {
  filter?: InputMaybe<CustomerDashboardFilterInput>;
};

export type QueryDeliveryTimeSlotsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DeliveryTimeSlotFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DeliverySortingInput>;
};

export type QueryDepressionScreeningArgs = {
  input: DepressionScreeningInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryDiabetesRiskArgs = {
  input: DiabetesRiskInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryDivisionArgs = {
  id: Scalars["ID"];
};

export type QueryDivisionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoctorArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  ssoId?: InputMaybe<Scalars["String"]>;
};

export type QueryDoctorCallsRatingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filterInput?: InputMaybe<DoctorCallsRatingsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoctorNurseAssignmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DoctorNurseAssignmentFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoctorTeamEnrolledMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  doctorId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDoctorVisitHealthProgramTrackingArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DoctorHealthProgramPointsFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoctorVisitRejectionAuditsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DoctorVisitRejectionAuditFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryDoctorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DoctorFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DoctorOrder>;
};

export type QueryDoctorsAvailabilitiesSumArgs = {
  doctors: Array<InputMaybe<Scalars["ID"]>>;
  fromDate: Scalars["Date"];
  toDate: Scalars["Date"];
};

export type QueryDocumentStoreArgs = {
  id: Scalars["ID"];
};

export type QueryDocumentStoresArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryDownloadBulkFileArgs = {
  fileId: Scalars["ID"];
};

export type QueryDownloadCodeSystemFileArgs = {
  code: CodeSystemCode;
  codeSystemId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDownloadSubListArgs = {
  subListId: Scalars["ID"];
};

export type QueryDraftOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<OrderDraftFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<OrderSortingInput>;
};

export type QueryEarlyRefillRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<EarlyRefillRequestFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryFraminghamRiskArgs = {
  input: FraminghamRiskInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGenerateAppointmentChatLinksArgs = {
  id: Scalars["ID"];
};

export type QueryGetAllDiagnosisValidProcedureArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DiagnosisValidProcedureFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DiagnosisValidProcedureSorting>;
};

export type QueryGetAllowedDeliveryTypesArgs = {
  genericDrugs: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetClinicianArgs = {
  nationalId: Scalars["String"];
};

export type QueryGetClinicianCredentialsArgs = {
  destinationType: DestinationType;
  user: Scalars["ID"];
};

export type QueryGetCodeSystemArgs = {
  id: Scalars["ID"];
};

export type QueryGetCodeSystemConceptArgs = {
  id: Scalars["ID"];
};

export type QueryGetCodeSystemConceptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  codeSystemId?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
  showOnlyVisibleProperties?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetCodeSystemConceptsByCodeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
  showOnlyVisibleProperties?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetCodeSystemDefinitionArgs = {
  code: CodeSystemCode;
};

export type QueryGetCodeSystemDefinitionByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetCodeSystemDefinitionsSummeryArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QueryGetCodeSystemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<CodeSystemCode>;
  first?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetDecisionArgs = {
  id: Scalars["ID"];
};

export type QueryGetDecisionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DecisionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<DecisionSortingInput>;
};

export type QueryGetDiagnosisValidProcedureArgs = {
  id: Scalars["ID"];
};

export type QueryGetGenericDrugsArgs = {
  genericCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetHisDoctorAppointmentsArgs = {
  filter: HisAppointmentFilterInput;
};

export type QueryGetHisDoctorAvailableTimeSlotsArgs = {
  filter: HisAppointmentFilterInput;
};

export type QueryGetHealthConditionArgs = {
  id: Scalars["ID"];
};

export type QueryGetHealthConditionDiagnosesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthConditionDiagnosesFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthConditionDiagnosesSortingInput>;
};

export type QueryGetHealthConditionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthConditionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthConditionSortingInput>;
};

export type QueryGetHealthParameterArgs = {
  id: Scalars["ID"];
};

export type QueryGetHealthParameterCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthParameterCategoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetHealthParametersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthParameterFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthParameterSortingInput>;
};

export type QueryGetLabTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryGetLabTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<LabTemplateFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<LabTemplateSortingInput>;
};

export type QueryGetLatestBodyCompositionArgs = {
  patientId?: InputMaybe<Scalars["H_Long"]>;
};

export type QueryGetPaymentArgs = {
  paymentId: Scalars["String"];
};

export type QueryGetPaymentCardArgs = {
  cardId: Scalars["ID"];
};

export type QueryGetPaymentCardsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetPharmacyCredentialsArgs = {
  branch: Scalars["ID"];
  destinationType: DestinationType;
};

export type QueryGetPlanArgs = {
  id: Scalars["ID"];
};

export type QueryGetPlansArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DecisionPlanFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetReferralByIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetRiskFactorTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryGetRiskFactorTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<RiskFactorTemplateFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<RiskFactorTemplateSortingInput>;
};

export type QueryGetSpecialtiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<SpecialityFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetSubListArgs = {
  id: Scalars["ID"];
};

export type QueryGetSubListItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SubListItemSortingInput>;
  subListId: Scalars["ID"];
};

export type QueryGetSubListsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<SublistFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SubListSortingInput>;
};

export type QueryGetTradeDrugQuantityArgs = {
  erxReference: Scalars["String"];
  patientIdentifier: Scalars["String"];
};

export type QueryGetTradeDrugsArgs = {
  tradeCodes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetTransactionArgs = {
  input: PaymentCheckoutInput;
};

export type QueryGetVisitReferralsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<VisitReferralSortingInput>;
  visit: Scalars["ID"];
};

export type QueryGoalWeightArgs = {
  input: GoalWeightInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGroupCandidateUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appType: AppTypes;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<UserSortingInput>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGuidedCareHealthProgramArgs = {
  id: Scalars["ID"];
};

export type QueryGuidedCareHealthProgramActivityArgs = {
  id: Scalars["ID"];
};

export type QueryGuidedCareHealthProgramsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<GuidedCareHealthProgramFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<GuidedCareHealthProgramSortingInput>;
};

export type QueryGuidedCareJourneyArgs = {
  id: Scalars["ID"];
};

export type QueryGuidedCareJourneyItemArgs = {
  id: Scalars["ID"];
};

export type QueryGuidedCareJourneyItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<GuidedCareJourneyItemFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryGuidedCareJourneysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<GuidedCareJourneyFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<GuidedCareJourneySortingInput>;
};

export type QueryGuidedCareTaskArgs = {
  id: Scalars["ID"];
};

export type QueryGuidedCareTasksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<GuidedCareTaskFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<GuidedCareTaskSortingInput>;
};

export type QueryGuidedCareTeamChatGroupsArgs = {
  teamId: Scalars["ID"];
};

export type QueryHealthChannelArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryHealthChannelCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthChannelCategoryArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryHealthChannelFollowersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthChannelFollowerFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthChannelsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthChannelFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramArgs = {
  id: Scalars["ID"];
};

export type QueryHealthProgramBenefitGroupOptionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryHealthProgramBenefitGroupOptionsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};

export type QueryHealthProgramBenefitGroupsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};

export type QueryHealthProgramCallsForVendorArgs = {
  filter?: InputMaybe<CallsForVendorFilter>;
};

export type QueryHealthProgramCallsPerVendorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<CallsPerVendorFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramExclusionOptionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryHealthProgramExclusionOptionsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};

export type QueryHealthProgramExtraBenefitsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};

export type QueryHealthProgramMemberArgs = {
  id: Scalars["ID"];
};

export type QueryHealthProgramMemberListsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramMemberListFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramMemberListSortingInput>;
};

export type QueryHealthProgramMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramMemberFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramMemberSortingInput>;
};

export type QueryHealthProgramMembershipRequestArgs = {
  id: Scalars["ID"];
};

export type QueryHealthProgramMembershipRequestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramMembershipRequestFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramMembershipRequestSortingInput>;
};

export type QueryHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramNetworkProvidersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkProviderFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramNetworksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramNetworkSortingInput>;
};

export type QueryHealthProgramTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryHealthProgramTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramTemplateFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramTemplateSortingInput>;
};

export type QueryHealthProgramTotalPointsReportArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter: HealthProgramPointsFilterInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramUserSubscriptionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramVendorTotalPointsReportArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramVendorPointsFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryHealthProgramsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};

export type QueryHealthSymptomArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryHealthSymptomsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthSymptomFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthSymptomOrder>;
};

export type QueryIdealBodyWeightArgs = {
  input: IdealBodyWeightInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryInsuranceNetworkArgs = {
  id: Scalars["ID"];
};

export type QueryInsuranceNetworkFacilitiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<InsuranceNetworkFacilityFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryInsuranceNetworksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<InsuranceNetworkFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<InsuranceSortingInput>;
};

export type QueryKeyCloakPermissionsArgs = {
  appType: AppTypes;
};

export type QueryLabArgs = {
  id: Scalars["ID"];
};

export type QueryLanguageArgs = {
  id: Scalars["ID"];
};

export type QueryLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<LanguageFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryLatestCodeSystemVersionArgs = {
  codeSystemCode: CodeSystemCode;
};

export type QueryLifeExpectancyArgs = {
  input: LifeExpectancyInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryMarketplaceBrandArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceBrandsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceBrandFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceDefaultSortingInput>;
};

export type QueryMarketplaceCartArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryMarketplaceDiscountArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceDiscountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceDiscountFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceDiscountSortingInput>;
};

export type QueryMarketplaceHealthPackageArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceHealthPackageBestSellersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type QueryMarketplaceHealthPackageCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceCategoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceDefaultSortingInput>;
};

export type QueryMarketplaceHealthPackageCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceHealthPackagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceHealthPackageFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceHealthPackageSortingInput>;
};

export type QueryMarketplaceHealthPackagesForYouArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type QueryMarketplaceOrderArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceOrderFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceOrderSortingInput>;
};

export type QueryMarketplaceProductArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceProductBestSellersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type QueryMarketplaceProductCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceCategoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceDefaultSortingInput>;
};

export type QueryMarketplaceProductCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplaceProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceProductFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceProductSortingInput>;
};

export type QueryMarketplaceProductsForYouArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type QueryMarketplacePromotionArgs = {
  id: Scalars["ID"];
};

export type QueryMarketplacePromotionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplacePromotionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplacePromotionSortingInput>;
};

export type QueryMarketplaceReviewsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceReviewFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryMedicalFormArgs = {
  id: Scalars["ID"];
};

export type QueryMedicalFormAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MedicalFormAnswerFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryMedicalFormsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MedicalFormFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MedicalFormSortingInput>;
};

export type QueryMedicalMessageArgs = {
  id: Scalars["ID"];
};

export type QueryMedicalMessageByCodeArgs = {
  code: Scalars["String"];
};

export type QueryMedicalMessageVariantArgs = {
  id: Scalars["ID"];
};

export type QueryMedicalMessageVariantsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MedicalMessageVariantFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MedicalMessageVariantSortingInput>;
};

export type QueryMedicalMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MedicalMessageFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MedicalMessageSortingInput>;
};

export type QueryMedlistUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<UserSortingInput>;
};

export type QueryOrderArgs = {
  id: Scalars["ID"];
};

export type QueryOrderByTokenArgs = {
  token: Scalars["UUID"];
};

export type QueryOrderRejectionReasonsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<OrderSortingInput>;
};

export type QueryOrdersDashboardArgs = {
  filter?: InputMaybe<OrderDashboardFilterInput>;
};

export type QueryOrdersSocialDashboardArgs = {
  filter?: InputMaybe<OrderDashboardFilterInput>;
};

export type QueryOrdersTotalArgs = {
  period?: InputMaybe<ReportingPeriod>;
};

export type QueryOutPatientJourneyStatusHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<OutPatientJourneyStatusHistoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryOutPatientJourneysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  last?: InputMaybe<Scalars["Int"]>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type QueryPatientArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
};

export type QueryPatientEligibleTeamsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ProviderGuidedCareHealthProgramTeamFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryPatientHealthParameterArgs = {
  id: Scalars["ID"];
};

export type QueryPatientHealthParameterVitalSignsChangePercentageArgs = {
  patientId: Scalars["ID"];
};

export type QueryPatientHealthParametersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PatientHealthParameterFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PatientHealthParameterSortingInput>;
};

export type QueryPatientIdByConsentCodeArgs = {
  code: Scalars["String"];
};

export type QueryPatientMedicalProfileArgs = {
  patientId: Scalars["ID"];
};

export type QueryPatientProfileActiveConsentFormsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryPatientProfileConsentFormArgs = {
  id: Scalars["ID"];
};

export type QueryPatientProfileConsentFormCheckArgs = {
  patientId: Scalars["ID"];
};

export type QueryPatientProfilePendingConsentFormsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryPatientShareArgs = {
  branchId?: InputMaybe<Scalars["ID"]>;
  doctorId: Scalars["ID"];
  visitCareType: VisitCareType;
};

export type QueryPatientShareForAppointmentArgs = {
  appointmentId: Scalars["ID"];
};

export type QueryPatientsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PatientFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryPayerArgs = {
  id: Scalars["ID"];
};

export type QueryPayerBankListArgs = {
  input?: InputMaybe<PayerBankListRequestInput>;
};

export type QueryPayerBenefitListArgs = {
  input?: InputMaybe<PayerBenefitListRequestInput>;
};

export type QueryPayerCityListArgs = {
  input: PayerCityListRequestInput;
};

export type QueryPayerClaimArgs = {
  input: PayerClaimDetailsRequestInput;
};

export type QueryPayerClaimsArgs = {
  input: PayerClaimListRequestInput;
};

export type QueryPayerClinicianDetailsArgs = {
  input?: InputMaybe<PayerClinicianDetailsRequestInput>;
};

export type QueryPayerClinicianListArgs = {
  input?: InputMaybe<PayerClinicianListRequestInput>;
};

export type QueryPayerClinicianSearchArgs = {
  input: PayerClinicianSearchRequestInput;
};

export type QueryPayerCountriesArgs = {
  input?: InputMaybe<PayerCountryRequestInput>;
};

export type QueryPayerCurrenciesArgs = {
  input?: InputMaybe<PayerCurrencyRequestInput>;
};

export type QueryPayerLanguagesArgs = {
  input: PayerLanguageRequestInput;
};

export type QueryPayerMemberBankDetailsArgs = {
  input?: InputMaybe<PayerMemberBankDetailsRequestInput>;
};

export type QueryPayerPreApprovalsArgs = {
  input: PayerPreApprovalListRequestInput;
};

export type QueryPayerProviderDetailsArgs = {
  input?: InputMaybe<PayerProviderDetailsRequestInput>;
};

export type QueryPayerProviderFilterListArgs = {
  input: PayerProviderFilterListRequestInput;
};

export type QueryPayerProviderListArgs = {
  input?: InputMaybe<PayerProviderListRequestInput>;
};

export type QueryPayerProviderNearByListArgs = {
  input: PayerProviderNearByListRequestInput;
};

export type QueryPayerProviderTypeListArgs = {
  input: PayerProviderTypeListRequestInput;
};

export type QueryPayerSpecialtyListArgs = {
  input: PayerSpecialtyListRequestInput;
};

export type QueryPayersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PayerFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PayerOrder>;
};

export type QueryPaymentInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentInvoiceFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryPermissionGroupArgs = {
  id: Scalars["ID"];
};

export type QueryPermissionGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appType: AppTypes;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PermissionGroupFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PermissionGroupSortingInput>;
  vendorId?: InputMaybe<Scalars["ID"]>;
};

export type QueryPlanArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryPlansArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PlanFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PlanOrder>;
};

export type QueryPluginArgs = {
  id: Scalars["ID"];
};

export type QueryPluginsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PluginFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PluginSortingInput>;
};

export type QueryPrescriptionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  patientIdentifier?: InputMaybe<Scalars["String"]>;
  referenceNumber?: InputMaybe<Scalars["String"]>;
};

export type QueryPrescriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PrescriptionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PrescriptionSortingInput>;
};

export type QueryProviderGuidedCareHealthProgramTeamArgs = {
  id: Scalars["ID"];
};

export type QueryProviderGuidedCareHealthProgramTeamsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ProviderGuidedCareHealthProgramTeamFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<ProviderGuidedCareHealthProgramTeamSortingInput>;
};

export type QueryProviderSettingsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ProviderSettingsFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryQuestionArgs = {
  id: Scalars["ID"];
};

export type QueryQuestionAnswersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<QuestionAnswerFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryRejectionReasonsArgs = {
  filter?: InputMaybe<ReasonFilterInput>;
};

export type QuerySleepArgs = {
  input: SleepInput;
  saveData?: InputMaybe<Scalars["Boolean"]>;
};

export type QuerySurveyArgs = {
  id: Scalars["ID"];
};

export type QuerySurveyStatisticsArgs = {
  id: Scalars["ID"];
  publishCriteriaId?: InputMaybe<Scalars["ID"]>;
};

export type QuerySurveysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<SurveyFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SurveySorting>;
};

export type QueryTaskDefinitionArgs = {
  id: Scalars["ID"];
};

export type QueryTaskDefinitionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<TaskDefinitionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<TaskDefinitionSortingInput>;
};

export type QueryTeamEnrolledMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  teamId: Scalars["ID"];
};

export type QueryTeamEnrolledPatientsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  teamId?: InputMaybe<Scalars["ID"]>;
};

export type QueryTeamLeaderDoctorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryTemplateActivityArgs = {
  id: Scalars["ID"];
};

export type QueryTemplateInterventionActivityArgs = {
  id: Scalars["ID"];
};

export type QueryTemplateInterventionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramTemplateInterventionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramTemplateSortingInput>;
};

export type QueryTemplateRecommendationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<TemplateRecommendationFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryUnverifiedNationalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<UnverifiedNationalsFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<UserSortingInput>;
};

export type QueryVendorArgs = {
  id: Scalars["ID"];
};

export type QueryVendorDepartmentArgs = {
  id: Scalars["ID"];
};

export type QueryVendorDepartmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VendorDepartmentFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<VendorDepartmentOrder>;
};

export type QueryVendorSubscriptionArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryVendorSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<SubscriptionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VendorFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<VendorOrder>;
  userLocation?: InputMaybe<LocationInput>;
};

export type QueryVisitArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryVisitComplainsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitComplainFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVisitDiagnosisRiskTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryVisitDiagnosisRiskTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVisitMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitMessageFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVisitPrescriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  visitId: Scalars["ID"];
};

export type QueryVisitReviewQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitReviewQuestionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVisitStatusHistoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitStatusHistoryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVisitSummariesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitSummaryFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryVisitSummaryArgs = {
  id: Scalars["ID"];
};

export type QueryVisitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<VisitSortingInput>;
};

export type Question = {
  __typename?: "Question";
  createdDate?: Maybe<Scalars["Date"]>;
  department?: Maybe<Department>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  images?: Maybe<Array<Maybe<Scalars["String"]>>>;
  medicalForm?: Maybe<MedicalForm>;
  options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  position?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  questionOptionScores?: Maybe<Array<Maybe<QuestionOptionScore>>>;
  questionType?: Maybe<QuestionType>;
  required?: Maybe<Scalars["Boolean"]>;
  updateDate?: Maybe<Scalars["Date"]>;
  vendor?: Maybe<Array<Maybe<Vendor>>>;
};

export type QuestionAnswer = {
  __typename?: "QuestionAnswer";
  booleanAnswer?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  multipleChoiceAnswer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  question?: Maybe<Question>;
  singleChoiceAnswer?: Maybe<Scalars["String"]>;
  stringAnswer?: Maybe<Scalars["String"]>;
};

export type QuestionAnswerCareJourneyExtendedInput = {
  booleanAnswer?: InputMaybe<Scalars["Boolean"]>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  questionId: Scalars["ID"];
  singleChoiceAnswer?: InputMaybe<Scalars["String"]>;
  stringAnswer?: InputMaybe<Scalars["String"]>;
};

export type QuestionAnswerConnection = {
  __typename?: "QuestionAnswerConnection";
  edges?: Maybe<Array<Maybe<QuestionAnswerEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type QuestionAnswerEdge = {
  __typename?: "QuestionAnswerEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<QuestionAnswerExtended>;
};

export type QuestionAnswerExtended = {
  __typename?: "QuestionAnswerExtended";
  booleanAnswer?: Maybe<Scalars["Boolean"]>;
  createdBy?: Maybe<User>;
  guidedCareJourneyItem?: Maybe<GuidedCareJourneyItem>;
  id: Scalars["ID"];
  multipleChoiceAnswer?: Maybe<Array<Maybe<Scalars["String"]>>>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  patient?: Maybe<Patient>;
  question?: Maybe<Question>;
  questionOptionScore?: Maybe<QuestionOptionScore>;
  score?: Maybe<Scalars["Int"]>;
  singleChoiceAnswer?: Maybe<Scalars["String"]>;
  stringAnswer?: Maybe<Scalars["String"]>;
};

export type QuestionAnswerExtendedInput = {
  booleanAnswer?: InputMaybe<Scalars["Boolean"]>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  outPatientJourneyId: Scalars["ID"];
  questionId: Scalars["ID"];
  singleChoiceAnswer?: InputMaybe<Scalars["String"]>;
  stringAnswer?: InputMaybe<Scalars["String"]>;
};

export type QuestionAnswerFilter = {
  guidedCareJourneyItems?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  medicalForms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  outPatientJourneys?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  questions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type QuestionAnswerInput = {
  booleanAnswer?: InputMaybe<Scalars["Boolean"]>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  question: Scalars["ID"];
  singleChoiceAnswer?: InputMaybe<Scalars["String"]>;
  stringAnswer?: InputMaybe<Scalars["String"]>;
};

export type QuestionInput = {
  departmentId?: InputMaybe<Scalars["ID"]>;
  images?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  options?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  position?: InputMaybe<Scalars["Int"]>;
  question: Scalars["String"];
  questionOptionScores?: InputMaybe<Array<QuestionOptionScoreInput>>;
  questionType: QuestionType;
  required: Scalars["Boolean"];
};

export type QuestionOptionScore = {
  __typename?: "QuestionOptionScore";
  id?: Maybe<Scalars["ID"]>;
  option?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
};

export type QuestionOptionScoreInput = {
  option: Scalars["String"];
  score: Scalars["Int"];
};

export enum QuestionType {
  Boolean = "BOOLEAN",
  MultiChoice = "MULTI_CHOICE",
  SingleChoice = "SINGLE_CHOICE",
  String = "STRING",
}

export type Rad = H_Node & {
  __typename?: "Rad";
  code?: Maybe<Scalars["String"]>;
  conceptId?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  visit?: Maybe<Visit>;
};

export enum RatingActionEnum {
  Create = "CREATE",
  Update = "UPDATE",
}

export type RatingAndNumberOfCallsPair = {
  __typename?: "RatingAndNumberOfCallsPair";
  count?: Maybe<Scalars["Int"]>;
  rating?: Maybe<Scalars["Int"]>;
};

export type ReasonFilterInput = {
  actor?: InputMaybe<Actor>;
  affectedType?: InputMaybe<AffectedType>;
};

export type Receptionist = Node & {
  __typename?: "Receptionist";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type ReferralEdge = {
  __typename?: "ReferralEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<PatientReferral>;
};

export enum ReferralErrorCodes {
  ReferralDoctorNotFound = "REFERRAL_DOCTOR_NOT_FOUND",
  ReferralEmptyDoctorLicense = "REFERRAL_EMPTY_DOCTOR_LICENSE",
  ReferralErrorCallingIntegrator = "REFERRAL_ERROR_CALLING_INTEGRATOR",
  ReferralInvalidId = "REFERRAL_INVALID_ID",
  ReferralMakerNoneNegativePageSize = "REFERRAL_MAKER_NONE_NEGATIVE_PAGE_SIZE",
  ReferralMakerUnknown = "REFERRAL_MAKER_UNKNOWN",
  ReferralMakerUnknownType = "REFERRAL_MAKER_UNKNOWN_TYPE",
  ReferralMemberIdIsNull = "REFERRAL_MEMBER_ID_IS_NULL",
  ReferralMemberNotActive = "REFERRAL_MEMBER_NOT_ACTIVE",
  ReferralNotFound = "REFERRAL_NOT_FOUND",
  ReferralNoPrincipalDiagnosis = "REFERRAL_NO_PRINCIPAL_DIAGNOSIS",
  ReferralOnlyOnePrincipalDiagnosisAllowed = "REFERRAL_ONLY_ONE_PRINCIPAL_DIAGNOSIS_ALLOWED",
  ReferralOperationNotAllowed = "REFERRAL_OPERATION_NOT_ALLOWED",
  ReferralPatientNotEligible = "REFERRAL_PATIENT_NOT_ELIGIBLE",
  ReferralPatientNotFound = "REFERRAL_PATIENT_NOT_FOUND",
  ReferralPayerLicenseNotDefined = "REFERRAL_PAYER_LICENSE_NOT_DEFINED",
  ReferralPermissionDenied = "REFERRAL_PERMISSION_DENIED",
  ReferralUserIdNotDefined = "REFERRAL_USER_ID_NOT_DEFINED",
}

export type ReferralGraphqlError = {
  __typename?: "ReferralGraphqlError";
  code?: Maybe<ReferralErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum ReferralOrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type ReferralRequestInput = {
  diagnosis: Array<InputMaybe<DiagnosisInput>>;
  observation: Scalars["String"];
  previousReferralReferenceID?: InputMaybe<Scalars["String"]>;
  specialty: Scalars["String"];
  visitId: Scalars["ID"];
};

export type RefillError = {
  __typename?: "RefillError";
  code: RefillErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum RefillErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type RefundPaymentFromWalletInput = {
  /**
   * the amount value is only effective for the case of partial (not full) refund,
   * and the amount value should be within the available value for refund
   */
  amount: Scalars["Float"];
  paymentOperationLogId: Scalars["ID"];
};

export enum RefundStatus {
  Handled = "HANDLED",
  Requested = "REQUESTED",
}

export enum RefundTarget {
  PaymentGateway = "PAYMENT_GATEWAY",
  Wallet = "WALLET",
}

export type RejectVendor = {
  __typename?: "RejectVendor";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  rejectErrors: Array<VendorError>;
  vendorRejectionReason?: Maybe<VendorRejectionReason>;
};

export type RejectionReason = {
  __typename?: "RejectionReason";
  code: Scalars["String"];
  display: Scalars["String"];
  id: Scalars["ID"];
};

/** CRUD a new rejection reason. */
export type RejectionReasonCrud = {
  __typename?: "RejectionReasonCRUD";
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  rejectionReason?: Maybe<H_RejectionReason>;
};

export type RejectionReasonInput = {
  actor: Actor;
  affectedType: AffectedType;
  /** Rejection Reason */
  text: Scalars["String"];
  text_ar: Scalars["String"];
};

export enum RelationTypes {
  Brother = "BROTHER",
  Daughter = "DAUGHTER",
  Father = "FATHER",
  Grandfather = "GRANDFATHER",
  Grandmother = "GRANDMOTHER",
  Husband = "HUSBAND",
  Mother = "MOTHER",
  Other = "OTHER",
  Sister = "SISTER",
  Son = "SON",
  Wife = "WIFE",
}

export type RemoveVendorFromFavorite = {
  __typename?: "RemoveVendorFromFavorite";
  customerFavoriteVendor?: Maybe<CustomerFavoriteVendor>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export enum ReportingPeriod {
  Last_7Days = "LAST_7_DAYS",
  ThisMonth = "THIS_MONTH",
  Today = "TODAY",
}

export type RequestOtpForDeleteAccount = {
  __typename?: "RequestOTPForDeleteAccount";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars["String"]>;
};

export type RequestPasswordReset = {
  __typename?: "RequestPasswordReset";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars["String"]>;
};

export enum RequestStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum RequestedBy {
  Patient = "PATIENT",
  ProviderGuidedCareHealthProgramTeam = "PROVIDER_GUIDED_CARE_HEALTH_PROGRAM_TEAM",
}

export type RequestedGenericDrugQuantity = {
  code?: InputMaybe<Scalars["String"]>;
  quantity?: InputMaybe<Scalars["Float"]>;
};

export enum ResidencyTypesEnum {
  Residence = "RESIDENCE",
  Visitor = "VISITOR",
}

export type ResubmitOrderGetErxHubApproval = {
  __typename?: "ResubmitOrderGetErxHubApproval";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type ReviewQuestion = H_Node & {
  __typename?: "ReviewQuestion";
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  question?: Maybe<Scalars["String"]>;
  valueType?: Maybe<ReviewQuestionValueType>;
  visitsReviewQuestions?: Maybe<VisitReviewQuestionCountableConnection>;
};

export type ReviewQuestionVisitsReviewQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export enum ReviewQuestionValueType {
  Boolean = "BOOLEAN",
  StarRating = "STAR_RATING",
}

export type ReviewVendor = {
  __typename?: "ReviewVendor";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorReview?: Maybe<VendorReview>;
};

/** Review an Visit */
export type ReviewVisit = {
  __typename?: "ReviewVisit";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  result?: Maybe<Scalars["String"]>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type RiskFactorTemplate = {
  __typename?: "RiskFactorTemplate";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  fields?: Maybe<Array<Maybe<HealthParameter>>>;
  id?: Maybe<Scalars["ID"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  plan?: Maybe<DecisionPlan>;
};

export type RiskFactorTemplateConnection = {
  __typename?: "RiskFactorTemplateConnection";
  edges?: Maybe<Array<Maybe<RiskFactorTemplateEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type RiskFactorTemplateEdge = {
  __typename?: "RiskFactorTemplateEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<RiskFactorTemplate>;
};

export type RiskFactorTemplateFilterInput = {
  arabicDisplay?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  display?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
};

export type RiskFactorTemplateInput = {
  arabicDisplay: Scalars["String"];
  code: Scalars["String"];
  display: Scalars["String"];
  isActive: Scalars["Boolean"];
};

export enum RiskFactorTemplateSortingField {
  ArabicDisplay = "ARABIC_DISPLAY",
  Code = "CODE",
  CreatedDate = "CREATED_DATE",
  Display = "DISPLAY",
  IsActive = "IS_ACTIVE",
}

export type RiskFactorTemplateSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: RiskFactorTemplateSortingField;
};

export type RiskFactorTemplateUpdateInput = {
  arabicDisplay: Scalars["String"];
  display: Scalars["String"];
  isActive: Scalars["Boolean"];
};

export enum RiskLevel {
  High = "HIGH",
  Low = "LOW",
}

export type RiskTemplateResultInput = {
  id: Scalars["ID"];
  result: Scalars["String"];
};

export type RoundInput = {
  maxNumberOfPharmacies: Scalars["Int"];
  pharmaciesTypes: Array<VendorBranchTypeEnum>;
  radius: Scalars["Decimal"];
};

export type S_DateTimeRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars["LocalDateTime"]>;
  /** End date. */
  lte?: InputMaybe<Scalars["LocalDateTime"]>;
};

export type SearchParameter = {
  code?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Type>;
  valueBoolean?: InputMaybe<Scalars["Boolean"]>;
  valueDate?: InputMaybe<Scalars["Date"]>;
  valueFloat?: InputMaybe<Scalars["Float"]>;
  valueInteger?: InputMaybe<Scalars["Int"]>;
  valueString?: InputMaybe<Scalars["String"]>;
};

export type SendOrderSubscriptionEvent = {
  __typename?: "SendOrderSubscriptionEvent";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  orderErrors: Array<OrderError>;
  success?: Maybe<Scalars["Boolean"]>;
};

/** Creates a new visit message. */
export type SendVisitMessage = {
  __typename?: "SendVisitMessage";
  chatErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitMessage?: Maybe<VisitMessage>;
};

export enum ServiceDescription {
  LabTests = "LAB_TESTS",
  RadTests = "RAD_TESTS",
}

export type ServiceDetails = {
  __typename?: "ServiceDetails";
  id?: Maybe<Scalars["ID"]>;
  serviceCode?: Maybe<Scalars["String"]>;
  serviceName?: Maybe<Scalars["String"]>;
  standard?: Maybe<Scalars["String"]>;
};

export type ServiceDetailsInput = {
  appointment?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  serviceCode: Scalars["String"];
  serviceName?: InputMaybe<Scalars["String"]>;
  standard: Scalars["String"];
};

export type ServiceOfGuidedCareHealthProgram = H_Node & {
  __typename?: "ServiceOfGuidedCareHealthProgram";
  frequencyType?: Maybe<AppointmentFrequencyType>;
  id: Scalars["ID"];
  minimumNumberOfCoverages?: Maybe<Scalars["Int"]>;
  serviceDescription?: Maybe<ServiceDescription>;
  serviceOfGuidedCareHealthProgramDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateService?: Maybe<ServiceOfHealthProgramTemplate>;
};

export type ServiceOfGuidedCareHealthProgramInput = {
  frequencyType: AppointmentFrequencyType;
  id?: InputMaybe<Scalars["ID"]>;
  minimumNumberOfCoverages: Scalars["Int"];
  serviceDescription?: InputMaybe<ServiceDescription>;
  serviceOfGuidedCareHealthProgramDetails: Array<InputMaybe<ServiceDetailsInput>>;
  templateService?: InputMaybe<ServiceOfHealthProgramTemplateInput>;
};

export type ServiceOfHealthProgramTemplate = H_Node & {
  __typename?: "ServiceOfHealthProgramTemplate";
  frequencyType?: Maybe<AppointmentFrequencyType>;
  id: Scalars["ID"];
  minimumNumberOfCoverages?: Maybe<Scalars["Int"]>;
  serviceDescription?: Maybe<ServiceDescription>;
  serviceOfHealthProgramTemplateDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
};

export type ServiceOfHealthProgramTemplateInput = {
  frequencyType: AppointmentFrequencyType;
  id?: InputMaybe<Scalars["ID"]>;
  minimumNumberOfCoverages: Scalars["Int"];
  serviceDescription?: InputMaybe<ServiceDescription>;
  serviceOfHealthProgramTemplateDetails: Array<InputMaybe<ServiceDetailsInput>>;
};

export type SetOrderToDelivered = {
  __typename?: "SetOrderToDelivered";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type SetOrderToOutForDelivery = {
  __typename?: "SetOrderToOutForDelivery";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type SetPassword = {
  __typename?: "SetPassword";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars["Boolean"]>;
};

export enum SiteErrorCode {
  ForbiddenCharacter = "FORBIDDEN_CHARACTER",
  GraphqlError = "GRAPHQL_ERROR",
}

export type SiteSettings = H_Node &
  Node & {
    __typename?: "SiteSettings";
    /**
     * allowed time in seconds to make a new call after the essential latest call was made
     * , essential call means the first call that was requested by patient and perhaps the call was timedout/rejoined/cancelled
     * and then the final status of it can be
     * (INCOMPLETED, COMPLETED, CANCELED, CANCELED_BY_CONSUMER, ENDED_BY_ONE_OF_THE_PARTICIPANTS)
     */
    allowedTimeInSecondsToMakeNewCall?: Maybe<Scalars["Int"]>;
    androidPackageName?: Maybe<Scalars["String"]>;
    androidVersion?: Maybe<Scalars["Float"]>;
    appointmentReminderSettingsVariablesName?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** time in seconds before the appointment system will send reminder notification */
    appointmentReminderTimeSeconds?: Maybe<Scalars["Int"]>;
    /** time in seconds for the call join */
    callJoinTimeoutSeconds?: Maybe<Scalars["Int"]>;
    /** time in seconds for the call rejoin */
    callRejoinTimeoutSeconds?: Maybe<Scalars["Int"]>;
    countries: Array<CountryDisplay>;
    currencies: Array<Maybe<Scalars["String"]>>;
    defaultCurrency: Scalars["String"];
    /** time in seconds out after which the visit will be automatically rejected */
    doctorVisitAcceptanceTimeoutSeconds?: Maybe<Scalars["Int"]>;
    /** time in seconds before the appointment system will send the first reminder notification for patient to schedule guided care appointment */
    guidedCareAppointmentBookingFirstReminderSeconds?: Maybe<Scalars["Int"]>;
    /** guided care appointment reminder care team admin message */
    guidedCareAppointmentBookingReminderCareTeamAdminMessage?: Maybe<Scalars["String"]>;
    /** guided care appointment reminder care team admin message arabic */
    guidedCareAppointmentBookingReminderCareTeamAdminMessageAr?: Maybe<Scalars["String"]>;
    /** guided care appointment reminder doctor message */
    guidedCareAppointmentBookingReminderDoctorMessage?: Maybe<Scalars["String"]>;
    /** guided care appointment reminder doctor message arabic */
    guidedCareAppointmentBookingReminderDoctorMessageAr?: Maybe<Scalars["String"]>;
    /** guided care appointment reminder patient message */
    guidedCareAppointmentBookingReminderPatientMessage?: Maybe<Scalars["String"]>;
    /** guided care appointment reminder patient message arabic */
    guidedCareAppointmentBookingReminderPatientMessageAr?: Maybe<Scalars["String"]>;
    /** time in seconds before the appointment system will send the second reminder notification for patient to schedule guided care appointment */
    guidedCareAppointmentBookingSecondReminderSeconds?: Maybe<Scalars["Int"]>;
    /** time in seconds before the appointment system will send the third reminder notification for patient to schedule guided care appointment */
    guidedCareAppointmentBookingThirdReminderSeconds?: Maybe<Scalars["Int"]>;
    /** health program visit wait time in seconds before call */
    healthProgramVisitWaitTimeBeforeCallSeconds?: Maybe<Scalars["Int"]>;
    id: Scalars["ID"];
    iosAppId?: Maybe<Scalars["String"]>;
    iosVersion?: Maybe<Scalars["Float"]>;
    isPaymentModeTest?: Maybe<Scalars["Boolean"]>;
    languages: Array<Maybe<LanguageDisplay>>;
    marketPlacePharmacyFirst?: Maybe<Scalars["Boolean"]>;
    /** minimum call duration in seconds to consider call as complete */
    messengerCallMinimumDurationSeconds?: Maybe<Scalars["Int"]>;
    multiplePharmaciesDelivery?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    orderAcceptanceTimeout?: Maybe<Scalars["Int"]>;
    orderBaseDeliveryFee?: Maybe<Scalars["Float"]>;
    orderDeliveryTimeout?: Maybe<Scalars["Int"]>;
    orderDispensingTimeout?: Maybe<Scalars["Int"]>;
    orderDispensingTimeoutAlert?: Maybe<Scalars["Int"]>;
    orderErxHubApprovalResubmitTimeout?: Maybe<Scalars["Int"]>;
    orderErxHubApprovalTimeout?: Maybe<Scalars["Int"]>;
    orderErxHubApprovalTimeoutAlert?: Maybe<Scalars["Int"]>;
    paymentKafkaTopics?: Maybe<Array<Maybe<P_SubscriptionKafkaTopic>>>;
    phonePrefixes: Array<Maybe<Scalars["String"]>>;
    prescriptionOrderPaymentTimeoutSeconds?: Maybe<Scalars["Int"]>;
    /** time in minutes before the visit considered running late */
    runningLateTimeoutMinutes?: Maybe<Scalars["Int"]>;
    subscriptionKafkaTopics?: Maybe<Array<Maybe<SubscriptionKafkaTopic>>>;
    termsAndConditions?: Maybe<Scalars["String"]>;
    termsAndConditionsAr?: Maybe<Scalars["String"]>;
    timeZone?: Maybe<Scalars["Float"]>;
    vatPercentage?: Maybe<Scalars["Float"]>;
    /**
     * VAT Percentage Value For Medical Services Including Only [Appointment, Visit,
     * Out Patient Journey or Patient Check in Journey]
     */
    vatPercentageForMedicalServices?: Maybe<Scalars["Float"]>;
  };

export type SiteSettingsCountriesArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type SiteSettingsError = {
  __typename?: "SiteSettingsError";
  code: SiteSettingsErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum SiteSettingsErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  CannotFetchTaxRates = "CANNOT_FETCH_TAX_RATES",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type SiteSettingsInput = {
  androidPackageName?: InputMaybe<Scalars["String"]>;
  androidVersion?: InputMaybe<Scalars["Float"]>;
  iosAppId?: InputMaybe<Scalars["String"]>;
  iosVersion?: InputMaybe<Scalars["Float"]>;
  marketPlacePharmacyFirst?: InputMaybe<Scalars["Boolean"]>;
  multiplePharmaciesDelivery?: InputMaybe<Scalars["Boolean"]>;
  orderAcceptanceTimeout?: InputMaybe<Scalars["Int"]>;
  orderBaseDeliveryFee?: InputMaybe<Scalars["Decimal"]>;
  orderDeliveryTimeout?: InputMaybe<Scalars["Int"]>;
  orderDispensingTimeout?: InputMaybe<Scalars["Int"]>;
  orderDispensingTimeoutAlert?: InputMaybe<Scalars["Int"]>;
  orderErxHubApprovalResubmitTimeout?: InputMaybe<Scalars["Int"]>;
  orderErxHubApprovalTimeout?: InputMaybe<Scalars["Int"]>;
  orderErxHubApprovalTimeoutAlert?: InputMaybe<Scalars["Int"]>;
  prescriptionOrderPaymentTimeoutSeconds?: InputMaybe<Scalars["Int"]>;
  vatPercentage?: InputMaybe<Scalars["Float"]>;
};

export type SiteSettingsUpdate = {
  __typename?: "SiteSettingsUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  siteSettings?: Maybe<SiteSettings>;
  siteSettingsErrors: Array<SiteSettingsError>;
};

export type SleepInput = {
  age: Scalars["Int"];
  basedOnSleepTime: Scalars["Boolean"];
  time: Scalars["LocalTime"];
};

export type SleepInputLog = {
  __typename?: "SleepInputLog";
  age: Scalars["Int"];
  basedOnSleepTime: Scalars["Boolean"];
  time: Scalars["LocalTime"];
};

export type SleepResponse = {
  __typename?: "SleepResponse";
  sleepCycles?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["LocalTime"]>;
};

export type SleepResult = {
  __typename?: "SleepResult";
  createdDate?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["ID"]>;
  queryInput?: Maybe<SleepInputLog>;
  sleepCycles?: Maybe<Scalars["Int"]>;
  time?: Maybe<Scalars["LocalTime"]>;
};

export type SleepResultConnection = {
  __typename?: "SleepResultConnection";
  edges: Array<SleepResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SleepResultEdge = {
  __typename?: "SleepResultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: SleepResult;
};

export enum SmokingHabit {
  FormerSmoker = "FORMER_SMOKER",
  HeavySmoker = "HEAVY_SMOKER",
  LightSmoker = "LIGHT_SMOKER",
  Never = "NEVER",
}

export type SocialWorker = Node & {
  __typename?: "SocialWorker";
  created: Scalars["DateTime"];
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  user?: Maybe<User>;
};

export type SocialWorkerInput = {
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export enum Source {
  Doctor = "DOCTOR",
  Iot = "IOT",
  Nurse = "NURSE",
  Patient = "PATIENT",
}

export type Speciality = {
  __typename?: "Speciality";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code: Scalars["String"];
  display: Scalars["String"];
};

export type SpecialityConnection = {
  __typename?: "SpecialityConnection";
  edges?: Maybe<Array<Maybe<SpecialityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SpecialityEdge = {
  __typename?: "SpecialityEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Speciality>;
};

export type SpecialityFilterInput = {
  codes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  display?: InputMaybe<Scalars["String"]>;
};

export type SpecializationError = {
  __typename?: "SpecializationError";
  code: SpecializationErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum SpecializationErrorCode {
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type SubList = {
  __typename?: "SubList";
  display?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars["ID"]>;
};

export type SubListConnection = {
  __typename?: "SubListConnection";
  edges?: Maybe<Array<Maybe<SubListEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SubListEdge = {
  __typename?: "SubListEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<SubList>;
};

export type SubListInput = {
  display?: InputMaybe<Scalars["String"]>;
  field?: InputMaybe<FieldInput>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type SubListItem = {
  __typename?: "SubListItem";
  code: Scalars["String"];
  display: Scalars["String"];
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
};

export type SubListItemConnection = {
  __typename?: "SubListItemConnection";
  edges?: Maybe<Array<Maybe<SubListItemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type SubListItemEdge = {
  __typename?: "SubListItemEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<SubListItem>;
};

export type SubListItemInput = {
  code: Scalars["String"];
  display: Scalars["String"];
  id?: InputMaybe<Scalars["ID"]>;
};

export enum SubListItemSortField {
  Code = "CODE",
  Display = "DISPLAY",
}

export type SubListItemSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: SubListItemSortField;
};

export enum SubListSortField {
  Display = "DISPLAY",
  Field = "FIELD",
}

export type SubListSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: SubListSortField;
};

export type SublistFilterInput = {
  display?: InputMaybe<Scalars["String"]>;
  field?: InputMaybe<Scalars["ID"]>;
};

export type SubscribedUser = {
  __typename?: "SubscribedUser";
  errors?: Maybe<Array<Maybe<SubscriptionGraphqlError>>>;
  firebaseToken?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  doctorAvailability?: Maybe<DoctorAvailabilityStatusChange>;
  messageSent?: Maybe<MessageSent>;
  needActionCodeSystems?: Maybe<NeedActionCodeSystems>;
};

export type SubscriptionNeedActionCodeSystemsArgs = {
  token: Scalars["String"];
};

/**
 * for refund to wallet : changes will be as following, values may differ for (refundAmount, walletBalance)
 *
 * [
 *     {
 *         "field": "refundStatus",
 *         "value": "HANDLED"
 *     }, {
 *         "field": "refundTarget",
 *         "value": "WALLET"
 *     }, {
 *         "field": "refundAmount",
 *         "value": "50.11"
 *     }, {
 *         "field": "walletBalance",
 *         "value": "60.22"
 *     }
 * ]
 */
export type SubscriptionEntityChanged = H_Node & {
  __typename?: "SubscriptionEntityChanged";
  changes?: Maybe<Array<Maybe<SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars["Instant"]>;
  graphqlType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  users?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type SubscriptionEntityChangedForAppointmentNotification = H_Node & {
  __typename?: "SubscriptionEntityChangedForAppointmentNotification";
  arabicText?: Maybe<Scalars["String"]>;
  changes?: Maybe<Array<Maybe<SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars["Instant"]>;
  englishText?: Maybe<Scalars["String"]>;
  graphqlType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  notificationType?: Maybe<AppointmentNotificationType>;
  users?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type SubscriptionEntityFieldChanges = {
  __typename?: "SubscriptionEntityFieldChanges";
  field?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SubscriptionError = {
  __typename?: "SubscriptionError";
  code: SubscriptionErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum SubscriptionErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export enum SubscriptionErrorCodes {
  SubscriptionInvalidId = "SUBSCRIPTION_INVALID_ID",
  SubscriptionMakerUnknownType = "SUBSCRIPTION_MAKER_UNKNOWN_TYPE",
  SubscriptionNoneNegativePageSize = "SUBSCRIPTION_NONE_NEGATIVE_PAGE_SIZE",
  SubscriptionNotFound = "SUBSCRIPTION_NOT_FOUND",
  SubscriptionPermissionDenied = "SUBSCRIPTION_PERMISSION_DENIED",
  SubscriptionUnknown = "SUBSCRIPTION_UNKNOWN",
  SubscriptionValidation = "SUBSCRIPTION_VALIDATION",
}

export type SubscriptionFilterInput = {
  validFrom?: InputMaybe<Scalars["DateTime"]>;
  validTill?: InputMaybe<Scalars["DateTime"]>;
};

export type SubscriptionGraphqlError = {
  __typename?: "SubscriptionGraphqlError";
  code?: Maybe<SubscriptionErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type SubscriptionInput = {
  fixedCostAmount?: InputMaybe<Scalars["Decimal"]>;
  fixedOrderCostAmount?: InputMaybe<Scalars["Decimal"]>;
  fixedOrderPercentage?: InputMaybe<Scalars["Decimal"]>;
  plan?: InputMaybe<Scalars["ID"]>;
  vendor?: InputMaybe<Scalars["ID"]>;
};

export type SubscriptionKafkaTopic = {
  __typename?: "SubscriptionKafkaTopic";
  graphqlType?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
};

export type Survey = {
  __typename?: "Survey";
  answeredParticipantsCount?: Maybe<Scalars["Int"]>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  label?: Maybe<Scalars["String"]>;
  notification?: Maybe<Scalars["String"]>;
  publishCriteria?: Maybe<Array<Maybe<PublishCriteria>>>;
  questions?: Maybe<Array<Question>>;
  status?: Maybe<SurveyStatus>;
  updateDate?: Maybe<Scalars["Date"]>;
};

export type SurveyAnswerInput = {
  answers?: InputMaybe<Array<InputMaybe<QuestionAnswerInput>>>;
  survey: Scalars["ID"];
};

export type SurveyConnection = {
  __typename?: "SurveyConnection";
  edges?: Maybe<Array<Maybe<SurveyEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SurveyEdge = {
  __typename?: "SurveyEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<Survey>;
};

export enum SurveyErrorCodes {
  SurveyDuplicate = "SURVEY_DUPLICATE",
  SurveyInvalidDateFormat = "SURVEY_INVALID_DATE_FORMAT",
  SurveyMakerUnknownType = "SURVEY_MAKER_UNKNOWN_TYPE",
  SurveyNoneNegativePageSize = "SURVEY_NONE_NEGATIVE_PAGE_SIZE",
  SurveyNotFound = "SURVEY_NOT_FOUND",
  SurveyPermissionDenied = "SURVEY_PERMISSION_DENIED",
  SurveyUnknown = "SURVEY_UNKNOWN",
  SurveyValidation = "SURVEY_VALIDATION",
}

export type SurveyFilter = {
  label?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<SurveyStatus>;
};

export enum SurveyGender {
  Female = "Female",
  Male = "Male",
}

export type SurveyGraphqlError = {
  __typename?: "SurveyGraphqlError";
  code?: Maybe<SurveyErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type SurveyInput = {
  description: Scalars["String"];
  label: Scalars["String"];
  notification: Scalars["String"];
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
};

export type SurveySorting = {
  direction: SortDirection;
  field: SurveySortingField;
};

export enum SurveySortingField {
  CreationDate = "CREATION_DATE",
  Status = "STATUS",
  UpdateDate = "UPDATE_DATE",
}

export type SurveyStatistics = {
  __typename?: "SurveyStatistics";
  questionsStatistics?: Maybe<Array<Maybe<QuestionsStatistics>>>;
  survey?: Maybe<Survey>;
};

export enum SurveyStatus {
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
  Expired = "EXPIRED",
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
}

export type SurveyUserAnswer = {
  __typename?: "SurveyUserAnswer";
  createdDate?: Maybe<Scalars["Date"]>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id: Scalars["ID"];
  questionAnswers?: Maybe<Array<Maybe<QuestionAnswer>>>;
  user?: Maybe<User>;
};

export enum Tpo {
  Eclaim = "ECLAIM",
  Io = "IO",
}

export type T_Specialization = {
  __typename?: "T_Specialization";
  arabicDisplay?: Maybe<Scalars["String"]>;
  code: Scalars["String"];
  display?: Maybe<Scalars["String"]>;
};

export type TaskDefinition = H_Node & {
  __typename?: "TaskDefinition";
  action?: Maybe<TaskDefinitionAction>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  displayAr?: Maybe<Scalars["String"]>;
  displayEn?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  medicalMessage?: Maybe<MedicalMessage>;
  notificationMessageAr?: Maybe<Scalars["String"]>;
  notificationMessageEn?: Maybe<Scalars["String"]>;
  notificationTitleAr?: Maybe<Scalars["String"]>;
  notificationTitleEn?: Maybe<Scalars["String"]>;
  offsetAfter?: Maybe<Scalars["Int"]>;
  offsetBefore?: Maybe<Scalars["Int"]>;
  priority?: Maybe<TaskDefinitionPriority>;
  remindingFrequencyInDays?: Maybe<Scalars["Int"]>;
  status?: Maybe<TaskDefinitionStatus>;
  taskWorkflowType?: Maybe<TaskWorkflowType>;
};

export enum TaskDefinitionAction {
  SendMedicalMessage = "SEND_MEDICAL_MESSAGE",
  SendNotification = "SEND_NOTIFICATION",
}

/** CRUD a new Task Definition */
export type TaskDefinitionCrud = {
  __typename?: "TaskDefinitionCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  taskDefinition?: Maybe<TaskDefinition>;
  taskDefinitionErrors?: Maybe<Array<H_EntityError>>;
};

export type TaskDefinitionCountableConnection = {
  __typename?: "TaskDefinitionCountableConnection";
  edges: Array<TaskDefinitionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type TaskDefinitionCountableEdge = {
  __typename?: "TaskDefinitionCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: TaskDefinition;
};

export type TaskDefinitionFilterInput = {
  actions?: InputMaybe<Array<InputMaybe<TaskDefinitionAction>>>;
  code?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  priorities?: InputMaybe<Array<InputMaybe<TaskDefinitionPriority>>>;
  statuses?: InputMaybe<Array<InputMaybe<TaskDefinitionStatus>>>;
};

export type TaskDefinitionInnerInput = {
  id: Scalars["ID"];
};

export type TaskDefinitionInput = {
  action: TaskDefinitionAction;
  code: Scalars["String"];
  description: Scalars["String"];
  displayAr: Scalars["String"];
  displayEn: Scalars["String"];
  medicalMessage?: InputMaybe<MedicalMessageInput>;
  notificationMessageAr: Scalars["String"];
  notificationMessageEn: Scalars["String"];
  notificationTitleAr: Scalars["String"];
  notificationTitleEn: Scalars["String"];
  offsetAfter: Scalars["Int"];
  offsetBefore: Scalars["Int"];
  priority: TaskDefinitionPriority;
  remindingFrequencyInDays: Scalars["Int"];
  status: TaskDefinitionStatus;
};

export enum TaskDefinitionPriority {
  Low = "LOW",
  Moderate = "MODERATE",
  Urgent = "URGENT",
}

export enum TaskDefinitionSortingField {
  Created = "CREATED",
  Priority = "PRIORITY",
}

export type TaskDefinitionSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<TaskDefinitionSortingField>;
};

export enum TaskDefinitionStatus {
  Cancelled = "CANCELLED",
  Done = "DONE",
  Idle = "IDLE",
  InProgress = "IN_PROGRESS",
  Missed = "MISSED",
  Pending = "PENDING",
}

export type TaskDefinitionUpdateInput = {
  action?: InputMaybe<TaskDefinitionAction>;
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  displayAr?: InputMaybe<Scalars["String"]>;
  displayEn?: InputMaybe<Scalars["String"]>;
  medicalMessage?: InputMaybe<MedicalMessageInput>;
  notificationMessageAr?: InputMaybe<Scalars["String"]>;
  notificationMessageEn?: InputMaybe<Scalars["String"]>;
  notificationTitleAr?: InputMaybe<Scalars["String"]>;
  notificationTitleEn?: InputMaybe<Scalars["String"]>;
  offsetAfter?: InputMaybe<Scalars["Int"]>;
  offsetBefore?: InputMaybe<Scalars["Int"]>;
  priority?: InputMaybe<TaskDefinitionPriority>;
  remindingFrequencyInDays?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<TaskDefinitionStatus>;
};

export enum TaskWorkflowType {
  ConfirmAppointmentDate = "CONFIRM_APPOINTMENT_DATE",
  ReviewMedicalFormAnswer = "REVIEW_MEDICAL_FORM_ANSWER",
  SubmitMedicalFormAnswers = "SUBMIT_MEDICAL_FORM_ANSWERS",
}

export type TaxType = {
  __typename?: "TaxType";
  description?: Maybe<Scalars["String"]>;
  taxCode?: Maybe<Scalars["String"]>;
};

export type TaxedMoney = {
  __typename?: "TaxedMoney";
  currency: Scalars["String"];
  gross: Money;
  net: Money;
  tax: Money;
};

export enum TeamMemberPosition {
  DentalHygienist = "DENTAL_HYGIENIST",
  DiabetesEducator = "DIABETES_EDUCATOR",
  FitnessCoach = "FITNESS_COACH",
  Nurse = "NURSE",
  Nutritionist = "NUTRITIONIST",
  Optometrist = "OPTOMETRIST",
  Physician = "PHYSICIAN",
  PodiatricMedicalAssistant = "PODIATRIC_MEDICAL_ASSISTANT",
  Psychologist = "PSYCHOLOGIST",
  SocialWorker = "SOCIAL_WORKER",
  TeamAdmin = "TEAM_ADMIN",
  TeamLeader = "TEAM_LEADER",
}

export type TemplateActivity = H_Node & {
  __typename?: "TemplateActivity";
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  frequencyType: AppointmentFrequencyType;
  id: Scalars["ID"];
  medicalForm?: Maybe<MedicalForm>;
  medicalFormClassificationToInterventionLinkList?: Maybe<Array<Maybe<MedicalFormClassificationToInterventionLink>>>;
  medicalMessage?: Maybe<MedicalMessage>;
  numberOfOccurrences?: Maybe<Scalars["Int"]>;
  serviceOfHealthProgramTemplateDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateActivityEventItems?: Maybe<Array<Maybe<TemplateActivityEventItem>>>;
  templateActivityType?: Maybe<GuidedCareActivityType>;
  templateTeamMember?: Maybe<TemplateTeamMember>;
};

export type TemplateActivityCrud = {
  __typename?: "TemplateActivityCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  templateActivity?: Maybe<TemplateActivity>;
  templateActivityErrors?: Maybe<Array<H_EntityError>>;
};

export type TemplateActivityEventItem = H_Node & {
  __typename?: "TemplateActivityEventItem";
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  offsetInDays?: Maybe<Scalars["Int"]>;
  templateActivity?: Maybe<TemplateActivity>;
};

export type TemplateActivityEventItemCountableConnection = {
  __typename?: "TemplateActivityEventItemCountableConnection";
  edges: Array<TemplateActivityEventItemCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type TemplateActivityEventItemCountableEdge = {
  __typename?: "TemplateActivityEventItemCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: TemplateActivityEventItem;
};

export type TemplateActivityEventItemFilterInput = {
  activityIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  offsetEquals?: InputMaybe<Scalars["Int"]>;
  offsetGreaterThanOrEquals?: InputMaybe<Scalars["Int"]>;
  offsetLessThanOrEquals?: InputMaybe<Scalars["Int"]>;
  position?: InputMaybe<TeamMemberPosition>;
  templateActivityType?: InputMaybe<Array<InputMaybe<GuidedCareActivityType>>>;
  templateId: Scalars["ID"];
};

export type TemplateActivityEventItemInput = {
  afterCallMessageId?: InputMaybe<Scalars["ID"]>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  beforeCallMessageId?: InputMaybe<Scalars["ID"]>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  offsetInDays?: InputMaybe<Scalars["Int"]>;
  templateActivity?: InputMaybe<TemplateActivityInputThin>;
};

export enum TemplateActivityEventItemSortingField {
  Offset = "OFFSET",
}

export type TemplateActivityEventItemSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: TemplateActivityEventItemSortingField;
};

export type TemplateActivityInput = {
  afterCallMessageId?: InputMaybe<Scalars["ID"]>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  beforeCallMessageId?: InputMaybe<Scalars["ID"]>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  frequencyType?: InputMaybe<AppointmentFrequencyType>;
  id?: InputMaybe<Scalars["ID"]>;
  isModified?: InputMaybe<Scalars["Boolean"]>;
  medicalFormClassificationToInterventionLinkList?: InputMaybe<Array<InputMaybe<MedicalFormClassificationToInterventionLinkList>>>;
  medicalFormId?: InputMaybe<Scalars["ID"]>;
  medicalMessageId?: InputMaybe<Scalars["ID"]>;
  numberOfOccurrences?: InputMaybe<Scalars["Int"]>;
  serviceOfHealthProgramTemplateDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  templateActivityType?: InputMaybe<GuidedCareActivityType>;
  templateTeamMember?: InputMaybe<ActivityTemplateTeamMemberInput>;
};

export type TemplateActivityInputThin = {
  id: Scalars["ID"];
};

export enum TemplateFieldType {
  AudioFile = "AUDIO_FILE",
  Boolean = "BOOLEAN",
  Date = "DATE",
  List = "LIST",
  Number = "NUMBER",
  NumericList = "NUMERIC_LIST",
  String = "STRING",
  VideoFile = "VIDEO_FILE",
}

export type TemplateGoal = H_Node & {
  __typename?: "TemplateGoal";
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  healthParameter?: Maybe<HealthParameter>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
  templateRecommendations?: Maybe<Array<Maybe<TemplateRecommendation>>>;
};

export type TemplateGoalInput = {
  description: Scalars["String"];
  descriptionAr?: InputMaybe<Scalars["String"]>;
  healthParameterId?: InputMaybe<Scalars["ID"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  nameAr?: InputMaybe<Scalars["String"]>;
  target: Scalars["String"];
  templateRecommendations?: InputMaybe<Array<InputMaybe<TemplateRecommendationInput>>>;
};

export type TemplateIntervention = H_Node & {
  __typename?: "TemplateIntervention";
  category?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  interventionTemplateActivities?: Maybe<Array<Maybe<TemplateInterventionActivity>>>;
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
};

export type TemplateInterventionActivity = H_Node & {
  __typename?: "TemplateInterventionActivity";
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  frequencyType: AppointmentFrequencyType;
  id: Scalars["ID"];
  interventionActivityEventItems?: Maybe<Array<Maybe<TemplateInterventionActivityEventItem>>>;
  medicalForm?: Maybe<MedicalForm>;
  medicalMessage?: Maybe<MedicalMessage>;
  numberOfOccurrences?: Maybe<Scalars["Int"]>;
  serviceOfInterventionActivityDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateActivityType?: Maybe<GuidedCareActivityType>;
  templateTeamMember?: Maybe<TemplateTeamMember>;
};

export type TemplateInterventionActivityCrud = {
  __typename?: "TemplateInterventionActivityCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  templateActivity?: Maybe<TemplateInterventionActivity>;
  templateActivityErrors?: Maybe<Array<H_EntityError>>;
};

export type TemplateInterventionActivityEventItem = H_Node & {
  __typename?: "TemplateInterventionActivityEventItem";
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  offsetInDays?: Maybe<Scalars["Int"]>;
  templateInterventionActivity?: Maybe<TemplateInterventionActivity>;
};

export type TemplateInterventionActivityEventItemInput = {
  afterCallMessageId?: InputMaybe<Scalars["ID"]>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  beforeCallMessageId?: InputMaybe<Scalars["ID"]>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  offsetInDays?: InputMaybe<Scalars["Int"]>;
};

export type TemplateInterventionActivityInput = {
  afterCallMessageId?: InputMaybe<Scalars["ID"]>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  beforeCallMessageId?: InputMaybe<Scalars["ID"]>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars["Int"]>;
  frequencyType?: InputMaybe<AppointmentFrequencyType>;
  id?: InputMaybe<Scalars["ID"]>;
  isModified?: InputMaybe<Scalars["Boolean"]>;
  medicalFormId?: InputMaybe<Scalars["ID"]>;
  medicalMessageId?: InputMaybe<Scalars["ID"]>;
  numberOfOccurrences?: InputMaybe<Scalars["Int"]>;
  serviceOfInterventionActivityDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  templateActivityType?: InputMaybe<GuidedCareActivityType>;
  templateTeamMember?: InputMaybe<ActivityTemplateTeamMemberInput>;
};

export type TemplateInterventionInput = {
  category?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  descriptionAr?: InputMaybe<Scalars["String"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  interventionTemplateActivities?: InputMaybe<Array<InputMaybe<TemplateInterventionActivityInput>>>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  uniqueId: Scalars["String"];
};

export type TemplateInterventionInputLight = {
  uniqueId: Scalars["String"];
};

export type TemplateInterventionThinInput = {
  uniqueId: Scalars["String"];
};

export type TemplateRecommendation = H_Node & {
  __typename?: "TemplateRecommendation";
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  descriptionAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  nameAr?: Maybe<Scalars["String"]>;
  templateInterventions?: Maybe<Array<Maybe<TemplateIntervention>>>;
};

export type TemplateRecommendationCountableConnection = {
  __typename?: "TemplateRecommendationCountableConnection";
  edges: Array<TemplateRecommendationCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type TemplateRecommendationCountableEdge = {
  __typename?: "TemplateRecommendationCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: TemplateRecommendation;
};

export type TemplateRecommendationFilterInput = {
  code?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type TemplateRecommendationInput = {
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  descriptionAr?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  templateInterventions?: InputMaybe<Array<InputMaybe<TemplateInterventionThinInput>>>;
};

export type TemplateTeamMember = H_Node & {
  __typename?: "TemplateTeamMember";
  id: Scalars["ID"];
  isBackupPersonMandatory?: Maybe<Scalars["Boolean"]>;
  isKeyTeamMember?: Maybe<Scalars["Boolean"]>;
  isLicencedHealthProfessional?: Maybe<Scalars["Boolean"]>;
  position?: Maybe<TeamMemberPosition>;
  specialization?: Maybe<Scalars["String"]>;
  specializationArabicDisplay?: Maybe<Scalars["String"]>;
  specializationDisplay?: Maybe<Scalars["String"]>;
};

export type TemplateTeamMemberIdInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type TemplateTeamMemberInput = {
  id?: InputMaybe<Scalars["ID"]>;
  isBackupPersonMandatory: Scalars["Boolean"];
  isKeyTeamMember: Scalars["Boolean"];
  isLicencedHealthProfessional: Scalars["Boolean"];
  position?: InputMaybe<TeamMemberPosition>;
  specialization?: InputMaybe<Scalars["String"]>;
  uniqueId: Scalars["String"];
};

export type TerminateVendorSubscription = {
  __typename?: "TerminateVendorSubscription";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export enum TerminologyErrorCodes {
  InvalidOrExpiredToken = "INVALID_OR_EXPIRED_TOKEN",
  TerminologyClinicianNotFound = "TERMINOLOGY_CLINICIAN_NOT_FOUND",
  TerminologyConceptDefinitionListNotFound = "TERMINOLOGY_CONCEPT_DEFINITION_LIST_NOT_FOUND",
  TerminologyDuplicateColumn = "TERMINOLOGY_DUPLICATE_COLUMN",
  TerminologyDuplicateProperty = "TERMINOLOGY_DUPLICATE_PROPERTY",
  TerminologyDuplicateRowData = "TERMINOLOGY_DUPLICATE_ROW_DATA",
  TerminologyDuplicateVersion = "TERMINOLOGY_DUPLICATE_VERSION",
  TerminologyEmptyFile = "TERMINOLOGY_EMPTY_FILE",
  TerminologyFileServiceNotReachable = "TERMINOLOGY_FILE_SERVICE_NOT_REACHABLE",
  TerminologyInvalidDateFormat = "TERMINOLOGY_INVALID_DATE_FORMAT",
  TerminologyInvalidFileExtension = "TERMINOLOGY_INVALID_FILE_EXTENSION",
  TerminologyInvalidProcessingStatus = "TERMINOLOGY_INVALID_PROCESSING_STATUS",
  TerminologyInvalidToken = "TERMINOLOGY_INVALID_TOKEN",
  TerminologyListDefinitionNotFound = "TERMINOLOGY_LIST_Definition_NOT_FOUND",
  TerminologyListItemNotFound = "TERMINOLOGY_LIST_Item_NOT_FOUND",
  TerminologyListNotFound = "TERMINOLOGY_LIST_NOT_FOUND",
  TerminologyMandatoryConceptPropertyException = "TERMINOLOGY_MANDATORY_CONCEPT_PROPERTY_EXCEPTION",
  TerminologyNoneDraftCodeSystem = "TERMINOLOGY_NONE_DRAFT_CODE_SYSTEM",
  TerminologyNoneEditableConceptDefinition = "TERMINOLOGY_NONE_EDITABLE_CONCEPT_DEFINITION",
  TerminologyNoneEditableList = "TERMINOLOGY_NONE_EDITABLE_LIST",
  TerminologyNoneNegativePageSize = "TERMINOLOGY_NONE_NEGATIVE_PAGE_SIZE",
  TerminologyNoneUnderReviewCodeSystem = "TERMINOLOGY_NONE_UNDER_REVIEW_CODE_SYSTEM",
  TerminologyNotApprovedCodeSystem = "TERMINOLOGY_NOT_APPROVED_CODE_SYSTEM",
  TerminologyNotFound = "TERMINOLOGY_NOT_FOUND",
  TerminologyNoActiveCodeSystem = "TERMINOLOGY_NO_ACTIVE_CODE_SYSTEM",
  TerminologyOldVersion = "TERMINOLOGY_OLD_VERSION",
  TerminologyPermissionDenied = "TERMINOLOGY_PERMISSION_DENIED",
  TerminologyUnknown = "TERMINOLOGY_UNKNOWN",
  TerminologyUnknownType = "TERMINOLOGY_UNKNOWN_TYPE",
  TerminologyValidation = "TERMINOLOGY_VALIDATION",
}

export type TerminologyGraphqlError = {
  __typename?: "TerminologyGraphqlError";
  code?: Maybe<TerminologyErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type TextSummaryError = {
  __typename?: "TextSummaryError";
  code: TextSummaryErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum TextSummaryErrorCode {
  ControlledDrug = "CONTROLLED_DRUG",
  GraphqlError = "GRAPHQL_ERROR",
  Ingested = "INGESTED",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type TextSummaryInput = {
  text: Scalars["String"];
};

export type TextSummaryMutation = {
  __typename?: "TextSummaryMutation";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  summary?: Maybe<Scalars["String"]>;
  textSummaryErrors: Array<TextSummaryError>;
};

export type TimeRange = {
  __typename?: "TimeRange";
  closeTime?: Maybe<Scalars["Time"]>;
  openTime?: Maybe<Scalars["Time"]>;
};

export type TimeRangeFilterInput = {
  end: Scalars["Time"];
  start: Scalars["Time"];
};

export type TimeRangeInput = {
  closeTime: Scalars["Time"];
  openTime: Scalars["Time"];
};

export type TimeSlot = {
  __typename?: "TimeSlot";
  endTime?: Maybe<Scalars["Instant"]>;
  startTime?: Maybe<Scalars["Instant"]>;
};

export type ToGlobalId = {
  __typename?: "ToGlobalId";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  id?: Maybe<Scalars["ID"]>;
};

export type TradeDrug = {
  __typename?: "TradeDrug";
  code: Scalars["String"];
  dictionaryURL?: Maybe<Scalars["String"]>;
  display: Scalars["String"];
  genericCode: Scalars["String"];
  genericDisplay: Scalars["String"];
  granularUnit?: Maybe<Scalars["Int"]>;
  isHazardous?: Maybe<Scalars["Boolean"]>;
  isHighAlert?: Maybe<Scalars["Boolean"]>;
  isLASA?: Maybe<Scalars["Boolean"]>;
  largeIconURL?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Int"]>;
  routeOfAdmin?: Maybe<Scalars["String"]>;
  smallIconURL?: Maybe<Scalars["String"]>;
  unitID?: Maybe<Scalars["Int"]>;
};

export type TransactionReport = {
  __typename?: "TransactionReport";
  buildNumber?: Maybe<Scalars["String"]>;
  lastPayment?: Maybe<PaymentResponse>;
  lastPaymentPending?: Maybe<Scalars["Boolean"]>;
  lastPaymentSucceeded?: Maybe<Scalars["Boolean"]>;
  ndc?: Maybe<Scalars["String"]>;
  payments?: Maybe<Array<Maybe<PaymentResponse>>>;
  result?: Maybe<PaymentResponseResult>;
  timestamp?: Maybe<Scalars["String"]>;
  timestampInstant?: Maybe<Scalars["Instant"]>;
};

export type TranscribeError = {
  __typename?: "TranscribeError";
  code: TranscribeErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum TranscribeErrorCode {
  ControlledDrug = "CONTROLLED_DRUG",
  GraphqlError = "GRAPHQL_ERROR",
  Ingested = "INGESTED",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type TranscribeInput = {
  callId: Scalars["String"];
  fileUrl: Scalars["String"];
  visitId: Scalars["Int"];
};

export enum Type {
  Boolean = "BOOLEAN",
  Date = "DATE",
  Float = "FLOAT",
  Integer = "INTEGER",
  String = "STRING",
}

export type TypeMutationInsertUnattendedCall = {
  __typename?: "TypeMutationInsertUnattendedCall";
  result?: Maybe<Scalars["String"]>;
  unattendedCallErrors?: Maybe<Array<H_EntityError>>;
};

export type TypeProviderGuidedCareProgramTeamMemberCountableConnection = {
  __typename?: "TypeProviderGuidedCareProgramTeamMemberCountableConnection";
  edges: Array<TypeProviderGuidedCareProgramTeamMemberCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type TypeProviderGuidedCareProgramTeamMemberCountableEdge = {
  __typename?: "TypeProviderGuidedCareProgramTeamMemberCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ProviderGuidedCareProgramTeamMember;
};

export type UnAssignUserFromBranch = {
  __typename?: "UnAssignUserFromBranch";
  branchErrors: Array<BranchError>;
  branchUser?: Maybe<BranchUser>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type UnattendedCallInput = {
  doctorId: Scalars["Int"];
  doctorUserId: Scalars["Int"];
  healthProgramId: Scalars["Int"];
  patientId: Scalars["Int"];
  patientUserId: Scalars["Int"];
  vendorId: Scalars["Int"];
  visitId: Scalars["Int"];
};

export type UnbindDependent = {
  __typename?: "UnbindDependent";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UnbindDependentInput = {
  dependentUserId: Scalars["ID"];
};

export type UnbindParent = {
  __typename?: "UnbindParent";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UnverifiedNationals = Node & {
  __typename?: "UnverifiedNationals";
  created: Scalars["DateTime"];
  documentExpiryDate: Scalars["Date"];
  frontImageFileName: Scalars["String"];
  id: Scalars["ID"];
  idType?: Maybe<UnverifiedNationalsIdType>;
  modified: Scalars["DateTime"];
  nationalId: Scalars["String"];
  nationality?: Maybe<Scalars["String"]>;
  rearImageFileName: Scalars["String"];
  rejectionReason?: Maybe<Scalars["String"]>;
  status?: Maybe<UnverifiedNationalsStatus>;
  user: User;
};

export type UnverifiedNationalsCountableConnection = {
  __typename?: "UnverifiedNationalsCountableConnection";
  edges: Array<UnverifiedNationalsCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type UnverifiedNationalsCountableEdge = {
  __typename?: "UnverifiedNationalsCountableEdge";
  cursor: Scalars["String"];
  node: UnverifiedNationals;
};

export type UnverifiedNationalsFilterInput = {
  status?: InputMaybe<Array<InputMaybe<UnverifiedNationalsStatusesEnum>>>;
};

export enum UnverifiedNationalsIdType {
  NationalCard = "NATIONAL_CARD",
  Passport = "PASSPORT",
  ResidentCard = "RESIDENT_CARD",
}

export enum UnverifiedNationalsStatus {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export enum UnverifiedNationalsStatusesEnum {
  Approved = "APPROVED",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type UpdateUserEmailMobile = {
  __typename?: "UpdateUserEmailMobile";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UpdateUserEmailMobileInput = {
  email?: InputMaybe<Scalars["String"]>;
  emailToken?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  mobileToken?: InputMaybe<Scalars["String"]>;
};

export type UploadAttachment = {
  __typename?: "UploadAttachment";
  attachment?: Maybe<Attachment>;
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type UploadNationalCard = {
  __typename?: "UploadNationalCard";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  unverifiedNationals?: Maybe<UnverifiedNationals>;
};

export type UploadNationalCardInput = {
  documentExpiryDate: Scalars["Date"];
  frontImageFileName: Scalars["String"];
  idType: PatientIdTypeEnum;
  nationalId: Scalars["String"];
  nationality: Scalars["String"];
  rearImageFileName: Scalars["String"];
};

export type User = Node & {
  __typename?: "User";
  activeConsentForms?: Maybe<PatientProfileConsentFormConnection>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  appRole?: Maybe<AppRoleTypes>;
  appType?: Maybe<AppTypes>;
  avatar?: Maybe<Scalars["String"]>;
  branches?: Maybe<Array<Maybe<Branch>>>;
  consentFormCode?: Maybe<PatientProfileConsentCode>;
  dateJoined: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["Date"]>;
  defaultBillingAddress?: Maybe<Address>;
  defaultBranch?: Maybe<Branch>;
  defaultShippingAddress?: Maybe<Address>;
  dentalHygienist?: Maybe<DentalHygienist>;
  departments?: Maybe<Array<Maybe<Department>>>;
  dependents?: Maybe<Array<Maybe<User>>>;
  diabetesEducator?: Maybe<DiabetesEducator>;
  doctor?: Maybe<Doctor>;
  /** must be called by vendor nurse user or vendor doctor */
  doctorNurseAssignments?: Maybe<DoctorNurseAssignmentCountableConnection>;
  editableGroups?: Maybe<Array<Maybe<Group>>>;
  email?: Maybe<Scalars["String"]>;
  emailVerified: Scalars["Boolean"];
  events?: Maybe<Array<Maybe<CustomerEvent>>>;
  firstName?: Maybe<Scalars["String"]>;
  fitnessCoach?: Maybe<FitnessCoach>;
  followedHealthChannels?: Maybe<HealthChannelCountableConnection>;
  fullName?: Maybe<Scalars["String"]>;
  gender?: Maybe<UserGender>;
  healthLicenseEndDate?: Maybe<Scalars["Date"]>;
  healthLicenseNumber?: Maybe<Scalars["String"]>;
  healthLicenseStartDate?: Maybe<Scalars["Date"]>;
  healthProgramsSubscriptions?: Maybe<HealthProgramUserSubscriptionCountableConnection>;
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  isStaff?: Maybe<Scalars["Boolean"]>;
  isSuperuser?: Maybe<Scalars["Boolean"]>;
  isVendorAdmin?: Maybe<Scalars["Boolean"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  lastMessage?: Maybe<Message>;
  lastName?: Maybe<Scalars["String"]>;
  manager?: Maybe<Manager>;
  marketplaceCart?: Maybe<MarketplaceCart>;
  marketplaceOrders?: Maybe<MarketplaceOrderConnection>;
  marketplaceWishlist?: Maybe<MarketplaceWishlist>;
  meetingPlatformId?: Maybe<Scalars["String"]>;
  messages?: Maybe<MessageCountableConnection>;
  mobile?: Maybe<Scalars["String"]>;
  mobileVerified: Scalars["Boolean"];
  nationalId?: Maybe<Scalars["String"]>;
  needActionCodeSystems?: Maybe<NeedActionCodeSystems>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<UserNotificationConnection>;
  nurse?: Maybe<Nurse>;
  nutritionist?: Maybe<Nutritionist>;
  optometrist?: Maybe<Optometrist>;
  orders?: Maybe<OrderCountableConnection>;
  parentUser?: Maybe<User>;
  patient?: Maybe<Patient>;
  paymentReceipts: PaymentReceiptCountableConnection;
  pendingConsentForms?: Maybe<PatientProfileConsentFormConnection>;
  permissionGroups?: Maybe<Array<Maybe<Group>>>;
  pharmacist?: Maybe<Pharmacist>;
  photo?: Maybe<Scalars["String"]>;
  podiatricMedicalAssistant?: Maybe<PodiatricMedicalAssistant>;
  preferredLanguage?: Maybe<Language>;
  prescriptions?: Maybe<PrescriptionCountableConnection>;
  psychologist?: Maybe<Psychologist>;
  receptionist?: Maybe<Receptionist>;
  relationType?: Maybe<RelationTypes>;
  secondName?: Maybe<Scalars["String"]>;
  socialWorker?: Maybe<SocialWorker>;
  termsAndConditionsAcceptedVersion?: Maybe<Scalars["Float"]>;
  thirdName?: Maybe<Scalars["String"]>;
  unReadMessagesCount?: Maybe<Scalars["Int"]>;
  unverifiedNationals?: Maybe<Array<Maybe<UnverifiedNationals>>>;
  userPermissions?: Maybe<Array<Maybe<UserPermission>>>;
  vendor?: Maybe<Vendor>;
  vendorUserType?: Maybe<UserVendorUserType>;
  visits?: Maybe<VisitCountableConnection>;
};

export type UserActiveConsentFormsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserDoctorNurseAssignmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<DoctorNurseAssignmentFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserFollowedHealthChannelsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserHealthProgramsSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramUserSubscriptionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserMarketplaceOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<MarketplaceOrderFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<MarketplaceOrderSortingInput>;
};

export type UserMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  branchId: Scalars["ID"];
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<UserNotificationFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<UserNotificationSortingInput>;
};

export type UserOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<OrderSortingInput>;
};

export type UserPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserPendingConsentFormsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserPrescriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PrescriptionFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PrescriptionSortingInput>;
};

export type UserVisitsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<VisitFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type UserAction = {
  __typename?: "UserAction";
  imageURL?: Maybe<Scalars["String"]>;
  isRequired?: Maybe<Scalars["Boolean"]>;
  messageAR?: Maybe<Scalars["String"]>;
  messageEN?: Maybe<Scalars["String"]>;
  requiredAction?: Maybe<Scalars["String"]>;
};

export type UserBranchInput = {
  branch?: InputMaybe<Scalars["ID"]>;
  user?: InputMaybe<Scalars["ID"]>;
};

export type UserCard = {
  __typename?: "UserCard";
  cardToken?: Maybe<Scalars["String"]>;
  expiryMonth?: Maybe<Scalars["Int"]>;
  expiryYear?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["ID"]>;
  isDefault?: Maybe<Scalars["Boolean"]>;
  lastFourDigits?: Maybe<Scalars["String"]>;
  paymentBrand?: Maybe<PaymentBrand>;
  userId?: Maybe<Scalars["ID"]>;
};

export type UserCardCrud = {
  __typename?: "UserCardCRUD";
  entity?: Maybe<UserCard>;
  entityErrors?: Maybe<Array<H_EntityError>>;
};

export type UserCardConnection = {
  __typename?: "UserCardConnection";
  edges?: Maybe<Array<Maybe<UserCardEdge>>>;
  pageInfo?: Maybe<H_PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type UserCardEdge = {
  __typename?: "UserCardEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<UserCard>;
};

export type UserCountableConnection = {
  __typename?: "UserCountableConnection";
  edges: Array<UserCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type UserCountableEdge = {
  __typename?: "UserCountableEdge";
  cursor: Scalars["String"];
  node: User;
};

export type UserCreate = {
  __typename?: "UserCreate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserDelete = {
  __typename?: "UserDelete";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserFilterInput = {
  appRole?: InputMaybe<AppRoleTypes>;
  appType?: InputMaybe<Array<InputMaybe<AppTypes>>>;
  branchId?: InputMaybe<Scalars["String"]>;
  dateJoined?: InputMaybe<DateRangeInput>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  groupName?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
  search?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<UserStatus>;
  vendorId?: InputMaybe<Scalars["String"]>;
  vendorUserType?: InputMaybe<Array<InputMaybe<VendorUserTypes>>>;
};

export enum UserGender {
  Female = "FEMALE",
  Male = "MALE",
}

export type UserManagementSiteSettings = Node & {
  __typename?: "UserManagementSiteSettings";
  deleteAccountEmailMessage?: Maybe<Scalars["String"]>;
  deleteAccountEmailMessageAr?: Maybe<Scalars["String"]>;
  deleteAccountSmsMessage?: Maybe<Scalars["String"]>;
  deleteAccountSmsMessageAr?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  microserviceContextPath?: Maybe<Scalars["String"]>;
  passwordResetEmailMessage?: Maybe<Scalars["String"]>;
  passwordResetEmailMessageAr?: Maybe<Scalars["String"]>;
  passwordResetSmsMessage?: Maybe<Scalars["String"]>;
  passwordResetSmsMessageAr?: Maybe<Scalars["String"]>;
  patientCreateEmailMessage?: Maybe<Scalars["String"]>;
  patientCreateEmailMessageAr?: Maybe<Scalars["String"]>;
  patientCreateSmsMessage?: Maybe<Scalars["String"]>;
  patientCreateSmsMessageAr?: Maybe<Scalars["String"]>;
  patientOrderOtpEmailMessage?: Maybe<Scalars["String"]>;
  patientOrderOtpEmailMessageAr?: Maybe<Scalars["String"]>;
  patientOrderOtpSmsMessage?: Maybe<Scalars["String"]>;
  patientOrderOtpSmsMessageAr?: Maybe<Scalars["String"]>;
  rangeExpansionMaxNumberOfRounds?: Maybe<Scalars["Int"]>;
  rangeExpansionMaxNumberOfTries?: Maybe<Scalars["Int"]>;
  rangeExpansionRoundMaxNumberOfPharmacies?: Maybe<Scalars["Int"]>;
  rangeExpansionRoundPharmaciesTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  rangeExpansionRoundRadius?: Maybe<Scalars["Float"]>;
  rangeExpansionTimeOutPeriod?: Maybe<Scalars["Int"]>;
  registrationEmailMessage?: Maybe<Scalars["String"]>;
  registrationEmailMessageAr?: Maybe<Scalars["String"]>;
  registrationSmsMessage?: Maybe<Scalars["String"]>;
  registrationSmsMessageAr?: Maybe<Scalars["String"]>;
  termsAndConditionsAcceptedVersion?: Maybe<Scalars["Float"]>;
  termsAndConditionsVersion?: Maybe<Scalars["Float"]>;
};

export type UserManagementSiteSettingsError = {
  __typename?: "UserManagementSiteSettingsError";
  code: SiteErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type UserManagementSiteSettingsInput = {
  deleteAccountEmailMessage?: InputMaybe<Scalars["String"]>;
  deleteAccountEmailMessageAr?: InputMaybe<Scalars["String"]>;
  deleteAccountSmsMessage?: InputMaybe<Scalars["String"]>;
  deleteAccountSmsMessageAr?: InputMaybe<Scalars["String"]>;
  microserviceContextPath?: InputMaybe<Scalars["String"]>;
  passwordResetEmailMessage?: InputMaybe<Scalars["String"]>;
  passwordResetEmailMessageAr?: InputMaybe<Scalars["String"]>;
  passwordResetSmsMessage?: InputMaybe<Scalars["String"]>;
  passwordResetSmsMessageAr?: InputMaybe<Scalars["String"]>;
  patientCreateEmailMessage?: InputMaybe<Scalars["String"]>;
  patientCreateEmailMessageAr?: InputMaybe<Scalars["String"]>;
  patientCreateSmsMessage?: InputMaybe<Scalars["String"]>;
  patientCreateSmsMessageAr?: InputMaybe<Scalars["String"]>;
  patientOrderOtpEmailMessage?: InputMaybe<Scalars["String"]>;
  patientOrderOtpEmailMessageAr?: InputMaybe<Scalars["String"]>;
  patientOrderOtpSmsMessage?: InputMaybe<Scalars["String"]>;
  patientOrderOtpSmsMessageAr?: InputMaybe<Scalars["String"]>;
  rangeExpansionMaxNumberOfRounds?: InputMaybe<Scalars["Int"]>;
  rangeExpansionMaxNumberOfTries?: InputMaybe<Scalars["Int"]>;
  rangeExpansionRoundMaxNumberOfPharmacies?: InputMaybe<Scalars["Int"]>;
  rangeExpansionRoundPharmaciesTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  rangeExpansionRoundRadius?: InputMaybe<Scalars["Float"]>;
  rangeExpansionTimeOutPeriod?: InputMaybe<Scalars["Int"]>;
  registrationEmailMessage?: InputMaybe<Scalars["String"]>;
  registrationEmailMessageAr?: InputMaybe<Scalars["String"]>;
  registrationSmsMessage?: InputMaybe<Scalars["String"]>;
  registrationSmsMessageAr?: InputMaybe<Scalars["String"]>;
  termsAndConditionsAcceptedVersion?: InputMaybe<Scalars["Float"]>;
};

export type UserManagementSiteSettingsUpdate = {
  __typename?: "UserManagementSiteSettingsUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  siteSettingsErrors: Array<UserManagementSiteSettingsError>;
  userManagementSiteSettings?: Maybe<UserManagementSiteSettings>;
};

export type UserNotification = {
  __typename?: "UserNotification";
  creationDate?: Maybe<Scalars["LocalDateTime"]>;
  data?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<SubscriptionGraphqlError>>>;
  id?: Maybe<Scalars["ID"]>;
  medicalMessage?: Maybe<MedicalMessage>;
  seen?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<NotificationType>;
  updateDate?: Maybe<Scalars["LocalDateTime"]>;
};

export type UserNotificationConnection = {
  __typename?: "UserNotificationConnection";
  edges?: Maybe<Array<Maybe<UserNotificationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type UserNotificationEdge = {
  __typename?: "UserNotificationEdge";
  cursor?: Maybe<Scalars["String"]>;
  node?: Maybe<UserNotification>;
};

export enum UserNotificationField {
  CreationDate = "CREATION_DATE",
  Seen = "SEEN",
  Type = "TYPE",
  UpdateDate = "UPDATE_DATE",
}

export type UserNotificationFilterInput = {
  creationDate?: InputMaybe<S_DateTimeRangeInput>;
  excludeTypes?: InputMaybe<Array<InputMaybe<NotificationType>>>;
  medicalMessageTypes?: InputMaybe<Array<InputMaybe<MedicalMessageType>>>;
  medicalMessages?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  seen?: InputMaybe<Scalars["Boolean"]>;
  types?: InputMaybe<Array<InputMaybe<NotificationType>>>;
};

export type UserNotificationSortingInput = {
  direction: SortDirection;
  field: UserNotificationField;
};

export type UserPermission = {
  __typename?: "UserPermission";
  code?: Maybe<PermissionEnum>;
  keyCloakPermission?: Maybe<KeyCloakPermission>;
  name: Scalars["String"];
  sourcePermissionGroups?: Maybe<Array<Group>>;
};

export type UserPermissionSourcePermissionGroupsArgs = {
  userId: Scalars["ID"];
};

export type UserProfileUpdate = {
  __typename?: "UserProfileUpdate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserProfileUpdateInput = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
};

export enum UserSortField {
  DateJoined = "DATE_JOINED",
  Email = "EMAIL",
  FirstName = "FIRST_NAME",
  LastMessageSent = "LAST_MESSAGE_SENT",
  LastName = "LAST_NAME",
  OrderCount = "ORDER_COUNT",
}

export type UserSortingInput = {
  direction: OrderDirection;
  field: UserSortField;
};

export enum UserStatus {
  Active = "ACTIVE",
  Deactivated = "DEACTIVATED",
}

export type UserUpdate = {
  __typename?: "UserUpdate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserUpdateInput = {
  addGroups?: InputMaybe<Array<Scalars["ID"]>>;
  appRole?: InputMaybe<AppRoleTypes>;
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  defaultBranch?: InputMaybe<Scalars["ID"]>;
  dentalHygienistInfo?: InputMaybe<DentalHygienistInput>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  diabetesEducatorInfo?: InputMaybe<DiabetesEducatorInput>;
  doctorInfo?: InputMaybe<DoctorInput>;
  firstName?: InputMaybe<Scalars["String"]>;
  fitnessCoachInfo?: InputMaybe<FitnessCoachInput>;
  gender?: InputMaybe<PersonGenderEnum>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  nurseInfo?: InputMaybe<NurseInput>;
  nutritionistInfo?: InputMaybe<NutritionistInput>;
  optometristInfo?: InputMaybe<OptometristInput>;
  password?: InputMaybe<Scalars["String"]>;
  pharmacistInfo?: InputMaybe<PharmacistInput>;
  photo?: InputMaybe<Scalars["String"]>;
  podiatricMedicalAssistantInfo?: InputMaybe<PodiatricMedicalAssistantInput>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  psychologistInfo?: InputMaybe<PsychologistInput>;
  relationType?: InputMaybe<RelationTypes>;
  removeGroups?: InputMaybe<Array<Scalars["ID"]>>;
  secondName?: InputMaybe<Scalars["String"]>;
  socialWorkerInfo?: InputMaybe<SocialWorkerInput>;
  thirdName?: InputMaybe<Scalars["String"]>;
};

export enum UserVendorUserType {
  DentalHygienist = "DENTAL_HYGIENIST",
  DiabetesEducator = "DIABETES_EDUCATOR",
  Doctor = "DOCTOR",
  FitnessCoach = "FITNESS_COACH",
  Manager = "MANAGER",
  Nurse = "NURSE",
  Nutritionist = "NUTRITIONIST",
  Optometrist = "OPTOMETRIST",
  Pharmacist = "PHARMACIST",
  PodiatricMedicalAssistant = "PODIATRIC_MEDICAL_ASSISTANT",
  Psychologist = "PSYCHOLOGIST",
  Receptionist = "RECEPTIONIST",
  SocialWorker = "SOCIAL_WORKER",
}

export type ValidatePrescription = {
  __typename?: "ValidatePrescription";
  allowedDeliveryTypes?: Maybe<Array<Maybe<DeliveryTypes>>>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  prescription?: Maybe<Prescription>;
  prescriptionErrors: Array<PrescriptionError>;
};

export type ValidatePrescriptionInput = {
  memberId?: InputMaybe<Scalars["ID"]>;
  referenceNumber: Scalars["String"];
};

export type ValidateProceduresInput = {
  diagnosisCodes: Array<Scalars["String"]>;
  procedureCodes: Array<Scalars["String"]>;
};

export type Vendor = Node & {
  __typename?: "Vendor";
  address?: Maybe<Address>;
  appointments?: Maybe<AppointmentCountableConnection>;
  approved: Scalars["Boolean"];
  approvedAt?: Maybe<Scalars["DateTime"]>;
  approvedBy?: Maybe<User>;
  authorityCode?: Maybe<Scalars["String"]>;
  backGroundImage?: Maybe<Scalars["String"]>;
  bankInfo?: Maybe<VendorBankInfo>;
  branches?: Maybe<BranchCountableConnection>;
  callDoctorNowPlatformShare?: Maybe<Scalars["Float"]>;
  cluster?: Maybe<Scalars["String"]>;
  commercialRegistrationNumber: Scalars["String"];
  contactEmail?: Maybe<Scalars["String"]>;
  contactMobileNumber?: Maybe<Scalars["String"]>;
  contactPhoneNumber?: Maybe<Scalars["String"]>;
  created: Scalars["DateTime"];
  customerfavoritevendor: CustomerFavoriteVendorCountableConnection;
  deliveryMinFrom: Scalars["Int"];
  deliveryMinTo: Scalars["Int"];
  deliveryPrice: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  division?: Maybe<Division>;
  doctoravailabilitySet: DoctorAvailabilityCountableConnection;
  doctors: DoctorCountableConnection;
  enableVisitDetails: Scalars["Boolean"];
  favoriteCustomers: UserCountableConnection;
  fixedCosts?: Maybe<Scalars["Int"]>;
  group?: Maybe<Scalars["String"]>;
  hasMultipleBranches: Scalars["Boolean"];
  hasOwnDrivers: Scalars["Boolean"];
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  healthProgramNetworks?: Maybe<HealthProgramNetworkCountableConnection>;
  id: Scalars["ID"];
  images?: Maybe<Array<Maybe<VendorImage>>>;
  isActive: Scalars["Boolean"];
  isFavorite?: Maybe<Scalars["Boolean"]>;
  isIntegrated: Scalars["Boolean"];
  isLiveBookingIntegrated: Scalars["Boolean"];
  isShippingFree: Scalars["Boolean"];
  isVip: Scalars["Boolean"];
  logo?: Maybe<Scalars["String"]>;
  managersContactInfo?: Maybe<VendorManagersContactInfo>;
  maxNumberOfUsers?: Maybe<Scalars["Int"]>;
  modified: Scalars["DateTime"];
  name: Scalars["String"];
  nameAr?: Maybe<Scalars["String"]>;
  nationalId: Scalars["String"];
  notifyByEmail: Scalars["Boolean"];
  onboardingStatus?: Maybe<Scalars["String"]>;
  operationStatus?: Maybe<Scalars["String"]>;
  orderMinimumFreeDelivery: Scalars["Float"];
  ordersCount?: Maybe<Scalars["Int"]>;
  orgIdNhic?: Maybe<Scalars["String"]>;
  ownerName: Scalars["String"];
  paymentReceipts: PaymentReceiptCountableConnection;
  priceRange?: Maybe<PriceRangeEnum>;
  providerSettings?: Maybe<ProviderSettings>;
  rating?: Maybe<Scalars["Float"]>;
  rejections?: Maybe<VendorRejectionReasonCountableConnection>;
  reviews: VendorReviewConnection;
  seoDescription?: Maybe<Scalars["String"]>;
  seoTitle?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  source?: Maybe<Scalars["String"]>;
  subscriptions: VendorSubscriptionCountableConnection;
  supportOutpatientJourney: Scalars["Boolean"];
  taxLicenseNumber: Scalars["String"];
  totalOrdersCount: Scalars["Float"];
  tradeName: Scalars["String"];
  type?: Maybe<VendorType>;
  users?: Maybe<UserCountableConnection>;
  virtualGroup?: Maybe<Scalars["String"]>;
};

export type VendorAppointmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AppointmentFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<AppointmentSortingInput>;
};

export type VendorBranchesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<BranchFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<BranchOrder>;
  userLocation?: InputMaybe<LocationInput>;
};

export type VendorCustomerfavoritevendorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorDoctoravailabilitySetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorDoctorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorFavoriteCustomersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorFixedCostsArgs = {
  dateFrom: Scalars["Date"];
  dateTill: Scalars["Date"];
};

export type VendorHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorHealthProgramNetworksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<HealthProgramNetworkFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<HealthProgramNetworkSortingInput>;
};

export type VendorPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorRejectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorReviewsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorSubscriptionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VendorUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<UserSortingInput>;
};

export type VendorAdminInput = {
  acceptsDelivery?: InputMaybe<Scalars["Boolean"]>;
  acceptsPickup?: InputMaybe<Scalars["Boolean"]>;
  addressInput?: InputMaybe<AddressInput>;
  authorityCode?: InputMaybe<Scalars["String"]>;
  backGroundImage?: InputMaybe<Scalars["String"]>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  callDoctorNowPlatformShare?: InputMaybe<Scalars["Float"]>;
  cluster?: InputMaybe<Scalars["String"]>;
  commercialRegistrationNumber?: InputMaybe<Scalars["String"]>;
  contactMobileNumber?: InputMaybe<Scalars["String"]>;
  contactPhoneNumber?: InputMaybe<Scalars["String"]>;
  deliveryMinFrom?: InputMaybe<Scalars["Int"]>;
  deliveryMinTo?: InputMaybe<Scalars["Int"]>;
  deliveryPrice?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  enableVisitDetails?: InputMaybe<Scalars["Boolean"]>;
  group?: InputMaybe<Scalars["String"]>;
  hasMultipleBranches?: InputMaybe<Scalars["Boolean"]>;
  hasOwnDrivers?: InputMaybe<Scalars["Boolean"]>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isIntegrated?: InputMaybe<Scalars["Boolean"]>;
  isLiveBookingIntegrated?: InputMaybe<Scalars["Boolean"]>;
  isVip?: InputMaybe<Scalars["Boolean"]>;
  logo?: InputMaybe<Scalars["String"]>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  maxNumberOfUsers?: InputMaybe<Scalars["Int"]>;
  mobileNumber: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  notifyByEmail?: InputMaybe<Scalars["Boolean"]>;
  operationStatus?: InputMaybe<Scalars["String"]>;
  orgIdNhic?: InputMaybe<Scalars["String"]>;
  ownerName?: InputMaybe<Scalars["String"]>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  slug?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  subscription?: InputMaybe<SubscriptionInput>;
  supportOutpatientJourney: Scalars["Boolean"];
  taxLicenseNumber?: InputMaybe<Scalars["String"]>;
  tradeName?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<VendorTypeEnum>;
  virtualGroup?: InputMaybe<Scalars["String"]>;
};

export type VendorBankInfo = Node & {
  __typename?: "VendorBankInfo";
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bankName?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  vendor: Vendor;
};

export type VendorBankInfoInput = {
  accountName?: InputMaybe<Scalars["String"]>;
  accountNumber?: InputMaybe<Scalars["String"]>;
  bankName?: InputMaybe<Scalars["String"]>;
  iban?: InputMaybe<Scalars["String"]>;
};

export enum VendorBranchTypeEnum {
  Clinic = "CLINIC",
  DiagnosticCenter = "DIAGNOSTIC_CENTER",
  Hospital = "HOSPITAL",
  Pharmacy = "PHARMACY",
}

export type VendorCountableConnection = {
  __typename?: "VendorCountableConnection";
  edges: Array<VendorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VendorCountableEdge = {
  __typename?: "VendorCountableEdge";
  cursor: Scalars["String"];
  node: Vendor;
};

export type VendorCreate = {
  __typename?: "VendorCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorDelete = {
  __typename?: "VendorDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorDepartmentCreate = {
  __typename?: "VendorDepartmentCreate";
  department?: Maybe<Department>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorDepartmentErrors: Array<VendorDepartmentError>;
};

export type VendorDepartmentCreateInput = {
  branch: Scalars["ID"];
  code: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  directions?: InputMaybe<Scalars["String"]>;
  medicalServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name: Scalars["String"];
};

export type VendorDepartmentDelete = {
  __typename?: "VendorDepartmentDelete";
  department?: Maybe<Department>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorDepartmentErrors: Array<VendorDepartmentError>;
};

export type VendorDepartmentError = {
  __typename?: "VendorDepartmentError";
  code: VendorErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type VendorDepartmentFilterInput = {
  branch?: InputMaybe<Scalars["ID"]>;
  code?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<Scalars["ID"]>;
};

export type VendorDepartmentOrder = {
  direction: OrderDirection;
  field?: InputMaybe<VendorDepartmentOrderField>;
};

export enum VendorDepartmentOrderField {
  Branch = "BRANCH",
  Code = "CODE",
  Name = "NAME",
}

export type VendorDepartmentUpdate = {
  __typename?: "VendorDepartmentUpdate";
  department?: Maybe<Department>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorDepartmentErrors: Array<VendorDepartmentError>;
};

export type VendorDepartmentUpdateInput = {
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  directions?: InputMaybe<Scalars["String"]>;
  medicalServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type VendorError = {
  __typename?: "VendorError";
  code: VendorErrorCode;
  field?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export enum VendorErrorCode {
  AlreadyExists = "ALREADY_EXISTS",
  DuplicatedInputItem = "DUPLICATED_INPUT_ITEM",
  GraphqlError = "GRAPHQL_ERROR",
  Invalid = "INVALID",
  NotFound = "NOT_FOUND",
  NotVendorsImage = "NOT_VENDORS_IMAGE",
  Required = "REQUIRED",
  Unique = "UNIQUE",
}

export type VendorFilterInput = {
  approved?: InputMaybe<Scalars["Boolean"]>;
  commercialRegistrationNumber?: InputMaybe<Scalars["String"]>;
  contactMobileNumber?: InputMaybe<Scalars["String"]>;
  contactMobileNumber_Icontains?: InputMaybe<Scalars["String"]>;
  contactPhoneNumber?: InputMaybe<Scalars["String"]>;
  contactPhoneNumber_Icontains?: InputMaybe<Scalars["String"]>;
  delivery?: InputMaybe<DecimalRangeInput>;
  description?: InputMaybe<Scalars["String"]>;
  description_Icontains?: InputMaybe<Scalars["String"]>;
  hasChatWith?: InputMaybe<Scalars["Boolean"]>;
  hasHealthPackages?: InputMaybe<Scalars["Boolean"]>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  isIntegrated?: InputMaybe<Scalars["Boolean"]>;
  isLiveBookingIntegrated?: InputMaybe<Scalars["Boolean"]>;
  isVip?: InputMaybe<Scalars["Boolean"]>;
  location?: InputMaybe<DistanceFilterInput>;
  name?: InputMaybe<Scalars["String"]>;
  name_Icontains?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  nationalId_Icontains?: InputMaybe<Scalars["String"]>;
  ownerName?: InputMaybe<Scalars["String"]>;
  ownerName_Icontains?: InputMaybe<Scalars["String"]>;
  priceRange?: InputMaybe<Scalars["String"]>;
  rating?: InputMaybe<DecimalRangeInput>;
  search?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_Icontains?: InputMaybe<Scalars["String"]>;
  subscriptionExpiry?: InputMaybe<DateRangeInput>;
  supportOutpatientJourney?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Array<InputMaybe<VendorTypeEnum>>>;
};

export type VendorImage = Node & {
  __typename?: "VendorImage";
  alt: Scalars["String"];
  id: Scalars["ID"];
  sortOrder?: Maybe<Scalars["Int"]>;
  url: Scalars["String"];
};

export type VendorImageUrlArgs = {
  size?: InputMaybe<Scalars["Int"]>;
};

export type VendorImageCreate = {
  __typename?: "VendorImageCreate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageCreateInput = {
  alt?: InputMaybe<Scalars["String"]>;
  image: Scalars["String"];
  vendor: Scalars["ID"];
};

export type VendorImageDelete = {
  __typename?: "VendorImageDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageReorder = {
  __typename?: "VendorImageReorder";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  images?: Maybe<Array<Maybe<VendorImage>>>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageUpdate = {
  __typename?: "VendorImageUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageUpdateInput = {
  alt?: InputMaybe<Scalars["String"]>;
};

export type VendorInput = {
  acceptsDelivery?: InputMaybe<Scalars["Boolean"]>;
  acceptsPickup?: InputMaybe<Scalars["Boolean"]>;
  addressInput?: InputMaybe<AddressInput>;
  backGroundImage?: InputMaybe<Scalars["String"]>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  commercialRegistrationNumber?: InputMaybe<Scalars["String"]>;
  contactMobileNumber?: InputMaybe<Scalars["String"]>;
  contactPhoneNumber?: InputMaybe<Scalars["String"]>;
  deliveryMinFrom?: InputMaybe<Scalars["Int"]>;
  deliveryMinTo?: InputMaybe<Scalars["Int"]>;
  deliveryPrice?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  hasMultipleBranches?: InputMaybe<Scalars["Boolean"]>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isLiveBookingIntegrated?: InputMaybe<Scalars["Boolean"]>;
  logo?: InputMaybe<Scalars["String"]>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  mobileNumber: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  notifyByEmail?: InputMaybe<Scalars["Boolean"]>;
  ownerName?: InputMaybe<Scalars["String"]>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  supportOutpatientJourney: Scalars["Boolean"];
  taxLicenseNumber?: InputMaybe<Scalars["String"]>;
  tradeName?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<VendorTypeEnum>;
};

export type VendorManagersContactInfo = Node & {
  __typename?: "VendorManagersContactInfo";
  financialManagerEmail?: Maybe<Scalars["String"]>;
  financialManagerMobileNumber?: Maybe<Scalars["String"]>;
  financialManagerName?: Maybe<Scalars["String"]>;
  generalManagerEmail?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  purchasingManagerEmail?: Maybe<Scalars["String"]>;
  purchasingManagerMobileNumber?: Maybe<Scalars["String"]>;
  purchasingManagerName?: Maybe<Scalars["String"]>;
  vendor: Vendor;
};

export type VendorManagersContactInfoInput = {
  financialManagerEmail?: InputMaybe<Scalars["String"]>;
  financialManagerMobileNumber?: InputMaybe<Scalars["String"]>;
  financialManagerName?: InputMaybe<Scalars["String"]>;
  generalManagerEmail?: InputMaybe<Scalars["String"]>;
  purchasingManagerEmail?: InputMaybe<Scalars["String"]>;
  purchasingManagerMobileNumber?: InputMaybe<Scalars["String"]>;
  purchasingManagerName?: InputMaybe<Scalars["String"]>;
};

export type VendorMarkMessagesAsSeen = {
  __typename?: "VendorMarkMessagesAsSeen";
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars["Int"]>;
};

export type VendorMessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<MessageAttachmentInput>>>;
  branch?: InputMaybe<Scalars["ID"]>;
  content?: InputMaybe<Scalars["String"]>;
  recipient?: InputMaybe<Scalars["ID"]>;
};

export type VendorOrder = {
  direction: OrderDirection;
  field?: InputMaybe<VendorOrderField>;
};

export type VendorOrderAccept = {
  __typename?: "VendorOrderAccept";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type VendorOrderAcceptInput = {
  acceptedMedications?: InputMaybe<Array<InputMaybe<MedicationOrderLineInput>>>;
};

export type VendorOrderCancel = {
  __typename?: "VendorOrderCancel";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type VendorOrderDeliveryStatusUpdate = {
  __typename?: "VendorOrderDeliveryStatusUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export enum VendorOrderField {
  Created = "CREATED",
  Name = "NAME",
  Nearest = "NEAREST",
  OrderCount = "ORDER_COUNT",
  Rating = "RATING",
}

export type VendorOrderReject = {
  __typename?: "VendorOrderReject";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  order?: Maybe<Order>;
  orderErrors: Array<OrderError>;
};

export type VendorRegister = {
  __typename?: "VendorRegister";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorRejectionReason = Node & {
  __typename?: "VendorRejectionReason";
  created: Scalars["DateTime"];
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  reason: Scalars["String"];
  vendor: Vendor;
};

export type VendorRejectionReasonCountableConnection = {
  __typename?: "VendorRejectionReasonCountableConnection";
  edges: Array<VendorRejectionReasonCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VendorRejectionReasonCountableEdge = {
  __typename?: "VendorRejectionReasonCountableEdge";
  cursor: Scalars["String"];
  node: VendorRejectionReason;
};

export type VendorRejectionReasonInput = {
  reason: Scalars["String"];
  vendor: Scalars["ID"];
};

export type VendorReview = Node & {
  __typename?: "VendorReview";
  content?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<User>;
  id: Scalars["ID"];
  published: Scalars["Boolean"];
  rating: Scalars["Int"];
  vendor: Vendor;
};

export type VendorReviewConnection = {
  __typename?: "VendorReviewConnection";
  edges: Array<Maybe<VendorReviewEdge>>;
  pageInfo: PageInfo;
};

export type VendorReviewCreateInput = {
  content?: InputMaybe<Scalars["String"]>;
  rating: Scalars["Int"];
  vendor: Scalars["ID"];
};

export type VendorReviewDelete = {
  __typename?: "VendorReviewDelete";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorReview?: Maybe<VendorReview>;
};

export type VendorReviewEdge = {
  __typename?: "VendorReviewEdge";
  cursor: Scalars["String"];
  node?: Maybe<VendorReview>;
};

export type VendorReviewPublish = {
  __typename?: "VendorReviewPublish";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorReview?: Maybe<VendorReview>;
};

export type VendorReviewPublishInput = {
  published: Scalars["Boolean"];
};

export type VendorSendMessage = {
  __typename?: "VendorSendMessage";
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  message?: Maybe<Message>;
};

export type VendorSubscription = Node & {
  __typename?: "VendorSubscription";
  created: Scalars["DateTime"];
  currency: Scalars["String"];
  fixedCost?: Maybe<Money>;
  fixedCostAmount: Scalars["Float"];
  fixedOrderCost?: Maybe<Money>;
  fixedOrderCostAmount: Scalars["Float"];
  fixedOrderPercentage: Scalars["Float"];
  id: Scalars["ID"];
  isActive: Scalars["Boolean"];
  modified: Scalars["DateTime"];
  plan?: Maybe<Plan>;
  validFrom: Scalars["DateTime"];
  validTill?: Maybe<Scalars["DateTime"]>;
  vendor?: Maybe<Vendor>;
};

export type VendorSubscriptionCountableConnection = {
  __typename?: "VendorSubscriptionCountableConnection";
  edges: Array<VendorSubscriptionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VendorSubscriptionCountableEdge = {
  __typename?: "VendorSubscriptionCountableEdge";
  cursor: Scalars["String"];
  node: VendorSubscription;
};

export enum VendorType {
  Clinic = "CLINIC",
  DiagnosticCenter = "DIAGNOSTIC_CENTER",
  Hospital = "HOSPITAL",
  Pharmacy = "PHARMACY",
}

export enum VendorTypeEnum {
  Clinic = "CLINIC",
  DiagnosticCenter = "DIAGNOSTIC_CENTER",
  Hospital = "HOSPITAL",
  Pharmacy = "PHARMACY",
}

export type VendorUpdate = {
  __typename?: "VendorUpdate";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateAdminInput = {
  acceptsDelivery?: InputMaybe<Scalars["Boolean"]>;
  acceptsPickup?: InputMaybe<Scalars["Boolean"]>;
  addressInput?: InputMaybe<AddressInput>;
  authorityCode?: InputMaybe<Scalars["String"]>;
  backGroundImage?: InputMaybe<Scalars["String"]>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  callDoctorNowPlatformShare?: InputMaybe<Scalars["Float"]>;
  cluster?: InputMaybe<Scalars["String"]>;
  commercialRegistrationNumber?: InputMaybe<Scalars["String"]>;
  contactMobileNumber?: InputMaybe<Scalars["String"]>;
  contactPhoneNumber?: InputMaybe<Scalars["String"]>;
  deliveryMinFrom?: InputMaybe<Scalars["Int"]>;
  deliveryMinTo?: InputMaybe<Scalars["Int"]>;
  deliveryPrice?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableVisitDetails?: InputMaybe<Scalars["Boolean"]>;
  group?: InputMaybe<Scalars["String"]>;
  hasMultipleBranches?: InputMaybe<Scalars["Boolean"]>;
  hasOwnDrivers?: InputMaybe<Scalars["Boolean"]>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isIntegrated?: InputMaybe<Scalars["Boolean"]>;
  isLiveBookingIntegrated?: InputMaybe<Scalars["Boolean"]>;
  isVip?: InputMaybe<Scalars["Boolean"]>;
  logo?: InputMaybe<Scalars["String"]>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  maxNumberOfUsers?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameAr?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  notifyByEmail?: InputMaybe<Scalars["Boolean"]>;
  operationStatus?: InputMaybe<Scalars["String"]>;
  orgIdNhic?: InputMaybe<Scalars["String"]>;
  ownerName?: InputMaybe<Scalars["String"]>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  slug?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  subscription?: InputMaybe<SubscriptionInput>;
  supportOutpatientJourney?: InputMaybe<Scalars["Boolean"]>;
  taxLicenseNumber?: InputMaybe<Scalars["String"]>;
  tradeName?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<VendorTypeEnum>;
  virtualGroup?: InputMaybe<Scalars["String"]>;
};

export type VendorUpdateEnableVisitDetails = {
  __typename?: "VendorUpdateEnableVisitDetails";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateEnableVisitDetailsInput = {
  enableVisitDetails: Scalars["Boolean"];
};

export type VendorUpdateIsIntegrated = {
  __typename?: "VendorUpdateIsIntegrated";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateIsIntegratedInput = {
  isIntegrated: Scalars["Boolean"];
};

export type VendorUserCreate = {
  __typename?: "VendorUserCreate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserCreateInput = {
  address?: InputMaybe<AddressInput>;
  appRole: AppRoleTypes;
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  defaultBranch?: InputMaybe<Scalars["ID"]>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  photo?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars["String"]>;
  thirdName?: InputMaybe<Scalars["String"]>;
};

export type VendorUserDelete = {
  __typename?: "VendorUserDelete";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export enum VendorUserTypes {
  DentalHygienist = "DENTAL_HYGIENIST",
  DiabetesEducator = "DIABETES_EDUCATOR",
  Doctor = "DOCTOR",
  FitnessCoach = "FITNESS_COACH",
  Manager = "MANAGER",
  Nurse = "NURSE",
  Nutritionist = "NUTRITIONIST",
  Optometrist = "OPTOMETRIST",
  Pharmacist = "PHARMACIST",
  PodiatricMedicalAssistant = "PODIATRIC_MEDICAL_ASSISTANT",
  Psychologist = "PSYCHOLOGIST",
  Receptionist = "RECEPTIONIST",
  SocialWorker = "SOCIAL_WORKER",
}

export type VendorUserUpdate = {
  __typename?: "VendorUserUpdate";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserUpdateInput = {
  appRole?: InputMaybe<AppRoleTypes>;
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PersonGenderEnum>;
  healthLicenseEndDate?: InputMaybe<Scalars["Date"]>;
  healthLicenseNumber?: InputMaybe<Scalars["String"]>;
  healthLicenseStartDate?: InputMaybe<Scalars["Date"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  mobile?: InputMaybe<Scalars["String"]>;
  nationalId?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars["String"]>;
  thirdName?: InputMaybe<Scalars["String"]>;
};

export type VerifyCredentials = {
  __typename?: "VerifyCredentials";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars["String"]>;
};

export type VerifyNationalId = {
  __typename?: "VerifyNationalId";
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  unverifiedNationals?: Maybe<UnverifiedNationals>;
};

export type VerifyNationalIdInput = {
  rejectionReason?: InputMaybe<Scalars["String"]>;
  status: UnverifiedNationalsStatusesEnum;
};

export type Visit = H_Node & {
  __typename?: "Visit";
  appointment?: Maybe<Appointment>;
  assistantAgentConnected?: Maybe<Scalars["Boolean"]>;
  attachments?: Maybe<Array<Maybe<VisitAttachment>>>;
  branch?: Maybe<Branch>;
  callAuditRecords?: Maybe<Array<Maybe<CallAuditRecord>>>;
  callId?: Maybe<Scalars["H_Long"]>;
  callRecordStatusInfo?: Maybe<VisitCallRecordStatusInfo>;
  cancellationReason?: Maybe<Scalars["String"]>;
  careInitialType?: Maybe<CareType>;
  careType?: Maybe<VisitCareType>;
  chatId?: Maybe<Scalars["H_Long"]>;
  complains?: Maybe<VisitComplainCountableConnection>;
  consultationRate?: Maybe<Scalars["Float"]>;
  consumerJoined?: Maybe<Scalars["Boolean"]>;
  consumerJoinedDate?: Maybe<Scalars["Instant"]>;
  consumerLeft?: Maybe<Scalars["Boolean"]>;
  consumerLeftDate?: Maybe<Scalars["Instant"]>;
  created?: Maybe<Scalars["Instant"]>;
  department?: Maybe<Department>;
  doctor?: Maybe<Doctor>;
  doctorAvailabilityStatus?: Maybe<Scalars["String"]>;
  doctorId?: Maybe<Scalars["Int"]>;
  doctorJoined?: Maybe<Scalars["Boolean"]>;
  doctorJoinedDate?: Maybe<Scalars["Instant"]>;
  doctorLeft?: Maybe<Scalars["Boolean"]>;
  doctorLeftDate?: Maybe<Scalars["Instant"]>;
  doctorNote?: Maybe<Scalars["String"]>;
  doctorUser?: Maybe<User>;
  doctorUserId?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["Int"]>;
  endTime?: Maybe<Scalars["Instant"]>;
  followupWorkflowId?: Maybe<Scalars["String"]>;
  followupWorkflowRunId?: Maybe<Scalars["String"]>;
  followupWorkflowType?: Maybe<Scalars["String"]>;
  hasDoctorNote?: Maybe<Scalars["Boolean"]>;
  hasLab?: Maybe<Scalars["Boolean"]>;
  hasRad?: Maybe<Scalars["Boolean"]>;
  healthProgram?: Maybe<HealthProgram>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  id: Scalars["ID"];
  labs?: Maybe<Array<Maybe<Lab>>>;
  meetingPlatformLink?: Maybe<Scalars["String"]>;
  meetingPlatformLinkForChatView?: Maybe<Scalars["String"]>;
  meetingPlatformLinkForJoinCall?: Maybe<Scalars["String"]>;
  messages?: Maybe<VisitMessageCountableConnection>;
  modified?: Maybe<Scalars["Instant"]>;
  /** User-friendly number of a visit */
  number?: Maybe<Scalars["String"]>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars["Int"]>;
  patientNationalId?: Maybe<Scalars["String"]>;
  patientNote?: Maybe<Scalars["String"]>;
  patientUser?: Maybe<User>;
  patientUserId?: Maybe<Scalars["Int"]>;
  payPerCallOnly?: Maybe<Scalars["Boolean"]>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  prescription?: Maybe<Prescription>;
  prescriptionId?: Maybe<Scalars["Int"]>;
  priceAmount?: Maybe<Scalars["Float"]>;
  priceAmountAfterVat?: Maybe<Scalars["Float"]>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  rads?: Maybe<Array<Maybe<Rad>>>;
  rejectionAudits?: Maybe<DoctorVisitRejectionAuditCountableConnection>;
  reviews?: Maybe<Array<Maybe<VisitReviewQuestion>>>;
  startTime?: Maybe<Scalars["Instant"]>;
  status?: Maybe<VisitStatus>;
  subscription?: Maybe<HealthProgramUserSubscription>;
  summaries?: Maybe<VisitSummaryCountableConnection>;
  transcription?: Maybe<VisitTranscription>;
  type?: Maybe<VisitType>;
  vatPercentage?: Maybe<Scalars["Float"]>;
  vendor?: Maybe<Vendor>;
  vendorActorJoined?: Maybe<Scalars["Boolean"]>;
  vendorActorJoinedDate?: Maybe<Scalars["Instant"]>;
  vendorActorLeft?: Maybe<Scalars["Boolean"]>;
  vendorActorLeftDate?: Maybe<Scalars["Instant"]>;
  vendorId?: Maybe<Scalars["Int"]>;
  visitDiagnoses?: Maybe<Array<Maybe<VisitDiagnosis>>>;
  visitPrescriptions?: Maybe<Array<Maybe<VisitPrescriptionTransaction>>>;
  vitalSigns?: Maybe<Array<Maybe<PatientHealthParameter>>>;
  workflowId?: Maybe<Scalars["String"]>;
};

export type VisitComplainsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitMessagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitRejectionAuditsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitSummariesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitAction = {
  __typename?: "VisitAction";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

/** Visit Approval Action */
export type VisitApprovalAction = {
  __typename?: "VisitApprovalAction";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

/** Update Visit Assistant Agent Status. */
export type VisitAssistantAgentStatusUpdate = {
  __typename?: "VisitAssistantAgentStatusUpdate";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  success?: Maybe<Scalars["Boolean"]>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitAttachment = H_Node & {
  __typename?: "VisitAttachment";
  attachment?: Maybe<Attachment>;
  attachmentId?: Maybe<Scalars["Int"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  type?: Maybe<VisitAttachmentType>;
  visit?: Maybe<Visit>;
};

export enum VisitAttachmentType {
  File = "FILE",
  MedicalReport = "MEDICAL_REPORT",
  TestResult = "TEST_RESULT",
}

export type VisitAttachmentUploadInput = {
  attachment: Scalars["ID"];
  type: VisitAttachmentType;
  visit: Scalars["ID"];
};

/** end visit call. */
export type VisitCallEnd = {
  __typename?: "VisitCallEnd";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  success?: Maybe<Scalars["Boolean"]>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitCallRecordStatusInfo = {
  __typename?: "VisitCallRecordStatusInfo";
  callRecordProgress?: Maybe<Scalars["String"]>;
  callRecordStatus?: Maybe<CallRecordStatus>;
  callRecordUrl?: Maybe<Scalars["String"]>;
};

export enum VisitCareType {
  /** Home Visit */
  HomeVisit = "HOME_VISIT",
  /** In Person */
  InPerson = "IN_PERSON",
  /** Virtual */
  Virtual = "VIRTUAL",
}

export type VisitChronicGuidedCareMemberAddInput = {
  guidedCareProgramId: Scalars["ID"];
  visitDiagnosisRiskTemplate: Scalars["ID"];
};

export type VisitComplain = H_Node & {
  __typename?: "VisitComplain";
  complain?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  customerId?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  visit?: Maybe<Visit>;
};

export type VisitComplainCountableConnection = {
  __typename?: "VisitComplainCountableConnection";
  edges: Array<VisitComplainCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitComplainCountableEdge = {
  __typename?: "VisitComplainCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: VisitComplain;
};

/** creates a new Visit Complain */
export type VisitComplainCreate = {
  __typename?: "VisitComplainCreate";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitComplain?: Maybe<VisitComplain>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitComplainCreateInput = {
  /** customer complain */
  complain: Scalars["String"];
  /** ID of Visit to be Complain about */
  visit: Scalars["ID"];
};

export type VisitComplainFilterInput = {
  visits?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type VisitCountableConnection = {
  __typename?: "VisitCountableConnection";
  edges: Array<VisitCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitCountableEdge = {
  __typename?: "VisitCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Visit;
};

export type VisitDiagnosis = H_Node & {
  __typename?: "VisitDiagnosis";
  code: Scalars["String"];
  display?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  riskTemplate?: Maybe<VisitDiagnosisRiskTemplate>;
  visit?: Maybe<Visit>;
  visitDiagnosisType: VisitDiagnosisType;
};

export type VisitDiagnosisCrud = {
  __typename?: "VisitDiagnosisCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitDiagnosis?: Maybe<VisitDiagnosis>;
  visitDiagnosisErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitDiagnosisRiskTemplate = H_Node & {
  __typename?: "VisitDiagnosisRiskTemplate";
  chronicDiseaseCode?: Maybe<Scalars["String"]>;
  chronicDiseaseSeverity?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
  enrolledGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  id: Scalars["ID"];
  isFilled?: Maybe<Scalars["Boolean"]>;
  isGuidedCareProgramSelected?: Maybe<Scalars["Boolean"]>;
  notification?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
  selectedGuidedCareProgram?: Maybe<GuidedCareHealthProgram>;
  suggestedGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  visitDiagnosis?: Maybe<VisitDiagnosis>;
};

export type VisitDiagnosisRiskTemplateCrud = {
  __typename?: "VisitDiagnosisRiskTemplateCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitDiagnosisRiskTemplate?: Maybe<VisitDiagnosisRiskTemplate>;
  visitDiagnosisRiskTemplateErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitDiagnosisRiskTemplateCountableConnection = {
  __typename?: "VisitDiagnosisRiskTemplateCountableConnection";
  edges: Array<VisitDiagnosisRiskTemplateCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitDiagnosisRiskTemplateCountableEdge = {
  __typename?: "VisitDiagnosisRiskTemplateCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  node: VisitDiagnosisRiskTemplate;
};

export enum VisitDiagnosisType {
  Principal = "PRINCIPAL",
  Secondary = "SECONDARY",
}

/** assign call request to doctor */
export type VisitDoctorAssign = {
  __typename?: "VisitDoctorAssign";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  result?: Maybe<Scalars["String"]>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitDoctorNoteInput = {
  doctorNote?: InputMaybe<Scalars["String"]>;
  visit: Scalars["ID"];
};

export type VisitFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  callRecordStatus?: InputMaybe<CallRecordStatus>;
  careInitialType?: InputMaybe<Array<InputMaybe<CareType>>>;
  careType?: InputMaybe<Array<InputMaybe<VisitCareType>>>;
  chatIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  consumerJoined?: InputMaybe<Scalars["Boolean"]>;
  consumerLeft?: InputMaybe<Scalars["Boolean"]>;
  date?: InputMaybe<H_DateTimeRangeInput>;
  departments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  doctorIsRunningLate?: InputMaybe<Scalars["Boolean"]>;
  doctorJoined?: InputMaybe<Scalars["Boolean"]>;
  doctorLeft?: InputMaybe<Scalars["Boolean"]>;
  doctors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  id?: InputMaybe<Scalars["String"]>;
  nationalIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  patientIsRunningLate?: InputMaybe<Scalars["Boolean"]>;
  patientName?: InputMaybe<Scalars["String"]>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  payPerCallOnly?: InputMaybe<Scalars["Boolean"]>;
  payers?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  requestedBy?: InputMaybe<RequestedBy>;
  selfOnly?: InputMaybe<Scalars["Boolean"]>;
  status?: InputMaybe<Array<InputMaybe<VisitStatus>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  types?: InputMaybe<Array<InputMaybe<VisitType>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  vendorActorIsRunningLate?: InputMaybe<Scalars["Boolean"]>;
  vendorActorJoined?: InputMaybe<Scalars["Boolean"]>;
  vendorActorLeft?: InputMaybe<Scalars["Boolean"]>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type VisitMessage = H_Node & {
  __typename?: "VisitMessage";
  attachments?: Maybe<Array<Maybe<VisitMessageAttachment>>>;
  content?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  isBotMessage?: Maybe<Scalars["Boolean"]>;
  jsonPayload?: Maybe<Scalars["String"]>;
  recipient?: Maybe<User>;
  recipientId?: Maybe<Scalars["Int"]>;
  seenDate?: Maybe<Scalars["Instant"]>;
  sender?: Maybe<User>;
  senderId?: Maybe<Scalars["Int"]>;
  visit?: Maybe<Visit>;
};

export type VisitMessageAttachment = H_Node & {
  __typename?: "VisitMessageAttachment";
  attachment?: Maybe<Attachment>;
  attachmentId?: Maybe<Scalars["Int"]>;
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["ID"];
  message?: Maybe<VisitMessage>;
  modified?: Maybe<Scalars["Instant"]>;
  sortOrder?: Maybe<Scalars["Int"]>;
};

export type VisitMessageCountableConnection = {
  __typename?: "VisitMessageCountableConnection";
  edges: Array<VisitMessageCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitMessageCountableEdge = {
  __typename?: "VisitMessageCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: VisitMessage;
};

export type VisitMessageFilterInput = {
  visits?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type VisitMessageInput = {
  /** message image attachments */
  attachments?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** message text content */
  content?: InputMaybe<Scalars["String"]>;
  /** Message json payload */
  jsonPayload?: InputMaybe<Scalars["String"]>;
  /** visit ID to chat belong to. */
  visit?: InputMaybe<Scalars["ID"]>;
};

export type VisitPrescriptionActivity = {
  __typename?: "VisitPrescriptionActivity";
  code?: Maybe<Scalars["String"]>;
  denialCode?: Maybe<Scalars["String"]>;
  denialDescription?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["String"]>;
  frequencyUnitType?: Maybe<Scalars["String"]>;
  frequencyValue?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  instructions?: Maybe<Scalars["String"]>;
  observation?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Float"]>;
  refills?: Maybe<Scalars["Int"]>;
};

export enum VisitPrescriptionActivityFrequencyType {
  Day = "Day",
  Hour = "Hour",
  Month = "Month",
  Week = "Week",
}

export enum VisitPrescriptionActivityType {
  GenericDrug = "GenericDrug",
  TradeDrug = "TradeDrug",
}

export type VisitPrescriptionTransaction = H_Node & {
  __typename?: "VisitPrescriptionTransaction";
  activities?: Maybe<Array<Maybe<VisitPrescriptionActivity>>>;
  id: Scalars["ID"];
  referenceId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
};

export type VisitPrescriptionTransactionCountableConnection = {
  __typename?: "VisitPrescriptionTransactionCountableConnection";
  edges: Array<VisitPrescriptionTransactionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitPrescriptionTransactionCountableEdge = {
  __typename?: "VisitPrescriptionTransactionCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: VisitPrescriptionTransaction;
};

export enum VisitReferralSortField {
  CreatedDate = "CREATED_DATE",
}

export type VisitReferralSortingInput = {
  direction: ReferralOrderDirection;
  field: VisitReferralSortField;
};

export type VisitReviewInput = {
  questionsValues: Array<InputMaybe<VisitReviewQuestionInput>>;
  /** ID of Visit to be reviewed */
  visit: Scalars["ID"];
};

export type VisitReviewQuestion = H_Node & {
  __typename?: "VisitReviewQuestion";
  created?: Maybe<Scalars["Instant"]>;
  customerId?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  modified?: Maybe<Scalars["Instant"]>;
  question?: Maybe<ReviewQuestion>;
  value?: Maybe<Scalars["Float"]>;
  visit?: Maybe<Visit>;
};

export type VisitReviewQuestionCountableConnection = {
  __typename?: "VisitReviewQuestionCountableConnection";
  edges: Array<VisitReviewQuestionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitReviewQuestionCountableEdge = {
  __typename?: "VisitReviewQuestionCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: VisitReviewQuestion;
};

export type VisitReviewQuestionFilterInput = {
  reviewQuestions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type VisitReviewQuestionInput = {
  question: Scalars["ID"];
  /** question value */
  value: Scalars["Float"];
};

export enum VisitSortField {
  /** Sort visit by created. */
  Created = "CREATED",
  /** Sort visit by date. */
  Date = "DATE",
  /** Sort visit by number. */
  Number = "NUMBER",
}

export type VisitSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort visits by the selected field. */
  field: VisitSortField;
};

export enum VisitStatus {
  Accepted = "ACCEPTED",
  Assigned = "ASSIGNED",
  CallInProgress = "CALL_IN_PROGRESS",
  CallRejoinable = "CALL_REJOINABLE",
  CallTimedOut = "CALL_TIMED_OUT",
  Canceled = "CANCELED",
  CanceledByConsumer = "CANCELED_BY_CONSUMER",
  ChatInProgress = "CHAT_IN_PROGRESS",
  Completed = "COMPLETED",
  DoctorAnswerTimedOut = "DOCTOR_ANSWER_TIMED_OUT",
  EndedByOneOfTheParticipants = "ENDED_BY_ONE_OF_THE_PARTICIPANTS",
  Incompleted = "INCOMPLETED",
  OutPatientJourneyAssessmentDone = "OUT_PATIENT_JOURNEY_ASSESSMENT_DONE",
  OutPatientJourneyAssessmentPending = "OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING",
  OutPatientJourneyCheckedIn = "OUT_PATIENT_JOURNEY_CHECKED_IN",
  OutPatientJourneyConsultationDone = "OUT_PATIENT_JOURNEY_CONSULTATION_DONE",
  OutPatientJourneyConsultationPending = "OUT_PATIENT_JOURNEY_CONSULTATION_PENDING",
  OutPatientJourneyPending = "OUT_PATIENT_JOURNEY_PENDING",
  OutPatientJourneyTreatmentDone = "OUT_PATIENT_JOURNEY_TREATMENT_DONE",
  PaymentFailed = "PAYMENT_FAILED",
  PaymentPending = "PAYMENT_PENDING",
  PaymentSucceeded = "PAYMENT_SUCCEEDED",
  PaymentTimedOut = "PAYMENT_TIMED_OUT",
  Pending = "PENDING",
  Rejected = "REJECTED",
}

export type VisitStatusHistory = H_Node & {
  __typename?: "VisitStatusHistory";
  callId?: Maybe<Scalars["H_Long"]>;
  cancellationReason?: Maybe<Scalars["String"]>;
  chatId?: Maybe<Scalars["H_Long"]>;
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Instant"]>;
  doctor?: Maybe<Scalars["H_Long"]>;
  doctorId?: Maybe<Scalars["Int"]>;
  doctorUserId?: Maybe<Scalars["Int"]>;
  duration?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  lastModifiedBy?: Maybe<Scalars["String"]>;
  lastModifiedDate?: Maybe<Scalars["Instant"]>;
  meetingPlatformLink?: Maybe<Scalars["String"]>;
  meetingPlatformLinkForChatView?: Maybe<Scalars["String"]>;
  meetingPlatformLinkForJoinCall?: Maybe<Scalars["String"]>;
  patientId?: Maybe<Scalars["Int"]>;
  patientUserId?: Maybe<Scalars["Int"]>;
  qualityRating?: Maybe<Scalars["Int"]>;
  refId?: Maybe<Scalars["H_Long"]>;
  status?: Maybe<VisitStatus>;
  vendorId?: Maybe<Scalars["Int"]>;
};

export type VisitStatusHistoryCountableConnection = {
  __typename?: "VisitStatusHistoryCountableConnection";
  edges: Array<VisitStatusHistoryCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitStatusHistoryCountableEdge = {
  __typename?: "VisitStatusHistoryCountableEdge";
  cursor: Scalars["String"];
  node: VisitStatusHistory;
};

export type VisitStatusHistoryFilterInput = {
  doctors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  refIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  selfOnly?: InputMaybe<Scalars["Boolean"]>;
  statuses?: InputMaybe<Array<InputMaybe<VisitStatus>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

/** Visit status update */
export type VisitStatusUpdate = {
  __typename?: "VisitStatusUpdate";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitStatusUpdateInput = {
  callId?: InputMaybe<Scalars["H_Long"]>;
  cancellationReason?: InputMaybe<Scalars["String"]>;
  chatId?: InputMaybe<Scalars["H_Long"]>;
  consumerJoined?: InputMaybe<Scalars["Boolean"]>;
  consumerLeft?: InputMaybe<Scalars["Boolean"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  followupWorkflowId?: InputMaybe<Scalars["String"]>;
  followupWorkflowRunId?: InputMaybe<Scalars["String"]>;
  followupWorkflowType?: InputMaybe<Scalars["String"]>;
  meetingPlatformLink?: InputMaybe<Scalars["String"]>;
  qualityRating?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<VisitStatus>;
  vendorActorJoined?: InputMaybe<Scalars["Boolean"]>;
  vendorActorLeft?: InputMaybe<Scalars["Boolean"]>;
  workflowId?: InputMaybe<Scalars["String"]>;
  workflowRunId?: InputMaybe<Scalars["String"]>;
  workflowType?: InputMaybe<Scalars["String"]>;
};

export type VisitSummary = H_Node & {
  __typename?: "VisitSummary";
  created?: Maybe<Scalars["Instant"]>;
  doctorNotes?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isProblemSolved?: Maybe<Scalars["Boolean"]>;
  modified?: Maybe<Scalars["Instant"]>;
  visit?: Maybe<Visit>;
};

/** CRUD a new Visit Summary. */
export type VisitSummaryCrud = {
  __typename?: "VisitSummaryCRUD";
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitSummary?: Maybe<VisitSummary>;
  visitSummaryErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitSummaryCountableConnection = {
  __typename?: "VisitSummaryCountableConnection";
  edges: Array<VisitSummaryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitSummaryCountableEdge = {
  __typename?: "VisitSummaryCountableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: VisitSummary;
};

export type VisitSummaryFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  visits?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type VisitSummaryInput = {
  doctorNotes?: InputMaybe<Scalars["String"]>;
  isProblemSolved?: InputMaybe<Scalars["Boolean"]>;
  visitId?: InputMaybe<Scalars["String"]>;
};

export type VisitTranscription = Node & {
  __typename?: "VisitTranscription";
  callId: Scalars["String"];
  created: Scalars["DateTime"];
  failureReason?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  modified: Scalars["DateTime"];
  processingStatus: VisitTranscriptionProcessingStatus;
  segments?: Maybe<VisitTranscriptionSegmentsCountableConnection>;
  summarizedText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  visit?: Maybe<Visit>;
};

export type VisitTranscriptionSegmentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type VisitTranscriptionMutation = {
  __typename?: "VisitTranscriptionMutation";
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  transcriptErrors: Array<TranscribeError>;
  visitTranscription?: Maybe<VisitTranscription>;
};

export enum VisitTranscriptionProcessingStatus {
  Failed = "FAILED",
  Pending = "PENDING",
  Processed = "PROCESSED",
  UnderProcess = "UNDER_PROCESS",
}

export type VisitTranscriptionSegments = Node & {
  __typename?: "VisitTranscriptionSegments";
  endTime: Scalars["String"];
  id: Scalars["ID"];
  speaker: Scalars["String"];
  startTime: Scalars["String"];
  text: Scalars["String"];
};

export type VisitTranscriptionSegmentsCountableConnection = {
  __typename?: "VisitTranscriptionSegmentsCountableConnection";
  edges: Array<VisitTranscriptionSegmentsCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type VisitTranscriptionSegmentsCountableEdge = {
  __typename?: "VisitTranscriptionSegmentsCountableEdge";
  cursor: Scalars["String"];
  node: VisitTranscriptionSegments;
};

export enum VisitType {
  DirectAppointment = "DIRECT_APPOINTMENT",
  DirectAtHome = "DIRECT_AT_HOME",
  DirectCall = "DIRECT_CALL",
  DirectInPerson = "DIRECT_IN_PERSON",
  HealthProgram = "HEALTH_PROGRAM",
}

export type Wallet = {
  __typename?: "Wallet";
  balance?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["ID"]>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  refundReason?: Maybe<Scalars["String"]>;
  refundedAfterPayment?: Maybe<Scalars["Boolean"]>;
};

export type WalletPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<PaymentOperationLogSortingInput>;
};

export type Weight = {
  __typename?: "Weight";
  unit: Scalars["String"];
  value: Scalars["Float"];
};

export type WorkingHour = {
  __typename?: "WorkingHour";
  day?: Maybe<DayOfWeekEnum>;
  openTimeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type WorkingHourInput = {
  day: DayOfWeekEnum;
  openTimeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export type WorkingHourOverride = {
  __typename?: "WorkingHourOverride";
  date?: Maybe<Scalars["Date"]>;
  openTimeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type WorkingHourOverrideInput = {
  date: Scalars["Date"];
  openTimeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export type OptionsStatistics = {
  __typename?: "optionsStatistics";
  option?: Maybe<Scalars["String"]>;
  percentage?: Maybe<Scalars["Float"]>;
};

export enum Period {
  HalfYear = "HALF_YEAR",
  Monthly = "MONTHLY",
  ThreeMonths = "THREE_MONTHS",
  Weekly = "WEEKLY",
  Yearly = "YEARLY",
}

export type QuestionsStatistics = {
  __typename?: "questionsStatistics";
  answeredParticipantsCount?: Maybe<Scalars["Int"]>;
  optionsStatistics?: Maybe<Array<Maybe<OptionsStatistics>>>;
  participantsCount?: Maybe<Scalars["Int"]>;
  question?: Maybe<Question>;
};

export type ValidateProcedureResponse = {
  __typename?: "validateProcedureResponse";
  justified: Scalars["Boolean"];
  procedureCode: Scalars["String"];
};
