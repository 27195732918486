import CompletePercentage from "@/components/Progress/CompletePercentage";
import { useTranslation } from "@/i18n/i18n.config";
import { useReactiveVar } from "@apollo/client";
import { Box, Typography, useTheme } from "@toolkit/ui";
import React from "react";
import { userProfileVar } from "../userProfileVar";
import { getColorBaseOnScore, getLifeExpectancyQueryVariablesFilledInputsCount, lifeExpectancyFieldsTotalCount } from "../utils";
import { useProfileCompletedStyles } from "./ProfileCompleted.styles";

export const ProfileCompleted: React.FC = () => {
  const profileData = useReactiveVar(userProfileVar);
  const filledCount = getLifeExpectancyQueryVariablesFilledInputsCount(profileData);
  const value = filledCount / (lifeExpectancyFieldsTotalCount / 100);
  const theme = useTheme();
  const { t } = useTranslation("digital-twin");
  const { classes } = useProfileCompletedStyles();

  return (
    <>
      <Box className={classes.container}>
        <CompletePercentage
          value={value}
          color={getColorBaseOnScore(value, theme)}
          size={140}
          thickness={3}
          renderText={() => (
            <Box className={classes.percentageTextContainer} style={{ color: getColorBaseOnScore(value, theme) }}>
              <Typography component='span' className={classes.percentageValue}>
                {Number(value.toFixed(0))}
                <Typography component='span' className={classes.percentageSymbol}>
                  %
                </Typography>
              </Typography>
              <Typography component='span' className={classes.profileText}>
                {t("Complete")}
              </Typography>
            </Box>
          )}
        />
      </Box>
      <Typography className={classes.wellDoneText}>{t("Well Done!")}</Typography>
      <Box className={classes.descriptionContainer}>
        <Typography className={classes.descriptionText}>
          {t(
            "You’ve successfully filled your profile. We can now offer you even more personalized predictions and insights with your very own digital twin."
          )}
        </Typography>
      </Box>
    </>
  );
};
