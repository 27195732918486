import { SvgIcon, SvgIconProps } from "@toolkit/ui";
import { FC } from "react";

export const ColoredLifeCycleIcon: FC<SvgIconProps> = props => {
  const { width, height, ...rest } = props;
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width={width || "34.6"} height={height || "30.087"} viewBox='0 0 34.6 30.087' {...rest}>
      <g id='Group_15779' data-name='Group 15779' transform='translate(-29.6 -128.552)'>
        <g id='Group_15778' data-name='Group 15778'>
          <g id='Group_15777' data-name='Group 15777'>
            <g id='Group_15776' data-name='Group 15776'>
              <g id='icons8-life-cycle</svg>' transform='translate(29.6 128.552)'>
                <path
                  id='Path_23069'
                  data-name='Path 23069'
                  d='M28.035,30.009A6.816,6.816,0,0,0,22.017,27,6.816,6.816,0,0,0,16,30.009v1.5H28.035Z'
                  transform='translate(-4.717 -9.7)'
                  fill='#607d8b'
                />
                <path
                  id='Path_23070'
                  data-name='Path 23070'
                  d='M25.008,24H22v2.257a1.393,1.393,0,0,0,1.475,1.5,1.456,1.456,0,0,0,1.534-1.5Z'
                  transform='translate(-6.204 -8.957)'
                  fill='#ff9800'
                />
                <path
                  id='Path_23071'
                  data-name='Path 23071'
                  d='M22.761,23.522a3.761,3.761,0,1,1,3.761-3.761,3.764,3.764,0,0,1-3.761,3.761'
                  transform='translate(-5.461 -6.974)'
                  fill='#ffb74d'
                />
                <path
                  id='Path_23072'
                  data-name='Path 23072'
                  d='M22.6,14A3.865,3.865,0,0,0,19,18.193v1.073l.752.752V17.761L24.5,16.107l1.272,1.654v2.257l.752-.752v-.9c0-1.816-.1-3.161-2.257-3.608L23.9,14Z'
                  transform='translate(-5.461 -6.478)'
                  fill='#424242'
                />
                <path
                  id='Path_23073'
                  data-name='Path 23073'
                  d='M19.056,4A15.031,15.031,0,0,0,4.05,18.291H6.307a12.776,12.776,0,0,1,24.474-4.345l2.073-.891A15.043,15.043,0,0,0,19.056,4Z'
                  transform='translate(-1.756 -4)'
                  fill='#2196f3'
                />
                <path
                  id='Path_23074'
                  data-name='Path 23074'
                  d='M4.385,25.761,7.77,22H1ZM18.3,38.548A15.031,15.031,0,0,0,33.306,24.257H31.049A12.776,12.776,0,0,1,6.575,28.6L4.5,29.492A15.043,15.043,0,0,0,18.3,38.548Z'
                  transform='translate(-1 -8.461)'
                  fill='#2196f3'
                />
                <path
                  id='Path_23075'
                  data-name='Path 23075'
                  d='M41.385,21,38,24.761h6.77Z'
                  transform='translate(-10.17 -8.213)'
                  fill='#2196f3'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
