import { BackButton, CardWithTitle, ExpandableList, ListCategory, ListCategoryWithItems } from "@/components";
import { useHeaderContext } from "@/providers";
import { routesPaths } from "@/routing/routesPaths";
import { i18n } from "@toolkit/i18n";
import { Box } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCalculatorsMenu } from "./constants";
import { useStyles } from "./styles";

export const CalculatorsMenu: React.FC<{
  defaultExpanded?: ListCategory["id"][];
}> = ({ defaultExpanded }) => {
  const navigate = useNavigate();
  const { setHeader, resetHeader, setIcon } = useHeaderContext();

  const _calculators: ListCategoryWithItems[] = getCalculatorsMenu().map(item => {
    return {
      ...item,
    } as ListCategoryWithItems;
  });
  const { classes } = useStyles();
  useEffect(() => {
    setHeader(i18n.t("Calculators", { ns: "digital-twin" }));
    setIcon(<BackButton onClick={() => navigate(routesPaths.myDigitalTwin)} />);
    return () => {
      resetHeader();
    };
  }, [i18n.language]);

  return (
    <Box sx={{ padding: "10px", height: "max-content", minHeight: "100%" }}>
      <CardWithTitle title={i18n.t("Discover more Calculators", { ns: "digital-twin" })}>
        <ExpandableList defaultExpanded={defaultExpanded} categories={_calculators} classes={classes} />
      </CardWithTitle>
    </Box>
  );
};
