import { makeStyles } from "@toolkit/ui";

export const useProfileCompletedStyles = makeStyles()(theme => ({
  container: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
  },
  percentageTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
    fontSize: theme.mixins.fonts.fontSize.xs,
    position: "absolute",
  },
  percentageValue: {
    padding: 0,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontSize: 35,
  },
  percentageSymbol: {
    verticalAlign: "super",
    display: "inline",
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
  profileText: {
    marginTop: "-10px",
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  wellDoneText: {
    marginTop: "30px",
    marginBottom: "30px",
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  descriptionContainer: {
    display: "flex",
    justifyContent: "center",
  },
  descriptionText: {
    width: "290px",
    paddingInline: "10px",
    lineHeight: 1.5,
    fontWeight: theme.mixins.fonts.fontWeight.regular,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
