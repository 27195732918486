import { makeStyles } from "@toolkit/ui";

export const useCalculatorNutritionStepResultStyle = makeStyles()(() => ({
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 400,
  },
}));
