import React from "react";
import { Box, BoxProps, useTheme } from "@toolkit/ui";
interface ValueLabelComponentProps extends BoxProps {
  value: number;
  unit?: string;
  labelProps?: BoxProps;
}

export const ValueLabelComponent: React.FC<ValueLabelComponentProps> = ({ value, unit = "", labelProps, ...boxProps }) => {
  const theme = useTheme();
  return (
    <Box sx={{ direction: "ltr" }} display='flex' flexDirection='row' gap={"3px"} alignItems='center' {...boxProps}>
      <Box
        sx={{
          fontSize: theme.mixins.fonts.fontSize.md,
          fontWeight: theme.mixins.fonts.fontWeight.bold,
        }}
      >
        {value}
      </Box>
      <Box
        {...labelProps}
        sx={{
          fontSize: theme.mixins.fonts.fontSize.sm,
        }}
      >
        {unit}
      </Box>
    </Box>
  );
};
