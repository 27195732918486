import { ArticleListPage } from "@/pages/Articles/ArticleListPage";
import { ArticleDetailsPage } from "@/pages/Articles/ArticleDetailsPage";

export const baseArticlesRoute = "/articles";

export const articleRoutes = {
  articleList: {
    route: "/list",
    component: ArticleListPage,
    queryParams: "articleCategory",
    get absRoute() {
      return `${baseArticlesRoute}${this.route}`;
    },
    getRouteWithParams({ articleCategory }: { articleCategory?: string }) {
      return `${this.absRoute}?articleCategory=${articleCategory}`;
    },
  },
  articleDetails: {
    route: "/details/:articleId",
    component: ArticleDetailsPage,
    get absRoute() {
      return `${baseArticlesRoute}${this.route}`;
    },
    getRouteWithParams({ articleId }: { articleId: string }) {
      return `${this.absRoute}`?.replace(":articleId", articleId);
    },
  },
};
