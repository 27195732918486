import { z } from "zod";
import { ActivityLevel, Gender } from "@/schema/types.ts";

export const calculatorNutritionFormSchema = z.object({
  age: z.number().min(0).max(120),
  gender: z.nativeEnum(Gender),
  height: z.number().min(0).max(250),
  weight: z.number().min(0).max(300),
  activityLevel: z.nativeEnum(ActivityLevel),
  goalWeight: z.number().min(0).max(300).nullable().optional(),
  days: z.number().min(1).max(300).nullable().optional(),
  readyToChangeActivityLevel: z.boolean().nullable().optional(),
  newActivityLevel: z.nativeEnum(ActivityLevel).nullable().optional(),
});

export type ICalculatorNutritionFormValues = z.infer<typeof calculatorNutritionFormSchema>;
