import { routesPaths } from "../../routing/routesPaths";
import i18n from "../../i18n/i18n.config";
import { sideMenuDrawerOpenStateVar } from "./sideMenuDrawerOpenStateVar";
import { ArabicIcon, CalculatorIcon, DiseaseIcon, HeartHealthIcon, HelpIcon, HomeIcon, RobotIcon, SettingIcon } from "../Icons";

interface MenuItem {
  text: string;
  link?: string;
  icon: React.ComponentType;
  onclick?: () => void;
}

type MenuSection = MenuItem[];

function getDrawerClickHandler(fn: () => void) {
  return function () {
    fn();
    sideMenuDrawerOpenStateVar(false);
  };
}

export function getMenuItems(_i18n: typeof i18n): MenuSection[] {
  return [
    [{ text: _i18n.t("Home", { ns: "digital-twin" }), link: routesPaths.home, icon: HomeIcon }],
    [
      {
        text: _i18n.t("My Digital Twin", { ns: "digital-twin" }),
        link: routesPaths.myDigitalTwin + "?rand=" + Math.random(),
        icon: RobotIcon,
      },
      {
        text: _i18n.t("Disease and risk management", { ns: "digital-twin" }),
        link: routesPaths.diseaseAndRiskManagement,
        icon: DiseaseIcon,
      },
      { text: _i18n.t("Health And Well Being", { ns: "digital-twin" }), link: routesPaths.healthAndWellBeing, icon: HeartHealthIcon },
      {
        text: _i18n.t("Age Dynamics and calculators", { ns: "digital-twin" }),
        link: routesPaths.ageDynamicsAndCalculators,
        icon: CalculatorIcon,
      },
    ],
    [
      {
        text: _i18n.t("Change Language", { ns: "digital-twin" }),
        onclick: getDrawerClickHandler(() => {
          if (_i18n.language.startsWith("en")) {
            _i18n.changeLanguage("ar");
          } else {
            _i18n.changeLanguage("en");
          }
          // location.reload();
        }),
        icon: ArabicIcon,
        link: undefined,
      },
      { text: _i18n.t("get help", { ns: "digital-twin" }), link: routesPaths.getHelp, icon: HelpIcon },
      { text: _i18n.t("Account Settings", { ns: "digital-twin" }), link: routesPaths.accountSettings, icon: SettingIcon },
    ],
  ];
}
