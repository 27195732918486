import { CardWithTitle } from "@/components";
import { useTranslation } from "@/i18n/i18n.config";
import { Box, CardMedia } from "@toolkit/ui";
import { ProfileCompleted } from "../../ProfileCompleted/ProfileCompleted";

import { routesPaths } from "@/routing/routesPaths";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneratingDigitalTwinInProgressCountDownPageStylesStyles } from "./GeneratingDigitalTwinInProgressCountDownPageStyles";
import { useLifeExpectancyQuery } from "@/gql/queries";
import { getLifeExpectancyQueryInput } from "../../utils";
import { userProfileVar } from "../../userProfileVar";

export const GeneratingDigitalTwinInProgressCountDownPage: React.FC = () => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useGeneratingDigitalTwinInProgressCountDownPageStylesStyles();
  const [countDown, setCountDown] = useState(6);
  const intervalRef = useRef<NodeJS.Timer>();
  const navigate = useNavigate();

  const { loading } = useLifeExpectancyQuery({
    variables: {
      input: getLifeExpectancyQueryInput(userProfileVar()),
    },
  });
  console.log(loading);

  useEffect(() => {
    intervalRef.current = setTimeout(() => {
      if (countDown === 0) {
        clearTimeout(intervalRef.current);
        navigate(routesPaths.myDigitalTwinHome);
        return;
      }
      setCountDown(c => c - 1);
    }, 1000);
  }, [countDown]);
  const generateVideo = "https://objectstorage.me-dubai-1.oraclecloud.com/n/axjdg8m7rpbd/b/public-assets/o/ezgif-3-062b2dbcdd.gif";

  return (
    <CardWithTitle
      title={t("Generating My Digital Twin")}
      className={classes.card}
      titleProps={{
        className: classes.title,
      }}
    >
      <ProfileCompleted />
      <Box className={classes.videoBox}>
        <CardMedia className={classes.cardMedia} component='img' src={generateVideo} />
      </Box>
    </CardWithTitle>
  );
};
