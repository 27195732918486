/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeeAndTDeeQueryVariables = Types.Exact<{
  input: Types.BeeAndTdeeInput;
  saveData?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type BeeAndTDeeQuery = { __typename?: 'Query', beeAndtdee?: { __typename?: 'BEEAndTDEEResponse', bee?: number | null, tdee?: number | null } | null };


export const BeeAndTDeeDocument = gql`
    query BeeAndTDee($input: BEEAndTDEEInput!, $saveData: Boolean) {
  beeAndtdee(input: $input, saveData: $saveData) {
    bee
    tdee
  }
}
    `;

/**
 * __useBeeAndTDeeQuery__
 *
 * To run a query within a React component, call `useBeeAndTDeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeeAndTDeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeeAndTDeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *      saveData: // value for 'saveData'
 *   },
 * });
 */
export function useBeeAndTDeeQuery(baseOptions: Apollo.QueryHookOptions<BeeAndTDeeQuery, BeeAndTDeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeeAndTDeeQuery, BeeAndTDeeQueryVariables>(BeeAndTDeeDocument, options);
      }
export function useBeeAndTDeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeeAndTDeeQuery, BeeAndTDeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeeAndTDeeQuery, BeeAndTDeeQueryVariables>(BeeAndTDeeDocument, options);
        }
export type BeeAndTDeeQueryHookResult = ReturnType<typeof useBeeAndTDeeQuery>;
export type BeeAndTDeeLazyQueryHookResult = ReturnType<typeof useBeeAndTDeeLazyQuery>;
export type BeeAndTDeeQueryResult = Apollo.QueryResult<BeeAndTDeeQuery, BeeAndTDeeQueryVariables>;