import { makeStyles } from "@toolkit/ui";

type CalculatorNutritionResultStyleProps = {
  size: number;
  spacing: number;
};

export const useCalculatorNutritionResultStyle = makeStyles<CalculatorNutritionResultStyleProps>()((theme, params) => ({
  root: {
    marginBlock: 16,
    padding: 32,
    border: `1px solid ${theme.palette.stale[300]}`,
  },
  wrapper: {
    position: "relative",
    display: "inline-flex",
  },
  tdeeCircularProgress: {
    color: theme.palette.primary.main,
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 ${(2 / 44) * params.size}px ${theme.palette.stale[200]}`,
  },
  beeCircularProgress: {
    position: "absolute",
    top: params.spacing,
    left: params.spacing,
    color: theme.palette.primary[500],
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 ${(2 / 44) * (params.size - params.spacing * 2)}px ${theme.palette.stale[200]}`,
  },
  targetCaloriesCircularProgress: {
    position: "absolute",
    top: params.spacing * 2,
    left: params.spacing * 2,
    color: theme.palette.success.main,
    borderRadius: "50%",
    boxShadow: `inset 0 0 0 ${(2 / 44) * (params.size - params.spacing * 4)}px ${theme.palette.stale[200]}`,
  },
  circularProgressText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    transform: "translate(-50%, -50%)",
  },
  chipsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 8,
    marginTop: 8,
  },
}));
