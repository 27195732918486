import { useState } from "react";
export type IModel = {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
};

export const useModal = (defaultOpen = false): IModel => {
  const [open, setOpen] = useState(defaultOpen || false);

  const handleToggle = (): void => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleToggle,
  };
};
