import { GlucometerIcon } from "@/components/Icons/GlucometerIcon";
import { ObesityIcon } from "@/components/Icons/ObesityIcon";
import { routesPaths } from "@/routing/routesPaths";
import { DiabetesRisk } from "@/schema/types";
import { Link } from "react-router-dom";
import { BrainIcon, HeartIcon } from "../../components/Icons";
import i18n from "../../i18n/i18n.config";
import { userProfileVar } from "../MyDigitalTwinProfile/userProfileVar";
import { Theme } from "@toolkit/ui";

export const getOrganRisks = (theme: Theme) => [
  {
    isDiseaseRisk: true,
    get color() {
      return this.isHighRisk ? theme.palette.error.main : theme.palette.success.main;
    },
    icon: (
      <Link to={routesPaths.ageDynamicsAndCalculators + "?route=cardiovascular"}>
        <HeartIcon />
      </Link>
    ),
    bodyString: i18n.t("Heart Risk", { ns: "digital-twin" }),
    get isHighRisk() {
      return this.value >= 50;
    },
    get bodyOrganRisk() {
      if (userProfileVar().heartRiskResult === undefined) {
        return i18n.t("incomplete", { ns: "digital-twin" });
      }
      return this.isHighRisk ? i18n.t("High", { ns: "digital-twin" }) : i18n.t("Low", { ns: "digital-twin" });
    },
    get value() {
      return Math.min(userProfileVar().heartRiskResult || 0, 100);
    },
  },
  {
    isDiseaseRisk: true,
    get color() {
      return this.isHighRisk ? theme.palette.error.main : theme.palette.success.main;
    },
    icon: (
      <Link to={routesPaths.ageDynamicsAndCalculators + "?route=diabetes"}>
        <GlucometerIcon />
      </Link>
    ),
    bodyString: i18n.t("Diabetes Risk", { ns: "digital-twin" }),
    get bodyOrganRisk() {
      if (userProfileVar().diabetesRisk === undefined) {
        return i18n.t("incomplete", { ns: "digital-twin" });
      }
      return this.isHighRisk ? i18n.t("High", { ns: "digital-twin" }) : i18n.t("Low", { ns: "digital-twin" });
    },
    get isHighRisk() {
      return userProfileVar().diabetesRisk === DiabetesRisk.High;
    },
    get value() {
      console.log(userProfileVar());
      return Math.min(userProfileVar().diabetesRiskScore || 0, 100) * 10;
    },
  },
  {
    isWellBeing: true,
    get color() {
      return this.isHighRisk ? theme.palette.error.main : theme.palette.success.main;
    },
    icon: (
      <Link to={routesPaths.ageDynamicsAndCalculators + "?route=mental-health"}>
        <BrainIcon />
      </Link>
    ),
    bodyString: i18n.t("Mental Health Risk", { ns: "digital-twin" }),
    get bodyOrganRisk() {
      if (userProfileVar().depressionRiskScore === undefined || userProfileVar().anxietyRiskScore === undefined) {
        return i18n.t("incomplete", { ns: "digital-twin" });
      }
      return this.isHighRisk ? i18n.t("High", { ns: "digital-twin" }) : i18n.t("Low", { ns: "digital-twin" });
    },
    get isHighRisk() {
      return this.value >= 50;
    },
    get value() {
      const results = (userProfileVar().depressionRiskScore || 0) + (userProfileVar().anxietyRiskScore || 0);
      return (results * 100) / 12;
    },
  },
  {
    isWellBeing: true,
    get color() {
      return this.isHighRisk ? theme.palette.error.main : theme.palette.success.main;
    },
    icon: (
      <Link to={routesPaths.ageDynamicsAndCalculators + "?route=nutrition"}>
        <ObesityIcon />
      </Link>
    ),
    bodyString: i18n.t("Obesity Risk", { ns: "digital-twin" }),
    get bodyOrganRisk() {
      if (userProfileVar().bmi === undefined) {
        return i18n.t("incomplete", { ns: "digital-twin" });
      }
      return this.isHighRisk ? i18n.t("High", { ns: "digital-twin" }) : i18n.t("Low", { ns: "digital-twin" });
    },
    get isHighRisk() {
      console.log(this.value);
      return this.value > 0 && (this.value > 25 || this.value < 18.5);
    },
    get value() {
      return Math.min(userProfileVar().bmi || 0, 100);
    },
  },
];
