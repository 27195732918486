import { Box, Card } from "@toolkit/ui";
import { useLifeExpectancyFormCardStyles } from "./LifeExpectancyFormCard.styles";
import { lifeExpectancyTabs } from "./lifeExpectancyTabs";

type LifeExpectancyFormCardProps = {
  index?: number;
  activeButton: () => void;
};

export const LifeExpectancyFormCard: React.FC<LifeExpectancyFormCardProps> = ({ activeButton, index = 0 }) => {
  const { classes } = useLifeExpectancyFormCardStyles();

  return (
    <Card className={classes.card}>
      <Box className={classes.tabContainer}>
        {lifeExpectancyTabs.map((tab, idx) => (
          <Box key={tab?.label} className={`${classes.tab} ${idx === index ? "active" : "inactive"}`}>
            {tab.label}
          </Box>
        ))}
      </Box>
      <Box className={classes.content}>{lifeExpectancyTabs[index].render(activeButton)}</Box>
    </Card>
  );
};
