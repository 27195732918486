import { Typography, Box, Button } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "../../i18n/i18n.config";
import { useJoinCareStyles } from "./JoinCare.styles";

export const JoinCare: FC = () => {
  const { classes } = useJoinCareStyles();
  const { t } = useTranslation("digital-twin");

  return (
    <>
      <Typography className={classes.joinCareTitle}>{t("Join care program")}</Typography>
      <Typography className={classes.joinCareSubtitle}>{t("To manage your high-risk conditions")}</Typography>
      <Box className={classes.joinCareContainer}>
        <Button className={classes.joinCareButton}>{t("Join Now!")}</Button>
      </Box>
    </>
  );
};
