import { localStorageKeys } from "@/configs/local-storage-keys";
import { ActivityLevel, DietQuality, EducationLevel, Gender, LifeExpectancyInput, RiskLevel, SmokingHabit } from "@/schema/types";
import { Theme } from "@toolkit/ui";

export const getColorBaseOnScore = (score: number, theme: Theme) => {
  if (score === 0) return theme.palette.primary[1600];
  if (score < 66) return theme.palette.error.main;
  if (score < 99) return theme.palette.warning.main;
  else return theme.palette.success.main;
};

export const digitalTwinUserProfileData = localStorage.getItem(localStorageKeys.digitalTwinUserProfile);

export const setDigitalTwinUserProfileToLocalStorage = (profile: unknown) =>
  localStorage.setItem(localStorageKeys.digitalTwinUserProfile, JSON.stringify(profile));

export const removeDigitalTwinUserProfileToLocalStorage = () => localStorage.removeItem(localStorageKeys.digitalTwinUserProfile);

export const getLifeExpectancyQueryInput = (data: { [x: string]: unknown } = {}): LifeExpectancyInput => {
  return {
    age: data?.age as number,
    dietQuality: data.dietQuality as DietQuality,
    educationLevel: data.educationLevel as EducationLevel,
    gender: data.gender as Gender,
    physicalActivity: data.physicalActivity as ActivityLevel,
    smokingHabit: data.smokingHabit as SmokingHabit,
    stressLevel: data.stressLevel as RiskLevel,
  };
};

export const getLifeExpectancyQueryVariablesFilledInputsCount = (data: { [x: string]: unknown }) => {
  const filledInputs = getLifeExpectancyQueryInput(data);
  return Object.values(filledInputs).reduce<number>((acc, value) => (value !== undefined && value !== null ? acc + 1 : acc), 0);
};
export const lifeExpectancyFieldsTotalCount = 7;
