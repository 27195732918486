import { makeStyles } from "@toolkit/ui";

export const useCalculatorNutritionFormStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  fieldsWrapper: {
    flex: 1,
  },
  completePlanButton: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));
