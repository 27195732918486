import { Box, Typography, useTheme } from "@toolkit/ui";
import { useTranslation } from "../../../i18n/i18n.config";

export const SignupButton = () => {
  const theme = useTheme();
  const { t } = useTranslation("digital-twin");

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography component='span' fontSize={theme.mixins.fonts.fontSize.lg}>
        {t("Don't have an account?")}
      </Typography>
      <Typography component='span' marginInlineStart='4px' color={theme.palette.primary.main} fontWeight={"medium"}>
        {t("Signup")}
      </Typography>
    </Box>
  );
};
