import { makeStyles } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useBMISliderStyles = makeStyles()(theme => ({
  sliderContainer: {
    position: "relative",
    width: "100%",
    height: "70px",
  },
  labelContainer: {
    height: "20px",
    width: "100%",
    display: "flex",
    fontSize: theme.mixins.fonts.fontSize.xxs,
    color: theme.palette.common.black,
    position: "absolute",
    top: "0px",
  },
  labelUnderweight: {
    flex: 1.42,
  },
  labelHealthWeight: {
    flex: 5.4,
  },
  labelOverweight: {
    flex: 2,
  },
  labelObese: {
    flex: 1.16,
  },
  valueLabel: {
    marginTop: "42px",
    background: "transparent",
    color: theme.palette.common.black,
    position: "relative",
    border: "unset",
    "& svg": {
      position: "absolute",
      top: "6px",
      left: "-8px",
      width: "16px",
      height: "16px",
    },
  },
  sliderMark: {
    color: theme.palette.common.white,
    opacity: 0.9,
    border: "2px solid",
    borderColor: theme.palette.warning[100],
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  sliderMarkLabel: {
    color: theme.palette.text.primary,
    marginTop: "-7px",
    marginLeft: "5px",
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  sliderRail: {
    "--min-v": 15,
    "--max-v": 34,
    "--range": "calc( var(--max-v) - var(--min-v) )",
    "--v-underweight-min": "calc( 18.5 - var(--min-v) )",
    "--v-danger-weight-min": "calc( ( var(--v-underweight-min) - var(--min-v) ) / 2 )",
    "--v-healthy-min": "calc( 18.5 - var(--min-v) )",
    "--v-overweight-min": "calc( 24.9 - var(--min-v) )",
    "--v-obese-min": "calc( 29.9 - var(--min-v) )",
    "--v-danger-percentage-min": "calc( var(--v-danger-weight-min) / ( var(--range) ) * 100% )",
    "--v-underweight-percentage-min": "calc( var(--v-underweight-min) / ( var(--range) ) * 100% )",
    "--v-healthy-percentage-min": "calc( var(--v-healthy-min) / ( var(--range) ) * 100% )",
    "--v-overweight-percentage-min": "calc( var(--v-overweight-min) / ( var(--range) ) * 100% )",
    "--v-obese-percentage-min": "calc( var(--v-obese-min) / ( var(--range) ) * 100% )",
    opacity: 1,
    background: "hsla(0, 91%, 55%, 1)",
    backgroundImage: `linear-gradient(${pickLocalizedValue(
      "90deg",
      "-90deg"
    )}, hsla(0, 91%, 55%, 1) 0%, hsla(8, 87%, 53%, 1) var(--v-underweight-percentage-min),
       hsla(34, 83%, 52%, 1)  var(--v-underweight-percentage-min), hsla(30, 80%, 52%, 1) var(--v-healthy-percentage-min),
       hsla(69, 94%, 48%, 1) var(--v-healthy-percentage-min), hsla(69, 94%, 48%, 1) calc( var(--v-overweight-percentage-min) - 2%),
       hsla(70, 93%, 48%, 1) calc( var(--v-overweight-percentage-min) - 3%), hsla(66, 90%, 46%, 1) var(--v-overweight-percentage-min),
       hsla(41, 79%, 49%, 1) var(--v-overweight-percentage-min), hsla(33, 82%, 52%, 1) var(--v-obese-percentage-min),
       hsla(5, 90%, 55%, 1) calc( var(--v-obese-percentage-min) + 3% ), hsla(0, 100%, 50%, 1) 100%)`,
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#F52424", endColorstr="#EF3D21", GradientType=1)',
  },
}));
