import { makeStyles } from "@toolkit/ui";

export const useFitnessProfileStyles = makeStyles()(theme => ({
  statsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "42px",
    marginTop: "30px",
  },
  statsItemContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingInline: "30px",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.primary[50],
    padding: "10px",
    marginTop: "10px",
  },
  recommendedAction: {
    marginTop: "15px",
    fontSize: theme.mixins.fonts.fontSize.lg,
    color: theme.palette.text.primary,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  recommendation: {
    fontSize: theme.mixins.fonts.fontSize.md,
    color: theme.palette.text.secondary,
  },
}));
