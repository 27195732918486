import { useIdealBodyWeightLazyQuery } from "@/gql/queries";
import { useTranslation } from "@/i18n/i18n.config";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { DefaultFooter } from "../CalculatorPageDefaultFooter";
import { CalculatorsPageLayout } from "../CalculatorsPageLayout";
import {
  IdealBodyWeightCalculatorForm,
  IIdealBodyWeightCalculatorFormRef,
  IIdealBodyWeightCalculatorFormZodSchemaType,
} from "./ideal-body-weight-calculator-form/IdealBodyWeightCalculatorForm";
import { IdealBodyWeightResultCard } from "./ideal-body-weight-results/IdealBodyWeightResultCard";
import { useNavigate } from "react-router-dom";
import { routesPaths } from "@/routing/routesPaths";

export const IdealBodyWeightCalculatorPage = () => {
  const { t } = useTranslation("digital-twin");
  const ref = useRef<IIdealBodyWeightCalculatorFormRef>(null);
  const navigate = useNavigate();
  const doSubmit = debounce(() => ref.current?.handleSubmit(), 500);
  const [fetchIdealBodyWeight, { loading, data }] = useIdealBodyWeightLazyQuery({});
  const weight = data?.idealBodyWeight?.idealBodyWeight;
  const handleSubmit = (data: IIdealBodyWeightCalculatorFormZodSchemaType) => {
    fetchIdealBodyWeight({
      variables: {
        input: {
          gender: data.gender,
          height: data.height,
        },
      },
    });
  };
  const values = ref.current?.formMethods.watch();
  useEffect(() => {
    doSubmit();
  }, [values]);

  return (
    <CalculatorsPageLayout
      title={t("Ideal Body Weight Calculator")}
      footer={
        <DefaultFooter
          isSubmitDisabled={!ref.current?.formMethods.formState.isValid || loading}
          handleSubmit={() => navigate(routesPaths.myDigitalTwin)}
        />
      }
    >
      <IdealBodyWeightResultCard isDataReady={weight !== undefined} weight={weight ?? 0} isLoading={loading} />
      <IdealBodyWeightCalculatorForm ref={ref} onSubmit={handleSubmit} onChange={() => ref.current?.handleSubmit()} />
    </CalculatorsPageLayout>
  );
};
