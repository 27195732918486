/* eslint-disable */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IdealSleepCalculationQueryVariables = Types.Exact<{
  input: Types.SleepInput;
  saveData?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type IdealSleepCalculationQuery = { __typename?: 'Query', sleep?: { __typename?: 'SleepResponse', time?: any | null, sleepCycles?: number | null } | null };


export const IdealSleepCalculationDocument = gql`
    query IdealSleepCalculation($input: SleepInput!, $saveData: Boolean) {
  sleep(input: $input, saveData: $saveData) {
    time
    sleepCycles
  }
}
    `;

/**
 * __useIdealSleepCalculationQuery__
 *
 * To run a query within a React component, call `useIdealSleepCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdealSleepCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdealSleepCalculationQuery({
 *   variables: {
 *      input: // value for 'input'
 *      saveData: // value for 'saveData'
 *   },
 * });
 */
export function useIdealSleepCalculationQuery(baseOptions: Apollo.QueryHookOptions<IdealSleepCalculationQuery, IdealSleepCalculationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdealSleepCalculationQuery, IdealSleepCalculationQueryVariables>(IdealSleepCalculationDocument, options);
      }
export function useIdealSleepCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdealSleepCalculationQuery, IdealSleepCalculationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdealSleepCalculationQuery, IdealSleepCalculationQueryVariables>(IdealSleepCalculationDocument, options);
        }
export type IdealSleepCalculationQueryHookResult = ReturnType<typeof useIdealSleepCalculationQuery>;
export type IdealSleepCalculationLazyQueryHookResult = ReturnType<typeof useIdealSleepCalculationLazyQuery>;
export type IdealSleepCalculationQueryResult = Apollo.QueryResult<IdealSleepCalculationQuery, IdealSleepCalculationQueryVariables>;