import { Box, List, ListItem, ListItemText, MuiDrawer, Divider, ListItemIcon, useMediaQuery, useTheme } from "@toolkit/ui";
import { getMenuItems } from "./menuItems";
import { Link, useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { sideMenuDrawerOpenStateVar } from "./sideMenuDrawerOpenStateVar";
import { useSideMenuStyles } from "./SideMenu.styles";
import { PreList } from "./PreLoginList/PreLoginList";
import { PostLoginList } from "./PostLoginList/PostLoginList";
import React from "react";
import { i18n } from "@toolkit/i18n";

const SideMenuDrawer = () => {
  const isOpen = useReactiveVar(sideMenuDrawerOpenStateVar);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClose = () => sideMenuDrawerOpenStateVar(false);
  const { classes } = useSideMenuStyles();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  const menuItems = getMenuItems(i18n);

  const handleLinkClick = (link: string) => {
    handleClose();
    navigate(link);
  };
  return (
    <MuiDrawer
      classes={{ paper: classes.paper, root: classes.root }}
      anchor='left'
      open={isOpen}
      onClose={handleClose}
      variant={!media ? "persistent" : "temporary"}
      disableEnforceFocus
    >
      <Box className={classes.menuContainer}>
        <PreList />
        <Box className={classes.listContainer}>
          <List>
            {menuItems.map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                {section.map((item, itemIndex) => (
                  <ListItem
                    disabled={sectionIndex === 2 && itemIndex >= 1}
                    key={item.text}
                    component={item.link && !(sectionIndex === 2 && itemIndex >= 1) ? Link : "div"}
                    to={item.link ? item.link : undefined}
                    onClick={item?.onclick ?? handleLinkClick.bind(null, item.link!)}
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item.text} />
                  </ListItem>
                ))}
                {sectionIndex !== menuItems?.length - 1 && <Divider className={classes.divider} />}
              </React.Fragment>
            ))}
          </List>
        </Box>
        <PostLoginList />
      </Box>
    </MuiDrawer>
  );
};

export default SideMenuDrawer;
