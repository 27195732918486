import { FC, PropsWithChildren } from "react";
import { Box, Button, IconButton, MuiArrowBackIosNewIcon, MuiArrowForwardIosIcon, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { isInSehhatiApp } from "@/configs/sehhati-app/sehhati-app-utils";
import { useCalculatorPageLayoutStyle } from "./useCalculatorPageLayoutStyle";

type CalculatorPageLayoutProps = PropsWithChildren<{
  title: string;
  includeBackButton?: boolean;
  onBackIconClick: () => void;
}>;

export const CalculatorPageLayout: FC<CalculatorPageLayoutProps> = props => {
  const { title, includeBackButton = true, onBackIconClick } = props;

  const { t } = useTranslation("digital-twin");

  const { classes } = useCalculatorPageLayoutStyle();

  return (
    <Box className={classes.root}>
      {!isInSehhatiApp() && (
        <Box className={classes.header}>
          <IconButton onClick={onBackIconClick}>
            {pickLocalizedValue(<MuiArrowBackIosNewIcon fontSize={"small"} />, <MuiArrowForwardIosIcon fontSize={"small"} />)}
          </IconButton>

          <Box className={classes.titleWrapper}>
            <Typography className={classes.title}>{title}</Typography>
          </Box>
        </Box>
      )}

      <Box className={classes.body}>{props.children}</Box>

      {includeBackButton && (
        <Box>
          <Button fullWidth className={classes.footerButton} onClick={onBackIconClick}>
            {t("Back")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
