import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { getLifeExpectancyQueryVariablesFilledInputsCount, lifeExpectancyFieldsTotalCount } from "@/domains/MyDigitalTwinProfile/utils";
import { routesPaths } from "@/routing/routesPaths";
import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 *
 * this page do redirect to most appropriate page based on user profile
 */
export const MyDigitalTwin = () => {
  const _formField = useReactiveVar(userProfileVar);
  const navigate = useNavigate();
  console.log({ _formField });
  const formFilledCount = getLifeExpectancyQueryVariablesFilledInputsCount(_formField);
  useEffect(() => {
    if (formFilledCount >= lifeExpectancyFieldsTotalCount) navigate(routesPaths.myDigitalTwinHome);
    else navigate(routesPaths.myDigitalTwinIncomplete);
  }, []);
  if (formFilledCount >= lifeExpectancyFieldsTotalCount) navigate(routesPaths.myDigitalTwinHome);
  else navigate(routesPaths.myDigitalTwinIncomplete);
  return <></>;
};
