import { getEnvVariable } from "@health/env";
import { initializeI18nConfig } from "@toolkit/i18n";
import _ from "lodash";
import React from "react";
import App from "./App";
import { setUpAppFromParams } from "./configs/sehhati-app/sehhatiAppSetup";
import { createRoot } from "react-dom/client";
import "./index.css";

const maxWidth = Math.min(window.innerWidth, 1920); // Set a reasonable cap or use actual width
document.documentElement.style.setProperty("--max-device-width", `${maxWidth}px`);
document.documentElement.style.setProperty("--device-width", `${window.innerWidth}px`);

window.addEventListener(
  "resize",
  _.debounce(() => {
    document.documentElement.style.setProperty("--device-width", `${window.innerWidth}px`);
  }, 300)
);

async function setupApp() {
  try {
    await setUpAppFromParams();
    initializeI18nConfig({
      enableTolgeeWizard: getEnvVariable("ENABLE_TOLGEE_WIZARD", process.env.ENABLE_TOLGEE_WIZARD!),
      tolgeeProjectID: getEnvVariable("TOLGEE_PROJECT_ID", process.env.TOLGEE_PROJECT_ID!),
      apiUrl: getEnvVariable("TOLGEE_API_URL", process.env.TOLGEE_API_URL!),
      apiKey: getEnvVariable("TOLGEE_API_KEY", process.env.TOLGEE_API_KEY!),
    });
  } catch (e) {
    console.log("error here ");
  }

  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
setupApp();
