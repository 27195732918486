import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useInfoBoxStyles } from "./useInfoBox.styles";

interface InfoBoxProps {
  label: string;
  value: string | number;
}

export const InfoBox: React.FC<InfoBoxProps> = ({ label, value }) => {
  const { classes } = useInfoBoxStyles();

  return (
    <Box className={classes.infoBox}>
      <Typography>{label}</Typography>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};
