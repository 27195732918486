import { BMISlider, ResultsCard, ResultsCardProps } from "@/components";
import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { useTranslation } from "@/i18n/i18n.config";
import { Box, CircularProgress, makeStyles, Typography } from "@toolkit/ui";
import { FC, useEffect } from "react";

export const BmiResultCard: FC<
  {
    height: number;
    weight: number;
  } & ResultsCardProps
> = ({ height, weight, isLoading, isDataReady }) => {
  const { t } = useTranslation("digital-twin");
  const { classes } = useBmiResultCardStyles();
  const getBmi = (weight: number, height: number) => {
    return weight / Math.pow(height / 100, 2);
  };
  const value = getBmi(weight, height);
  useEffect(() => {
    userProfileVar({
      ...userProfileVar(),
      bmi: value,
    });
  }, [value]);
  return (
    <ResultsCard isLoading={isLoading} isDataReady={isDataReady}>
      <Box display={"flex"} flexDirection={"column"} py={2}>
        <Typography variant='body2' color='textPrimary'>
          {t("Current BMI status")}
        </Typography>
        <Typography variant='h4' color='textPrimary'>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <Typography variant='h3' color='textPrimary'>
                {isDataReady && isFinite(value) ? value.toFixed(1) : "-"}
              </Typography>
              <Box className={classes.sliderContainer}>
                <BMISlider value={value} />
              </Box>
            </>
          )}
        </Typography>
      </Box>
    </ResultsCard>
  );
};

const useBmiResultCardStyles = makeStyles()(() => ({
  sliderContainer: {
    marginTop: "35px",
    paddingInline: "10px",
  },
}));
