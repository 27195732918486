import { useTranslation } from "@/i18n/i18n.config";
import { Grid } from "@toolkit/ui";
import { CalculatorHeaderCard } from "@/domains/calculators/CalculatorNutrition/components/CalculatorHeaderCard/CalculatorHeaderCard";
import {
  ISleepTimeFormEvent,
  ISleepTimeFormZodSchemaType,
  SleepTimeForm,
} from "@/domains/calculators/sleep-time/sleep-time-calculator-form/SleepTimeCalculatorForm";
import React, { FC } from "react";
import { ICalculatorSleepSelectedTime } from "@/domains/calculators/sleep-time/types";

export type ICalculatorSleepStepFormEvent = ISleepTimeFormEvent;

type CalculatorSleepStepFormProps = {
  formValues?: ISleepTimeFormZodSchemaType;
  selectedTime: ICalculatorSleepSelectedTime;
  onChange: (event: ICalculatorSleepStepFormEvent) => void;
};

export const CalculatorSleepStepForm: FC<CalculatorSleepStepFormProps> = props => {
  const { formValues, selectedTime, onChange } = props;

  const { t } = useTranslation("digital-twin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CalculatorHeaderCard
          title={t("Set your sleep goal!")}
          description={t("Fill in the details to calculate your sleep or wake up time.")}
        />
      </Grid>

      <Grid item xs={12}>
        <SleepTimeForm formValues={formValues} selectedTime={selectedTime} onChange={onChange} />
      </Grid>
    </Grid>
  );
};
