import { userProfileVar } from "@/domains/MyDigitalTwinProfile/userProfileVar";
import { Gender } from "@/schema/types";
import { localStorageKeys } from "../local-storage-keys";
import { isInSehhatiApp } from "./sehhati-app-utils";
import { fetchGetAccessTokenFromParams } from "./sehhati-token-exchange-api";
import { setDigitalTwinUserProfileToLocalStorage } from "@/domains/MyDigitalTwinProfile/utils";
import { i18n } from "@toolkit/i18n";

export async function setUpAppFromParams() {
  const params = new URLSearchParams(window.location.search);
  if (process.env.NODE_ENV === "development" || params.has("access-key")) {
    await fetchGetAccessTokenFromParams();
  }
  if (isInSehhatiApp() && (!params.has("id") || !params.has("age") || !params.has("access-key"))) {
    console.warn("Missing required parameters, please provide id, age and access-key");
  }
  if (params.has("lang")) {
    const lng = params.get("lang") || localStorage.getItem(localStorageKeys.locale) || "ar";
    localStorage.setItem(localStorageKeys.locale, lng);
    i18n.changeLanguage(lng);
  }
  try {
    userProfileVar({
      ...userProfileVar(),
      age: parseInt(params.get("age") || userProfileVar().age),
      height: parseInt(params.get("height") || userProfileVar().height),
      weight: parseInt(params.get("weight") || userProfileVar().weight),
      gender: getGenderFromParams(userProfileVar().gender),
    });
    setDigitalTwinUserProfileToLocalStorage(userProfileVar());
  } catch (e) {
    console.error(e);
  }
}

function getGenderFromParams(defaultValue?: string) {
  const params = new URLSearchParams(window.location.search);
  if (!params.has("gender")) return defaultValue;
  if (params.get("gender")?.toLowerCase().startsWith("m")) {
    return Gender.Male;
  } else if (params.get("gender")?.toLowerCase().startsWith("f")) {
    return Gender.Female;
  }
  return defaultValue;
}
