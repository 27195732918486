import { makeStyles } from "@toolkit/ui";

export const useExpandableListStyles = makeStyles()(theme => ({
  root: {
    height: "fit-content",
    width: "100%",
    padding: 0,
    margin: 0,
    position: "relative",
    marginTop: "30px",
  },
  categoryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    cursor: "pointer",
    width: "100%",
    paddingInline: "15px",
    height: "99px",
    "&": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.primary[50],
    },
  },
  categoryIcon: {
    marginRight: "10px",
    marginTop: 10,
    marginBottom: 10,
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  categoryTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginInlineStart: "10px",
    textTransform: "capitalize",
  },
  expandIconButton: {
    marginInlineStart: "auto",
    "& svg": {
      width: "7px",
      height: "13px",
      marginLeft: "auto",
    },
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "20px",
    "&:last-of-type": {
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.primary[50],
    },
    height: "49px",
    width: "calc(100%)",
  },
  itemTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    marginInlineStart: "45px",
    textTransform: "capitalize",
  },
  disabledItemTitle: {
    color: theme.palette.text.disabled,
  },
  disabledCategoryTitle: {
    color: theme.palette.text.disabled,
  },
  infoIconButton: {
    "& svg": {
      width: "15px",
      height: "15px",
      margin: "0px",
      fill: theme.palette.primary[1700],
    },
  },
  navigateIconButton: {
    "& svg": {
      width: "7px",
      height: "13px",
      fill: theme.palette.primary[1600],
      marginLeft: "auto",
    },
  },
}));
