import React from "react";
import { Box, Typography } from "@toolkit/ui";
import { useBodyOrganRiskStyles } from "./BodyOrganRisk.styles";
import { BorderLinearProgress } from "./BorderLinearProgress";

interface BodyOrganRiskProps {
  icon?: React.ReactNode;
  bodyOrganRisk?: string;
  bodyString?: string;
  color?: string;
  value: number;
}

export const BodyOrganRisk: React.FC<BodyOrganRiskProps> = ({ icon, bodyOrganRisk, bodyString, value, color }) => {
  const { classes } = useBodyOrganRiskStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.bodyString}>{bodyString}</Typography>
        <Typography className={classes.bodyOrganRisk}>{bodyOrganRisk}</Typography>
        <BorderLinearProgress customColor={color!} variant='determinate' value={value!} classes={{ root: classes.progress }} />
      </Box>
    </Box>
  );
};
